import { useEffect, useState } from 'react';
import { GeoCoordinates, OpeningHoursSpecification } from 'schema-dts';
import { CmsRecord } from 'src/content/cms-provider';
import { getFullNameOfDay } from 'src/helpers/date-time-helper';
import { get } from 'src/helpers/request-helper';
import { ClubOpeningTimes, WeeklyOpeningTimes } from '../common/club-opening-times';
import { ClubPhoneNumber, ContactDetails } from '../common/contact-details';
import { getClubOpeningTimes } from '../common/opening-hours/opening-hours-helper';
import { group } from 'src/helpers/group-helper';

export const useGeoLocation = (clubId: string): GeoCoordinates => {
  const [geoLocation, setGeoLocation] = useState<GeoCoordinates>(null);

  useEffect(() => {
    get('clubs/locations')
      .then(({ clubLocations }) => {
        setGeoLocation({ '@type': 'GeoCoordinates', ...clubLocations[clubId] });
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return geoLocation;
};

export const useOpeningHoursSpecification = (clubId: string): OpeningHoursSpecification[] => {
  const [openingHoursSpecification, setOpeningHoursSpecification] = useState([]);

  const formatClubOpeningTimesForStructuredData = (weeklyOpeningTimes: WeeklyOpeningTimes) => {
    const clubOpeningTimes = Object.keys(weeklyOpeningTimes)
      .map((day) => {
        const openingTimes = weeklyOpeningTimes[day];
        return openingTimes.map((openingTime) => {
          return { day: day, opens: openingTime.from, closes: openingTime.to };
        });
      })
      .flat();

    const clubOpeningTimesInStructuredDataFormat = [
      ...group(clubOpeningTimes, (day) => {
        return day.opens + day.closes;
      }).values(),
    ].map((group) => {
      return {
        '@type': 'OpeningHoursSpecification',
        'dayOfWeek': group.map((openingTime) => {
          return getFullNameOfDay(openingTime.day);
        }),
        'opens': group[0].opens,
        'closes': group[0].closes,
      };
    });

    return clubOpeningTimesInStructuredDataFormat;
  };

  useEffect(() => {
    const getClubOpeningTimesData = async () => {
      await getClubOpeningTimes(clubId)
        .then((response: ClubOpeningTimes) => {
          setOpeningHoursSpecification(
            formatClubOpeningTimesForStructuredData(response.weeklyOpeningTimes)
          );
        })
        .catch((err) => console.log(err));
    };

    getClubOpeningTimesData();
  }, []);

  return openingHoursSpecification;
};

export const HEAD_OFFICE_PHONE_NUMBER = '+44 (0)1707 283500';

export const getClubPhoneNumber = (
  phoneNumbers: readonly ClubPhoneNumber[],
  numberType: string
): string => {
  const phoneNumber = phoneNumbers.find(
    (clubPhoneNumber: ClubPhoneNumber) => clubPhoneNumber.description.trim() === numberType
  );
  return phoneNumber ? phoneNumber.number : HEAD_OFFICE_PHONE_NUMBER;
};

const isPostCode = (part: string): boolean => /\w{1,2}\d{1,2}\s\d\w{2}/.test(part);

export const getAddressParts = (
  contactDetails: ContactDetails
): { streetAddress: string; postCode: string } => {
  let streetAddress = contactDetails.address1.trim();
  const splitAddress = contactDetails.address2.split(', ');
  let postCode = '';

  if (splitAddress.length === 1) {
    postCode = isPostCode(splitAddress[0]) ? splitAddress[0] : '';
  } else if (splitAddress.length > 1) {
    postCode = splitAddress[splitAddress.length - 1];
    splitAddress.pop();
    const addressLine2 = splitAddress.join(', ');
    const streetAddressEndsWithComma = streetAddress.lastIndexOf(',') + 1 === streetAddress.length;
    streetAddress = streetAddressEndsWithComma
      ? streetAddress.concat(' ').concat(addressLine2)
      : streetAddress.concat(', ').concat(addressLine2);
  }

  return { streetAddress, postCode };
};

export const getContactDetails = (cmsContent: CmsRecord): ContactDetails => ({
  clubName: cmsContent.clubDetails.clubName,
  hyphenatedClubName: cmsContent.clubDetails.hyphenatedClubName,
  address1: cmsContent.sharedContent.clubLocation.text1,
  address2: cmsContent.sharedContent.clubLocation.text2,
  clubPhoneNumbers: cmsContent.sharedLandingContent.clubPhoneNumbers,
  clubEmail: cmsContent.sharedContent.clubEmail,
});
