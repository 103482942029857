import {
  navigateToExternalPage,
  getGlobalPagePath,
  getClubPagePath,
} from 'src/helpers/navigation-helper';
import React, { useContext } from 'react';
import { CmsContent } from 'src/content';
import { SiteContext } from 'src/context/site-context';
import { clubPageIsDisabledInCms } from 'src/content/optional-page-helper';
import { isExternalLink } from 'src/helpers/link-helper';
import { useGatsbyPaths } from 'src/custom-hooks/use-gatsby-paths';
import { navigate } from 'gatsby';

type MobileNavLinkProps = {
  id: string;
  path: string;
  text: string;
  closeNav: () => void;
};

// TODO a lot of functionality is shared between this, and DesktopNavLink + WebsiteLink
const MobileNavLink: React.FC<MobileNavLinkProps> = ({ id, path, text, closeNav }) => {
  const { siteId, language, buildType, isGlobalPage } = useContext(SiteContext);
  const { isGatsbyPath } = useGatsbyPaths();

  const cmsContent = useContext(CmsContent);
  const isInternalLink = !(path && isExternalLink(path));

  if (!isGlobalPage && isInternalLink) {
    if (clubPageIsDisabledInCms(path, cmsContent.pages)) {
      return null;
    }
  }

  const translatedPath = isInternalLink
    ? isGlobalPage
      ? getGlobalPagePath(path, language, buildType)
      : getClubPagePath(path, siteId, language)
    : path;

  return (
    <div
      id={id}
      css={{ cursor: 'pointer' }}
      onClick={() => {
        isInternalLink && isGatsbyPath(translatedPath)
          ? navigate(translatedPath)
          : navigateToExternalPage(translatedPath);
        closeNav();
      }}
    >
      {text}
    </div>
  );
};

export default MobileNavLink;
