import { BuildType } from 'src/enums/build/build-type';
import { Cookie } from 'src/cookies';
import { getPreferenceCookieWithKey } from 'src/cookies/cookie-types/preference-cookies';

const LANGUAGE_COOKIE_NAME = 'lang';

export const getPreferredLanguageCookieForBuildType = (buildType: BuildType): Cookie => {
  const key = getPreferredLanguageCookieKey(buildType);
  return getPreferenceCookieWithKey(key);
};

const getPreferredLanguageCookieKey = (buildType: BuildType): string =>
  `${getLanguageCookiePrefixForBuildType(buildType)}#${LANGUAGE_COOKIE_NAME}`;

const getLanguageCookiePrefixForBuildType = (buildType: BuildType): string => {
  switch (buildType) {
    case BuildType.DAVID_LLOYD_BELGIUM:
      return 'davidlloydbe';
    case BuildType.DAVID_LLOYD_GERMANY:
      return 'davidlloydde';
    case BuildType.DAVID_LLOYD_SPAIN:
      return 'davidlloydes';
    case BuildType.DAVID_LLOYD_FRANCE:
      return 'davidlloydfr';
    case BuildType.DAVID_LLOYD_IRELAND:
      return 'davidlloydie';
    case BuildType.DAVID_LLOYD_ITALY:
      return 'davidlloydit';
    case BuildType.DAVID_LLOYD_NETHERLANDS:
      return 'davidlloydnl';
    default:
      return 'davidlloyduk';
  }
};
