import { BuildType } from './build-type';

export enum BuildDomain {
  DAVID_LLOYD_UK = 'uk',
  HARBOUR = 'harbour',
  DAVID_LLOYD_BELGIUM = 'belgium',
  DAVID_LLOYD_GERMANY = 'germany',
  DAVID_LLOYD_SPAIN = 'spain',
  DAVID_LLOYD_FRANCE = 'france',
  DAVID_LLOYD_IRELAND = 'ireland',
  DAVID_LLOYD_ITALY = 'italy',
  DAVID_LLOYD_NETHERLANDS = 'netherlands',
  DAVID_LLOYD_SWITZERLAND = 'switzerland',
  MERIDIAN = 'meridian',
}

export const allBuildDomains = [
  BuildDomain.DAVID_LLOYD_UK,
  BuildDomain.HARBOUR,
  BuildDomain.DAVID_LLOYD_BELGIUM,
  BuildDomain.DAVID_LLOYD_GERMANY,
  BuildDomain.DAVID_LLOYD_SPAIN,
  BuildDomain.DAVID_LLOYD_FRANCE,
  BuildDomain.DAVID_LLOYD_IRELAND,
  BuildDomain.DAVID_LLOYD_ITALY,
  BuildDomain.DAVID_LLOYD_NETHERLANDS,
  BuildDomain.DAVID_LLOYD_SWITZERLAND,
  BuildDomain.MERIDIAN,
];

// Add domains here when ready to (prepare) them for the redesigned website
export const newWebsiteBuildDomains: BuildDomain[] = allBuildDomains.filter(
  (buildDomain) => buildDomain !== BuildDomain.HARBOUR && buildDomain !== BuildDomain.MERIDIAN
);

export enum DesignRolloutStatus {
  SITECORE_ONLY,
  MIGRATING_TO_GATSBY,
  GATSBY_ONLY,
}

export const getDesignRolloutStatusForBuildDomain = (
  buildDomain: BuildDomain
): DesignRolloutStatus => {
  switch (buildDomain) {
    case BuildDomain.HARBOUR: // See newWebsiteBuildDomains
      return DesignRolloutStatus.SITECORE_ONLY;

    // return DesignRolloutStatus.MIGRATING_TO_GATSBY;

    case BuildDomain.DAVID_LLOYD_BELGIUM:
    case BuildDomain.DAVID_LLOYD_SPAIN:
    case BuildDomain.DAVID_LLOYD_IRELAND:
    case BuildDomain.DAVID_LLOYD_ITALY:
    case BuildDomain.DAVID_LLOYD_NETHERLANDS:
    case BuildDomain.DAVID_LLOYD_FRANCE:
    case BuildDomain.DAVID_LLOYD_GERMANY:
    case BuildDomain.DAVID_LLOYD_SWITZERLAND:
    case BuildDomain.DAVID_LLOYD_UK:
    case BuildDomain.MERIDIAN:
      return DesignRolloutStatus.GATSBY_ONLY;
  }
};

export function getBuildTypesForBuildDomain(buildDomain: BuildDomain): BuildType[] {
  switch (buildDomain) {
    case BuildDomain.DAVID_LLOYD_UK:
      return [BuildType.DAVID_LLOYD_UK];
    case BuildDomain.HARBOUR:
      return [BuildType.HARBOUR];
    case BuildDomain.DAVID_LLOYD_BELGIUM:
      return [BuildType.DAVID_LLOYD_BELGIUM];
    case BuildDomain.DAVID_LLOYD_GERMANY:
      return [BuildType.DAVID_LLOYD_GERMANY];
    case BuildDomain.DAVID_LLOYD_SPAIN:
      return [BuildType.DAVID_LLOYD_SPAIN];
    case BuildDomain.DAVID_LLOYD_FRANCE:
      return [BuildType.DAVID_LLOYD_FRANCE];
    case BuildDomain.DAVID_LLOYD_IRELAND:
      return [BuildType.DAVID_LLOYD_IRELAND];
    case BuildDomain.DAVID_LLOYD_ITALY:
      return [BuildType.DAVID_LLOYD_ITALY];
    case BuildDomain.DAVID_LLOYD_NETHERLANDS:
      return [BuildType.DAVID_LLOYD_NETHERLANDS];
    case BuildDomain.DAVID_LLOYD_SWITZERLAND:
      return [BuildType.DAVID_LLOYD_SWITZERLAND];
    case BuildDomain.MERIDIAN:
      return [BuildType.MERIDIAN];
  }
}

export function getBuildDomainForBuildType(buildType: BuildType): BuildDomain {
  switch (buildType) {
    case BuildType.DAVID_LLOYD_UK:
      return BuildDomain.DAVID_LLOYD_UK;
    case BuildType.DAVID_LLOYD_BELGIUM:
      return BuildDomain.DAVID_LLOYD_BELGIUM;
    case BuildType.DAVID_LLOYD_FRANCE:
      return BuildDomain.DAVID_LLOYD_FRANCE;
    case BuildType.DAVID_LLOYD_GERMANY:
      return BuildDomain.DAVID_LLOYD_GERMANY;
    case BuildType.DAVID_LLOYD_IRELAND:
      return BuildDomain.DAVID_LLOYD_IRELAND;
    case BuildType.DAVID_LLOYD_ITALY:
      return BuildDomain.DAVID_LLOYD_ITALY;
    case BuildType.DAVID_LLOYD_NETHERLANDS:
      return BuildDomain.DAVID_LLOYD_NETHERLANDS;
    case BuildType.DAVID_LLOYD_SPAIN:
      return BuildDomain.DAVID_LLOYD_SPAIN;
    case BuildType.DAVID_LLOYD_SWITZERLAND:
      return BuildDomain.DAVID_LLOYD_SWITZERLAND;
    case BuildType.HARBOUR:
      return BuildDomain.HARBOUR;
    case BuildType.MERIDIAN:
      return BuildDomain.MERIDIAN;
    case BuildType.BLAZE_STUDIO: {
      throw new Error('Not implemented yet: BuildType.BLAZE_STUDIO case');
    }
  }
}

export function getPrimaryBuildTypeForBuildDomain(buildDomain: BuildDomain): BuildType {
  switch (buildDomain) {
    case BuildDomain.DAVID_LLOYD_UK:
      return BuildType.DAVID_LLOYD_UK;
    case BuildDomain.HARBOUR:
      return BuildType.HARBOUR;
    case BuildDomain.DAVID_LLOYD_BELGIUM:
      return BuildType.DAVID_LLOYD_BELGIUM;
    case BuildDomain.DAVID_LLOYD_GERMANY:
      return BuildType.DAVID_LLOYD_GERMANY;
    case BuildDomain.DAVID_LLOYD_SPAIN:
      return BuildType.DAVID_LLOYD_SPAIN;
    case BuildDomain.DAVID_LLOYD_FRANCE:
      return BuildType.DAVID_LLOYD_FRANCE;
    case BuildDomain.DAVID_LLOYD_IRELAND:
      return BuildType.DAVID_LLOYD_IRELAND;
    case BuildDomain.DAVID_LLOYD_ITALY:
      return BuildType.DAVID_LLOYD_ITALY;
    case BuildDomain.DAVID_LLOYD_NETHERLANDS:
      return BuildType.DAVID_LLOYD_NETHERLANDS;
    case BuildDomain.DAVID_LLOYD_SWITZERLAND:
      return BuildType.DAVID_LLOYD_SWITZERLAND;
    case BuildDomain.MERIDIAN:
      return BuildType.MERIDIAN;
  }
}
