import React, { useContext } from 'react';
import { StaticTextLookupKey } from 'src/translators/static-text-lookup';
import { TranslatedStaticText } from 'src/translators/static-text-translator';
import { SiteContext } from 'src/context/site-context';
import { CSSInterpolation } from '@emotion/serialize';

type WebsiteLinkButtonContentProps = {
  buttonText?: string | React.ReactElement;
  buttonKey?: StaticTextLookupKey;
  buttonCss?: CSSInterpolation;
  buttonEndIcon?: React.ReactElement;
  buttonType?: string;
  gtmTrackingId?: string;
};

export const WebsiteLinkButtonContent: React.FC<WebsiteLinkButtonContentProps> = ({
  buttonText,
  buttonKey,
  buttonCss,
  buttonEndIcon,
  buttonType,
  gtmTrackingId,
}) => {
  const { styling } = useContext(SiteContext);

  return (
    <div
      css={buttonCss ? buttonCss : styling.buttons.primary}
      className={`${buttonType || `button-inline`}`}
      data-gtmid={gtmTrackingId || buttonText.toString()}
    >
      {buttonText || <TranslatedStaticText lookupKey={buttonKey} />}
      {buttonEndIcon}
    </div>
  );
};
