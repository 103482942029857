import { LinksLookup } from 'src/translators/links-lookup';

// When modifying an existing entry here, you might need to add an NGINX redirect from the old URL to the new one,
// in belgium.template
export const linksLookup: LinksLookup = {
  'book-a-tour': 'boek-een-bezoek',
  'club-facilities': 'uitrusting-van-de-club',
  'club-treatments': 'club-treatments',
  'club-fitness': 'club-fitness',
  'creche': 'kinderdagverblijf',
  'exercise-classes': 'gymlessen',
  'gym': 'fitnessruimte',
  'sports': 'sports',
  'kids-classes': 'lessen-voor-kids',
  'children': 'children',
  'pools': 'zwembaden',
  'spa': 'spa',
  'tennis': 'tennis',
  'enquire-now': 'vraag-info',
  'family-and-kids': 'familie-en-kids',
  'golf': 'golf',
  'join-now': 'inschrijven',
  'membership-trial': 'proefabonnement',
  'relax-and-socialise': 'ontspan-en-ontmoet-mensen',
  'timetable': 'tijdschemas',
  'yoga': 'yoga',
  'blaze': 'blaze',
  'personal-training': 'personal-training',
  'join': 'inschrijven',
  // If this link is changed, we should also update the links stored in CC
  // E.g. see DLLABS-10292
  'join-online': 'sluit-online-aan',
  'treatments': 'face-body-spa',
  'global-enquire-now': 'vraag-info',
  'global-enquire-now-campaign-1': 'campaign-1',
  'global-enquire-now-campaign-2': 'campaign-2',
  'global-enquire-now-campaign-3': 'campaign-3',
  'referral': 'verwijzing',
  'friend': 'vriend',
  'memberships': 'lidmaatschaps',
  'terms-and-conditions': 'voorwaarden',
  'policy': 'beleid',
  'gyms-in-brussels': 'sportscholen-in-brussel',
};
