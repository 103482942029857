import React from 'react';
import { FooterImageContent } from 'src/models/cms/footer-content';
import moment from 'moment';
import CloudinaryImage from 'src/components/common/cloudinary-image';
import mq from 'src/styling/media-queries';
import { FooterImageSize } from 'src/helpers/footer-images-helper';

const footerImagesSectionTitle = mq({
  textTransform: 'uppercase',
  fontWeight: 'bold',
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
  paddingLeft: ['1rem', '2rem'],
});

const footerImagesList = mq({
  paddingBottom: '1rem',
  paddingLeft: ['1rem', '0'],
});

const footerImageContainer = mq({
  display: 'inline-block',
  margin: '0 0.5rem',
});

const footerImage = (size: FooterImageSize) => {
  return mq({
    minHeight: minFooterImageSize(size),
    minWidth: minFooterImageSize(size),
    maxHeight: maxFooterImageSize(size),
    maxWidth: maxFooterImageSize(size),
  });
};

const minFooterImageSize = (size: FooterImageSize) => {
  switch (size) {
    case FooterImageSize.ExtraSmall:
      return '1.5rem';
    case FooterImageSize.Small:
      return '1.75rem';
    case FooterImageSize.Medium:
      return '2rem';
    case FooterImageSize.Large:
      return '3rem';
    case FooterImageSize.ExtraLarge:
      return '4rem';
    default:
      return '1.5rem';
  }
};

const maxFooterImageSize = (size: FooterImageSize) => {
  switch (size) {
    case FooterImageSize.ExtraSmall:
      return '3rem';
    case FooterImageSize.Small:
      return '4rem';
    case FooterImageSize.Medium:
      return '8rem';
    case FooterImageSize.Large:
      return '10rem';
    case FooterImageSize.ExtraLarge:
    default:
      return '15rem';
  }
};

type FooterImagesSectionProps = {
  title: string;
  images: FooterImageContent[];
};

const FooterImagesSection: React.FC<FooterImagesSectionProps> = ({ title, images }) => {
  const currentTime = moment();
  const shouldDisplaySection =
    images.filter(
      (image) => !image.setToExpire || moment(image.expiryDate, 'YYYY-MM-DD').isAfter(currentTime)
    ).length > 0;

  return (
    shouldDisplaySection && (
      <li>
        <h4 css={footerImagesSectionTitle}>{title}</h4>
        <ul css={footerImagesList}>
          {images.map(
            (image, index) =>
              (!image.setToExpire ||
                moment(image.expiryDate, 'YYYY-MM-DD').isAfter(currentTime)) && (
                // using the index as the key because images are never reordered/inserted/deleted dynamically
                <li key={index} css={footerImageContainer}>
                  {image.link ? (
                    <a href={image.link} target={'_blank'} rel={'noopener noreferrer'}>
                      <CloudinaryImage
                        imageDetails={image.image}
                        styles={footerImage(image.size)}
                      />
                    </a>
                  ) : (
                    <CloudinaryImage imageDetails={image.image} styles={footerImage(image.size)} />
                  )}
                </li>
              )
          )}
        </ul>
      </li>
    )
  );
};

export default FooterImagesSection;
