import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { CmsContent, ClubMetadata } from 'src/content';
import ContentProviders from 'src/content/content-providers';
import { clubBannerIsActive } from 'src/helpers/club-helper';
import { addVarToDataLayer } from 'src/helpers/analytics-helper';
import Footer from './footer/footer';
import FooterRedesign from './footer/redesign';
import Header from './header';
import Snippets, { SnippetsProps } from './snippets';
import { clubFacilitiesMobileNavHeight, flex, globalBannerHeight } from 'src/styling/constants';
import mq from 'src/styling/media-queries';
import MobileNav from './mobile-nav';
import { globalBannerIsActive } from '../common/promo/timed-promotions-helper';
import { useToggle } from 'src/custom-hooks/use-toggle';
import { SiteContext } from 'src/context/site-context';
import GlobalContentProvider from 'src/content/global-content-provider';
import { IS_MEMBER_COOKIE } from 'src/cookies/is-member-cookie';
import CookieConsentPopup from 'src/components/layout/cookie-consent';
import { cookieConsentIsNecessaryForBuildType } from 'src/helpers/cookie-helper';
import { FEATURE_FLAG, getFeatureFlagValueAtClub } from 'src/helpers/feature-flag-helper';
import { getFacilitiesAtClub } from '../facilities/facilities';
import tawk from 'src/helpers/tawk';
import { useLocation } from '@gatsbyjs/reach-router';
import { GlobalCmsContent } from 'src/content/global-content';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import 'src/styling/global.css';

const runningInBrowser = typeof window !== `undefined` && !navigator.userAgent.includes('jsdom');

type ClubPageLayoutProps = PropsWithChildren<{
  siteId: string;
  snippets?: SnippetsProps;
}>;

const ClubPageLayout = (props: ClubPageLayoutProps) => {
  return (
    <GlobalContentProvider>
      <ContentProviders clubId={props.siteId}>
        <LayoutContent {...props} />
      </ContentProviders>
    </GlobalContentProvider>
  );
};

type LayoutContentProps = ClubPageLayoutProps;

const LayoutContent: React.FC<LayoutContentProps> = ({ snippets, children }) => {
  const { promotionContent, sharedContent, features, pages, navigationMenuContent } =
    useContext(CmsContent);
  const { newFooterContent } = useContext(GlobalCmsContent);
  const { clubName, clubId } = useContext(ClubMetadata);
  const { styling, buildType, siteId } = useContext(SiteContext);

  const bannerIsActive =
    globalBannerIsActive(clubId, promotionContent.timedPromotions) ||
    clubBannerIsActive(sharedContent);

  const [mobileNavigationOpened, setMobileNavigationOpened, , hideMobileNavigation] =
    useToggle(false);
  const [clubFacilitiesMobileNavEnabled, setClubFacilitiesMobileNavEnabled] = useState(false);

  const clubFacilitiesPresent =
    getFacilitiesAtClub(
      sharedContent.clubFacilities,
      pages,
      navigationMenuContent.navigationPagesToDisplay.clubFacilitiesPages
    ).length > 0;

  useEffect(() => {
    addVarToDataLayer({ clubName: clubName });
    if (runningInBrowser) {
      addVarToDataLayer({
        memberType: IS_MEMBER_COOKIE.isSet() ? 'member' : 'nonMember',
      });
    }

    getFeatureFlagValueAtClub(siteId, FEATURE_FLAG.ENABLE_CLUB_FACILITIES_MOBILE_NAV)
      .catch(() => false)
      .then((response) => {
        setClubFacilitiesMobileNavEnabled(response);
      });
  }, []);

  const currentPath = useLocation();

  const enquireNowLink = sharedContent.enquireNowButton.enquireNowButtonLink;

  if (!features.clubPageLiveChat && !currentPath.pathname.includes(enquireNowLink)) {
    tawk.hideWidget();
  }

  return (
    <>
      <div css={[flex.columnCentered, { position: 'relative', maxHeight: '100vh' }]}>
        <Header
          clubName={clubName}
          toggleMobileNav={() => setMobileNavigationOpened(!mobileNavigationOpened)}
          showingMobileNav={mobileNavigationOpened}
          timedPromotions={promotionContent.timedPromotions}
          enableClubFacilitiesMobileNav={clubFacilitiesMobileNavEnabled}
        />
        {bannerIsActive && (
          <div
            css={mq({ paddingBottom: [globalBannerHeight.mobile, globalBannerHeight.default] })}
          />
        )}
        <div className="spacer" style={{ paddingBottom: '4.5rem' }} />
        <div
          className="spacer-nav"
          css={mq.withSmallTablet({ paddingBottom: '3.5rem', display: ['none', 'none', 'inline'] })}
        />
        <MobileNav
          clubName={clubName}
          closeNav={() => hideMobileNavigation()}
          mobileNavigationOpened={mobileNavigationOpened}
          bannerIsActive={bannerIsActive}
        />
        <div
          className="page-scroll"
          css={mq({
            backgroundColor: styling.colors.secondaryBackground,
            width: '100%',
            position: 'relative',
            top: [
              clubFacilitiesPresent && clubFacilitiesMobileNavEnabled
                ? clubFacilitiesMobileNavHeight
                : 0,
              0,
            ],
          })}
        >
          {children}
          {newFooterContent.useNewFooter ? (
            <FooterRedesign />
          ) : (
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                backgroundColor: styling.colors.headerBackground,
              }}
            >
              <Footer />
            </div>
          )}
        </div>
      </div>
      <Snippets {...snippets} chatEnabled={features.clubPageLiveChat} buildType={buildType} />
      {cookieConsentIsNecessaryForBuildType(buildType) && <CookieConsentPopup />}
    </>
  );
};

export default ClubPageLayout;
