import React from 'react';
import { IconProps } from './icon-types';

export const FlagUk = ({ size }: IconProps) => (
  <svg
    viewBox="0 0 127 127"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: size, height: size, borderRadius: size }}
  >
    <g>
      <path d="M166.786 -5.74609H-39.1875V132.195H166.786V-5.74609Z" fill="#21409A" />
      <path
        d="M0.871094 21.0823H16.6435L49.6626 43.1952V-18.0117H77.9548V43.1879L110.956 21.0823H126.724V31.637L100.689 49.076H126.724V77.3718H100.689L126.724 94.809V105.367H110.963L77.9548 83.2581V148.412H49.6626V83.2489L16.638 105.367H0.871094V94.8127L26.9106 77.3718H0.871094V49.076H26.9161L0.871094 31.6333V21.0823Z"
        fill="white"
      />
      <path
        d="M49.6626 79.393V77.3711H42.6757L0.871094 105.367H10.8708L49.6626 79.393Z"
        fill="#EC1C24"
      />
      <path
        d="M77.9551 47.0483V49.0757H84.9256L126.725 21.082H116.723L77.9551 47.0483Z"
        fill="#EC1C24"
      />
      <path d="M0.871094 27.7723L32.6778 49.0757H42.6775L0.871094 21.082V27.7723Z" fill="#EC1C24" />
      <path d="M84.9297 77.3711L126.725 105.367V98.669L94.9257 77.3711H84.9297Z" fill="#EC1C24" />
      <path
        d="M72.2957 54.737V-18.0117H55.3163V54.737H0.871094V71.7127H55.3163V148.412H72.2957V71.7127H126.724V54.737H72.2957Z"
        fill="#EC1C24"
      />
    </g>
  </svg>
);
