import React, { useContext } from 'react';

import { ContentAndMetaConsumer } from 'src/content';
import { EnabledPages } from 'src/content/enabled-pages-helper';
import { SiteContext } from 'src/context/site-context';
import mq from 'src/styling/media-queries';
import FadedImage from '../common/faded-image';
import imageFade from 'src/helpers/image-fades';
import { WebsiteLink } from '../common/website-link';
import { ClubPageTypeProps } from './club-page-type';
import { NavigationMenuContent } from 'src/models/cms/navigation-menu-content';

const wrapperStyle = (shouldCenter: boolean) =>
  mq({
    width: shouldCenter ? null : '100%',
    maxWidth: shouldCenter ? [null, '70rem'] : null,
    marginLeft: shouldCenter ? [null, 'auto'] : null,
    marginRight: shouldCenter ? [null, 'auto'] : null,
  });

const facilityWrapperStyle = () =>
  mq({
    'width': '100%',
    '*zoom': 1,
    'clear': 'both',
    'content': '" "',
    'padding': ['0 1rem', '1rem 0 0'],
    'display': 'flex',
    'justifyContent': 'center',
  });

type HeaderSubmenuSelectProps = {
  center?: boolean;
  type: ClubPageTypeProps;
  includeTitle?: boolean;
  gtmTrackingId?: string;
  onClick?: () => void;
};

type HeaderSubmenuSelectContentProps = HeaderSubmenuSelectProps & {
  clubName: string;
  enabledPages: EnabledPages;
  navigationMenu: NavigationMenuContent;
  onClick?: () => void;
};

export const HeaderSubmenuSelect = (props: HeaderSubmenuSelectProps) => (
  <ContentAndMetaConsumer>
    {(cmsData, clubMetadata) => (
      <HeaderSubmenuSelectContent
        {...props}
        clubName={clubMetadata.clubName}
        enabledPages={cmsData.pages}
        navigationMenu={cmsData.navigationMenuContent}
      />
    )}
  </ContentAndMetaConsumer>
);

const HeaderSubmenuSelectContent = ({
  center,
  type,
  navigationMenu,
  onClick,
}: HeaderSubmenuSelectContentProps) => {
  const { styling } = useContext(SiteContext);
  const isCenterFalse = center === false;

  return (
    <div css={wrapperStyle(!isCenterFalse)}>
      <div css={facilityWrapperStyle()}>
        {navigationMenu[type]?.map((menuItem) => (
          <div className="pa2 f5 w-12-rem h-8-rem" key={menuItem.link}>
            <WebsiteLink to={menuItem.link} handleClick={onClick}>
              <div
                css={{
                  height: '100%',
                  width: '100%',
                  position: 'relative',
                }}
              >
                <FadedImage
                  className="rounded"
                  image={menuItem.image}
                  fadeType={imageFade.fadeDownwards}
                  zoomOnHover
                >
                  <div className={`pa2 h-100 flex items-end`}>
                    <div
                      css={[
                        styling.fonts.bold,
                        { color: 'white', textAlign: 'left', lineHeight: 1 },
                      ]}
                    >
                      {menuItem.displayText}
                    </div>
                  </div>
                </FadedImage>
              </div>
            </WebsiteLink>
          </div>
        ))}
      </div>
    </div>
  );
};
