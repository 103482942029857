import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import imageFade from '../../helpers/image-fades';
import CloudinaryImage from './cloudinary-image';
import FixedImage from './fixed-image';
import SiteConsumer from '../../context/site-consumer';
import { CSSInterpolation } from '@emotion/serialize';
import ImageLoadingSkeleton from './image-loading-skeleton';
import mq, { mqReducedMotion } from 'src/styling/media-queries';
import { keyframes } from '@emotion/react';

export enum RoundedCornersOptions {
  YES,
  THEME_DEFAULT,
  NO,
}

export enum FadeInEffectOption {
  NONE,
  IMMEDIATELY, // do not wait for the image to load or for React to hydrate
  AFTER_LOAD,
}

type FadedImageProps = {
  style?: CSSInterpolation;
  contentWrapperStyle?: CSSInterpolation;
  className?: string;
  image: Queries.ImageDetails | Queries.ImageWithCropDetails;
  enablePreload?: boolean;
  children?: React.ReactChild;
  fadeType?: string;
  mobileFadeType?: string;
  shadow?: boolean;
  gtmTrackingId?: string;
  roundedCorners?: RoundedCornersOptions;
  zoomOnHover?: boolean;
  isDownloadApp?: boolean;
  loading?: 'eager' | 'lazy';
  loadingSkeleton?: boolean;
  fadeInEffect?: FadeInEffectOption;
} & (
  | {
      fixedImage: true;
      imageWidthLimit?: undefined;
      enableBlurredPlaceholder?: undefined;
    }
  | {
      fixedImage?: false;
      imageWidthLimit?: number;
      enableBlurredPlaceholder?: boolean;
    }
);

const immediateFadeInAnimation = keyframes({
  '0%': {
    opacity: 0,
  },
  '100%': {
    opacity: 1,
  },
});

const FadedImage = forwardRef<HTMLImageElement, FadedImageProps>(
  (
    {
      style = {},
      contentWrapperStyle = {},
      className,
      image,
      enablePreload = false,
      enableBlurredPlaceholder = false,
      children,
      fadeType,
      mobileFadeType,
      shadow,
      gtmTrackingId,
      roundedCorners = RoundedCornersOptions.THEME_DEFAULT,
      fixedImage,
      zoomOnHover,
      isDownloadApp,
      loading,
      loadingSkeleton = false,
      fadeInEffect = FadeInEffectOption.NONE,
      imageWidthLimit,
    },
    ref
  ) => {
    const [isImageLoaded, setImageIsLoaded] = useReducer(() => true, false);
    const innerRef = useRef(null);
    useImperativeHandle(ref, () => innerRef.current, []);

    const imageDetails = {
      ...image,
      ...(image as Queries.ImageWithCropDetails)?.cropDetails,
    };

    const fadeInStyle = (() => {
      switch (fadeInEffect) {
        case FadeInEffectOption.NONE:
          return null;
        case FadeInEffectOption.IMMEDIATELY:
          return mqReducedMotion({
            animation: [`1s ease-in-out ${immediateFadeInAnimation}`, 'none'],
          });
        case FadeInEffectOption.AFTER_LOAD:
          return mqReducedMotion({
            opacity: isImageLoaded ? 1 : 0,
            transition: ['opacity 1s ease-in-out', 'none'],
          });
      }
    })();

    return (
      <SiteConsumer>
        {({ styling, isMeridian }) => {
          const hasRoundedCorners = getShouldUseRoundedCorners(
            roundedCorners,
            !!image.leftHighlight,
            isMeridian
          );
          const roundedCornersValue = isMeridian && !isDownloadApp ? '0.65rem' : '0.2rem';

          return (
            <div
              css={[
                {
                  'width': '100%',
                  'height': '100%',
                  'overflow': 'hidden',
                  'position': 'relative',
                  'flexShrink': 0,
                  'borderRadius': hasRoundedCorners ? roundedCornersValue : 0,
                  'borderLeft': image.leftHighlight
                    ? `15px solid ${styling.colors.highlightText}`
                    : null,
                  'img': {
                    display: 'block',
                  },
                  '&:hover': zoomOnHover && {
                    img: {
                      transform: 'scale(1.1)',
                    },
                  },
                },
                style,
                fadeInStyle,
                enableBlurredPlaceholder &&
                  mqReducedMotion({
                    filter: isImageLoaded ? 'none' : 'blur(5px)',
                    transition: ['filter 0.7s ease-in-out', 'none'],
                  }),
              ]}
              className={`${className ? className : ''} ${shadow ? 'shadow' : ''}`}
              data-gtmid={gtmTrackingId || className}
            >
              {fixedImage ? (
                <FixedImage
                  imageDetails={imageDetails}
                  ref={innerRef}
                  loading={loading}
                  onImageLoaded={() => {
                    setImageIsLoaded();
                  }}
                  enablePreload={enablePreload}
                />
              ) : (
                <>
                  {enableBlurredPlaceholder && (
                    <CloudinaryImage
                      imageDetails={imageDetails}
                      imageWidthLimit={200}
                      enablePreload
                      enableInlineDataSrc
                    />
                  )}
                  <CloudinaryImage
                    imageDetails={imageDetails}
                    ref={innerRef}
                    loading={loading}
                    onImageLoaded={() => {
                      setImageIsLoaded();
                    }}
                    enablePreload={enablePreload}
                    imageWidthLimit={imageWidthLimit}
                    styles={
                      enableBlurredPlaceholder && [
                        { position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 },
                        !isImageLoaded && { opacity: '0 !important' },
                      ]
                    }
                  />
                </>
              )}
              {loadingSkeleton ? <ImageLoadingSkeleton imageRef={innerRef} /> : null}
              <div
                css={mq([
                  {
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    background: mobileFadeType
                      ? [
                          `linear-gradient(${mobileFadeType})`,
                          `linear-gradient(${fadeType || imageFade.none})`,
                        ]
                      : `linear-gradient(${fadeType || imageFade.none})`,
                  },
                  contentWrapperStyle,
                ])}
              >
                {children}
              </div>
            </div>
          );
        }}
      </SiteConsumer>
    );
  }
);

const getShouldUseRoundedCorners = (
  roundedCornersOption: RoundedCornersOptions,
  isLeftHighlight: boolean,
  isMeridian: boolean
) => {
  if (roundedCornersOption === RoundedCornersOptions.NO) {
    return false;
  }
  if (isMeridian) {
    return true;
  }
  return roundedCornersOption === RoundedCornersOptions.YES && !isLeftHighlight;
};

FadedImage.displayName = 'FadedImage';

export default FadedImage;
