import React, { useContext } from 'react';
import RightChevron from 'src/components/icons/right-chevron';
import { SiteContext } from 'src/context/site-context';
import { Styling } from 'src/context/styling';
import { borderRadius, shadow, zIndexes } from 'src/styling/constants';
import mq from 'src/styling/media-queries';
import { CSSInterpolation } from '@emotion/serialize';

type LinkDropdownProps = {
  links: readonly Queries.LinkDropdownCtaDropdownOptions[];
};

const createStyling = (styling: Styling) =>
  ({
    dropdownWrapper: { zIndex: zIndexes.popup, top: '-2px' },
    dropdownContainer: mq.withSmallTablet([
      shadow.around,
      {
        borderRadius: borderRadius.meridianButton,
        backgroundColor: 'white',
        minWidth: '100%',
        maxWidth: ['100vw', 'calc(100vw - 32px)'],
        width: ['calc(100vw)', 'auto'],
        right: ['-16px', 0],
      },
    ]),
    link: mq.withSmallTablet([
      styling.fonts.regular,
      {
        display: 'flex',
        width: ['auto', 'max-content'],
        maxWidth: ['100vw', 'calc(100vw - 32px)'],
        alignItems: 'center',
        color: styling.colors.headerNavigationText,
        padding: ['1.25rem 2rem', '1rem'],
      },
    ]),
    chevron: mq.withSmallTablet({
      display: ['inline', 'none'],
    }),
    divider: mq.withSmallTablet({
      height: '1.5px',
      backgroundColor: '#dedfe1',
      marginLeft: ['2rem', '1rem'],
      marginRight: ['2rem', '1rem'],
    }),
    upArrow: mq.withSmallTablet({
      position: 'absolute',
      display: ['inline', 'none'],
      width: 0,
      height: 0,
      right: 'calc(50% - 12px)',
      top: 'calc(100% + 6px)',
      borderLeft: '12px solid transparent',
      borderRight: '12px solid transparent',
      borderBottom: '12px solid white',
      zIndex: 1,
    }),
  }) satisfies Record<string, CSSInterpolation>;

const LinkDropdown = ({ links }: LinkDropdownProps) => {
  const { styling } = useContext(SiteContext);
  const componentStyling = createStyling(styling);

  return (
    <>
      <div className="relative" css={componentStyling.dropdownWrapper}>
        <div css={componentStyling.upArrow} />
        <div className="absolute top-1" css={componentStyling.dropdownContainer}>
          <div className="flex flex-column pv1">
            {links.map(({ link, displayText }, index) => (
              <>
                <a href={link} css={componentStyling.link}>
                  <span className="mr-auto">{displayText}</span>
                  <div css={componentStyling.chevron}>
                    <RightChevron size={24} fill="#A7A8AB" />
                  </div>
                </a>
                {index !== links.length - 1 && <div css={componentStyling.divider} />}
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default LinkDropdown;
