import React from 'react';
import { IconProps } from './icon-types';
import { colors } from '../../styling/constants';

type ExpandIconProps = IconProps & {
  isExpanded?: boolean;
};

const ExpandIcon = ({ size, fill, isExpanded }: ExpandIconProps) => (
  <div style={{ width: size || '100%', height: size || '100%' }}>
    <svg
      fill={fill || colors.davidLloydPurple}
      css={{
        display: 'block',
        transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
        transition: 'transform 500ms ease',
      }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  </div>
);

export default ExpandIcon;
