import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { GlobalCmsContent } from 'src/content/global-content';
import { SiteContext } from 'src/context/site-context';
import { Styling } from 'src/context/styling';
import { PromoRibbonStyle } from 'src/enums/promo-ribbon-style';
import { CSSInterpolation } from '@emotion/serialize';
import { WebsiteLink } from 'src/components/common/website-link';
import mq, { mqReducedMotion } from 'src/styling/media-queries';
import { flex } from 'src/styling/constants';
import RedesignDownChevron from 'src/components/icons/redesign-down-chevron';
import { Language } from 'src/enums/language';
import { BuildType } from 'src/enums/build/build-type';
import { isGlobalLinkCurrentPage } from 'src/helpers/navigation-helper';
import { currentHeaderHeightPx } from 'src/components/layout/redesign/header-desktop';
import { amplitudeTrackEvent } from 'src/helpers/amplitude-event-track';

const SCROLL_BELOW_HEADER_BEFORE_HIDE_PX = 100;

const backgroundColorForRibbon = (styling: Styling, color?: string) => {
  switch (color) {
    case PromoRibbonStyle.PLUM:
      return styling.colors.camelot;
    case PromoRibbonStyle.GREEN:
      return styling.colors.darkGreen;
    case PromoRibbonStyle.BLACK:
      return '#000';
    default:
      return styling.colors.camelot;
  }
};

const isEnabledOnPage = (
  promoRibbon: Queries.GlobalHeaderPromoRibbon,
  basePath: string,
  language: Language,
  buildType: BuildType
) => {
  if (!promoRibbon?.enabled || !promoRibbon?.selectedPages) {
    return false;
  }

  if (promoRibbon.selectedPages.allPages) {
    return true;
  }

  const pagesEnabledOn = [
    ...(promoRibbon.selectedPages.homePage ? ['/'] : []),
    ...(promoRibbon.selectedPages.levelOnePages ?? []),
    ...(promoRibbon.selectedPages.levelTwoPages ?? []),
    ...(promoRibbon.selectedPages.levelThreePages ?? []),
  ];

  return pagesEnabledOn.some((link) =>
    isGlobalLinkCurrentPage(link, language, buildType, basePath)
  );
};

const createComponentStyling = (styling: Styling, showChevron: boolean, isExpanded: boolean) =>
  ({
    wrapper: [
      flex.columnCentered,
      mq.withSmallTablet({
        width: '100%',
        padding: ['1.125rem 1.25rem', '1.125rem 2.5rem', '1.125rem 3.75rem'],
      }),
    ],
    container: [
      flex.row,
      {
        width: '100%',
        maxWidth: '92.25rem',
        justifyContent: 'center',
        alignItems: 'start',
        gap: '0.5rem',
      },
    ],
    textWrapper: [
      styling.fonts.bold,
      {
        maxWidth: '100%',
        textWrap: isExpanded ? 'wrap' : 'nowrap',
        color: styling.colors.cream,
        fontSize: '1rem',
        lineHeight: '125%',
        overflow: 'hidden',
        maskImage:
          showChevron && !isExpanded ? 'linear-gradient(90deg, #000 90%, transparent)' : null,
      },
    ],
    link: {
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    expandButton: {
      background: 'unset',
      border: 'unset',
      padding: '0.5rem',
      margin: '-0.5rem',
    },
    chevron: [
      flex.rowCentered,
      mqReducedMotion({
        transition: ['rotate 0.4s', 'none'],
        rotate: isExpanded ? '180deg' : '0deg',
        height: '1.25rem',
      }),
    ],
  }) satisfies Record<string, CSSInterpolation>;

const createAnimatedStyles = (
  componentHeight: number,
  shouldHide: boolean,
  isMobileNavOpen: boolean = false
) =>
  ({
    openCloseWrapper: [
      mq({
        width: '100%',
        transition: [
          isMobileNavOpen || shouldHide ? 'height 0.4s ease, visibility 0.4s' : 'height 0.4s ease',
          shouldHide ? 'height 0.4s ease, visibility 0.4s' : 'height 0.4s ease',
        ],
        visibility: [
          isMobileNavOpen || shouldHide ? 'hidden' : 'visible',
          shouldHide ? 'hidden' : 'visible',
        ],
        overflow: 'hidden',
        height: [
          isMobileNavOpen || shouldHide ? 0 : componentHeight,
          shouldHide ? 0 : componentHeight,
        ],
      }),
      mqReducedMotion({
        transition: [null, 'none'],
      }),
    ],
  }) satisfies Record<string, CSSInterpolation>;

type PromoRibbonProps = {
  isMobileNavOpen?: boolean;
};

const PromoRibbon = ({ isMobileNavOpen }: PromoRibbonProps) => {
  const { headerContent } = useContext(GlobalCmsContent);
  const { styling, language, buildType, basePath, pageLevel } = useContext(SiteContext);

  const [shouldShowExpand, setShowExpand] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [shouldHide, setShouldHide] = useState(false);
  const [hasRecentlyChangedShouldHide, setHasRecentlyChangedShouldHide] = useState(false);

  const [componentHeight, setComponentHeight] = useState(56);

  const componentRef = useRef<HTMLDivElement | null>(null);
  const textAndChevronContainerRef = useRef<HTMLDivElement | null>(null);
  const textRef = useRef<HTMLDivElement | null>(null);
  const chevronRef = useRef<HTMLDivElement | null>(null);

  const updateShouldShowExpand = useCallback(() => {
    if (!textRef.current || !textAndChevronContainerRef.current) {
      return;
    }

    if (!isExpanded) {
      setShowExpand(textRef.current.scrollWidth >= textAndChevronContainerRef.current.offsetWidth);
      return;
    }

    if (!chevronRef.current) {
      return;
    }

    const isTextOnOneLine = textRef.current.scrollHeight <= chevronRef.current.scrollHeight;

    if (isTextOnOneLine) {
      setShowExpand(false);
      setIsExpanded(false);
    }
  }, [isExpanded]);

  const updateShouldHide = useCallback(() => {
    if (typeof window === 'undefined') {
      return;
    }

    setShouldHide(window.scrollY - currentHeaderHeightPx() > SCROLL_BELOW_HEADER_BEFORE_HIDE_PX);
  }, []);

  const updateComponentHeight = useCallback(() => {
    setComponentHeight(componentRef.current?.getBoundingClientRect().height || 56);
  }, []);

  useLayoutEffect(() => {
    updateShouldHide();
    updateShouldShowExpand();
    updateComponentHeight();
  }, [updateShouldHide, updateShouldShowExpand, updateComponentHeight]);

  useEffect(() => {
    setHasRecentlyChangedShouldHide(true);
    setTimeout(() => setHasRecentlyChangedShouldHide(false), 400);
  }, [shouldHide]);

  useEffect(() => {
    const onWindowSizeChange = () => {
      updateShouldShowExpand();
      updateComponentHeight();
    };

    window.addEventListener('resize', onWindowSizeChange);
    window.addEventListener('deviceorientation', onWindowSizeChange);

    return () => {
      window.removeEventListener('resize', onWindowSizeChange);
      window.removeEventListener('deviceorientation', onWindowSizeChange);
    };
  }, [updateShouldShowExpand, updateComponentHeight]);

  useEffect(() => {
    // We don't want to update this value if it is animating as it can cause issues with the scroll position calculation.
    // We do a check when the animation is finished to check if the banner has ended in the correct state (i.e. from
    // the user repeatedly scrolling back and forth)
    if (hasRecentlyChangedShouldHide) {
      return;
    }

    updateShouldHide();
    window.addEventListener('scroll', updateShouldHide);

    return () => {
      window.removeEventListener('scroll', updateShouldHide);
    };
  }, [updateShouldHide, hasRecentlyChangedShouldHide]);

  const promoRibbon = headerContent?.headerPromoRibbon;

  if (!isEnabledOnPage(promoRibbon, basePath, language, buildType) || !promoRibbon?.text) {
    return null;
  }

  const componentStyling = createComponentStyling(styling, shouldShowExpand, isExpanded);
  const animatedStyles = createAnimatedStyles(componentHeight, shouldHide, isMobileNavOpen);

  const handleClick = () =>
    amplitudeTrackEvent(`Click to Promo Ribbon link`, {
      custom_page: window.location.origin,
      custom_level: pageLevel,
    });

  const onClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      css={[
        animatedStyles.openCloseWrapper,
        {
          backgroundColor: backgroundColorForRibbon(styling, promoRibbon.color),
        },
      ]}
    >
      <div css={componentStyling.wrapper} ref={componentRef}>
        <div css={componentStyling.container} ref={textAndChevronContainerRef}>
          <div css={componentStyling.textWrapper} ref={textRef}>
            {promoRibbon.link ? (
              <WebsiteLink
                to={promoRibbon.link}
                css={componentStyling.link}
                handleClick={handleClick}
              >
                {promoRibbon.text}
              </WebsiteLink>
            ) : (
              promoRibbon.text
            )}
          </div>
          {shouldShowExpand && (
            <button type="button" css={componentStyling.expandButton} onClick={onClick}>
              <div css={componentStyling.chevron} ref={chevronRef}>
                <RedesignDownChevron fill={styling.colors.cream} size="1rem" />
              </div>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PromoRibbon;
