import React, { useContext, useReducer } from 'react';
import { flex } from 'src/styling/constants';
import { Styling } from 'src/context/styling';
import mq, { mqReducedMotion } from 'src/styling/media-queries';
import { CSSInterpolation } from '@emotion/serialize';
import { SiteContext } from 'src/context/site-context';
import RedesignDownChevronThin from 'src/components/icons/redesign-down-chevron-thin';
import { BuildType } from 'src/enums/build/build-type';
import { getFooterColors } from 'src/components/layout/footer/redesign/colors';
import AnimatedExpandWrapper from 'src/components/common/animated-expand-wrapper';
import { amplitudeTrackEvent } from 'src/helpers/amplitude-event-track';

const createComponentStyling = (styling: Styling, buildType: BuildType, isOpen: boolean) => {
  const footerColors = getFooterColors(buildType);

  return {
    button: [
      flex.row,
      mq({
        display: [null, 'none'],
        gap: '0.5rem',
        backgroundColor: 'unset',
        border: 'unset',
        borderBottom: '1px solid',
        borderBottomColor: footerColors.border,
        color: 'inherit',
        padding: '0.25rem 0 1rem',
        alignItems: 'center',
        fontSize: '1rem',
        lineHeight: '125%',
        width: '100%',
        cursor: 'pointer',
      }),
      {
        '&:hover div': {
          color: `${footerColors.textHighlight} !important`,
          path: {
            fill: `${footerColors.textHighlight} !important`,
          },
        },
      },
    ],
    chevron: mqReducedMotion({
      marginLeft: 'auto',
      transition: ['rotate 0.4s', 'none'],
      rotate: isOpen ? '180deg' : '0',
    }),
    sectionTitle: [
      styling.fonts.bold,
      mq({
        color: footerColors.heading,
        fontSize: '1rem',
        lineHeight: '125%',
        marginBottom: ['0', '2rem'],
      }),
    ],
    linksWrapper: mq({
      padding: ['1.5rem 0 1.75rem', 0],
      gap: ['1.5rem', '0.5rem'],
    }),
    link: [
      styling.fonts.regular,
      {
        'display': 'inline-block',
        'fontSize': '1rem',
        'lineHeight': '125%',
        '&:hover': {
          color: footerColors.textHighlight,
        },
      },
    ],
  } satisfies Record<string, CSSInterpolation>;
};

type FooterCategoryProps = {
  title: string;
  links: readonly Queries.FooterLink[];
};

const FooterCategory = ({ title, links }: FooterCategoryProps) => {
  const { styling, buildType, pageLevel } = useContext(SiteContext);
  const [isOpen, toggleOpen] = useReducer((isOpen) => !isOpen, false);

  const componentStyling = createComponentStyling(styling, buildType, isOpen);
  const footerColors = getFooterColors(buildType);

  const handleClickButton = () =>
    amplitudeTrackEvent(`Click to ${title} - Footer`, {
      custom_page: window.location.origin,
      custom_level: pageLevel,
    });

  const handleClickLink = (name: string) =>
    amplitudeTrackEvent(`Click to Link - ${name} - Footer`, {
      custom_page: window.location.origin,
      custom_level: pageLevel,
    });

  const buttonClick = () => {
    toggleOpen();
    handleClickButton();
  };

  return (
    <div css={flex.column}>
      <button type="button" css={componentStyling.button} onClick={buttonClick}>
        <div css={componentStyling.sectionTitle}>{title}</div>
        <div css={componentStyling.chevron}>
          <RedesignDownChevronThin size="1.25rem" fill={footerColors.text} />
        </div>
      </button>
      <div css={[componentStyling.sectionTitle, mq({ display: ['none', 'unset'] })]}>{title}</div>
      <AnimatedExpandWrapper desktopAlwaysOpen isExpanded={isOpen}>
        <ul css={[componentStyling.linksWrapper, flex.column]}>
          {links.map(({ text, url, openInNewTab }) => {
            const onClick = () => handleClickLink(text);

            return (
              <li key={text} css={componentStyling.link}>
                <a
                  href={url}
                  target={openInNewTab ? '_blank' : undefined}
                  rel={openInNewTab ? 'noopener noreferrer' : undefined}
                  onClick={onClick}
                >
                  {text}
                </a>
              </li>
            );
          })}
        </ul>
      </AnimatedExpandWrapper>
    </div>
  );
};

export default FooterCategory;
