import facepaint from 'facepaint';

export const breakpoints = {
  maxMobileScreen: '30rem',
  maxSmallTabletScreen: '48rem',
  maxLargeTabletScreen: '60rem',
  maxLargeDesktopScreen: '90rem',
  maxExtraLargeDesktopScreen: '120rem',
};

export const queryForPoint = (bp: string) => `@media screen and (min-width: ${bp})`;

export const screenWidth = {
  smallTablet: queryForPoint(breakpoints.maxMobileScreen),
  largeTablet: queryForPoint(breakpoints.maxSmallTabletScreen),
  desktop: queryForPoint(breakpoints.maxLargeTabletScreen),
  largeDesktop: queryForPoint(breakpoints.maxLargeDesktopScreen),
  extraLargeDesktop: queryForPoint(breakpoints.maxExtraLargeDesktopScreen),
};

const mqDesktop = facepaint([screenWidth.desktop]);
const mqSmallTabletDesktop = facepaint([screenWidth.smallTablet, screenWidth.desktop]);
const mqLargeTabletDesktop = facepaint([screenWidth.largeTablet, screenWidth.desktop]);
const mqLargeDesktop = facepaint([screenWidth.largeDesktop, screenWidth.extraLargeDesktop]);

export const mq = Object.assign(mqDesktop, {
  withSmallTablet: mqSmallTabletDesktop,
  withLargeTablet: mqLargeTabletDesktop,
  withLargeDesktop: mqLargeDesktop,
});

export const mqReducedMotion = facepaint([`@media (prefers-reduced-motion)`]);

export default mq;
