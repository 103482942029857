import { Cookie, getCookieWithKey } from 'src/cookies';
import { userHasConsentedToCookieCategoryWithKey } from 'src/cookies/consent-cookie';
import { BuildType } from 'src/enums/build/build-type';
import { getUrlValueForLanguage, Language } from 'src/enums/language';
import { getPreferredLanguageCookieForBuildType } from '../preferred-language-cookies';

const preferencesConsentCookieCategoryKey = 'preferences';

export const getPreferenceCookieWithKey = (key: string): Cookie => {
  return getCookieWithKey(key, preferencesConsentCookieCategoryKey);
};

export const userHasConsentedToPreferencesCookieCategory = (buildType: BuildType): boolean =>
  userHasConsentedToCookieCategoryWithKey(preferencesConsentCookieCategoryKey, buildType);

export const trySetPreferredLanguageCookie = (buildType: BuildType, language: Language): void => {
  if (language) {
    const cookie = getPreferredLanguageCookieForBuildType(buildType);
    const value = getUrlValueForLanguage(language);
    cookie.trySetForSessionAtBasePath(value, buildType);
  }
};
