import { Styling } from 'src/context/styling';
import React from 'react';
import CloseIcon from 'src/components/icons/close';
import { CSSInterpolation } from '@emotion/serialize';

type CloseButtonProps = {
  styling: Styling;
  handleCloseButtonClick: () => void;
};

const css = (styling: Styling): CSSInterpolation => ({
  width: '1.5rem',
  right: '0.5rem',
  top: '2rem',
  height: '1.5rem',
  position: 'absolute',
  cursor: 'pointer',
  fill: styling.colors.highlightText,
});

export const CloseButton: React.FC<CloseButtonProps> = ({ styling, handleCloseButtonClick }) => (
  <div data-gtmid="cookie-consent-dismiss" css={css(styling)} onClick={handleCloseButtonClick}>
    <CloseIcon fill={styling.colors.highlightText} />
  </div>
);
