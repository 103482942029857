import { StaticTextLookupKey } from '../../src/translators/static-text-lookup';
import { Language } from '../../src/enums/language';
import { BuildType } from '../../src/enums/build/build-type';

export enum Country {
  BELGIUM = 'Belgium',
  FRANCE = 'France',
  GERMANY = 'Germany',
  IRELAND = 'Ireland',
  ITALY = 'Italy',
  NETHERLANDS = 'Netherlands',
  SPAIN = 'Spain',
  SWITZERLAND = 'Switzerland',
  UNITED_KINGDOM = 'United Kingdom',
}

export type CountriesToSplitIntoRegions = Country.UNITED_KINGDOM;

export const getTranslationKeyForCountry = (country: Country): StaticTextLookupKey => {
  switch (country) {
    case Country.BELGIUM:
      return 'countryBelgium';
    case Country.FRANCE:
      return 'countryFrance';
    case Country.GERMANY:
      return 'countryGermany';
    case Country.IRELAND:
      return 'countryIreland';
    case Country.ITALY:
      return 'countryItaly';
    case Country.NETHERLANDS:
      return 'countryNetherlands';
    case Country.SPAIN:
      return 'countrySpain';
    case Country.SWITZERLAND:
      return 'countrySwitzerland';
    case Country.UNITED_KINGDOM:
      return 'countryUnitedKingdom';
    default:
      throw new Error('Unsupported country');
  }
};

export const getDefaultBuildTypeForCountry = (country: Country): BuildType => {
  switch (country) {
    case Country.BELGIUM:
      return BuildType.DAVID_LLOYD_BELGIUM;
    case Country.FRANCE:
      return BuildType.DAVID_LLOYD_FRANCE;
    case Country.GERMANY:
      return BuildType.DAVID_LLOYD_GERMANY;
    case Country.IRELAND:
      return BuildType.DAVID_LLOYD_IRELAND;
    case Country.ITALY:
      return BuildType.DAVID_LLOYD_ITALY;
    case Country.NETHERLANDS:
      return BuildType.DAVID_LLOYD_NETHERLANDS;
    case Country.SPAIN:
      return BuildType.DAVID_LLOYD_SPAIN;
    case Country.SWITZERLAND:
      return BuildType.DAVID_LLOYD_SWITZERLAND;
    case Country.UNITED_KINGDOM:
      return BuildType.DAVID_LLOYD_UK;
  }
};

export const getCountryByLanguage = (language: Language): Country => {
  switch (language) {
    case Language.EN_BE:
    case Language.FR_BE:
    case Language.NL_BE:
      return Country.BELGIUM;
    case Language.DE_DE:
    case Language.EN_DE:
      return Country.GERMANY;
    case Language.EN_ES:
    case Language.ES_ES:
    case Language.CA_ES:
      return Country.SPAIN;
    case Language.EN_IE:
      return Country.IRELAND;
    case Language.EN_IT:
    case Language.IT_IT:
      return Country.ITALY;
    case Language.EN_NL:
    case Language.NL_NL:
      return Country.NETHERLANDS;
    case Language.FR_FR:
    case Language.EN_FR:
      return Country.FRANCE;
    case Language.FR_CH:
    case Language.EN_CH:
      return Country.SWITZERLAND;
    default:
      return Country.UNITED_KINGDOM;
  }
};
