import React from 'react';
import { IconProps } from './icon-types';

const Globe = ({ fill, svgStyle }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={svgStyle}
  >
    <path
      d="M11.625 0.375C18.0469 0.375 23.25 5.57812 23.25 12C23.25 18.4219 18.0469 23.625 11.625 23.625C5.20312 23.625 0 18.4219 0 12C0 5.57812 5.20312 0.375 11.625 0.375ZM20.6719 7.5C19.4531 5.10938 17.3438 3.28125 14.7656 2.39062C15.6562 3.65625 16.3594 5.4375 16.7812 7.5H20.6719ZM15.75 12C15.75 10.9688 15.6562 9.9375 15.5156 9H7.6875C7.54688 9.9375 7.5 10.9688 7.5 12C7.5 13.0781 7.54688 14.1094 7.6875 15H15.5156C15.6562 14.1094 15.75 13.0781 15.75 12ZM11.625 1.875C10.3594 1.875 8.71875 3.98438 7.92188 7.5H15.2812C14.4844 3.98438 12.8438 1.875 11.625 1.875ZM8.4375 2.39062C5.85938 3.28125 3.75 5.10938 2.53125 7.5H6.42188C6.84375 5.4375 7.54688 3.65625 8.4375 2.39062ZM1.5 12C1.5 13.0781 1.64062 14.0625 1.92188 15H6.1875C6.04688 14.0625 6 13.0781 6 12C6 10.9688 6.04688 9.98438 6.1875 9H1.92188C1.64062 9.98438 1.5 10.9688 1.5 12ZM2.53125 16.5C3.75 18.9375 5.85938 20.7656 8.4375 21.6562C7.54688 20.3906 6.84375 18.6094 6.42188 16.5H2.53125ZM11.625 22.125C12.8438 22.125 14.4844 20.0625 15.2812 16.5H7.92188C8.71875 20.0625 10.3594 22.125 11.625 22.125ZM14.7656 21.6562C17.3438 20.7656 19.4531 18.9375 20.6719 16.5H16.7812C16.3594 18.6094 15.6562 20.3906 14.7656 21.6562ZM17.0156 15H21.2812C21.5625 14.0625 21.75 13.0781 21.75 12C21.75 10.9688 21.5625 9.98438 21.2812 9H17.0156C17.1562 9.98438 17.25 10.9688 17.25 12C17.25 13.0781 17.1562 14.0625 17.0156 15Z"
      fill={fill}
    />
  </svg>
);

export default Globe;
