import React, { useContext } from 'react';
import { GlobalCmsContent } from 'src/content/global-content';
import { Styling } from 'src/context/styling';
import { CSSInterpolation } from '@emotion/serialize';
import { SiteContext } from 'src/context/site-context';
import { ButtonStyle, RedesignButtonStyle } from 'src/enums/button-style';
import { getNewButtonCss } from 'src/components/facilities/buttonCss';
import LinkButton from 'src/components/facilities/link-button';
import { flex, shadow } from 'src/styling/constants';
import { mqReducedMotion } from 'src/styling/media-queries';
import { amplitudeTrackEvent } from 'src/helpers/amplitude-event-track';

const createComponentStyling = (styling: Styling) =>
  ({
    wrapper: {
      'position': 'relative',
      'padding': '1.25rem',
      'gap': '1rem',
      'backgroundColor': styling.colors.cream,
      'alignItems': 'center',
      '& a': {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        maxWidth: '30rem',
      },
    },
    button: [
      {
        justifyContent: 'center',
        width: '100%',
        maxWidth: '30rem',
      },
    ],
  }) satisfies Record<string, CSSInterpolation>;

const createAnimationStyles = (isMenuOpen: boolean): CSSInterpolation =>
  mqReducedMotion({
    transition: ['top 0.4s ease-in-out', 'none'],
    top: [isMenuOpen ? 0 : '100%', 0],
  });

type HeaderMobileBottomNavProps = {
  isMenuOpen: boolean;
  openClubSearch: () => void;
  onClickLink?: () => void;
};

const HeaderMobileBottomNav = ({
  isMenuOpen,
  openClubSearch,
  onClickLink,
}: HeaderMobileBottomNavProps) => {
  const { headerContent } = useContext(GlobalCmsContent);
  const { styling, pageLevel } = useContext(SiteContext);
  const componentStyling = createComponentStyling(styling);
  const searchSettings = headerContent.searchSettings;

  if (!headerContent?.headerButton || !searchSettings) {
    return null;
  }

  const handleClickJoinClubButton = () => {
    onClickLink?.();
    return amplitudeTrackEvent('Click to Join Club', {
      custom_page: window.location.origin,
      custom_level: pageLevel,
    });
  };

  const handleClickFindClubButton = () =>
    amplitudeTrackEvent('Click to "Find a club" - Navigation', {
      custom_page: window.location.origin,
      custom_level: pageLevel,
    });

  return (
    <div
      css={[componentStyling.wrapper, flex.column, shadow.above, createAnimationStyles(isMenuOpen)]}
    >
      {headerContent.headerButton && (
        <LinkButton
          {...headerContent.headerButton}
          buttonStyle={headerContent.headerButton.buttonStyle as ButtonStyle}
          buttonType="grid-button"
          gtmTrackingId="header-button"
          customButtonStyle={[
            getNewButtonCss(headerContent.headerButton.buttonStyle as RedesignButtonStyle, styling),
            componentStyling.button,
          ]}
          iconView={headerContent.headerButton.buttonIcon}
          handleClick={handleClickJoinClubButton}
          isRedesignButton
        />
      )}
      {!!searchSettings && (
        <button
          type="button"
          css={[getNewButtonCss(RedesignButtonStyle.TRANSPARENT, styling), componentStyling.button]}
          onClick={() => {
            handleClickFindClubButton();
            openClubSearch();
          }}
        >
          {searchSettings.openSearchButtonName}
        </button>
      )}
    </div>
  );
};

export default HeaderMobileBottomNav;
