import { Language } from '../enums/language';
import { linksLookup as enBeLinksLookup } from './links/en-be';
import { linksLookup as frBeLinksLookup } from './links/fr-be';
import { linksLookup as nlBeLinksLookup } from './links/nl-be';
import { linksLookup as deDeLinksLookup } from './links/de-de';
import { linksLookup as enDeLinksLookup } from './links/en-de';
import { linksLookup as enEsLinksLookup } from './links/en-es';
import { linksLookup as caEsLinksLookup } from './links/ca-es';
import { linksLookup as esEsLinksLookup } from './links/es-es';
import { linksLookup as enGbLinksLookup } from './links/en-gb';
import { linksLookup as enIeLinksLookup } from './links/en-ie';
import { linksLookup as enItLinksLookup } from './links/en-it';
import { linksLookup as itItLinksLookup } from './links/it-it';
import { linksLookup as enNlLinksLookup } from './links/en-nl';
import { linksLookup as nlNlLinksLookup } from './links/nl-nl';
import { linksLookup as enFrLinksLookup } from './links/en-fr';
import { linksLookup as frFrLinksLookup } from './links/fr-fr';
import { linksLookup as frChLinksLookup } from './links/fr-ch';
import { linksLookup as enChLinksLookup } from './links/en-ch';
import { trimPath } from '../helpers/path-matching-helper';

export const linksLookupByLanguage = {
  [Language.EN_BE]: enBeLinksLookup,
  [Language.FR_BE]: frBeLinksLookup,
  [Language.NL_BE]: nlBeLinksLookup,

  [Language.DE_DE]: deDeLinksLookup,
  [Language.EN_DE]: enDeLinksLookup,

  [Language.EN_ES]: enEsLinksLookup,
  [Language.CA_ES]: caEsLinksLookup,
  [Language.ES_ES]: esEsLinksLookup,

  [Language.EN_GB]: enGbLinksLookup,

  [Language.EN_IE]: enIeLinksLookup,

  [Language.EN_IT]: enItLinksLookup,
  [Language.IT_IT]: itItLinksLookup,

  [Language.EN_NL]: enNlLinksLookup,
  [Language.NL_NL]: nlNlLinksLookup,

  [Language.EN_FR]: enFrLinksLookup,
  [Language.FR_FR]: frFrLinksLookup,

  [Language.FR_CH]: frChLinksLookup,
  [Language.EN_CH]: enChLinksLookup,
};

export const translateLinkIntoLanguage = (key: string, language: Language): string => {
  const linkInCurrentLanguage = (linksLookupByLanguage[language] as any)[key];

  if (linkInCurrentLanguage) {
    return linkInCurrentLanguage;
  }

  return key;
};

export const translatePath = (path: string, language: Language): string => {
  const trimmedPath = trimPath(path);
  return trimmedPath
    .split('/')
    .filter((token) => token !== '')
    .map((token: string) => translateLinkIntoLanguage(token, language))
    .reduce((newPath: string, token: string) => `${newPath}${token}/`, '/');
};

const suggestLinkTranslationFromLanguage = (
  pathToken: string,
  sourceLanguage: Language
): string => {
  const translations = linksLookupByLanguage[sourceLanguage];

  const matchingTranslations = Object.entries(translations).filter(
    ([, translation]) => translation === pathToken
  );

  if (matchingTranslations.length > 0) {
    return matchingTranslations[0][0];
  }

  return pathToken;
};

export const suggestPathTranslation = (
  path: string,
  sourceLanguage: Language,
  targetLanguage: Language
): string => {
  const trimmedPath = trimPath(path);

  const englishPath = trimmedPath
    .split('/')
    .filter((token) => token !== '')
    .map((token: string) => suggestLinkTranslationFromLanguage(token, sourceLanguage))
    .reduce((newPath: string, token: string) => `${newPath}${token}/`, '/');

  return translatePath(englishPath, targetLanguage);
};
