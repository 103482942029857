import { getDisplayTextForLanguage, Language } from '../enums/language';
import { getClubInformation } from '../content/club-look-ups-by-id';
import {
  BuildType,
  getAllLanguagesForBuildType,
  getOrderedLanguagesForBuildType,
} from '../enums/build/build-type';

export function isDefaultLanguageForClub(clubId: string, language: Language): boolean {
  return getClubDefaultLanguage(clubId) === language;
}

export function getClubDefaultLanguage(clubId: string): Language {
  const buildType = getClubInformation(clubId).buildType;
  return getOrderedLanguagesForBuildType(buildType).default;
}

export function getBestLanguageMatch(
  language: Language,
  buildType: BuildType,
  languagesOverride?: Language[]
): Language {
  const languagesForBuildType = getAllLanguagesForBuildType(buildType);
  const countryIndependentLanguage = getDisplayTextForLanguage(language);
  const defaultLanguage = getOrderedLanguagesForBuildType(buildType).default;

  if (!!languagesOverride?.length) {
    // If the language override language is the language requested, use that
    if (languagesOverride.some((overrideLanguage) => overrideLanguage === language)) {
      return language;
    }

    // If the language override is a variant of the language requested, use that
    for (let languageOverride of languagesOverride) {
      if (countryIndependentLanguage === getDisplayTextForLanguage(languageOverride)) {
        return languageOverride;
      }
    }

    // If the language override is the default for the build type, use that
    if (languagesOverride.some((overrideLanguage) => overrideLanguage === defaultLanguage)) {
      return defaultLanguage;
    }

    return languagesOverride[0];
  }

  // If the build type supports the exact language requested, use that
  if (languagesForBuildType.some((buildLanguage) => buildLanguage === language)) {
    return language;
  }

  // If the build type supports a variant of the language requested, use that
  for (let buildLanguage of languagesForBuildType) {
    if (countryIndependentLanguage === getDisplayTextForLanguage(buildLanguage)) {
      return buildLanguage;
    }
  }

  // Use the default language for the build type
  return getOrderedLanguagesForBuildType(buildType).default;
}
