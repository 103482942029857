import React from 'react';
import { Icon } from './icon-types';
import { colors } from 'src/styling/constants';

const Location: Icon = ({ fill = colors.darkGreen }) => (
  <svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.38281 19.0039C0.964844 11.2109 0 10.3945 0 7.5C0 3.56641 3.1543 0.375 7.125 0.375C11.0586 0.375 14.25 3.56641 14.25 7.5C14.25 10.3945 13.248 11.2109 7.83008 19.0039C7.49609 19.5234 6.7168 19.5234 6.38281 19.0039ZM7.125 10.4688C8.75781 10.4688 10.0938 9.16992 10.0938 7.5C10.0938 5.86719 8.75781 4.53125 7.125 4.53125C5.45508 4.53125 4.15625 5.86719 4.15625 7.5C4.15625 9.16992 5.45508 10.4688 7.125 10.4688Z"
      fill={fill}
    />
  </svg>
);

export default Location;
