import * as React from 'react';

import { GlobalCmsProvider } from './cms-provider/global-cms-provider';
import { GlobalCmsContent } from './global-content';

export const GlobalContentProvider = ({ children }: { children: React.ReactNode }) => (
  <GlobalCmsProvider>
    {(cmsContent) => {
      return <GlobalCmsContent.Provider value={cmsContent}>{children}</GlobalCmsContent.Provider>;
    }}
  </GlobalCmsProvider>
);

export default GlobalContentProvider;
