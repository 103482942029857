import { ClubInformation } from '../../enums/club-information';
import { Country } from '../../enums/country';
import { BuildType } from '../../enums/build/build-type';

export const compareClubsByClubNameAscending = (
  leftClub: ClubInformation,
  rightClub: ClubInformation
) => leftClub.clubName.localeCompare(rightClub.clubName);

export const compareCountryByActiveCountryAscending =
  (activeCountry: Country) => (leftCountry: Country, rightCountry: Country) => {
    {
      if (leftCountry === activeCountry) return -1;
      if (rightCountry === activeCountry) return 1;

      return leftCountry.localeCompare(rightCountry);
    }
  };

export const sortClubsInDisplayOrderForBuildType = (
  clubs: ClubInformation[],
  buildType: BuildType
) => {
  return buildType === BuildType.MERIDIAN
    ? sortClubsByClubNameAscendingWithHamburgClubsFirst(clubs)
    : clubs.sort(compareClubsByClubNameAscending);
};

const sortClubsByClubNameAscendingWithHamburgClubsFirst = (clubs: ClubInformation[]) => {
  const isHamburgClub = (club: ClubInformation) => club.clubName.toLowerCase().includes('hamburg');
  const sortedHamburgClubs = clubs
    .filter((club: ClubInformation) => isHamburgClub(club))
    .sort(compareClubsByClubNameAscending);
  const sortedNonHamburgClubs = clubs
    .filter((club: ClubInformation) => !isHamburgClub(club))
    .sort(compareClubsByClubNameAscending);

  return [...sortedHamburgClubs, ...sortedNonHamburgClubs];
};
