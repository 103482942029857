import { LinksLookup } from 'src/translators/links-lookup';

// When modifying an existing entry here, you might need to add an NGINX redirect from the old URL to the new one,
// in spain.template
export const linksLookup: LinksLookup = {
  'book-a-tour': 'reserva-tu-visita',
  'club-facilities': 'nuestras-instalaciones',
  'club-treatments': 'club-treatments',
  'club-fitness': 'club-fitness',
  'creche': 'guarderia',
  'exercise-classes': 'entrena-con-nosotros',
  'gym': 'gimnasio',
  'sports': 'sports',
  'kids-classes': 'clases-para-ninos',
  'children': 'children',
  'pools': 'piscinas',
  'spa': 'spa',
  'tennis': 'tenis',
  'enquire-now': 'visitanos-ya',
  'family-and-kids': 'familia',
  'golf': 'padel',
  'join-now': 'apuntate-ya',
  'membership-trial': 'cuota-de-prueba',
  'relax-and-socialise': 'relajate',
  'timetable': 'horarios',
  'yoga': 'yoga',
  'blaze': 'blaze',
  'personal-training': 'entrenamientos-personales',
  'join': 'inscribete',
  // If this link is changed, we should also update the links stored in CC
  // E.g. see DLLABS-10292
  'join-online': 'inscribete-online',
  'treatments': 'face-body-spa',
  'global-enquire-now': 'visitanos-ya',
  'global-enquire-now-campaign-1': 'campaign-1',
  'global-enquire-now-campaign-2': 'campaign-2',
  'global-enquire-now-campaign-3': 'campaign-3',
  'referral': 'referencia',
  'friend': 'amigo',
  'memberships': 'membresias',
  'terms-and-conditions': 'terminos-y-condiciones',
  'policy': 'politica',
  'gyms-in-barcelona': 'gimnasios-en-barcelona',
  'gyms-in-madrid': 'gimnasios-en-madrid',
};
