import { useCallback, useEffect } from 'react';

const DESKTOP_ONLY_SCROLL_CLASS = 'desktop-scroll-only';

export const useDocumentDesktopOnlyScroll = () => {
  const setDocumentDesktopOnlyScroll = useCallback((isDesktopOnly: boolean) => {
    if (typeof document === 'undefined') {
      return;
    }

    if (isDesktopOnly) {
      document.documentElement?.classList.add(DESKTOP_ONLY_SCROLL_CLASS);
    } else {
      document.documentElement?.classList.remove(DESKTOP_ONLY_SCROLL_CLASS);
    }
  }, []);

  useEffect(() => {
    setDocumentDesktopOnlyScroll(false);

    return () => {
      setDocumentDesktopOnlyScroll(false);
    };
  }, [setDocumentDesktopOnlyScroll]);

  return setDocumentDesktopOnlyScroll;
};
