import { BuildType } from 'src/enums/build/build-type';

export const getMembersLoginUrlForBuildType = (buildType: BuildType): string => {
  switch (buildType) {
    case BuildType.MERIDIAN:
      return 'https://azrapponline01.azurewebsites.net/login';
    default:
      return '/account/login/';
  }
};

export const shouldApplyMembersLoginButton = (
  isGlobalPage: boolean,
  isBlaze: boolean,
  buildType: BuildType
) => {
  return (
    !isBlaze &&
    !(isGlobalPage && buildType === BuildType.DAVID_LLOYD_GERMANY) &&
    buildType !== BuildType.DAVID_LLOYD_SWITZERLAND
  );
};
