import { Language } from 'src/enums/language';
import { navPageLinkMatchesTranslatedUrl } from './desktop-nav/desktop-subnav-menu-links';

export const clubPageDropdownHeaderList = [
  'club-facilities',
  'club-treatments',
  'club-fitness',
  'sports',
  'children',
];

export type ClubPageTypeProps = 'treatmentsMenu' | 'fitnessMenu' | 'sportsMenu' | 'childrenMenu';

export const applyClubPageType = (linkFromCms: string, language: Language): ClubPageTypeProps => {
  if (navPageLinkMatchesTranslatedUrl('club-treatments', linkFromCms, language)) {
    return 'treatmentsMenu';
  } else if (navPageLinkMatchesTranslatedUrl('club-fitness', linkFromCms, language)) {
    return 'fitnessMenu';
  } else if (navPageLinkMatchesTranslatedUrl('sports', linkFromCms, language)) {
    return 'sportsMenu';
  } else if (navPageLinkMatchesTranslatedUrl('children', linkFromCms, language)) {
    return 'childrenMenu';
  }
};

export const clubPageTypeHeaderContent = (clubPageType: ClubPageTypeProps) => {
  switch (clubPageType) {
    case 'treatmentsMenu':
      return {
        childOfClubPageLink: '/treatments',
        seeAllLink: 'treatments',
        seeAllTitleLookupKey: 'treatmentsOverview',
      };
    case 'fitnessMenu':
      return {
        childOfClubPageLink: '/club-fitness',
        seeAllLink: 'club-fitness',
        seeAllTitleLookupKey: 'fitnessOverview',
      };
    case 'sportsMenu':
      return {
        childOfClubPageLink: '/sports',
        seeAllLink: 'sports',
        seeAllTitleLookupKey: 'sportsOverview',
      };
    case 'childrenMenu':
      return {
        childOfClubPageLink: '/children',
        seeAllLink: 'children',
        seeAllTitleLookupKey: 'childrenOverview',
      };
    default:
      return {
        childOfClubPageLink: '/club-facilities',
        seeAllLink: 'club-facilities',
        seeAllTitleLookupKey: 'seeAllFacilities',
      };
  }
};
