import React from 'react';
import { SiteContext, SiteConfig } from 'src/context/site-context';

const SiteConsumer = ({
  children,
}: {
  children: ({
    siteId,
    language,
    buildType,
    styling,
    basePath,
    untranslatedPath,
    seo,
  }: SiteConfig) => React.ReactNode;
}) => (
  <SiteContext.Consumer>
    {({
      siteId,
      language,
      buildType,
      isGlobalPage,
      styling,
      basePath,
      untranslatedPath,
      isMeridian,
      isBlaze,
      isHarbour,
      isDL,
      pageLevel,
      seo,
    }) =>
      children({
        siteId,
        language,
        buildType,
        isGlobalPage,
        styling,
        basePath,
        untranslatedPath,
        isMeridian,
        isBlaze,
        isHarbour,
        isDL,
        pageLevel,
        seo,
      })
    }
  </SiteContext.Consumer>
);

export default SiteConsumer;
