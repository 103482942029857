import { navigate } from 'gatsby';
import { getLanguageUrlPrefixForBuildType, BuildType } from '../enums/build/build-type';
import { getClubInformation } from '../content/club-look-ups-by-id';
import { translatePath } from 'src/translators/links-translator';
import { Language } from 'src/enums/language';
import { updateUrlPreservingQueryParams } from 'src/helpers/query-string-helper';
import { getSiteBaseUrl } from './config-helper';
import { getBestLanguageMatch } from './language-helper';
import { trimPath } from 'src/helpers/path-matching-helper';

export function navigateToGlobalPage(
  path: string,
  language: Language,
  buildType: BuildType,
  state?: any
) {
  const globalPagePath: string = getGlobalPagePath(path, language, buildType);
  navigate(updateUrlPreservingQueryParams(globalPagePath), { state: state });
}

export function navigateToInternalClubPage(
  path: string,
  siteId: string,
  language: Language,
  options?: { state?: any; excludedQueryParams?: string[] }
) {
  const clubPagePath: string = getClubPagePath(path, siteId, language);
  navigate(
    updateUrlPreservingQueryParams(clubPagePath, {
      excludedQueryParams: options?.excludedQueryParams,
    }),
    { state: options?.state }
  );
}

export function navigateToExternalPage(url: string) {
  window.location.href = url;
}

export function navigateToExternalPageWithoutHistory(url: string) {
  document.location.replace(url);
}

export const preparePath = (path: string) => path.replace(/\/\/\//g, '/').replace(/\/\//g, '/');

export function getClubPagePath(basePath: string, siteId: string, language: Language) {
  const {
    buildType,
    languagesOverride,
    hyphenatedClubName: clubNameUrl,
  } = getClubInformation(siteId);
  const bestMatchLanguage = getBestLanguageMatch(language, buildType, languagesOverride);

  const translatedBasePath = translatePath(basePath, bestMatchLanguage);
  const languagePrefix = getLanguageUrlPrefixForBuildType(bestMatchLanguage, buildType);
  const path = `/${languagePrefix}/clubs/${clubNameUrl}/${translatedBasePath}/`;

  return preparePath(path);
}

export function getAbsoluteClubPagePath(basePath: string, siteId: string, language: Language) {
  const { buildType } = getClubInformation(siteId);
  const clubPagePath = getClubPagePath(basePath, siteId, language);
  const siteBase = getSiteBaseUrl(buildType);

  return `${siteBase}${clubPagePath}`;
}

export function getGlobalPagePath(basePath: string, language: Language, buildType: BuildType) {
  const translatedBasePath = translatePath(basePath, language);
  const languagePrefix = getLanguageUrlPrefixForBuildType(language, buildType);
  const path = `/${languagePrefix}/${translatedBasePath}/`;

  return preparePath(path);
}

export const getSiteUrlForPath = (buildType: BuildType, path: string) => {
  const baseUrl = trimPath(getSiteBaseUrl(buildType));
  const normalisedPath = preparePath(`/${trimPath(path)}/`);

  return `${baseUrl}${normalisedPath}`;
};

export function openPageInNewTab(url: string) {
  window.open(url);
}

export function removeQueryParamsFromUrl(queryParams: string[]) {
  if (typeof window === 'undefined') {
    return;
  }

  const newPath = updateUrlPreservingQueryParams(window.location.pathname, {
    excludedQueryParams: queryParams,
  });
  navigate(newPath, { replace: true, state: window.history.state });
}

export const navigateToSalesApp = () => {
  navigateToExternalPageWithoutHistory(process.env.GATSBY_SALES_APP_URL);
};

export const isGlobalLinkCurrentPage = (
  link: string,
  language: Language,
  buildType: BuildType,
  basePath: string
) => {
  return getGlobalPagePath(link, language, buildType) === translatePath(basePath, language);
};
