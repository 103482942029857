import moment, { Moment } from 'moment';

export const reactDatePickerDateFormat = 'MM-DD-YYYY';
export const standardDateFormat = 'YYYY-MM-DD';
export const shortUIDateFormat = 'DD MMM';
export const UIDateFormat = 'ddd DD MMM';
export const longUIDateFormat = 'dddd DD MMMM';
export const hasEnquiredCookieDateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
export const monthUIDateFormat = 'DD MMM YYYY';

export const daysInAWeek = 7;

export type Weekday =
  | 'MONDAY'
  | 'TUESDAY'
  | 'WEDNESDAY'
  | 'THURSDAY'
  | 'FRIDAY'
  | 'SATURDAY'
  | 'SUNDAY';
export const IsoWeekday: Record<Weekday, number> = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7,
};

export function timestampToMoment(timestamp: { seconds: number }) {
  return moment.unix(timestamp.seconds);
}

export function isToday(date: Moment | Date) {
  return moment(date).isSame(moment(), 'd');
}

export function isTomorrow(date: Moment | Date) {
  return moment(date).isSame(moment().add(1, 'day'), 'd');
}

export function getDaysBetweenDates(fromDate: Moment, toDate: Moment): Moment[] {
  const dates: Moment[] = [fromDate];
  const currentDay = fromDate.clone();
  while (toDate.clone().startOf('day').diff(currentDay.add(1, 'day').startOf('day'), 'days') >= 0) {
    dates.push(currentDay.clone());
  }
  return dates;
}

export function getFullWeekDates(fromDate: Moment, toDate: Moment): Moment[] {
  const firstMonday = getPreviousDateOfWeekday(fromDate, IsoWeekday.MONDAY);
  const finalSunday = getNextDateOfWeekday(toDate, IsoWeekday.SUNDAY);

  return getDaysBetweenDates(firstMonday, finalSunday);
}

function getPreviousDateOfWeekday(fromDate: Moment, isoWeekday: number): Moment {
  const dateThisWeek = getDateOfWeekdayInWeek(fromDate, isoWeekday);

  return dateThisWeek.isoWeekday() > fromDate.isoWeekday()
    ? dateThisWeek.subtract(1, 'week')
    : dateThisWeek;
}

function getNextDateOfWeekday(fromDate: Moment, isoWeekday: number): Moment {
  const dateThisWeek = getDateOfWeekdayInWeek(fromDate, isoWeekday);

  return dateThisWeek.isoWeekday() < fromDate.isoWeekday()
    ? dateThisWeek.add(1, 'week')
    : dateThisWeek;
}

function getDateOfWeekdayInWeek(fromDate: Moment, isoWeekday: number): Moment {
  if (fromDate.isoWeekday() === isoWeekday) {
    return fromDate;
  }

  return fromDate
    .clone()
    .startOf('isoWeek')
    .add(isoWeekday - 1, 'days');
}

export function getFullNameOfDay(day: string): string {
  return moment(day, 'ddd').format('dddd');
}
