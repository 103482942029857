import { Icon } from 'src/components/icons/icon-types';
import React from 'react';

const Preview: Icon = ({ svgStyle, fill }) => (
  <svg
    width="28"
    height="18"
    viewBox="0 0 28 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={svgStyle}
  >
    <path
      d="M27.3125 8.34375C27.4062 8.53125 27.4531 8.76562 27.4531 9.04688C27.4531 9.28125 27.4062 9.51562 27.3125 9.70312C24.7812 14.6719 19.7188 18 14 18C8.23438 18 3.17188 14.6719 0.640625 9.70312C0.546875 9.51562 0.5 9.28125 0.5 9C0.5 8.76562 0.546875 8.53125 0.640625 8.34375C3.17188 3.375 8.23438 0 14 0C19.7188 0 24.7812 3.375 27.3125 8.34375ZM14 15.75V15.7969C17.7031 15.7969 20.75 12.75 20.75 9.04688V9C20.75 5.29688 17.7031 2.25 14 2.25C10.25 2.25 7.25 5.29688 7.25 9C7.25 12.75 10.25 15.75 14 15.75ZM14 4.5V4.54688C16.4844 4.54688 18.5 6.51562 18.5 9C18.5 11.4844 16.4844 13.5 14 13.5C11.5156 13.5 9.5 11.4844 9.5 9C9.5 8.625 9.54688 8.20312 9.64062 7.82812C10.0156 8.10938 10.4844 8.25 11 8.25C12.2188 8.25 13.2031 7.26562 13.2031 6.04688C13.2031 5.53125 13.0625 5.0625 12.7812 4.6875C13.1562 4.59375 13.5781 4.54688 14 4.5Z"
      fill={fill}
    />
  </svg>
);
export default Preview;
