import { CSSInterpolation } from '@emotion/serialize';
import mq from './media-queries';
import { Styling } from 'src/context/styling';

// Identity function with types that infer the correct keys of our object, but explicitly specify that the
// values are CSS styles (Interpolation is Emotion's CSS style type)
const stylesObject = <T>(x: { [k in keyof T]: CSSInterpolation }) => x;

// Values
const davidLloydBrown = '#53342C';
const davidLloydGray = '#F6F6F6';
const davidLloydPurple = '#AF2C8A';
const davidLloydSecondaryPurple = '#881E68';
const davidLloydTransparentPurple = '#fac8ff70';
const davidLloydTeal = '#008578';
const clubFinderHoverPurple = `#F9F4F8`;
const comingSoonPurple = '#B0538C';
const comingSoonBorderPurple = '#984284';
const clubFinderPrimaryGreen = '#81D0BD';
const clubFinderSecondaryGreen = '#488C7A';
const clubFinderTextGrey = '#645751';
const clubFinderSearchGrey = '#858585';
const borderGrey = '#CCCCCC';
const formTextElementBorderGrey = borderGrey;
const greyedOutBackground = '#777';
const bronze = '#53342C';
const buttonDisabledColor = '#E7C0DC';
const membershipSummaryDarkGray = '#2F2F2F';
const mediumGray = '#E8E8E9';
const errorMessageText = '#C8363A';
const errorMessageBackground = '#FDEDED';
const blazeOrange = '#FC440F';
const blazeGray = '#808080';
const blazeIconBackgroundGray = '#232323';
const copyrightGray = '#AF9E9A';
const blazeDividerGray = '#414141';
const lightGray = '#EEE';
const silver = '#A3A3A3';
const lightSilver = '#AAA';
const beige = '#A99995';
const harbourSlateGrey = '#323E48';
const fadedHarbourSlateGrey = '#EAEBEC';
const harbourGold = '#BD9B60';
const harbourDarkGold = '#9E814F';
const harbourHeaderBorder = '#4B4130';
const fadedHarbourGold = '#DDC499';
const alert = '#C41E3D';
const highlightGold = '#E1B20C';
const paleRed = '#D56B66';
const meridianBody = '#0B365C';
const meridianBackgroundLight = '#FBFDFE';
const meridianSecondaryBackground = '#FFF';
const meridianHighlight = '#FF5A1F';
const meridianSecondaryHighlight = '#FCD9BD';
const meridianPopupSecondaryActionBackground = 'white';
const meridianPopupSecondaryActionBorder = '#FCD9BD';
const meridianDivider = '#E2E8F0';
const meridianLightGray = '#64748B';
const meridianCoolGray = '#F1F5F9';
const meridianDarkCoolGray = '#CFD8E3';
const meridianFieryYellow = '#FCB564';
const meridianSlateGrey = '#323E48';
const meridianLightGrayFooter = '#27303f';
const meridianDarkGrayFooter = '#1a202e';
const paleGrey = '#e8e8e9';
const meridianHighlightGrey = '#364152';
const payByLinkBlockGrey = '#f7f7f7';
const beigeGrey = '#d4ccca';
const disabledBackground = '#D9D9D9';
const disabledText = '#8D8D8D';
const cardBackground = '#faf9f7';
const introText = '#2E282C';
const darkGreen = '#23494B';
const pineGlade = '#c2c282';
const camelot = '#82285f';
const vibrant = '#AF1172';
const outerSpace = '#223536';
const blackHaze = '#e9ecec';
const capeCod = '#474a4a';
const edward = '#a1a9a9';
const ash = '#c4c5b2';
const grayOlive = '#aaaa91';
const transparentGrayOlive = 'rgba(170, 170, 145, 0.30)';
const greenSmoke = '#b2b47b';
const transparentGreenGrey = 'rgba(71, 74, 74, 0.20)';
const hessianBackground = '#EEEEE9';
const cream = '#FCFCF6';
const transparentGreenGreyBackground = '#474A4A1A';
const brightGrey = '#ededed';
const brightYellow = '#FFE500';
const sageBackground = '#EEF1E9';
const defaultPromoTextColor = 'white';

export const colors = {
  alert,
  ash,
  beige,
  beigeGrey,
  blackHaze,
  blazeDividerGray,
  blazeGray,
  blazeIconBackgroundGray,
  blazeOrange,
  borderGrey,
  bronze,
  buttonDisabled: buttonDisabledColor,
  camelot,
  capeCod,
  cardBackground,
  clubFinderHoverPurple,
  clubFinderPrimaryGreen,
  clubFinderSearchGrey,
  clubFinderSecondaryGreen,
  clubFinderTextGrey,
  comingSoonBorderPurple,
  comingSoonPurple,
  copyrightGray,
  cream,
  darkGreen,
  davidLloydBrown,
  davidLloydGray,
  davidLloydPurple,
  davidLloydSecondaryPurple,
  davidLloydTeal,
  davidLloydTransparentPurple,
  disabledBackground,
  disabledText,
  defaultPromoTextColor,
  edward,
  errorMessageBackground,
  errorMessageText,
  fadedHarbourGold,
  fadedHarbourSlateGrey,
  formTextElementBorderGrey,
  grayOlive,
  transparentGrayOlive,
  transparentGreenGrey,
  greenSmoke,
  greyedOutBackground,
  harbourDarkGold,
  harbourGold,
  harbourHeaderBorder,
  harbourSlateGrey,
  hessianBackground,
  highlightGold,
  introText,
  lightGray,
  lightSilver,
  mediumGray,
  membershipSummaryDarkGray,
  meridianBackgroundLight,
  meridianBody,
  meridianCoolGray,
  meridianDarkCoolGray,
  meridianDarkGrayFooter,
  meridianDivider,
  meridianFieryYellow,
  meridianHighlight,
  meridianHighlightGrey,
  meridianLightGray,
  meridianLightGrayFooter,
  meridianSecondaryBackground,
  meridianSecondaryHighlight,
  meridianPopupSecondaryActionBackground,
  meridianPopupSecondaryActionBorder,
  meridianSlateGrey,
  outerSpace,
  paleGrey,
  paleRed,
  payByLinkBlockGrey,
  pineGlade,
  silver,
  transparentGreenGreyBackground,
  brightGrey,
  vibrant,
  brightYellow,
  sageBackground,
};

export const spacing = {
  none: 0,
  extraSmall: '.25rem',
  small: '.5rem',
  medium: '1rem',
  large: '2rem',
  extraLarge: '4rem',
  extraExtraLarge: '8rem',
  extraExtraExtraLarge: '16rem',
};

export const calculateDesktopGridWidth = (parentColumns: number, childColumns: number) => {
  const gutterWidth = 24;
  const columnWidth = 88;

  const childWidth = childColumns * columnWidth + (childColumns - 1) * gutterWidth;
  const parentWidth = parentColumns * columnWidth + (parentColumns - 1) * gutterWidth;

  return (100 * childWidth) / parentWidth + '%';
};

export const fontSizes = {
  extraSmall: '.25rem',
  small: '.5rem',
  medium: '1rem',
  large: '2rem',
  extraLarge: '4rem',
};

export const zIndexes = {
  dropShadow: 1,
  pageNavigation: 2,
  dropdown: 5,
  breadcrumb: 6,
  overlay: 10,
  sliderOverlay: 10,
  clubFacilitiesHeader: 20,
  cookies: 40,
  header: 50,
  popup: 55,
  mobileNav: 60,
  popupCloseButton: 99,

  wholePageOverlay: 9999,
};

export const borderRadius = {
  button: '0.2rem',
  roundedCorner: '.25rem',
  basicButton: '0.1875rem',
  redesignButton: '3.75rem',
  meridianButton: '0.3rem',
  container: '0.3rem',
  accordion: '0.625rem', // 10px
  narrowImage: '0.87056rem',
  navigationBlock: '1.67656rem',
  image: {
    small: '0.375rem', // 6px
    medium: '0.75rem', // 12px
    large: '1.5rem', // 24px
  },
};

// Styles
export const roundedCorners = {
  borderRadius: borderRadius.roundedCorner,
};

export const maxPageWidth = mq({
  maxWidth: [null, '70rem'],
  paddingLeft: [null, '1rem'],
  paddingRight: [null, '1rem'],
  marginLeft: [null, 'auto'],
  marginRight: [null, 'auto'],
});

export type Font = {
  fontFamily: string;
  fontWeight: number;
};

export const fonts: { [key: string]: Font } = {
  regular: {
    fontFamily: 'Neuzeit Grotesk, Helvetica Neue, Helvetica, Arial, sans-serif',
    fontWeight: 400,
  },
  light: {
    fontFamily: 'Neuzeit Grotesk, Helvetica Neue, Helvetica, Arial, sans-serif',
    fontWeight: 300,
  },
  bold: {
    fontFamily: 'Neuzeit Grotesk, Helvetica Neue, Helvetica, Arial, sans-serif',
    fontWeight: 700,
  },
  blaze: {
    fontFamily: 'Akziden Grotesk BQ Bold Italic Blaze, Akziden Grotesk Bold Italic',
    fontWeight: 500,
  },
  blazeHeader: {
    fontFamily: 'Akziden Grotesk Bold Italic',
    fontWeight: 500,
  },
  harbour: {
    fontFamily: 'ITC Avant Garde Pro Bk',
    fontWeight: 500,
  },
  harbourBold: {
    fontFamily: 'ITC Avant Garde Pro Demi',
    fontWeight: 500,
  },
  harbourHeader: {
    fontFamily: 'ITC Avant Garde Pro Bk',
    fontWeight: 500,
  },
  meridianLight: {
    fontFamily: 'MuseoSans-300',
    fontWeight: 300,
  },
  meridian: {
    fontFamily: 'MuseoSans-500',
    fontWeight: 500,
  },
  meridianBold: {
    fontFamily: 'MuseoSans-700',
    fontWeight: 700,
  },
};

const buttonBase: CSSInterpolation = {
  ...fonts.bold,
  border: 'none',
  borderRadius: borderRadius.basicButton,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  color: 'white',
  backgroundColor: davidLloydPurple,
  transition: 'background-color 0.2s ease',
  cursor: 'pointer',
};

export const button = {
  base: buttonBase,
  secondary: {
    ...buttonBase,
    border: `${borderRadius.basicButton} solid ${davidLloydPurple}`,
    color: davidLloydPurple,
    backgroundColor: 'white',
  },
  disabled: {
    ...buttonBase,
    backgroundColor: buttonDisabledColor,
    borderColor: 'transparent',
    color: 'white',
    cursor: 'auto',
  },
  blaze: {
    ...buttonBase,
    backgroundColor: blazeOrange,
    borderRadius: '1.68rem',
  },
  transparent: {
    ...buttonBase,
    'color': 'white',
    'backgroundColor': 'transparent',
    'border': `${borderRadius.basicButton} solid ${colors.davidLloydPurple}`,
    '&:hover': {
      color: 'white',
      backgroundColor: colors.davidLloydPurple,
      border: `${borderRadius.basicButton} solid ${colors.davidLloydSecondaryPurple}`,
    },
  },
};

export const flex = stylesObject({
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  columnCentered: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  columnSpacedOut: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  rowCentered: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowSpacedOut: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  centerDisplay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export const absolute = {
  fullSize: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
  } as CSSInterpolation,
};

export const formFieldHeightInRem = 3.35;
export const formFieldHeight = `${formFieldHeightInRem}rem`;

const defaultShadow = '0 10px 35px 0 rgba(0, 0, 0, 0.1), 0 4px 7px 0 rgba(0, 0, 0, 0.04)';
export const shadow = {
  regular: {
    boxShadow: defaultShadow,
  },
  regularDesktop: mq({
    boxShadow: ['none', defaultShadow],
  }),
  below: {
    boxShadow: '0px 10px 20px 0px rgba(0, 0, 0, 0.05)',
  },
  above: {
    boxShadow: '0px -10px 20px 0px rgba(0, 0, 0, 0.05)',
  },
  around: {
    boxShadow: '4px 4px 20px 1px rgba(0, 0, 0, 0.2)',
  },
  headerBelow: mq({
    boxShadow: ['none', '0 5px 10px 0 rgba(0, 0, 0, 0.1)'],
  }),
  small: {
    boxShadow: '0 5px 20px 0 rgba(0, 0, 0, 0.1), 0 4px 7px 0 rgba(0, 0, 0, 0.04)',
  },
};

export const errorMessageStyling = (styling: Styling): CSSInterpolation => [
  maxPageWidth,
  styling.fonts.regular,
  {
    padding: '1rem',
    margin: '2rem auto',
    lineHeight: 1.5,
    fontSize: '1rem',
    textAlign: 'center',
    color: styling.colors.bodyText,
  },
];

export const styleInvalidSubmitButton = (styling: CSSInterpolation) => ({
  ':invalid': {
    'button[type=submit]': styling,
  },
});

export const clubFacilitiesMobileNavHeight = '2.6rem';

export const mainNavHeight = '4.5rem';
export const globalBannerHeight = {
  mobile: '4rem',
  default: '4.5rem',
};

// We reduce the height of sticky items to handle cases where the browser renderer causes an extra
// pixel spacing above the sticky item to appear
export const STACKED_STICKY_ITEM_HEIGHT_REDUCTION = 1;
