import { TranslatedStaticText } from 'src/translators/static-text-translator';
import React from 'react';
import { Styling } from 'src/context/styling';
import { CSSInterpolation } from '@emotion/serialize';
import mq from 'src/styling/media-queries';

type TitleProps = {
  styling: Styling;
};

const css = (styling: Styling): CSSInterpolation =>
  mq([
    styling.fonts.bold,
    {
      padding: '0.5rem 0',
      color: styling.colors.bodyText,
    },
  ]);

export const Title: React.FC<TitleProps> = ({ styling }) => (
  <div css={css(styling)}>
    <TranslatedStaticText lookupKey="cookiesPolicy" />
  </div>
);
