import React, { useContext } from 'react';
import { flex, colors } from 'src/styling/constants';
import { Styling } from 'src/context/styling';
import { useToggle } from 'src/custom-hooks/use-toggle';
import HeaderChangeClub from '../header-change-club';
import HeaderClubSelect from '../header-club-select';
import { getBuildDomainForBuildType, newWebsiteBuildDomains } from 'src/enums/build/build-domain';
import { SiteContext } from 'src/context/site-context';

type MobileNavClubProps = {
  styling: Styling;
  clubName?: string;
  onFinish?: () => void;
};

const MobileNavClub: React.FC<MobileNavClubProps> = ({ styling, clubName, onFinish }) => {
  const [clubSelectExpanded, setClubSelectExpanded, , hideClubSelect] = useToggle(false);
  const { buildType } = useContext(SiteContext);
  const isNewDesign = newWebsiteBuildDomains.includes(getBuildDomainForBuildType(buildType));
  const handleFinish = () => {
    onFinish?.();
    hideClubSelect();
  };

  return (
    <div css={{ width: '100%', padding: '0 1.5rem' }}>
      <div
        css={[
          flex.row,
          {
            flexShrink: 0,
            border: `1px solid ${colors.lightGray}`,
            color: styling.colors.highlightText,
            margin: '1rem 0',
            fontSize: '1rem',
            padding: '1rem',
          },
        ]}
      >
        {!!clubName && (
          <div
            css={[
              styling.fonts.bold,
              {
                flex: 1,
                color: styling.colors.headerClubName,
                fontSize: '1.2rem',
              },
            ]}
          >
            {clubName}
          </div>
        )}
        <div
          css={[
            styling.fonts.regular,
            styling.common.link,
            isNewDesign ? { color: styling.colors.capeCod } : {},
          ]}
        >
          <HeaderChangeClub
            isClubSelectDropdownVisible={clubSelectExpanded}
            toggleClubSelectDropdown={setClubSelectExpanded}
          />
        </div>
      </div>

      {clubSelectExpanded && <HeaderClubSelect onFinish={handleFinish} />}
    </div>
  );
};

export default MobileNavClub;
