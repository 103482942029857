import { useContext, useEffect, useState } from 'react';
import {
  FEATURE_FLAG,
  getFeatureFlagValue,
  getFeatureFlagValueAtClub,
} from 'src/helpers/feature-flag-helper';
import { SiteContext } from 'src/context/site-context';
import { BuildType } from 'src/enums/build/build-type';

const usePuzzelLiveChatFeatureFlag = (): boolean => {
  const [shouldUsePuzzelLiveChat, setShouldUsePuzzelLiveChat] = useState<boolean>(null);

  const { buildType, isGlobalPage, siteId } = useContext(SiteContext);

  useEffect(() => {
    const getPuzzelFeatureFlagValue = () => {
      return isGlobalPage
        ? getFeatureFlagValue(FEATURE_FLAG.PUZZEL_LIVE_CHAT_INSTEAD_OF_TAWK)
        : getFeatureFlagValueAtClub(siteId, FEATURE_FLAG.PUZZEL_LIVE_CHAT_INSTEAD_OF_TAWK);
    };

    if (isGlobalPage && buildType !== BuildType.DAVID_LLOYD_UK) {
      setShouldUsePuzzelLiveChat(false);
    } else {
      getPuzzelFeatureFlagValue()
        .then((usePuzzelLiveChat) => {
          setShouldUsePuzzelLiveChat(usePuzzelLiveChat);
        })
        .catch(() => setShouldUsePuzzelLiveChat(false));
    }
  }, [buildType, isGlobalPage, siteId]);

  return shouldUsePuzzelLiveChat;
};

export default usePuzzelLiveChatFeatureFlag;
