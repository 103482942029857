import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { useMemo } from 'react';

const clubMetadataQuery = graphql`
  query ClubMetadataQuery {
    site {
      siteMetadata {
        clubMetadata {
          clubId
          clubName
          clubUrlName
          clubDataRepository
        }
      }
    }
  }
`;

export type ClubMetadata = {
  clubId: string;
  clubName: string;
  clubUrlName: string;
  clubDataRepository: string;
};

export const MetadataProvider = ({
  clubId,
  children,
}: {
  clubId: string;
  children: (clubMetadata: ClubMetadata) => React.ReactNode;
}) => {
  const { site } = useStaticQuery<Queries.ClubMetadataQueryQuery>(clubMetadataQuery);

  const clubMetadata = useMemo(() => {
    return site.siteMetadata.clubMetadata.find((club) => club.clubId === clubId);
  }, [clubId, site.siteMetadata.clubMetadata]);

  return children(clubMetadata);
};

export default MetadataProvider;
