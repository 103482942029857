import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';

const useSafeState = <T>(initialState: T): [T, Dispatch<SetStateAction<T>>] => {
  const [state, setState] = useState<T>(initialState);
  const ref = useRef<boolean>(true);

  useEffect(() => {
    return () => {
      ref.current = false;
    };
  }, [ref]);

  const setSafeState = useCallback((newState: SetStateAction<T>) => {
    if (ref.current) {
      setState(newState);
    }
  }, []);

  return [state, setSafeState];
};

export default useSafeState;
