import React, { useContext, useRef, FC } from 'react';
import { CSSInterpolation } from '@emotion/serialize';
import { SiteContext } from 'src/context/site-context';
import { ClubSearchInput } from 'src/components/common/club-search-input';
import { getNewButtonCss } from 'src/components/facilities/buttonCss';
import { RedesignButtonStyle } from 'src/enums/button-style';
import { flex } from 'src/styling/constants';
import { getButtonIcon } from 'src/components/facilities/link-button';
import { RedesignButtonIcon } from 'src/enums/button-icons';
import { useClubSearchForm } from 'src/custom-hooks/use-club-search-form';
import { GlobalCmsContent } from 'src/content/global-content';

const createComponentStyling = () =>
  ({
    wrapper: [
      flex.rowCentered,
      { flex: '1', gap: '0.75rem', paddingTop: '3.75rem', height: 'fit-content' },
    ],
  }) satisfies Record<string, CSSInterpolation>;

const ClubSearch: FC<{ isNavigation?: boolean; onFinish?: () => void }> = ({
  isNavigation,
  onFinish,
}) => {
  const inputRef = useRef(null);
  const { styling } = useContext(SiteContext);
  const { headerContent } = useContext(GlobalCmsContent);
  const componentStyling = createComponentStyling();
  const buttonIcon = getButtonIcon(RedesignButtonIcon.SEARCH);
  const handleSubmit = useClubSearchForm(inputRef, true, onFinish);

  return (
    <form onSubmit={handleSubmit} css={componentStyling.wrapper}>
      <ClubSearchInput
        inputRef={inputRef}
        inputPlaceholder={headerContent.searchSettings.searchPlaceholderDesktop}
        pinnedSearchOptionText={headerContent.searchSettings.desktopPinnedSearchOptionText}
        isNavigation={isNavigation}
        onFinish={onFinish}
      />
      <button type="submit" css={getNewButtonCss(RedesignButtonStyle.PLUM, styling)}>
        {headerContent.searchSettings.openSearchButtonName}
        {buttonIcon}
      </button>
    </form>
  );
};

export default ClubSearch;
