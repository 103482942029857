import React, { useContext } from 'react';
import { SiteContext } from 'src/context/site-context';
import { getMembersLoginUrlForBuildType } from 'src/helpers/members-login-helper';
import mq from 'src/styling/media-queries';
import { TranslatedStaticText } from 'src/translators/static-text-translator';
import { getBuildDomainForBuildType, newWebsiteBuildDomains } from 'src/enums/build/build-domain';

export const MembersLoginButton: React.FC<any> = () => {
  const { styling, buildType } = useContext(SiteContext);
  const isNewDesign = newWebsiteBuildDomains.includes(getBuildDomainForBuildType(buildType));

  return (
    <div
      css={mq({
        paddingTop: ['.75rem', 0],
        marginBottom: ['2rem', 0],
      })}
    >
      <a
        css={mq({
          ...[styling.fonts.bold, styling.fonts.regular],
          color: isNewDesign ? styling.colors.capeCod : styling.colors.highlightText,
          textDecoration: ['underline', 'initial'],
        })}
        href={getMembersLoginUrlForBuildType(buildType)}
        target="_blank"
        rel="noopener noreferrer"
      >
        <TranslatedStaticText lookupKey="membersLogin" />
      </a>
    </div>
  );
};
