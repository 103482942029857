import React from 'react';
import mq from 'src/styling/media-queries';
import FooterImagesSection from 'src/components/layout/footer/footer-images-section';
import { FooterImagesSectionContent } from 'src/models/cms/footer-content';

type FooterImagesProps = {
  sections: FooterImagesSectionContent[];
};

const footerImagesContainer = mq({
  position: 'relative',
  display: 'block',
});

const footerImagesList = mq({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: ['column', 'row'],
  width: '100%',
  padding: '0.5rem 2rem',
  flexWrap: 'wrap',
});

const FooterImages: React.FC<FooterImagesProps> = ({ sections }) => {
  return (
    <div css={footerImagesContainer}>
      <ul css={footerImagesList}>
        {sections?.map((section, index) => (
          // using the index as the key because sections are never reordered/inserted/deleted dynamically
          <FooterImagesSection key={index} title={section.title} images={section.images} />
        ))}
      </ul>
    </div>
  );
};

export default FooterImages;
