import { TranslatedStaticText } from 'src/translators/static-text-translator';
import React from 'react';
import { Styling } from 'src/context/styling';

type LearnMoreLinkProps = {
  styling: Styling;
};

const LearnMoreLink: React.FC<LearnMoreLinkProps> = ({ styling }) => (
  <a
    css={[styling.common.link, { paddingLeft: '0.5rem' }]}
    href="/policy/cookie-policy"
    target="_blank"
    rel="noopener noreferrer"
    data-gtmid="cookies-learn-more"
  >
    <TranslatedStaticText lookupKey="learnMore" />.
  </a>
);

type BodyProps = {
  styling: Styling;
};

export const Body: React.FC<BodyProps> = ({ styling }) => (
  <div>
    <TranslatedStaticText lookupKey="cookiePolicyDescription" />
    <LearnMoreLink styling={styling} />
  </div>
);
