import React from 'react';
import { Language } from 'src/enums/language';
import { TranslatedStaticText } from 'src/translators/static-text-translator';
import { translateLinkIntoLanguage } from 'src/translators/links-translator';
import {
  EnabledPage,
  EnabledPages,
  isInClubFacilitiesSection,
} from 'src/content/enabled-pages-helper';
import { getEnabledPageOrDefault, isDisabledPage } from 'src/content/optional-page-helper';
import { ClubFacilitiesPage } from 'src/models/cms/navigation-menu-content';
import { trimPath } from 'src/helpers/path-matching-helper';

export const FACILITIES_URL_PATH = '/club-facilities/';

export type ClubFacilityKeyWithVideo = {
  facility: keyof typeof facilities;
  videoId: string;
};

export type FacilityDetails = {
  pageName: string;
  urlPath: string;
  displayName: React.ReactNode;
  cmsKey: string;
};

export type FacilityDetailsWithVideo = FacilityDetails & { videoId: string };

export const facilities = {
  spa: {
    pageName: 'clubFacilities_spaPage',
    urlPath: 'spa',
    displayName: <TranslatedStaticText lookupKey="spa" />,
    cmsKey: 'spa',
  } as FacilityDetails,
  pool: {
    pageName: 'clubFacilities_poolsPage',
    urlPath: 'pools',
    displayName: <TranslatedStaticText lookupKey="pools" />,
    cmsKey: 'pools',
  } as FacilityDetails,
  exerciseClasses: {
    pageName: 'clubFacilities_exerciseClassesPage',
    urlPath: 'exercise-classes',
    displayName: <TranslatedStaticText lookupKey="exerciseClasses" />,
    cmsKey: 'exerciseClasses',
  } as FacilityDetails,
  gym: {
    pageName: 'clubFacilities_gymPage',
    urlPath: 'gym',
    displayName: <TranslatedStaticText lookupKey="gym" />,
    cmsKey: 'gym',
  } as FacilityDetails,
  tennis: {
    pageName: 'clubFacilities_tennisPage',
    urlPath: 'tennis',
    displayName: <TranslatedStaticText lookupKey="tennis" />,
    cmsKey: 'tennis',
  } as FacilityDetails,
  padel: {
    pageName: 'clubFacilities_padelPage',
    urlPath: 'padel',
    displayName: <TranslatedStaticText lookupKey="padel" />,
    cmsKey: 'padel',
  } as FacilityDetails,
  creche: {
    pageName: 'clubFacilities_crechePage',
    urlPath: 'creche',
    displayName: <TranslatedStaticText lookupKey="creche" />,
    cmsKey: 'creche',
  } as FacilityDetails,
  kidsClasses: {
    pageName: 'clubFacilities_kidsClassesPage',
    urlPath: 'kids-classes',
    displayName: <TranslatedStaticText lookupKey="kidsClub" />,
    cmsKey: 'kidsClasses',
  } as FacilityDetails,
};

export const getFacilitiesAtClub = (
  clubFacilities: ClubFacilityKeyWithVideo[],
  enabledPages: EnabledPages,
  clubFacilitiesPages: ClubFacilitiesPage[],
  facilityTypeToHide?: FacilityDetails
) => {
  return clubFacilities
    .filter((facility) => !!facility)
    .map((clubFacility) => {
      return {
        ...facilities[clubFacility.facility],
        videoId: clubFacility.videoId,
      };
    })
    .filter((facility) => {
      // Making sure we've found an associated facility
      if (!facility.pageName) return false;

      const shouldShowFacilityPage = facility !== facilityTypeToHide;
      const isFacilityPageEnabled = isEnabledFacility(enabledPages, facility);
      const isFacilityPageLinkedTo = clubFacilitiesPages.find((clubFacilitiesPage) =>
        // This needs to be "includes" rather than an "===" because we call it "pools" in the
        // facilities object above, but "pool" in the cms key
        facility.cmsKey.includes(clubFacilitiesPage.facility)
      );
      const isFacilityPageInClubFacilitiesSection = isInClubFacilities(enabledPages, facility);

      return (
        isFacilityPageLinkedTo &&
        shouldShowFacilityPage &&
        isFacilityPageEnabled &&
        isFacilityPageInClubFacilitiesSection
      );
    });
};

export function getUrlPathToClubFacility(facility?: FacilityDetails): string {
  const partialClubFacilityUrl = facility ? `${facility.urlPath}/` : '';
  return FACILITIES_URL_PATH + partialClubFacilityUrl;
}

function getFacilityUrlPath(urlPath: string, language: Language): string {
  const facilityUrlPath = `/${translateLinkIntoLanguage(trimPath(FACILITIES_URL_PATH), language)}/`;
  if (urlPath.startsWith(facilityUrlPath)) {
    const partialUrl = urlPath.slice(facilityUrlPath.length);
    return trimPath(partialUrl);
  }
  return null;
}

export function findFacilityFromUrlPath(urlPath: string, language: Language): FacilityDetails {
  const facilityUrlPath: string = getFacilityUrlPath(urlPath, language);
  return Object.values(facilities).find(
    (facilityDetails) =>
      translateLinkIntoLanguage(facilityDetails.urlPath, language) === facilityUrlPath
  );
}

export const isEnabledFacility = (enabledPages: EnabledPages, facility: FacilityDetails): boolean =>
  !isDisabledPage(getEnabledPageForFacility(enabledPages, facility));

export const isDisabledFacility = (
  enabledPages: EnabledPages,
  facility: FacilityDetails
): boolean => !isEnabledFacility(enabledPages, facility);

export const isInClubFacilities = (
  enabledPages: EnabledPages,
  facility: FacilityDetails
): boolean => isInClubFacilitiesSection(getEnabledPageForFacility(enabledPages, facility));

const getEnabledPageForFacility = (
  enabledPages: EnabledPages,
  facility: FacilityDetails
): EnabledPage => getEnabledPageOrDefault(enabledPages, facility.pageName);

export default facilities;
