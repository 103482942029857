import React, { useContext } from 'react';

import MarkdownToHtmlBlock from '../common/markdown-to-html-block';
import LinkButton from '../facilities/link-button';
import { flex, globalBannerHeight } from 'src/styling/constants';
import mq from 'src/styling/media-queries';
import { HeaderBannerContent } from 'src/models/cms/common';
import { SiteContext } from 'src/context/site-context';
import { getBuildButtonStyle } from 'src/helpers/styling';

type HeaderBannerProps = {
  content: HeaderBannerContent;
};

const HeaderBanner: React.FC<HeaderBannerProps> = ({ content }) => {
  const { buildType } = useContext(SiteContext);
  if (!content) {
    return null;
  }

  const button = content.button;

  return (
    <div
      css={mq([
        flex.rowCentered,
        {
          backgroundColor: content.bannerBackgroundColor,
          maxHeight: [globalBannerHeight.mobile, globalBannerHeight.default],
          minHeight: [globalBannerHeight.mobile, globalBannerHeight.default],
          padding: ['1rem 0.5rem', '1rem 2rem'],
        },
      ])}
    >
      <div css={mq({ flex: 1, fontSize: ['1rem', '1.25rem'], p: { margin: 0 } })}>
        <MarkdownToHtmlBlock markdownText={content.text} colorOverride={content.bannerTextColor} />
      </div>

      {button.showButton && (
        <div css={{ padding: '0.25rem' }}>
          <LinkButton
            buttonText={button.buttonText}
            buttonLink={button.buttonLink}
            buttonType="button-header"
            buttonStyle={getBuildButtonStyle(buildType, button)}
            goToGlobal={button.goToGlobal}
          />
        </div>
      )}
    </div>
  );
};

export default HeaderBanner;
