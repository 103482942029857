import React, { useContext, useRef, useState } from 'react';
import { CSSInterpolation } from '@emotion/serialize';
import { SiteContext } from 'src/context/site-context';
import { getNewButtonCss } from 'src/components/facilities/buttonCss';
import { RedesignButtonStyle } from 'src/enums/button-style';
import { flex } from 'src/styling/constants';
import { useClubSearchForm, useClubSearch } from 'src/custom-hooks/use-club-search-form';
import { GlobalCmsContent } from 'src/content/global-content';
import Search from 'src/components/icons/search-icon';
import Cross from 'src/components/icons/cross';
import { WebsiteLink } from 'src/components/common/website-link';
import { Styling } from 'src/context/styling';
import HeaderMobileSubMenuWrapper from './header-sub-menu-wrapper';
import MarkdownToHtmlBlock from 'src/components/common/markdown-to-html-block';
import { amplitudeTrackEvent } from 'src/helpers/amplitude-event-track';
import { CLUBS_LINK } from 'src/enums/clubs';
import Map from 'src/components/icons/map';
import mq from 'src/styling/media-queries';
import { ClubSearchLink } from 'src/components/common/club-search-input';

const createComponentStyling = (styling: Styling) =>
  ({
    title: [
      styling.fonts.regular,
      {
        fontSize: '2.5rem',
        lineHeight: '3rem',
        margin: '4rem 0 1.5rem 1.875rem',
      },
    ],
    text: [
      styling.fonts.regular,
      {
        fontSize: '1rem',
        lineHeight: '125%',
        margin: '0 2.5rem 1.5rem 1.875rem',
      },
    ],
    formContainer: [flex.column, { flex: '1', paddingTop: '1.88rem' }],
    wrapper: [
      flex.rowCentered,
      {
        width: '100%',
        gap: '0.75rem',
      },
    ],
    inputWrapper: [
      flex.row,
      {
        position: 'relative',
        flex: 1,
      },
    ],
    closeButton: [
      flex.rowCentered,
      {
        cursor: 'pointer',
        position: 'absolute',
        right: '0.75rem',
        height: '100%',
      },
    ],
    searchIcon: [flex.rowCentered, { position: 'absolute', left: '0.75rem', height: '100%' }],
    seachButton: { paddingLeft: '1rem', paddingRight: '1rem' },
    dropdown: {
      'margin': '1rem -1.25rem 0',
      'width': 'calc(100% + 2.5rem)',
      'textAlign': 'left',
      '> a:nth-of-type(even)': {
        backgroundColor: styling.colors.davidLloydGray,
      },
    },
    dropdownItem: {
      cursor: 'pointer',
      gap: '1rem',
      padding: '1rem 1.5rem',
      fontSize: '1.1875rem',
      lineHeight: '145%',
      minHeight: '3.75rem',
    },
    viewMapButton: mq({
      flexWrap: 'nowrap',
      position: 'sticky',
      top: '0',
      display: ['flex', 'none'],
      gap: '1rem',
      fontSize: '1.1875rem',
      padding: '1rem 1.5rem',
      zIndex: '1',
      lineHeight: '145%',
      backgroundColor: styling.colors.cream,
      marginBottom: '0.5rem',
    }),
    dropdownItemHover: {
      '&:hover': {
        'color': styling.colors.camelot,
        'textDecoration': 'underline',
        '> svg > path': {
          fill: styling.colors.camelot,
        },
      },
    },
  }) satisfies Record<string, CSSInterpolation>;

type HeaderClubSearchProps = {
  isMenuOpen: boolean;
  isVisible: boolean;
  openClubSearch?: () => void;
  onFinish?: () => void;
};

const HeaderClubSearch = (props: HeaderClubSearchProps) => {
  const inputRef = useRef(null);
  const { styling, pageLevel } = useContext(SiteContext);
  const { headerContent } = useContext(GlobalCmsContent);
  const [searchValue, setSearchValue] = useState<string>('');
  const componentStyling = createComponentStyling(styling);
  const handleSubmit = useClubSearchForm(inputRef, true, props.onFinish);
  const filteredClubs = useClubSearch(searchValue);

  if (!headerContent.searchSettings) {
    return null;
  }

  const handleInputFocus = () =>
    amplitudeTrackEvent(`Focus to Club List in the "Find a club" - Navigation`, {
      custom_page: window.location.origin,
      custom_level: pageLevel,
    });

  const handleClickViewMap = () => {
    props.onFinish?.();
    return amplitudeTrackEvent(`Click to View Map in the "Find a club" - Navigation`, {
      custom_page: window.location.origin,
      custom_level: pageLevel,
    });
  };

  const viewMapLink = (
    <WebsiteLink
      to={CLUBS_LINK}
      css={[flex.rowCentered, componentStyling.viewMapButton, componentStyling.dropdownItemHover]}
      handleClick={handleClickViewMap}
    >
      <>
        <Map />
        {headerContent.searchSettings.mobilePinnedSearchOptionText}
      </>
    </WebsiteLink>
  );

  return (
    <HeaderMobileSubMenuWrapper
      isMenuOpen={props.isMenuOpen}
      isVisible={props.isVisible}
      isChildMenuVisible={false}
      openClubSearch={props.openClubSearch}
      onClickLink={props.onFinish}
    >
      <div>
        <div css={componentStyling.title}>{headerContent.searchSettings.title}</div>
        <MarkdownToHtmlBlock
          markdownText={headerContent.searchSettings.description}
          colorOverride={styling.colors.capeCod}
          cssOverride={componentStyling.text}
        />
      </div>

      <form onSubmit={handleSubmit} css={componentStyling.formContainer}>
        <div css={componentStyling.wrapper}>
          <div css={componentStyling.inputWrapper}>
            {searchValue ? (
              <div
                tabIndex={0}
                onClick={() => {
                  setSearchValue('');
                }}
                css={componentStyling.closeButton}
              >
                <Cross />
              </div>
            ) : null}
            <div css={componentStyling.searchIcon}>
              <Search fill={styling.colors.capeCod} />
            </div>
            <input
              ref={inputRef}
              value={searchValue}
              onChange={(e) => {
                const { value } = e.target;
                setSearchValue(value);
              }}
              name="club-search"
              autoComplete="off"
              placeholder={headerContent.searchSettings.searchPlaceholderMobile}
              css={[styling.redesignForms.input, { paddingLeft: '2.4375rem' }]}
              onFocus={() => handleInputFocus()}
            />
          </div>
          <button
            className="redesign-button search plum"
            type="submit"
            css={[getNewButtonCss(RedesignButtonStyle.PLUM, styling), componentStyling.seachButton]}
          >
            {headerContent.searchSettings.mobileSearchButtonName}
          </button>
        </div>
        <div css={componentStyling.dropdown}>
          {searchValue && viewMapLink}
          {searchValue &&
            filteredClubs.map((club) => (
              <ClubSearchLink
                key={club.siteId}
                styles={[
                  flex.rowCentered,
                  componentStyling.dropdownItem,
                  componentStyling.dropdownItemHover,
                ]}
                club={club}
                onClick={props.onFinish}
              />
            ))}
        </div>
      </form>
    </HeaderMobileSubMenuWrapper>
  );
};

export default HeaderClubSearch;
