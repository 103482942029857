import React, { PropsWithChildren, useEffect } from 'react';
import { DEFAULT_CLUB_ID } from 'src/content/constants';
import { SiteContext } from 'src/context/site-context';
import { getStyling } from 'src/context/styling/index';
import { AlternatePage, PageContext, PageLayoutType } from 'src/components/common/page-context';
import { getBuildTypesFlags } from './site-context-helper';
import { trySetPreferredLanguageCookie } from 'src/cookies/cookie-types/preference-cookies';
import {
  DesignRolloutStatus,
  getBuildDomainForBuildType,
  getDesignRolloutStatusForBuildDomain,
} from 'src/enums/build/build-domain';

type SiteProviderProps = PropsWithChildren<{
  pageContext: PageContext;
}>;

const isPageMigrated = (page: AlternatePage) =>
  getDesignRolloutStatusForBuildDomain(getBuildDomainForBuildType(page.buildType)) !==
  DesignRolloutStatus.SITECORE_ONLY;

const SiteProvider: React.FC<SiteProviderProps> = (props) => {
  const {
    isGlobalPage,
    buildType,
    language,
    pageLanguages,
    basePath,
    untranslatedPath,
    seo,
    pageLayout,
  } = props.pageContext;

  const siteId = props.pageContext.clubId || DEFAULT_CLUB_ID;
  const alternatePages = (props.pageContext.alternatePages ?? []).filter(isPageMigrated);
  const styling = getStyling(buildType);
  const pageLevel = pageLayout?.type === PageLayoutType.global ? pageLayout.pageLevel : undefined;
  const { isMeridian, isBlaze, isHarbour, isDL } = getBuildTypesFlags(buildType);

  useEffect(() => {
    trySetPreferredLanguageCookie(buildType, language);
  }, [buildType, language]);

  return (
    <SiteContext.Provider
      value={{
        siteId,
        isGlobalPage,
        language,
        pageLanguages,
        alternatePages,
        buildType,
        isMeridian,
        isBlaze,
        isHarbour,
        isDL,
        pageLevel,
        styling,
        basePath,
        untranslatedPath,
        seo,
      }}
    >
      {props.children}
    </SiteContext.Provider>
  );
};

export default SiteProvider;
