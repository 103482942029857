import React, { useContext } from 'react';
import { getClubsLinkedToBuildType } from 'src/content/club-look-ups-by-id';
import { SiteContext } from 'src/context/site-context';
import { BuildType, shouldUseInternalClubPickerForBuildType } from 'src/enums/build/build-type';
import { TranslatedStaticText } from 'src/translators/static-text-translator';
import { Link } from 'gatsby';
import { CSSInterpolation } from '@emotion/serialize';

type HeaderChangeClubProps = {
  isClubSelectDropdownVisible: boolean;
  toggleClubSelectDropdown: (expanded: boolean) => void;
  styles?: CSSInterpolation;
};

const HeaderChangeClub: React.FC<HeaderChangeClubProps> = ({
  isClubSelectDropdownVisible,
  toggleClubSelectDropdown,
  styles,
}) => {
  const { buildType } = useContext(SiteContext);
  const showClubSelectionDropdown = () => toggleClubSelectDropdown(!isClubSelectDropdownVisible);
  const ChangeClubText = () => <TranslatedStaticText lookupKey="changeClub" />;
  const shouldChangeClubHeaderBeShown = (buildType: BuildType) => {
    return getClubsLinkedToBuildType(buildType).length > 1;
  };

  return (
    <>
      {shouldChangeClubHeaderBeShown(buildType) &&
        (shouldUseInternalClubPickerForBuildType(buildType) ? (
          <div onClick={showClubSelectionDropdown} css={styles}>
            <ChangeClubText />
          </div>
        ) : (
          <Link to="/clubs" css={styles}>
            <ChangeClubText />
          </Link>
        ))}
    </>
  );
};

export default HeaderChangeClub;
