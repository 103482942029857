import React from 'react';
import { IconProps } from 'src/components/icons/icon-types';

const RedesignRightChevronThin = ({ size, fill }: IconProps) => (
  <svg width={size || '100%'} viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.796875 0.847656C1.03125 0.613281 1.35938 0.613281 1.59375 0.847656L11.4844 10.7383C11.7188 10.9727 11.7188 11.3008 11.4844 11.5352L1.59375 21.4258C1.35938 21.6602 1.03125 21.6602 0.796875 21.4258L0.46875 21.0977C0.234375 20.8633 0.234375 20.5352 0.46875 20.3008L9.60938 11.1133L0.46875 1.97266C0.234375 1.73828 0.234375 1.41016 0.46875 1.17578L0.796875 0.847656Z"
      fill={fill}
    />
  </svg>
);

export default RedesignRightChevronThin;
