import React from 'react';
import { Icon } from './icon-types';

const Download: Icon = ({ svgStyle, fill }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="download"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={16}
    style={svgStyle}
  >
    <path
      d="M6.627 0.587 C 6.466 0.671,6.325 0.827,6.271 0.980 C 6.249 1.043,6.240 2.147,6.230 5.828 L 6.218 10.594 4.048 8.737 C 2.855 7.716,1.832 6.853,1.775 6.820 C 1.693 6.770,1.635 6.756,1.483 6.748 C 1.236 6.735,1.097 6.790,0.937 6.966 C 0.673 7.255,0.689 7.662,0.975 7.937 C 1.055 8.014,5.616 11.918,6.496 12.662 C 6.682 12.819,6.992 12.860,7.217 12.756 C 7.389 12.677,12.811 8.040,12.905 7.892 C 12.984 7.767,13.030 7.566,13.010 7.434 C 12.972 7.187,12.774 6.945,12.548 6.870 C 12.382 6.815,12.124 6.833,11.996 6.909 C 11.944 6.940,10.950 7.779,9.786 8.773 C 8.623 9.768,7.662 10.585,7.650 10.589 C 7.639 10.594,7.630 8.554,7.630 5.836 C 7.630 1.142,7.629 1.073,7.584 0.951 C 7.533 0.816,7.372 0.641,7.233 0.570 C 7.183 0.544,7.080 0.525,6.965 0.519 C 6.789 0.509,6.770 0.513,6.627 0.587 M1.196 14.155 C 1.065 14.202,0.888 14.365,0.817 14.505 C 0.736 14.662,0.734 14.942,0.811 15.099 C 0.882 15.242,1.012 15.370,1.155 15.438 L 1.272 15.493 6.895 15.493 L 12.518 15.493 12.637 15.437 C 12.960 15.287,13.120 14.911,13.006 14.575 C 12.951 14.413,12.768 14.224,12.610 14.165 C 12.482 14.117,12.474 14.117,6.888 14.119 C 1.985 14.120,1.283 14.125,1.196 14.155 "
      stroke="none"
      fillRule="evenodd"
      fill={fill}
    ></path>
  </svg>
);
export default Download;
