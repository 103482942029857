import React from 'react';
import { ChevronProps } from './icon-types';

const RightChevron = ({ size, onClick, fill, svgStyle }: ChevronProps) => (
  <div style={{ width: '100%' }} onClick={onClick}>
    <svg
      style={{
        display: 'block',
        margin: 'auto',
        width: size,
        height: size,
        paddingLeft: '0.2rem',
        ...svgStyle,
      }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill={fill}
    >
      <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  </div>
);

export default RightChevron;
