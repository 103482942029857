import * as React from 'react';
import { CmsRecord } from 'src/content/cms-provider';
import { ClubMetadata as ClubMetadataType } from 'src/content/metadata-provider';
import { useContext } from 'react';

export const CmsContent = React.createContext<CmsRecord>(null);
export const ClubMetadata = React.createContext<ClubMetadataType | null>(null);

export const ContentAndMetaConsumer = ({
  children,
}: {
  children: (cmsContent: CmsRecord, clubMetadata: ClubMetadataType) => React.ReactElement;
}) => {
  const cmsContent = useContext(CmsContent);
  const clubMetadata = useContext(ClubMetadata);
  return children(cmsContent, clubMetadata);
};
