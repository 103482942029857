import React from 'react';
import { FlagFrance } from '../components/icons/flagFrance';
import { FlagUk } from '../components/icons/flagUk';
import { FlagNetherlands } from '../components/icons/flagNetherlands';
import { FlagGermany } from '../components/icons/flagGermany';
import { FlagSpain } from '../components/icons/flagSpain';
import { FlagItaly } from '../components/icons/flagItaly';
import { FlagCatalan } from '../components/icons/flagCatalan';
import { IconProps } from 'src/components/icons/icon-types';

export enum Language {
  EN_BE = 'en-be',
  FR_BE = 'fr-be',
  NL_BE = 'nl-be',

  DE_DE = 'de-de',
  EN_DE = 'en-de',

  EN_ES = 'en-es',
  ES_ES = 'es-es',
  CA_ES = 'ca-es',

  EN_GB = 'en-gb',

  EN_IE = 'en-ie',

  EN_IT = 'en-it',
  IT_IT = 'it-it',

  EN_NL = 'en-nl',
  NL_NL = 'nl-nl',

  FR_FR = 'fr-fr',
  EN_FR = 'en-fr',

  FR_CH = 'fr-ch',
  EN_CH = 'en-ch',
}

export const getIsoLanguageCodeFromLanguage = (language: Language) => language.substring(0, 2);

export const getDisplayTextForLanguage = (language: Language) => {
  switch (language) {
    case Language.EN_BE:
    case Language.EN_DE:
    case Language.EN_ES:
    case Language.EN_GB:
    case Language.EN_IE:
    case Language.EN_IT:
    case Language.EN_NL:
    case Language.EN_FR:
    case Language.EN_CH:
      return 'EN';
    case Language.FR_BE:
    case Language.FR_FR:
    case Language.FR_CH:
      return 'FR';
    case Language.NL_BE:
    case Language.NL_NL:
      return 'NL';
    case Language.DE_DE:
      return 'DE';
    case Language.IT_IT:
      return 'IT';
    case Language.ES_ES:
      return 'ES';
    case Language.CA_ES:
      return 'CA';
    default:
      console.warn(`No display text found for Language ${language}`);
  }
};

export const getFlagIconForLanguage = (language: Language, iconProps: IconProps = {}) => {
  switch (language) {
    case Language.EN_BE:
    case Language.EN_DE:
    case Language.EN_ES:
    case Language.EN_GB:
    case Language.EN_IE:
    case Language.EN_IT:
    case Language.EN_NL:
    case Language.EN_FR:
    case Language.EN_CH:
      return <FlagUk {...iconProps} />;
    case Language.FR_BE:
    case Language.FR_FR:
    case Language.FR_CH:
      return <FlagFrance {...iconProps} />;
    case Language.NL_BE:
    case Language.NL_NL:
      return <FlagNetherlands {...iconProps} />;
    case Language.DE_DE:
      return <FlagGermany {...iconProps} />;
    case Language.IT_IT:
      return <FlagItaly {...iconProps} />;
    case Language.ES_ES:
      return <FlagSpain {...iconProps} />;
    case Language.CA_ES:
      return <FlagCatalan {...iconProps} />;
    default:
      console.warn(`No flag icon found for Language ${language}`);
  }
};

export const getBackendLanguage = (language: Language) => {
  switch (language) {
    case Language.EN_BE:
    case Language.EN_DE:
    case Language.EN_ES:
    case Language.EN_GB:
    case Language.EN_IE:
    case Language.EN_IT:
    case Language.EN_NL:
    case Language.EN_FR:
    case Language.EN_CH:
      return 'en-gb';
    case Language.FR_BE:
    case Language.FR_FR:
    case Language.FR_CH:
      return 'fr-be';
    case Language.NL_BE:
    case Language.NL_NL:
      return 'nl-be';
    case Language.DE_DE:
      return 'de-de';
    case Language.IT_IT:
      return 'it-it';
    case Language.ES_ES:
      return 'es-es';
    case Language.CA_ES:
      return 'ca';
    default:
      console.warn(`No backend language found for Language ${language}`);
  }
};

export const getUrlValueForLanguage = (language: Language) => {
  switch (language) {
    case Language.EN_BE:
    case Language.EN_DE:
    case Language.EN_ES:
    case Language.EN_GB:
    case Language.EN_IE:
    case Language.EN_IT:
    case Language.EN_NL:
    case Language.EN_FR:
    case Language.EN_CH:
      return 'en';
    default:
      return language.toString();
  }
};
