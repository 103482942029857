import { FormEvent, useContext, useMemo } from 'react';
import { getClubsForClubFinder } from 'src/content/club-look-ups-by-id';
import { SiteContext } from 'src/context/site-context';
import { BuildType, getClubDisplayValues } from 'src/enums/build/build-type';
import { navigateToGlobalPage } from 'src/helpers/navigation-helper';
import { amplitudeTrackEvent } from 'src/helpers/amplitude-event-track';
import { ClubInformation } from 'src/enums/club-information';

export const useClubSearchForm = (
  inputRef: any,
  isNavigation?: boolean,
  onSubmit?: () => void
): any => {
  const { buildType, language, pageLevel } = useContext(SiteContext);

  return (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSubmit?.();

    amplitudeTrackEvent(
      `"Find a club" - ${isNavigation ? 'Navigation' : 'On the page'} - Submit click`,
      {
        custom_page: window.location.origin,
        custom_level: pageLevel,
      }
    );

    const searchValue = inputRef.current?.value;
    navigateToGlobalPage('clubs', language, buildType, {
      search_location: searchValue,
    });
  };
};

export const useClubSearch = (searchKey: string) => {
  const { buildType } = useContext(SiteContext);

  const trimmedSearchKey = searchKey?.trim() ?? '';

  const allClubs: ClubInformation[] = useMemo(
    () =>
      getClubsForClubFinder(buildType).map((club) => ({
        ...club,
        clubName:
          club.buildType === BuildType.DAVID_LLOYD_UK
            ? club.clubName
            : `${getClubDisplayValues(club.buildType).clubNamePrefix} ${club.clubName}`,
      })),
    [buildType]
  );
  const filteredClubs = useMemo(
    () =>
      allClubs.filter((club) =>
        club.clubName.toLowerCase().includes(trimmedSearchKey.toLowerCase())
      ),
    [trimmedSearchKey, allClubs]
  );

  if (trimmedSearchKey.length === 0) {
    return [];
  }

  return filteredClubs;
};
