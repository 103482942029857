import React, { forwardRef, useEffect, useId, useImperativeHandle, useRef } from 'react';
import {
  getObjectPosition,
  getSrc,
  getSrcSet,
  mobileBreakpointInPixels,
} from 'src/helpers/cloudinary-helpers';
import { CSSInterpolation } from '@emotion/serialize';
import Helmet from 'react-helmet';
import { inlineImageDataIdAttribute } from 'src/enums/build/inline-image-data-id';

export type CloudinaryImageDetails = Queries.ImageDetails & {
  mobileCrop?: Queries.ImageCrop;
};

type CloudinaryImageProps = {
  imageDetails: CloudinaryImageDetails;
  styles?: CSSInterpolation;
  loading?: 'eager' | 'lazy';
  onImageLoaded?: () => void;
  enablePreload?: boolean;
  imageWidthLimit?: number;
  enableInlineDataSrc?: boolean;
};

const mobileCropMedia = `(max-width: ${mobileBreakpointInPixels}px)`;

const CloudinaryImage = forwardRef<HTMLImageElement, CloudinaryImageProps>(
  (
    {
      imageDetails,
      styles,
      loading,
      onImageLoaded = () => {},
      enablePreload = false,
      imageWidthLimit,
      enableInlineDataSrc,
    },
    ref
  ) => {
    const innerRef = useRef<HTMLImageElement>(null);
    useImperativeHandle(ref, () => innerRef.current, []);

    const imagePath = imageDetails?.imagePath;

    useEffect(() => {
      if (innerRef.current?.complete) {
        onImageLoaded?.();
      }
    }, []);

    const enableInlineDataSrcId = useId();
    let enableInlineDataSrcIdCount = 0;

    if (!imageDetails || !imagePath) {
      return null;
    }

    const src = getSrc(imagePath, imageWidthLimit);
    const srcSet = getSrcSet(imagePath, imageWidthLimit);
    const mobileCropSrcSet = !!imageDetails.mobileCrop
      ? getSrcSet(imagePath, imageWidthLimit, imageDetails.mobileCrop)
      : undefined;

    return (
      <>
        {enablePreload && (
          <Helmet>
            <link rel="preload" fetchPriority="high" as="image" href={src} imageSrcSet={srcSet} />
            {imageDetails.mobileCrop && (
              <link
                rel="preload"
                fetchPriority="high"
                as="image"
                href={src}
                imageSrcSet={mobileCropSrcSet}
                media={mobileCropMedia}
              />
            )}
          </Helmet>
        )}
        <picture>
          {imageDetails.mobileCrop && (
            <source
              srcSet={mobileCropSrcSet}
              media={mobileCropMedia}
              {...{
                [inlineImageDataIdAttribute]: enableInlineDataSrc
                  ? `${enableInlineDataSrcId}${++enableInlineDataSrcIdCount}`
                  : undefined,
              }}
            />
          )}
          <img
            ref={innerRef}
            src={src}
            srcSet={srcSet}
            alt={imageDetails.alternateText}
            css={[
              {
                width: '100%',
                height: '100%',
                objectFit: `cover`,
                objectPosition: getObjectPosition(imageDetails),
              },
              styles,
            ]}
            loading={loading}
            onLoad={onImageLoaded}
            {...{
              [inlineImageDataIdAttribute]: enableInlineDataSrc
                ? `${enableInlineDataSrcId}${++enableInlineDataSrcIdCount}`
                : undefined,
            }}
          />
        </picture>
      </>
    );
  }
);

CloudinaryImage.displayName = 'CloudinaryImage';

export default CloudinaryImage;
