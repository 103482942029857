import React, { useContext } from 'react';
import Facility from './facility';
import { FacilityDetails, ClubFacilityKeyWithVideo, getFacilitiesAtClub } from './facilities';
import { ContentAndMetaConsumer } from 'src/content';
import { EnabledPages } from 'src/content/enabled-pages-helper';
import { getClubDisplayValues } from 'src/enums/build/build-type';
import { TranslatedStaticText } from 'src/translators/static-text-translator';
import { NavigationPagesToDisplay } from 'src/models/cms/navigation-page';
import { SiteContext } from 'src/context/site-context';
import { Styling } from 'src/context/styling';
import mq from 'src/styling/media-queries';
import { getBuildDomainForBuildType, newWebsiteBuildDomains } from 'src/enums/build/build-domain';

const wrapperStyle = (shouldCenter: boolean, useSmallThumbnails?: boolean) =>
  mq({
    marginBottom: useSmallThumbnails ? null : '4rem',
    width: shouldCenter ? null : '100%',
    maxWidth: shouldCenter ? [null, '70rem'] : null,
    marginLeft: shouldCenter ? [null, 'auto'] : null,
    marginRight: shouldCenter ? [null, 'auto'] : null,
  });

const titleStyle = (styling: Styling, isNewDesign: boolean) =>
  mq([
    styling.texts.header,
    isNewDesign ? styling.fonts.regular : {},
    {
      fontSize: ['2rem', '2.5rem'],
      lineHeight: 1,
      marginTop: '4rem',
      color: styling.colors.bodyText,
    },
  ]);

const subtitleStyle = (styling: Styling) =>
  mq([
    styling.fonts.light,
    {
      lineHeight: 1.5,
      color: styling.colors.bodyText,
      fontSize: ['1rem', '1.25rem'],
      maxWidth: [null, '50rem'],
      margin: [0, '0 auto'],
    },
  ]);

const facilityWrapperStyle = (useSmallThumbnails?: boolean) =>
  mq({
    'width': '100%',
    '*zoom': 1,
    'clear': 'both',
    'content': '" "',
    'padding': ['0 1rem', '1rem 0 0'],
    'display': useSmallThumbnails ? 'flex' : 'table',
    'justifyContent': useSmallThumbnails ? 'center' : null,
  });

type FacilitySelectProps = {
  useSmallThumbnails?: boolean;
  center?: boolean;

  facilityTypeToHide?: FacilityDetails;
  includeTitle?: boolean;
  gtmTrackingId?: string;
  onClick?: () => void;
  isNav?: boolean;
};

type FacilitySelectContentProps = FacilitySelectProps & {
  clubName: string;
  clubFacilities: ClubFacilityKeyWithVideo[];
  enabledPages: EnabledPages;
  navigationPagesToDisplay: NavigationPagesToDisplay;
  onClick?: () => void;
};

const FacilitySelect = (props: FacilitySelectProps) => (
  <ContentAndMetaConsumer>
    {(cmsData, clubMetadata) => (
      <FacilitySelectContent
        {...props}
        clubName={clubMetadata.clubName}
        clubFacilities={cmsData.sharedContent.clubFacilities}
        enabledPages={cmsData.pages}
        navigationPagesToDisplay={cmsData.navigationMenuContent.navigationPagesToDisplay}
      />
    )}
  </ContentAndMetaConsumer>
);

const FacilitySelectContent = ({
  clubFacilities,
  facilityTypeToHide,
  includeTitle,
  useSmallThumbnails,
  center,
  gtmTrackingId,
  clubName,
  enabledPages,
  navigationPagesToDisplay,
  onClick,
  isNav,
}: FacilitySelectContentProps) => {
  const { styling, buildType } = useContext(SiteContext);
  const isNewDesign = newWebsiteBuildDomains.includes(getBuildDomainForBuildType(buildType));
  const isCenterFalse = center === false;

  const facilitiesToShow = getFacilitiesAtClub(
    clubFacilities,
    enabledPages,
    navigationPagesToDisplay.clubFacilitiesPages,
    facilityTypeToHide
  );

  if (facilitiesToShow.length === 0) {
    return null;
  }

  return (
    <div css={wrapperStyle(!isCenterFalse, useSmallThumbnails)}>
      {includeTitle ? (
        <div css={{ textAlign: 'center' }}>
          <h2 css={titleStyle(styling, isNewDesign)}>
            <TranslatedStaticText lookupKey="clubFacilities" />
          </h2>
          <div css={subtitleStyle(styling)}>
            <TranslatedStaticText
              lookupKey="highlightsAtClub"
              params={{
                clubPrefix: getClubDisplayValues(buildType).clubNamePrefix,
                clubName: clubName,
              }}
            />
          </div>
        </div>
      ) : null}

      <div css={facilityWrapperStyle(useSmallThumbnails)}>
        {facilitiesToShow.map((facility) => (
          <Facility
            key={facility.urlPath}
            facilityType={facility}
            useSmallThumbnails={useSmallThumbnails}
            gtmTrackingId={gtmTrackingId}
            onClick={onClick}
            isNav={isNav}
          />
        ))}
      </div>
    </div>
  );
};

export default FacilitySelect;
