import Cloudinary, { VideoPlayer } from 'cloudinary-video-player';
import React, { PropsWithChildren, useEffect, useId, useReducer, useRef, useState } from 'react';
import { CSSInterpolation } from '@emotion/serialize';
import Vimeo, { TimeUpdateEvent } from '@u-wave/react-vimeo';
import imageFade from 'src/helpers/image-fades';
import FadedImage, { FadeInEffectOption } from 'src/components/common/faded-image';
import {
  CLOUDINARY_CLOUD_NAME,
  getAdaptiveBitrateStreamingVideoUrl,
} from 'src/helpers/cloudinary-helpers';
import 'cloudinary-video-player/cld-video-player.min.css';
import Helmet from 'react-helmet';

const calculateScaleForDifferentAspectRatio = (
  containerRef: React.MutableRefObject<HTMLDivElement>
) => {
  const videoElement = containerRef.current?.getElementsByTagName('video')[0];
  if (!videoElement || !videoElement.videoWidth || !videoElement.videoHeight) {
    return null;
  }

  const videoAspectRatio = videoElement.videoWidth / videoElement.videoHeight;

  if (videoAspectRatio >= 16 / 9) {
    return null;
  }

  const actualVideoWidth = videoElement.offsetHeight * videoAspectRatio;
  const containerWidth = containerRef.current.offsetWidth;

  return actualVideoWidth < containerWidth ? `${containerWidth / actualVideoWidth}` : null;
};

const createVideoWrapperStyles = (containerRef: React.MutableRefObject<HTMLDivElement>) => {
  if (!containerRef.current) {
    return {};
  }

  const heightForWidth = (containerRef.current.offsetWidth * 9) / 16;
  const widthForHeight = (containerRef.current.offsetHeight * 16) / 9;

  const letterboxIsHorizontal = heightForWidth > containerRef.current.offsetHeight;

  const videoWrapperFix: CSSInterpolation = letterboxIsHorizontal
    ? {
        width: '100%',
        height: heightForWidth,
        overflowY: 'clip',
        top: -(heightForWidth - containerRef.current.offsetHeight) / 2,
        left: 0,
        scale: calculateScaleForDifferentAspectRatio(containerRef),
      }
    : {
        height: '100%',
        width: widthForHeight,
        overflowX: 'clip',
        left: -(widthForHeight - containerRef.current.offsetWidth) / 2,
        top: 0,
        scale: calculateScaleForDifferentAspectRatio(containerRef),
      };

  return videoWrapperFix;
};

type CloudinaryVideoPlayer = VideoPlayer & {
  on: (event: string, callback: () => void) => void;
};

export enum VideoType {
  CLOUDINARY = 'Cloudinary',
  VIMEO = 'Vimeo',
}

export type VideoDetails = CloudinaryVideoDetails | VimeoVideoDetails;

export type CloudinaryVideoDetails = {
  type: VideoType.CLOUDINARY;
  videoId?: null;
  videoUrl: string;
  videoPlaceholder: Queries.ImageWithCropDetails | Queries.ImageDetails;
};

export type VimeoVideoDetails = {
  type: VideoType.VIMEO;
  videoId: string;
  videoUrl?: null;
  videoPlaceholder: Queries.ImageWithCropDetails | Queries.ImageDetails;
};

export type VideoWithPlaceholderImageProps = PropsWithChildren<{
  video: VideoDetails;
  autoplay?: boolean;
  loop?: boolean;
  background?: boolean;
  muted?: boolean;
  disableAutopause?: boolean;
  forcePause?: boolean;
  onTimeUpdate?: (event: TimeUpdateEvent | null) => void;
  fadeType?: string;
  fadeDivStyle?: CSSInterpolation;
  fadeDivClassName?: string;
}>;

const VideoWithPlaceholderImage = ({
  video,
  autoplay,
  loop,
  background,
  muted,
  disableAutopause,
  forcePause,
  onTimeUpdate,
  fadeType,
  fadeDivStyle,
  fadeDivClassName,
  children,
}: VideoWithPlaceholderImageProps) => {
  const [, setShouldRecalculateSize] = useReducer(() => Date.now(), Date.now());
  const containerRef = useRef<HTMLDivElement>(null);
  const cloudinaryVideoRef = useRef<CloudinaryVideoPlayer>(null);
  const [isVideoReady, setVideoReady] = useState(video.type !== VideoType.CLOUDINARY);
  const [isVimeoVideoPaused, setIsVimeoVideoPaused] = useState(true);
  const id = useId();

  useEffect(
    function initialiseCloudinaryVideo() {
      if (video.type !== VideoType.CLOUDINARY || !video.videoUrl) {
        return;
      }

      if (cloudinaryVideoRef.current) {
        setVideoReady(false);
        cloudinaryVideoRef.current.source(getAdaptiveBitrateStreamingVideoUrl(video.videoUrl));
        return;
      }

      const cloudinaryVideo = Cloudinary.videoPlayer(id, {
        cloudName: CLOUDINARY_CLOUD_NAME,
        autoplay,
        muted,
        loop,
        controls: false,
        bigPlayButton: false,
        playsinline: true,
        pictureInPictureToggle: false,
        fontFace: 'inherit',
      }) as CloudinaryVideoPlayer;

      cloudinaryVideo.source(getAdaptiveBitrateStreamingVideoUrl(video.videoUrl));

      const updateInterval = () => {
        const currentTime = cloudinaryVideo.currentTime() as number;
        const duration = cloudinaryVideo.duration() as number;

        onTimeUpdate({
          duration: duration,
          seconds: currentTime,
          percent: currentTime / duration,
        });
      };

      cloudinaryVideo.on('loadeddata', () => setVideoReady(true));
      cloudinaryVideo.on('timeupdate', updateInterval);
      cloudinaryVideo.on('sourcechanged', () => onTimeUpdate(null));
      cloudinaryVideoRef.current = cloudinaryVideo;
    },
    [autoplay, id, loop, muted, video.type, video.videoUrl]
  );

  useEffect(() => {
    setShouldRecalculateSize();

    window.addEventListener('resize', setShouldRecalculateSize);

    return () => {
      window.removeEventListener('resize', setShouldRecalculateSize);
    };
  }, []);

  useEffect(
    function pauseCloudinaryVideo() {
      if (video.type !== VideoType.CLOUDINARY) {
        return;
      }

      if (!forcePause || !cloudinaryVideoRef.current) {
        return;
      }

      const videoElement = cloudinaryVideoRef.current;

      videoElement.pause();

      return () => {
        void videoElement.play();
      };
    },
    [forcePause, video.type]
  );

  const videoStyle: CSSInterpolation = {
    ...createVideoWrapperStyles(containerRef),
    opacity: isVideoReady ? 1 : 0,
    position: 'absolute',
  };

  const placeholderImageStyle: CSSInterpolation = {
    position: 'absolute',
    width: '100%',
    height: '100%',
  };

  const fadeDivStyles: CSSInterpolation = [
    {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background: isVideoReady ? `linear-gradient(${fadeType || imageFade.fullFade})` : undefined,
    },
    fadeDivStyle,
  ];

  return (
    <div css={{ width: '100%', height: '100%' }} onMouseOver={() => setIsVimeoVideoPaused(false)}>
      <div css={{ width: '100%', height: '100%' }} ref={containerRef}>
        <div css={placeholderImageStyle}>
          <FadedImage
            fixedImage={false}
            image={video.videoPlaceholder}
            enablePreload
            enableBlurredPlaceholder
            loading="eager"
            fadeInEffect={FadeInEffectOption.NONE}
            loadingSkeleton={false}
            imageWidthLimit={!!video.videoUrl || !!video.videoId ? 600 : undefined}
          />
        </div>
        <div css={videoStyle}>
          {video.type === VideoType.CLOUDINARY && !!video.videoUrl && (
            <>
              <Helmet>
                <link
                  rel="preload"
                  fetchPriority="high"
                  as="video"
                  href={getAdaptiveBitrateStreamingVideoUrl(video.videoUrl)}
                />
              </Helmet>
              <video id={id} className="cld-video-player cld-fluid" />
            </>
          )}
          {video.type === VideoType.VIMEO && !!video.videoId && (
            <Vimeo
              video={video.videoId}
              paused={(forcePause === undefined && isVimeoVideoPaused) || forcePause}
              loop={loop}
              muted={muted}
              controls={false}
              responsive={true}
              autopause={!disableAutopause}
              background={background}
              autoplay={autoplay}
              onTimeUpdate={onTimeUpdate}
            />
          )}
        </div>
      </div>
      <div css={fadeDivStyles} className={fadeDivClassName}>
        {children}
      </div>
    </div>
  );
};

export default VideoWithPlaceholderImage;
