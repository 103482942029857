import { clubDataRepository, globalDataRepository } from '../content/constants';
import { Language } from '../enums/language';
import { BuildType } from '../enums/build/build-type';

export interface CmsFileNode<T> {
  relativeDirectory: string;
  childClubJson: T;
}

export interface CmsGlobalFileNode<T> {
  relativeDirectory: string;
  childGlobalJson: T;
}

export const parseAsPlatformCompatiblePath = (path: string): string => path.replace('\\', '/');

export const parseRelativeDirectory = (node: CmsFileNode<any> | CmsGlobalFileNode<any>) =>
  (node.relativeDirectory = parseAsPlatformCompatiblePath(node.relativeDirectory));

export const findCmsFileNodeForClub = <T>(
  nodes: readonly CmsFileNode<T>[],
  clubId: string,
  language: Language
): CmsFileNode<T> => {
  return (
    nodes.find((n) => n.relativeDirectory === 'cross-club/club') ??
    nodes.find((n) => n.relativeDirectory === clubDataRepository(clubId, language)) ??
    nodes.find((n) => n.relativeDirectory === clubDataRepository(null, language))
  );
};

export const findCmsFileNodeForBrand = <T>(
  nodes: CmsGlobalFileNode<T>[],
  language: Language,
  buildType: BuildType
): CmsGlobalFileNode<T> => {
  return nodes.find((n) => n.relativeDirectory === globalDataRepository(language, buildType));
};
