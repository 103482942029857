import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react';

import imageFade from 'src/helpers/image-fades';
import { clubBannerIsActive } from 'src/helpers/club-helper';
import { WebsiteLink } from '../common/website-link';
import FadedImage from '../common/faded-image';
import LanguagePicker from '../common/language-picker';
import LinkDropdownCta from '../common/link-dropdown-cta';
import EnquireNowButton from '../facilities/enquire-now-button';
import CloseIcon from '../icons/close';
import HamburgerIcon from '../icons/hamburger';
import { colors, flex, mainNavHeight, zIndexes } from 'src/styling/constants';
import mq from 'src/styling/media-queries';
import DesktopSubNav from './desktop-nav/desktop-subnav';
import GlobalTimedBanner from './global-timed-banner';
import { PromotionContent } from 'src/models/cms/promotions-content';
import { CmsContent } from 'src/content';
import HeaderBanner from './header-banner';
import HeaderChangeClub from './header-change-club';
import HeaderClubSelect from './header-club-select';
import { useToggle } from 'src/custom-hooks/use-toggle';
import { SiteContext } from 'src/context/site-context';
import ClubFacilitiesMobileNav from './mobile-nav/club-facilities-mobile-nav';
import { GlobalCmsContent } from 'src/content/global-content';
import { getBuildDomainForBuildType, newWebsiteBuildDomains } from 'src/enums/build/build-domain';

type HeaderProps = {
  clubName?: string;
  toggleMobileNav: () => void;
  showingMobileNav: boolean;
  enableClubFacilitiesMobileNav?: boolean;
  timedPromotions?: PromotionContent[];
};

const HEADER_HEIGHT_CSS_VARIABLE = 'header-height';
const HEADER_OFFSET_SPACING = 30;
export const HEADER_OFFSET = `calc(var(--${HEADER_HEIGHT_CSS_VARIABLE}) + ${HEADER_OFFSET_SPACING}px)`;

const Header = ({
  clubName,
  toggleMobileNav,
  showingMobileNav,
  timedPromotions,
  enableClubFacilitiesMobileNav,
}: HeaderProps) => {
  const cmsData = useContext(CmsContent);
  const globalCmsData = useContext(GlobalCmsContent);
  const { styling, isHarbour, isBlaze, isGlobalPage, buildType } = useContext(SiteContext);
  const [clubSelectExpanded, setClubSelectExpanded, , hideClubSelect] = useToggle(false);
  const onPageClick = () => hideClubSelect();
  const handleFinish = () => hideClubSelect();

  useEffect(() => {
    const pageElement = document.getElementsByClassName('page-scroll')[0];

    if (pageElement) {
      pageElement.addEventListener('click', onPageClick);
    }

    return () => {
      if (pageElement) {
        pageElement.removeEventListener('click', onPageClick);
      }
    };
  }, [onPageClick]);

  const isHeaderHeightListenerSet = useRef(false);
  useLayoutEffect(() => {
    const calculateHeaderHeight = () => {
      const doc = document.documentElement;
      const elt = document.getElementsByClassName('upper-nav-bar')[0] as HTMLElement | undefined;
      doc.style.setProperty(`--${HEADER_HEIGHT_CSS_VARIABLE}`, `${elt?.offsetHeight || 0}px`);
    };

    if (isHeaderHeightListenerSet.current) return;

    window.addEventListener('resize', calculateHeaderHeight);
    window.addEventListener('orientationchange', calculateHeaderHeight);
    calculateHeaderHeight();
    isHeaderHeightListenerSet.current = true;
  }, []);

  const linkDropdownCtaContent = (isGlobalPage ? globalCmsData : cmsData).sharedContent
    .linkDropdownCta;
  const isNewDesign = newWebsiteBuildDomains.includes(getBuildDomainForBuildType(buildType));

  const headerChangeClubStyle = isNewDesign
    ? {
        'padding': '0.5rem 0 0.4375rem',
        'borderBottom': '1px solid',
        'borderBottomColor': 'transparent',
        '&:hover': {
          borderBottomColor: styling.colors.camelot,
        },
      }
    : {
        padding: '0.5rem 0',
      };
  const oldIconColor = isBlaze ? colors.blazeGray : styling.colors.highlightText;
  const iconColor = isNewDesign ? styling.colors.capeCod : oldIconColor;

  return (
    <div
      css={{
        position: 'fixed',
        maxWidth: '100vw',
        width: '100%',
        backgroundColor: styling.colors.headerBackground,
        zIndex: zIndexes.header,
        left: 0,
      }}
      className="upper-nav-bar"
    >
      {cmsData && clubBannerIsActive(cmsData.sharedContent) && (
        <HeaderBanner content={cmsData.sharedContent.clubBanner} />
      )}
      {!isGlobalPage && <GlobalTimedBanner timedPromotions={timedPromotions} />}
      <div
        css={mq([
          flex.rowCentered,
          {
            height: mainNavHeight,
            flexShrink: 0,
            padding: ['0 1rem', '0 2rem'],
            fontSize: '1rem',
            borderBottom: `1px solid ${styling.colors.headerBorder}`,
          },
        ])}
      >
        <div
          css={mq({
            width: isBlaze ? null : '3rem',
            display: ['flex', 'none'],
            float: 'left',
            height: '100%',
            alignItems: 'center',
          })}
          onClick={toggleMobileNav}
        >
          {showingMobileNav ? (
            <CloseIcon size="1.5rem" fill={iconColor} />
          ) : (
            <HamburgerIcon size="1.5rem" fill={iconColor} />
          )}
        </div>
        <div
          css={mq({
            flexShrink: 0,
            width: '6.3rem',
            margin: ['0 auto 0 0', '1rem 2rem'],
            float: 'left',
          })}
        >
          <WebsiteLink to="/">
            <FadedImage image={styling.logos.headerLogo} fadeType={imageFade.none} fixedImage />
          </WebsiteLink>
        </div>
        <div
          css={mq({
            flex: 1,
            display: ['none', 'flex'],
            height: '100%',
            textAlign: 'center',
          })}
        >
          {!isGlobalPage && (
            <div
              css={mq([
                styling.texts.header,
                flex.rowCentered,
                {
                  padding: '1rem',
                  height: '100%',
                  lineHeight: 1,
                  fontSize: ['1rem', '1.25rem'],
                  borderLeft: `1px solid ${styling.colors.headerBorder}`,
                  color: styling.colors.headerClubName,
                  letterSpacing: isHarbour ? '0.5rem' : null,
                  margin: isHarbour ? '3px' : null,
                },
              ])}
            >
              {clubName}
            </div>
          )}
          <div
            css={[
              flex.rowCentered,
              styling.fonts.regular,
              {
                'color': isNewDesign ? styling.colors.capeCod : styling.colors.highlightText,
                '&:hover': isNewDesign
                  ? {
                      color: styling.colors.camelot,
                    }
                  : {},
                'padding': '0.5rem 1rem',
                'height': '100%',
                'cursor': 'pointer',
                'borderRight': `1px solid ${styling.colors.headerBorder}`,
              },
            ]}
          >
            <HeaderChangeClub
              isClubSelectDropdownVisible={clubSelectExpanded}
              toggleClubSelectDropdown={setClubSelectExpanded}
              styles={headerChangeClubStyle}
            />
          </div>
        </div>
        {!isBlaze && (
          <>
            {!!linkDropdownCtaContent?.enabled && (
              <div className="ml3 w-auto-l h-100 flex items-center icon--david-lloyd-purple justify-end ph2-l">
                <LinkDropdownCta
                  title={linkDropdownCtaContent.text}
                  links={linkDropdownCtaContent.dropdownOptions}
                  showingMobileNav={showingMobileNav}
                />
              </div>
            )}
          </>
        )}
        <div css={mq({ display: ['none', 'inline'] })}>
          <LanguagePicker />
        </div>
        {!isBlaze && (
          <>
            {!isGlobalPage && !linkDropdownCtaContent?.enabled && (
              <div className="w-third w-auto-l h-100 flex items-center icon--david-lloyd-purple justify-end ph2-l">
                <div className="mv2 ml4-l">
                  <EnquireNowButton
                    buttonType="button button-header"
                    gtmTrackingId="header-join-now"
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <DesktopSubNav clubName={clubName} />
      {enableClubFacilitiesMobileNav && !isGlobalPage && (
        <ClubFacilitiesMobileNav
          clubFacilitiesPages={
            cmsData.navigationMenuContent.navigationPagesToDisplay.clubFacilitiesPages
          }
          clubFacilities={cmsData.sharedContent.clubFacilities}
          enabledPages={cmsData.pages}
        />
      )}
      {clubSelectExpanded && <HeaderClubSelect onFinish={handleFinish} />}
    </div>
  );
};

export default Header;
