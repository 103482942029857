import React from 'react';
import { getFlagIconForLanguage, getDisplayTextForLanguage, Language } from 'src/enums/language';
import ExpandIcon from '../../icons/expand';
import { flex } from '../../../styling/constants';
import SiteConsumer from '../../../context/site-consumer';

type LanguageItemChevron =
  | {
      withChevron: true;
      isChevronExpanded?: boolean;
    }
  | {
      withChevron?: false;
      isChevronExpanded?: undefined;
    };

type LanguageItemProps = {
  language: Language;
} & LanguageItemChevron;

const LanguageItem: React.FC<LanguageItemProps> = ({
  language,
  withChevron,
  isChevronExpanded,
}) => (
  <SiteConsumer>
    {({ styling }) => (
      <div css={[flex.rowCentered, { margin: '0.5rem 1rem' }]}>
        {getFlagIconForLanguage(language, { size: '1rem' })}
        <div css={{ padding: '0.25rem' }}>{getDisplayTextForLanguage(language)}</div>
        {withChevron && (
          <ExpandIcon
            fill={styling.colors.highlightText}
            size="1rem"
            isExpanded={isChevronExpanded}
          />
        )}
      </div>
    )}
  </SiteConsumer>
);

export default LanguageItem;
