import React from 'react';
import { IconProps } from 'src/components/icons/icon-types';

const MenuIcon = ({ size, fill }: IconProps) => (
  <div style={{ width: size || '100%' }}>
    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Menu Button</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 6.05469C0.223858 6.05469 0 6.27855 0 6.55469V7.05469C0 7.33083 0.223857 7.55469 0.5 7.55469H23.5C23.7761 7.55469 24 7.33083 24 7.05469V6.55469C24 6.27855 23.7761 6.05469 23.5 6.05469H0.5ZM0.5 16.5547C0.223858 16.5547 0 16.7785 0 17.0547V17.5547C0 17.8308 0.223857 18.0547 0.5 18.0547H23.5C23.7761 18.0547 24 17.8308 24 17.5547V17.0547C24 16.7785 23.7761 16.5547 23.5 16.5547H0.5Z"
        fill={fill}
      />
    </svg>
  </div>
);

export default MenuIcon;
