import React from 'react';
import { IconProps } from './icon-types';

export const FlagItaly = ({ size }: IconProps) => (
  <svg
    width="127"
    height="127"
    viewBox="0 0 127 127"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: size, height: size, borderRadius: size }}
  >
    <g>
      <path d="M140.722 0.298828H-13.9492V126.148H140.722V0.298828Z" fill="#099345" />
      <path d="M140.723 0.298828H37.6055V126.152H140.723V0.298828Z" fill="white" />
      <path d="M140.721 0.298828H89.1641V126.152H140.721V0.298828Z" fill="#EC1C24" />
    </g>
  </svg>
);
