import React from 'react';
import { Language } from 'src/enums/language';
import { NavigationPage } from 'src/models/cms/navigation-page';
import { translateLinkIntoLanguage } from 'src/translators/links-translator';
import ClubDropdownHeader from '../club-dropdown-header';
import { applyClubPageType, clubPageDropdownHeaderList } from '../club-page-type';
import { DesktopNavLink } from './desktop-subnav';
import { WindowLocation } from '@gatsbyjs/reach-router';
import { CSSInterpolation } from '@emotion/serialize';

export const navPageLinkMatchesTranslatedUrl = (
  linkToTranslate: string,
  linkFromCms: string,
  language: Language
): boolean => {
  const translatedNavigationLinkRegex = new RegExp(
    `^(\/?)${translateLinkIntoLanguage(linkToTranslate, language)}(\/?)$`
  );
  return translatedNavigationLinkRegex.test(linkFromCms);
};

const isClubDropdownHeader = (navLink: string, language: Language): boolean => {
  const isClubDropdown = clubPageDropdownHeaderList.map((item) => {
    return navPageLinkMatchesTranslatedUrl(item, navLink, language);
  });
  return isClubDropdown.includes(true);
};

type MainPageSubNavLinkProps = {
  navigationPage: NavigationPage;
  language: Language;
  location: WindowLocation;
  isGlobalPage: Boolean;
};

export const DesktopSubNavLinkForMainPages: React.FC<MainPageSubNavLinkProps> = ({
  navigationPage,
  language,
  location,
  isGlobalPage,
}) => {
  return isClubDropdownHeader(navigationPage.link, language) && !isGlobalPage ? (
    <ClubDropdownHeader
      location={location}
      displayText={navigationPage.displayText}
      clubPageType={applyClubPageType(navigationPage.link, language)}
    />
  ) : (
    <DesktopNavLink
      location={location}
      path={navigationPage.link}
      displayText={navigationPage.displayText}
    />
  );
};

type DesktopSubNavLinkProps = {
  pagesToDisplay: NavigationPage[];
  location: WindowLocation;
  language?: Language;
  isGlobalPage?: Boolean;
};

const styles = {
  mainSubNav: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  secondarySubNav: {
    display: 'flex',
    flexWrap: 'wrap',
    flexBasis: 0,
    flexGrow: 1,
    justifyContent: 'flex-end',
    marginLeft: 'auto',
  },
} satisfies Record<string, CSSInterpolation>;

export const DesktopSubNavMainPageLinks: React.FC<DesktopSubNavLinkProps> = ({
  pagesToDisplay,
  language,
  location,
  isGlobalPage,
}) => {
  return (
    <div css={styles.mainSubNav}>
      {pagesToDisplay?.map((navigationPage) => (
        <DesktopSubNavLinkForMainPages
          key={navigationPage.displayText}
          navigationPage={navigationPage}
          language={language}
          location={location}
          isGlobalPage={isGlobalPage}
        />
      ))}
    </div>
  );
};

export const DesktopSubNavSecondaryPageLinks: React.FC<DesktopSubNavLinkProps> = ({
  pagesToDisplay,
  location,
}) => {
  return (
    <div css={styles.secondarySubNav}>
      {pagesToDisplay?.map((navigationPage) => (
        <DesktopNavLink
          key={navigationPage.displayText}
          location={location}
          path={navigationPage.link}
          displayText={navigationPage.displayText}
        />
      ))}
    </div>
  );
};
