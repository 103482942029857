import React from 'react';
import { Icon } from './icon-types';
import { colors } from 'src/styling/constants';

const Map: Icon = ({ fill = colors.darkGreen }) => (
  <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.7812 0.5625C21.0781 0.5625 21.375 0.822266 21.375 1.19336V14.0332C21.375 14.5156 21.041 14.9609 20.5957 15.1465L14.9922 17.0762C14.7324 17.1504 14.5098 17.1875 14.25 17.1875C13.9902 17.1875 13.7305 17.1504 13.5078 17.0762L7.125 14.8125L0.779297 17.1504C0.705078 17.1875 0.630859 17.1875 0.556641 17.1875C0.259766 17.1875 0 16.9648 0 16.5938V3.75391C0 3.27148 0.296875 2.82617 0.742188 2.64062L6.3457 0.710938C6.60547 0.636719 6.86523 0.5625 7.08789 0.5625C7.34766 0.599609 7.60742 0.636719 7.86719 0.710938L14.25 2.9375L20.5586 0.636719C20.6328 0.599609 20.707 0.5625 20.7812 0.5625ZM8.3125 2.75195V13.3652L13.0625 15.0352V4.42188L8.3125 2.75195ZM1.78125 14.9238L6.49414 13.1426H6.53125V2.5293L1.78125 4.19922V14.9238ZM19.5938 13.5879V2.86328L14.8438 4.64453V15.2578L19.5938 13.5879Z"
      fill={fill}
    />
  </svg>
);

export default Map;
