export const staticTextLookup = {
  FAQs: "FAQ",
  aCopyOfThisGuaranteeShouldBeRetained: "Une copie de cette garantie doit être conservée par le client, elle sera envoyée par e-mail de confirmation",
  about: "Qui nous sommes",
  aboutYou: "À propos de vous",
  accessToClubsAndSpaRetreats: "Platinum membership will also give you access to other David Lloyd clubs. See below for details of the clubs you'll be able to access.\nPlus, if the club has a Spa Retreat, this will also include access to the Spa Retreat facilities. Click here for a list of clubs with Spa Retreat.",
  accessToClubsNearYou: "Votre accès aux clubs près de chez vous:",
  accessToOtherClubs: "Accès à d'autres clubs",
  accountHoldersName: "Nom du titulaire du compte",
  accountNumber: "Numéro de compte",
  activating: "Activating",
  addPartnerAmount: "Ajouter un partenaire %amount%",
  addSelectedMembership: "Ajouter l'adhésion sélectionnée",
  addSelectedPromotion: "Ajouter la promotion sélectionnée",
  addToCalendar: "Ajouter Au Calendrier",
  addYourAssociates: "Ajouter votre conjoint et vos enfants",
  addYourChildren: "Ajouter vos enfants",
  addYourPartner: "Ajouter votre conjoint",
  addYourPromotions: "Sélectionnez votre promotion",
  additionalDetailsSecondErrorMessage: "Désolé, nous ne pouvons pas enregistrer vos coordonnées en ligne pour le moment, nous les prendrons lors de votre première visite au club.",
  additionalMembers: "Membres supplémentaires",
  additionalMemberships: "Adhésions supplémentaires",
  address: "Adresse",
  addressLine1: "Adresse ligne 1",
  addressLine2: "Adresse ligne 2",
  addressLinePlaceholder: "Saisissez votre adresse",
  adultMembers: "Membres adultes",
  adults: "Adultes",
  afterSchool: "Après l'école",
  allAgesWelcome: "Pour tous les âges",
  allFacilities: "Tous les équipements",
  alsoAtClub: "Également à %clubName%",
  amountSubjectToPendingPriceRise: "The amount stated is subject to any pending price rise, refer with the club team for further details.",
  and: "et",
  annualOnJoiningTheClubPayment: "En rejoignant le club, il vous sera demandé d'effectuer un paiement comprenant des frais d'adhésion, un paiement au prorata pour le reste du mois (le cas échéant) et un paiement couvrant le coût de l'adhésion pendant 12 mois civils. Si vous souhaitez améliorer votre expérience d'adhésion, vous pouvez ajouter des produits supplémentaires tels que l'entraînement personnel ou le coaching, qui peuvent également être payés dès aujourd'hui.",
  annually: "annuellement",
  anythingElseYoudLikeUsToKnow: "Que voudriez-vous nous dire d'autre",
  anytimeClubAccess: "Accès au club à tout moment",
  app: " Appli",
  appQrCodeInstructions: "Alignez simplement la caméra de votre téléphone sur le code, et une fois qu'il est reconnu, appuyez sur le lien pour télécharger l'application %brandName%.",
  appUpdateRequired: "Mise à jour de l'application requise",
  areYouSureYouDoNotWantToBeContacted: "Êtes-vous sûr de ne pas vouloir être contacté par email ou sms ?",
  associatesWereReset: "Malheureusement, ce forfait ne prend pas en charge tous les membres supplémentaires que vous avez sélectionnés - veuillez choisir un autre forfait si vous souhaitez inclure ces membres.",
  atClub: "À %clubName%",
  atHomeDigitalContent: "Contenu numérique depuis chez soi, y compris des cours en direct et à la demande, des séminaires éducatifs et des conseils de nos entraîneurs",
  atUnit: "À %unitName%",
  availablePromotions: "promotions disponibles",
  availableSlot: "%numberOfSlots% créneau disponible",
  availableSlots: "%numberOfSlots% créneaux disponibles",
  awaitingPayment: "Awaiting payment",
  back: "Retour",
  backToTop: "Retour en haut",
  beforeYouBecomeAMember: "Avant de nous rejoindre, laissez-nous vous montrer comment tirer parti de tous les avantages du club",
  bestValue: "Meilleure offre",
  bookAClass: "Réservez un cours",
  bookAClubWelcome: "Réserver un rendez-vous de bienvenue",
  bookAClubWelcomeError: "Désolé, une erreur s'est produite. Merci de nous contacter pour que nous trouvions un horaire.",
  bookATour: "Réserver une visite",
  bookATourError: "Désolé, une erreur s'est produite. Merci de nous contacter pour que nous trouvions un horaire.",
  bookATourHeader: "Réservez votre visite",
  bookATourSubtext: "Choisissez un club ci-dessous pour planifier une visite",
  bookOnTheMove: "Réserver où que vous soyez",
  bookYourClubWelcome: "Réservez votre rendez-vous d'accueil",
  bookYourClubWelcomeAt: "Réservez votre rendez-vous d'accueil à %clubName%",
  bookYourTour: "Réservez votre visite",
  bookYourTourOf: "Réservez votre visite de %clubName%",
  booked: "Réservé",
  bookingYourClubWelcome: "Réserver votre accueil au club...",
  bookingYourTour: "Réservation en cours…",
  buyapowaWarningPopupCancelButtonText: "Choisir un autre club",
  buyapowaWarningPopupConfirmButtonText: "Rejoindre %clubName%",
  buyapowaWarningPopupHeader: "Attention",
  buyapowaWarningPopupText: "Le club %clubName% n'est pas actuellement inscrit à notre programme de parrainage. Si vous continuez cette inscription, ni vous ni la personne qui vous a parrainé ne pourrez bénéficier de récompenses.",
  byClickingFinishYouAcceptDDConditions: "En cliquant sur \"%buttonText%\", vous reconnaissez que vous êtes le titulaire du compte bancaire et que le compte n'exige pas qu'une autre personne autorise les débits sur ce compte. S'il s'agit d'un compte personnel ou professionnel et que plus d'une personne doit autoriser les débits sur ce compte, veuillez nous contacter directement.",
  cancel: "Annuler",
  cancelMyMembership: "Annuler mon adhésion",
  cantFindASlot: "Impossible de trouver un créneau ?",
  cardCollectionTime: "Heure de récupération de carte/visite du club",
  careers: "Carrières",
  changeClub: "Changer de club",
  changePreferences: "Modifier les préférences",
  changeSelectedPackage: "Modifier l'adhésion sélectionnée",
  checkYourAccessToASpecificClub: "Vérifiez votre accès à un club spécifique:",
  child2AndUnder: "Adhésion enfant:\nJusqu'à 2 ans",
  child3To18: "Adhésion enfant:\n3-18 ans",
  childMembers: "Membres enfants",
  children: "Enfant",
  childrenOverview: "Aperçu des enfants",
  chooseAClub: "Choisir un club",
  chooseAClubToRegisterForClubTrial: "Choisir un club",
  chooseHowYouPay: "Choisissez votre mode de paiement",
  chooseMembershipLength: "Choisissez la durée de votre abonnement",
  chooseYourStartDate: "Sélectionnez votre date de commencement",
  clickHereIfYouAreNot: "Veuillez cliquer ici si vous n'êtes pas %firstName%",
  close: "Fermer",
  closeDetails: "Fermer les détails",
  club: "Club",
  clubEmail: "E-mail du club",
  clubFacilities: "Équipements du club",
  clubFinderSearchPlaceholder: "Entrez un lieu",
  clubOpeningHours: "Horaires du club",
  clubOpeningTimes: "Heures d'ouverture du club",
  clubOverview: "Vue d'ensemble du club",
  clubTourTime: "Heure de la visite du club",
  clubVideo: "Vidéo du club",
  clubWelcomeMeetingTime: "Heure de la réunion de bienvenue au club",
  comingSoon: "Prochainement disponible",
  completeDirectDebitPopupText: "Gagnez du temps lors de votre première visite en configurant votre prélèvement maintenant",
  completeForm: "Veuillez remplir le formulaire ci-dessous.",
  completeItInClub: "Configurer mon paiement automatique dans le club",
  completeItNow: "Configurer mon paiement automatique maintenant",
  completeSEPAInClub: "Configurer mon paiement automatique dans le club",
  completeSEPANow: "Configurer mon paiement automatique maintenant",
  completeSEPAPopupText: "Gagnez du temps lors de votre première visite en configurant votre prélèvement maintenant",
  confirm: "Confirmer",
  confirmNoPaymentTaken: "Veuillez confirmer qu'aucun paiement n'a été effectué",
  confirmPassword: "Confirmer le mot de passe",
  confirmPasswordPlaceholder: "Veuillez confirmer votre nouveau mot de passe",
  confirmPaymentTaken: "Veuillez confirmer que le paiement a été traité",
  contact: "Contact",
  contactInformation: "Coordonnées",
  contactMeToArrange: "Me contacter pour fixer",
  contactNotPromotedMonthlyPaymentErrorSubtitle: "La configuration de votre abonnement prend quelques instants en coulisses. Veuillez réessayer, mais ne vous inquiétez pas, vous ne serez pas débité deux fois.",
  contactNotPromotedMonthlyPaymentErrorTitle: "Désolé, nous ne sommes pas aussi rapides que vous aujourd'hui !",
  contactPreferencesAfterPrivacyPolicy: "pour tous les détails. Il vous suffit de cliquer les cases pour rester en contact.",
  contactPreferencesBeforePrivacyPolicy: "Nous serions heureux de vous tenir informé de nos produits, services et offres. Si vous êtes d'accord, cliquez les cases ci-dessous. Vous pouvez changer d'avis à tout moment. Voir notre",
  contactTheClubForOpeningTimes: "Veuillez contacter le club pour plus d'informations sur les horaires",
  "continue": "Continuer",
  cookiePolicyDescription: "Notre site web utilise des cookies pour améliorer votre expérience. En utilisant ce site, vous acceptez notre utilisation de cookies.",
  cookiesPolicy: "Politique de cookies",
  copyright: "Droits d’auteur",
  coreStrengthAndHIIT: "Endurance, force et mise en condition physique, HIIT, bien-être et yoga, vélo",
  corporateFitness: "Unternehmensfitness",
  countryBelgium: "Belgium",
  countryFrance: "France",
  countryGermany: "Germany",
  countryIreland: "Ireland",
  countryItaly: "Italy",
  countryNetherlands: "Netherlands",
  countrySpain: "Spain",
  countrySwitzerland: "Switzerland",
  countryUnitedKingdom: "United Kingdom",
  county: "Région",
  countyPlaceholder: "Saisissez votre pays",
  couple: "Couple",
  creche: "Crèche",
  creditorAddress: "Adresse du créancier",
  creditorId: "Identificant du créancier",
  creditorName: "Nom créancier",
  dateAtTime: "Le %date% à %time%",
  dateOfBirth: "Date de naissance",
  days: "journées",
  daytime: "En journée",
  detailsAddedToMembershipAccount: "Ces informations seront à présent ajoutées à votre compte membre.",
  diamondPlusRequired: "L'adhésion à Diamond Plus est nécessaire pour accéder à certaines retraites Spa.",
  directDebitClubOpens: "Votre premier prélèvement automatique commencera dès l'ouverture du club, en tenant compte d'éventuelles promotions actives au moment de votre adhésion",
  directDebitClubReopens: "Votre prélèvement mensuel commencera à la réouverture du club et prendra en compte les promotions d'adhésion en place",
  directDebitMandate: "Mandat de prélèvement automatique",
  directDebitMembershipStarts: "Votre premier prélèvement automatique commencera au début de votre adhésion, en tenant compte d'éventuelles promotions actives au moment de votre adhésion",
  discoverTheCreche: "Découvrir la crèche",
  done: "Fait",
  downloadAppPrompt: "Pour effectuer des réservations et gérer votre adhésion, veuillez télécharger l'application David Lloyd Clubs.",
  downloadDescriptionMembershipVerification: "Téléchargez notre application mobile avant votre visite pour que nous puissions vous aider à vous inscrire et profiter au maximum de votre abonnement.",
  downloadDescriptionTennis: "Téléchargez l'application pour voir les horaires, réserver un cours ou un court de tennis de n'importe où.",
  downloadDescriptionTrialConfirmation: "Téléchargez-la avant votre première visite pour que nous puissions vous aider à vous inscrire et profiter au maximum de votre abonnement.",
  downloadTheMobileAppHere: "Téléchargez l'application mobile ici:",
  dr: "Dr",
  duration: "Durée",
  edit: "Modifier",
  email: "Email",
  emailAddress: "Adresse email",
  emailAddressesMustBeUnique: "Veuillez saisir une adresse électronique unique. Elle doit être différente de l'adresse électronique fournie par le membre principal.",
  endsIn: "Fini dans",
  enjoyTimeTogetherAt: "Profitez de moments ensemble à %clubPrefix% %clubName%",
  enquireAboutMembership: "renseignements sur l'abonnement",
  enquireNow: "Se renseigner",
  enquireText: "Parlez-nous de votre demande",
  enterALocation: "Entrez une destination",
  enterValidEmailAddress: "S'il vous plaît, mettez une adresse email valide",
  enterValidMobilePhoneNumber: "Veuillez saisir un numéro de téléphone portable valable",
  enterValidName: "Veuillez saisir un nom valide comprenant une lettre majuscule",
  enterValidPhoneNumber: "Veuillez saisir un numéro de téléphone valable",
  enterValidPostCode: "Veuillez entrer un code postal valide",
  enterValidTaxId: "Please enter a valid tax ID",
  equipmentAt: "Notre équipement à %clubName%",
  exerciseClasses: "Cours de gym",
  exerciseClassesAt: "Les cours de gym à %clubName%",
  experienceAllTheBenefits: "Profitez de tous les avantages d'un abonnement au club.",
  expertPersonalTrainers: "Entraîneurs personnels experts",
  expertTuition: "Profs experts",
  exploreClub: "Parcourir %clubName%",
  exploreKidsActivities: "Parcourir les activités pour enfants",
  failedToFetchExistingPayment: "A payment has already been made, but we encountered an error confirming details",
  familyAmpersandKids: "Familles & enfants",
  familyAndKids: "Familles et enfants",
  familySwim: "Natation en famille",
  female: "Femme",
  findAClub: "Trouver un club",
  findOutMore: "En savoir plus",
  findYourClub: "Trouvez votre club",
  firstFullMonth: "Premier mois complet",
  firstMonthFees: "Comme nous ne sommes pas en mesure de configurer votre prélèvement automatique à temps pour votre premier mois complet, ce paiement est prélevé à l'avance",
  firstName: "Prénom",
  fitnessOverview: "Aperçu du fitness",
  fixedTermFee: "Abonnement de douze mois à compter du %month%",
  fixedTermFeeTooltip: "Paiement couvrant le coût de l'adhésion pendant 12 mois civils",
  flexible: "Flexible",
  followUs: "Suivez-nous",
  followedByMonthly: "Suivi du paiement mensuel de",
  followedByMonthlyPaymentsTitle: "Suivi de mensualités",
  free: "Gratuit",
  frequentlyAskedQuestions: "Foire aux Questions",
  friend: "Ami",
  friendsAndFamilyReferralAlreadyUsed: "Désolé, ce lien est introuvable. Veuillez contacter la personne qui vous a référé pour qu'elle vous invite à nouveau.",
  friendsAndFamilyReferralExpired: "Désolé, ce lien a expiré. Veuillez contacter la personne qui vous a recommandé pour qu'elle vous nomme à nouveau.",
  friendsAndFamilyReferralNotFound: "Désolé, ce lien est introuvable. Veuillez contacter la personne qui vous a référé pour qu'elle vous invite à nouveau.",
  from: "De",
  from3MonthsPlus: "Plus de 3 mois",
  from3To18Years: "3-18 ans",
  gender: "Sexe",
  germanAndFallbackOnly: "Test",
  getInTouch: "Contactez-nous",
  getStarted: "Démarrer",
  getYourQuoteContactPreferencesAfterPrivacyPolicy: "pour tous les détails. Il vous suffit de cliquer la case pour rester en contact.",
  getYourQuoteContactPreferencesBeforePrivacyPolicy: "Nous serions heureux de rester en contact et vous en dire plus sur nos produits, services et offres. Si vous êtes d'accord, cliquez la case ci-dessous. Vous pouvez changer d'avis à tout moment. Voir notre",
  groupExerciseClasses: "Cours en groupe",
  gym: "Gym",
  havingProblemsSavingMonthlyPaymentDetails: "Ho sentim, avui no som tan ràpids com tu! Gestionar la teva inscripció ens porta un temps. Si us plau, torna-ho a intentar, però no pateixis perquè no et carregarem dos cops.",
  healthyBreakfast: "Petit déjeuner sain",
  heatedSwimmingPools: "Piscines chauffées",
  highlightsAtClub: "Le meilleur de %clubPrefix% %clubName%*",
  hours: "heures",
  howCanWeContactYou: "Comment pouvons-nous vous contacter ?",
  iAgree: "J'accepte",
  ifSomethingWereToChangeInYourLifestyle: "Si quelque chose devait changer dans votre mode de vie, %brandName% applique une période de réflexion de 14 jours. Parlez à un membre de l'équipe pour en savoir plus.",
  ifSomethingWereToChangeRightToCancel: "Si quelque chose devait changer dans votre mode de vie, vous avez le droit d'annuler. Parlez à un membre du personnel pour en savoir plus.",
  imageDisclaimer: "*Clause de non-responsabilité : les images sont fournies à titre d'illustration uniquement et peuvent ne pas correspondre au club que vous avez sélectionné.",
  importantInformation: "Une information important",
  includedInPromotion: "Inclus dans la promotion",
  individual: "Individu",
  initialEnquiryKeepYouUpdated: "Après votre première demande, nous serions ravis de vous informer de nos derniers produits, services et offres. Si vous êtes intéressé, il vous suffit de cliquer sur les cases ci-dessous.",
  initialMonthTerm: "Période initiale %monthDuration% mois civils",
  isChildPresentText: "Mon enfant est au Club avec moi aujourd'hui",
  isPartnerPresentText: "Mon partenaire est dans le club avec moi",
  join: "Nous rejoindre",
  joinCorporate: "Rejoindre Corporate",
  joinDifferentClub: "S'abonner à un autre club",
  joinFriendsAndFamily: "Rejoignez vos amis et votre famille",
  joinOurReferralScheme: "Il semblerait que vous essayiez de rejoindre notre programme de parrainage...",
  joinTodayAndSave: "Inscrivez-vous aujourd'hui et économisez",
  joiningFee: "Frais d’adhésion",
  joiningFeeFirstMonth: "frais d'inscription et premier mois d'abonnement inclus",
  joiningFeeProRataFirstMonth: "Frais d'inscription pour le reste du premier mois et le premier mois complet",
  joiningFeeTooltip: "Paiement pour appartenir au club",
  joiningFeeTwelveMonths: "Frais d'inscription, adhésion pour le reste du mois et 12 mois civils",
  joiningFees: "Frais d'inscription",
  joiningFeesApply: "Frais d’adhésion applicables",
  joiningFeesWaived: "OFFRE SPECIALE - Économie de frais d'adhésion maintenant disponible",
  joiningTheClubAsAFamily: "Rejoindre le club en famille",
  justMe: "Juste moi",
  keepAnEyeOutForFutureTrials: "Gardez un œil sur notre site web pour connaîre les prochaines périodes d'essai ou contactez-nous via le site et notre équipe vous répondra",
  keepInTouch: "Restons en contact",
  keepYouUpdated: "Nous serions heureux de pouvoir vous tenir informé de nos produits, services et offres. Si vous êtes intéressé, cliquez les cases ci-dessous.",
  kickstartTitleWithName: "%firstName%, comment vous décririez-vous ?",
  kickstartTitleWithoutName: "Comment vous décririez-vous ?",
  kidsActivities: "Activités pour enfants",
  kidsBuildYourOwnPizza: "Crée ta propre pizza",
  kidsClub: "Club enfants",
  lastName: "Nom",
  latestNews: "Latest News",
  learnMore: "En savoir plus",
  learnSomethingNew: "Apprendre quelque chose de nouveau",
  letThemEnjoyTheClub: "Laissez-les profiter du club pendant que vous prenez du temps pour vous.",
  letUsKnowWhatYouAreInterestedIn: "Dites-nous ce qui vous intéresse pour que nous puissions personnaliser votre visite",
  letUsKnowWhatYouAreLookingForwardTo: "Faites-nous savoir quels sont les prestations du club que vous souhaitez le plus utiliser",
  likeOurPage: "Likez notre page",
  linkIsInvalid: "Désolé, ce lien n'est pas valide",
  loadingClubWelcomeTimes: "Chargement des horaires du rendez-vous de bienvenue..",
  loadingTourTimes: "Horaires en cours de chargement…",
  logOut: "Déconnexion",
  login: "Se connecter",
  male: "Homme",
  master: "Me",
  meAndMyFamily: "Moi et ma famille",
  meAndMyPartner: "Moi et mon conjoint",
  membersLogin: "Connexion membre",
  membershipDescription: "Vous le trouverez dans votre e-mail de bienvenue ou sur votre carte de membre",
  membershipDiscountUntil: "Jusqu'au %date%",
  membershipEnquiryDetails: "L'utilisateur a sélectionné : %duration%%packageKey%%packageTypes%",
  membershipFees: "Frais d’abonnement",
  membershipFeesUpUntil: "Frais d'adhésion jusqu'à %date%",
  membershipNumber: "Numéro d'affiliation",
  membershipPlaceholder: "Veuillez saisir votre numéro d'affiliation",
  membershipPriceChangedError: "The payment has been confirmed, if you wish to change membership details, please complete joining journey and manually update club centric account",
  membershipSaving: "Économies sur abonnement",
  membershipStartClubConfirm: "Votre club vous confirmera la date de début de votre adhésion",
  membershipStartClubOpens: "Votre adhésion commencera dès l'ouverture du club",
  membershipTermsAndConditions: "Conditions d’adhésion",
  membershipTrial: "Abonnement d'essai",
  memberships: "Abonnements",
  min: "min",
  minutes: "minutes",
  miss: "Mlle",
  monthlyDirectDebitsStartOn: "Paiements mensuels par prélèvement automatique à compter du",
  monthlyPaymentsDuringOfferPeriodTitle: "Paiements mensuels pendant la période de l'offre spéciale",
  monthlySavings: "Economies mensuelles",
  months: "mois",
  more: "En savoir plus",
  moreDetailsSkipDirectDebit: "Je souhaite configurer mon prélèvement automatique dans Club",
  moreDetailsSkipSEPA: "Je souhaite configurer mon prélèvement automatique dans Club",
  mostPopular: "Plus populaire",
  mostRecent: "Most Recent",
  mr: "M.",
  mrs: "Mme",
  ms: "Ms",
  mx: "Mx",
  myMembership: "Mon abonnement",
  nearlyThere: "On y est presque",
  next: "Suivant",
  noAdditionalMembershipsAvailable: "Aucune option d'adhésion supplémentaire n'est actuellement disponible.",
  noAvailablePromotions: "Aucune action n'est disponible.",
  noClubWelcomeSlotsError: "Malheureusement, nous n'avons pas d'horaires disponibles en ligne pour le moment pour votre rendez-vous de bienvenue. Veuillez nous contacter, et nous vous trouverons un horaire.",
  noClubsInClubFinder: "Il n'y a pas de clubs dans cette zone, mais les clubs les plus proches de chez vous sont listés ci-dessous.",
  noJoiningFee: "Aucun frais d'adhésion",
  noJoiningFeeOffer: "Offre spéciale: Adhésion gratuite !",
  noMembershipFeeForMonthOffer: "Offre bonus: Abonnement gratuit en %month%",
  noMembershipFeeUntilMonthOffer: "Offre bonus: Abonnement gratuit jusqu'au mois de %month%",
  noPreferencePackageKey: "aucune préférence pour le type de package d'adhésion ; ",
  noPreferencePackageTypes: "aucune préférence pour le type d'accès aux membres.",
  noTourSlotsError: "Désolé, nous n'avons pas d'horaires de visites disponibles en ligne actuellement. Merci de nous contacter, et nous vous trouverons un créneau",
  normally: "Normallement",
  notAllMembershipsAvailable: "Les abonnements ne sont pas tous disponibles",
  notSureWhichClubsNearest: "Clubs les plus proches incertains",
  now: "Maintenant",
  onDate: "le %date%",
  onFacebook: "%clubName% sur Facebook",
  onInstagram: "%clubName% sur Instagram",
  onJoiningTheClubPayment: "En rejoignant le club, il vous sera demandé d'effectuer un premier paiement comprenant des frais d'adhésion et un paiement au prorata (il s'agit de votre paiement aujourd'hui). Ceci est suivi d'un abonnement mensuel qui sera pris par prélèvement automatique. Si vous souhaitez améliorer votre expérience d'adhésion, vous pouvez ajouter des produits supplémentaires tels que l'entraînement personnel ou le coaching, qui peuvent également être payés dès aujourd'hui.",
  oneMonth: "Un mois",
  onlyAvailableForALimitedTime: "Disponible pour un période limitée seulement",
  openingHours: "Heures d'ouverture",
  optInToSpecialOffer: "Ajoutez ceci pour vous inscrire à la promotion d'adhésion",
  optOutAtAnyTimeAfterPrivacyPolicy: "pour tous les détails!",
  optOutAtAnyTimeBeforePrivacyPolicy: "Vous pouvez vous désinscrire à tout moment. Voir notre",
  options: "Options",
  organisation: "Organisation",
  organisationOther: "Autres",
  other: "Autre",
  ourEquipmentAt: "Nos équipements à %clubName%",
  ourFriendlyTeamAreLookingForwardToMeetingYou: "Notre équipe se réjouit de vous accueillir",
  outdoorPoolTimesWeatherDependent: "Les horaires de la piscine extérieure dépendent des conditions météo",
  over14EmailAddress: "Les membres de plus de 14 ans peuvent utiliser les installations du club. Saisissez leur adresse électronique pour qu'ils puissent être inscrits.",
  over14PhoneNumber: "Les membres de plus de 14 ans peuvent utiliser l'application mobile %brandName%, veuillez saisir leur numéro de téléphone portable.",
  "package": "Offre",
  packageSelected: "%packageName% sélectionné",
  padel: "Padel",
  paidOnline: "Payé en ligne",
  partner: "Partenaire",
  partnerEmail: "Votre partenaire recevra un e-mail confirmant les détails de son adhésion et pour terminer sa configuration d'adhésion. Entrez son numéro de téléphone portable et nous vous enverrons un lien pour vous inscrire à l'application mobile %brandName%.",
  partnerWasReset: "Ce forfait n'offre malheureusement pas l'option d’ajouter son conjoint – Nous vous invitons à envisager de choisir un autre forfait pour inclure votre conjoint",
  partnersAndSponsors: "Sponsors & partenaires",
  password: "Mot de passe",
  passwordPlaceholder: "Veuillez introduire votre mot de passe",
  payAnnually: "Paiement annuel",
  payAtReception: "Payer à la réception",
  payMonthly: "Paiement mensuel",
  payOnline: "Payez en ligne",
  payingAnnually: "Le paiement annuel est généralement moins cher que le paiement mensuel",
  paymentAlreadyConfirmed: "The payment has been confirmed, skip to the monthly payment page",
  paymentDetails: "Informations de paiement",
  paymentLinkExpired: "Payment link expired",
  paymentReceived: "Payment received",
  paymentRefused: "Paiement refusé",
  penneArrabbiata: "Penne arrabbiata",
  perMonth: "Par mois",
  personalInformation: "Informations personelles",
  phoneNumber: "Numéro de téléphone mobile",
  pickAnotherTime: "Choisir un autre horaire",
  pleaseCheckAndTryAgain: "Veuillez vérifier le lien et essayer à nouveau",
  pleaseCheckYourCardDetails: "Veuillez vérifier les informations de votre carte",
  pleaseCheckYourDetailsAndTryAgain: "Veuillez vérifier vos coordonnées et réessayer. Si le problème persiste, rendez-nous visite en club et notre équipe se fera un plaisir de vous aider.",
  pleaseCheckYourEmail: "Please check your email",
  pleaseClickHereTo: "Veuillez cliquer ici pour",
  pleaseContactOnlineJoining: "Veuillez contacter %email% pour obtenir de l'aide.",
  pleaseDoubleCheckAndTryAgain: "Veuillez vérifier vos informations et réessayer",
  pleaseEnterANumber: "Saisissez le numéro",
  pleaseMakePaymentAtReception: "Veuillez effectuer le paiement à la réception",
  pleasePickAnotherTime: "Veuillez choisir une autre heure.",
  pleaseRefreshOrTryAgainLater: "Veuillez actualiser ou réessayer plus tard",
  pleaseSelectATime: "Veuillez choisir une heure",
  pleaseTryAgainLater: "Veuillez réessayer plus tard",
  pleaseUseAnAlternativeBrowser: "Veuillez utiliser un moteur de recherche différent, tel qu'une version à jour de Microsoft Edge, Google Chrome ou Safari",
  pleaseVerifyCaptcha: "Veuillez vérifier le captcha pour continuer",
  policies: "Politiques",
  policiesAndDocuments: "Politiques et documents",
  poolOpeningHours: "Heures d'ouverture de la piscine",
  poolOpeningTimes: "Horaires de la piscine",
  poolTimes: "Horaires de la piscine",
  pools: "Piscines",
  poolsAreClosed: "Toutes nos piscines sont actuellement fermées",
  postCode: "Code postal",
  postCodePlaceholder: "Saisissez votre code postal",
  preferNotToSay: "Préfère ne pas indiquer",
  press: "Presse",
  pressCentre: "Press Centre",
  previous: "Page précédente",
  priceDuringPromotionalPeriod: "prix pendant la période promotionnelle",
  pricePerAdult: "%adultPrice% par adulte",
  pricePerChild: "%childPrice% par enfant",
  primaryMember: "Membre principal",
  privacyPolicy: "Politique de confidentialité",
  promotionSelected: "Action sélectionnée",
  promotionalItems: "Objets promotionnels",
  promotions: "Promotions",
  qrCodeExpired: "QR code expired - please pay at reception",
  quickRegistration: "Enregistrement Rapide",
  referral: "Parrainage",
  refundedIfYouJoinOnAStandardMembership: "frais à payer si vous ne souscrivez pas à un abonnement standard",
  regionUnitedKingdomEast: "East",
  regionUnitedKingdomGreaterLondon: "Greater London",
  regionUnitedKingdomMidlands: "Midlands",
  regionUnitedKingdomNorthEast: "North East",
  regionUnitedKingdomNorthWest: "North West",
  regionUnitedKingdomNorthernIreland: "Northern Ireland",
  regionUnitedKingdomScotland: "Scotland",
  regionUnitedKingdomSouth: "South",
  regionUnitedKingdomSouthEast: "South East",
  regionUnitedKingdomSouthWest: "South West",
  regionUnitedKingdomWales: "Wales",
  registerForClubTrial: "Démarrer votre abonnement découverte de 14 jours",
  registerForClubTrialButton: "Commencer mon abonnement découverte",
  registeredCompanyNumber: "Société immatriculée sous le numéro",
  registrationErrorMessage: "Veuillez vérifier que vos coordonnées sont correctes. Si vous rencontrez des difficultés pour créer un compte, veuillez nous envoyer un e-mail à l'adresse <a style=\"color: %emailColor%;\" href=\"mailto:%email%\">%email%</a> afin qu’on puisse vous assister dans les plus brefs délais.",
  registrationUnauthorised: "L’autorisation a échoué",
  relaxAmpersandSocialise: "Détente & rencontres",
  relaxAndEnjoySpaRetreats: "Détendez-vous et profitez de retraites spa",
  relaxAndEnjoyTheSpaRetreatsAt: "Détendez-vous et profitez des retraites au spa à:",
  relaxAndSocialise: "Détente et rencontres",
  remainderOfFirstMonth: "Reste du premier mois",
  remainderOfFirstMonthAnd12MonthsMembership: "Reste du premier mois et 12 mois civils d'adhésion",
  remainderOfFirstMonthAndFirstFullMonth: "Reste du premier mois et premier mois complet",
  remainderOfThisMonth: "Reste du mois",
  remainderOfThisMonthTooltip: "Paiement couvrant le coût de l'adhésion pour le reste du mois",
  requestingAClubWelcome: "Demander un rendez-vous de bienvenue",
  retry: "Réessayer",
  saveAmount: "Économisez %amount%",
  savingUpTo: "économisez jusqu'à %price%*",
  scanQrCodeToDownload: "Scannez le code QR pour télécharger l'application",
  scanQrCodeToPay: "Scan the QR code to proceed to payment page",
  seeAllFacilities: "Voir tous les équipements",
  seeDetails: "Voir les détails",
  seeMore: "Voir plus…",
  selectADate: "Choisir une date",
  selectAnOrganisation: "Sélectionnez une entreprise ou un groupe…",
  selectOptionsBelow: "Choisir parmi les options ci-dessous pour trouver des activités pour toute la famille.",
  selectPackage: "Sélectionnez %packageName%",
  selectPromotion: "Sélectionnez une promotion",
  selectTitle: "Sélectionez un titre",
  selectedDuration: "une durée de package de %durationType% ; ",
  selectedPackageKey: "une adhésion à %packageKey% ; ",
  selectedPackageTypes: "accès aux membres qui inclut %packageTypes%.",
  selectedTourTimeNoLongerAvailable: "Malheureusement, l'heure de visite que vous avez sélectionnée n'est plus disponible.",
  selectedTourTimeNoLongerAvailableHeader: "L'heure de la visite n'est pas disponible",
  sendQuote: "Envoyer un devis",
  sepaGuaranteeLine1: "En signant ce formulaire de mandat, vous autorisez IA] %companyName% à envoyer des instructions  à votre banque pour debiter votre compte et 1B] votre banque à débiter votre compte conformément aux instructions de %companyName%.",
  sepaGuaranteeLine2: "Vous bénèficiez d'un droit à un remboursement par votre banque selon les conditions décrites dans la convention que vous avez passée avec elle. \nToute demande de remboursement doit être présentée dans ls 8 semaines suivant la date de débit de votre compte.",
  sepaGuaranteeLine3: "Vos droits concernant ce mandat sont expliqués dans un document que vous pouvez obtenir auprès de votre banque. ",
  sepaGuaranteeLine4: "Tout les champs sont obligatoires. ",
  sepaGuaranteeLine5: "Type d'encaissement: récurrent (peut être utilisé plusieurs fois)",
  sepaTitle: "Mandat de domicilation européenne SEPA",
  settingUpYourDirectDebit: "Configuration de votre prélèvement automatique",
  settingUpYourMonthlyPayment: "Mise en place de votre paiement mensuel",
  shareOnFacebook: "Partager sur Facebook",
  shareOnTwitter: "Partager sur Twitter",
  signMeUp: "M'inscrire",
  signUpForAGradeCoaching: "Inscrivez-vous pour un coaching professionnel pour tous âges",
  skip: "Skip",
  skipPayment: "Ignorer le paiement, je souhaite payer à la réception",
  slotsTakingAWhileToLoad: "Cela prend du temps de trouver nos créneaux disponibles. Nous allons continuer à essayer, mais en attendant, vous pouvez nous contacter directement aux coordonnées ci-dessous.",
  snrgyFamilyFitness: "Fitness en famille Synrgy",
  socialMedia: "Social Media",
  softPlay: "Jeux libres",
  somethingHasGoneWrong: "Une erreur s'est produite",
  sorryIeNotSupported: "Désolé mais Internet Explorer n'est pas supporté",
  sorrySomethingHasGoneWrongPleaseTryAgain: "Désolé, une erreur s'est produite. Merci de réessayer.",
  sorrySomethingWentWrong: "Désolé, une erreur s'est produite.",
  sorryThereWasAProblemWithYourPayment: "Désolé, il y a eu un problème avec votre paiement",
  sorryWereHavingTechnicalProblems: "Désolé, nous avons des problèmes techniques",
  sortBy: "Sort by:",
  sortByMyLocation: "Sort by my location",
  sortCode: "Code de tri",
  spa: "Spa",
  specialHours: "Horaires spéciaux",
  specialOffer: "Offre spéciale",
  specialsAndEvents: "Spéciaux et événements",
  sportsOverview: "Aperçu des sports",
  standard: "Standard",
  startDate: "Date de début",
  startInNextWeek: "Commencez votre abonnement à tout moment la semaine prochaine",
  startOnFixedDate: "Vous pouvez commencer à devenir membre le",
  stateOfTheArtGymFacilities: "Installations de sports dernier cri",
  studioOpeningHours: "Heures d'ouverture du studio",
  studioOverview: "Vue d'ensemble du studio",
  submitAndStartMembership: "Envoyer et commencer à profiter de mon abonnement",
  swimmingAt: "La natation à %clubName%",
  swimmingCoaching: "Coaching natation",
  swimmingPools: "Piscines",
  tailorYourClubWelcome: "Personnalisé votre rendez-vous d'accueil",
  tailorYourTour: "Personnalisez votre visite",
  takeOurVideoTour: "Regardez notre vidéo pour vous faire une idée de l'expérience %brandName%*",
  takeTheNextStep: "Passez à l'étape suivante",
  takeTheNextStepDescription: "Découvrez comment devenir membre de  %clubPrefix% %clubName% pourrait vous transformer ainsi que le temps que vous passez en famille.",
  taxId: "Tax ID",
  telephone: "Téléphone",
  tennis: "Tennis",
  tennisClub: "Club de tennis",
  tennisCoaching: "Coaching tennis",
  termsAndConditions: "Termes et conditions",
  textMessage: "Sms",
  thankYouForSettingUpDirectDebit: "Merci d'avoir configuré votre prélèvement automatique avec nous",
  thankYouForSubscribing: "Merci de vous être abonné!",
  thankYouWelcomeToBrand: "Merci, et bienvenue chez %brandName%!",
  theClub: "Le club",
  thereAreCurrentlyNoMembershipPackages: "Désolé, nous n'avons aucun abonnement disponible à l'achat en ligne pour le moment. Veuillez contacter votre club local pour savoir comment devenir membre.",
  threeMonths: "3 mois",
  timetable: "Horaires",
  title: "Titre",
  today: "Aujourd'hui",
  tomorrow: "demain",
  totalDue: "Total",
  totalDueToday: "Montant total dû",
  totalSavedJoiningToday: "Montant total économisé en vous inscrivant aujourd'hui",
  townOrCity: "Ville",
  townOrCityPlaceholder: "Saisissez votre ville",
  treatments: "Spa de jour visage et corps",
  treatmentsOverview: "Aperçu des traitements",
  unableToOfferTrial: "Désolé, nous ne sommes actuellement pas en mesure de proposer un essai.",
  unableToTakeClubWelcomeBookings: "Malheureusement, nous ne sommes pas en mesure de prendre des réservations en ligne pour le moment - vous pouvez nous contacter via le formulaire ci-dessous pour réserver votre rendez-vous de bienvenue.",
  unableToTakeTourBookings: "Désolé, nous ne sommes actuellement pas en mesure de procéder à des réservations en ligne - vous pouvez nous contacter aux coordonnées ci-dessous pour réserver votre visite.",
  unexpectedError: "Erreur inattendue",
  unfortunatelyAnErrorHasOccurred: "Désolé, une erreur s'est produite.",
  unfortunatelyThisTimeIsNoLongerAvailable: "Désolé, cet horaire n'est plus disponible",
  unknownMembershipError: "Votre abonnement a pu être correctement enregistré. Veuillez guetter l'arrivée d'un email de confirmation. Si vous n'en recevez pas dans l'heure, veuillez réessayer",
  unsubscribe: "Se désabonner",
  updateAppPrompt: "Veuillez mettre à jour votre application avec la dernière version et réessayer.",
  upgradeYourMembershipToAddChildren: "Mettez à niveau votre abonnement pour ajouter un enfant",
  upgradeYourMembershipToAddPartner: "Changez votre abonnement pour ajouter votre conjoint",
  useMyLocation: "Utiliser ma localisation",
  userAlreadyExists: "Ce numéro de membre est déjà utilisé",
  userInputOrganisation: "Quel est le nom de votre entreprise ou de votre groupe ?",
  userVerificationFailed: "La vérification a échoué",
  usualJoiningFees: "Frais d’adhésion standard",
  vatNumber: "Numéro de TVA",
  viewAllClubs: "Voir tous les clubs",
  viewInMaps: "Voir sur la carte",
  viewMap: "View a map of all David Lloyd Clubs",
  viewOurFoodMenu: "Voir notre menu",
  viewTimetable: "Voir les horaires",
  visitSpaRetreats: "Visitez les retraites spa",
  was: "Était",
  weAlreadyHaveYourDetails: "%firstName%, vos coordonnées sont déjà dans notre système.",
  weDontRecogniseThatLocation: "Désolé, nous ne reconnaissons pas cet emplacement",
  weJustNeedAFewMoreDetails: "Encore quelques détails",
  weSendOurMostExcitingOffers: "Nous envoyons nos offres les plus intéressantes par email",
  weWereUnableToSaveDetails: "Désolés, nous n'avons pas pu enregistrer vos informations",
  weekend: "Week-end",
  weeklyTimetable: "Horaires hebdomadaires",
  welcomeTo: "Bienvenue au",
  whatToExpect: "À quoi faut-il s'attendre ?",
  whatsApp: "WhatsApp",
  whereToFindUs: "Où nous trouver",
  whoCanCome: "Qui peut venir ?",
  whosComing: "Qui vient ?",
  workEmailAddress: "Adresse e-mail professionnelle",
  years: "ans",
  youngChildren: "Jeunes enfants [c'est-à-dire les enfants du forfait 0-2 ans]",
  yourCardDetails: "Carte bancaire",
  yourChild: "Votre enfant (%range%)",
  yourChildren: "vos enfants (%range%)",
  yourClubWelcomeIsBooked: "%name%, votre accueil est réservé.",
  yourDetails: "Vos coordonnées",
  yourFirstFullMonth: "Votre premier mois complet (%month%)",
  yourFirstFullMonthTooltip: "Paiement couvrant le coût de l'adhésion jusqu'à ce que votre prélèvement automatique ait été configuré auprès de votre banque",
  yourMembership: "Votre abonnement",
  yourMembershipTrial: "Votre abonnement d'essai",
  yourNannies: "votre nounou",
  yourNanny: "votre nounou",
  yourOlderChild: "votre enfant plus agé (%range%)",
  yourOlderChildren: "votre enfant plus agé (%range%)",
  yourPartner: "votre partenaire ",
  yourSavings: "Vos économies",
  yourSavingsSubtitle: "Voici ce que vous pouvez économiser en vous inscrivant aujourd'hui",
  yourStartDate: "Votre date de début",
  yourTourIsBooked: "%name%, votre visite est réservée",
  yourYoungChild: "Votre plus jeune enfant (%range%)",
  yourYoungChildren: "Votre plus jeune enfant (%range%) "
};
