import React from 'react';
import { IconProps } from './icon-types';

const Close = ({ size, fill }: IconProps) => (
  <div style={{ width: size || '100%', height: size || '100%' }}>
    <svg
      style={{ display: 'block' }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Close</title>
      <g id="Atoms/Icons/Close" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <polygon
          id="Fill-3"
          fill={fill}
          points="18.9999989 6.40999942 17.5899989 4.9999997 11.9999993 10.5899996 6.40999942 4.9999997 4.9999997 6.40999942 10.5899996 11.9999993 4.9999997 17.5899989 6.40999942 18.9999989 11.9999993 13.409999 17.5899989 18.9999989 18.9999989 17.5899989 13.409999 11.9999993"
        />
      </g>
    </svg>
  </div>
);

export default Close;
