import { BuildDomain } from '../enums/build/build-domain';
import { BuildType } from '../enums/build/build-type';

export function getGtmId(buildType: BuildType): string {
  switch (buildType) {
    case BuildType.DAVID_LLOYD_BELGIUM:
      return process.env.GATSBY_GTM_ID_BE;
    case BuildType.DAVID_LLOYD_GERMANY:
      return process.env.GATSBY_GTM_ID_DE;
    case BuildType.DAVID_LLOYD_SPAIN:
      return process.env.GATSBY_GTM_ID_ES;
    case BuildType.DAVID_LLOYD_FRANCE:
      return process.env.GATSBY_GTM_ID_FR;
    case BuildType.DAVID_LLOYD_IRELAND:
      return process.env.GATSBY_GTM_ID_IE;
    case BuildType.DAVID_LLOYD_ITALY:
      return process.env.GATSBY_GTM_ID_IT;
    case BuildType.DAVID_LLOYD_NETHERLANDS:
      return process.env.GATSBY_GTM_ID_NL;
    case BuildType.HARBOUR:
      return process.env.GATSBY_GTM_ID_HARBOUR;
    case BuildType.DAVID_LLOYD_UK:
      return process.env.GATSBY_GTM_ID_UK;
    case BuildType.MERIDIAN:
      return process.env.GATSBY_GTM_ID_MERIDIAN;
    case BuildType.DAVID_LLOYD_SWITZERLAND:
      return process.env.GATSBY_GTM_ID_CH;
    default:
      console.warn(`Using default GTM container for buildType ${buildType}`);
      return process.env.GATSBY_GTM_ID_UK;
  }
}

export function getSiteBaseUrl(buildType: BuildType): string {
  switch (buildType) {
    case BuildType.DAVID_LLOYD_BELGIUM:
      return process.env.GATSBY_SITE_BASE_URL_BELGIUM;
    case BuildType.DAVID_LLOYD_FRANCE:
      return process.env.GATSBY_SITE_BASE_URL_FRANCE;
    case BuildType.DAVID_LLOYD_GERMANY:
      return process.env.GATSBY_SITE_BASE_URL_GERMANY;
    case BuildType.HARBOUR:
      return process.env.GATSBY_SITE_BASE_URL_HARBOUR;
    case BuildType.DAVID_LLOYD_IRELAND:
      return process.env.GATSBY_SITE_BASE_URL_IRELAND;
    case BuildType.DAVID_LLOYD_ITALY:
      return process.env.GATSBY_SITE_BASE_URL_ITALY;
    case BuildType.DAVID_LLOYD_NETHERLANDS:
      return process.env.GATSBY_SITE_BASE_URL_NETHERLANDS;
    case BuildType.DAVID_LLOYD_SPAIN:
      return process.env.GATSBY_SITE_BASE_URL_SPAIN;
    case BuildType.MERIDIAN:
      return process.env.GATSBY_SITE_BASE_URL_MERIDIAN;
    case BuildType.DAVID_LLOYD_SWITZERLAND:
      return process.env.GATSBY_SITE_BASE_URL_SWITZERLAND;
    case BuildType.DAVID_LLOYD_UK:
    case BuildType.BLAZE_STUDIO:
      return process.env.GATSBY_SITE_BASE_URL_UK;
  }
}

export function getCloudinaryCName(): string {
  return process.env.GATSBY_CLOUDINARY_CNAME;
}

export function getBuildDomain(): BuildDomain {
  return process.env.BUILD_DOMAIN as BuildDomain;
}

export function getPublicEncryptionKey(): Object {
  return JSON.parse(process.env.GATSBY_PUBLIC_ENCRYPTION_KEY);
}

interface PuzzelConfig {
  customerKey: string;
  configId: string;
}

export function getPuzzelConfig(): PuzzelConfig {
  return {
    customerKey: process.env.GATSBY_PUZZEL_CUSTOMER_KEY,
    configId: process.env.GATSBY_PUZZEL_CONFIG_ID,
  };
}

export function getTawkUrl(buildType: BuildType, siteId: string): string {
  switch (buildType) {
    case BuildType.DAVID_LLOYD_BELGIUM:
      const antwerpSiteId = '106';
      return siteId === antwerpSiteId
        ? process.env.GATSBY_TAWK_URL_ANTWERP
        : process.env.GATSBY_TAWK_URL_BRUSSELS;
    case BuildType.DAVID_LLOYD_FRANCE:
      return process.env.GATSBY_TAWK_URL_FRANCE;
    case BuildType.DAVID_LLOYD_GERMANY:
      return process.env.GATSBY_TAWK_URL_GERMANY;
    case BuildType.HARBOUR:
      return process.env.GATSBY_TAWK_URL_HARBOUR;
    case BuildType.DAVID_LLOYD_IRELAND:
      return process.env.GATSBY_TAWK_URL_IRELAND;
    case BuildType.DAVID_LLOYD_ITALY:
      return process.env.GATSBY_TAWK_URL_ITALY;
    case BuildType.DAVID_LLOYD_NETHERLANDS:
      return process.env.GATSBY_TAWK_URL_NETHERLANDS;
    case BuildType.DAVID_LLOYD_SPAIN:
      return process.env.GATSBY_TAWK_URL_SPAIN;
    case BuildType.MERIDIAN:
      return process.env.GATSBY_TAWK_URL_MERIDIAN;
    case BuildType.DAVID_LLOYD_SWITZERLAND:
      return process.env.GATSBY_TAWK_URL_SWITZERLAND;
    default:
      return process.env.GATSBY_TAWK_URL_UK;
  }
}
