import { graphql, useStaticQuery } from 'gatsby';
import { trimPath } from 'src/helpers/path-matching-helper';

export const useGatsbyPaths = () => {
  const data = useStaticQuery<Queries.pagesQueryQuery>(graphql`
    query pagesQuery {
      allSitePage {
        nodes {
          path
        }
      }
    }
  `);

  const paths: Set<string> = new Set(
    data?.allSitePage?.nodes == null ? [] : data.allSitePage.nodes.map((node) => node.path)
  );

  const isGatsbyPath = (path: string): boolean => {
    const normalisedPath = trimPath(path) === '' ? '/' : `/${trimPath(path)}/`;

    return paths.has(normalisedPath);
  };

  return { paths, isGatsbyPath };
};
