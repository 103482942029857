import React from 'react';
import { IconProps } from 'src/components/icons/icon-types';

const RedesignDownChevron = ({ size, fill, svgStyle }: IconProps) => (
  <svg
    width={size ?? 15}
    height="11"
    viewBox="0 0 17 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={svgStyle}
  >
    <path
      d="M16.3652 1.60938C16.5508 1.75781 16.5508 2.05469 16.3652 2.24023L8.60938 9.99609C8.42383 10.1816 8.16406 10.1816 7.97852 9.99609L0.222656 2.24023C0.0371094 2.05469 0.0371094 1.75781 0.222656 1.60938L0.927734 0.867188C1.11328 0.681641 1.41016 0.681641 1.55859 0.867188L8.3125 7.58398L15.0293 0.867188C15.1777 0.681641 15.4746 0.681641 15.6602 0.867188L16.3652 1.60938Z"
      fill={fill}
    />
  </svg>
);

export default RedesignDownChevron;
