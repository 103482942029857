import React from 'react';
import { IconProps } from 'src/components/icons/icon-types';

const ArrowRightThin = ({ size, fill }: IconProps) => (
  <div style={{ width: size || '100%' }}>
    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.01562 0.748047C8.20117 0.5625 8.46094 0.5625 8.64648 0.748047L16.4766 8.57812C16.6621 8.76367 16.6621 9.02344 16.4766 9.20898L8.64648 17.0391C8.46094 17.2246 8.20117 17.2246 8.01562 17.0391L7.75586 16.7793C7.57031 16.5938 7.57031 16.334 7.75586 16.1484L14.3613 9.50586H0.445312C0.185547 9.50586 0 9.32031 0 9.06055V8.68945C0 8.4668 0.185547 8.24414 0.445312 8.24414H14.3613L7.75586 1.63867C7.57031 1.45312 7.57031 1.19336 7.75586 1.00781L8.01562 0.748047Z"
        fill={fill}
      />
    </svg>
  </div>
);

export default ArrowRightThin;
