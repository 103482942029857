import React from 'react';
import { IconProps } from './icon-types';

export const FlagFrance = ({ size }: IconProps) => (
  <svg
    viewBox="0 0 126 126"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: size, height: size, borderRadius: size }}
  >
    <g>
      <path d="M140.364 0.00585938H-14.3066V125.855H140.364V0.00585938Z" fill="#21409A" />
      <path d="M140.364 0.00585938H37.2461V125.859H140.364V0.00585938Z" fill="white" />
      <path d="M140.36 0.00585938H88.8027V125.859H140.36V0.00585938Z" fill="#EC1C24" />
    </g>
  </svg>
);
