import { BuildType } from 'src/enums/build/build-type';
import { colors } from 'src/styling/constants';

export const getFooterColors = (buildType: BuildType) => {
  switch (buildType) {
    case BuildType.HARBOUR: {
      return {
        backgroundColor: colors.harbourSlateGrey,
        heading: colors.harbourGold,
        text: 'white',
        textHighlight: colors.harbourGold,
        border: colors.harbourHeaderBorder,
      };
    }
    case BuildType.MERIDIAN: {
      return {
        backgroundColor: colors.meridianLightGrayFooter,
        heading: 'white',
        text: 'white',
        textHighlight: colors.meridianHighlight,
        border: colors.meridianDivider,
      };
    }
    default: {
      return {
        backgroundColor: colors.hessianBackground,
        heading: colors.capeCod,
        text: colors.capeCod,
        textHighlight: colors.camelot,
        border: colors.transparentGreenGrey,
      };
    }
  }
};
