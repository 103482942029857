import React, { useContext, useReducer } from 'react';
import Globe from 'src/components/icons/globe';
import { SiteContext } from 'src/context/site-context';
import { Styling } from 'src/context/styling';
import { flex } from 'src/styling/constants';
import { CSSInterpolation } from '@emotion/serialize';
import RedesignDownChevronThin from 'src/components/icons/redesign-down-chevron-thin';
import mq, { mqReducedMotion } from 'src/styling/media-queries';
import { getFooterColors } from 'src/components/layout/footer/redesign/colors';
import { BuildType } from 'src/enums/build/build-type';
import AnimatedExpandWrapper from 'src/components/common/animated-expand-wrapper';

const createComponentStyling = (styling: Styling, buildType: BuildType, isOpen: boolean) => {
  const footerColors = getFooterColors(buildType);

  return {
    button: [
      flex.row,
      mq({
        gap: ['0.75rem', '0.5rem'],
        backgroundColor: 'unset',
        border: 'unset',
        color: 'inherit',
        padding: '0.25rem 0',
        alignItems: 'center',
        fontSize: '1rem',
        lineHeight: '125%',
        width: ['100%', 'auto'],
      }),
      {
        '&:hover': {
          path: {
            fill: `${footerColors.textHighlight} !important`,
          },
          color: footerColors.textHighlight,
        },
      },
    ],
    chevron: [
      mq({
        rotate: isOpen ? '180deg' : '0',
        marginLeft: ['auto', 0],
      }),
      mqReducedMotion({
        transition: ['rotate 0.4s', 'none'],
      }),
    ],
    linksWrapper: [
      flex.column,
      mq({
        paddingLeft: '2rem',
        paddingTop: '2rem',
        gap: ['1.5rem', '0.5rem'],
      }),
    ],
    link: [
      styling.fonts.regular,
      {
        'display': 'inline-block',
        'fontSize': '1rem',
        'lineHeight': '125%',
        '&:hover': {
          color: footerColors.textHighlight,
        },
      },
    ],
  } satisfies Record<string, CSSInterpolation>;
};

type CountrySelectionProps = {
  currentCountryText: string;
  links: readonly Queries.FooterLink[];
};

const CountrySelection = ({ currentCountryText, links }: CountrySelectionProps) => {
  const { styling, buildType } = useContext(SiteContext);

  const [isOpen, toggleOpen] = useReducer((isOpen) => !isOpen, false);

  const componentStyling = createComponentStyling(styling, buildType, isOpen);
  const footerColors = getFooterColors(buildType);

  return (
    <div>
      <button type="button" css={componentStyling.button} onClick={toggleOpen}>
        <Globe fill={footerColors.heading} />
        <div css={styling.fonts.regular}>{currentCountryText}</div>
        <div css={componentStyling.chevron}>
          <div css={mq({ display: ['none', 'unset'] })}>
            <RedesignDownChevronThin size="0.875rem" fill={footerColors.text} />
          </div>
          <div css={mq({ display: ['unset', 'none'] })}>
            <RedesignDownChevronThin size="1.25rem" fill={footerColors.text} />
          </div>
        </div>
      </button>
      <AnimatedExpandWrapper
        isExpanded={isOpen}
        containerStyles={
          isOpen
            ? mq({
                marginBottom: ['-1.5rem', '2.5rem'],
              })
            : null
        }
      >
        <div css={componentStyling.linksWrapper}>
          {links.map(({ text, url, openInNewTab }) => (
            <div key={text} css={componentStyling.link}>
              <a
                href={url}
                target={openInNewTab ? '_blank' : undefined}
                rel={openInNewTab ? 'noopener noreferrer' : undefined}
              >
                {text}
              </a>
            </div>
          ))}
        </div>
      </AnimatedExpandWrapper>
    </div>
  );
};

export default CountrySelection;
