import { BuildType } from 'src/enums/build/build-type';

export const getAppLinkForBuildType = (buildType: BuildType) => {
  switch (buildType) {
    case BuildType.BLAZE_STUDIO:
      return 'http://hyperurl.co/yjur0j';
    default:
      return 'http://hyperurl.co/8ziigl';
  }
};

export const getPlayStoreLinkForBuildType = (buildType: BuildType) => {
  switch (buildType) {
    case BuildType.BLAZE_STUDIO:
      return 'https://play.google.com/store/apps/details?id=uk.co.blazeboutique.mobileapp';
    default:
      return 'https://play.google.com/store/apps/details?id=co.uk.davidlloyd.mobileapp';
  }
};

export const getAppStoreLinkForBuildType = (buildType: BuildType) => {
  switch (buildType) {
    case BuildType.BLAZE_STUDIO:
      return 'https://apps.apple.com/gb/app/blaze-studio/id1488597253';
    default:
      return 'https://apps.apple.com/gb/app/david-lloyd-clubs/id1037992616';
  }
};

export type SocialLinks = {
  instagram?: string;
  facebook?: string;
  youtube?: string;
  twitter?: string;
};

export const getSocialLinksForBuildType = (buildType: BuildType): SocialLinks => {
  switch (buildType) {
    case BuildType.DAVID_LLOYD_UK:
      return {
        instagram: 'https://www.instagram.com/davidlloyduk/',
        facebook: 'https://www.facebook.com/davidlloydclubsuk',
        youtube: 'https://www.youtube.com/user/davidlloydleisureltd',
        twitter: 'https://twitter.com/DavidLloydUK',
      };
    default:
      return null;
  }
};

export const isExternalLink = (link: string): boolean => {
  return link.startsWith('http://') || link.startsWith('https://') || link.startsWith('www.');
};

export const isJumpLink = (link: string): boolean => link.startsWith('#');
