import { GOOGLE_ANALYTICS_COOKIE } from 'src/cookies/google-analytics-cookie';

interface GaTracker {
  get: (key: string) => any;
}

interface GaClient {
  getAll: () => GaTracker[];
}

declare global {
  interface Window {
    dataLayer: unknown[];
    ga: GaClient;
  }
}

const ElementViewEvent = 'elementView';

export interface DataLayerElementView {
  event: 'elementView';
  element: string;
}

export interface DataLayerTransactionProduct {
  sku: string;
  name: string;
  category: string;
  price: number;
  quantity: number;
  brand?: string;
  variant?: string;
}

export interface DataLayerTransaction {
  transactionId: string;
  transactionAffiliation: string;
  transactionTotal: number;
  transactionTax: number;
  transactionShipping: number;
  transactionProducts: DataLayerTransactionProduct[];
  signupRef: string;
}

export interface DataLayerVariable {
  [key: string]: any;
}

export const addToDataLayer = (data: unknown) => {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(data);
  }
};

export const addEventToDataLayer = (event: string) => {
  addToDataLayer({
    event,
  });
};

export const addTransactionToDataLayer = (transaction: DataLayerTransaction) => {
  addToDataLayer(transaction);
};

export const addVarToDataLayer = (variable: DataLayerVariable) => {
  addToDataLayer(variable);
};

export const addElementViewToDataLayer = (element: string) => {
  if (element) {
    addToDataLayer({
      event: ElementViewEvent,
      element,
    });
  }
};

export const addElementViewToDataLayerWithParentAndChild = (parent: string, child: string) => {
  if (parent && child) {
    addElementViewToDataLayer(`${parent}/${child}`);
  }
};

export const getGoogleAnalyticsClientId = (): string => {
  // We get the google client id from the _ga cookie, relying on the structure defined in this (https://stackoverflow.com/questions/20053949/how-to-get-the-google-analytics-client-id) thread.
  // We cannot use google trackers here because the user needs to navigate to at least one page for the trackers to load. For example, if the user loads the
  // user loads the enquire-now page directly and sends an enquiry, then no trackers will be loaded and the google client ID will be undefined.
  const gaCookie = GOOGLE_ANALYTICS_COOKIE.getValue();
  const clientIdParts = gaCookie ? gaCookie.split('.') : undefined;
  return getGoogleClientId(clientIdParts);
};

const getGoogleClientId = (gaCookieParts: string[]): string => {
  if (!gaCookieParts || gaCookieParts.length <= 3) {
    return undefined;
  }
  const randomUserId = gaCookieParts[2];
  const timestamp = gaCookieParts[3];
  return `${randomUserId}.${timestamp}`;
};
