import React, { useContext } from 'react';
import mq from '../../../styling/media-queries';
import { Styling } from 'src/context/styling';
import { SiteContext } from 'src/context/site-context';
import FooterNavMenu from 'src/components/layout/footer/footer-nav-menu';
import { GlobalCmsContent } from 'src/content/global-content';
import FooterImages from 'src/components/layout/footer/footer-images';
import { fontSizes } from 'src/styling/constants';

const footerStyle = (styling: Styling) =>
  mq([
    {
      lineHeight: 1.25,
      padding: ['0', '2rem 4rem'],
      content: '" "',
      display: 'table',
      clear: 'both',
      color: styling.colors.footerText,
      backgroundColor: styling.colors.footerBackground,
      width: '100%',
      textAlign: ['center', 'left'],
    },
  ]);

const Footer = () => {
  const { styling, isGlobalPage } = useContext(SiteContext);
  const { footerContent } = useContext(GlobalCmsContent);
  const CopyrightAndVAT = () => (
    <div css={[styling.fonts.light, { color: styling.colors.copyrightGray }]}>
      {footerContent?.copyrightInformation && (
        <>
          <em css={{ fontWeight: 'bold', fontStyle: 'normal' }}>
            {footerContent?.copyrightInformation}
          </em>
          <br />
        </>
      )}
      {footerContent?.vatInformation} {footerContent?.registeredCompanyInformation}
    </div>
  );

  return (
    <div css={footerStyle(styling)}>
      <FooterNavMenu sections={footerContent?.navMenuSections} />
      <FooterImages sections={footerContent?.footerImagesSections} />
      <div
        css={mq({
          display: 'flex',
          flexDirection: ['column', 'row'],
          justifyContent: 'space-between',
        })}
      >
        <div
          css={mq({
            textAlign: ['center', 'left'],
            float: [null, 'left'],
            justifyContent: 'space-between',
            display: 'flex',
            flexDirection: 'column',
          })}
        >
          <CopyrightAndVAT />
          <div
            css={[
              styling.fonts.light,
              {
                fontSize: fontSizes.medium,
                paddingTop: '1rem',
              },
            ]}
          >
            {!isGlobalPage && footerContent?.imagesDisclaimer}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
