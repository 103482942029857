import { ClubIntroImageKey } from 'src/enums/club-intro-image';

export const getImagePathForClubFacilityIcon = (mobileNavIcon: string): string => {
  switch (mobileNavIcon) {
    case 'Spa':
      return '/fixed-images/club-facilities-mobile-nav-icons/spa.png';
    case 'Creche':
      return '/fixed-images/club-facilities-mobile-nav-icons/creche.png';
    case 'Gym':
      return '/fixed-images/club-facilities-mobile-nav-icons/gym.png';
    case 'Exercise Classes':
      return '/fixed-images/club-facilities-mobile-nav-icons/group-ex.png';
    case 'Kids Club':
      return '/fixed-images/club-facilities-mobile-nav-icons/kids.png';
    case 'Pools':
      return '/fixed-images/club-facilities-mobile-nav-icons/pool.png';
    case 'Tennis':
      return '/fixed-images/club-facilities-mobile-nav-icons/tennis.png';
    case 'Padel':
      return '/fixed-images/club-facilities-mobile-nav-icons/padel.png';
  }
};

export const getClubIntroImage = (
  introImageKey: ClubIntroImageKey | undefined
): Queries.ImageDetails | undefined => {
  if (introImageKey === undefined) {
    return undefined;
  }
  return clubIntroImages[introImageKey];
};

const clubIntroImages: { [key in ClubIntroImageKey]: Queries.ImageDetails } = {
  amMichelIntroClubImage: {
    imagePath: '/fixed-images/meridian-clubs/am_michel.jpg',
    alternateText: 'Am Michel club intro picture',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  eppendorfIntroClubImage: {
    imagePath: '/fixed-images/meridian-clubs/eppendorf.jpg',
    alternateText: 'Eppendorf club intro picture',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  barmbekIntroClubImage: {
    imagePath: '/fixed-images/meridian-clubs/barmbek.jpg',
    alternateText: 'Barmbek club intro picture',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  berlinSpandauIntroClubImage: {
    imagePath: '/fixed-images/meridian-clubs/berlin.jpg',
    alternateText: 'Berlin Spandau club intro picture',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  frankfurtIntroClubImage: {
    imagePath: '/fixed-images/germany-clubs/frankfurt.jpg',
    alternateText: 'Frankfurt club intro picture',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  alstertalIntroClubImage: {
    imagePath: '/fixed-images/meridian-clubs/alstertal.jpg',
    alternateText: 'Alstertal club intro picture',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  kielSophienhofIntroClubImage: {
    imagePath: '/fixed-images/meridian-clubs/kiel_sophienhof.jpg',
    alternateText: 'Kiel Sophienhof club intro picture',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  wandsbekIntroClubImage: {
    imagePath: '/fixed-images/meridian-clubs/wandsbek.jpg',
    alternateText: 'Wandsbek club intro picture',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  veigyIntroClubImage: {
    imagePath: '/fixed-images/france-clubs/veigy.jpg',
    alternateText: 'Veigy club intro picture',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  countryClubGenevaIntroClubImage: {
    imagePath: '/fixed-images/switzerland-clubs/country-club-geneva.jpg',
    alternateText: 'Country Club Geneva club intro picture',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  uccleIntroClubImage: {
    imagePath: '/fixed-images/belgium-clubs/uccle.jpg',
    alternateText: 'Uccle club intro picture',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  antwerpIntroClubImage: {
    imagePath: '/fixed-images/belgium-clubs/antwerp.jpg',
    alternateText: 'Antwerp club intro picture',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  sterrebeekIntroClubImage: {
    imagePath: '/fixed-images/belgium-clubs/sterrebeek.jpg',
    alternateText: 'Sterrebeek club intro picture',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  badHomburgIntroClubImage: {
    imagePath: '/fixed-images/germany-clubs/bad-homburg.jpg',
    alternateText: 'Bad Homburg club intro picture',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  chelseaIntroClubImage: {
    imagePath: '/fixed-images/harbour-clubs/chelsea.jpg',
    alternateText: 'Chelsea club intro picture',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  nottingHillIntroClubImage: {
    imagePath: '/fixed-images/harbour-clubs/notting-hill.jpg',
    alternateText: 'Notting Hill club intro picture',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  dublinRiverviewIntroClubImage: {
    imagePath: '/fixed-images/ireland-clubs/dublin-riverview.jpg',
    alternateText: 'Dublin Riverview club intro picture',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  malaspinaIntroClubImage: {
    imagePath: '/fixed-images/italy-clubs/malaspina.jpg',
    alternateText: 'Malaspina club intro picture',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  modenaIntroClubImage: {
    imagePath: '/fixed-images/italy-clubs/modena.jpg',
    alternateText: 'Modena club intro picture',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  rotterdamBlijdorpIntroClubImage: {
    imagePath: '/fixed-images/netherlands-clubs/rotterdam-blijdorp.jpg',
    alternateText: 'Rotterdam Blijdorp club intro picture',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  amsterdamIntroClubImage: {
    imagePath: '/fixed-images/netherlands-clubs/amsterdam.jpg',
    alternateText: 'Amsterdam club intro picture',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  capelleIntroClubImage: {
    imagePath: '/fixed-images/netherlands-clubs/capelle.jpg',
    alternateText: 'Capelle club intro picture',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  rotterdamCentrumIntroClubImage: {
    imagePath: '/fixed-images/netherlands-clubs/rotterdam-centrum.jpg',
    alternateText: 'Rotterdam Centrum club intro picture',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  utrechtIntroClubImage: {
    imagePath: '/fixed-images/netherlands-clubs/utrecht.jpg',
    alternateText: 'Utrecht club intro picture',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  eindhovenIntroClubImage: {
    imagePath: '/fixed-images/netherlands-clubs/eindhoven.jpg',
    alternateText: 'Eindhoven club intro picture',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  turoIntroClubImage: {
    imagePath: '/fixed-images/spain-clubs/turo.jpg',
    alternateText: 'Turo club intro picture',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  aravacaIntroClubImage: {
    imagePath: '/fixed-images/spain-clubs/aravaca.jpg',
    alternateText: 'Aravaca club intro picture',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  gavaMarIntroClubImage: {
    imagePath: '/fixed-images/spain-clubs/gava-mar.jpg',
    alternateText: 'Gava Mar club intro picture',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  malagaIntroClubImage: {
    imagePath: '/fixed-images/spain-clubs/malaga.jpg',
    alternateText: 'Málaga club intro picture',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  zaragozaIntroClubImage: {
    imagePath: '/fixed-images/spain-clubs/zaragoza.jpg',
    alternateText: 'Zaragoza club intro picture',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  laFincaIntroClubImage: {
    imagePath: '/fixed-images/spain-clubs/la-finca.jpg',
    alternateText: 'La Finca club intro picture',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  serranoIntroClubImage: {
    imagePath: '/fixed-images/spain-clubs/serrano.jpg',
    alternateText: 'Serrano club intro picture',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  boadillaIntroClubImage: {
    imagePath: '/fixed-images/spain-clubs/boadilla.jpg',
    alternateText: 'Boadilla club intro picture',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
};

const fixedImages: { [key in string]: Queries.ImageDetails } = {
  ...clubIntroImages,
  defaultFallbackImageForTimetablePopup: {
    imagePath: '/fixed-images/online-joining-landing.jpg',
    alternateText: 'Exercise class',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  meridianFallbackImageForTimetablePopup: {
    imagePath: '/fixed-images/meridian-class-image.jpg',
    alternateText: 'Meridian class',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  memberOffersHero: {
    imagePath: '/fixed-images/member-offers/memberOffersHero.jpeg',
    alternateText: 'Hero',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  memberOffersDLShopSmall: {
    imagePath: '/fixed-images/member-offers/davidLloydShopSmallPlaceholder.png',
    alternateText: 'David Lloyd Shop',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  memberOffersDLShopBig: {
    imagePath: '/fixed-images/member-offers/davidLloydShopBigPlaceholder.png',
    alternateText: 'David Lloyd Shop',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  memberOffersForeoBackground: {
    imagePath: '/fixed-images/member-offers/foreoBackground.jpg',
    alternateText: 'Foreo',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  memberOffersForeoLogo: {
    imagePath: '/fixed-images/member-offers/foreoLogo.png',
    alternateText: 'Foreo Logo',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  memberOffersPlayBraveBackground: {
    imagePath: '/fixed-images/member-offers/playBraveBackground.jpg',
    alternateText: 'Play Brave',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  memberOffersPlayBraveLogo: {
    imagePath: '/fixed-images/member-offers/playBraveLogo.png',
    alternateText: 'Play Brave Logo',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  memberOffersYoBackground: {
    imagePath: '/fixed-images/member-offers/yoBackground.png',
    alternateText: 'Yo',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  memberOffersYoLogo: {
    imagePath: '/fixed-images/member-offers/yoLogo.png',
    alternateText: 'Yo! Logo',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  memberOffersGate8Background: {
    imagePath: '/fixed-images/member-offers/gate8Background.png',
    alternateText: 'Gate 8',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  memberOffersGate8Logo: {
    imagePath: '/fixed-images/member-offers/gate8Logo.png',
    alternateText: 'Gate 8 Logo',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  memberOffersCasallBackground: {
    imagePath: '/fixed-images/member-offers/casallBackground.jpg',
    alternateText: 'Casall',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  memberOffersCasallLogo: {
    imagePath: '/fixed-images/member-offers/casallLogo.png',
    alternateText: 'Casall Logo',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  memberOffersElemisBackground: {
    imagePath: '/fixed-images/member-offers/elemisBackground.png',
    alternateText: 'Elemis',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  memberOffersElemisLogo: {
    imagePath: '/fixed-images/member-offers/elemisLogo.png',
    alternateText: 'Elemis Logo',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  memberOffersSportsPursuitBackground: {
    imagePath: '/fixed-images/member-offers/sportsPursuitBackground.png',
    alternateText: 'Sports Pursuit',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  memberOffersSportsPursuitLogo: {
    imagePath: '/fixed-images/member-offers/sportsPursuitLogo.png',
    alternateText: 'Sports Pursuit Logo',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  memberOffersTheTimesBackground: {
    imagePath: '/fixed-images/member-offers/theTimesBackground.jpg',
    alternateText: 'The Times',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  memberOffersTheTimesLogo: {
    imagePath: '/fixed-images/member-offers/theTimesLogo.png',
    alternateText: 'The Times Logo',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  memberOffersNeilsonBackground: {
    imagePath: '/fixed-images/member-offers/neilson-banner.jpg',
    alternateText: 'Neilson',
    horizontalFocus: 'start',
    leftHighlight: false,
  },
  memberOffersNeilsonLogo: {
    imagePath: '/fixed-images/member-offers/neilson-logo.png',
    alternateText: 'Neilson Logo',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  appStore: {
    imagePath: '/fixed-images/app-store.png',
    alternateText: 'Download the iOS app',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  playStore: {
    imagePath: '/fixed-images/play-store.png',
    alternateText: 'Download the Android app',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  davidLloydLogo: {
    imagePath: '/fixed-images/david-lloyd-logo.svg',
    alternateText: 'The David Lloyd Clubs logo',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  whiteDavidLloydLogo: {
    imagePath: '/fixed-images/white-david-lloyd-logo.png',
    alternateText: 'The David Lloyd Clubs logo',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  onlineJoiningMobileApp: {
    imagePath: '/fixed-images/online-joining-mobile-app.png',
    alternateText: 'The David Lloyd Clubs app',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  onlineJoiningHarbourMobileApp: {
    imagePath: '/fixed-images/harbour-mobile-app.png',
    alternateText: 'The Harbour Club app',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  onlineJoiningMeridianMobileApp: {
    imagePath: '/fixed-images/meridian-mobile-app.png',
    alternateText: 'The Meridian app',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  penneArrabbiata: {
    imagePath: '/fixed-images/penne-arrabbiata.jpeg',
    alternateText: 'A freshly prepared plate of penne arrabbiata',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  healthyBreakfasts: {
    imagePath: '/fixed-images/kids-breakfast.jpeg',
    alternateText: 'Healthy fruit and grains making up a freshly prepared breakfast bowl',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  buildYourPizza: {
    imagePath: '/fixed-images/build-your-pizza.jpeg',
    alternateText: 'A pizza with a friendly smile in pepperoni and tomato sauce',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  strengthAndConditioning: {
    imagePath: '/fixed-images/strength-and-conditioning.jpg',
    alternateText: '-',
    horizontalFocus: 'left',
    leftHighlight: false,
  },
  holistic: {
    imagePath: '/fixed-images/holistic.jpg',
    alternateText: '-',
    horizontalFocus: 'left',
    leftHighlight: false,
  },
  highEnergy: {
    imagePath: '/fixed-images/high-energy.jpg',
    alternateText: '-',
    horizontalFocus: 'left',
    leftHighlight: false,
  },
  groupCycling: {
    imagePath: '/fixed-images/cycling.jpg',
    alternateText: 'T-',
    horizontalFocus: 'left',
    leftHighlight: false,
  },
  hiit: {
    imagePath: '/fixed-images/hiit.jpg',
    alternateText: '-',
    horizontalFocus: 'left',
    leftHighlight: false,
  },
  explore: {
    imagePath: '/fixed-images/explore.jpg',
    alternateText: 'A member exercising in the club',
    horizontalFocus: 'left',
    leftHighlight: false,
  },
  activityCreche: {
    imagePath: '/fixed-images/creche-3.jpg',
    alternateText: 'Children playing with gymastics ribbons',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  activitySoftPlay: {
    imagePath: '/fixed-images/soft-play.jpg',
    alternateText: 'Kids running and jumping through the soft play area',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  activityKidsActivities: {
    imagePath: '/fixed-images/kids-activities.jpg',
    alternateText: 'Kids indulging their creative skills in an art workshop',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  blazeBooking: {
    imagePath: '/fixed-images/blaze-booking.jpg',
    alternateText: 'Booking a BLAZE class on the mobile app',
    horizontalFocus: 'right',
    leftHighlight: false,
  },
  blazeBoxing: {
    imagePath: '/fixed-images/blaze-boxing.jpg',
    alternateText: 'Boxing at a BLAZE class',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  blazeCombat: {
    imagePath: '/fixed-images/blaze-combat.jpg',
    alternateText: 'Combat at a BLAZE class',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  blazeCycling: {
    imagePath: '/fixed-images/blaze-cycling.jpg',
    alternateText: 'Cycling at a BLAZE class',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  blazeInstructor: {
    imagePath: '/fixed-images/blaze-instructor.jpg',
    alternateText: 'BLAZE instructor with MyZone information',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  blazeTrainer1: {
    imagePath: '/fixed-images/blaze-trainer-1.jpg',
    alternateText: 'A blaze trainer smiling',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  blazeTrainer2: {
    imagePath: '/fixed-images/blaze-trainer-2.jpg',
    alternateText: 'A blaze trainer smiling',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  myZone: {
    imagePath: '/fixed-images/my-zone.png',
    alternateText: 'MyZone bracelet',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  trackYourProgress: {
    imagePath: '/fixed-images/track-your-progress.jpg',
    alternateText: 'Track your progress',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  whiteBlazeStudioLogo: {
    imagePath: '/fixed-images/white-blaze-studio-logo.png',
    alternateText: 'White BLAZE studio logo',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  whiteBlazeLogo: {
    imagePath: '/fixed-images/white-blaze-logo.png',
    alternateText: 'White BLAZE logo',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  yellowBlazeLogo: {
    imagePath: '/fixed-images/yellow-blaze-logo.svg',
    alternateText: 'Yellow BLAZE logo',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  trial: {
    imagePath: '/fixed-images/membership-trial-2.jpg',
    alternateText: 'Enjoy a trial at David Lloyd',
    horizontalFocus: 'left',
    leftHighlight: false,
  },
  trialMembershipCard: {
    imagePath: '/fixed-images/membership-trial-card.png',
    alternateText: '14 day trial membership card',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  harbourLogo: {
    imagePath: '/fixed-images/harbour-logo.png',
    alternateText: 'Harbour club logo',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  whiteHarbourLogo: {
    imagePath: '/fixed-images/white-harbour-logo.png',
    alternateText: 'Harbour club logo',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  lightBgMeridianLogo: {
    imagePath: '/fixed-images/meridian-logo-light-bg.png',
    alternateText: 'Meridian club logo',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  darkBgMeridianLogo: {
    imagePath: '/fixed-images/meridian-logo-dark-bg.png',
    alternateText: 'Meridian club logo',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  onlineJoiningLandingImage: {
    imagePath: '/fixed-images/online-joining-landing.jpg',
    alternateText: 'Join online',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
  harbourJoinImage: {
    imagePath: '/fixed-images/harbour-join.jpg',
    alternateText: 'Join online',
    horizontalFocus: 'center',
    leftHighlight: false,
  },
};

export default fixedImages;
