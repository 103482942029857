import { LinksLookup } from 'src/translators/links-lookup';

// When modifying an existing entry here, you might need to add an NGINX redirect from the old URL to the new one,
// in belgium.template
export const linksLookup: LinksLookup = {
  'book-a-tour': 'reserver-une-visite',
  'club-facilities': 'equipements-du-club',
  'club-treatments': 'club-treatments',
  'club-fitness': 'club-fitness',
  'creche': 'creche',
  'exercise-classes': 'cours-de-gym',
  'gym': 'salle-de-fitness',
  'sports': 'sports',
  'kids-classes': 'cours-pour-enfants',
  'children': 'enfants',
  'pools': 'piscines',
  'spa': 'spa',
  'tennis': 'tennis',
  'enquire-now': 'se-renseigner',
  'family-and-kids': 'famille-et-enfants',
  'golf': 'golf',
  'join-now': 'sinscrire',
  'membership-trial': 'abonnement-dessai',
  'relax-and-socialise': 'detendez-vous-et-faites-des-rencontres',
  'timetable': 'horaires',
  'yoga': 'yoga',
  'blaze': 'blaze',
  'personal-training': 'entraînement-particulier',
  'join': 'nous-rejoindre',
  // If this link is changed, we should also update the links stored in CC
  // E.g. see DLLABS-10292
  'join-online': 'joindre-en-ligne',
  'treatments': 'face-body-spa',
  'global-enquire-now': 'se-renseigner',
  'global-enquire-now-campaign-1': 'campaign-1',
  'global-enquire-now-campaign-2': 'campaign-2',
  'global-enquire-now-campaign-3': 'campaign-3',
  'referral': 'invitation',
  'friend': 'ami',
  'memberships': 'adhesions',
  'terms-and-conditions': 'termes-et-conditions',
  'policy': 'politique',
  'gyms-in-brussels': 'gyms-à-bruxelles',
};
