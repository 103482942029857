/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import { updateChatVariables } from 'src/helpers/puzzel';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { userHasConsentedToAnalyticsCookieCategory } from './src/cookies/cookie-types/analytical-cookies';
import { buildDomain } from './build/environment';
import { getPrimaryBuildTypeForBuildDomain } from './src/enums/build/build-domain';
import { GatsbyBrowser } from 'gatsby';
import PageLayout from 'src/components/layout/page-layout';
import { PageContext } from 'src/components/common/page-context';
import { TemplatePageContext } from 'src/components/common/template-page-context';

let amplitudeInitialized = false;

const loadAmplitude = () => {
  if (!amplitudeInitialized) {
    import('@amplitude/analytics-browser').then(({ init, add, setOptOut }) => {
      (window as any).launchAmplitude = function ({ allow }: { allow: boolean }) {
        setOptOut(!allow);
      };

      const sessionReplayTracking = sessionReplayPlugin({
        sampleRate: 0.03,
      });

      const buildType = getPrimaryBuildTypeForBuildDomain(buildDomain);

      const isAmplitudeAllow = userHasConsentedToAnalyticsCookieCategory(buildType);

      add(sessionReplayTracking);

      init(process.env.GATSBY_AMPLITUDE_API_KEY, {
        serverZone: 'EU',
      });

      setOptOut(!isAmplitudeAllow);

      amplitudeInitialized = true;
    });
  }
};

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
  // IntersectionObserver polyfill for gatsby-image (Safari, IE)
  if (typeof window.IntersectionObserver === 'undefined') {
    // @ts-ignore
    import(`intersection-observer`);
  }

  if (typeof window.fetch === 'undefined') {
    // @ts-ignore
    import('whatwg-fetch');
  }

  // Object-fit/Object-position polyfill for gatsby-image (IE)
  const testImg = document.createElement(`img`);
  if (
    typeof testImg.style.objectFit === 'undefined' ||
    typeof testImg.style.objectPosition === 'undefined'
  ) {
    // @ts-ignore
    import('object-fit-images').then(({ default: ObjectFitImages }) => ObjectFitImages());
  }

  if (!amplitudeInitialized && typeof window !== 'undefined') {
    window.addEventListener('load', loadAmplitude, { once: true });
  }
};

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location }) => {
  updateChatVariables({ page: location.pathname });
};

export const wrapPageElement: GatsbyBrowser<
  unknown,
  PageContext | TemplatePageContext
>['wrapPageElement'] = ({ element, props }) => {
  return <PageLayout props={props}>{element}</PageLayout>;
};
