import React, { useContext } from 'react';

import { getTimedPromotionForClub } from '../common/promo/timed-promotions-helper';
import { SiteContext } from 'src/context/site-context';
import { PromotionContent } from 'src/models/cms/promotions-content';
import HeaderBanner from './header-banner';

type GlobalBannerProps = {
  timedPromotions: PromotionContent[];
};

const GlobalTimedBanner: React.FC<GlobalBannerProps> = ({ timedPromotions }) => {
  const { siteId } = useContext(SiteContext);
  const timedPromotion = getTimedPromotionForClub(siteId, timedPromotions);

  if (!timedPromotion || !timedPromotion.promotionDetails.globalBanner.showBanner) {
    return null;
  }

  const content = timedPromotion.promotionDetails.globalBanner;

  return <HeaderBanner content={content} />;
};

export default GlobalTimedBanner;
