export enum ButtonStyle {
  TRANSPARENT = 'transparent',
  SECONDARY_TRANSPARENT = 'secondary_transparent',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  ALERT = 'alert',
}

export enum RedesignButtonStyle {
  PLUM = 'plum',
  DARK_GREEN = 'dark green',
  TRANSPARENT = 'transparent',
  WHITE_WITH_PLUM_FONT = 'white with plum font',
  WHITE_WITH_GREEN_FONT = 'white with green font',
}
