import { BuildType } from 'src/enums/build/build-type';
import { colors } from 'src/styling/constants';

export type Colors = {
  bodyText: string;
  highlight: string;
  highlightText: string;
  headerNavigationText: string;
  background: string;
  backgroundLight: string;
  border: string;
  headerBorder: string;
  fadedHighlight: string;
  darkHighlight: string;
  secondaryBackground: string;
  grayBackground: string;
  copyrightGray: string;
  secondaryHighlight: string;
  footerText: string;
  footerNavMenuTitle: string;
  alert: string;
  headerBackground: string;
  headerClubName: string;
  goldHighlight: string;
  darkGoldHighlight: string;
  successGreen: string;
  paleRed: string;
  footerBackground: string;
  quadrantTextBorder?: string;
  lightGrayText?: string;
  modalCloseButtonBackgroundColor: string;
  promoBannerColor: string;
  specialOfferTextColor: string;
  promoBannerTitleColor: string;
  promoDescriptionColour: string;
  packageDrawbackColor: string;
  mobileBannerColor: string;
  paymentBlockBackgroundColor: string;
  paymentBlockTitleColor: string;
  timetableCardBackground: string;
  timetableCardText: string;
  popupSecondaryActionBackground?: string;
  popupSecondaryActionBorder?: string;
  subtitledLinkImageSubtitleBackground: string;
  subtitledLinkImageSubtitleText: string;
  packageCardPriceBanner: string;
  focusOutlineColor: string;
  clubFinderHover: string;
  comingSoonHero: string;
  comingSoonHeroBorder: string;
  clubFinderText: string;
  clubFinderSearch: string;
  clubFinderComingSoonBackground: string;
  clubFinderComingSoonBorder: string;
  clubFinderComingSoonText: string;
  /* NWP colors */
  accordionIcon: string;
  introText: string;
  darkGreen: string;
  cream: string;
  camelot: string;
  capeCod: string;
  hessianBackground: string;
  vibrant: string;
  brightYellow: string;
  outerSpace: string;
  sageBackground: string;
  pineGlade: string;
  blackHaze: string;
  edward: string;
  ash: string;
  davidLloydGray: string;
  brightGrey: string;
  transparentGreenGrey: string;
  grayOlive: string;
  greenSmoke: string;
  transparentGrayOlive: string;
};

export const getColors = (buildType: BuildType): Colors => {
  switch (buildType) {
    case BuildType.BLAZE_STUDIO:
      return {
        bodyText: 'white',
        highlight: colors.blazeOrange,
        highlightText: colors.blazeOrange,
        headerNavigationText: colors.blazeOrange,
        background: 'black',
        backgroundLight: 'black',
        border: colors.blazeDividerGray,
        fadedHighlight: colors.blazeGray,
        secondaryBackground: 'black',
        darkHighlight: colors.blazeOrange,
        grayBackground: colors.blazeIconBackgroundGray,
        headerBorder: colors.blazeDividerGray,
        copyrightGray: colors.blazeGray,
        secondaryHighlight: colors.blazeOrange,
        footerText: colors.lightSilver,
        footerNavMenuTitle: colors.lightSilver,
        headerBackground: 'black',
        headerClubName: colors.lightSilver,
        alert: colors.alert,
        goldHighlight: colors.highlightGold,
        darkGoldHighlight: colors.harbourDarkGold,
        successGreen: colors.davidLloydTeal,
        paleRed: colors.paleRed,
        footerBackground: 'black',
        modalCloseButtonBackgroundColor: colors.blazeGray,
        promoBannerColor: colors.highlightGold,
        specialOfferTextColor: 'white',
        promoBannerTitleColor: 'white',
        promoDescriptionColour: colors.blazeOrange,
        packageDrawbackColor: colors.blazeGray,
        mobileBannerColor: colors.blazeOrange,
        paymentBlockBackgroundColor: colors.paleGrey,
        paymentBlockTitleColor: colors.capeCod,
        timetableCardBackground: 'white',
        timetableCardText: 'black',
        subtitledLinkImageSubtitleBackground: 'black',
        subtitledLinkImageSubtitleText: 'white',
        packageCardPriceBanner: colors.beigeGrey,
        focusOutlineColor: colors.blazeGray,
        clubFinderHover: colors.clubFinderHoverPurple,
        comingSoonHero: colors.comingSoonPurple,
        comingSoonHeroBorder: colors.comingSoonBorderPurple,
        clubFinderText: colors.clubFinderTextGrey,
        clubFinderSearch: colors.clubFinderSearchGrey,
        clubFinderComingSoonBackground: 'white',
        clubFinderComingSoonBorder: colors.buttonDisabled,
        clubFinderComingSoonText: colors.davidLloydPurple,
        /* NWP colors */
        accordionIcon: colors.copyrightGray,
        introText: colors.introText,
        darkGreen: colors.darkGreen,
        cream: colors.cream,
        camelot: colors.camelot,
        capeCod: colors.capeCod,
        hessianBackground: colors.hessianBackground,
        vibrant: colors.vibrant,
        brightYellow: colors.brightYellow,
        outerSpace: colors.outerSpace,
        sageBackground: colors.sageBackground,
        pineGlade: colors.pineGlade,
        blackHaze: colors.blackHaze,
        edward: colors.edward,
        ash: colors.ash,
        davidLloydGray: colors.hessianBackground,
        brightGrey: colors.brightGrey,
        transparentGreenGrey: colors.transparentGreenGrey,
        grayOlive: colors.grayOlive,
        greenSmoke: colors.greenSmoke,
        transparentGrayOlive: colors.transparentGrayOlive,
      } as Colors;
    case BuildType.HARBOUR:
      return {
        bodyText: colors.harbourSlateGrey,
        highlight: colors.harbourGold,
        highlightText: colors.harbourGold,
        headerNavigationText: colors.harbourGold,
        background: colors.davidLloydGray,
        backgroundLight: colors.davidLloydGray,
        border: colors.borderGrey,
        fadedHighlight: colors.fadedHarbourGold,
        secondaryBackground: 'white',
        darkHighlight: colors.harbourDarkGold,
        grayBackground: colors.davidLloydGray,
        headerBorder: colors.harbourHeaderBorder,
        copyrightGray: colors.lightGray,
        secondaryHighlight: colors.harbourGold,
        footerText: 'white',
        footerNavMenuTitle: colors.harbourGold,
        headerBackground: colors.harbourSlateGrey,
        headerClubName: 'white',
        alert: colors.alert,
        goldHighlight: colors.highlightGold,
        darkGoldHighlight: colors.harbourDarkGold,
        successGreen: colors.davidLloydTeal,
        paleRed: colors.paleRed,
        footerBackground: colors.harbourSlateGrey,
        modalCloseButtonBackgroundColor: colors.fadedHarbourGold,
        promoBannerColor: colors.harbourSlateGrey,
        specialOfferTextColor: colors.harbourGold,
        promoBannerTitleColor: 'white',
        promoDescriptionColour: colors.harbourSlateGrey,
        packageDrawbackColor: colors.silver,
        mobileBannerColor: colors.harbourGold,
        paymentBlockBackgroundColor: colors.paleGrey,
        paymentBlockTitleColor: colors.capeCod,
        timetableCardBackground: 'white',
        timetableCardText: colors.harbourSlateGrey,
        subtitledLinkImageSubtitleBackground: colors.harbourSlateGrey,
        subtitledLinkImageSubtitleText: 'white',
        packageCardPriceBanner: colors.beigeGrey,
        focusOutlineColor: colors.fadedHarbourGold,
        clubFinderHover: colors.fadedHarbourSlateGrey,
        comingSoonHero: colors.fadedHarbourGold,
        comingSoonHeroBorder: colors.harbourDarkGold,
        clubFinderText: colors.clubFinderTextGrey,
        clubFinderSearch: colors.clubFinderSearchGrey,
        clubFinderComingSoonBackground: 'white',
        clubFinderComingSoonBorder: colors.fadedHarbourGold,
        clubFinderComingSoonText: colors.harbourGold,
        /* NWP colors */
        accordionIcon: colors.copyrightGray,
        introText: colors.introText,
        darkGreen: colors.darkGreen,
        cream: colors.cream,
        camelot: colors.camelot,
        capeCod: colors.capeCod,
        hessianBackground: colors.hessianBackground,
        vibrant: colors.vibrant,
        brightYellow: colors.brightYellow,
        outerSpace: colors.outerSpace,
        sageBackground: colors.sageBackground,
        pineGlade: colors.pineGlade,
        blackHaze: colors.blackHaze,
        edward: colors.edward,
        ash: colors.ash,
        davidLloydGray: colors.davidLloydGray,
        brightGrey: colors.brightGrey,
        transparentGreenGrey: colors.transparentGreenGrey,
        grayOlive: colors.grayOlive,
        greenSmoke: colors.greenSmoke,
        transparentGrayOlive: colors.transparentGrayOlive,
      } as Colors;
    case BuildType.MERIDIAN:
      return {
        bodyText: colors.meridianBody,
        highlight: colors.meridianFieryYellow,
        highlightText: colors.meridianHighlight,
        headerNavigationText: colors.meridianBody,
        background: colors.meridianCoolGray,
        backgroundLight: colors.meridianBackgroundLight,
        border: colors.meridianDivider,
        fadedHighlight: colors.blazeGray,
        secondaryBackground: colors.meridianSecondaryBackground,
        popupSecondaryActionBackground: colors.meridianPopupSecondaryActionBackground,
        popupSecondaryActionBorder: colors.meridianPopupSecondaryActionBorder,
        darkHighlight: colors.blazeOrange,
        grayBackground: colors.meridianCoolGray,
        headerBorder: colors.meridianDivider,
        copyrightGray: colors.blazeGray,
        secondaryHighlight: colors.meridianSecondaryHighlight,
        footerText: 'white',
        footerNavMenuTitle: 'white',
        headerBackground: colors.meridianSecondaryBackground,
        headerClubName: colors.meridianBody,
        alert: colors.alert,
        goldHighlight: colors.highlightGold,
        darkGoldHighlight: colors.harbourDarkGold,
        successGreen: colors.davidLloydTeal,
        paleRed: colors.paleRed,
        footerBackground: colors.meridianLightGrayFooter,
        meridianDarkGrayFooter: colors.meridianDarkGrayFooter,
        quadrantTextBorder: colors.meridianDarkCoolGray,
        lightGrayText: colors.meridianLightGray,
        modalCloseButtonBackgroundColor: colors.meridianSecondaryHighlight,
        promoBannerColor: colors.meridianHighlightGrey,
        specialOfferTextColor: 'white',
        promoBannerTitleColor: 'white',
        promoDescriptionColour: colors.meridianFieryYellow,
        packageDrawbackColor: colors.blazeGray,
        mobileBannerColor: colors.meridianHighlight,
        paymentBlockBackgroundColor: 'white',
        paymentBlockTitleColor: colors.meridianBody,
        timetableCardBackground: 'white',
        timetableCardText: colors.meridianBody,
        subtitledLinkImageSubtitleBackground: colors.meridianBody,
        subtitledLinkImageSubtitleText: 'white',
        packageCardPriceBanner: colors.beigeGrey,
        focusOutlineColor: colors.meridianSecondaryHighlight,
        clubFinderHover: colors.clubFinderHoverPurple,
        comingSoonHero: colors.comingSoonPurple,
        comingSoonHeroBorder: colors.comingSoonBorderPurple,
        clubFinderText: colors.clubFinderTextGrey,
        clubFinderSearch: colors.clubFinderSearchGrey,
        clubFinderComingSoonBackground: 'white',
        clubFinderComingSoonBorder: colors.buttonDisabled,
        clubFinderComingSoonText: colors.davidLloydPurple,
        /* NWP colors */
        accordionIcon: colors.copyrightGray,
        introText: colors.introText,
        darkGreen: colors.darkGreen,
        cream: colors.cream,
        camelot: colors.camelot,
        capeCod: colors.capeCod,
        hessianBackground: colors.hessianBackground,
        vibrant: colors.vibrant,
        brightYellow: colors.brightYellow,
        outerSpace: colors.outerSpace,
        sageBackground: colors.sageBackground,
        pineGlade: colors.pineGlade,
        blackHaze: colors.blackHaze,
        edward: colors.edward,
        ash: colors.ash,
        davidLloydGray: colors.davidLloydGray,
        brightGrey: colors.brightGrey,
        transparentGreenGrey: colors.transparentGreenGrey,
        grayOlive: colors.grayOlive,
        greenSmoke: colors.greenSmoke,
        transparentGrayOlive: colors.transparentGrayOlive,
      } as Colors;
    default:
      return {
        bodyText: colors.capeCod,
        highlight: colors.camelot,
        highlightText: colors.camelot,
        headerNavigationText: colors.camelot,
        background: colors.hessianBackground,
        backgroundLight: colors.hessianBackground,
        border: colors.borderGrey,
        fadedHighlight: colors.buttonDisabled,
        secondaryBackground: colors.cream,
        darkHighlight: colors.davidLloydSecondaryPurple,
        grayBackground: colors.hessianBackground,
        headerBorder: colors.lightGray,
        copyrightGray: colors.copyrightGray,
        secondaryHighlight: colors.davidLloydTeal,
        footerText: colors.capeCod,
        footerNavMenuTitle: colors.capeCod,
        headerBackground: colors.cream,
        headerClubName: colors.capeCod,
        alert: colors.alert,
        goldHighlight: colors.highlightGold,
        darkGoldHighlight: colors.harbourDarkGold,
        successGreen: colors.davidLloydTeal,
        paleRed: colors.paleRed,
        footerBackground: colors.cream,
        modalCloseButtonBackgroundColor: colors.davidLloydTransparentPurple,
        promoBannerColor: colors.highlightGold,
        specialOfferTextColor: 'white',
        promoBannerTitleColor: colors.capeCod,
        promoDescriptionColour: colors.camelot,
        packageDrawbackColor: colors.copyrightGray,
        mobileBannerColor: colors.davidLloydTeal,
        paymentBlockBackgroundColor: colors.paleGrey,
        paymentBlockTitleColor: colors.capeCod,
        timetableCardBackground: colors.cream,
        timetableCardText: colors.capeCod,
        subtitledLinkImageSubtitleBackground: colors.camelot,
        subtitledLinkImageSubtitleText: 'white',
        packageCardPriceBanner: colors.beigeGrey,
        focusOutlineColor: colors.davidLloydTransparentPurple,
        clubFinderHover: colors.clubFinderHoverPurple,
        comingSoonHero: colors.comingSoonPurple,
        comingSoonHeroBorder: colors.comingSoonBorderPurple,
        clubFinderText: colors.clubFinderTextGrey,
        clubFinderSearch: colors.clubFinderSearchGrey,
        clubFinderComingSoonBackground: colors.cream,
        clubFinderComingSoonBorder: colors.buttonDisabled,
        clubFinderComingSoonText: colors.camelot,
        /* NWP colors */
        accordionIcon: colors.copyrightGray,
        introText: colors.introText,
        darkGreen: colors.darkGreen,
        cream: colors.cream,
        camelot: colors.camelot,
        capeCod: colors.capeCod,
        hessianBackground: colors.hessianBackground,
        vibrant: colors.vibrant,
        brightYellow: colors.brightYellow,
        outerSpace: colors.outerSpace,
        sageBackground: colors.sageBackground,
        pineGlade: colors.pineGlade,
        blackHaze: colors.blackHaze,
        edward: colors.edward,
        ash: colors.ash,
        davidLloydGray: colors.hessianBackground,
        brightGrey: colors.brightGrey,
        transparentGreenGrey: colors.transparentGreenGrey,
        grayOlive: colors.grayOlive,
        greenSmoke: colors.greenSmoke,
        transparentGrayOlive: colors.transparentGrayOlive,
      } as Colors;
  }
};
