import { colors, flex, fonts, styleInvalidSubmitButton } from 'src/styling/constants';
import mq from 'src/styling/media-queries';
import { Styling } from 'src/context/styling';
import { CSSInterpolation } from '@emotion/serialize';

export const spacerForUnderFooter = {
  ':after': {
    content: '""',
    display: 'block',
    height: '7rem',
  },
};

export const columnStyling = [
  flex.column,
  {
    flexBasis: 0,
    flex: 1,
  },
];

export const maxWidth = '27.625rem';

export const inputNoBottomBorderStyle: CSSInterpolation = { input: { borderBottomStyle: 'none' } };

export const fieldWrapperStyle = (
  styling: Styling,
  hideBottomBorder: boolean = false
): CSSInterpolation =>
  mq([
    flex.column,
    styling.fonts.light,
    {
      flexWrap: 'wrap',
      width: '100%',
      justifyContent: 'flex-start',
    },
    ...(hideBottomBorder ? [inputNoBottomBorderStyle] : []),
  ]);

export const labelStyle = (styling: Styling): CSSInterpolation =>
  mq([
    styling.fonts.bold,
    {
      color: colors.capeCod,
      position: 'absolute',
      visibility: 'hidden',
    },
  ]);

export const journeyButtonPadding: CSSInterpolation = { padding: '0.8rem 1rem' };

export const formInnerWrapperStyling = mq.withSmallTablet({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  margin: '0 auto',
  maxWidth,
  fontSize: '1rem',
  alignItems: 'center',
  justifyContent: 'center',
});

export const formPadding: CSSInterpolation = mq.withSmallTablet({
  padding: ['0 1rem', '0 4rem', '0 4rem'],
});

export const formWrapperStyling = (styling: Styling) => [
  mq.withSmallTablet({
    display: 'flex',
    flexDirection: 'column',
    fontSize: '1rem',
  }),
  formPadding,
  styleInvalidSubmitButton([styling.buttons.disabled, journeyButtonPadding]),
];

export const columnFormWrappingStyling = (styling: Styling) =>
  mq.withSmallTablet(formWrapperStyling(styling), {
    flexDirection: ['column', 'column', 'row'],
    height: ['auto', 'auto', 'calc(100vh - 5rem)'],
    paddingBottom: '4rem',
  });

export const rightColumnStyling = [
  columnStyling,
  mq({
    justifyContent: ['start', 'center'],
  }),
];

export const leftColumnStyling = mq.withSmallTablet([
  columnStyling,
  {
    maxHeight: ['12rem', '12rem', 'none'],
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
]);

export const lineBackgroundTitleWrapperStyling = mq({
  height: '2px',
  width: '60%',
  margin: '1.25rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  backgroundImage: `linear-gradient(90deg, rgba(255,255,255,0) 0%, ${colors.capeCod} 50%, rgba(255,255,255,0) 100%);`,
  textTransform: 'uppercase',
});

export const lineBackgroundTitleStyling = mq([
  fonts.bold,
  {
    backgroundColor: 'white',
    padding: '0.5rem',
    fontSize: '1.5rem',
  },
]);

export const h1TitleStyling = (styling: Styling) =>
  mq.withSmallTablet([
    styling.fonts.bold,
    {
      textTransform: 'uppercase',
      fontSize: ['2.5rem', '3rem', '5rem'],
    },
  ]);

export const h2TitleStyling = (styling: Styling) =>
  mq([
    styling.fonts.bold,
    {
      fontSize: ['2rem', '2.5rem'],
      textAlign: 'center',
      marginTop: ['1.5rem', '2.25rem'],
      marginBottom: ['1rem', '1.5rem'],
      width: '100%',
      color: styling.colors.bodyText,
    },
  ]);

export const h3TitleStyling = (styling: Styling) =>
  mq([
    styling.fonts.bold,
    {
      fontSize: ['1.5rem', '2rem'],
      textAlign: 'center',
      marginTop: ['1rem', '2rem'],
      width: '100%',
      color: styling.colors.bodyText,
    },
  ]);

export const stripeStyling = () =>
  mq([
    {
      height: '2px',
      width: '60%',
      backgroundImage:
        'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgb(80,60,50) 50%, rgba(255,255,255,0) 100%)',
      display: 'flex',
      alignSelf: 'center',
      margin: '1rem',
    },
  ]);

export const spacer = { marginTop: '1.5rem', marginBottom: '1.5rem' };

export const subtitleStyling = (styling: Styling) =>
  mq([
    styling.fonts.regular,
    {
      textAlign: 'center',
      fontSize: '1rem',
      marginBottom: ['1.5rem', '2.25rem'],
    },
  ]);

export const reminderTextStyling = (styling: Styling) =>
  mq([
    styling.fonts.light,
    {
      fontSize: '1rem',
      textAlign: 'center',
      padding: '0 1rem',
    },
  ]);
