import { LinksLookup } from 'src/translators/links-lookup';

// When modifying an existing entry here, you might need to add an NGINX redirect from the old URL to the new one,
// in spain.template
export const linksLookup: LinksLookup = {
  'book-a-tour': 'book-a-tour',
  'club-facilities': 'club-facilities',
  'club-treatments': 'club-treatments',
  'club-fitness': 'club-fitness',
  'creche': 'creche',
  'exercise-classes': 'exercise-classes',
  'gym': 'gym',
  'sports': 'sports',
  'kids-classes': 'kids-classes',
  'children': 'children',
  'pools': 'pools',
  'spa': 'spa',
  'tennis': 'tennis',
  'enquire-now': 'enquire-now',
  'family-and-kids': 'family-and-kids',
  'golf': 'golf',
  'join-now': 'join-now',
  'membership-trial': 'membership-trial',
  'relax-and-socialise': 'relax-and-socialise',
  'timetable': 'timetable',
  'yoga': 'yoga',
  'blaze': 'blaze',
  'personal-training': 'personal-training',
  'join': 'join',
  // If this link is changed, we should also update the links stored in CC
  // E.g. see DLLABS-10292
  'join-online': 'join-online',
  'treatments': 'face-body-spa',
  'global-enquire-now': 'enquire-now',
  'global-enquire-now-campaign-1': 'campaign-1',
  'global-enquire-now-campaign-2': 'campaign-2',
  'global-enquire-now-campaign-3': 'campaign-3',
  'referral': 'referral',
  'friend': 'friend',
  'memberships': 'memberships',
  'terms-and-conditions': 'terms-and-conditions',
  'policy': 'policy',
  'gyms-in-madrid': 'gyms-in-madrid',
};
