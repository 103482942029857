import { getClubInformation } from './club-look-ups-by-id';
import { Language } from '../enums/language';
import { isDefaultLanguageForClub } from '../helpers/language-helper';
import { BuildType, getOrderedLanguagesForBuildType } from '../enums/build/build-type';

export const CLUBS_NOT_IN_ABS: { [clubId: string]: string } = {
  '131': 'BLAZE Studio Birmingham',
};

export const BLAZE_SPECIFIC_CLUB_PAGES: string[] = ['/faqs/'];

export const DEFAULT_CLUB_ID = '2'; // Raynes Park

export const DEFAULT_CLUB_NAME = 'Raynes Park';

export const DEFAULT_BUILD_TYPE = BuildType.DAVID_LLOYD_UK;

export const PLACEHOLDER_FOLDER = 'placeholder';

export const SLIDER_AUTOPLAY_SPEED = 3000;

const dataRepositoryKey = (clubId: string | null, language: Language) => {
  if (clubId == null) {
    return PLACEHOLDER_FOLDER;
  }

  const clubInformation = getClubInformation(clubId);
  return clubInformation
    ? isDefaultLanguageForClub(clubId, language)
      ? clubInformation.hyphenatedClubName
      : `${clubInformation.hyphenatedClubName}_${language}`
    : PLACEHOLDER_FOLDER;
};

export const clubDataRepository = (clubId: string | null, language: Language) =>
  `${dataRepositoryKey(clubId, language)}/club`;

export const getGlobalDataFolderBase = (buildType: BuildType, language?: Language) => {
  const isDefaultLanguage =
    !language || getOrderedLanguagesForBuildType(buildType).default === language;
  return isDefaultLanguage ? `${buildType}-global` : `${buildType}-global_${language}`;
};

export const getGlobalDataFolder = (folder: string, buildType: BuildType, language?: Language) =>
  `${getGlobalDataFolderBase(buildType, language)}/${folder}`;

export const globalDataRepository = (language: Language, buildType: BuildType) =>
  getGlobalDataFolder('global', buildType, language);

export const cityPageDataRepository = (language: Language, buildType: BuildType) =>
  getGlobalDataFolder('global-city-page', buildType, language);

export const helpCentreCategoryRepository = (language: Language, buildType: BuildType) =>
  getGlobalDataFolder('global-help-centre-category', buildType, language);
