import React, { PropsWithChildren, ReactElement } from 'react';
import GlobalPageLayout from 'src/components/layout/global-page-layout';
import { PageContext, PageLayoutOptions, PageLayoutType } from 'src/components/common/page-context';
import SiteProvider from 'src/context/site-provider';
import ClubPageLayout from 'src/components/layout/club-page-layout';
import { TemplatePageContext } from 'src/components/common/template-page-context';
import { PageProps } from 'gatsby';
import { SimplePageLayout } from 'src/components/layout/simple-page-layout';

type LayoutProps = PropsWithChildren<{
  props: PageProps<unknown, PageContext | TemplatePageContext, unknown>;
}>;

const PageLayout = ({ props, children }: LayoutProps) => {
  const pageContext =
    (props.pageContext as TemplatePageContext).baseContext ?? (props.pageContext as PageContext);

  const pageLayout: PageLayoutOptions =
    props.location.pathname === '/admin/' ? { type: PageLayoutType.blank } : pageContext.pageLayout;

  const page: ReactElement = (() => {
    switch (pageLayout.type) {
      case PageLayoutType.blank:
        return <>{children}</>;
      case PageLayoutType.simple:
        return (
          <SimplePageLayout shouldShowFooter={pageLayout.shouldShowFooter ?? false}>
            {children}
          </SimplePageLayout>
        );
      case PageLayoutType.global:
        return (
          <GlobalPageLayout shouldShowFooter={pageLayout.shouldShowFooter ?? true}>
            {children}
          </GlobalPageLayout>
        );
      case PageLayoutType.club:
        return <ClubPageLayout siteId={pageContext.clubId}>{children}</ClubPageLayout>;
    }
  })();

  return <SiteProvider pageContext={pageContext}>{page}</SiteProvider>;
};

export default PageLayout;
