export const staticTextLookup = {
  FAQs: "FAQs",
  aCopyOfThisGuaranteeShouldBeRetained: "Eine Kopie dieser Garantie sollte vom Kunden aufbewahrt werden. Diese wird per E-Mail-Bestätigung zugesandt.",
  about: "Über uns",
  aboutYou: "Über Sie",
  accessToClubsAndSpaRetreats: "Platinum membership will also give you access to other David Lloyd clubs. See below for details of the clubs you'll be able to access.\nPlus, if the club has a Spa Retreat, this will also include access to the Spa Retreat facilities. Click here for a list of clubs with Spa Retreat.",
  accessToClubsNearYou: "Ihr Zugang zum Club in Ihrer Nähe:",
  accessToOtherClubs: "Zugang zu anderen Clubs",
  accountHoldersName: "Name des Kontoinhabers\n",
  accountNumber: "Kontonummer ",
  activating: "Aktivieren von\n",
  addPartnerAmount: "Fügen Sie einen Partner %amount% hinzu",
  addSelectedMembership: "Fügen Sie eine ausgewählte Mitgliedschaft hinzu",
  addSelectedPromotion: "Gewählte Werbeaktion hinzufügen",
  addToCalendar: "Termin Hinzufügen",
  addYourAssociates: "Ihren Partner und Ihre Kinder hinzufügen",
  addYourChildren: "Ihre Kinder hinzufügen",
  addYourPartner: "Ihren Partner hinzufügen",
  addYourPromotions: "Wählen Sie Ihr Angebot",
  additionalDetailsSecondErrorMessage: "Leider können wir Ihre Daten derzeit nicht aufnehmen und werden diese bei Ihrem ersten Clubbesuch vervollständigen.",
  additionalMembers: "Zusätzliche Mitglieder",
  additionalMemberships: "Zusätzliche Mitgliedschaften",
  address: "Adresse",
  addressLine1: "Adresszeile 1",
  addressLine2: "Adresszeile 2",
  addressLinePlaceholder: "Geben Sie Ihre Adresse ein",
  adultMembers: "Erwachsene Mitglieder",
  adults: "Erwachsene",
  afterSchool: "Nach der Schule",
  allAgesWelcome: "Alle Altersgruppen sind willkommen",
  allFacilities: "Alle Räumlichkeiten",
  alsoAtClub: "Auch bei %clubName%",
  amountSubjectToPendingPriceRise: "Der angegebene Betrag steht unter dem Vorbehalt einer ausstehenden Preiserhöhung. Weitere Informationen erhalten Sie bei der Klubmannschaft.",
  and: "und",
  annualOnJoiningTheClubPayment: "Wenn Sie dem Club beitreten, werden Sie gebeten, eine Zahlung zu leisten, die aus einer Beitrittsgebühr, einer anteiligen Zahlung für den Rest dieses Monats (falls zutreffend) und einer Zahlung zur Deckung der Mitgliedschaft für 12 Kalendermonate besteht. Wenn Sie Ihre Mitgliedserfahrung verbessern möchten, könnnen Sie zusätzliche Produkte wie Personal Training oder Coaching hinzufügen. Auch dafür können Sie heute bezahlen.",
  annually: "jährlich",
  anythingElseYoudLikeUsToKnow: "Gibt es noch etwas, was wir wissen sollen?",
  anytimeClubAccess: "Club-Zugang jeder Zeit",
  app: " App",
  appQrCodeInstructions: "Richten Sie einfach die Kamera Ihres Telefons über den Code aus und tippen Sie nach dem Erkennen auf den Link, um die %brandName% App herunterzuladen.",
  appUpdateRequired: "Ein App-Update ist erforderlich",
  areYouSureYouDoNotWantToBeContacted: "Bist du sicher, dass du nicht möchtest, dass wir dich per E-Mail oder SMS kontaktieren?",
  associatesWereReset: "Leider können die zusätzlichen Mitglieder, die Sie ausgewählt haben, in diesem Paket nicht aufgenommen werden - bitte wählen Sie ein anderes Paket, wenn Sie diese Mitglieder hinzufügen möchten.",
  atClub: "Bei %clubName%",
  atHomeDigitalContent: "Die digitale @home Inhalte umfassen Live- und On-Demand-Content, Schulungsseminare und Trainer-Tipps",
  atUnit: "Bei %unitName%",
  availablePromotions: "verfügbare Werkeaktionen",
  availableSlot: "%numberOfSlots% verfügbarer Termin",
  availableSlots: "%numberOfSlots% verfügbare Termine",
  awaitingPayment: "Awaiting payment",
  back: "Zurück",
  backToTop: "Zurück zum Anfang",
  beforeYouBecomeAMember: "Bevor Sie Mitglied werden, möchten wir Ihnen gerne zeigen, wie Sie am besten von unserem Club profitieren können",
  bestValue: "Günstigster Wert",
  bookAClass: "Jetzt reservieren",
  bookAClubWelcome: "Buchen Sie Willkommensmeeting",
  bookAClubWelcomeError: "Entschuldigen Sie bitte, es ist etwas schief gelaufen. Bitte setzen Sie sich mit uns in Verbindung, und wir werden einen Termin für Sie finden.",
  bookATour: "Buchen Sie eine Tour",
  bookATourError: "Entschuldigen Sie bitte, es ist etwas schief gelaufen. Bitte setzen Sie sich mit uns in Verbindung, und wir werden einen Termin für Sie finden.",
  bookATourHeader: "Buche Deine Tour",
  bookATourSubtext: "Wähle Deinen Club und buche eine Tour",
  bookOnTheMove: "Ihre Buchung wird bearbeitet",
  bookYourClubWelcome: "Buchen Sie Ihr Willkommensmeeting",
  bookYourClubWelcomeAt: "Buchen Sie Ihr Willkommensmeeting in %clubName%",
  bookYourTour: "Buchen Sie Ihre Tour",
  bookYourTourOf: "Buchen Sie Ihre Tour bei %clubName%",
  booked: "Nicht verfügbar",
  bookingYourClubWelcome: "Buchen Sie jetzt Ihr Willkommensmeeting...",
  bookingYourTour: "Wir buchen gerade Ihre Tour…",
  buyapowaWarningPopupCancelButtonText: "Bitte wählen Sie einen anderen Club.",
  buyapowaWarningPopupConfirmButtonText: "Treten Sie %clubName% bei",
  buyapowaWarningPopupHeader: "Beachten Sie",
  buyapowaWarningPopupText: "Club %clubName% ist derzeit nicht für unser Empfehlungsprogramm angemeldet, sodass weder Sie noch die Person, die Sie empfohlen hat, berechtigt sind, Prämien zu erhalten, wenn Sie mit dieser Anmeldung fortfahren.",
  byClickingFinishYouAcceptDDConditions: "Mit dem Klick auf '%buttonText%' bestätigen Sie, dass Sie der Kontoinhaber sind und das Konto keine weitere Person benötigt, um die Abbuchungen auf diesem Konto zu autorisieren. Wenn es sich um ein Privat- oder Geschäftskonto handelt und mehr als eine Person zur Autorisierung von Abbuchungen auf diesem Konto erforderlich ist, dann kontaktieren Sie uns bitte direkt.",
  cancel: "Cancel",
  cancelMyMembership: "Meine Mitgliedschaft kündigen",
  cantFindASlot: "Können Sie keinen freien Termin finden?",
  cardCollectionTime: "Mitgliedskarte sammeln/Ihre ClubTour",
  careers: "Für uns arbeiten",
  changeClub: "Club wechseln",
  changePreferences: "Einstellungen ändern",
  changeSelectedPackage: "Ändern Sie eine ausgewählte Mitgliedschaft",
  checkYourAccessToASpecificClub: "Überprüfen Sie Ihren Zugang zu einem spezifischen Club",
  child2AndUnder: "Mitgliedschaft für Kinder:\nBis zu 2 Jahre alt",
  child3To18: "Mitgliedschaft für Kinder:\n3-18 Jahre alt",
  childMembers: "Minderjährige Mitglieder",
  children: "Kinder",
  childrenOverview: "Kinderübersicht",
  chooseAClub: "Einen Club wählen",
  chooseAClubToRegisterForClubTrial: "Wählen Sie einen Club aus",
  chooseHowYouPay: "Wählen Sie Ihre Zahlungsmethode",
  chooseMembershipLength: "Wählen Sie die Dauer der Mitgliedschaft",
  chooseYourStartDate: "Wählen Sie Ihr Startdatum aus",
  clickHereIfYouAreNot: "Bitte klicken Sie hier, wenn Sie nicht %firstName% sind",
  close: "Schließen",
  closeDetails: "Details schließen",
  club: "Club",
  clubEmail: "Club E-Mail Adresse",
  clubFacilities: "Ausstattung des Clubs",
  clubFinderSearchPlaceholder: "Geben Sie einen Standort ein",
  clubOpeningHours: "Öffnungszeiten des Clubs",
  clubOpeningTimes: "Öffnungszeiten des Clubs",
  clubOverview: "Übersicht des Clubs",
  clubTourTime: "Termin der Club Tour",
  clubVideo: "Video des Clubs",
  clubWelcomeMeetingTime: "Persönliche Begrüßung Uhrzeit im Club",
  comingSoon: "Demnächst erhältlich",
  completeDirectDebitPopupText: "Sparen Sie Zeit bei Ihrem ersten Besuch, indem Sie Ihre Einzugsermächtigung jetzt erteilen",
  completeForm: "Bitte füllen Sie das untenstehende Formular aus.",
  completeItInClub: "Einzugsermächtigung im Club abschließen",
  completeItNow: "Einzugsermächtigung jetzt erteilen",
  completeSEPAInClub: "Einzugsermächtigung im Club abschließen",
  completeSEPANow: "Einzugsermächtigung jetzt erteilen",
  completeSEPAPopupText: "Sparen Sie Zeit bei Ihrem ersten Besuch, indem Sie Ihre Einzugsermächtigung jetzt erteilen",
  confirm: "Confirm",
  confirmNoPaymentTaken: "Please confirm no payment has been processed",
  confirmPassword: "Passwort bestätigen",
  confirmPasswordPlaceholder: "Bitte bestätigen Sie Ihr neues Passwort",
  confirmPaymentTaken: "Please confirm payment has been processed",
  contact: "Kontakt",
  contactInformation: "Kontaktangaben",
  contactMeToArrange: "Kontaktieren Sie mich",
  contactNotPromotedMonthlyPaymentErrorSubtitle: "Das Einrichten Ihrer Mitgliedschaft dauert nur einen kleinen Moment. Bitte versuchen Sie es erneut, Sie werden nicht doppelt berechnet.",
  contactNotPromotedMonthlyPaymentErrorTitle: "Tut uns leid, wir sind heute nicht ganz so schnell wie Sie!\n",
  contactPreferencesAfterPrivacyPolicy: "Kreuzen Sie einfach die Kästchen an, um in Kontakt zu bleiben.",
  contactPreferencesBeforePrivacyPolicy: "Wir würden gerne mit Ihnen in Kontakt bleiben und Ihnen mehr über unsere neuesten Produkte, Dienstleistungen und Angebote erzählen. Wenn Sie damit einverstanden sind, kreuzen Sie einfach die untenstehenden Kästchen an. Sie können Ihre Meinung jederzeit ändern. Weitere Einzelheiten finden Sie in unseren",
  contactTheClubForOpeningTimes: "Bitte kontaktieren Sie den Club für weitere Informationen zu den Öffnungszeiten",
  "continue": "Weiter",
  cookiePolicyDescription: "Unsere Website verwendet Cookies, um Ihre Erfahrungen zu verbessern. Durch die Nutzung der Website stimmen Sie der Verwendung von Cookies zu.",
  cookiesPolicy: "Richtlinien für Cookies",
  copyright: "Urheberrecht",
  coreStrengthAndHIIT: "Core-, Kraft-, Konditionstraining, HIIT, Wellness und Gesundheit, Yoga, Fahrrad",
  corporateFitness: "Firmen-Fitness",
  countryBelgium: "Belgium",
  countryFrance: "France",
  countryGermany: "Germany",
  countryIreland: "Ireland",
  countryItaly: "Italy",
  countryNetherlands: "Netherlands",
  countrySpain: "Spain",
  countrySwitzerland: "Switzerland",
  countryUnitedKingdom: "United Kingdom",
  county: "Landkreis",
  countyPlaceholder: "Wählen Sie Ihren Landkreis",
  couple: "Paar",
  creche: "Kinderbetreuung",
  creditorAddress: "Adresse",
  creditorId: "Glaubiger-Identifikationsnummer",
  creditorName: "Name des Kreditgebers",
  dateAtTime: "%date% um %time%",
  dateOfBirth: "Geburtsdatum",
  days: "Tagen",
  daytime: "Tagsüber",
  detailsAddedToMembershipAccount: "Diese Angaben werden jetzt zu Ihrem Mitglieds-Account hinzugefügt",
  diamondPlusRequired: "Diamond Plus membership is required to access certain Spa Retreats",
  directDebitClubOpens: "Ihr erster Lastschrifteinzug beginnt mit der Öffnung des Clubs unter Berücksichtigung des zum Zeitpunkt des Beitritts laufenden Angebots",
  directDebitClubReopens: "Ihre monatliche Abbuchung wird beginnen, wenn der Club neu öffnet. Dabei werden alle bestehenden Promotions für Neumitglieder berücksichtigt",
  directDebitMandate: "Lastschriftmandat",
  directDebitMembershipStarts: "Die erste Abbuchung beginnt mit dem Start der Mitgliedschaft unter Berücksichtigung des zum Zeitpunkt des Beitritts laufenden Angebots",
  discoverTheCreche: "Entdecken Sie die Baby- und Kinderbetreuung",
  done: "Fertig",
  downloadAppPrompt: "Um Buchungen vorzunehmen und Ihre Mitgliedschaft zu verwalten, laden Sie bitte die David Lloyd Clubs App herunter.",
  downloadDescriptionMembershipVerification: "Laden Sie unsere mobile App vor Ihrem Besuch herunter, damit wir Ihnen bei der Registrierung und der optimalen Nutzung Ihrer Mitgliedschaft helfen können.",
  downloadDescriptionTennis: "Laden Sie die App herunter, um unseren Kursplan anzusehen, Kurse zu buchen und Tennisplätze zu reservieren, wo immer Sie auch gerade sind.",
  downloadDescriptionTrialConfirmation: "Laden Sie die App vor Ihrem ersten Besuch herunter, damit wir Ihnen bei der Registrierung helfen und das Beste aus Ihrer Mitgliedschaft herausholen können.",
  downloadTheMobileAppHere: "Bitte laden Sie hier unsere mobile App herunter:",
  dr: "Dr.",
  duration: "Laufzeit",
  edit: "Bearbeiten",
  email: "E-Mail",
  emailAddress: "E-Mail-Adresse",
  emailAddressesMustBeUnique: "Bitte geben Sie eine eindeutige E-Mail-Adresse ein. Sie muss sich von der E-Mail-Adresse unterscheiden, die das Hauptmitglied angegeben hat",
  endsIn: "endet in",
  enjoyTimeTogetherAt: "Genießen Sie gemeinsame Zeit bei %clubPrefix% %clubName%",
  enquireAboutMembership: "Nach einer Mitgliedschaft erkundigen",
  enquireNow: "Jetzt anfragen",
  enquireText: "Was ist der Grund Ihrer Anfrage?",
  enterALocation: "Geben Sie Ihren Ort ein",
  enterValidEmailAddress: "Bitte geben Sie eine gültige E-Mail-Adresse ein",
  enterValidMobilePhoneNumber: "Bitte fügen Sie eine gültige Handynummer hinzu",
  enterValidName: "Bitte geben Sie einen gültigen Namen inklusive Grossbuchstaben ein.",
  enterValidPhoneNumber: "Bitte fügen Sie eine gültige Telefonnummer hinzu",
  enterValidPostCode: "Bitte geben Sie eine gültige Postleitzahl ein",
  enterValidTaxId: "Please enter a valid tax ID",
  equipmentAt: "Unsere Ausstattung bei %clubName%",
  exerciseClasses: "Fitness-Kurse",
  exerciseClassesAt: "Kurse bei %clubName%",
  experienceAllTheBenefits: "Erleben Sie alle Vorteile der Clubmitgliedschaft.",
  expertPersonalTrainers: "Professionelle persönliche Trainer",
  expertTuition: "Einführung durch Experten",
  exploreClub: "Entdecken Sie %clubName%",
  exploreKidsActivities: "Entdecken Sie unsere Aktivitäten für Kinder",
  failedToFetchExistingPayment: "A payment has already been made, but we encountered an error confirming details",
  familyAmpersandKids: "Familie & Kinder",
  familyAndKids: "Familie und Kinder",
  familySwim: "Familienschwimmen",
  female: "Weiblich",
  findAClub: "Club finden",
  findOutMore: "Erfahren Sie mehr",
  findYourClub: "Finden Sie Ihren Club",
  firstFullMonth: "Erster ganzer Monat",
  firstMonthFees: "Da wir nicht in der Lage sind, Ihr Lastschriftverfahren rechtzeitig für Ihren ersten vollen Monat einzurichten, wird diese Zahlung im Voraus eingezogen",
  firstName: "Vorname",
  fitnessOverview: "Übersicht",
  fixedTermFee: "Zwölfmonatige Mitgliedschaft ab %month%",
  fixedTermFeeTooltip: "Zahlung zur Deckung der Kosten einer Mitgliedschaft für 12 Kalendermonate",
  flexible: "Flexibel",
  followUs: "Folgen Sie uns",
  followedByMonthly: "Gefolgt von einer monatlichen Zahlung von",
  followedByMonthlyPaymentsTitle: "Monatliche Zahlungen",
  free: "Unentgeltlich",
  frequentlyAskedQuestions: "Häufig Gestellte Fragen",
  friend: "Freund/Freundin",
  friendsAndFamilyReferralAlreadyUsed: "Tut uns Leid, dieser Link wird bereits benutzt. Bitte wenden Sie sich an die Person, die Sie weiterempfohlen hat, damit sie den Vorgang wiederholt.",
  friendsAndFamilyReferralExpired: "Sorry, dieser Link ist abgelaufen. Bitte kontaktieren Sie die Person, die Sie empfohlen hat, um Sie erneut zu nominieren.",
  friendsAndFamilyReferralNotFound: "Tut uns Leid, dieser Link konnte nicht gefunden werden. Bitte wenden Sie sich an die Person, die Sie weiterempfohlen hat, damit sie den Vorgang wiederholt.",
  from: "Von",
  from3MonthsPlus: "Ab 3 Monaten",
  from3To18Years: "von 3-18 Jahren",
  gender: "Geschlecht",
  germanAndFallbackOnly: "Testen",
  getInTouch: "Nehmen Sie Kontakt auf",
  getStarted: "Anfangen",
  getYourQuoteContactPreferencesAfterPrivacyPolicy: ". Kreuzen Sie einfach das Kästchen an, um mit uns in Kontakt zu bleiben.",
  getYourQuoteContactPreferencesBeforePrivacyPolicy: "Wir würden gerne mit Ihnen in Kontakt bleiben und Ihnen mehr über unsere neuesten Produkte, Dienstleistungen und Angebote erzählen. Wenn Sie damit einverstanden sind, kreuzen Sie einfach das untenstehende Kästchen an. Sie können Ihre Meinung jederzeit ändern. Weitere Einzelheiten finden Sie in unseren",
  groupExerciseClasses: "Gruppenklassen",
  gym: "Gerätetraining",
  havingProblemsSavingMonthlyPaymentDetails: "Wir haben Probleme beim Speichern Ihrer monatlichen Zahlungsinformationen",
  healthyBreakfast: "Gesundes Frühstück",
  heatedSwimmingPools: "Beheizte Pools",
  highlightsAtClub: "Highlights bei %clubPrefix% %clubName%*",
  hours: "Stunden",
  howCanWeContactYou: "Wie können wir Sie erreichen?",
  iAgree: "Ich stimme zu",
  ifSomethingWereToChangeInYourLifestyle: "Sollte sich etwas an Ihrem Lebensstil ändern, hat %brandName% eine 14-tägige Bedenkzeit. Sprechen Sie mit einem Mitglied des Teams, um mehr zu erfahren.",
  ifSomethingWereToChangeRightToCancel: "Wenn sich etwas in Ihrem Lebensstil ändern sollte, haben Sie das Recht, zu kündigen. Sprechen Sie mit einem Mitarbeiter, um mehr zu erfahren.",
  imageDisclaimer: "*Disclaimer: Die Bilder dienen nur der Veranschaulichung und entsprechen möglicherweise nicht dem von Ihnen ausgewählten Club.",
  importantInformation: "Wichtige Informationen",
  includedInPromotion: "Im Angebot enthalten",
  individual: "Einzelperson",
  initialEnquiryKeepYouUpdated: "Nach Ihrer ersten Anfrage informieren wir Sie gerne über unsere neuesten Produkte, Dienstleistungen und Angebote. Bei Interesse klicken Sie einfach auf die Felder unten.",
  initialMonthTerm: "Laufzeit %monthDuration% Kalendermonate",
  isChildPresentText: "Ihr Kind ist heute mit Ihnen in den Club gekommen",
  isPartnerPresentText: "Mein Partner ist mit mir im Club",
  join: "Mitglied werden",
  joinCorporate: "Mitglied werden als Unternehmen",
  joinDifferentClub: "Treten Sie einem anderen Club bei",
  joinFriendsAndFamily: "Mitglied werden als Freunde und Familie",
  joinOurReferralScheme: "Es scheint, dass Sie versuchen, unserem Empfehlungsprogramm beizutreten...",
  joinTodayAndSave: "Heute Mitglied werden und sparen",
  joiningFee: "Aufnahmegebühr",
  joiningFeeFirstMonth: "einschließlich Aufnahmegebühr und erster Monat",
  joiningFeeProRataFirstMonth: "Aufnahmegebühr, Restbetrag des ersten Monats und Betrag des ersten vollen Monats",
  joiningFeeTooltip: "Zahlung für die Zugehörigkeit zum Club",
  joiningFeeTwelveMonths: "Anmeldegebühr, Rest des Monats und 12 Kalendermonate Mitgliedschaft",
  joiningFees: "Aufnahmegebühren",
  joiningFeesApply: "Aufnahmegebühren fallen an",
  joiningFeesWaived: "SONDERANGEBOT - sparen Sie Ihre Aufnahmegebühr",
  joiningTheClubAsAFamily: "Dem Club als Familie beitreten",
  justMe: "Nur ich",
  keepAnEyeOutForFutureTrials: "Bitte behalten Sie unsere Website für zukünftige Probezeiten im Auge oder reichen Sie eine Anfrage über die Website ein und das Clubteam wird sich mit Ihnen in Verbindung setzen.",
  keepInTouch: "Lassen Sie uns in Kontakt bleiben",
  keepYouUpdated: "Wir würden Sie gerne über unsere neuesten Produkte, Dienstleistungen und Angebote informieren. Wenn Sie interessiert sind, klicken Sie einfach auf die untenstehenden Felder.",
  kickstartTitleWithName: "%firstName%, wie würden Sie sich bezeichnen?",
  kickstartTitleWithoutName: "Wie würden Sie sich bezeichnen?",
  kidsActivities: "Aktivitäten für Kinder",
  kidsBuildYourOwnPizza: "Kinder machen ihre eigene Pizza",
  kidsClub: "Kinder-Club",
  lastName: "Nachname",
  latestNews: "Latest News",
  learnMore: "Erfahren Sie mehr",
  learnSomethingNew: "Lernen Sie etwas Neues",
  letThemEnjoyTheClub: "Nehmen Sie sich etwas Zeit für sich selbst, während Ihre Kinder eine tolle Zeit im Kids Club haben.",
  letUsKnowWhatYouAreInterestedIn: "Lassen Sie uns wissen, was Sie am meisten interessiert, damit wir Ihr Erlebnis Ihren Wünschen anpassen können",
  letUsKnowWhatYouAreLookingForwardTo: "An welchen Bereichen unseres Clubs sind Sie besonders interessiert?",
  likeOurPage: "Liken Sie unsere Seite",
  linkIsInvalid: "Tut uns Leid, dieser Link ist ungültig",
  loadingClubWelcomeTimes: "Zeiten werden geladen...",
  loadingTourTimes: "Tourzeiten werden geladen",
  logOut: "Abmelden",
  login: "Anmeldung",
  male: "Männlich",
  master: "Herr",
  meAndMyFamily: "Ich + Meine Familie",
  meAndMyPartner: "Ich + Mein Partner",
  membersLogin: "Mitglieder Login",
  membershipDescription: "Befindet sich in Ihrer Willkommens-Mail oder auf Ihrer Mitgliedskarte",
  membershipDiscountUntil: "bis zum %date%",
  membershipEnquiryDetails: "Der Benutzer wählte: %duration%%packageKey%%packageTypes%",
  membershipFees: "Mitgliedsbeiträge",
  membershipFeesUpUntil: "Mitgliedsbeiträge bis zu %date%",
  membershipNumber: "Mitgliedsnummer",
  membershipPlaceholder: "Bitte geben Sie Ihre Mitgliedsnummer ein",
  membershipPriceChangedError: "The payment has been confirmed, if you wish to change membership details, please complete joining journey and manually update club centric account",
  membershipSaving: "Mitgliedschaftseinsparungen",
  membershipStartClubConfirm: "Ihr Club wird Ihnen bestätigen, wann Ihre Mitgliedschaft beginnt",
  membershipStartClubOpens: "Ihre Mitgliedschaft beginnt mit der Öffnung des Clubs",
  membershipTermsAndConditions: "Bedingungen und Konditionen für eine Mitgliedschaft\n",
  membershipTrial: "Probe-Mitgliedschaft",
  memberships: "Mitgliedschaft",
  min: "Min",
  minutes: "Minuten",
  miss: "Fräulein",
  monthlyDirectDebitsStartOn: "Monatliche Lastschriftzahlungen, beginnend am\n",
  monthlyPaymentsDuringOfferPeriodTitle: "Monatliche Zahlungen während der Angebotsperiode",
  monthlySavings: "Monatliche Einsparungen",
  months: "Monate",
  more: "Weiteres",
  moreDetailsSkipDirectDebit: "Ich möchte meine Einzugsermächtigung im Club erteilen",
  moreDetailsSkipSEPA: "Ich möchte meine Einzugsermächtigung im Club erteilen",
  mostPopular: "Beliebteste",
  mostRecent: "Most Recent",
  mr: "Herr",
  mrs: "Frau",
  ms: "Frau",
  mx: "Divers",
  myMembership: "Meine Mitgliedschaft",
  nearlyThere: "Fast geschafft",
  next: "Nächster",
  noAdditionalMembershipsAvailable: "Derzeit gibt es keine zusätzlichen Mitgliedschaftsoptionen.\n",
  noAvailablePromotions: "Es steht keine Werbeaktionzur Vefügung",
  noClubWelcomeSlotsError: "Leider haben wir aktuell keine Verfügbarkeiten frei, um Sie willkommen zu heißen. Bitte melden Sie sich bei uns, damit wir einen passenden Termin für Sie finden.",
  noClubsInClubFinder: "Es gibt keine Clubs in dieser Gegend, aber Clubs in Ihrer Nähe sind unten aufgeführt.",
  noJoiningFee: "Keine Aufnahmegebühr",
  noJoiningFeeOffer: "Sonderangebot: Keine Aufnahmegebühr",
  noMembershipFeeForMonthOffer: "Bonusangebot: Kein Mitgliedsbeitrag für %month%",
  noMembershipFeeUntilMonthOffer: "Bonusangebot: Kein Mitgliedsbeitrag bis %month%",
  noPreferencePackageKey: "keine Präferenz für den Typ des Mitgliedschaftspakets ",
  noPreferencePackageTypes: "keine Präferenz für Mitgliedschaftszugriffstyp.",
  noTourSlotsError: "Leider haben wir im Moment keine online verfügbaren Tourzeiten. Bitte kontaktieren Sie uns, und wir werden für Sie eine Zeit finden.",
  normally: "Normalerweise",
  notAllMembershipsAvailable: "Nicht alle Mitgliedschaften sind verfügbar",
  notSureWhichClubsNearest: "Nicht sicher, welcher Club am nächsten ist",
  now: "Jetzt",
  onDate: "am %date%",
  onFacebook: "%clubName% auf Facebook",
  onInstagram: "%clubName% auf Instagram",
  onJoiningTheClubPayment: "Wenn Sie dem Club beitreten, werden Sie aufgefordert, eine erste Zahlung zu leisten, die sich aus einer Beitrittsgebühr und einer anteiligen Zahlung zusammensetzt (dies ist Ihre heutige Zahlung). Darauf folgt ein monatliches Abonnement, das per Lastschrift abgeschlossen wird. Wenn Sie Ihre Mitgliedserfahrung verbessern möchten, können Sie zusätzliche Produkte wie Personal Training oder Coaching hinzufügen. Dies kann auch heute bezahlt werden.",
  oneMonth: "Ein Monat",
  onlyAvailableForALimitedTime: "nur für einen begrenzten Zeitraum verfügbar",
  openingHours: "Öffnungszeiten",
  optInToSpecialOffer: "Fügen Sie dies hinzu, um sich für ein Sonderangebot anzumelden",
  optOutAtAnyTimeAfterPrivacyPolicy: " für alle Einzelheiten!\n",
  optOutAtAnyTimeBeforePrivacyPolicy: "Sie können sich jederzeit abmelden. Siehe unsere",
  options: "Optionen",
  organisation: "Unternehmen",
  organisationOther: "Sonstiges",
  other: "Sonstiger",
  ourEquipmentAt: "Unsere Einrichtung in %clubName%",
  ourFriendlyTeamAreLookingForwardToMeetingYou: "Unser freundliches Team freut sich darauf, Sie kennenzulernen",
  outdoorPoolTimesWeatherDependent: "Unsere Außenpool-Zeiten sind abhängig vom Wetter",
  over14EmailAddress: "Mitglieder über 14 Jahre können die Einrichtungen des Clubs nutzen. Geben Sie ihre E-Mail-Adresse ein, damit sie eingeschrieben werden können.",
  over14PhoneNumber: "Mitglieder über 14 Jahre können die %brandName% Mobile App nutzen, bitte geben Sie Ihre Handynummer ein.",
  "package": "Paket",
  packageSelected: "%packageName% ausgewählt",
  padel: "Ballsport",
  paidOnline: "Online bezahlt",
  partner: "Partner",
  partnerEmail: "Ihr*e Partner*in erhält eine E-Mail zur Bestätigung der Mitgliedsdaten und zum Abschließen der Mitgliedschaft. Geben Sie Ihre Handynummer ein und wir senden Ihnen einen Link zur Registrierung für die %brandName% Mobile App.",
  partnerWasReset: "Leider gibt es für dieses Paket keine Partneroption - bitte wählen Sie ein anderes Paket, wenn Sie Ihren Partner hinzufügen",
  partnersAndSponsors: "Partner & Sponsoren",
  password: "Passwort",
  passwordPlaceholder: "Bitte geben Sie Ihr Passwort ein",
  payAnnually: "Jährlich zahlen",
  payAtReception: "Zahlen Sie an der Rezeption",
  payMonthly: "Monatlich zahlen",
  payOnline: "Pay online",
  payingAnnually: "Jährliches Zahlen ist günstiger als monatliches Zahlen",
  paymentAlreadyConfirmed: "The payment has been confirmed, skip to the monthly payment page",
  paymentDetails: "Zahlungsdetails",
  paymentLinkExpired: "Payment link expired",
  paymentReceived: "Payment received",
  paymentRefused: "Zahlung abgelehnt",
  penneArrabbiata: "Penne Arrabbiata",
  perMonth: "pro Monat",
  personalInformation: "Persönliche Angaben",
  phoneNumber: "Nummer des Mobiltelefons",
  pickAnotherTime: "Bitte wählen Sie eine andere Uhrzeit",
  pleaseCheckAndTryAgain: "Bitte versuchen Sie es erneut",
  pleaseCheckYourCardDetails: "Bitte überprüfen Sie die Angaben zu Ihrer Karte.",
  pleaseCheckYourDetailsAndTryAgain: "Bitte überprüfen Sie Ihre Angaben und versuchen Sie es erneut.",
  pleaseCheckYourEmail: "Bitte überprüfen Sie Ihre E-Mail\n",
  pleaseClickHereTo: "Bitte klicken Sie hier, um",
  pleaseContactOnlineJoining: "Bitte kontaktieren Sie %email% für weitere Unterstützung.",
  pleaseDoubleCheckAndTryAgain: "Bitte überprüfen Sie Ihre Angaben und versuchen Sie es erneut",
  pleaseEnterANumber: "Bitte geben Sie eine Nummer ein",
  pleaseMakePaymentAtReception: "Please make payment at reception",
  pleasePickAnotherTime: "Bitte wähle einen anderen Zeitpunkt.",
  pleaseRefreshOrTryAgainLater: "Bitte aktualisieren Sie die Seite oder versuchen Sie es später erneut",
  pleaseSelectATime: "Bitte wählen Sie eine Uhrzeit",
  pleaseTryAgainLater: "Bitte versuchen Sie es später noch einmal",
  pleaseUseAnAlternativeBrowser: "Bitte verwenden Sie einen anderen Browser, wie eine aktualisierte Version von Microsoft Edge, Google Chrome oder Safari",
  pleaseVerifyCaptcha: "Bitte überprüfen Sie das Captcha, um fortzufahren",
  policies: "Richtlinien",
  policiesAndDocuments: "Richtlinien & Dokumente",
  poolOpeningHours: "Öffnungszeiten des Pools",
  poolOpeningTimes: "Öffnungszeiten des Pools",
  poolTimes: "Pool Zeiten",
  pools: "Schwimmbecken",
  poolsAreClosed: "Unsere Pools sind derzeit geschlossen",
  postCode: "Postleitzahl",
  postCodePlaceholder: "Geben Sie Ihre PLZ ein",
  preferNotToSay: "Divers",
  press: "Presse",
  pressCentre: "Press Centre",
  previous: "Zurück",
  priceDuringPromotionalPeriod: "Angebotspreis während des Aktionszeitraums",
  pricePerAdult: "%adultPrice% pro Erwachsener ",
  pricePerChild: "%childPrice% pro Kind",
  primaryMember: "Hauptmitglied",
  privacyPolicy: "Datenschutzerklärung",
  promotionSelected: "Gewähltes Werbematerial",
  promotionalItems: "Werbeartikel",
  promotions: "Werkeaktionen",
  qrCodeExpired: "QR code expired - please pay at reception",
  quickRegistration: "Schnelle Anmeldung",
  referral: "Empfehlung",
  refundedIfYouJoinOnAStandardMembership: "Gebühr, die zu zahlen ist, wenn Sie nicht mit einer Standardmitgliedschaft beitreten",
  regionUnitedKingdomEast: "East",
  regionUnitedKingdomGreaterLondon: "Greater London",
  regionUnitedKingdomMidlands: "Midlands",
  regionUnitedKingdomNorthEast: "North East",
  regionUnitedKingdomNorthWest: "North West",
  regionUnitedKingdomNorthernIreland: "Northern Ireland",
  regionUnitedKingdomScotland: "Scotland",
  regionUnitedKingdomSouth: "South",
  regionUnitedKingdomSouthEast: "South East",
  regionUnitedKingdomSouthWest: "South West",
  regionUnitedKingdomWales: "Wales",
  registerForClubTrial: "Starten Sie heute Ihre 14-tägige Probemitgliedschaft",
  registerForClubTrialButton: "Starten Sie Ihre Probemitgliedschaft",
  registeredCompanyNumber: "Eingetragene Firmen-Nr.",
  registrationErrorMessage: "Bitte überprüfen Sie die Richtigkeit Ihrer Daten. Wenn Sie Probleme bei der Registrierung eines Kontos haben, senden Sie uns bitte eine E-Mail an <a style=\"color: %emailColor%;\" href=\"mailto:%email%\">%email%</a> und unser Team wird sich so schnell wie möglich mit Ihnen in Verbindung setzen.",
  registrationUnauthorised: "Autorisierung fehlgeschlagen",
  relaxAmpersandSocialise: "Entspannen & Kontakte knüpfen",
  relaxAndEnjoySpaRetreats: "Relax and enjoy Spa Retreats",
  relaxAndEnjoyTheSpaRetreatsAt: "Entspannen und genießen sie unseren Spa in:",
  relaxAndSocialise: "Entspannen und Kontakte knüpfen",
  remainderOfFirstMonth: "Restbetrag des ersten Monats",
  remainderOfFirstMonthAnd12MonthsMembership: "Restbetrag des ersten Monats und 12 Kalendermonate Mitgliedschaft",
  remainderOfFirstMonthAndFirstFullMonth: "Restbetrag des ersten Monats und ersten ganzen Monats",
  remainderOfThisMonth: "Restbetrag für diesen Monat",
  remainderOfThisMonthTooltip: "Zahlung zur Deckung der Kosten der Mitgliedschaft für den Rest dieses Monats",
  requestingAClubWelcome: "Fragen Sie einen Termin an",
  retry: "Erneut versuchen",
  saveAmount: "Sparen Sie  %amount%",
  savingUpTo: "Rabatt bis zu %price%*",
  scanQrCodeToDownload: "Scannen Sie den QR-Code, um die App herunterzuladen",
  scanQrCodeToPay: "Scan the QR code to proceed to payment page",
  seeAllFacilities: "Alle Räumlichkeiten ansehen",
  seeDetails: "Mehr Informationen",
  seeMore: "Mehr dazu…",
  selectADate: "Wählen Sie ein Datum",
  selectAnOrganisation: "Wählen Sie eine Firma oder Gruppe...",
  selectOptionsBelow: "Wählen Sie aus den folgenden Optionen aus, um Aktivitäten für die ganze Familie zu finden.",
  selectPackage: "Wählen Sie %packageName%",
  selectPromotion: "Werbeaktion wählen",
  selectTitle: "Titel wählen",
  selectedDuration: "eine %durationType% Paketdauer; ",
  selectedPackageKey: "eine %packageKey% Mitgliedschaft; ",
  selectedPackageTypes: "Mitgliedschaftszugriff, der %packageTypes% beinhalted.",
  selectedTourTimeNoLongerAvailable: "Leider ist die von Ihnen gewählte Tourzeit nicht mehr verfügbar.",
  selectedTourTimeNoLongerAvailableHeader: "Tourzeit nicht verfügbar",
  sendQuote: "Angebot senden",
  sepaGuaranteeLine1: "Ich ermächtige/ Wir ermächtigen (A) %companyName%, Zahlungen von meinem/ unserem Konto mittels Lastschrift einzuziehen. Zugleich (B) weise ich mein/ weisen wir unser Kreditinstitut an, die von %companyName% auf mein/ unser Konto gezogenen Lastschriften einzulösen.",
  sepaGuaranteeLine2: "Hinweis: Ich kann/ Wir können innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem/ unserem Kreditinstitut vereinbarten Bedingungen.",
  sepaGuaranteeLine3: "Hinweis: Meine/ Unsere Rechte zu dem obigen Mandat sind in einem Merkblatt enthalten, das ich/ wir von meinem/ unserem Kreditinstitut erhalten kann/ können.",
  sepaGuaranteeLine4: "Ihre Rechte in Bezug auf dieses Mandat werden in einer Erklärung erläutert, die Sie von Ihrer Bank erhalten können.",
  sepaGuaranteeLine5: "Art der Zahlung: Wiederkehrend (kann mehr als einmal verwendet werden)",
  sepaTitle: "Mandat für SEPA-Lastschriftverfahren. Mandatsreferenz (vom Gläubiger anzugeben)",
  settingUpYourDirectDebit: "Einrichtung des Lastschrifteinzugsverfahrens",
  settingUpYourMonthlyPayment: "Einrichten Ihrer monatlichen Zahlung",
  shareOnFacebook: "Auf Facebook teilen",
  shareOnTwitter: "Auf Twitter teilen",
  signMeUp: "anmelden",
  signUpForAGradeCoaching: "Melden Sie sich für eine Personal Training Session mit unseren hochqualifizierten Trainern an.",
  skip: "Skip",
  skipPayment: "Zahlung überspringen, ich möchte an der Rezeption bezahlen",
  slotsTakingAWhileToLoad: "Es dauert eine Weile, unsere verfügbaren Termine zu durchsuchen. Wenn Sie nicht warten möchten, können Sie sich in der Zwischenzeit direkt über die untenstehenden Angaben melden.",
  snrgyFamilyFitness: "SYNRGY Family Fitness",
  socialMedia: "Social Media",
  softPlay: "Soft Play",
  somethingHasGoneWrong: "Etwas ist schiefgegangen",
  sorryIeNotSupported: "Tut uns leid, Internet Explorer wird nicht unterstützt",
  sorrySomethingHasGoneWrongPleaseTryAgain: "Tut uns leid, es ist etwas schiefgegangen. Bitte versuchen Sie es noch einmal.",
  sorrySomethingWentWrong: "Sorry, etwas ist schief gelaufen.",
  sorryThereWasAProblemWithYourPayment: "Sorry, es gab ein Problem mit Ihrer Zahlung",
  sorryWereHavingTechnicalProblems: "Sorry,  wir haben technische Probleme",
  sortBy: "Sort by:",
  sortByMyLocation: "Sort by my location",
  sortCode: "Sortiernummer",
  spa: "Spa",
  specialHours: "Besondere Zeiten",
  specialOffer: "Exklusives Angebot",
  specialsAndEvents: "Angebote und Veranstaltungen",
  sportsOverview: "Sportübersicht",
  standard: "Standard",
  startDate: "Startdatum",
  startInNextWeek: "Starten Sie Ihre Mitgliedschaft jederzeit nächste Woche",
  startOnFixedDate: "Sie können Ihre Mitgliedschaft beginnen am\n",
  stateOfTheArtGymFacilities: "Hochmoderne Fitnesseinrichtung",
  studioOpeningHours: "Öffnungszeiten des Studios",
  studioOverview: "Übersicht des Studios",
  submitAndStartMembership: "Absenden und Mitgliedschaft genießen",
  swimmingAt: "Schwimmen bei %clubName%",
  swimmingCoaching: "Schwimm-Unterricht",
  swimmingPools: "Schwimmbecken",
  tailorYourClubWelcome: "Passen Sie Ihr Willkommensmeeting an",
  tailorYourTour: "Individualisieren Sie Ihre Tour",
  takeOurVideoTour: "Schauen Sie sich unser Video an, um einen Eindruck von %brandName% zu bekommen*",
  takeTheNextStep: "Machen Sie den nächsten Schritt",
  takeTheNextStepDescription: "Werden Sie jetzt aktiv und starten Sie Ihre Mitgliedschaft im %clubPrefix% %clubName%.",
  taxId: "Tax ID",
  telephone: "Telefon",
  tennis: "Tennis",
  tennisClub: "Tennis Club",
  tennisCoaching: "Tennis Unterricht",
  termsAndConditions: "Allgemeine Geschäftsbedingungen",
  textMessage: "Textnachricht",
  thankYouForSettingUpDirectDebit: "Vielen Dank, dass Sie uns eine Bankeinzugsermächtigung erteilt haben",
  thankYouForSubscribing: "Vielen Dank, dass Sie sich angemeldet haben!",
  thankYouWelcomeToBrand: "Vielen Dank und herzlich willkommen bei %brandName%!",
  theClub: "Der Club",
  thereAreCurrentlyNoMembershipPackages: "Tut uns Leid, zurzeit gibt es keine Online-Mitgliedschaft. Bitte wenden Sie sich an Ihren örtlichen Club, um mehr darüber zu erfahren, wie Sie Mitglied werden können.",
  threeMonths: "3 Monate",
  timetable: "Kursplan",
  title: "Titel",
  today: "Heute",
  tomorrow: "Morgen",
  totalDue: "Zu bezahlender Betrag",
  totalDueToday: "Zu bezahlender Betrag",
  totalSavedJoiningToday: "Gesamteinsparungen, wenn Sie heute noch Mitglied werden",
  townOrCity: "Stadt",
  townOrCityPlaceholder: "Geben Sie Ihre Stadt ein",
  treatments: "Face & Body Day Spa\n",
  treatmentsOverview: "Übersicht",
  unableToOfferTrial: "Es tut uns Leid, es sind aktuell keine Probezeiten verfügbar.",
  unableToTakeClubWelcomeBookings: "Leider können wir aktuell keine Online-Buchungen zur Verfügung stellen - melden Sie sich gerne bei uns, um Ihr Willkommensmeeting zu buchen. ",
  unableToTakeTourBookings: "Leider können wir im Moment keine Online-Buchungen entgegennehmen - Bitte setzen Sie sich über die untenstehenden Details mit uns in Verbindung, um Ihre Tour zu buchen.",
  unexpectedError: "Unerwarteter Fehler",
  unfortunatelyAnErrorHasOccurred: "Leider ist ein Fehler aufgetreten",
  unfortunatelyThisTimeIsNoLongerAvailable: "Leider ist diese Uhrzeit nicht mehr verfügbar.",
  unknownMembershipError: "Möglicherweise wurde Ihre Mitgliedschaft erfolgreich erfasst. Bitte prüfen Sie, ob Sie eine Bestätigungs-E-Mail erhalten haben. Wenn Sie innerhalb einer Stunde keine erhalten, versuchen Sie es bitte erneut.",
  unsubscribe: "Abmelden",
  updateAppPrompt: "Bitte aktualisieren Sie Ihre App auf die neueste Version und versuchen Sie es erneut.",
  upgradeYourMembershipToAddChildren: "Erweitern Sie Ihre Mitgliedschaft, um ein Kind hinzuzufügen",
  upgradeYourMembershipToAddPartner: "Erweitern Sie Ihre Mitgliedschaft, um einen Partner hinzuzufügen",
  useMyLocation: "Meinen Standort verwenden",
  userAlreadyExists: "Diese Mitgliedsnummer wird bereits verwendet",
  userInputOrganisation: "Wie heißt Ihre Firma oder Gruppe?",
  userVerificationFailed: "Verifizierung fehlgeschlagen",
  usualJoiningFees: "Standard-Aufnahmegebühren",
  vatNumber: "Umsatzsteuernummer",
  viewAllClubs: "Alle Clubs sehen",
  viewInMaps: "In Maps öffnen",
  viewMap: "View a map of all David Lloyd Clubs",
  viewOurFoodMenu: "Speisekarte ansehen",
  viewTimetable: "Kursplan ansehen",
  visitSpaRetreats: "Visit Spa Retreats",
  was: "Vorher",
  weAlreadyHaveYourDetails: "%firstName%, Ihre Angaben sind bereits in unserem System.",
  weDontRecogniseThatLocation: "Entschuldigen Sie, wir finden diesen Ort nicht",
  weJustNeedAFewMoreDetails: "Wir brauchen nur noch ein paar weitere Informationen",
  weSendOurMostExcitingOffers: "Nichts verpassen! Unsere besten Angebote senden wir per E-Mail",
  weWereUnableToSaveDetails: "Es tut uns leid, leider konnten wir Ihre Angaben nicht speichern",
  weekend: "Wochenende",
  weeklyTimetable: "Wöchentlicher Kursplan",
  welcomeTo: "Willkommen bei",
  whatToExpect: "Was erwartet mich?",
  whatsApp: "WhatsApp",
  whereToFindUs: "Wo Sie uns finden",
  whoCanCome: "Wer darf kommen?",
  whosComing: "Wer kommt?",
  workEmailAddress: "Arbeits-E-Mail-Adresse",
  years: "Jahre",
  youngChildren: "Kleinkinder",
  yourCardDetails: "Ihre Kartendetails",
  yourChild: "dein Kind (%range%)",
  yourChildren: "Ihre Kinder (%range%)",
  yourClubWelcomeIsBooked: "%name%, Ihr Willkommensmeeting ist gebucht",
  yourDetails: "Ihre Angaben",
  yourFirstFullMonth: "Ihr erster voller Monat (%month%)",
  yourFirstFullMonthTooltip: "Zahlung zur Deckung der Kosten der Mitgliedschaft, bis Ihre Lastschrift bei Ihrer Bank eingerichtet wurde",
  yourMembership: "Deine Mitgliedschaft",
  yourMembershipTrial: "Ihre Probe-Mitgliedschaft",
  yourNannies: "Ihre Nannies",
  yourNanny: "Ihre Nanny",
  yourOlderChild: "Ihrer Kinder (%range%)",
  yourOlderChildren: "Ihre Kinder (%range%)",
  yourPartner: "Ihr Partnermitglied",
  yourSavings: "Ihre Einsparungen",
  yourSavingsSubtitle: "Das können Sie sparen, wenn Sie heute noch Mitglied werden",
  yourStartDate: "Ihr Starttermin",
  yourTourIsBooked: "%name%, Ihre Tour ist gebucht",
  yourYoungChild: "Ihr Kleinkind (%range%)",
  yourYoungChildren: "Ihre Kleinkinder (%range%)"
};
