import React, { useContext } from 'react';
import { CSSInterpolation } from '@emotion/serialize';
import { flex } from 'src/styling/constants';
import { Styling } from 'src/context/styling';
import { SiteContext } from 'src/context/site-context';
import { WebsiteLink } from 'src/components/common/website-link';
import ArrowRightThin from 'src/components/icons/arrow-right-thin';
import { mqReducedMotion } from 'src/styling/media-queries';
import { amplitudeTrackEvent } from 'src/helpers/amplitude-event-track';
import capitalize from 'lodash/capitalize';
import { pathToTitle } from 'src/helpers/string-helper';

const createComponentStyling = (styling: Styling) =>
  ({
    text: [
      styling.fonts.regular,
      {
        borderBottom: '1px solid',
        borderBottomColor: 'transparent',
        fontSize: '1.1875rem',
        lineHeight: '145%',
      },
    ],
    hover: {
      '&:hover div': {
        color: styling.colors.camelot,
        borderBottomColor: styling.colors.camelot,
      },
      '&:hover path': {
        fill: styling.colors.camelot,
      },
    },
    item: {
      minHeight: '4rem',
      borderBottom: '1px solid',
      borderBottomColor: styling.colors.transparentGrayOlive,
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '0.5rem',
    },
    levelThreeWrapper: {
      position: 'absolute',
      width: '100%',
      top: 0,
    },
    selected: {
      color: styling.colors.camelot,
      borderBottomColor: styling.colors.camelot,
    },
    levelThreeHidden: mqReducedMotion({
      transition: ['opacity 0.3s ease-in-out, left 0.3s ease-in-out, visibility 0.3s', 'none'],
      opacity: 0,
      left: '60%',
      visibility: 'hidden',
    }),
    levelThreeVisible: mqReducedMotion({
      transition: ['opacity 0.3s ease-in-out, left 0.3s ease-in-out', 'none'],
      opacity: 1,
      left: 'calc(100% + 1.5rem)',
      visibility: 'visible',
    }),
  }) satisfies Record<string, CSSInterpolation>;

type LevelTwoMenuProps = {
  selected: boolean;
  levelTwoMenu: Queries.GlobalHeaderLevelTwoMenuItem;
  onSelectMenu: () => void;
  updateRef: (ref: HTMLUListElement) => void;
  onFinish?: () => void;
};

const HeaderDesktopLevelTwoMenu = ({
  selected,
  levelTwoMenu,
  onSelectMenu,
  updateRef,
  onFinish,
}: LevelTwoMenuProps) => {
  const { styling, pageLevel } = useContext(SiteContext);
  const componentStyling = createComponentStyling(styling);

  const hasLevelThreeItems = !!levelTwoMenu.levelThreeMenuItems?.length;

  const handleClickSecondLevel = () => {
    onFinish?.();
    return amplitudeTrackEvent(
      `Click to ${capitalize(levelTwoMenu.name || '')} - ${pathToTitle(levelTwoMenu.link || '')}`,
      {
        custom_page: window.location.origin,
        custom_level: pageLevel,
      }
    );
  };

  return (
    <>
      <WebsiteLink
        to={levelTwoMenu.link}
        onFocus={() => {
          onSelectMenu();
        }}
        handleClick={handleClickSecondLevel}
      >
        <div
          key={levelTwoMenu.name}
          css={[componentStyling.item, flex.row, componentStyling.hover]}
          onPointerOver={() => {
            onSelectMenu();
          }}
        >
          <div css={[componentStyling.text, selected ? componentStyling.selected : undefined]}>
            {levelTwoMenu.name}
          </div>
          {hasLevelThreeItems && (
            <ArrowRightThin
              size={16}
              fill={selected ? styling.colors.camelot : styling.colors.capeCod}
            />
          )}
        </div>
      </WebsiteLink>

      {hasLevelThreeItems && (
        <ul
          css={[
            componentStyling.levelThreeWrapper,
            selected ? componentStyling.levelThreeVisible : componentStyling.levelThreeHidden,
          ]}
          ref={updateRef}
        >
          {levelTwoMenu.levelThreeMenuItems.map((levelThreeMenu) => {
            const handleClickThirdLevel = () => {
              onFinish?.();
              return amplitudeTrackEvent(
                `Click to ${capitalize(levelThreeMenu.name || '')} - ${pathToTitle(levelThreeMenu.link || '')}`,
                {
                  custom_page: window.location.origin,
                  custom_level: pageLevel,
                  custom_click: 'level three',
                }
              );
            };

            return (
              <li key={levelThreeMenu.name}>
                <WebsiteLink to={levelThreeMenu.link} handleClick={handleClickThirdLevel}>
                  <div
                    key={levelThreeMenu.name}
                    css={[componentStyling.item, flex.row, componentStyling.hover]}
                  >
                    <div css={componentStyling.text}>{levelThreeMenu.name}</div>
                  </div>
                </WebsiteLink>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

export default HeaderDesktopLevelTwoMenu;
