import { defaultEnabledPage, EnabledPage, EnabledPages } from './enabled-pages-helper';
import { ClubSection } from '../../cms/page-widgets/enabled-pages-widget';

export const getClubPageNameFromUrl = (urlPath: string): string => {
  const pageName = urlPath
    .replace(/(^\/)|(\/$)/g, '')
    .replace(/^(clubs\/)/, '')
    .replace(/\//g, '_')
    .replace(/-[a-z]/g, (firstLetterAndDash) => firstLetterAndDash.substring(1).toUpperCase());
  return `${pageName}Page`;
};

export const isEnabledPage = (page: EnabledPage): boolean => page.enabled;

export const isDisabledPage = (page: EnabledPage): boolean => !isEnabledPage(page);

export const clubPageIsDisabledInCms = (urlPath: string, enabledPages: EnabledPages): boolean => {
  const enabledPage = getEnabledPageForPath(urlPath, enabledPages);
  return isDisabledPage(enabledPage);
};

export const getEnabledPageOrDefault = (
  enabledPages: EnabledPages,
  pageName: string
): EnabledPage => {
  const enabledPage = enabledPages[pageName];
  return enabledPage ? enabledPage : defaultEnabledPage;
};

export const getEnabledPageForPath = (urlPath: string, enabledPages: EnabledPages): EnabledPage => {
  const pageName = getClubPageNameFromUrl(urlPath);
  return getEnabledPageOrDefault(enabledPages, pageName);
};

export const getPathForClubPage = (enabledPages: EnabledPages, urlPath: string): string => {
  const enabledPage = getEnabledPageForPath(urlPath, enabledPages);
  const clubSection = enabledPage.clubSection;

  const urlPathWithoutClubFacilities = urlPath.replace(
    /club-facilities\/.+/,
    (clubFacilitiesAndSlug) => clubFacilitiesAndSlug.split('/')[1]
  );

  switch (clubSection) {
    case ClubSection.ROOT:
      return urlPathWithoutClubFacilities;
    case ClubSection.CLUB_FACILITIES:
      return urlPathWithoutClubFacilities.replace('clubs', 'clubs/club-facilities');
    case ClubSection.TREATMENTS:
    case ClubSection.FITNESS:
      throw new Error(`The club section ${clubSection} is not supported yet.`);
  }
};
