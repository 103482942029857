import React, { useContext } from 'react';
import { Language, getUrlValueForLanguage } from 'src/enums/language';
import { shadow, zIndexes } from 'src/styling/constants';
import { navigate } from 'gatsby';
import LanguageItem from './language-item';
import { SiteContext } from 'src/context/site-context';
import { updateUrlPreservingQueryParams } from 'src/helpers/query-string-helper';
import { PageLanguage } from 'src/components/common/page-context';
import { BuildType, shouldIncludeLanguageInUrlForBuildType } from 'src/enums/build/build-type';
import { trimPath } from 'src/helpers/path-matching-helper';
import { preparePath } from 'src/helpers/navigation-helper';

const navigateToPathForLanguage = (language: Language, path: string, buildType: BuildType) => {
  const trimmedPath = trimPath(path);
  const pathWithLanguage = shouldIncludeLanguageInUrlForBuildType(buildType)
    ? `/${getUrlValueForLanguage(language)}/${trimmedPath}/`
    : `/${trimmedPath}/`;

  navigate(updateUrlPreservingQueryParams(preparePath(pathWithLanguage)));
};

type LanguagePickerPopupProps = {
  currentLanguage: Language;
  pageLanguages: PageLanguage[];
};

const LanguagePickerPopup: React.FC<LanguagePickerPopupProps> = ({
  currentLanguage,
  pageLanguages,
}) => {
  const { styling, buildType } = useContext(SiteContext);

  return (
    <div
      css={[
        shadow.regular,
        { position: 'absolute', background: 'white', top: '-0.5rem', zIndex: zIndexes.overlay },
      ]}
    >
      <div css={{ color: styling.colors.highlightText }}>
        <LanguageItem language={currentLanguage} />
      </div>
      {pageLanguages
        .filter(({ language }) => language !== currentLanguage)
        .map(({ language, path }) => (
          <div
            key={language}
            css={{ color: styling.colors.bodyText }}
            onClick={() => navigateToPathForLanguage(language, path, buildType)}
          >
            <LanguageItem language={language} />
          </div>
        ))}
    </div>
  );
};

export default LanguagePickerPopup;
