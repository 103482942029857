export const staticTextLookup = {
  afterSchool: 'After School',
  allAgesWelcome: 'All ages welcome',
  allFacilities: 'All Facilities',
  alsoAtClub: 'Also at %clubName%',
  atClub: 'At %clubName%',
  atUnit: 'At %unitName%',
  backToTop: "Back to top",
  bookAClubWelcome: 'Book a club welcome',
  bookAClass: 'Book a class',
  bookATour: 'Book a tour',
  bookATourHeader: 'Book your tour',
  bookATourSubtext: 'Choose a club from below to book a tour',
  bookAClubWelcomeError: 'Sorry, something went wrong. Please get in touch, and we will find you a time.',
  bookATourError: 'Sorry, something went wrong. Please get in touch, and we will find you a time.',
  bookOnTheMove: 'Book on the move',
  booked: 'Booked',
  changeClub: 'Change club',
  yourStartDate: 'Your start date',
  chooseYourStartDate: 'Choose your start date',
  clubFacilities: 'Club Facilities',
  clubOpeningHours: 'Club opening hours',
  treatmentsOverview: 'Treatments overview',
  fitnessOverview: 'Fitness overview',
  sportsOverview: 'Sports overview',
  childrenOverview: 'Children overview',
  clubOverview: 'Club Overview',
  clubVideo: 'Club Video',
  clubEmail: 'Club email',
  contactPreferencesAfterPrivacyPolicy: 'for full details. Just tick the boxes to keep in touch.',
  contactPreferencesBeforePrivacyPolicy: "We'd love to keep in touch and tell you more about our latest products, services and offers. If you agree, just tick the boxes below. You can always change your mind at any time. Please see our",
  contactTheClubForOpeningTimes: 'Please contact the club for more information on opening times',
  cookiesPolicy: 'Cookies Policy',
  cookiePolicyDescription: 'Our website uses cookies to improve your experience. By using the website you agree to our use of cookies.',
  creche: 'Crèche',
  daytime: 'Daytime',
  downloadDescriptionMembershipVerification: 'Download our mobile app before your visit so we can help you register and make the most of your membership.',
  downloadDescriptionTennis: 'Download the app to view timetables, book classes and racquets courts wherever you are.',
  downloadDescriptionTrialConfirmation: 'Download before your first visit so we can help you register and make the most of your membership.',
  email: 'E-mail',
  enquireAboutMembership: 'enquire about membership',
  enquireNow: 'Enquire now',
  enquireText: 'Tell us about your enquiry',
  exerciseClasses: 'Exercise Classes',
  experienceAllTheBenefits: 'Experience all the benefits of club membership.',
  expertTuition: 'Expert tuition',
  exploreClub: 'Explore %clubName%',
  exploreKidsActivities: "Explore kids' activities",
  familyAmpersandKids: 'Family & Kids',
  familyAndKids: 'Family and kids',
  familySwim: 'Family swim',
  findOutMore: 'Find out more',
  free: 'Free',
  from3MonthsPlus: 'from 3 mths +',
  from3To18Years: 'from 3-18 years',
  germanAndFallbackOnly: 'Test',
  getYourQuoteContactPreferencesAfterPrivacyPolicy: 'for full details. Just tick the box to keep in touch.',
  getYourQuoteContactPreferencesBeforePrivacyPolicy: "We'd love to keep in touch and tell you more about our latest products, services and offers. If you agree, just tick the box below. You can always change your mind at any time. Please see our",
  gym: 'Gym',
  highlightsAtClub: 'Highlights at %clubPrefix% %clubName%*',
  howCanWeContactYou: 'How can we contact you?',
  importantInformation: 'Important Information',
  kidsActivities: "Kids' activities",
  kidsClub: 'Kids Club',
  learnMore: 'Learn more',
  learnSomethingNew: 'Learn something new',
  letThemEnjoyTheClub: 'Let them enjoy the club while you take some time for yourself.',
  loadingClubWelcomeTimes: 'Loading club welcome times...',
  loadingTourTimes: 'Loading tour times...',
  membersLogin: "Member's login",
  mostPopular: 'Most popular',
  noClubWelcomeSlotsError: 'Unfortunately, we do not have any club welcome times available online at the moment. Please get in touch, and we will find you a time',
  noTourSlotsError: 'Unfortunately, we do not have any tour times available online at the moment. Please get in touch, and we will find you a time',
  openingHours: 'Opening hours',
  padel: 'Padel',
  paymentRefused: 'Payment refused',
  perMonth: 'per month',
  pleaseCheckYourCardDetails: 'Please check your card details',
  pleaseCheckYourDetailsAndTryAgain: 'Please check your details and try again. If the problem persists, visit us in club and our team will be happy to help you.',
  pleaseClickHereTo: 'Please click here to',
  pleaseTryAgainLater: 'Please try again later',
  pleaseVerifyCaptcha: 'Please verify the captcha to continue',
  poolOpeningHours: 'Pool opening hours',
  poolOpeningTimes: 'Pool opening times',
  pools: 'Pools',
  relaxAndSocialise: 'Relax and socialise',
  relaxAmpersandSocialise: 'Relax & Socialise',
  retry: 'Retry',
  seeAllFacilities: 'See all facilities',
  selectADate: 'Select a date',
  selectOptionsBelow: 'Select from the options below to find activities for the whole family.',
  sendQuote: 'Send quote',
  signUpForAGradeCoaching: 'Sign up for A-grade coaching for all ages',
  skipPayment: 'Skip payment, I would like to pay at reception',
  slotsTakingAWhileToLoad: "It's taking a while to find our available slots. We'll keep trying, but in the meantime, you can get in touch directly using the details below",
  snrgyFamilyFitness: 'Synrgy family fitness',
  somethingHasGoneWrong: 'Something has gone wrong',
  sorrySomethingHasGoneWrongPleaseTryAgain: 'Sorry, something has gone wrong. Please try again.',
  sorrySomethingWentWrong: 'Sorry, something went wrong.',
  sorryThereWasAProblemWithYourPayment: 'Sorry, there was a problem with your payment',
  sorryWereHavingTechnicalProblems: "Sorry, we're having technical problems",
  spa: 'Spa',
  specialHours: 'Special hours',
  studioOpeningHours: 'Studio opening hours',
  studioOverview: 'Studio Overview',
  swimmingCoaching: 'Swimming coaching',
  swimmingPools: 'Swimming Pools',
  takeOurVideoTour: 'Watch our video to get a feel for the %brandName% experience*',
  takeTheNextStep: 'Take the next step',
  takeTheNextStepDescription: "Discover how joining %clubPrefix% %clubName% could transform your health and wellbeing.",
  telephone: "Telephone",
  whatsApp: "WhatsApp",
  tennis: 'Tennis',
  tennisClub: 'Tennis Club',
  tennisCoaching: 'Tennis Coaching',
  termsAndConditions: 'Terms and Conditions',
  textMessage: 'Text Message',
  thankYouWelcomeToBrand: 'Thank you, Welcome to %brandName%!',
  downloadTheMobileAppHere: 'Download the mobile app here:',
  appQrCodeInstructions: "Simply line up your phone camera over the code, and once it's recognised tap the link to download the %brandName% app.",
  scanQrCodeToDownload: "Scan the QR code to download the App",
  theClub: 'The club',
  today: 'today',
  unexpectedError: 'Unexpected error',
  unknownMembershipError: 'Your membership may have been successfully recorded. Please check for a confirmation E-mail. If you do not receive one within an hour, then please try again.',
  unfortunatelyAnErrorHasOccurred: 'Unfortunately, an error has occurred',
  viewOurFoodMenu: 'View our food menu',
  viewTimetable: 'View timetable',
  weekend: 'Weekend',

  unableToOfferTrial: "Sorry, we're unable to offer a trial at the moment",
  keepAnEyeOutForFutureTrials: 'Please keep an eye on our website for future trial periods or submit an enquiry from the website and the club team will be in touch',
  yourMembership: 'Your membership',
  yourMembershipTrial: 'Your Membership Trial',
  seeDetails: 'See details',
  refundedIfYouJoinOnAStandardMembership: 'fee payable if you do not join on a standard membership',
  duration: 'Duration',
  club: 'Club',
  startDate: 'Start date',
  clubTourTime: 'Club tour time',
  membershipTrial: 'Membership Trial',
  findYourClub: 'Find your club',
  notSureWhichClubsNearest: 'Not sure which club\'s nearest',
  useMyLocation: 'Use my location',
  sortByMyLocation: 'Sort by my location',
  firstName: 'First name',
  lastName: 'Last name',
  enterValidName: 'Please enter a valid name including a capital letter',
  phoneNumber: 'Mobile phone number',
  enterValidPhoneNumber: 'Please enter a valid phone number',
  enterValidMobilePhoneNumber: 'Please enter a valid mobile phone number',
  emailAddress: 'Email address',
  enterValidEmailAddress: 'Please enter a valid email address',
  emailAddressesMustBeUnique: 'Please enter a unique email address. It must be different to the email address provided by the primary member',
  dateOfBirth: 'Date of birth',
  addressLine1: 'Address line 1',
  addressLine2: 'Address line 2',
  townOrCity: 'Town/City',
  postCode: 'Postcode',
  enterValidPostCode: 'Please enter a valid postcode',
  enterValidTaxId: 'Please enter a valid tax ID',
  taxId: 'Tax ID',
  isPartnerPresentText: 'My partner is in the Club with me',
  isChildPresentText: 'My child is in the Club with me today',
  county: 'County',
  clubWelcomeMeetingTime: 'Club welcome meeting time',
  pleaseSelectATime: 'Please select a time',
  contactMeToArrange: 'Contact me to arrange',
  shareOnFacebook: 'Share on Facebook',
  shareOnTwitter: 'Share on Twitter',
  timetable: 'Timetable',
  joiningTheClubAsAFamily: 'Joining the club as a family',
  enjoyTimeTogetherAt: 'Enjoy time together at %clubPrefix% %clubName%',
  onFacebook: '%clubName% on Facebook',
  onInstagram: '%clubName% on Instagram',
  followUs: 'Follow us',
  likeOurPage: 'Like our page',
  seeMore: 'See more...',
  discoverTheCreche: 'Discover the crèche',
  healthyBreakfast: 'Healthy breakfast',
  kidsBuildYourOwnPizza: 'Kids build your own pizza',
  penneArrabbiata: 'Penne arrabbiata',
  swimmingAt: 'Swimming at %clubName%',
  softPlay: 'Soft play',
  poolTimes: 'Pool times',
  outdoorPoolTimesWeatherDependent: 'Our outdoor pool times are weather dependent',
  exerciseClassesAt: 'Exercise classes at %clubName%',
  equipmentAt: 'Our equipment at %clubName%',
  weeklyTimetable: 'Weekly timetable',
  bookYourClubWelcomeAt: 'Book your club welcome at %clubName%',
  bookYourTourOf: 'Book your tour of %clubName%',
  getInTouch: 'Get in touch',
  requestingAClubWelcome: 'Requesting a club welcome appointment',
  cantFindASlot: "Can't find a slot?",
  tomorrow: 'tomorrow',
  availableSlots: '%numberOfSlots% available slots',
  availableSlot: '%numberOfSlots% available slot',
  onDate: 'on %date%',
  tailorYourClubWelcome: 'Tailor your club welcome',
  tailorYourTour: 'Tailor your tour',
  letUsKnowWhatYouAreLookingForwardTo: 'Let us know what areas of the club you are most looking forward to using',
  letUsKnowWhatYouAreInterestedIn: "Let us know what you're interested in so we can tailor your experience",
  weJustNeedAFewMoreDetails: 'We just need a few more details',
  bookYourClubWelcome: 'Book your club welcome',
  bookYourTour: 'Book your tour',
  nearlyThere: 'Nearly there',
  weSendOurMostExcitingOffers: 'Don’t miss out! We send our most exciting offers by email ',
  areYouSureYouDoNotWantToBeContacted: "Are you sure you don't want us to contact you via email or text message?",
  changePreferences: 'Change preferences',
  continue: 'Continue',
  viewInMaps: 'View in maps',
  whereToFindUs: 'Where to find us',
  yourClubWelcomeIsBooked: '%name%, your club welcome is booked',
  yourTourIsBooked: '%name%, your tour is booked',
  dateAtTime: '%date% at %time%',
  bookingYourClubWelcome: 'Booking your club welcome...',
  bookingYourTour: 'Booking your tour...',
  unableToTakeClubWelcomeBookings: "Unfortunately, we're unable to take online bookings at the moment - you can get in touch with us via the details below to book your club welcome.",
  unableToTakeTourBookings: "Unfortunately, we're unable to take online bookings at the moment - you can get in touch with us via the details below to book your tour.",
  pickAnotherTime: 'Pick another time',
  unfortunatelyThisTimeIsNoLongerAvailable: 'Unfortunately, this time is no longer available.',

  selectedTourTimeNoLongerAvailableHeader: 'Tour time not available',
  selectedTourTimeNoLongerAvailable: 'Unfortunately, the tour time you selected is no longer available.',
  pleasePickAnotherTime: 'Please pick another time.',

  edit: 'Edit',
  ourFriendlyTeamAreLookingForwardToMeetingYou: 'Our friendly team are looking forward to meeting you',
  whatToExpect: 'What to expect?',
  whoCanCome: 'Who can come?',
  keepInTouch: 'Keep in touch',
  keepYouUpdated: "We'd love to update you about our latest products, services and offers. Tick the boxes below to let us know how you'd like to hear from us.",
  initialEnquiryKeepYouUpdated: "After your initial enquiry, we'd love to update you about our latest products, services and offers. Tick the boxes below to let us know how you'd like to hear from us.",
  optOutAtAnyTimeAfterPrivacyPolicy: 'for full details!',
  optOutAtAnyTimeBeforePrivacyPolicy: 'You can opt-out at any time. See our',
  justMe: 'Just me',
  meAndMyPartner: 'Me + my partner',
  meAndMyFamily: 'Me + my family',

  anythingElseYoudLikeUsToKnow: "Anything else you'd like us to know?",
  ourEquipmentAt: 'Our equipment at %clubName%',
  FAQs: 'FAQs',
  privacyPolicy: 'Privacy Policy',
  whosComing: "Who's Coming?",
  yourDetails: 'Your details',
  min: 'min',

  poolsAreClosed: 'All our pools are currently closed.',
  pleaseEnterANumber: 'Please enter a number',

  close: 'Close',
  more: 'More',
  next: 'Next',
  back: 'Back',
  previous: 'Previous',
  done: 'Done',
  logOut: 'Log out',
  confirm: 'Confirm',
  cancel: 'Cancel',

  gender: 'Gender',
  male: 'Male',
  female: 'Female',

  selectTitle: 'Select a title...',
  title: 'Title',
  mr: 'Mr',
  mrs: 'Mrs',
  miss: 'Miss',
  ms: 'Ms',
  master: 'Master',
  dr: 'Dr',
  mx: 'Mx',
  other: 'Other',

  preferNotToSay: 'Prefer not to say',

  addressLinePlaceholder: 'Enter your address',
  townOrCityPlaceholder: 'Enter your town or city',
  postCodePlaceholder: 'Enter your postcode',
  countyPlaceholder: 'Enter your county',

  personalInformation: 'Personal Information',
  contactInformation: 'Contact Information',
  aboutYou: 'About You',
  address: 'Address',

  welcomeTo: 'Welcome to',
  beforeYouBecomeAMember: 'Before you become a member, let us show you how you can get the most out of this club',
  join: 'Join',
  joinCorporate: 'Join Corporate',
  joinFriendsAndFamily: 'Join Friends and Family',

  buyapowaWarningPopupHeader: 'Please note',
  buyapowaWarningPopupText: 'Club %clubName% is not currently enrolled on our refer-a-friend scheme, so neither you nor the person who referred you would be eligible to receive any rewards if you continue with this sign up.',
  buyapowaWarningPopupCancelButtonText: 'Choose another club',
  buyapowaWarningPopupConfirmButtonText: 'Join %clubName%',

  referral: 'Referral',
  friend: 'Friend',

  yourCardDetails: 'Your card details',
  paymentDetails: 'Payment details',
  confirmNoPaymentTaken: 'Please confirm no payment has been processed',
  confirmPaymentTaken: 'Please confirm payment has been processed',

  totalDueToday: 'Total due',
  followedByMonthly: 'Followed by monthly payment of',
  package: 'Package',
  remainderOfThisMonth: 'Remainder of this month',
  remainderOfThisMonthTooltip: 'Payment covering the cost of membership for the remainder of this month',
  yourFirstFullMonth: 'Your first full month (%month%)',
  yourFirstFullMonthTooltip: 'Payment covering the cost of membership until your direct debit has been set up with your bank',
  joiningFeeTooltip: 'Payment to belong to the club',
  fixedTermFee: 'Twelve months membership starting %month%',
  fixedTermFeeTooltip: 'Payment covering the cost of membership for 12 calendar months',
  onJoiningTheClubPayment: 'On joining the club you will be asked to make an initial payment comprising of a joining fee and a pro rata payment (this is your payment today). This is followed by a monthly subscription that will be taken by direct debit. If you wish to enhance your membership experience you can add on additional products such as personal training or coaching, this can also be paid today.',
  annualOnJoiningTheClubPayment: 'On joining the club you will be asked to make a payment comprising of a joining fee, a pro rata payment for the remainder of this month (if appropriate) and payment covering the cost of membership for 12 calendar months. If you wish to enhance your membership experience you can add on additional products such as personal training or coaching, this can also be paid today.',
  ifSomethingWereToChangeInYourLifestyle: 'If something were to change in your lifestyle, %brandName% operates a 14 day cooling off period. Speak to a member of the team to find out more.',
  ifSomethingWereToChangeRightToCancel: 'If something were to change in your lifestyle, you have the right to cancel. Speak to a member of staff to find out more.',
  payAtReception: 'Pay at reception',
  paidOnline: 'Paid online',
  payOnline: 'Pay online',
  scanQrCodeToPay: 'Scan the QR code to proceed to payment page',
  qrCodeExpired: 'QR code expired - please pay at reception',
  pleaseMakePaymentAtReception: 'Please make payment at reception',
  awaitingPayment: 'Awaiting payment',
  paymentLinkExpired: 'Payment link expired',
  paymentReceived: 'Payment received',

  signMeUp: 'Sign me up',
  onlyAvailableForALimitedTime: 'Only available for a limited time',
  stateOfTheArtGymFacilities: 'State of the art gym facilities',
  heatedSwimmingPools: 'Heated swimming pools',
  groupExerciseClasses: 'Group exercise classes',
  coreStrengthAndHIIT: 'Core, strength, and conditioning, HIIT, Wellness and wellbeing Yoga, Cycling',
  expertPersonalTrainers: 'Expert personal trainers',
  atHomeDigitalContent: '@home digital content including Live and on-demand content, educational seminars and trainer tips',
  anytimeClubAccess: 'Anytime club access',

  was: 'Was',
  now: 'Now',
  savingUpTo: 'Saving up to %price%*',

  pleaseCheckYourEmail: 'Please check your email',
  activating: 'Activating',

  sorryIeNotSupported: 'Sorry, Internet Explorer is not supported',
  pleaseUseAnAlternativeBrowser: 'Please use an alternative browser, such as an updated version of Microsoft Edge, Google Chrome or Safari',

  oneMonth: 'One month',
  threeMonths: '3 months',

  iAgree: 'I agree',

  about: 'About',
  policies: 'Policies',
  policiesAndDocuments: 'Policies & documents',
  careers: 'Careers',
  contact: 'Contact',
  press: 'Press',
  chooseAClub: 'Choose a club',
  memberships: 'Memberships',
  login: 'Login',
  cancelMyMembership: 'Cancel my membership',
  thereAreCurrentlyNoMembershipPackages: "Sorry you missed out; our early membership release has closed for now. If you haven’t already registered, please click the button above and we’ll make sure to send you a reminder when the next chance to join opens.",
  registerForClubTrial: 'Start your 14 day trial today',
  chooseAClubToRegisterForClubTrial: 'Choose a club below',
  registerForClubTrialButton: 'Start your trial',

  partnersAndSponsors: 'Partners & Sponsors',
  membershipTermsAndConditions: 'Membership T&Cs',

  corporateFitness: 'Unternehmensfitness',
  specialsAndEvents: 'Specials und Events',

  linkIsInvalid: 'Sorry, this link is invalid',
  pleaseCheckAndTryAgain: 'Please check and try again',
  pleaseRefreshOrTryAgainLater: 'Please refresh, or try again later',

  thankYouForSettingUpDirectDebit: 'Thank you for setting up your Direct Debit with us',
  detailsAddedToMembershipAccount: 'These details will now be added to your membership account.',

  chooseMembershipLength: 'Choose the length of your membership',
  standard: 'Standard',
  flexible: 'Flexible',
  bestValue: 'Best value',
  initialMonthTerm: 'Initial %monthDuration% calendar month term',
  closeDetails: 'Close details',

  addYourPromotions: 'Select your promotion',
  addYourAssociates: 'Add your partner and children',
  addYourPartner: 'Add your partner',
  addYourChildren: 'Add your children',
  notAllMembershipsAvailable: 'Not All Memberships Available',
  associatesWereReset: 'Unfortunately this package does not support all of the additional members you had selected - please choose another package if you wish to include those members.',
  partnerWasReset: "Unfortunately this package doesn't have a partner option - please look at upgrading to another package to include your partner.",
  upgradeYourMembershipToAddPartner: 'Upgrade your membership to add a partner',
  myMembership: 'My membership',
  from: 'From',

  joiningFeesWaived: 'SPECIAL OFFER - Joining fee saving now available',
  noJoiningFeeOffer: 'Special Offer: No joining fee',
  noMembershipFeeForMonthOffer: 'Bonus Offer: No membership fee for %month%',
  usualJoiningFees: 'Usual joining fees',
  joiningFeesApply: 'Joining fees apply',
  pricePerAdult: '%adultPrice% per adult',
  pricePerChild: '%childPrice% per child',
  membershipFees: 'Membership fees',
  membershipFeesUpUntil: 'Membership fees up until %date%',
  firstMonthFees: 'As we are unable to set up your direct debit in time for your first full month, this payment is taken upfront',
  joiningFee: 'Joining fee',
  promotionalItems: 'Promotional items',
  startInNextWeek: 'Start your membership anytime in the next week',
  startOnFixedDate: 'You may start your membership on',
  accessToOtherClubs: 'Access to other clubs',
  accessToClubsNearYou: 'Your access to clubs near you:',
  accessToClubsAndSpaRetreatsParagraph1: "Platinum membership will also give you access to other David Lloyd Clubs.\nSee below for details of the clubs you'll be able to access.",
  accessToClubsAndSpaRetreatsParagraph2: 'Plus, if the club has a Spa Retreat, this will also include\naccess to the Spa Retreat facilities.',
  accessToClubsAndSpaRetreatsParagraph3: 'Click here for a list of clubs with Spa Retreat.',
  findAClub: 'Find a club',
  checkYourAccessToASpecificClub: 'Check your access to a specific club:',
  enterALocation: 'Enter a location...',
  weDontRecogniseThatLocation: "Sorry, we don't recognise that location",
  relaxAndEnjoySpaRetreats: 'Relax and enjoy Spa Retreats',
  visitSpaRetreats: 'Visit Spa Retreats',
  diamondPlusRequired: 'Diamond Plus membership is required to access certain Spa Retreats',
  viewAllClubs: 'View all clubs',

  specialOffer: 'Special offer',
  endsIn: 'Ends in:',
  days: 'days',
  hours: 'hours',
  minutes: 'minutes',
  and: 'and',
  membershipDiscountUntil: 'Until %date%',
  monthlyPaymentsDuringOfferPeriodTitle: 'Monthly payments during special offer period',
  followedByMonthlyPaymentsTitle: 'Followed by monthly payments',
  optInToSpecialOffer: 'Add this to opt in to the joining promotion',
  includedInPromotion: 'Included in Promotion',

  primaryMember: 'Primary member',
  partner: 'Partner',
  children: 'Children',
  youngChildren: 'Young Children',
  adults: 'Adults',
  priceDuringPromotionalPeriod: 'price during promotional period',

  normally: 'Normally',
  joiningFeeFirstMonth: 'Joining fee and first month',
  joinTodayAndSave: 'Join today and save',
  joiningFees: 'Joining Fees',
  monthlySavings: 'Monthly Savings',
  adultMembers: 'Adult Members',
  childMembers: 'Child Members',
  yourSavings: 'Your Savings',
  yourSavingsSubtitle: "Here's what you can save by joining today:",
  totalSavedJoiningToday: 'Total savings by joining today',
  membershipSaving: 'Membership Saving',

  accountHoldersName: "Account holder's name",
  accountNumber: 'Account number',
  directDebitMandate: 'Direct Debit Mandate',
  sortCode: 'Sort code',

  settingUpYourDirectDebit: 'Setting up your direct debit',
  byClickingFinishYouAcceptDDConditions: 'By clicking ‘%buttonText%’ you acknowledge that you are the bank account holder and the account does not require another person to authorise the debits on this account. If it is either a personal or business account and more than one person is required to authorise debits on this account, then please contact us directly.',

  aCopyOfThisGuaranteeShouldBeRetained: 'A copy of this guarantee should be retained by the customer, it will be sent via email confirmation',
  monthlyDirectDebitsStartOn: 'Monthly direct debit payments to start on',
  weWereUnableToSaveDetails: 'Sorry, we were unable to save your details',
  pleaseDoubleCheckAndTryAgain: 'Please double check your details and try again',

  additionalDetailsSecondErrorMessage: "Sorry, we're unable to take your details online at the moment, we'll take these on your first visit to the club.",
  treatments: 'Face & Body Day Spa',

  yourYoungChildren: 'Your young children (%range%)',
  yourPartner: 'Your partner',
  yourOlderChildren: 'Your older children (%range%)',
  yourNannies: 'Your nannies',
  yourYoungChild: 'Your young child (%range%)',
  yourOlderChild: 'Your older child (%range%)',
  yourNanny: 'Your nanny',

  directDebitClubReopens: 'Your monthly direct debit will start when the club reopens and will take into account any joining promotions in place',
  directDebitClubOpens: 'Your first direct debit will start when the club opens taking into account any promotions in place at time of joining',
  directDebitMembershipStarts: 'Your first direct debit will start when your membership starts taking into account any promotions in place at time of joining',
  membershipStartClubOpens: 'Your membership will start when the club opens',
  membershipStartClubConfirm: 'Your club will confirm when your membership starts',

  moreDetailsSkipDirectDebit: 'I want to set up my Direct Debit in Club',
  submitAndStartMembership: 'Submit & start enjoying my membership',

  completeDirectDebitPopupText: 'Save time on your first visit by setting up your Direct Debit now',
  completeItInClub: 'Complete Direct Debit in Club',
  completeItNow: 'Complete Direct Debit now',

  months: 'months',
  years: 'years',

  upgradeYourMembershipToAddChildren: 'Upgrade your membership to add a child',

  yourChild: 'Your child (%range%)',
  yourChildren: 'Your children (%range%)',

  sepaTitle: 'SEPA Direct Debit Mandate',
  sepaGuaranteeLine1: 'By signing this mandate form, you authorize (A) %companyName% to send instructions to your bank to debit your account and (B) your bank to debit your account in accordance with the instructions from %companyName%',
  sepaGuaranteeLine2: 'As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank.',
  sepaGuaranteeLine3: 'A refund must be claimed within 8 weeks starting from the date on which your account was debited.',
  sepaGuaranteeLine4: 'Your rights regarding this mandate are explained in a statement that you can obtain from your bank.',
  sepaGuaranteeLine5: 'Type of Payment: Recurrent (can be used more than once)',
  creditorId: 'Creditor ID',
  creditorName: 'Creditor Name',
  creditorAddress: 'Creditor address',

  settingUpYourMonthlyPayment: 'Setting up your monthly payment',

  completeSEPAPopupText: 'Save time on your first visit by setting up your SEPA Direct Debit now',
  completeSEPAInClub: 'Complete SEPA Direct Debit in Club',
  completeSEPANow: 'Complete SEPA Direct Debit now',
  moreDetailsSkipSEPA: 'I want to set up my SEPA Direct Debit in Club',

  partnerEmail: 'Your partner will be sent an email confirming their membership details & to complete their membership set up. Enter their mobile number & we will send a link to register to the %brandName% mobile app.',
  over14PhoneNumber: 'Members over 14 can use the %brandName% mobile app, please enter their mobile number.',
  over14EmailAddress: 'Members over 14 can use in-club facilities. Enter their email address so they can be enrolled.',

  joinDifferentClub: 'Join a different club',

  contactNotPromotedMonthlyPaymentErrorTitle: "Sorry, we're not quite as fast as you today!",
  contactNotPromotedMonthlyPaymentErrorSubtitle: "Setting up your membership takes a few moments behind the scenes. Please retry, but don't worry you won't be charged twice.",
  havingProblemsSavingMonthlyPaymentDetails: "We're having problems saving your monthly payment details",
  pleaseContactOnlineJoining: 'Please contact %email% for assistance',

  workEmailAddress: 'Work Email Address',
  organisation: 'Organisation',
  selectAnOrganisation: 'Select a company or group...',
  organisationOther: 'Other',
  userInputOrganisation: 'What is your company or group name?',

  friendsAndFamilyReferralNotFound: 'Sorry, this link was not found. Please contact the person who referred you to nominate you again.',
  friendsAndFamilyReferralExpired: 'Sorry, this link has expired. Please contact the person who referred you to nominate you again.',
  friendsAndFamilyReferralAlreadyUsed: 'Sorry, this link has already been used. Please contact the person who referred you to nominate you again.',

  thankYouForSubscribing: 'Thank you for subscribing!',

  quickRegistration: 'Quick Registration',
  membershipNumber: 'Membership number',
  membershipPlaceholder: 'Please enter your membership number',
  password: 'Password',
  passwordPlaceholder: 'Please enter your password',
  confirmPassword: 'Confirm password',
  confirmPasswordPlaceholder: 'Please confirm your new password',
  membershipDescription: 'Located within your welcome email or on your membership card',
  getStarted: 'Get started',
  completeForm: 'Please complete the below form.',
  userAlreadyExists: 'This membership number is already in use',
  userVerificationFailed: 'Verification failed',
  registrationUnauthorised: 'Authorisation failed',
  registrationErrorMessage: 'Please check your details are correct. If you are having trouble registering for an account, please drop us an email at <a style="color: %emailColor%;" href="mailto:%email%">%email%</a> and someone will get back to you as soon as possible.',
  weAlreadyHaveYourDetails: '%firstName%, we already have your details in our system.',
  clickHereIfYouAreNot: 'Please click here if you are not %firstName%',

  imageDisclaimer: '*Disclaimer: images are for illustration only and may not be of the club you have selected.',

  payAnnually: 'Pay annually',
  payMonthly: 'Pay monthly',
  joiningFeeTwelveMonths: 'Joining fee, remainder of the month and 12 calendar months membership',
  chooseHowYouPay: 'Choose how you pay',
  annually: 'Annually',
  payingAnnually: 'Paying annually is typically cheaper than paying monthly',

  joiningFeeProRataFirstMonth: 'Joining fee, remainder of first month and first full month',

  kickstartTitleWithoutName: 'How would you describe yourself?',
  kickstartTitleWithName: '%firstName%, how would you describe yourself?',

  remainderOfFirstMonth: 'Remainder of first month',
  remainderOfFirstMonthAndFirstFullMonth: 'Remainder of first month and first full month',
  remainderOfFirstMonthAnd12MonthsMembership: 'Remainder of first month and 12 calendar months membership',
  firstFullMonth: 'First full month',

  availablePromotions: 'available promotions',
  addSelectedPromotion: 'Add selected promotion',
  selectPromotion: 'Select promotion',
  additionalMembers: 'Additional members',
  additionalMemberships: 'Additional memberships',
  options: 'Options',
  selectPackage: 'Select %packageName%',
  saveAmount: 'Save %amount%',
  addPartnerAmount: 'Add a partner %amount%',
  packageSelected: '%packageName% selected',
  changeSelectedPackage: 'Change selected membership',
  noAdditionalMembershipsAvailable: 'There are no additional membership options currently available.',
  noJoiningFee: 'No Joining Fee',
  promotionSelected: 'Promotion selected',
  noAvailablePromotions: 'There are no available promotions.',

  appUpdateRequired: 'App Update Required',
  joinOurReferralScheme: "It looks like you're trying to join our referral scheme...",
  updateAppPrompt: 'Please update your app to the latest version and try again.',

  downloadAppPrompt: 'To make bookings and manage your membership, please download the David Lloyd Clubs App.',

  couple: 'Couple',
  individual: 'Individual',
  child2AndUnder: 'Child Membership:\n Up To 2 Years',
  child3To18: 'Child Membership:\n 3-18 Years',

  membershipEnquiryDetails: 'The user selected: %duration%%packageKey%%packageTypes%',
  selectedDuration: 'a %durationType% package duration; ',
  selectedPackageKey: 'a %packageKey% membership; ',
  noPreferencePackageKey: 'no preference for membership package type; ',
  selectedPackageTypes: 'membership access that includes %packageTypes%.',
  noPreferencePackageTypes: 'no preference for membership access type.',

  frequentlyAskedQuestions: 'Frequently Asked Questions',

  noClubsInClubFinder: 'There are no clubs in this area, however clubs closest to you are listed below.',
  comingSoon: 'Coming Soon',
  clubFinderSearchPlaceholder: 'Enter a Location',

  addToCalendar: 'Add To Calendar',

  amountSubjectToPendingPriceRise: 'The amount stated is subject to any pending price rise, refer with the club team for further details.',

  countryBelgium: 'Belgium',
  countryFrance: 'France',
  countryGermany: 'Germany',
  countryIreland: 'Ireland',
  countryItaly: 'Italy',
  countryNetherlands: 'Netherlands',
  countrySpain: 'Spain',
  countrySwitzerland: 'Switzerland',
  countryUnitedKingdom: 'United Kingdom',

  regionUnitedKingdomEast: 'East',
  regionUnitedKingdomGreaterLondon: 'Greater London',
  regionUnitedKingdomMidlands: 'Midlands',
  regionUnitedKingdomNorthEast: 'North East',
  regionUnitedKingdomNorthWest: 'North West',
  regionUnitedKingdomNorthernIreland: 'Northern Ireland',
  regionUnitedKingdomScotland: 'Scotland',
  regionUnitedKingdomSouth: 'South',
  regionUnitedKingdomSouthEast: 'South East',
  regionUnitedKingdomSouthWest: 'South West',
  regionUnitedKingdomWales: 'Wales',

  pressCentre: 'Press Centre',
  socialMedia: 'Social Media',
  latestNews: 'Latest News',
  sortBy: 'Sort by:',
  mostRecent: 'Most Recent',
  sitemap: 'Sitemap',
  servicesAndFacilities: 'Services and facilities',
  joinAClubOnline: 'Join a Club online',
};
