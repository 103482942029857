import { getCloudinaryCName } from 'src/helpers/config-helper';
import { isNullOrBlank } from 'src/helpers/string-helper';
import { HorizontalFocus } from 'src/enums/image';

export const CLOUDINARY_CLOUD_NAME = 'softwire-david-lloyd';

export const mobileBreakpointInPixels = 480;
const defaultSrcSetBreakpoints = [mobileBreakpointInPixels, 640, 800, 1024, 1400, 1600];

export const getSrc = (imageUrlOrPath: string, widthLimit?: number, crop?: Queries.ImageCrop) => {
  return getSizedImageUrl(imageUrlOrPath, widthLimit, crop);
};

const getSizedImageUrl = (
  imageUrlOrPath: string,
  widthLimit?: number,
  crop?: Queries.ImageCrop
) => {
  const parsedImagePath = getDecodedImagePathOrNullIfNotFound(imageUrlOrPath);
  if (!parsedImagePath) {
    return imageUrlOrPath;
  }

  const cloudinaryCropTransformation = !!crop
    ? `/${encodeURIComponent(`c_crop,g_xy_center,x_${crop.x},y_${crop.y},w_${crop.width},h_${crop.height}`)}`
    : '';
  const cloudinaryWidthLimitTransformation = !!widthLimit
    ? `/${encodeURIComponent(`c_limit,w_${widthLimit}`)}`
    : '';

  return (
    `${getCloudinaryCName()}/${CLOUDINARY_CLOUD_NAME}` +
    '/image/upload' +
    '/f_auto' +
    cloudinaryCropTransformation +
    cloudinaryWidthLimitTransformation +
    '/v1' +
    `/${encodeURI(parsedImagePath)}`
  );
};

const VIDEO_EXTENSION_REGEX = /^(.*)(\.[a-zA-Z0-9]+)$/;

export const getAdaptiveBitrateStreamingVideoUrl = (videoUrlOrPath: string): string | null => {
  const urlWithoutExtension = videoUrlOrPath.match(VIDEO_EXTENSION_REGEX)?.[1] ?? videoUrlOrPath;
  const fullUrlWithExtension = `${urlWithoutExtension}.m3u8`;

  const parsedImagePath = getDecodedImagePathOrNullIfNotFound(fullUrlWithExtension);

  const url =
    parsedImagePath != null
      ? `${getCloudinaryCName()}/${CLOUDINARY_CLOUD_NAME}/video/upload/sp_auto/v1/${parsedImagePath}`
      : fullUrlWithExtension;

  return encodeURI(url);
};

export const getImageForClubFinder = (imageUrlOrPath: string) => {
  const parsedImagePath = getDecodedImagePathOrNullIfNotFound(imageUrlOrPath);

  const url =
    parsedImagePath != null
      ? `${getCloudinaryCName()}/${CLOUDINARY_CLOUD_NAME}/image/upload/w_80,h_80,c_fill/v1/${parsedImagePath}`
      : imageUrlOrPath;

  return encodeURI(url);
};

// Expected full URL pattern = https://<domain>/<first part of slug>/v<some digits>/<file path (including subdirectories)>
// e.g. https://res.cloudinary.com/softwire-david-lloyd/image/upload/v1638362073/recipes/envelope.jpg
const imageUrlRegex: RegExp = /^https:\/\/\S+\/v\d+\/(\S+)$/;

export const getDecodedImagePathOrNullIfNotFound = (imageUrlOrPath: string): string | null => {
  if (isImagePath(imageUrlOrPath)) {
    return decodeURI(imageUrlOrPath);
  }

  const imageUrlMatch: RegExpExecArray = imageUrlRegex.exec(imageUrlOrPath);

  if (imageUrlMatch == null || isNullOrBlank(imageUrlMatch[1])) {
    return null;
  }

  return decodeURI(imageUrlMatch[1]);
};

const isImagePath = (imageUrlOrPath: string) => !imageUrlOrPath.startsWith('https');

export const getSrcSet = (imagePath: string, widthLimit?: number, crop?: Queries.ImageCrop) => {
  // This is specifying the approximate size for the image for srcSet, but will be default image option even if the screen/image is bigger
  const maxPageWidthInPixels = 1920;
  const originalImageUrl = getSrc(imagePath, widthLimit, crop);

  const breakpoints =
    widthLimit === undefined
      ? defaultSrcSetBreakpoints
      : defaultSrcSetBreakpoints.filter((bp) => bp < widthLimit).concat([widthLimit]);

  const srcSetEntries = [
    ...breakpoints.map((w) => `${getSizedImageUrl(imagePath, w, crop)} ${w}w`),
  ];

  if (widthLimit === undefined) {
    srcSetEntries.push(`${originalImageUrl} ${maxPageWidthInPixels}w`);
  }

  return srcSetEntries.join(', ');
};

export const getObjectPosition = (imageDetails: Queries.ImageDetails) => {
  switch (imageDetails.horizontalFocus) {
    case HorizontalFocus.START:
      return '0% 50%';
    case HorizontalFocus.LEFT:
      return '25% 50%';
    case HorizontalFocus.RIGHT:
      return '75% 50%';
    case HorizontalFocus.END:
      return '100% 50%';
    default:
      return '50% 55%';
  }
};
