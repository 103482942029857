import React from 'react';
import { BuildType } from 'src/enums/build/build-type';
import { DEFAULT_CLUB_ID } from 'src/content/constants';
import { Language } from 'src/enums/language';
import { getBuildTypesFlags } from './site-context-helper';
import { defaultStyling, Styling } from './styling';
import { AlternatePage, PageLanguage } from 'src/components/common/page-context';
import { PAGE_LEVEL } from 'src/enums/page-level';

export type SiteConfig = {
  siteId: string;
  isGlobalPage: boolean;
  language: Language;
  pageLanguages?: PageLanguage[];
  alternatePages?: AlternatePage[];
  buildType: BuildType;
  isMeridian: boolean;
  isBlaze: boolean;
  isHarbour: boolean;
  isDL: boolean;
  styling: Styling;
  basePath: string;
  untranslatedPath: string;
  pageLevel: PAGE_LEVEL;
  seo: {
    allowPageIndex: boolean | undefined;
  };
};

export const SiteContext = React.createContext<SiteConfig>({
  siteId: DEFAULT_CLUB_ID,
  isGlobalPage: false,
  language: Language.EN_GB,
  buildType: BuildType.DAVID_LLOYD_UK,
  ...getBuildTypesFlags(BuildType.DAVID_LLOYD_UK),
  styling: defaultStyling,
  basePath: '/',
  untranslatedPath: '/',
  pageLevel: null,
  seo: {
    allowPageIndex: undefined,
  },
});
