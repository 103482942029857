import React from 'react';
import { Icon } from './icon-types';

const Search: Icon = ({ fill = 'white', svgStyle }) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={svgStyle}
  >
    <path
      d="M16.375 14.6562C16.5312 14.8125 16.5312 15.0625 16.375 15.1875L15.6562 15.9062C15.5312 16.0625 15.2812 16.0625 15.125 15.9062L11.3438 12.125C11.2812 12.0312 11.25 11.9375 11.25 11.8438V11.4375C10.0938 12.4062 8.625 13 7 13C3.40625 13 0.5 10.0938 0.5 6.5C0.5 2.9375 3.40625 0 7 0C10.5625 0 13.5 2.9375 13.5 6.5C13.5 8.125 12.875 9.625 11.9062 10.75H12.3125C12.4062 10.75 12.5 10.8125 12.5938 10.875L16.375 14.6562ZM7 11.5C9.75 11.5 12 9.28125 12 6.5C12 3.75 9.75 1.5 7 1.5C4.21875 1.5 2 3.75 2 6.5C2 9.28125 4.21875 11.5 7 11.5Z"
      fill={fill}
    />
  </svg>
);

export default Search;
