import React, { useContext } from 'react';
import { GlobalCmsContent } from 'src/content/global-content';
import { SiteContext } from 'src/context/site-context';
import mq from 'src/styling/media-queries';
import { Styling } from 'src/context/styling';
import { CSSInterpolation } from '@emotion/serialize';
import { WebsiteLink } from 'src/components/common/website-link';
import DavidLloydLogo from 'src/components/icons/david-lloyd-logo';
import { flex } from 'src/styling/constants';
import MarkdownToHtmlBlock from 'src/components/common/markdown-to-html-block';
import CloudinaryImage from 'src/components/common/cloudinary-image';
import CountrySelection from 'src/components/layout/footer/redesign/country-selection';
import FooterCategory from 'src/components/layout/footer/redesign/footer-category';
import { BuildType } from 'src/enums/build/build-type';
import { getFooterColors } from 'src/components/layout/footer/redesign/colors';
import FixedImage from 'src/components/common/fixed-image';
import fixedImages from 'src/helpers/fixed-images';
import { amplitudeTrackEvent } from 'src/helpers/amplitude-event-track';

const createComponentStyling = (styling: Styling, buildType: BuildType, isDL?: boolean) => {
  const footerColors = getFooterColors(buildType);

  return {
    container: {
      backgroundColor: footerColors.backgroundColor,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      color: footerColors.text,
    },
    footer: mq.withSmallTablet({
      maxWidth: isDL ? '92.25rem' : null,
      width: '100%',
      padding: ['2.5rem 1.25rem 4.5rem', '2.5rem 2.5rem', '2.5rem 3.75rem'],
      gap: [0, 0, '0.75rem'],
    }),
    desktopOnly: mq({
      display: ['none', 'unset'],
    }),
    topSectionWrapper: mq({
      gap: ['2.5rem', '1.5rem'],
      display: 'flex',
      flexDirection: ['column', 'row'],
      alignItems: 'center',
    }),
    footerSection: mq({
      flexBasis: ['100%', '20%'],
      flexGrow: ['initial', 1],
    }),
    logo: {
      padding: '2.5rem 0',
    },
    appLinksWrapper: {
      justifyContent: 'center',
    },
    appLinks: {
      justifyContent: ['center', 'start'],
      alignItems: 'center',
      flexWrap: 'wrap',
      columnGap: '1.5rem',
      rowGap: '0.25rem',
    },
    mainContentWrapper: mq({
      borderBottom: '1px solid',
      borderBottomColor: footerColors.border,
      paddingBottom: ['4rem', '4.75rem'],
      paddingTop: ['3.25rem', 0],
      marginBottom: '1.5rem',
      gap: '1.5rem',
      display: 'flex',
      flexDirection: ['column', 'row'],
    }),
    sectionTitle: [
      styling.fonts.bold,
      mq({
        color: footerColors.heading,
        fontSize: '1rem',
        lineHeight: '125%',
        marginBottom: ['1rem', '2rem'],
      }),
    ],
    imageLink: {
      display: 'inline-block',
    },
    socialMediaSection: {
      marginTop: '4rem',
    },
    socialMediaIcons: mq({
      columnGap: ['2.25rem', '2rem'],
      rowGap: '0.5rem',
      flexWrap: 'wrap',
    }),
    copyrightInformation: [
      styling.fonts.regular,
      {
        'color': footerColors.text,
        'fontSize': '1rem',
        'lineHeight': '125%',
        'strong': [styling.fonts.bold],
        'p, strong': {
          '&:not(:last-child)': {
            marginBottom: '0.75rem',
          },
          '&:last-child': {
            marginBottom: 0,
          },
        },
      },
    ],
    disclaimer: [
      styling.fonts.regular,
      {
        fontSize: '1rem',
        lineHeight: '125%',
        margin: '0.75rem 0 0',
      },
    ],
  } satisfies Record<string, CSSInterpolation>;
};

const footerImageHeight = ['2.5rem', '2.25rem'];

type BrandLogoProps = {
  buildType: BuildType;
};

const BrandLogo = ({ buildType }: BrandLogoProps) => {
  if (buildType === BuildType.MERIDIAN) {
    return (
      <div css={{ height: '6rem', width: 'fit-content', margin: '-1.5rem 0' }}>
        <FixedImage imageDetails={fixedImages.lightBgMeridianLogo} />
      </div>
    );
  }

  if (buildType === BuildType.HARBOUR) {
    return (
      <div css={{ height: '5rem', width: 'fit-content' }}>
        <FixedImage imageDetails={fixedImages.whiteHarbourLogo} />
      </div>
    );
  }

  return <DavidLloydLogo size="11.25rem" />;
};

type AppLinksSectionProps = {
  title: string;
  images: readonly Queries.FooterImage[];
  componentStyling: ReturnType<typeof createComponentStyling>;
};

const AppLinksSection = ({ title, images, componentStyling }: AppLinksSectionProps) => {
  const { pageLevel } = useContext(SiteContext);
  const handleClick = (name: string) =>
    amplitudeTrackEvent(`Click to App link - ${name} - Footer`, {
      custom_page: window.location.origin,
      custom_level: pageLevel,
    });

  return (
    <div css={[componentStyling.footerSection, flex.column, componentStyling.appLinksWrapper]}>
      {title && <div css={componentStyling.sectionTitle}>{title}</div>}
      <div css={[componentStyling.appLinks, flex.row]}>
        {(images ?? []).map((image) => (
          <div key={image.image.alternateText}>
            <a
              css={componentStyling.imageLink}
              href={image.link}
              target={image.openInNewTab ? '_blank' : '_self'}
              rel="noopener noreferrer"
              onClick={() => handleClick(image.image.alternateText)}
            >
              <CloudinaryImage
                imageDetails={image.image}
                styles={mq({
                  height: footerImageHeight,
                  width: 'auto',
                })}
                loading="lazy"
              />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

type SocialMediaSectionProps = {
  title: string;
  images: readonly Queries.FooterImage[];
  componentStyling: ReturnType<typeof createComponentStyling>;
};

const SocialMediaSection = ({ title, images, componentStyling }: SocialMediaSectionProps) => {
  const { pageLevel } = useContext(SiteContext);
  const handleClick = (name: string) =>
    amplitudeTrackEvent(`Click to Social Media - ${name} - Footer`, {
      custom_page: window.location.origin,
      custom_level: pageLevel,
    });

  return (
    <div css={componentStyling.socialMediaSection}>
      <div css={componentStyling.sectionTitle}>{title}</div>
      <div css={[componentStyling.socialMediaIcons, flex.row]}>
        {images.map((image) => (
          <div key={image.image.alternateText}>
            <a
              css={componentStyling.imageLink}
              href={image.link}
              target={image.openInNewTab ? '_blank' : '_self'}
              rel="noopener noreferrer"
              onClick={() => handleClick(image.image.alternateText)}
            >
              <CloudinaryImage
                imageDetails={image.image}
                styles={mq({
                  height: footerImageHeight,
                  width: 'auto',
                })}
                loading="lazy"
              />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

type CopyrightInformationProps = {
  copyrightInformation: string;
  imagesDisclaimer: string;
  isGlobalPage: boolean;
  componentStyling: ReturnType<typeof createComponentStyling>;
};

const CopyrightInformation = ({
  copyrightInformation,
  imagesDisclaimer,
  isGlobalPage,
  componentStyling,
}: CopyrightInformationProps) => (
  <div>
    {copyrightInformation && (
      <MarkdownToHtmlBlock
        colorOverride="unset"
        cssOverride={componentStyling.copyrightInformation}
        markdownText={copyrightInformation}
      />
    )}
    {imagesDisclaimer && !isGlobalPage && (
      <p css={componentStyling.disclaimer}>{imagesDisclaimer}</p>
    )}
  </div>
);

const FooterRedesign = () => {
  const { newFooterContent } = useContext(GlobalCmsContent);
  const { styling, isGlobalPage, buildType, isDL, pageLevel } = useContext(SiteContext);

  if (!newFooterContent) {
    return;
  }

  const componentStyling = createComponentStyling(styling, buildType, isDL);

  const numberOfFooterNavMenuSections = newFooterContent.footerNavMenuSections?.length ?? 0;

  const logoAmplitudeAction = () =>
    amplitudeTrackEvent('Click to Logo - Footer', {
      custom_page: window.location.origin,
      custom_level: pageLevel,
    });

  const logoClick = () => {
    logoAmplitudeAction();
    document.body.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <footer css={componentStyling.container} id="footer">
      <div css={[componentStyling.footer, flex.column]}>
        <div css={componentStyling.topSectionWrapper}>
          <div css={[componentStyling.logo, componentStyling.footerSection]}>
            <WebsiteLink to="/" handleClick={logoClick}>
              <BrandLogo buildType={buildType} />
            </WebsiteLink>
          </div>
          {numberOfFooterNavMenuSections > 1 &&
            new Array(numberOfFooterNavMenuSections - 1)
              .fill(null)
              .map((_, index) => (
                <div
                  key={index}
                  css={[componentStyling.footerSection, componentStyling.desktopOnly]}
                />
              ))}
          {!!newFooterContent.footerAppLinks.images?.length && (
            <AppLinksSection
              title={newFooterContent.footerAppLinks.title}
              images={newFooterContent.footerAppLinks.images}
              componentStyling={componentStyling}
            />
          )}
        </div>
        <div css={componentStyling.mainContentWrapper}>
          {(newFooterContent.footerNavMenuSections ?? []).map(({ title, links }) => (
            <div css={componentStyling.footerSection} key={title}>
              <FooterCategory title={title} links={links} />
            </div>
          ))}
          <div css={componentStyling.footerSection}>
            <div css={componentStyling.sectionTitle}>
              {newFooterContent.footerCountrySelection.title}
            </div>
            <CountrySelection
              currentCountryText={newFooterContent.footerCountrySelection.currentCountryText}
              links={newFooterContent.footerCountrySelection.links ?? []}
            />
            {!!newFooterContent.footerSocialMediaLinks.images?.length && (
              <SocialMediaSection
                title={newFooterContent.footerSocialMediaLinks.title}
                images={newFooterContent.footerSocialMediaLinks.images}
                componentStyling={componentStyling}
              />
            )}
          </div>
        </div>
        <CopyrightInformation
          copyrightInformation={newFooterContent.footerCopyrightInformation}
          imagesDisclaimer={newFooterContent.footerImagesDisclaimer}
          isGlobalPage={isGlobalPage}
          componentStyling={componentStyling}
        />
      </div>
    </footer>
  );
};

export default FooterRedesign;
