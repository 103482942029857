export const staticTextLookup = {
  FAQs: "FAQ",
  aCopyOfThisGuaranteeShouldBeRetained: "Una copia di questa garanzia deve essere conservata dal cliente, verrà inviata via e-mail di conferma",
  about: "Chi siamo",
  aboutYou: "Informazioni su di te",
  accessToClubsAndSpaRetreats: "Platinum membership will also give you access to other David Lloyd clubs. See below for details of the clubs you'll be able to access.\nPlus, if the club has a Spa Retreat, this will also include access to the Spa Retreat facilities. Click here for a list of clubs with Spa Retreat.",
  accessToClubsNearYou: "Accesso ad un altro club vicino al tuo",
  accessToOtherClubs: "Accesso ad un altro club",
  accountHoldersName: "Nome del titolare del conto",
  accountNumber: "Numero di conto",
  activating: "Attivazione",
  addPartnerAmount: "Aggiungi un partner %amount%",
  addSelectedMembership: "Aggiungi la membership selezionata",
  addSelectedPromotion: "Aggiungi promozione selezionata",
  addToCalendar: "Aggiungi Al Calendario",
  addYourAssociates: "Aggiungi il tuo/la tua partner e i figli",
  addYourChildren: "Aggiungi i tuoi figli",
  addYourPartner: "Aggiungi il tuo/la tua partner",
  addYourPromotions: "Seleziona la tua promozione",
  additionalDetailsSecondErrorMessage: "Spiacenti, al momento non siamo in grado di prendere i tuoi dati online; lo faremo in occasione della tua prima visita al club.",
  additionalMembers: "Membri aggiuntivi",
  additionalMemberships: "Membership aggiuntiva",
  address: "Indirizzo",
  addressLine1: "Riga 1 indirizzo",
  addressLine2: "Riga 2 indirizzo",
  addressLinePlaceholder: "Inserire l'indirizzo",
  adultMembers: "Adulti",
  adults: "Adulti",
  afterSchool: "Dopo la scuola",
  allAgesWelcome: "Per tutte le età",
  allFacilities: "Tutte le strutture",
  alsoAtClub: "Anche al %clubName%",
  amountSubjectToPendingPriceRise: "L'importo indicato è soggetto a qualsiasi aumento di prezzo in sospeso, consulta la squadra del club per ulteriori dettagli.",
  and: "e",
  annualOnJoiningTheClubPayment: "iscrivendoti al club, ti verrà chiesto di effettuare un pagamento comprendente una quota di iscrizione, un pagamento pro rata per il resto di questo mese (se il caso) e un pagamento che copre il costo della membership per 12 mesi di calendario. Se desideri migliorare la tua esperienza, puoi aggiungere altri prodotti, come personal training o coaching, i quali possono essere anche pagati oggi.",
  annually: "Annuale",
  anythingElseYoudLikeUsToKnow: "Altre informazioni che desideri fornirci",
  anytimeClubAccess: "Accesso libero al club",
  app: "App",
  appQrCodeInstructions: "Basta allineare la fotocamera del telefono al codice e, una volta riconosciuto, toccare il collegamento per scaricare l'app %brandName%.",
  appUpdateRequired: "Aggiornamento app richiesto",
  areYouSureYouDoNotWantToBeContacted: "Sei sicuro di non voler ricevere le nostre e-mail o i nostri SMS?",
  associatesWereReset: "Sfortunatamente, questo pacchetto non supporta tutti i membri aggiuntivi selezionati - scegliere un altro pacchetto se si desidera includere questi membri.",
  atClub: "Al %clubName%",
  atHomeDigitalContent: "@home include contenuti digitali, tra cui lezioni live e on-demand, seminari didattici e consigli dei nostri trainer",
  atUnit: "Al %unitName%",
  availablePromotions: "promozioni disponibili",
  availableSlot: "%numberOfSlots% fascia oraria disponibile",
  availableSlots: "%numberOfSlots% fasce orarie disponibili",
  awaitingPayment: "Awaiting payment",
  back: "Indietro",
  backToTop: "Torna in alto",
  beforeYouBecomeAMember: "Prima di diventare membro, vorremmo mostrarti tutti i vantaggi di questo club",
  bestValue: "Più conveniente",
  bookAClass: "Prenota una lezione",
  bookAClubWelcome: "Prenota il tuo benvenuto al club",
  bookAClubWelcomeError: "Scusa, qualcosa è andato storto. Contattaci e troveremo un orario per il tuo tour.",
  bookATour: "Prenota un tour",
  bookATourError: "Scusa, qualcosa è andato storto. Contattaci e troveremo un orario per il tuo tour.",
  bookATourHeader: "Prenota il tuo tour",
  bookATourSubtext: "Scegli un club per prenotare un tour",
  bookOnTheMove: "Prenota ovunque tu sia",
  bookYourClubWelcome: "Prenota il tuo benvenuto al club",
  bookYourClubWelcomeAt: "Prenota ora il tuo benvenuto al %clubName%",
  bookYourTour: "Prenota un tour",
  bookYourTourOf: "Prenota un tour del %clubName%",
  booked: "Prenotato",
  bookingYourClubWelcome: "Prenotazione del tuo benvenuto al club...",
  bookingYourTour: "Prenotazione del tuo tour in corso...",
  buyapowaWarningPopupCancelButtonText: "Scegli un altro club",
  buyapowaWarningPopupConfirmButtonText: "Iscriviti a %clubName%",
  buyapowaWarningPopupHeader: "Si prega di notare",
  buyapowaWarningPopupText: "Il Club %clubName% non è attualmente iscritto al nostro programma «Invita un amico», quindi né tu né la persona che ti ha segnalato avrete diritto a ricevere alcun premio se continui con questa registrazione.",
  byClickingFinishYouAcceptDDConditions: "Cliccando su \"%buttonText%\" confermi di essere il titolare del conto bancario e che tale conto non richiede che un'altra persona autorizzi gli addebiti. Se si tratta di un account personale o aziendale dove necessita l'autorizzazione più di una persona per gli addebiti su di esso, contattaci direttamente.",
  cancel: "Cancel",
  cancelMyMembership: "Cancel my membership",
  cantFindASlot: "Non riesci a trovare una fascia oraria?",
  cardCollectionTime: "Orario ritiro tessera/tour del club",
  careers: "Lavora con noi",
  changeClub: "Cambia club",
  changePreferences: "Modifica le preferenze",
  changeSelectedPackage: "Cambia la membership selezionata",
  checkYourAccessToASpecificClub: "Controlla i tuoi ingressi ad un club specifico:",
  child2AndUnder: "Membership per bambini:\n Fino a 2 anni",
  child3To18: "Membership per bambini:\n3-18 anni",
  childMembers: "Bambini",
  children: "Bambini",
  childrenOverview: "Panoramica bambini",
  chooseAClub: "Scegli un club",
  chooseAClubToRegisterForClubTrial: "Scegli un club qui sotto",
  chooseHowYouPay: "Scegli come pagare",
  chooseMembershipLength: "Seleziona la durata della tua membership",
  chooseYourStartDate: "Scegli la tua data di inizio",
  clickHereIfYouAreNot: "Fai clic qui se non sei %firstName%",
  close: "Chiuso",
  closeDetails: "Informazioni sulla chiusura",
  club: "Club",
  clubEmail: "Email del Club",
  clubFacilities: "Strutture club",
  clubFinderSearchPlaceholder: "Seleziona una città",
  clubOpeningHours: "Orari di apertura club",
  clubOpeningTimes: "Orari di apertura club",
  clubOverview: "Panoramica club",
  clubTourTime: "Orario tour del club",
  clubVideo: "Video club",
  clubWelcomeMeetingTime: "Orario di incontro di benvenuto del club",
  comingSoon: "In arrivo",
  completeDirectDebitPopupText: "Risparmia tempo alla tua prima visita creando ora l’Addebito diretto",
  completeForm: "Compila il form sotto.",
  completeItInClub: "Completa l’Addebito diretto al Club",
  completeItNow: "Completa l’Addebito diretto subito",
  completeSEPAInClub: "Completa l’Addebito diretto al Club",
  completeSEPANow: "Completa l’Addebito diretto subito",
  completeSEPAPopupText: "Risparmia tempo alla tua prima visita creando ora l’Addebito diretto",
  confirm: "Confirm",
  confirmNoPaymentTaken: "Please confirm no payment has been processed",
  confirmPassword: "Confermi password",
  confirmPasswordPlaceholder: "Confermi la nuova password",
  confirmPaymentTaken: "Please confirm payment has been processed",
  contact: "Contatti",
  contactInformation: "Informazioni di contatto",
  contactMeToArrange: "Contattatemi per stabilire un orario",
  contactNotPromotedMonthlyPaymentErrorSubtitle: "La configurazione della tua iscrizione richiede alcuni istanti. Riprova, ma non preoccuparti, l'importo non ti verrà addebitato due volte.",
  contactNotPromotedMonthlyPaymentErrorTitle: "Ci dispiace, non siamo veloci come te oggi!",
  contactPreferencesAfterPrivacyPolicy: "per tutti i dettagli. Spunta le caselle qui sotto per restare in contatto.",
  contactPreferencesBeforePrivacyPolicy: "Ci piacerebbe restare in contatto e aggiornarti sui nostri ultimissimi prodotti, servizi e offerte. Se sei d’accordo, spunta le caselle qui sotto. Puoi sempre cambiare idea. Consulta la nostra",
  contactTheClubForOpeningTimes: "Contatta il club per maggiori informazioni sugli orari di apertura",
  "continue": "Continua",
  cookiePolicyDescription: "Il nostro sito web utilizza i cookie per migliorare la tua esperienza. Continuando la navigazione, accetti il nostro utilizzo dei cookie.",
  cookiesPolicy: "Politica dei Cookie",
  copyright: "Copyright",
  coreStrengthAndHIIT: "\"Forza, stabilità e resistenza, HIIT, Salute e benessere, Yoga, Cycling",
  corporateFitness: "Corporate Fitness",
  countryBelgium: "Belgium",
  countryFrance: "France",
  countryGermany: "Germany",
  countryIreland: "Ireland",
  countryItaly: "Italy",
  countryNetherlands: "Netherlands",
  countrySpain: "Spain",
  countrySwitzerland: "Switzerland",
  countryUnitedKingdom: "United Kingdom",
  county: "Regione",
  countyPlaceholder: "Inserire la regione",
  couple: "Coppia",
  creche: "Nido",
  creditorAddress: "Indirizzo",
  creditorId: "Codice identificativo del creditore",
  creditorName: "Nome del creditore",
  dateAtTime: "il %date% alle ore %time%",
  dateOfBirth: "Data di nascita",
  days: "giorni",
  daytime: "Mattina",
  detailsAddedToMembershipAccount: "Questi dettagli verranno ora aggiunti all’account della tua membership.",
  diamondPlusRequired: "Diamond Plus membership is required to access certain Spa Retreats",
  directDebitClubOpens: "Il primo addebito diretto partirà all'apertura del Club, tenendo in considerazione le promozioni disponibili al momento della sottoscrizione",
  directDebitClubReopens: "Il tuo addebito diretto mensile inizierà alla riapertura del club e terrà conto di eventuali promozioni relative alla membership in corso",
  directDebitMandate: "Mandato di addebito diretto",
  directDebitMembershipStarts: "Il primo addebito diretto partirà nella data di inizio del tuo abbonamento, tenendo in considerazione le promozioni disponibili al momento della sottoscrizione",
  discoverTheCreche: "Scopri il nido",
  done: "Fatto",
  downloadAppPrompt: "Per effettuare prenotazioni e gestire la tua iscrizione, scarica l'app David Lloyd Clubs.",
  downloadDescriptionMembershipVerification: "Scarica la nostra app mobile prima della tua visita, così potremo aiutarti con la registrazione e a ottenere il massimo dalla tua iscrizione.",
  downloadDescriptionTennis: "Scarica la app per visualizzare i calendari, prenotare i corsi e i campi ovunque ti trovi.",
  downloadDescriptionTrialConfirmation: "Scaricala prima della tua prima visita, così potremo aiutarti con la registrazione e a ottenere il massimo dalla tua iscrizione.",
  downloadTheMobileAppHere: "Scarica qui la app mobile:",
  dr: "Dott.",
  duration: "Durata",
  edit: "Modifica",
  email: "E-mail",
  emailAddress: "Indirizzo e-mail",
  emailAddressesMustBeUnique: "Inserire un indirizzo e-mail univoco. Deve essere diverso dall'indirizzo e-mail fornito dal membro principale.",
  endsIn: "Termina il",
  enjoyTimeTogetherAt: "Godetevi del tempo insieme al %clubPrefix% %clubName%",
  enquireAboutMembership: "contattaci per informazioni sull’iscrizione",
  enquireNow: "Contattaci adesso per maggiori informazioni",
  enquireText: "Parlaci della tua richiesta",
  enterALocation: "Inserisci una località...",
  enterValidEmailAddress: "Si prega di inserire un indirizzo email valido",
  enterValidMobilePhoneNumber: "Inserisci un numero di cellulare valido",
  enterValidName: "Inserisci un nome valido comprensivo di una lettera maiuscola",
  enterValidPhoneNumber: "Inserisci un numero di telefono valido",
  enterValidPostCode: "Per favore inserire un codice postale valido",
  enterValidTaxId: "Please enter a valid tax ID",
  equipmentAt: "Le nostre attrezzature al %clubName%",
  exerciseClasses: "Corsi",
  exerciseClassesAt: "Allenamenti al %clubName%",
  experienceAllTheBenefits: "Scopri tutti i vantaggi di un’iscrizione al club.",
  expertPersonalTrainers: "Personal Trainer esperti",
  expertTuition: "Lezioni con gli esperti",
  exploreClub: "Esplora %clubName%",
  exploreKidsActivities: "Esplora le attività per bambini",
  failedToFetchExistingPayment: "A payment has already been made, but we encountered an error confirming details",
  familyAmpersandKids: "Famiglia e bambini",
  familyAndKids: "Famiglia e bambini",
  familySwim: "Nuoto per famiglie",
  female: "Donna",
  findAClub: "Trova un club",
  findOutMore: "Scopri di più",
  findYourClub: "Trova il tuo club",
  firstFullMonth: "Primo mese completo",
  firstMonthFees: "Poiché non siamo in grado di impostare l'addebito diretto in tempo per il primo mese completo, questo pagamento viene addebitato in anticipo",
  firstName: "Nome",
  fitnessOverview: "Panoramica fitness",
  fixedTermFee: "12 mesi di membership a partire da %month%",
  fixedTermFeeTooltip: "Pagamento a copertura del costo della membership per 12 mesi di calendario",
  flexible: "Flessibile",
  followUs: "Seguici",
  followedByMonthly: "Seguito dal pagamento mensile di",
  followedByMonthlyPaymentsTitle: "Seguito da pagamenti mensili",
  free: "Gratis",
  frequentlyAskedQuestions: "Domande Frequenti",
  friend: "Amico",
  friendsAndFamilyReferralAlreadyUsed: "Spiacenti, questo link è già stato utilizzato. Contatta la persona che ti ha segnalato per nominarti di nuovo.",
  friendsAndFamilyReferralExpired: "Siamo spiacenti, questo lonk è scaduto. Contatta la persona che ha comunicato il tuo nome affinchè lo faccia di nuovo.",
  friendsAndFamilyReferralNotFound: "Spiacenti, questo link non è stato trovato. Contatta la persona che ti ha segnalato per nominarti di nuovo.",
  from: "Da",
  from3MonthsPlus: "dai 3 mesi in su",
  from3To18Years: "dai 3 ai 18 anni",
  gender: "Sesso",
  germanAndFallbackOnly: "Test",
  getInTouch: "Contattaci",
  getStarted: "Inizi subito",
  getYourQuoteContactPreferencesAfterPrivacyPolicy: "per tutti i dettagli. Spunta la casella qui sotto per restare in contatto.",
  getYourQuoteContactPreferencesBeforePrivacyPolicy: "Ci piacerebbe restare in contatto e aggiornarti sui nostri ultimissimi prodotti, servizi e offerte. Se sei d’accordo, spunta la casella qui sotto. Puoi sempre cambiare idea. Consulta la nostra",
  groupExerciseClasses: "Classi di gruppo",
  gym: "Palestra",
  havingProblemsSavingMonthlyPaymentDetails: "Ci spiace, non siamo così veloci come te oggi! La configurazione della tua iscrizione richiede qualche attimo in più. Riprova, ma non preoccuparti, non ti verrà fatto nessun secondo addebito.",
  healthyBreakfast: "Colazione salutare",
  heatedSwimmingPools: "Piscina riscaldata",
  highlightsAtClub: "In evidenza al %clubPrefix% %clubName%*",
  hours: "ore",
  howCanWeContactYou: "Come possiamo contattarti?",
  iAgree: "Accetto",
  ifSomethingWereToChangeInYourLifestyle: "Se qualcosa dovesse cambiare nel tuo stile di vita, %brandName% offre un periodo di riflessione di 14 giorni. Parla con un membro del team per saperne di più.",
  ifSomethingWereToChangeRightToCancel: "Se qualcosa dovesse cambiare nel tuo stile di vita, hai il diritto di annullare la membership. Parla con un membro dello staff per saperne di più.",
  imageDisclaimer: "*Disclaimer: tutte le immagini sono inserite a scopo illustrativo e potrebbero non rappresentare il club da te selezionato.",
  importantInformation: "Informazioni importanti",
  includedInPromotion: "Incluso nella promozione",
  individual: "Individuale",
  initialEnquiryKeepYouUpdated: "Dopo la tua richiesta iniziale, saremo lieti di aggiornarti sui nostri ultimi prodotti, servizi e offerte. Se sei interessato, fai clic sulle caselle sottostanti.",
  initialMonthTerm: "Data di inizio del periodo di %monthDuration% mesi",
  isChildPresentText: "Mio figlio è nel Club con me oggi",
  isPartnerPresentText: "Il mio partner/la mia partner è nel Club con me",
  join: "Iscriviti",
  joinCorporate: "Iscriviti a Corporate",
  joinDifferentClub: "Iscriviti ad un altro Club",
  joinFriendsAndFamily: "Iscriviti come Family & Friends",
  joinOurReferralScheme: "Sembra che tu stia cercando di iscriverti al nostro programma di raccomandazione...",
  joinTodayAndSave: "Iscriviti oggi e risparmia",
  joiningFee: "Quota di adesione",
  joiningFeeFirstMonth: "inclusa quota di iscrizione e primo mese",
  joiningFeeProRataFirstMonth: "Quota di iscrizione, rimanenza del mese e primo mese intero",
  joiningFeeTooltip: "Pagamento per far parte del club",
  joiningFeeTwelveMonths: "Quota di iscrizione, resto del mese e abbonamento di 12 mesi di calendario",
  joiningFees: "Quote di iscrizione",
  joiningFeesApply: "Si applicano quote di adesione",
  joiningFeesWaived: "OFFERTA SPECIALE - risparmio sulla quota di iscrizione disponibile",
  joiningTheClubAsAFamily: "Iscriviti al club con tutta la famiglia",
  justMe: "Solo io",
  keepAnEyeOutForFutureTrials: "Controlla il nostro sito per futuri periodi di prova o inoltra una richiesta dal nostro sito, e il team del club si metterà in contatto con te",
  keepInTouch: "Restiamo in contatto",
  keepYouUpdated: "Ci piacerebbe aggiornarti sui nostri ultimissimi prodotti, servizi e offerte. Se ti sembra interessante, spunta le caselle qui sotto.",
  kickstartTitleWithName: "%firstName%, come descriveresti te stesso?",
  kickstartTitleWithoutName: "Come descriveresti te stesso?",
  kidsActivities: "Attività per bambini",
  kidsBuildYourOwnPizza: "Crea la tua pizza dedicato ai bambini",
  kidsClub: "Club per i bambini",
  lastName: "Cognome",
  latestNews: "Latest News",
  learnMore: "Scopri di più",
  learnSomethingNew: "Impara qualcosa di nuovo",
  letThemEnjoyTheClub: "Lascia che si godano il club mentre tu ti prendi un po’ di tempo per te.",
  letUsKnowWhatYouAreInterestedIn: "Facci sapere cosa ti interessa di più per personalizzare la tua esperienza",
  letUsKnowWhatYouAreLookingForwardTo: "Informaci su quali aree del club non vedi l'ora di utilizzare.",
  likeOurPage: "Metti “Mi piace” alla nostra pagina",
  linkIsInvalid: "Spiacenti, questo collegamento non è valido",
  loadingClubWelcomeTimes: "Caricamento in corso del  benvenuto al club...",
  loadingTourTimes: "Orari per il tour in caricamento...",
  logOut: "Disconnetti",
  login: "Accedi",
  male: "Uomo",
  master: "Master",
  meAndMyFamily: "Io + famiglia",
  meAndMyPartner: "Io + partner",
  membersLogin: "Accedi",
  membershipDescription: "Disponibile nella tua email di benvenuto o sulla tessera della membership",
  membershipDiscountUntil: "Fino a %date%",
  membershipEnquiryDetails: "L'utente ha selezionato: %duration%%packageKey%%packageTypes%",
  membershipFees: "Quote della membership",
  membershipFeesUpUntil: "Quote associative fino al %date%",
  membershipNumber: "Numero iscrizione",
  membershipPlaceholder: "Inserisci il numero di iscrizione",
  membershipPriceChangedError: "The payment has been confirmed, if you wish to change membership details, please complete joining journey and manually update club centric account",
  membershipSaving: "Risparmio sulla membership",
  membershipStartClubConfirm: "Il tuo Club confermerà data di inizio del tuo abbonamento",
  membershipStartClubOpens: "L'abbonamento è attivo dal momento dell'apertura del Club",
  membershipTermsAndConditions: "T&C per l'iscrizione",
  membershipTrial: "Iscrizione di prova",
  memberships: "Iscrizione",
  min: "min",
  minutes: "minuti",
  miss: "Sig.na",
  monthlyDirectDebitsStartOn: "Pagamenti mensili ad addebito diretto per iniziare",
  monthlyPaymentsDuringOfferPeriodTitle: "Pagamenti mensili durante il periodo in offerta",
  monthlySavings: "Risparmio mensile",
  months: "mesi",
  more: "Più informazioni",
  moreDetailsSkipDirectDebit: "Desidero creare il mio Addebito diretto quando visito il Club",
  moreDetailsSkipSEPA: "Desidero creare il mio Addebito diretto quando visito il Club",
  mostPopular: "Più popolari",
  mostRecent: "Most Recent",
  mr: "Sig.",
  mrs: "Sig.ra",
  ms: "Signorina",
  mx: "Signor",
  myMembership: "La mia membership",
  nearlyThere: "Ci siamo quasi",
  next: "Successivo",
  noAdditionalMembershipsAvailable: "Non ci sono opzioni di membership aggiuntive attualmente disponibili.",
  noAvailablePromotions: "Non ci sono promozioni disponibili.",
  noClubWelcomeSlotsError: "Sfortunatamente, al momento non abbiamo orari di benvenuto al club disponibili online. Ti preghiamo di contattarci, e ti troveremo un orario disponibile",
  noClubsInClubFinder: "Non ci sono club in quest’area, ma quelli più vicini sono elencati di seguito.",
  noJoiningFee: "Nessuna quota di iscrizione",
  noJoiningFeeOffer: "Offerta speciale Nessuna quota di adesione",
  noMembershipFeeForMonthOffer: "Offerta bonus: Nessuna quota di iscrizione per %month%",
  noMembershipFeeUntilMonthOffer: "Offerta bonus: Nessuna quota di iscrizione fino a %month%",
  noPreferencePackageKey: "nessuna preferenza per il tipo di pacchetto della membership; ",
  noPreferencePackageTypes: "nessuna preferenza per il tipo di accesso alla membership.",
  noTourSlotsError: "Purtroppo, in questo momento non ci sono orari per il tour disponibili online. Contattaci e troveremo un orario per il tuo tour",
  normally: "Generalmente",
  notAllMembershipsAvailable: "Non tutte le membership sono disponibili",
  notSureWhichClubsNearest: "Non so qual è il club più vicino",
  now: "Ora",
  onDate: "il %date%",
  onFacebook: "%clubName% su Facebook",
  onInstagram: "%clubName% su Instagram",
  onJoiningTheClubPayment: "Iscrivendoti al club, ti verrà chiesto di effettuare un pagamento iniziale comprendente una quota di iscrizione e un pagamento pro rata (questo è il tuo pagamento di oggi). Seguirà un abbonamento mensile che verrà addebitato tramite addebito diretto. Se desideri migliorare la tua esperienza, puoi aggiungere altri prodotti come personal training o coaching, i quali possono anche essere pagati oggi.",
  oneMonth: "Un mese",
  onlyAvailableForALimitedTime: "Disponibile solo per un periodo di tempo limitato",
  openingHours: "Orari di apertura",
  optInToSpecialOffer: "Aggiungilo per aderire alla promozione di adesione",
  optOutAtAnyTimeAfterPrivacyPolicy: "per tutti i dettagli!",
  optOutAtAnyTimeBeforePrivacyPolicy: "Puoi rinunciare in qualsiasi momento. Leggi i nostri",
  options: "Opzioni",
  organisation: "Organizzazione",
  organisationOther: "Altro",
  other: "Altro",
  ourEquipmentAt: "Le nostre attrezzature al %clubName%",
  ourFriendlyTeamAreLookingForwardToMeetingYou: "Il nostro simpatico team non vede l’ora di conoscerti",
  outdoorPoolTimesWeatherDependent: "Gli orari delle piscine all'aperto dipendono dal clima",
  over14EmailAddress: "I soci di età superiore ai 14 anni possono utilizzare le strutture del club. Inserite il loro indirizzo e-mail per poterli iscrivere.",
  over14PhoneNumber: "I soci di età superiore ai 14 anni possono utilizzare l'applicazione mobile %brandName%, inserendo il numero di cellulare.",
  "package": "Pacchetto",
  packageSelected: "%packageName% selezionato",
  padel: "Pádel",
  paidOnline: "Pagamento online",
  partner: "Associato",
  partnerEmail: "Al tuo partner verrà inviata un'e-mail di conferma dei dettagli dell'iscrizione e per completare la configurazione dell'iscrizione. Inserisci il suo numero di cellulare e ti invieremo un link per registrarti all'app mobile %brandName%.",
  partnerWasReset: "Sfortunatamente, questo pacchetto non ha un’opzione partner - seleziona un altro pacchetto che prevede l’inclusione del partner",
  partnersAndSponsors: "Partner e sponsor",
  password: "Password",
  passwordPlaceholder: "Inserisci la password",
  payAnnually: "Paga annualmente",
  payAtReception: "Pagamento alla reception",
  payMonthly: "Pagamento mensile",
  payOnline: "Pay online",
  payingAnnually: "Il pagamento annuale è in genere più economico rispetto al pagamento mensile",
  paymentAlreadyConfirmed: "The payment has been confirmed, skip to the monthly payment page",
  paymentDetails: "Dettagli pagamento",
  paymentLinkExpired: "Payment link expired",
  paymentReceived: "Payment received",
  paymentRefused: "Pagamento rifiutato",
  penneArrabbiata: "Penne all’arrabbiata",
  perMonth: "per mese",
  personalInformation: "Informazioni personali",
  phoneNumber: "Numero di cellulare",
  pickAnotherTime: "Scegli un altro orario",
  pleaseCheckAndTryAgain: "Controlla e riprova",
  pleaseCheckYourCardDetails: "Controlla i dati della carta",
  pleaseCheckYourDetailsAndTryAgain: "Controlla i dati e riprova",
  pleaseCheckYourEmail: "per favore controlla la posta elettronica",
  pleaseClickHereTo: "Fai clic qui per",
  pleaseContactOnlineJoining: "Per assistenza contatta %email% per favore",
  pleaseDoubleCheckAndTryAgain: "Controlla i tuoi dati e riprova",
  pleaseEnterANumber: "Inserire un numero",
  pleaseMakePaymentAtReception: "Please make payment at reception",
  pleasePickAnotherTime: "Scegliete un'altra ora.",
  pleaseRefreshOrTryAgainLater: "Aggiorna la pagina o riprova più tardi",
  pleaseSelectATime: "Seleziona un orario",
  pleaseTryAgainLater: "Riprova più tardi",
  pleaseUseAnAlternativeBrowser: "\"",
  pleaseVerifyCaptcha: "Si prega di verificare il captcha per continuare",
  policies: "Policy",
  policiesAndDocuments: "Politicy e documenti",
  poolOpeningHours: "Orari di apertura piscine",
  poolOpeningTimes: "Orari di apertura piscine",
  poolTimes: "Orari piscina",
  pools: "Piscine",
  poolsAreClosed: "Tutte le nostre piscine sono attualmente chiuse",
  postCode: "CAP",
  postCodePlaceholder: "Inserire il codice postale",
  preferNotToSay: "Preferisco non rispondere",
  press: "Notizie",
  pressCentre: "Press Centre",
  previous: "precedente",
  priceDuringPromotionalPeriod: "Prezzo durante il periodo promozionale",
  pricePerAdult: "%adultPrice% per adulto",
  pricePerChild: "%childPrice% per bambino",
  primaryMember: "Iscritto primario",
  privacyPolicy: "Politica sulla privacy",
  promotionSelected: "Promozione selezionata",
  promotionalItems: "Articoli promozionali",
  promotions: "Promozioni",
  qrCodeExpired: "QR code expired - please pay at reception",
  quickRegistration: "Iscrizione Rapida",
  referral: "Rinvio",
  refundedIfYouJoinOnAStandardMembership: "importo da pagare se non procedi a un’iscrizione standard",
  regionUnitedKingdomEast: "East",
  regionUnitedKingdomGreaterLondon: "Greater London",
  regionUnitedKingdomMidlands: "Midlands",
  regionUnitedKingdomNorthEast: "North East",
  regionUnitedKingdomNorthWest: "North West",
  regionUnitedKingdomNorthernIreland: "Northern Ireland",
  regionUnitedKingdomScotland: "Scotland",
  regionUnitedKingdomSouth: "South",
  regionUnitedKingdomSouthEast: "South East",
  regionUnitedKingdomSouthWest: "South West",
  regionUnitedKingdomWales: "Wales",
  registerForClubTrial: "Inizia oggi la tua prova di 14 giorni",
  registerForClubTrialButton: "Inizia la prova",
  registeredCompanyNumber: "Numero di registrazione azienda:",
  registrationErrorMessage: "Controlla che i tuoi dati siano corretti. Se hai problemi con la registrazione di un account, inviaci un’e-mail all’indirizzo <a style=\"color: %emailColor%;\" href=\"mailto:%email%\">%email%</a> e ci metteremo in contatto il prima possibile.",
  registrationUnauthorised: "Autorizzazione non riuscita",
  relaxAmpersandSocialise: "Rilassati e socializza",
  relaxAndEnjoySpaRetreats: "Relax and enjoy Spa Retreats",
  relaxAndEnjoyTheSpaRetreatsAt: "Rilassati e goditi le nostre SPA a:",
  relaxAndSocialise: "Rilassati e socializza",
  remainderOfFirstMonth: "Resto del primo mese",
  remainderOfFirstMonthAnd12MonthsMembership: "Resto del primo mese e membership di 12 mesi di calendario",
  remainderOfFirstMonthAndFirstFullMonth: "Resto del primo mese e primo mese completo",
  remainderOfThisMonth: "Resto di questo mese",
  remainderOfThisMonthTooltip: "Pagamento a copertura del costo della membership per il resto di questo mese",
  requestingAClubWelcome: "Richiesta per un appuntamento di benvenuto al club",
  retry: "Riprova",
  saveAmount: "Risparmia %amount%",
  savingUpTo: "Risparmia fino a %price%*",
  scanQrCodeToDownload: "Scansiona il codice QR per scaricare l'app",
  scanQrCodeToPay: "Scan the QR code to proceed to payment page",
  seeAllFacilities: "Vedi tutte le strutture",
  seeDetails: "Mostra dettagli",
  seeMore: "Mostra di più...",
  selectADate: "Seleziona una data",
  selectAnOrganisation: "Seleziona un’azienda o un gruppo...",
  selectOptionsBelow: "Scegli tra le opzioni qui sotto per trovare attività per tutta la famiglia.",
  selectPackage: "Seleziona %packageName%",
  selectPromotion: "Seleziona la promozione",
  selectTitle: "Seleziona un titolo...",
  selectedDuration: "una durata del pacchetto %durationType%; ",
  selectedPackageKey: "una membership %packageKey%; ",
  selectedPackageTypes: "accesso alla membership che include %packageTypes%.",
  selectedTourTimeNoLongerAvailable: "Purtroppo, l'orario del tour selezionato non è più disponibile.",
  selectedTourTimeNoLongerAvailableHeader: "Orario del tour non disponibile",
  sendQuote: "Invia preventivo",
  sepaGuaranteeLine1: "La sottoscrizione del presente mandato comporta (A) l’autorizzazione a %companyName% di richiedere alla banca del debitore, l'addebito sul suo conto corrente e (B) l’autorizzazione alla banca del debitore di procedere a tale addebito conformemente alle disposizioni impartite da %companyName%",
  sepaGuaranteeLine2: "Il debitore ha diritto di ottenere il rimborso dalla propria Banca secondo gli accordi ed alle condizioni che regolano il rapporto con quest’ultima. ",
  sepaGuaranteeLine3: "Se del caso, il rimborso deve essere richiesto nel termine di 8 settimane a decorrere dalla data di addebito in conto. ",
  sepaGuaranteeLine4: "Tipo di pagamento: Ricorrente ",
  sepaGuaranteeLine5: "Tipo di pagamento: ricorrente (può essere utilizzato più di una volta)",
  sepaTitle: "Mandato per addebito diretto SEPA. Riferimento del mandato (da indicare a cura del creditore)\n",
  settingUpYourDirectDebit: "Impostazione dell'addebito diretto",
  settingUpYourMonthlyPayment: "Imposta il tuo pagamento mensile",
  shareOnFacebook: "Condividi su Facebook",
  shareOnTwitter: "Condividi su Twitter",
  signMeUp: "Iscriviti",
  signUpForAGradeCoaching: "Iscriviti a una lezione individuale di prima classe per tutte le età",
  skip: "Skip",
  skipPayment: "Salta il pagamento, desidero pagare alla reception",
  slotsTakingAWhileToLoad: "Trovare le nostre fasce orarie disponibili sta richiedendo un po’ di tempo. Continueremo a provarci, ma nel frattempo puoi contattarci direttamente ai seguenti indirizzi",
  snrgyFamilyFitness: "Family fitness Synrgy",
  socialMedia: "Social Media",
  softPlay: "Gioco soft",
  somethingHasGoneWrong: "Qualcosa è andato storto",
  sorryIeNotSupported: "Ci dispiace, Internet Explorer non è supportato",
  sorrySomethingHasGoneWrongPleaseTryAgain: "Scusa, qualcosa è andato storto. Riprova.",
  sorrySomethingWentWrong: "Scusa, qualcosa è andato storto.",
  sorryThereWasAProblemWithYourPayment: "Scusa, c’è stato un problema con il tuo pagamento",
  sorryWereHavingTechnicalProblems: "Scusa, abbiamo dei problemi tecnici",
  sortBy: "Sort by:",
  sortByMyLocation: "Sort by my location",
  sortCode: "Richiedi codice",
  spa: "Spa",
  specialHours: "Orari speciali",
  specialOffer: "Offerta speciale",
  specialsAndEvents: "Progetti speciali ed eventi",
  sportsOverview: "Panoramica sport",
  standard: "Standard",
  startDate: "Data di inizio",
  startInNextWeek: "Avvia la tua membership in qualsiasi momento nella prossima settimana",
  startOnFixedDate: "Puoi iniziare la tua iscrizione il",
  stateOfTheArtGymFacilities: "Attrezzature da palestra all'avanguardia",
  studioOpeningHours: "Orari di apertura studio",
  studioOverview: "Panoramica studio",
  submitAndStartMembership: "Invia e inizia a usufruire della membership",
  swimmingAt: "Nuoto al %clubName%",
  swimmingCoaching: "Lezioni individuali di nuoto",
  swimmingPools: "Piscine",
  tailorYourClubWelcome: "Personalizza il tuo benvenuto al club.",
  tailorYourTour: "Personalizza il tuo tour",
  takeOurVideoTour: "Guarda il nostro video per scoprire l’esperienza %brandName%*",
  takeTheNextStep: "Fai la prossima mossa",
  takeTheNextStepDescription: "Scopri come un’iscrizione al %clubPrefix% %clubName% potrebbe trasformare il tempo che trascorri insieme alla tua famiglia.",
  taxId: "Tax ID",
  telephone: "Telefono",
  tennis: "Tennis",
  tennisClub: "Tennis Club",
  tennisCoaching: "Lezioni individuali di tennis",
  termsAndConditions: "Termini e condizioni",
  textMessage: "SMS",
  thankYouForSettingUpDirectDebit: "Grazie per aver scelto l’addebito diretto",
  thankYouForSubscribing: "Grazie per la sottoscrizione",
  thankYouWelcomeToBrand: "Grazie, Benvenuto a %brandName%!",
  theClub: "Il club",
  thereAreCurrentlyNoMembershipPackages: "Spiacenti, al momento non abbiamo alcuna membership disponibile per l’acquisto online. Contatta il tuo club locale per ulteriori informazioni sulle nostre membership.",
  threeMonths: "3 mesi",
  timetable: "Calendario",
  title: "Titolo",
  today: "Oggi",
  tomorrow: "domani",
  totalDue: "Importo totale dovuto",
  totalDueToday: "Importo totale dovuto",
  totalSavedJoiningToday: "Risparmio totale iscrivendoti oggi",
  townOrCity: "Città",
  townOrCityPlaceholder: "Inserire la città",
  treatments: "Day Spa viso e corpo",
  treatmentsOverview: "Panoramica dei trattamenti",
  unableToOfferTrial: "Ci dispiace, per il momento non possiamo offrire una prova",
  unableToTakeClubWelcomeBookings: "Sfortunatamente, al momento non siamo in grado di effettuare prenotazioni online: puoi contattarci tramite i riferimenti qui sotto per prenotare il tuo benvenuto al club.",
  unableToTakeTourBookings: "Purtroppo, in questo momento non possiamo accettare prenotazioni online; puoi contattarci ai seguenti indirizzi per prenotare il tuo tour.",
  unexpectedError: "Errore imprevisto",
  unfortunatelyAnErrorHasOccurred: "Purtroppo, si è verificato un errore",
  unfortunatelyThisTimeIsNoLongerAvailable: "Purtroppo, quest’orario non è più disponibile.",
  unknownMembershipError: "È possibile che la tua iscrizione sia stata registrata con successo. Controlla di aver ricevuto un’e-mail di conferma. Se non ricevi l’e-mail di conferma entro un’ora, prova di nuovo",
  unsubscribe: "Annulla sottoscrizione",
  updateAppPrompt: "Aggiorna l'app alla versione più recente e riprova.",
  upgradeYourMembershipToAddChildren: "Aggiorna la tua membership per aggiungere un bambino",
  upgradeYourMembershipToAddPartner: "Aggiorna la tua membership per aggiungere un partner",
  useMyLocation: "Usa la mia posizione",
  userAlreadyExists: "Questo numero di membership è già in uso",
  userInputOrganisation: "Qual è il nome tua azienda o del tuo gruppo?",
  userVerificationFailed: "Verifica non riuscita",
  usualJoiningFees: "Quote di adesione standard",
  vatNumber: "N. di Partita IVA:",
  viewAllClubs: "Scopri tutti i club",
  viewInMaps: "Apri in Google Maps",
  viewMap: "View a map of all David Lloyd Clubs",
  viewOurFoodMenu: "Visualizza il nostro menu",
  viewTimetable: "Visualizza calendario",
  visitSpaRetreats: "Visit Spa Retreats",
  was: "Era",
  weAlreadyHaveYourDetails: "%firstName%, abbiamo già i tuoi dettagli nel nostro sistema.",
  weDontRecogniseThatLocation: "Mi spiace,",
  weJustNeedAFewMoreDetails: "Ci serve solo qualche altra informazione",
  weSendOurMostExcitingOffers: "Inviamo le nostre offerte più interessanti via e-mail",
  weWereUnableToSaveDetails: "Siamo spiacenti, non è stato possibile salvare i tuoi dati",
  weekend: "Weekend",
  weeklyTimetable: "Calendario settimanale",
  welcomeTo: "Benvenuto a",
  whatToExpect: "Cosa devo aspettarmi?",
  whatsApp: "WhatsApp",
  whereToFindUs: "Dove siamo",
  whoCanCome: "Chi può venire con me?",
  whosComing: "Chi è dei nostri?",
  workEmailAddress: "Indirizzo mail del lavoro",
  years: "anni",
  youngChildren: "Bambini piccoli [ bambini da 0-2 anni]",
  yourCardDetails: "Dettagli della tua carta",
  yourChild: "la tua bambino (%range%)",
  yourChildren: "la tua figli (%range%)",
  yourClubWelcomeIsBooked: "%name%, il tuo benvenuto al club è stato prenotato",
  yourDetails: "I tuoi dati personali",
  yourFirstFullMonth: "Il tuo primo mese completo (%month%)",
  yourFirstFullMonthTooltip: "Pagamento che copre il costo della membership fino a quando l'addebito diretto non è stato autorizzato dalla tua banca",
  yourMembership: "Il tuo abbonamento",
  yourMembershipTrial: "La tua iscrizione di prova",
  yourNannies: "Le tue baby-sitter",
  yourNanny: "la tua baby sitter",
  yourOlderChild: "Il tuo bambino più grande (%range%)",
  yourOlderChildren: "Il tuo bambino più grande (%range%)",
  yourPartner: "Il tuo compagno/a",
  yourSavings: "I tuoi risparmi",
  yourSavingsSubtitle: "Ecco cosa puoi risparmiare iscrivendoti oggi",
  yourStartDate: "La tua data di inizio",
  yourTourIsBooked: "%name%, il tuo tour è prenotato",
  yourYoungChild: "il tuo bambino piccolo (%range%)",
  yourYoungChildren: "I tuoi bambini piccoli (%range%)"
};
