import React, { useContext } from 'react';
import { CmsContent } from 'src/content';
import LinkButton from 'src/components/facilities/link-button';
import { ButtonStyle } from 'src/enums/button-style';
import { SiteContext } from 'src/context/site-context';
import { GlobalCmsContent } from 'src/content/global-content';

type EnquireNowButtonProps = {
  buttonType?: string;
  gtmTrackingId: string;
  buttonStyle?: ButtonStyle;
};

const EnquireNowButton = ({ buttonType, gtmTrackingId, buttonStyle }: EnquireNowButtonProps) => {
  const clubCmsData = useContext(CmsContent);
  const globalCmsData = useContext(GlobalCmsContent);
  const { isGlobalPage } = useContext(SiteContext);

  const enquireNowButtonContent = isGlobalPage
    ? { ...globalCmsData.sharedContent.enquireNowButton, goToGlobal: true }
    : clubCmsData.sharedContent.enquireNowButton;

  return (
    <LinkButton
      buttonText={enquireNowButtonContent?.enquireNowButtonText}
      buttonKey="enquireNow"
      buttonLink={enquireNowButtonContent?.enquireNowButtonLink}
      buttonType={buttonType}
      buttonStyle={buttonStyle || ButtonStyle.PRIMARY}
      gtmTrackingId={gtmTrackingId}
      goToGlobal={enquireNowButtonContent?.goToGlobal}
    />
  );
};

export default EnquireNowButton;
