import { LinksLookup } from 'src/translators/links-lookup';

// When modifying an existing entry here, you might need to add an NGINX redirect from the old URL to the new one,
// in spain.template
export const linksLookup: LinksLookup = {
  'book-a-tour': 'reserva-una-visita',
  'club-facilities': 'instal-lacions',
  'club-treatments': 'club-treatments',
  'club-fitness': 'club-fitness',
  'creche': 'ludoteca',
  'exercise-classes': 'exercici-classes',
  'gym': 'gimnas',
  'sports': 'sports',
  'kids-classes': 'nenes-classes',
  'children': 'children',
  'pools': 'piscines',
  'spa': 'spa',
  'tennis': 'tennis',
  'enquire-now': 'informaten-ja',
  'family-and-kids': 'families-i-nens',
  'golf': 'golf',
  'join-now': 'apuntat-ara',
  'membership-trial': 'periode-de-prova',
  'relax-and-socialise': 'relax-i-social',
  'timetable': 'horaris',
  'yoga': 'ioga',
  'blaze': 'blaze',
  'personal-training': 'entrenament-personal',
  'join': 'inscriu-te',
  // If this link is changed, we should also update the links stored in CC
  // E.g. see DLLABS-10292
  'join-online': 'inscriu-te-online',
  'treatments': 'face-body-spa',
  'global-enquire-now': 'informaten-ja',
  'global-enquire-now-campaign-1': 'campaign-1',
  'global-enquire-now-campaign-2': 'campaign-2',
  'global-enquire-now-campaign-3': 'campaign-3',
  'referral': 'referencia',
  'friend': 'amic',
  'memberships': 'tipus-de-quotes',
  'terms-and-conditions': 'termes-i-condicions',
  'policy': 'politica',
  'gyms-in-barcelona': 'gimnasos-a-barcelona',
};
