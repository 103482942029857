import React, { useContext } from 'react';
import { CmsContent } from 'src/content';
import ClubLinkButtonContent, { ButtonDetails } from './club-link-button-content';
import GlobalLinkButtonContent from './global-link-button-content';
import { SiteContext } from 'src/context/site-context';
import { getBuildButtonStyle } from './buttonCss';
import { CSSInterpolation } from '@emotion/serialize';
import DownloadIcon from 'src/components/icons/download';
import Preview from 'src/components/icons/preview';
import { RedesignButtonIcon } from 'src/enums/button-icons';
import Search from 'src/components/icons/search-icon';
import RightArrowIcon from 'src/components/icons/right-arrow-icon';

export const getButtonIcon = (icon: string) => {
  switch (icon) {
    case RedesignButtonIcon.DOWNLOAD:
      return <DownloadIcon svgStyle={{ height: '1rem' }} />;
    case RedesignButtonIcon.EYE:
      return <Preview svgStyle={{ height: '1rem' }} />;
    case RedesignButtonIcon.ARROW:
      return <RightArrowIcon svgStyle={{ height: '1rem' }} />;
    case RedesignButtonIcon.SEARCH:
      return <Search svgStyle={{ height: '1rem' }} />;
    default:
      return null;
  }
};

type LinkButtonProps = ButtonDetails & {
  goToGlobal?: boolean;
  customButtonStyle?: CSSInterpolation;
  className?: string;
  iconView?: string;
  isRedesignButton?: boolean;
  handleClick?: () => void;
};

const LinkButton = (props: LinkButtonProps) => {
  const { styling, isGlobalPage } = useContext(SiteContext);
  const cmsData = useContext(CmsContent);

  const buttonStyle = props?.customButtonStyle || getBuildButtonStyle(props.buttonStyle, styling);
  const buttonEndIcon = props.buttonEndIcon || getButtonIcon(props.iconView);

  return isGlobalPage || props.goToGlobal ? (
    <GlobalLinkButtonContent {...props} buttonCss={buttonStyle} buttonEndIcon={buttonEndIcon} />
  ) : (
    <ClubLinkButtonContent
      {...props}
      buttonCss={buttonStyle}
      features={cmsData.features}
      enabledPages={cmsData.pages}
      buttonEndIcon={buttonEndIcon}
    />
  );
};

export default LinkButton;
