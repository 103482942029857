import React from 'react';
import { IconProps } from 'src/components/icons/icon-types';

const RightArrowIcon = ({ fill, svgStyle }: IconProps) => (
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={svgStyle}
  >
    <path
      d="M7.3125 0.21875C7.4375 0.0625 7.6875 0.0625 7.84375 0.21875L14.375 6.75C14.5312 6.90625 14.5312 7.125 14.375 7.28125L7.84375 13.8125C7.6875 13.9688 7.4375 13.9688 7.3125 13.8125L6.6875 13.2188C6.53125 13.0625 6.53125 12.8125 6.6875 12.6875L11.5312 7.8125H0.875C0.65625 7.8125 0.5 7.65625 0.5 7.4375V6.5625C0.5 6.375 0.65625 6.1875 0.875 6.1875H11.5312L6.6875 1.34375C6.53125 1.21875 6.53125 0.96875 6.6875 0.8125L7.3125 0.21875Z"
      fill={fill}
    />
  </svg>
);

export default RightArrowIcon;
