import { LinksLookup } from 'src/translators/links-lookup';

// When modifying an existing entry here, you might need to add an NGINX redirect from the old URL to the new one,
// in germany.template and meridian.template
export const linksLookup: LinksLookup = {
  'book-a-tour': 'tour-buchen',
  'club-facilities': 'club-einrichtung',
  'club-treatments': 'face-body-spa',
  'club-fitness': 'fitness',
  'creche': 'krippe',
  'exercise-classes': 'fitness-klassen',
  'children': 'children',
  'gym': 'geraetetraining',
  'sports': 'sports',
  'kids-classes': 'kinder-klassen',
  'pools': 'pools',
  'spa': 'wellness',
  'tennis': 'tennis',
  'enquire-now': 'jetzt-anfragen',
  'family-and-kids': 'familie-und-kinder',
  'golf': 'golf',
  'join-now': 'jetzt-mitglied-werden',
  'membership-trial': 'probemitgliedschaft',
  'relax-and-socialise': 'entspannen-und-unterhalten',
  'timetable': 'stundenplan',
  'yoga': 'yoga',
  'blaze': 'blaze',
  'personal-training': 'personal-training',
  'join': 'mitglied-werden',
  // If this link is changed, we should also update the links stored in CC
  // E.g. see DLLABS-10292
  'join-online': 'online-mitglied-werden',
  'treatments': 'face-body-spa',
  'global-enquire-now': 'jetzt-anfragen',
  'global-enquire-now-campaign-1': 'aktion-1',
  'global-enquire-now-campaign-2': 'aktion-2',
  'global-enquire-now-campaign-3': 'aktion-3',
  'referral': 'weiterempfehlung',
  'friend': 'freund',
  'memberships': 'mitgliedschaften',
  'terms-and-conditions': 'geschaftsbedingungen',
  'policy': 'politik',
  'gyms-in-hamburg': 'fitnessstudio-in-hamburg',
};
