import React, { useContext } from 'react';
import { SiteContext } from 'src/context/site-context';
import {
  getDisplayTextForLanguage,
  getFlagIconForLanguage,
  getUrlValueForLanguage,
  Language,
} from 'src/enums/language';
import { navigate } from 'gatsby';
import { updateUrlPreservingQueryParams } from 'src/helpers/query-string-helper';
import {
  BuildType,
  getAllLanguagesForBuildType,
  shouldIncludeLanguageInUrlForBuildType,
} from 'src/enums/build/build-type';
import { trimPath } from 'src/helpers/path-matching-helper';
import { preparePath } from 'src/helpers/navigation-helper';
import RedesignDownChevron from 'src/components/icons/redesign-down-chevron';
import { Styling } from 'src/context/styling';
import { CSSInterpolation } from '@emotion/serialize';
import mq, { mqReducedMotion } from 'src/styling/media-queries';
import { colors, flex, zIndexes } from 'src/styling/constants';
import AnimatedExpandWrapper from 'src/components/common/animated-expand-wrapper';
import { useToggle } from 'src/custom-hooks/use-toggle';

const createComponentStyling = (styling: Styling, isOpen: boolean) =>
  ({
    text: [
      styling.fonts.regular,
      mq({
        fontSize: ['1rem', '0.875rem'],
        lineHeight: ['125%', '160%'],
      }),
    ],
    wrapper: mq({
      position: 'relative',
      color: styling.colors.capeCod,
      borderBottom: ['1px solid', 'none'],
      borderBottomColor: colors.transparentGreenGrey,
    }),
    button: [
      flex.row,
      mq({
        width: '100%',
        gap: '0.5rem',
        backgroundColor: 'unset',
        border: 'unset',
        color: 'inherit',
        padding: ['1rem 0.5rem', '0.5rem'],
        alignItems: 'center',
        justifyContent: ['start', 'center'],
        cursor: 'pointer',
      }),
      {
        '&:hover': {
          textDecoration: 'underline',
          color: styling.colors.camelot,
        },
      },
    ],
    menuButton: mq({
      borderTop: ['1px solid', 'none'],
      borderTopColor: colors.transparentGreenGrey,
    }),
    chevron: [
      flex.row,
      mq({
        marginLeft: ['auto', 0],
        width: ['1.25rem', '0.875rem'],
        rotate: isOpen ? '180deg' : '0',
      }),
      mqReducedMotion({
        transition: ['rotate 0.4s', 'none'],
      }),
    ],
    menu: mq({
      width: '100%',
      padding: ['1rem 0 1rem', '0.75rem 0 0'],
      backgroundColor: styling.colors.cream,
    }),
    selectionText: mq({
      padding: ['0 0.5rem 0.5rem', '0 0.5rem 0.75rem'],
    }),
    selectionWrapper: mq({
      margin: ['0 0.5rem', 0],
    }),
    selectionButton: mq({
      'padding': ['1rem 0.5rem', '0.5rem'],
      '&:nth-child(odd)': {
        backgroundColor: 'rgba(71, 74, 74, 0.05)',
      },
    }),
  }) satisfies Record<string, CSSInterpolation>;

const navigateToPathForLanguage = (language: Language, path: string, buildType: BuildType) => {
  const trimmedPath = trimPath(path);
  const pathWithLanguage = shouldIncludeLanguageInUrlForBuildType(buildType)
    ? `/${getUrlValueForLanguage(language)}/${trimmedPath}/`
    : `/${trimmedPath}/`;

  navigate(updateUrlPreservingQueryParams(preparePath(pathWithLanguage)));
};

type LanguageSelectionProps = {
  text?: string;
};

const LanguageSelection = ({ text }: LanguageSelectionProps) => {
  const { styling, pageLanguages, language: currentLanguage, buildType } = useContext(SiteContext);
  const [menuOpen, setMenuOpen, , hideMenu] = useToggle(false);

  const allLanguagesForBuildType = getAllLanguagesForBuildType(buildType);
  const orderedLanguages = allLanguagesForBuildType
    .map((l) => pageLanguages?.find((p) => p.language === l))
    .filter((p) => !!p);

  if (orderedLanguages.length <= 1) {
    return null;
  }

  const languagesToSelect = orderedLanguages.filter((p) => p.language !== currentLanguage);
  const componentStyling = createComponentStyling(styling, menuOpen);

  return (
    <div css={componentStyling.wrapper}>
      <button
        type="button"
        css={[componentStyling.button, componentStyling.text, componentStyling.menuButton]}
        onClick={() => setMenuOpen(!menuOpen)}
      >
        {getFlagIconForLanguage(currentLanguage, { size: '1.25rem' })}
        <div>{getDisplayTextForLanguage(currentLanguage)}</div>
        <div css={componentStyling.chevron}>
          <RedesignDownChevron size="100%" fill={styling.colors.capeCod} />
        </div>
      </button>
      <AnimatedExpandWrapper
        isExpanded={menuOpen}
        containerStyles={mq({ position: ['initial', 'absolute'], zIndex: zIndexes.popup })}
      >
        <div css={componentStyling.menu}>
          <div css={[componentStyling.text, componentStyling.selectionText]}>{text}</div>
          <div css={componentStyling.selectionWrapper}>
            {languagesToSelect.map(({ language, path }) => (
              <button
                key={language}
                type="button"
                css={[componentStyling.button, componentStyling.selectionButton]}
                onClick={() => {
                  hideMenu();
                  navigateToPathForLanguage(language, path, buildType);
                }}
              >
                {getFlagIconForLanguage(language, { size: '1.25rem' })}
                <div css={componentStyling.text}>{getDisplayTextForLanguage(language)}</div>
              </button>
            ))}
          </div>
        </div>
      </AnimatedExpandWrapper>
    </div>
  );
};

export default LanguageSelection;
