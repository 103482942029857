import { BuildDomain } from '../src/enums/build/build-domain';

type ActiveEnv =
  | 'development'
  | 'test' // 'test' is the environment for local automated tests, not a deployed environment.
  | 'staging'
  | 'uat'
  | 'preprod'
  | 'prod';

export const activeEnv = (process.env.GATSBY_ACTIVE_ENV ||
  process.env.NODE_ENV ||
  'development') as ActiveEnv;

export const isProdEnv = activeEnv === 'prod' || activeEnv === 'preprod';

export const buildDomain = (process.env.BUILD_DOMAIN || BuildDomain.DAVID_LLOYD_UK) as BuildDomain;
