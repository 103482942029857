import { BuildType } from 'src/enums/build/build-type';
import { documentIsDefined } from 'src/helpers/document-helper';
import { userHasConsentedToCookieCategoryWithKey } from './consent-cookie';

export type Cookie = {
  key: string;
  getValue: () => string | undefined;
  isSet: () => boolean;
  trySetForSessionAtBasePath: (value: string, buildType: BuildType) => void;
  trySetForSessionAtCurrentPath: (value: string, buildType: BuildType) => void;
};

export const canSetCookie = (cookieCategory: string, buildType: BuildType): boolean =>
  documentIsDefined() && userHasConsentedToCookieCategoryWithKey(cookieCategory, buildType);

export const getCookieWithKey = (key: string, cookieCategory: string): Cookie => ({
  key: key,
  getValue: () => getCookieValueForKey(key),
  isSet: () => isCookieSetForKey(key),
  trySetForSessionAtBasePath: (value, buildType) =>
    trySetCookieForSession(key, value, cookieCategory, buildType, '/'),
  trySetForSessionAtCurrentPath: (value, buildType) =>
    trySetCookieForSession(key, value, cookieCategory, buildType),
});

const getCookieValueForKey = (key: string): string | undefined => {
  if (documentIsDefined()) {
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + key + '=');
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
  }
};

const isCookieSetForKey = (key: string): boolean => !!getCookieValueForKey(key);

const trySetCookieForSession = (
  key: string,
  value: string,
  cookieCategory: string,
  buildType: BuildType,
  path?: string
): void => {
  const separator = ';';
  const pathIfSet = path ? `${separator}path=${path}` : '';
  if (canSetCookie(cookieCategory, buildType)) {
    document.cookie = `${key}=${value}${pathIfSet}`;
  }
};
