import React, { useCallback } from 'react';
import useSafeState from 'src/custom-hooks/use-safe-state';

export const useNullableToggle = (
  initialState: boolean | null,
  shouldUseSafeState?: boolean
): [boolean | null, (newState: boolean | null) => void, () => void, () => void] => {
  const [safeState, setSafeState] = useSafeState<boolean | null>(initialState);
  const [reactState, setReactState] = React.useState<boolean | null>(initialState);

  const [state, setState] = shouldUseSafeState
    ? [safeState, setSafeState]
    : [reactState, setReactState];

  const toggleOn = useCallback(() => setState(true), [setState]);
  const toggleOff = useCallback(() => setState(false), [setState]);

  return [state, setState, toggleOn, toggleOff];
};

export const useToggle = (
  initialState: boolean,
  safeState?: boolean
): [boolean, (newState: boolean) => void, () => void, () => void] => {
  return useNullableToggle(initialState, safeState);
};
