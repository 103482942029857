import moment from 'moment';
import { PromotionContent } from 'src/models/cms/promotions-content';

export function getTimedPromotionForClub(siteId: string, timedPromotions: PromotionContent[]) {
  const now = moment();

  return timedPromotions.find((timedPromotion) => {
    const isPromotionForCurrentClub = timedPromotion.siteIds.includes(siteId);
    const isActive =
      moment(timedPromotion.promotionDetails.to).isAfter(now) &&
      moment(timedPromotion.promotionDetails.from).isBefore(now);

    return isActive && isPromotionForCurrentClub;
  });
}

export function getTimedPromotionTypeForClub(siteId: string, timedPromotions: PromotionContent[]) {
  const timedPromoActiveForCurrentClub = getTimedPromotionForClub(siteId, timedPromotions);

  const useTimedLandingPromo =
    timedPromoActiveForCurrentClub?.promotionDetails?.landing?.applyPromotionContent;

  const useTimedLandingPromoCampaign =
    timedPromoActiveForCurrentClub?.promotionDetails?.landing?.applyPromotionContent;

  const useTimedEnquirePromo =
    timedPromoActiveForCurrentClub?.promotionDetails?.enquire?.applyPromotionContent;

  const useTimedPromoPopup =
    timedPromoActiveForCurrentClub?.promotionDetails?.popup?.applyPromotionContent;

  return {
    timedPromoActiveForCurrentClub,
    useTimedLandingPromo,
    useTimedLandingPromoCampaign,
    useTimedEnquirePromo,
    useTimedPromoPopup,
  };
}

export function globalBannerIsActive(siteId: string, timedPromotions: PromotionContent[]) {
  const timedPromotion = getTimedPromotionForClub(siteId, timedPromotions);

  return timedPromotion && timedPromotion.promotionDetails.globalBanner.showBanner;
}
