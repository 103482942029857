import React, { useContext, useEffect, useRef, useState } from 'react';
import { BreadcrumbList, ListItem, WithContext } from 'schema-dts';
import { SiteContext } from 'src/context/site-context';
import { GlobalCmsContent } from 'src/content/global-content';
import { GlobalCmsRecord } from 'src/content/cms-provider/global-cms-provider';
import { getSiteUrlForPath, isGlobalLinkCurrentPage } from 'src/helpers/navigation-helper';
import { Language } from 'src/enums/language';
import { BuildType } from 'src/enums/build/build-type';
import { WebsiteLink } from 'src/components/common/website-link';
import mq from 'src/styling/media-queries';
import { Styling } from 'src/context/styling';
import { flex, STACKED_STICKY_ITEM_HEIGHT_REDUCTION, zIndexes } from 'src/styling/constants';
import RedesignRightChevronThin from 'src/components/icons/redesign-right-chevron-thin';
import { STRUCTURED_DATA_CONTEXT, StructuredDataObject } from 'src/components/seo/structured-data';
import { REDESIGN_HEADER_HEIGHT_CSS_VARIABLE } from 'src/components/layout/redesign/header-desktop';

type BreadcrumbTrail = {
  pageName: string;
  pageUrl: string;
  previousPages: {
    name: string;
    url: string;
  }[];
};

export const REDESIGN_BREADCRUMBS_HEIGHT_CSS_VARIABLE = 'redesign-breadcrumbs-height';

const reduceMenuItemToBreadcrumbTrails = (
  menuItem:
    | Queries.GlobalHeaderLevelOneMenuItem
    | Queries.GlobalHeaderLevelTwoMenuItem
    | Queries.GlobalHeaderLevelThreeMenuItem,
  parentStructure: BreadcrumbTrail
): BreadcrumbTrail[] => {
  const currentPageTrail: BreadcrumbTrail = {
    previousPages: parentStructure
      ? [
          ...parentStructure.previousPages,
          {
            name: parentStructure.pageName,
            url: parentStructure.pageUrl,
          },
        ]
      : [],
    pageName: menuItem.name,
    pageUrl: menuItem.link,
  };

  const childItems =
    (menuItem as Queries.GlobalHeaderLevelOneMenuItem).levelTwoMenuItems ||
    (menuItem as Queries.GlobalHeaderLevelTwoMenuItem).levelThreeMenuItems ||
    [];

  const childBreadcrumbTrails = childItems.flatMap((childItem) =>
    reduceMenuItemToBreadcrumbTrails(childItem, currentPageTrail)
  );

  return [currentPageTrail, ...childBreadcrumbTrails];
};

const mapHeaderContentToBreadcrumbTrails = (
  headerContent: GlobalCmsRecord['headerContent'],
  homePageName: string
): BreadcrumbTrail[] => {
  if (!headerContent) {
    return [];
  }

  const basePageStructure: BreadcrumbTrail = {
    previousPages: [],
    pageName: homePageName,
    pageUrl: '/',
  };

  const headerMenuBreadcrumbTrails = (headerContent.headerMenuItems ?? []).flatMap(
    (levelOneMenuItem) => reduceMenuItemToBreadcrumbTrails(levelOneMenuItem, basePageStructure)
  );

  const secondaryNavBreadcrumbTrails: BreadcrumbTrail[] =
    headerContent.headerSecondaryUpperMenu.map((value) => ({
      previousPages: [
        {
          name: homePageName,
          url: '/',
        },
      ],
      pageName: value.text,
      pageUrl: value.link,
    }));

  return [...headerMenuBreadcrumbTrails, ...secondaryNavBreadcrumbTrails];
};

const calculateCurrentBreadcrumbTrail = (
  homePageName: string,
  override: Queries.BreadcrumbsOverride,
  headerContent: GlobalCmsRecord['headerContent'],
  language: Language,
  buildType: BuildType,
  basePath: string
): BreadcrumbTrail | null => {
  if (override?.useOverride) {
    return {
      previousPages: (override.previousPages ?? []).map((p) => ({ ...p })),
      pageName: override.currentPageName ?? '',
      pageUrl: '',
    };
  }

  const headerBreadcrumbTrails = mapHeaderContentToBreadcrumbTrails(headerContent, homePageName);

  return (
    headerBreadcrumbTrails.find((bt) =>
      isGlobalLinkCurrentPage(bt.pageUrl, language, buildType, basePath)
    ) ?? null
  );
};

const createStructuredData = (
  breadcrumbsTrail: BreadcrumbTrail,
  buildType: BuildType
): WithContext<BreadcrumbList> => {
  if (!breadcrumbsTrail || breadcrumbsTrail.previousPages.length === 0) {
    return null;
  }

  const breadcrumbList: ListItem[] = [
    ...breadcrumbsTrail.previousPages.map(
      (page, index) =>
        ({
          '@type': 'ListItem',
          'position': index + 1,
          'name': page.name,
          'item': getSiteUrlForPath(buildType, page.url),
        }) satisfies ListItem
    ),
    {
      '@type': 'ListItem',
      'position': breadcrumbsTrail.previousPages.length + 1,
      'name': breadcrumbsTrail.pageName,
    },
  ];

  return {
    '@context': STRUCTURED_DATA_CONTEXT,
    '@type': 'BreadcrumbList',
    'itemListElement': breadcrumbList,
  };
};

const createComponentStyling = (styling: Styling, isFooterVisible: boolean) => ({
  container: mq([
    flex.row,
    {
      width: '100%',
      color: styling.colors.capeCod,
      backgroundColor: styling.colors.cream,
      justifyContent: 'center',
      position: isFooterVisible ? 'fixed' : 'sticky',
      top: `var(--${REDESIGN_HEADER_HEIGHT_CSS_VARIABLE})`,
      zIndex: zIndexes.breadcrumb,
    },
  ]),
  linksWrapper: [
    flex.row,
    mq.withSmallTablet({
      width: '100%',
      maxWidth: '92.25rem',
      padding: ['0.5rem 1.25rem', '0.5rem 2.5rem', '0.75rem 3.75rem'],
      columnGap: '0.5rem',
      alignItems: 'center',
      flexWrap: 'wrap',
    }),
  ],
  text: [
    styling.fonts.regular,
    {
      fontSize: '0.875rem',
      lineHeight: '160%',
      margin: '0.5rem',
    },
  ],
  link: {
    'borderBottom': '1px solid',
    'borderColor': 'transparent',
    '&:hover': {
      borderColor: 'currentcolor',
      color: styling.colors.camelot,
    },
  },
  currentPageText: [
    {
      fontWeight: 600,
      borderBottom: '1px solid transparent',
    },
  ],
});

type BreadcrumbsProps = Queries.Breadcrumbs;

const Breadcrumbs = ({ enabled, homeText, override }: BreadcrumbsProps) => {
  const { styling, language, buildType, basePath } = useContext(SiteContext);
  const { headerContent } = useContext(GlobalCmsContent);
  const [isFooterVisible, setIsFooterVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const currentPageBreadcrumbs = calculateCurrentBreadcrumbTrail(
    homeText,
    override,
    headerContent,
    language,
    buildType,
    basePath
  );

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    const updateBreadcrumbsHeightVariable = () => {
      const doc = document.documentElement;
      const containerHeight = containerRef.current?.getBoundingClientRect().height;

      if (!containerHeight) {
        return;
      }
      doc.style.setProperty(
        `--${REDESIGN_BREADCRUMBS_HEIGHT_CSS_VARIABLE}`,
        `${containerHeight - STACKED_STICKY_ITEM_HEIGHT_REDUCTION}px`
      );
    };

    const resizeObserver = new ResizeObserver(() => {
      requestAnimationFrame(updateBreadcrumbsHeightVariable);
    });

    resizeObserver.observe(containerRef.current);
    updateBreadcrumbsHeightVariable();

    const footer = document.getElementById('footer');

    if (!footer) {
      return () => {
        document.documentElement.style.removeProperty(
          `--${REDESIGN_BREADCRUMBS_HEIGHT_CSS_VARIABLE}`
        );
        resizeObserver.disconnect();
      };
    }

    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        setIsFooterVisible(entry.isIntersecting);
      },
      {
        root: null,
        threshold: 0.1,
      }
    );

    observer.observe(footer);

    return () => {
      document.documentElement.style.removeProperty(
        `--${REDESIGN_BREADCRUMBS_HEIGHT_CSS_VARIABLE}`
      );
      resizeObserver.disconnect();
      observer.disconnect();
    };
  }, []);

  if (!enabled || !currentPageBreadcrumbs) {
    return null;
  }

  const componentStyling = createComponentStyling(styling, isFooterVisible);
  const structuredData = createStructuredData(currentPageBreadcrumbs, buildType);

  return (
    <div css={componentStyling.container} ref={containerRef}>
      <StructuredDataObject structuredData={structuredData} />
      <div css={componentStyling.linksWrapper}>
        {currentPageBreadcrumbs.previousPages.map(({ name, url }) => (
          <React.Fragment key={name}>
            <WebsiteLink css={[componentStyling.text, componentStyling.link]} to={url}>
              {name}
            </WebsiteLink>
            <RedesignRightChevronThin size="0.375rem" fill={styling.colors.capeCod} />
          </React.Fragment>
        ))}
        <div css={[componentStyling.text, componentStyling.currentPageText]}>
          {currentPageBreadcrumbs.pageName}
        </div>
      </div>
    </div>
  );
};

export default Breadcrumbs;
