import { LinksLookup } from 'src/translators/links-lookup';

// When modifying an existing entry here, you might need to add an NGINX redirect from the old URL to the new one,
// in italy.template
export const linksLookup: LinksLookup = {
  'book-a-tour': 'prenota-un-tour',
  'club-facilities': 'strutture-club',
  'club-treatments': 'club-treatments',
  'club-fitness': 'club-fitness',
  'creche': 'nido',
  'exercise-classes': 'corsi',
  'gym': 'palestra',
  'sports': 'sports',
  'kids-classes': 'corsi-per-bambini',
  'children': 'children',
  'pools': 'piscine',
  'spa': 'spa',
  'tennis': 'tennis',
  'enquire-now': 'contattaci-subito',
  'family-and-kids': 'famiglia-e-bambini',
  'golf': 'golf',
  'join-now': 'iscriviti-subito',
  'membership-trial': 'iscrizione-di-prova',
  'relax-and-socialise': 'rilassati-e-socializza',
  'timetable': 'calendario',
  'yoga': 'yoga',
  'blaze': 'blaze',
  'personal-training': 'personal-training',
  'join': 'iscriviti',
  // If this link is changed, we should also update the links stored in CC
  // E.g. see DLLABS-10292
  'join-online': 'iscriviti-online',
  'treatments': 'face-body-spa',
  'global-enquire-now': 'contattaci-subito',
  'global-enquire-now-campaign-1': 'campaign-1',
  'global-enquire-now-campaign-2': 'campaign-2',
  'global-enquire-now-campaign-3': 'campaign-3',
  'referral': 'raccomandazione',
  'friend': 'amico',
  'memberships': 'iscrizione',
  'terms-and-conditions': 'termini-e-condizioni',
  'policy': 'politica',
};
