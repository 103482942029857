import queryString, { ParsedUrl } from 'query-string';

export function getQueryStringValue(key: string): string {
  if (typeof window !== 'undefined') {
    const queryParams = queryString.parse(window.location.search);
    const queryParam = queryParams[key];
    if (Array.isArray(queryParam)) {
      return queryParam.length > 0 ? queryParam[0] : '';
    }
    return queryParam;
  }
}
export function getQueryStringValueAsBoolean(key: string): boolean {
  const queryStringValue = getQueryStringValue(key);

  return queryStringValue === 'true';
}

export function getUrlWithNewQueryStringValue(key: string, value?: string): string {
  if (typeof window !== 'undefined') {
    const url: ParsedUrl = queryString.parseUrl(window.location.href);
    if (value) {
      url.query[key] = value;
    }
    return queryString.stringifyUrl(url);
  }
}

export function updateUrlPreservingQueryParams(
  targetPathName: string,
  options?: { excludedQueryParams?: string[] }
): string {
  if (typeof window !== 'undefined') {
    const url = targetPathName + window.location.search;

    return options?.excludedQueryParams != null
      ? queryString.exclude(url, options.excludedQueryParams)
      : url;
  }
}
