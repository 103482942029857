import React, { useContext, useEffect } from 'react';
import 'src/styling/global.css';
import { shadow, zIndexes } from 'src/styling/constants';
import { Styling } from 'src/context/styling';
import { CSSInterpolation } from '@emotion/serialize';
import { COOKIE_CONSENT_DISMISSED_COOKIE } from 'src/cookies/cookie-consent-dismissed-cookie';
import { useToggle } from 'src/custom-hooks/use-toggle';
import { SiteContext } from 'src/context/site-context';
import { CloseButton } from 'src/components/layout/cookie-consent/close-button';
import { Title } from 'src/components/layout/cookie-consent/title';
import { Body } from 'src/components/layout/cookie-consent/body';

const wrapperStyling = (styling: Styling): CSSInterpolation => [
  shadow.around,
  styling.fonts.light,
  {
    bottom: 0,
    zIndex: zIndexes.cookies,
    width: '100%',
    backgroundColor: styling.colors.background,
    position: 'fixed',
    padding: '2rem',
    fontSize: ['1rem', '1.25rem'],
    lineHeight: 1.5,
    color: styling.colors.bodyText,
  },
];

const CookieConsentPopup: React.FC = () => {
  const { styling, buildType } = useContext(SiteContext);
  const [showingCookiePolicy, setShowingCookiePolicy] = useToggle(false);

  useEffect(
    function initialiseClientSide() {
      setShowingCookiePolicy(!COOKIE_CONSENT_DISMISSED_COOKIE.isSet());
    },
    [setShowingCookiePolicy]
  );

  if (!showingCookiePolicy) {
    return null;
  }

  const handleCloseButtonClick = () => {
    COOKIE_CONSENT_DISMISSED_COOKIE.trySetForSessionAtBasePath('yes', buildType);
    setShowingCookiePolicy(false);
  };

  return (
    <div css={wrapperStyling(styling)}>
      <CloseButton styling={styling} handleCloseButtonClick={handleCloseButtonClick} />
      <Title styling={styling} />
      <Body styling={styling} />
    </div>
  );
};

export default CookieConsentPopup;
