import { BuildType, getLinkedBuildTypesForBuildType } from '../enums/build/build-type';
import {
  shouldIncludeInClubFinder,
  shouldIncludeInLists,
  shouldIncludeInOnlineJoiningLists,
} from '../enums/build/prod-build-state';
import {
  BuildDomain,
  getBuildDomainForBuildType,
  getBuildTypesForBuildDomain,
} from '../enums/build/build-domain';
import { sortClubsInDisplayOrderForBuildType } from '../components/common/club-sorter';
import { ClubInformation, CLUBS_INFORMATION } from '../enums/club-information';

let clubInformationBySiteId: Map<string, ClubInformation> = null;
export const getClubInformation = (siteId: string) => {
  if (clubInformationBySiteId === null) {
    clubInformationBySiteId = new Map(CLUBS_INFORMATION.map((c) => [c.siteId, c]));
  }
  return clubInformationBySiteId.get(siteId);
};

export const getClubByHyphenatedClubName = (hyphenatedClubName: string): ClubInformation =>
  CLUBS_INFORMATION.find(
    (clubInformation) => clubInformation.hyphenatedClubName === hyphenatedClubName
  )!;

export const getFullClubs = (): ClubInformation[] =>
  CLUBS_INFORMATION.filter((clubInformation) =>
    shouldIncludeInLists(clubInformation.prodBuildState)
  );

export const getFullClubsForBuildType = (buildType: BuildType): ClubInformation[] =>
  CLUBS_INFORMATION.filter((clubInformation) => clubInformation.buildType === buildType).filter(
    (clubInformation) => shouldIncludeInLists(clubInformation.prodBuildState)
  );

const getClubsOpenForOnlineJoiningForBuildType = (buildType: BuildType): ClubInformation[] =>
  CLUBS_INFORMATION.filter((clubInformation) => clubInformation.buildType === buildType).filter(
    (clubInformation) => shouldIncludeInOnlineJoiningLists(clubInformation.prodBuildState)
  );

export const getFullClubsForClubFinder = (buildTypes: BuildType[]): ClubInformation[] => {
  return CLUBS_INFORMATION.filter((clubInformation) =>
    buildTypes.includes(clubInformation.buildType)
  ).filter((clubInformation) => shouldIncludeInClubFinder(clubInformation.prodBuildState));
};

export const getClubsWithSiteIdIn = (siteIds: number[]): ClubInformation[] => {
  const siteIdsAsStrings = siteIds.map((siteId) => siteId.toString());
  return getFullClubs().filter((club) => club && siteIdsAsStrings.includes(club.siteId));
};

const fullClubsInDisplayOrderForBuildType = new Map<BuildType, ClubInformation[] | null>();
export const getFullClubsInDisplayOrderForBuildType = (buildType: BuildType): ClubInformation[] => {
  if (!fullClubsInDisplayOrderForBuildType.has(buildType)) {
    fullClubsInDisplayOrderForBuildType.set(
      buildType,
      sortClubsInDisplayOrderForBuildType(getFullClubsForBuildType(buildType), buildType)
    );
  }
  return fullClubsInDisplayOrderForBuildType.get(buildType);
};

export const getSiteIdForApi = (siteId: string): number => {
  const parsedSiteId = parseInt(siteId);
  if (isNaN(parsedSiteId)) {
    throw new Error('Attempted to parse a site ID that is not a number.');
  }
  return parsedSiteId;
};

export const getClubsLinkedToBuildType = (buildType: BuildType): ClubInformation[] => {
  const buildTypes = [buildType].concat(getLinkedBuildTypesForBuildType(buildType));

  return buildTypes.flatMap((type) => getFullClubsInDisplayOrderForBuildType(type));
};

export const getClubsOpenForOnlineJoiningLinkedToBuildType = (
  buildType: BuildType
): ClubInformation[] => {
  const buildTypes = [buildType].concat(getLinkedBuildTypesForBuildType(buildType));
  return buildTypes.flatMap((type) =>
    sortClubsInDisplayOrderForBuildType(getClubsOpenForOnlineJoiningForBuildType(type), buildType)
  );
};

export const getClubFinderBuildTypesForBuildDomain = (buildDomain: BuildDomain): BuildType[] => {
  const baseBuildTypes = getBuildTypesForBuildDomain(buildDomain);

  if (buildDomain === BuildDomain.DAVID_LLOYD_UK) {
    return [...baseBuildTypes, BuildType.HARBOUR];
  }
  return baseBuildTypes;
};

export const getClubsForClubFinder = (buildType: BuildType): ClubInformation[] => {
  const buildDomain = getBuildDomainForBuildType(buildType);
  const buildTypes = getClubFinderBuildTypesForBuildDomain(buildDomain);
  return getFullClubsForClubFinder(buildTypes);
};
