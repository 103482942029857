export enum BackgroundTheme {
  LIGHT = 'light',
  GREY = 'grey',
}

export enum TextBackgroundTheme {
  LIGHT = 'light',
  GREY = 'grey',
  MIXED = 'mixed',
}
