import React, { PropsWithChildren, useContext } from 'react';
import { Location } from '@gatsbyjs/reach-router';
import Header from './header';
import MobileNav from './mobile-nav';
import Snippets, { SnippetsProps } from './snippets';
import { flex } from 'src/styling/constants';
import mq from 'src/styling/media-queries';
import { SiteContext } from 'src/context/site-context';
import { useToggle } from 'src/custom-hooks/use-toggle';
import Footer from './footer/footer';
import CookieConsentPopup from 'src/components/layout/cookie-consent';
import { cookieConsentIsNecessaryForBuildType } from 'src/helpers/cookie-helper';
import FooterRedesign from 'src/components/layout/footer/redesign';
import HeaderRedesignMobile from 'src/components/layout/redesign/header-mobile';
import HeaderRedesignDesktop from 'src/components/layout/redesign/header-desktop';
import { useDocumentDesktopOnlyScroll } from 'src/components/layout/redesign/header-shared/use-document-desktop-only-scroll';
import { getBuildDomainForBuildType, newWebsiteBuildDomains } from 'src/enums/build/build-domain';
import { GlobalCmsContent } from 'src/content/global-content';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import 'src/styling/global.css';

type GlobalPageLayoutContentProps = PropsWithChildren<{
  snippets?: SnippetsProps;
  shouldShowFooter: boolean;
}>;

const GlobalPageLayoutContent: React.FC<GlobalPageLayoutContentProps> = ({
  children,
  snippets,
  shouldShowFooter,
}) => {
  const { newFooterContent } = useContext(GlobalCmsContent);
  const { styling, buildType } = useContext(SiteContext);
  const setDocumentDesktopOnlyScroll = useDocumentDesktopOnlyScroll();

  const buildDomain = getBuildDomainForBuildType(buildType);
  const useNewHeader = newWebsiteBuildDomains.includes(buildDomain);

  const [oldMobileNavigationOpened, setOldMobileNavigationOpened, , hideOldMobileNavigation] =
    useToggle(false);

  return (
    <Location>
      {({ location }) => (
        <>
          <div css={[flex.columnCentered, { position: 'relative' }]}>
            {useNewHeader ? (
              <>
                <HeaderRedesignMobile setMenuOpen={setDocumentDesktopOnlyScroll} />
                <HeaderRedesignDesktop location={location} />
              </>
            ) : (
              <>
                <Header
                  toggleMobileNav={() => setOldMobileNavigationOpened(!oldMobileNavigationOpened)}
                  showingMobileNav={oldMobileNavigationOpened}
                />
                <div className="spacer" style={{ paddingBottom: '4.5rem' }} />
                <div
                  className="spacer-nav"
                  css={mq.withSmallTablet({
                    paddingBottom: '3.5rem',
                    display: ['none', 'none', 'inline'],
                  })}
                />
                <MobileNav
                  closeNav={() => hideOldMobileNavigation()}
                  mobileNavigationOpened={oldMobileNavigationOpened}
                  bannerIsActive={false}
                />
              </>
            )}
            <div
              className="page-scroll"
              css={{
                backgroundColor: styling.colors.secondaryBackground,
                width: '100%',
              }}
            >
              {children}
              {shouldShowFooter &&
                (newFooterContent.useNewFooter ? (
                  <FooterRedesign />
                ) : (
                  <div
                    css={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      backgroundColor: styling.colors.headerBackground,
                    }}
                  >
                    <Footer />
                  </div>
                ))}
            </div>
          </div>
          <Snippets {...snippets} buildType={buildType} />
          {cookieConsentIsNecessaryForBuildType(buildType) && <CookieConsentPopup />}
        </>
      )}
    </Location>
  );
};

export default GlobalPageLayoutContent;
