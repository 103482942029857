import { BuildType } from '../../enums/build/build-type';
import { Colors, getColors } from './colors';
import { Buttons, getButtons, getRedesignButtons } from './button';
import { Fonts, getFonts } from './fonts';
import { CommonStyle, getCommon } from './common';
import { Logos, getLogos } from './logos';
import { Forms, getForms, getRedesignedForms } from './forms';
import { getTextStyles, TextStyles } from './text-styles';

export type Styling = {
  colors: Colors;
  common: CommonStyle;
  logos: Logos;
  buttons: Buttons;
  redesignButtons: Partial<Buttons>;
  forms: Forms;
  redesignForms: Partial<Forms>;
  fonts: Fonts;
  texts: TextStyles;
};

const getBuildTypeButtons = (buildType: BuildType, colors: Colors, fonts: Fonts) => {
  switch (buildType) {
    case BuildType.HARBOUR:
    case BuildType.MERIDIAN:
      return getButtons(buildType, colors, fonts);
    default:
      return getRedesignButtons(colors, fonts);
  }
};

export const getStyling = (buildType: BuildType) => {
  const colors = getColors(buildType);
  const fonts = getFonts(buildType);
  const common = getCommon(buildType, colors, fonts);
  const logos = getLogos(buildType);
  const buttons = getBuildTypeButtons(buildType, colors, fonts);
  const redesignButtons = getRedesignButtons(colors, fonts);
  const forms = getForms(buildType, colors, fonts);
  const redesignForms = getRedesignedForms(colors);
  const texts = getTextStyles(buildType);

  return {
    colors,
    common,
    logos,
    buttons,
    forms,
    redesignForms,
    fonts,
    texts,
    redesignButtons,
  } as Styling;
};

export const defaultStyling = getStyling(BuildType.DAVID_LLOYD_UK);
