import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { GlobalCmsContent } from 'src/content/global-content';
import { WebsiteLink } from 'src/components/common/website-link';
import { flex, STACKED_STICKY_ITEM_HEIGHT_REDUCTION, zIndexes } from 'src/styling/constants';
import { SiteContext } from 'src/context/site-context';
import { ButtonStyle, RedesignButtonStyle } from 'src/enums/button-style';
import { getNewButtonCss } from 'src/components/facilities/buttonCss';
import LinkButton from 'src/components/facilities/link-button';
import { Styling } from 'src/context/styling';
import { CSSInterpolation } from '@emotion/serialize';
import DavidLloydLogo from 'src/components/icons/david-lloyd-logo';
import MenuIcon from 'src/components/icons/menu-icon';
import HeaderMenu from 'src/components/layout/redesign/header-mobile/header-menu';
import { getBackgroundColorByTheme } from 'src/helpers/styling';
import { BackgroundTheme } from 'src/enums/background-theme';
import RedesignClose from 'src/components/icons/redesign-close';
import RedesignLeftChevronThin from 'src/components/icons/redesign-left-chevron-thin';
import PromoRibbon from 'src/components/layout/redesign/header-shared/promo-ribbon';
import {
  mqHeaderDisplay,
  REDESIGN_HEADER_HEIGHT_CSS_VARIABLE,
} from 'src/components/layout/redesign/header-desktop';
import { amplitudeTrackEvent } from 'src/helpers/amplitude-event-track';
import { REDESIGN_BREADCRUMBS_HEIGHT_CSS_VARIABLE } from 'src/components/common/breadcrumbs';

// We add an extra pixel padding above the header to handle cases where the browser renderer causes an extra
// pixel spacing above the header to appear
const ADDITIONAL_TOP_PADDING_PX = 1;

const createComponentStyling = (styling: Styling) =>
  ({
    container: [
      mqHeaderDisplay({
        width: '100%',
        display: ['flex', 'none'],
        position: 'sticky',
        top: `-${ADDITIONAL_TOP_PADDING_PX}px`,
        paddingTop: `${ADDITIONAL_TOP_PADDING_PX}px`,
        marginTop: `-${ADDITIONAL_TOP_PADDING_PX}px`,
        justifyContent: 'center',
        zIndex: zIndexes.header,
      }),
      {
        '& .header-sub-menu': {
          backgroundColor: getBackgroundColorByTheme(BackgroundTheme.LIGHT),
        },
      },
    ],
    header: {
      backgroundColor: getBackgroundColorByTheme(BackgroundTheme.LIGHT),
      width: '100%',
      zIndex: 1,
    },
    headerContent: {
      width: '100%',
      padding: '1rem 0.5rem',
      alignItems: 'center',
      gap: '0.5rem',
      borderBottom: '1px solid',
      borderBottomColor: styling.colors.transparentGrayOlive,
    },
    menuButtons: {
      flex: '0 1 33%',
      gap: '0.5rem',
    },
    openButton: {
      padding: '0.625rem',
    },
    menuButton: [
      styling.fonts.regular,
      flex.row,
      {
        background: 'unset',
        border: 'unset',
        padding: '1rem 0.5rem',
        cursor: 'pointer',
        fontSize: '1rem',
        lineHeight: '1.25rem',
        gap: '0.75rem',
        alignItems: 'center',
        color: styling.colors.capeCod,
      },
    ],
    logo: {
      flex: '0 1 33%',
      padding: '0.5rem 0',
      display: 'flex',
      justifyContent: 'center',
    },
    buttonWrapper: {
      flex: '0 1 33%',
      display: 'flex',
      justifyContent: 'flex-end',
      margin: '-1rem 0',
    },
  }) satisfies Record<string, CSSInterpolation>;

type HeaderMobileProps = {
  setMenuOpen?: (isOpen: boolean) => void;
};

const HeaderMobile = ({ setMenuOpen }: HeaderMobileProps) => {
  const { headerContent } = useContext(GlobalCmsContent);
  const { styling, pageLevel } = useContext(SiteContext);

  const componentStyling = createComponentStyling(styling);
  const topSectionRef = useRef<HTMLDivElement>(null);
  const headerBarRef = useRef<HTMLDivElement>(null);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isClubSearchOpen, setIsClubSearchOpen] = useState(false);
  const [selectedLevel1Index, setSelectedLevel1Index] = useState<number | null>(0);
  const [selectedLevel2Index, setSelectedLevel2Index] = useState<number | null>(null);

  const [headerBarHeight, setHeaderBarHeight] = useState(94);

  useLayoutEffect(() => {
    if (!topSectionRef.current) {
      return;
    }

    document.documentElement.style.setProperty(
      'scroll-padding-top',
      `calc(var(--${REDESIGN_HEADER_HEIGHT_CSS_VARIABLE}) + var(--${REDESIGN_BREADCRUMBS_HEIGHT_CSS_VARIABLE}, 0px))`
    );

    const updateHeaderHeights = () => {
      const doc = document.documentElement;
      const containerHeight = topSectionRef.current?.getBoundingClientRect().height;

      if (headerBarRef.current) {
        setHeaderBarHeight(headerBarRef.current.getBoundingClientRect().height);
      }

      if (!containerHeight) {
        return;
      }

      doc.style.setProperty(
        `--${REDESIGN_HEADER_HEIGHT_CSS_VARIABLE}`,
        `${containerHeight - ADDITIONAL_TOP_PADDING_PX - STACKED_STICKY_ITEM_HEIGHT_REDUCTION}px`
      );
    };

    const resizeObserver = new ResizeObserver(() => {
      requestAnimationFrame(updateHeaderHeights);
    });

    resizeObserver.observe(topSectionRef.current);
    updateHeaderHeights();

    return () => {
      controlCookieContainerVisibility('block');
      document.documentElement.style.removeProperty('scroll-padding-top');
      resizeObserver.disconnect();
    };
  }, []);

  const controlCookieContainerVisibility = (displayValue: 'none' | 'block') => {
    const container = document.querySelector('#ccc') as HTMLDivElement;

    if (!container) return;

    container.style.display = displayValue;
  };

  const openMenu = () => {
    setIsMenuOpen(true);
    setMenuOpen?.(true);

    amplitudeTrackEvent('Burger Menu Open', {
      custom_page: window.location.origin,
      custom_level: pageLevel,
    });

    controlCookieContainerVisibility('none');

    setSelectedLevel1Index(null);
    setSelectedLevel2Index(null);
    setIsClubSearchOpen(null);

    if (headerBarRef.current) {
      setHeaderBarHeight(headerBarRef.current.getBoundingClientRect().height);
    }
  };

  const goBack = () => {
    if (selectedLevel2Index != null) {
      setSelectedLevel2Index(null);
    } else {
      setSelectedLevel1Index(null);
      setIsClubSearchOpen(false);
    }
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    setMenuOpen?.(false);

    controlCookieContainerVisibility('block');

    setSelectedLevel1Index(null);
    setSelectedLevel2Index(null);
    setIsClubSearchOpen(null);
  };

  const onFinishMenuAction = () => {
    closeMenu();
  };

  if (!headerContent) {
    return null;
  }

  const logoAmplitudeAction = () => {
    amplitudeTrackEvent('Click to Logo', {
      custom_page: window.location.origin,
      custom_level: pageLevel,
    });
  };

  const handleClickHeaderButton = () =>
    amplitudeTrackEvent('Click to Join Club', {
      custom_page: window.location.origin,
      custom_level: pageLevel,
    });

  const logoClick = () => {
    onFinishMenuAction();
    logoAmplitudeAction();
    document.body.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <header css={[componentStyling.container]}>
      <div css={componentStyling.header} ref={topSectionRef}>
        <PromoRibbon isMobileNavOpen={isMenuOpen} />
        <div css={[componentStyling.headerContent, flex.row]} ref={headerBarRef}>
          <div css={[componentStyling.menuButtons, flex.row]}>
            {!isMenuOpen && (
              <button
                type="button"
                css={[componentStyling.menuButton, componentStyling.openButton]}
                onClick={openMenu}
              >
                <MenuIcon size="1.5rem" fill={styling.colors.capeCod} />
              </button>
            )}
            {isMenuOpen && (selectedLevel1Index != null || isClubSearchOpen) && (
              <button type="button" css={componentStyling.menuButton} onClick={goBack}>
                <RedesignLeftChevronThin size="0.75rem" fill={styling.colors.capeCod} />
                <div>{headerContent?.headerOtherText?.backText}</div>
              </button>
            )}
          </div>
          <div css={componentStyling.logo}>
            <WebsiteLink to="/" handleClick={logoClick}>
              <DavidLloydLogo size="6.875rem" />
            </WebsiteLink>
          </div>
          <div css={[componentStyling.buttonWrapper]}>
            {headerContent?.headerButton?.showButton && !isMenuOpen && (
              <LinkButton
                {...headerContent.headerButton}
                buttonStyle={headerContent.headerButton.buttonStyle as ButtonStyle}
                buttonType="grid-button"
                gtmTrackingId="header-button"
                customButtonStyle={[
                  getNewButtonCss(
                    headerContent.headerButton.buttonStyle as RedesignButtonStyle,
                    styling
                  ),
                  {
                    fontSize: '1rem',
                    padding: '0.75rem',
                    height: 'auto',
                  },
                ]}
                iconView={headerContent.headerButton.buttonIcon}
                handleClick={handleClickHeaderButton}
                isRedesignButton
              />
            )}
            {isMenuOpen && (
              <button type="button" css={componentStyling.menuButton} onClick={closeMenu}>
                <div>{headerContent.headerOtherText?.closeText}</div>
                <RedesignClose size="1rem" fill={styling.colors.capeCod} />
              </button>
            )}
          </div>
        </div>
      </div>
      <HeaderMenu
        headerBarHeight={headerBarHeight}
        isVisible={isMenuOpen}
        isClubSearchOpen={isClubSearchOpen}
        openClubSearch={() => {
          setIsClubSearchOpen(true);
        }}
        level1SelectedIndex={selectedLevel1Index}
        level2SelectedIndex={selectedLevel2Index}
        onSelectLevel1Index={setSelectedLevel1Index}
        onSelectLevel2Index={setSelectedLevel2Index}
        onFinish={onFinishMenuAction}
      />
    </header>
  );
};

export default HeaderMobile;
