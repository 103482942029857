import { CSSInterpolation } from '@emotion/serialize';
import { BuildType } from 'src/enums/build/build-type';
import { fonts, colors } from 'src/styling/constants';

export type TextStyles = {
  header: CSSInterpolation;
  promoTitle?: CSSInterpolation;
};

export const getTextStyles = (buildType: BuildType): TextStyles => {
  switch (buildType) {
    case BuildType.BLAZE_STUDIO: {
      return {
        header: [
          fonts.blazeHeader,
          {
            textTransform: 'uppercase',
          },
        ],
      };
    }
    case BuildType.HARBOUR: {
      return {
        header: [
          fonts.harbourHeader,
          {
            textTransform: 'uppercase',
            letterSpacing: '0.5rem',
            lineHeight: 1.2,
          },
        ],
      };
    }
    case BuildType.MERIDIAN: {
      return {
        header: [
          fonts.meridianBold,
          {
            lineHeight: '3rem',
          },
        ],
        promoTitle: [
          fonts.meridian,
          {
            lineHeight: '1rem',
            fontSize: '0.75rem',
            color: colors.meridianBody,
            textTransform: 'uppercase',
            marginBottom: '1rem',
          },
        ],
      };
    }
    default: {
      return {
        header: [
          fonts.bold,
          {
            lineHeight: 1,
          },
        ],
      };
    }
  }
};
