import {
  booleanWidget,
  selectWidget,
  SelectWidgetOption,
  WidgetBaseInterface,
  WidgetType,
} from '../common-widgets/decap-cms-widgets';

export const enabledPagesWidget = (): WidgetBaseInterface => ({
  name: 'pages',
  label: '⚙ Enabled pages',
  widget: WidgetType.OBJECT,
  fields: [
    enabledRootPageWidget('treatmentsPage', 'Treatments'),
    enabledRootPageWidget('familyPage', 'Family'),
    enabledRootPageWidget('liveClassesPage', 'Live Classes'),
    enabledRootPageWidget('onDemandPage', 'On Demand'),
    enabledFlexibleSectionPageWidget('golfPage', 'Golf'),
    enabledFlexibleSectionPageWidget('yogaPage', 'Yoga'),
    enabledRootPageWidget('timetablePage', 'Timetable'),
    enabledRootPageWidget('familyAndKidsPage', 'Family & Kids'),
    enabledRootPageWidget('relaxAndSocialisePage', 'Relax & Socialise'),
    enabledRootPageWidget('clubFacilitiesPage', 'Club Facilities'),
    enabledFlexibleSectionPageWidget('clubFacilities_spaPage', 'Spa'),
    enabledFlexibleSectionPageWidget('clubFacilities_poolsPage', 'Pools'),
    enabledFlexibleSectionPageWidget('clubFacilities_tennisPage', 'Tennis'),
    enabledFlexibleSectionPageWidget('clubFacilities_padelPage', 'Padel'),
    enabledFlexibleSectionPageWidget('clubFacilities_gymPage', 'Gym'),
    enabledFlexibleSectionPageWidget('clubFacilities_exerciseClassesPage', 'Exercise Classes'),
    enabledFlexibleSectionPageWidget('clubFacilities_crechePage', 'Crèche'),
    enabledFlexibleSectionPageWidget('clubFacilities_kidsClassesPage', 'Kids Club'),
    enabledFlexibleSectionPageWidget('blazePage', 'Blaze'),
    enabledFlexibleSectionPageWidget('personalTrainingPage', 'Personal Training'),
  ],
  collapsed: true,
});

export enum ClubSection {
  ROOT = 'root',
  CLUB_FACILITIES = 'clubFacilities',
  TREATMENTS = 'treatments',
  FITNESS = 'fitness',
}

const enabledRootPageWidget = (name: string, label: string): WidgetBaseInterface =>
  enabledClubPageWidget(name, label, [ClubSection.ROOT]);

const enabledFlexibleSectionPageWidget = (name: string, label: string): WidgetBaseInterface =>
  enabledClubPageWidget(name, label, getClubSectionOptionsForFlexibleHierarchyPage());

const enabledClubPageWidget = (
  name: string,
  label: string,
  clubSectionOptions: ClubSection[]
): WidgetBaseInterface => {
  const selectOptions = clubSectionOptions.map((section) =>
    getSelectWidgetOptionForClubSection(section)
  );
  return {
    name,
    label,
    widget: WidgetType.OBJECT,
    fields: [
      booleanWidget('enabled', 'Enabled'),
      selectWidget('clubSection', 'Club Section', selectOptions),
    ],
    collapsed: true,
  };
};

const getClubSectionOptionsForFlexibleHierarchyPage = (): ClubSection[] => [
  ClubSection.ROOT,
  ClubSection.CLUB_FACILITIES,
  ClubSection.TREATMENTS,
  ClubSection.FITNESS,
];

const getSelectWidgetOptionForClubSection = (level: ClubSection): SelectWidgetOption => {
  switch (level) {
    case ClubSection.ROOT:
      return {
        label: 'Root',
        value: level,
      };
    case ClubSection.CLUB_FACILITIES:
      return {
        label: 'Club Facilities',
        value: level,
      };
    case ClubSection.TREATMENTS:
      return {
        label: 'Treatments',
        value: level,
      };
    case ClubSection.FITNESS:
      return {
        label: 'Fitness',
        value: level,
      };
    default:
      throw new Error('Unrecognised ClubSection');
  }
};
