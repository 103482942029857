import { TranslatedStaticText } from '../../../translators/static-text-translator';
import { CmsContent } from '../../../content';
import mq from '../../../styling/media-queries';
import {
  flex,
  colors,
  zIndexes,
  mainNavHeight,
  globalBannerHeight,
} from '../../../styling/constants';
import React, { useContext } from 'react';
import { Styling } from '../../../context/styling';
import { CSSInterpolation } from '@emotion/serialize';
import MobileNavLink from './mobile-nav-link';
import MobileNavClub from './mobile-nav-club';
import LanguagePicker from '../../common/language-picker';
import { NavigationPage } from 'src/models/cms/navigation-page';
import { SiteContext } from 'src/context/site-context';
import { GlobalCmsContent } from 'src/content/global-content';
import { MembersLoginButton } from 'src/components/common/members-login-button';
import { shouldApplyMembersLoginButton } from 'src/helpers/members-login-helper';
import { getClubDisplayValues } from 'src/enums/build/build-type';
import { getBuildDomainForBuildType, newWebsiteBuildDomains } from 'src/enums/build/build-domain';

const wrapperStyling = (styling: Styling, bannerIsActive: boolean) =>
  mq({
    zIndex: zIndexes.mobileNav,
    top: bannerIsActive ? `calc(${globalBannerHeight.mobile} + ${mainNavHeight})` : mainNavHeight,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: styling.colors.headerBackground,
    position: 'fixed',
    overflow: 'auto',
    display: ['flex', 'none'],
    flexDirection: 'column',
    alignItems: 'center',
  });

const linksWrapperStyling = (styling: Styling, isNewDesign: boolean) =>
  [
    flex.columnCentered,
    {
      width: '100%',
      overflowY: 'auto',
      color: isNewDesign ? styling.colors.capeCod : styling.colors.highlightText,
      flexWrap: 'nowrap',
    },
  ] as CSSInterpolation;

const secondaryLinksWrapperStyling = (styling: Styling, isNewDesign: boolean) =>
  [
    {
      width: '100%',
      color: isNewDesign ? styling.colors.capeCod : styling.colors.highlightText,
      textDecoration: 'underline',
      lineHeight: '2.5',
    },
  ] as CSSInterpolation;

type MobileNavProps = {
  clubName?: string;
  closeNav: () => void;
  mobileNavigationOpened: boolean;
  bannerIsActive: boolean;
};

const MobileNav: React.FC<MobileNavProps> = ({
  clubName,
  closeNav,
  mobileNavigationOpened,
  bannerIsActive,
}) => {
  const clubCmsData = useContext(CmsContent);
  const globalCmsData = useContext(GlobalCmsContent);
  const { styling, buildType, isGlobalPage, isBlaze } = useContext(SiteContext);
  const isNewDesign = newWebsiteBuildDomains.includes(getBuildDomainForBuildType(buildType));

  const navigationPagesToDisplay = isGlobalPage
    ? globalCmsData.sharedContent?.navigationPagesToDisplay
    : clubCmsData.navigationMenuContent?.navigationPagesToDisplay;

  const applyMembersLoginButton = shouldApplyMembersLoginButton(isGlobalPage, isBlaze, buildType);

  return (
    <div css={mq({ display: [mobileNavigationOpened ? 'flex' : 'none', 'none'] })}>
      <div css={wrapperStyling(styling, bannerIsActive)}>
        <MobileNavClub clubName={clubName} styling={styling} onFinish={closeNav} />
        <div
          css={[
            flex.columnCentered,
            styling.fonts.bold,
            {
              flex: '1 1 auto',
              justifyContent: 'center',
              fontSize: isNewDesign ? '1.1875rem' : '3vh',
              lineHeight: 1.5,
            },
          ]}
        >
          <div css={[styling.fonts.regular, { color: colors.beige }]}>
            {isGlobalPage ? (
              <TranslatedStaticText
                lookupKey="atUnit"
                params={{ unitName: getClubDisplayValues(buildType).displayName }}
              />
            ) : (
              <TranslatedStaticText lookupKey="atClub" params={{ clubName: clubName }} />
            )}
            :
          </div>
          <div css={linksWrapperStyling(styling, isNewDesign)}>
            {navigationPagesToDisplay?.mainPages.map((navigationPage: NavigationPage) => (
              <MobileNavLink
                key={navigationPage.link}
                id={navigationPage.link}
                path={navigationPage.link}
                text={navigationPage.displayText}
                closeNav={closeNav}
              />
            ))}
          </div>
        </div>
        <LanguagePicker />
        <div
          css={[
            flex.columnCentered,
            isNewDesign ? styling.fonts.regular : styling.fonts.bold,
            { fontSize: isNewDesign ? '1.1875rem' : '2vh', textAlign: 'center' },
          ]}
        >
          {navigationPagesToDisplay?.secondaryPages?.length > 0 && (
            <div css={secondaryLinksWrapperStyling(styling, isNewDesign)}>
              {navigationPagesToDisplay?.secondaryPages?.map((navigationPage: NavigationPage) => (
                <MobileNavLink
                  key={navigationPage.link}
                  id={navigationPage.link}
                  path={navigationPage.link}
                  text={navigationPage.displayText}
                  closeNav={closeNav}
                />
              ))}
            </div>
          )}
          {applyMembersLoginButton && <MembersLoginButton />}
        </div>
      </div>
    </div>
  );
};

export default MobileNav;
