import React, { RefObject, useContext, useEffect, useState } from 'react';
import { SiteContext } from 'src/context/site-context';
import { mqReducedMotion } from 'src/styling/media-queries';
import { keyframes } from '@emotion/react';

const skeletonAnimation = keyframes({
  '0%': {
    opacity: 1,
  },
  '50%': {
    opacity: 0.4,
  },
  '100%': {
    opacity: 1,
  },
});

type ImageLoadingSkeletonProps = {
  imageRef: RefObject<HTMLImageElement>;
  showLoadingSkeleton?: boolean;
};

const ImageLoadingSkeleton: React.FC<ImageLoadingSkeletonProps> = ({
  imageRef,
  showLoadingSkeleton,
}) => {
  const { styling } = useContext(SiteContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const image = imageRef?.current;

    const hideImageSkeleton = () => {
      setIsLoading(false);
    };

    if (!image) return;

    if (image.complete) {
      hideImageSkeleton();
    } else {
      image.addEventListener('load', hideImageSkeleton);
      image.addEventListener('error', hideImageSkeleton);
    }

    return () => {
      image?.removeEventListener('load', hideImageSkeleton);
      image?.removeEventListener('error', hideImageSkeleton);
    };
  }, [imageRef]);

  const conditionToShow = showLoadingSkeleton ?? isLoading;

  return (
    conditionToShow && (
      <div
        css={mqReducedMotion({
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          opacity: '0.99',
          backgroundColor: styling.colors.capeCod,
          animation: [
            `2s ease-in-out 0.5s infinite normal none running ${skeletonAnimation}`,
            'none',
          ],
        })}
      />
    )
  );
};

export default ImageLoadingSkeleton;
