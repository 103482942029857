import { TEXT_FIELD_MAX_LENGTH_FOR_ENQUIRIES } from '../../src/components/common/form/clubcentric-max-lengths';

export enum WidgetType {
  STRING = 'string',
  TEXT = 'text',
  MARKDOWN = 'markdown',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  DATETIME = 'datetime',
  IMAGE = 'image',
  IMAGE_WITH_CROP = 'image-with-crop',
  SELECT = 'select',
  LIST = 'list',
  OBJECT = 'object',
  HIDDEN = 'hidden',
  PAGE_LANGUAGE = 'page-language',
  URL_SUFFIX = 'url-suffix',
  CLUB_SELECT = 'club-select',
  CONTENT_ORDER = 'content-order',
  FILE = 'file',
  RELATION = 'relation',
}

export interface WidgetBaseInterface {
  name: string;
  label: string;
  widget: WidgetType;
  hint?: string;

  [others: string]: any;
}

export interface SelectWidgetOption {
  label: string;
  value: string | number;
}

export const stringWidget = (
  name: string,
  label: string,
  hint: string = '',
  required: boolean = false,
  defaultValue: string = ''
): WidgetBaseInterface => ({
  name: name,
  label: label,
  hint: hint,
  widget: WidgetType.STRING,
  required,
  default: defaultValue,
});

export const pageCodeWidget = (): WidgetBaseInterface =>
  stringWidget(
    'pageCode',
    'Page Code',
    'An identifier that should be used for the same page across different languages and domains. This is used for the language switcher to navigate to the correct page and for SEO to link similar pages together.'
  );

export const requiredStringWidget = (
  name: string,
  label: string,
  hint: string = ''
): WidgetBaseInterface => stringWidget(name, label, hint, true);

export const stringLinkWidget = (
  name: string,
  label: string,
  hint: string = '',
  required: boolean = false,
  defaultValue: string = ''
): WidgetBaseInterface => ({
  name: name,
  label: label,
  hint: hint,
  widget: WidgetType.STRING,
  required,
  default: defaultValue,
  pattern: ['^[^\\s]*$', 'Please remove any whitespace from the link'],
});

export const textWidget = (
  name: string,
  label: string,
  required: Boolean = true,
  hint: string = '',
  maxLength: number = TEXT_FIELD_MAX_LENGTH_FOR_ENQUIRIES
): WidgetBaseInterface => ({
  name: name,
  label: label,
  widget: WidgetType.TEXT,
  hint,
  required,
  maxLength,
});

type MarkdownWidgetOptions = {
  name: string;
  label: string;
  required?: Boolean;
  hint?: string;
  defaultValue?: string;
  pattern?: Array<string>;
};

export const markdownWidget = ({
  name,
  label,
  required = true,
  hint = '',
  defaultValue = '',
  pattern,
}: MarkdownWidgetOptions): WidgetBaseInterface => ({
  name,
  label,
  widget: WidgetType.MARKDOWN,
  hint: hint,
  buttons: [
    'bold',
    'italic',
    'link',
    'heading-one',
    'heading-two',
    'quote',
    'bulleted-list',
    'numbered-list',
  ],
  required,
  default: defaultValue,
  pattern,
});

export const numberWidget = (
  name: string,
  label: string,
  hint: string = '',
  required: boolean = true,
  min: number = undefined,
  max: number = undefined
): WidgetBaseInterface => ({
  name: name,
  label: label,
  widget: WidgetType.NUMBER,
  valuetype: 'int',
  hint,
  required,
  min,
  max,
});

// There is a bug in the Netlify CMS number widget that doesn't allow floating point numbers to
// be entered, even with "valuetype: 'float'" (https://github.com/netlify/netlify-cms/issues/4808)
// so we instead need to enter the value as a string, validate the format with a regex, then parse
// to a number where required.
export const stringFloatWidget = (
  name: string,
  label: string,
  hint: string = '',
  required: boolean = true
): WidgetBaseInterface => ({
  name: name,
  label: label,
  hint: hint,
  widget: WidgetType.STRING,
  required: required,
  default: '',
  pattern: ['^-?\\d*(\\.\\d+)?$', 'Invalid number format'],
});

export const booleanWidget = (
  name: string,
  label: string,
  hint: string = '',
  defaultValue: boolean = false,
  hidden: boolean = false
): WidgetBaseInterface => ({
  name: name,
  label: label,
  hint: hint,
  widget: hidden ? WidgetType.HIDDEN : WidgetType.BOOLEAN,
  default: defaultValue,
  required: false,
});

export const dateTimeWidget = (name: string, label: string): WidgetBaseInterface => ({
  name: name,
  label: label,
  widget: WidgetType.DATETIME,
  format: 'YYYY-MM-DDTHH:mm:ss',
});

export const dateWidget = (
  name: string,
  label: string,
  hint: string = '',
  required: boolean = false,
  format: string = 'YYYY-MM-DD'
): WidgetBaseInterface => ({
  name: name,
  label: label,
  widget: WidgetType.DATETIME,
  required: required,
  format,
  default: '',
  hint,
});

export const requiredDateWidget = (
  name: string,
  label: string,
  hint: string = ''
): WidgetBaseInterface => dateWidget(name, label, hint, true);

export const imageWidget = (
  name?: string,
  label?: string,
  required?: boolean,
  defaultValue = ''
): WidgetBaseInterface => ({
  name: name || 'image',
  label: label || 'Image',
  widget: WidgetType.IMAGE,
  required: !!required,
  default: defaultValue,
});

export type ImageWithCropWidgetOptions = {
  name?: string;
  label?: string;
  defaultUrl?: string;
  required?: boolean;
  mobileCropRequired?: boolean;
  mobileImageHeightPx: number;
  mobileImageWidthPx: number;
};

export const imageWithCropWidget = ({
  name,
  label,
  defaultUrl = '',
  ...rest
}: ImageWithCropWidgetOptions): WidgetBaseInterface => ({
  name: name || 'image',
  label: label || 'Image',
  widget: WidgetType.IMAGE_WITH_CROP,
  default: {
    imagePath: defaultUrl,
  },
  ...rest,
});

export const cloudinaryVideoWidget = (name?: string, label?: string, required = false) => ({
  name: name || 'video',
  label: label || 'Video',
  widget: WidgetType.FILE,
  required,
});

export const selectWidget = (
  name: string,
  label: string,
  options: string[] | SelectWidgetOption[],
  multiple = false,
  required = false,
  defaultValue = '',
  hint = ''
): WidgetBaseInterface => ({
  widget: WidgetType.SELECT,
  name,
  label,
  options,
  multiple,
  required,
  default: defaultValue,
  hint,
});

export const listWidgetWithField = (
  name: string,
  label: string,
  field: string | WidgetBaseInterface,
  hint: string = '',
  summary?: string
): WidgetBaseInterface => ({
  name,
  label,
  widget: WidgetType.LIST,
  field,
  hint,
  summary,
  default: [] as any,
  collapsed: true,
});

export const listWidgetWithFields = (
  name: string,
  label: string,
  fields: WidgetBaseInterface[],
  hint: string = '',
  defaultList: any[] = [],
  summary?: string
): WidgetBaseInterface => ({
  name,
  label,
  widget: WidgetType.LIST,
  fields,
  hint,
  summary,
  default: defaultList,
  collapsed: true,
});
