import { Brand } from '../brand';
import { getUrlValueForLanguage, Language } from '../language';
import { MonthlyPayment } from '../monthly-payment';

export enum BuildType {
  DAVID_LLOYD_UK = 'david-lloyd-uk',
  DAVID_LLOYD_BELGIUM = 'david-lloyd-belgium',
  DAVID_LLOYD_GERMANY = 'david-lloyd-germany',
  DAVID_LLOYD_SPAIN = 'david-lloyd-spain',
  DAVID_LLOYD_FRANCE = 'david-lloyd-france',
  DAVID_LLOYD_IRELAND = 'david-lloyd-ireland',
  DAVID_LLOYD_ITALY = 'david-lloyd-italy',
  DAVID_LLOYD_NETHERLANDS = 'david-lloyd-netherlands',
  DAVID_LLOYD_SWITZERLAND = 'david-lloyd-switzerland',
  BLAZE_STUDIO = 'blaze-studio',
  HARBOUR = 'harbour',
  MERIDIAN = 'meridian',
}

export const DAVID_LLOYD_BUILD_TYPES = [
  BuildType.DAVID_LLOYD_UK,
  BuildType.DAVID_LLOYD_BELGIUM,
  BuildType.DAVID_LLOYD_GERMANY,
  BuildType.DAVID_LLOYD_SPAIN,
  BuildType.DAVID_LLOYD_FRANCE,
  BuildType.DAVID_LLOYD_IRELAND,
  BuildType.DAVID_LLOYD_ITALY,
  BuildType.DAVID_LLOYD_NETHERLANDS,
  BuildType.DAVID_LLOYD_SWITZERLAND,
];

export const BLAZE_CLUB_PAGES: string[] = ['/clubs/landing/', '/clubs/faqs/', '/clubs/timetable/'];

export const CLUB_PAGES_BUILD_WHITELIST = [
  '/clubs/landing/',
  '/clubs/book-a-tour/',
  '/clubs/book-a-club-welcome/',
  '/clubs/club-facilities/',
  '/clubs/enquire-now/',
  '/clubs/family-and-kids/',
  '/clubs/join-now/',
  '/clubs/membership-trial/',
  '/clubs/relax-and-socialise/',
  '/clubs/timetable/',
  '/clubs/yoga/',
  '/clubs/blaze/',
  '/clubs/personal-training/',
  '/clubs/golf/',
  '/clubs/family/',
  '/clubs/live-classes/',
  '/clubs/on-demand/',
  '/clubs/club-facilities/creche/',
  '/clubs/club-facilities/exercise-classes/',
  '/clubs/club-facilities/gym/',
  '/clubs/club-facilities/kids-classes/',
  '/clubs/club-facilities/pools/',
  '/clubs/club-facilities/spa/',
  '/clubs/club-facilities/tennis/',
  '/clubs/treatments/',
  '/clubs/live-classes/',
  '/clubs/join-online/',
  '/clubs/join-online/more-details/',
  '/clubs/join-online/corporate/',
  '/clubs/join-online/TDRCapital/',
  '/clubs/join-online/friends-and-family/',
];

const HARBOUR_CLUB_PAGES = [
  '/clubs/landing/',
  '/clubs/book-a-tour/',
  '/clubs/book-a-club-welcome/',
  '/clubs/club-facilities/',
  '/clubs/enquire-now/',
  '/clubs/family-and-kids/',
  '/clubs/relax-and-socialise/',
  '/clubs/timetable/',
  '/clubs/yoga/',
  '/clubs/blaze/',
  '/clubs/personal-training/',
  '/clubs/golf/',
  '/clubs/family/',
  '/clubs/live-classes/',
  '/clubs/on-demand/',
  '/clubs/club-facilities/creche/',
  '/clubs/club-facilities/exercise-classes/',
  '/clubs/club-facilities/gym/',
  '/clubs/club-facilities/kids-classes/',
  '/clubs/club-facilities/pools/',
  '/clubs/club-facilities/spa/',
  '/clubs/club-facilities/tennis/',
  '/clubs/membership-trial/',
  '/clubs/join-online/',
  '/clubs/join-online/more-details/',
  '/clubs/join-online/corporate/',
  '/clubs/join-online/friends-and-family/',
];

export type LanguagesForBuild = {
  default: Language;
  secondary: Language[];
};

// When editing the languages per build type, edit the redirect on the servers template as well
export const getOrderedLanguagesForBuildType = (buildType: BuildType): LanguagesForBuild => {
  switch (buildType) {
    case BuildType.DAVID_LLOYD_UK:
    case BuildType.HARBOUR:
    case BuildType.BLAZE_STUDIO:
      return {
        default: Language.EN_GB,
        secondary: [],
      };
    case BuildType.DAVID_LLOYD_BELGIUM:
      return {
        default: Language.NL_BE,
        secondary: [Language.EN_BE, Language.FR_BE],
      };
    case BuildType.DAVID_LLOYD_FRANCE:
      return {
        default: Language.FR_FR,
        secondary: [Language.EN_FR],
      };
    case BuildType.DAVID_LLOYD_GERMANY:
      return {
        default: Language.DE_DE,
        secondary: [Language.EN_DE],
      };
    case BuildType.DAVID_LLOYD_IRELAND:
      return {
        default: Language.EN_IE,
        secondary: [],
      };
    case BuildType.DAVID_LLOYD_ITALY:
      return {
        default: Language.IT_IT,
        secondary: [Language.EN_IT],
      };
    case BuildType.DAVID_LLOYD_NETHERLANDS:
      return {
        default: Language.NL_NL,
        secondary: [Language.EN_NL],
      };
    case BuildType.DAVID_LLOYD_SPAIN:
      return {
        default: Language.ES_ES,
        secondary: [Language.CA_ES, Language.EN_ES],
      };
    case BuildType.DAVID_LLOYD_SWITZERLAND:
      return {
        default: Language.FR_CH,
        secondary: [Language.EN_CH],
      };
    case BuildType.MERIDIAN:
      return {
        default: Language.DE_DE,
        secondary: [Language.EN_DE],
      };
    default:
      throw new Error(`Unexpected build type ${buildType} when looking up languages`);
  }
};

export const getAllLanguagesForBuildType = (buildType: BuildType): Language[] => {
  const orderedLanguages = getOrderedLanguagesForBuildType(buildType);
  return [orderedLanguages.default, ...orderedLanguages.secondary];
};

export const getClubSpecificLanguagesForBuildType = (
  languagesOverride: Language[],
  buildType: BuildType
): Language[] => {
  return languagesOverride || getAllLanguagesForBuildType(buildType);
};

export const shouldIncludeLanguageInUrlForBuildType = (buildType: BuildType): boolean => {
  if (buildType === BuildType.MERIDIAN) {
    return true;
  }

  const languagesForBuildType = getAllLanguagesForBuildType(buildType);
  return languagesForBuildType.length !== 1;
};

export const getLanguageUrlPrefixForBuildType = (
  language: Language,
  buildType: BuildType
): string => {
  return shouldIncludeLanguageInUrlForBuildType(buildType) ? getUrlValueForLanguage(language) : '';
};

export const getPagesToBuildForBuildType = (buildType: BuildType): String[] => {
  switch (buildType) {
    case BuildType.BLAZE_STUDIO:
      return BLAZE_CLUB_PAGES;
    case BuildType.HARBOUR:
      return HARBOUR_CLUB_PAGES;
    default:
      return CLUB_PAGES_BUILD_WHITELIST;
  }
};

type ClubDisplayValues = {
  cmsCollectionName: string;
  clubNamePrefix: string;
  displayName: string;
  copyright: string;
};

export const getClubDisplayValues = (buildType: BuildType): ClubDisplayValues => {
  switch (buildType) {
    case BuildType.DAVID_LLOYD_UK:
      return {
        cmsCollectionName: 'UK',
        clubNamePrefix: 'David Lloyd',
        displayName: 'David Lloyd Clubs',
        copyright: 'David Lloyd Leisure Ltd',
      };
    case BuildType.BLAZE_STUDIO:
      return {
        cmsCollectionName: 'BLAZE',
        clubNamePrefix: 'Blaze Studio',
        displayName: 'Blaze Studio',
        copyright: 'David Lloyd Leisure Ltd',
      };
    case BuildType.HARBOUR:
      return {
        cmsCollectionName: 'Harbour',
        clubNamePrefix: 'Harbour Club',
        displayName: 'Harbour Club',
        copyright: 'Harbour Club Ltd',
      };
    case BuildType.DAVID_LLOYD_BELGIUM:
      return {
        cmsCollectionName: 'BE',
        clubNamePrefix: 'David Lloyd',
        displayName: 'David Lloyd Clubs',
        copyright: 'David Lloyd Leisure Ltd',
      };
    case BuildType.DAVID_LLOYD_FRANCE:
      return {
        cmsCollectionName: 'FR',
        clubNamePrefix: 'David Lloyd',
        displayName: 'David Lloyd Clubs',
        copyright: 'David Lloyd Leisure Ltd',
      };
    case BuildType.DAVID_LLOYD_GERMANY:
      return {
        cmsCollectionName: 'DE',
        clubNamePrefix: 'David Lloyd',
        displayName: 'David Lloyd Clubs',
        copyright: 'David Lloyd Leisure Ltd',
      };
    case BuildType.DAVID_LLOYD_IRELAND:
      return {
        cmsCollectionName: 'IE',
        clubNamePrefix: 'David Lloyd',
        displayName: 'David Lloyd Clubs',
        copyright: 'David Lloyd Leisure Ltd',
      };
    case BuildType.DAVID_LLOYD_ITALY:
      return {
        cmsCollectionName: 'IT',
        clubNamePrefix: 'David Lloyd',
        displayName: 'David Lloyd Clubs',
        copyright: 'David Lloyd Leisure Ltd',
      };
    case BuildType.DAVID_LLOYD_NETHERLANDS:
      return {
        cmsCollectionName: 'NL',
        clubNamePrefix: 'David Lloyd',
        displayName: 'David Lloyd Clubs',
        copyright: 'David Lloyd Leisure Ltd',
      };
    case BuildType.DAVID_LLOYD_SPAIN:
      return {
        cmsCollectionName: 'ES',
        clubNamePrefix: 'David Lloyd',
        displayName: 'David Lloyd Clubs',
        copyright: 'David Lloyd Leisure Ltd',
      };
    case BuildType.DAVID_LLOYD_SWITZERLAND:
      return {
        cmsCollectionName: 'CH',
        clubNamePrefix: 'David Lloyd',
        displayName: 'David Lloyd Clubs',
        copyright: 'David Lloyd Leisure Ltd',
      };
    case BuildType.MERIDIAN:
      return {
        cmsCollectionName: 'Meridian',
        clubNamePrefix: 'David Lloyd Meridian',
        displayName: 'David Lloyd Meridian',
        copyright: 'David Lloyd Leisure Ltd',
      };

    default:
      console.warn(`Unexpected buildType ${buildType}`);
      return null;
  }
};

export const shouldUseInternalClubPickerForBuildType = (buildType: BuildType) => {
  return buildType !== BuildType.DAVID_LLOYD_UK && buildType !== BuildType.BLAZE_STUDIO;
};

export const getBrandForBuildType = (buildType: BuildType): Brand => {
  switch (buildType) {
    case BuildType.DAVID_LLOYD_UK:
      return Brand.DAVID_LLOYD_UK;
    case BuildType.DAVID_LLOYD_SPAIN:
      return Brand.DAVID_LLOYD_SPAIN;
    case BuildType.DAVID_LLOYD_BELGIUM:
      return Brand.DAVID_LLOYD_BELGIUM;
    case BuildType.HARBOUR:
      return Brand.HARBOUR;
    case BuildType.DAVID_LLOYD_IRELAND:
      return Brand.DAVID_LLOYD_IRELAND;
    case BuildType.DAVID_LLOYD_NETHERLANDS:
      return Brand.DAVID_LLOYD_NETHERLANDS;
    case BuildType.DAVID_LLOYD_ITALY:
      return Brand.DAVID_LLOYD_ITALY;
    case BuildType.DAVID_LLOYD_FRANCE:
      return Brand.DAVID_LLOYD_FRANCE;
    case BuildType.DAVID_LLOYD_GERMANY:
      return Brand.DAVID_LLOYD_GERMANY;
    case BuildType.MERIDIAN:
      return Brand.MERIDIAN;
    case BuildType.DAVID_LLOYD_SWITZERLAND:
      return Brand.DAVID_LLOYD_SWITZERLAND;
    case BuildType.BLAZE_STUDIO: {
      throw new Error('Not implemented yet: BuildType.BLAZE_STUDIO case');
    }
  }
};

export const getGlobalCmsCollectionLabelForBuildTypeWithBreadcrumbs = (
  buildType: BuildType,
  ...breadcrumbs: string[]
): string => {
  const breadcrumbSeparator = ' - ';
  const baseLabel = `${
    getClubDisplayValues(buildType).cmsCollectionName
  }${breadcrumbSeparator}Global`;
  if (breadcrumbs.length === 0) {
    return baseLabel;
  }
  return `${baseLabel}${breadcrumbSeparator}${breadcrumbs.join(breadcrumbSeparator)}`;
};

export const getGlobalCmsCollectionLabelForBuildType = (buildType: BuildType): string =>
  getGlobalCmsCollectionLabelForBuildTypeWithBreadcrumbs(buildType);

export const getGlobalCityPageCmsCollectionLabelForBuildType = (buildType: BuildType): string =>
  getGlobalCmsCollectionLabelForBuildTypeWithBreadcrumbs(buildType, 'City Pages');

export const getGlobalHelpCentreCategoriesCmsCollectionLabelForBuildType = (
  buildType: BuildType
): string =>
  getGlobalCmsCollectionLabelForBuildTypeWithBreadcrumbs(buildType, 'Help Centre Categories');

export const getMonthlyPaymentTypeForBuildType = (buildType: BuildType): MonthlyPayment => {
  if (
    buildType === BuildType.DAVID_LLOYD_UK ||
    buildType === BuildType.HARBOUR ||
    buildType === BuildType.BLAZE_STUDIO
  ) {
    return MonthlyPayment.DIRECT_DEBIT;
  } else {
    return MonthlyPayment.SEPA;
  }
};

export const isSingleClubDomain = (buildType: BuildType): boolean => {
  const singleClubDomains: BuildType[] = [
    BuildType.DAVID_LLOYD_GERMANY,
    BuildType.DAVID_LLOYD_FRANCE,
    BuildType.DAVID_LLOYD_IRELAND,
    BuildType.DAVID_LLOYD_ITALY,
  ];
  return singleClubDomains.includes(buildType);
};

export const getOnlineJoiningEmailForBuildType = (buildType: BuildType): string => {
  if (buildType === BuildType.HARBOUR) {
    return 'online.joining@harbourclub.co.uk';
  }
  return 'online.joining@davidlloyd.co.uk';
};

export const getFeedbackEmailForBuildType = (buildType: BuildType): string => {
  switch (buildType) {
    case BuildType.MERIDIAN:
      return 'appfeedback@davidlloyd.de';
    case BuildType.HARBOUR:
      return 'appfeedback@harbourclub.co.uk';
    default:
      return 'appfeedback@davidlloyd.co.uk';
  }
};

export const getLinkedBuildTypesForBuildType = (buildType: BuildType): BuildType[] => {
  switch (buildType) {
    case BuildType.DAVID_LLOYD_SWITZERLAND:
      return [BuildType.DAVID_LLOYD_FRANCE];
    case BuildType.DAVID_LLOYD_FRANCE:
      return [BuildType.DAVID_LLOYD_SWITZERLAND];
    case BuildType.DAVID_LLOYD_GERMANY:
      return [BuildType.MERIDIAN];
    case BuildType.MERIDIAN:
      return [BuildType.DAVID_LLOYD_GERMANY];
    case BuildType.DAVID_LLOYD_UK:
      return [BuildType.BLAZE_STUDIO];
    default:
      return [];
  }
};

export const getCountryCodeForBuildType = (buildType: string) => {
  switch (buildType) {
    case BuildType.DAVID_LLOYD_UK:
    case BuildType.BLAZE_STUDIO:
    case BuildType.HARBOUR:
      return 'GB';
    case BuildType.DAVID_LLOYD_BELGIUM:
      return 'BE';
    case BuildType.DAVID_LLOYD_FRANCE:
      return 'FR';
    case BuildType.DAVID_LLOYD_GERMANY:
    case BuildType.MERIDIAN:
      return 'DE';
    case BuildType.DAVID_LLOYD_IRELAND:
      return 'IE';
    case BuildType.DAVID_LLOYD_ITALY:
      return 'IT';
    case BuildType.DAVID_LLOYD_NETHERLANDS:
      return 'NL';
    case BuildType.DAVID_LLOYD_SPAIN:
      return 'ES';
    case BuildType.DAVID_LLOYD_SWITZERLAND:
      return 'CH';
  }
};
