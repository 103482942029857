import { Moment } from 'moment';
import { get } from '../../../helpers/request-helper';
import {
  ClosingTime,
  ClubOpeningTimes,
  OpeningTimesForDay,
  SpecialOpeningTime,
} from '../club-opening-times';

interface ClubDetails {
  clubOpeningTimes: ClubOpeningTimes;
}

function openingHoursIfDateIsSpecialHours(date: Moment, specialOpeningTimes: SpecialOpeningTime[]) {
  const openingInformationIfTodayIsBankHoliday = specialOpeningTimes.find(
    (specialOpeningTime) => specialOpeningTime.date === date.format('YYYY-MM-DD')
  );
  return openingInformationIfTodayIsBankHoliday
    ? openingInformationIfTodayIsBankHoliday.openingPeriods
    : null;
}

export function isClubClosedForDay(date: Moment, closingTimes: ClosingTime[]) {
  if (!closingTimes) return false;
  return !!closingTimes.find((closingTime) => closingTime.date === date.format('YYYY-MM-DD'));
}

export function getOpeningHoursForDay(
  date: Moment,
  openingTimes: ClubOpeningTimes
): OpeningTimesForDay {
  const clubIsClosedForDay = isClubClosedForDay(date, openingTimes.closures);

  const openingTimesIfDateIsSpecialHours = openingHoursIfDateIsSpecialHours(
    date,
    openingTimes.specialOpeningTimes
  );

  if (openingTimesIfDateIsSpecialHours) {
    return {
      openingTimes: openingTimesIfDateIsSpecialHours,
      isSpecialHours: true,
      isClosed: clubIsClosedForDay,
    };
  }

  const allOpeningTimesForDay = openingTimes.weeklyOpeningTimes[date.format('ddd').toLowerCase()];
  return {
    openingTimes: allOpeningTimesForDay,
    isSpecialHours: false,
    isClosed: clubIsClosedForDay,
  };
}

export async function getClubOpeningTimes(clubId: string | number) {
  const clubDetails: ClubDetails = await get<ClubDetails>(`clubs/${clubId}`);
  return clubDetails.clubOpeningTimes;
}
