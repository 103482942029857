import { track } from '@amplitude/analytics-browser';
import { BaseEvent } from '@amplitude/analytics-types';
import { TourType } from 'src/enums/tour-type';
import { JourneyType } from 'src/enums/journey-type';

export const amplitudeTrackEvent = (
  eventInput: string | BaseEvent,
  eventProperties?: Record<string, any> | undefined
) => track(eventInput, eventProperties);

export const getTourMessageForAmplitudeEvent = (tourType: TourType) => {
  switch (tourType) {
    case TourType.BOOK_A_TOUR:
      return 'Book a tour';
    case TourType.CLUB_WELCOME:
      return 'Book a club welcome';
    case TourType.TRIAL:
      return 'Trial';
  }
};

export const getJoinMessageForAmplitudeEvent = (journeyType: JourneyType) => {
  switch (journeyType) {
    case JourneyType.NORMAL:
      return 'Join Normal';
    case JourneyType.FRIENDS_AND_FAMILY:
      return 'Join Friends and Family';
    case JourneyType.TDR_CAPITAL:
      return 'Join TDRCapital';
    case JourneyType.CORPORATE:
      return 'Join Corporate';
    case JourneyType.JOIN_IN_CLUB:
      return 'Join In Club';
    case JourneyType.TRIAL:
      return 'Join Trial';
  }
};
