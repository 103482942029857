import React, { PropsWithChildren, useContext } from 'react';
import { GlobalCmsContent } from 'src/content/global-content';
import { Styling } from 'src/context/styling';
import { CSSInterpolation } from '@emotion/serialize';
import { SiteContext } from 'src/context/site-context';
import MarkdownToHtmlBlock from 'src/components/common/markdown-to-html-block';
import { WebsiteLink } from 'src/components/common/website-link';
import RedesignRightChevronThin from 'src/components/icons/redesign-right-chevron-thin';
import { flex } from 'src/styling/constants';
import HeaderMobileSecondaryNav from 'src/components/layout/redesign/header-mobile/header-secondary-nav';
import HeaderMobileSubMenuWrapper from './header-sub-menu-wrapper';
import { amplitudeTrackEvent } from 'src/helpers/amplitude-event-track';
import capitalize from 'lodash/capitalize';
import { pathToTitle } from 'src/helpers/string-helper';

const createComponentStyling = (styling: Styling, level: number) =>
  ({
    title: [
      styling.fonts.regular,
      {
        fontSize: '2.5rem',
        lineHeight: '3rem',
        margin: '1.5rem 0 1.5rem 1.875rem',
      },
    ],
    text: [
      styling.fonts.regular,
      {
        fontSize: '1rem',
        lineHeight: '125%',
        margin: '0 2.5rem 1.5rem 1.875rem',
      },
    ],
    borderBottom: {
      borderBottom: '1px solid',
      borderBottomColor: styling.colors.transparentGrayOlive,
    },
    link: [
      styling.fonts.regular,
      {
        fontSize: '1.5rem',
        lineHeight: '145%',
        padding: '0.5rem 0.5rem 0.5rem 0.75rem',
        width: '100%',
        backgroundColor: 'unset',
        border: 'unset',
        color: 'inherit',
        textAlign: 'start',
        justifyContent: 'space-between',
        cursor: 'pointer',
        minHeight: level === 1 ? '3.75rem' : '3.25rem',
        gap: '0.5rem',
      },
      {
        '& svg': {
          flexShrink: 0,
        },
      },
    ],
    largerPaddingLeft: {
      paddingLeft: '1.875rem',
    },
  }) satisfies Record<string, CSSInterpolation>;

type SubMenuLink =
  | {
      name: string;
      link: string;
      onClick?: null;
    }
  | {
      name: string;
      link?: null;
      onClick: () => void;
    };

type SubMenuLinkProps = PropsWithChildren<{
  link: SubMenuLink;
  styles: CSSInterpolation;
  enabled: boolean;
  onClickLink?: () => void;
}>;

const SubMenuLinkComponent = ({
  link,
  styles,
  children,
  enabled,
  onClickLink,
}: SubMenuLinkProps) => {
  const { pageLevel } = useContext(SiteContext);
  const handleClickLink = () => {
    onClickLink?.();
    return amplitudeTrackEvent(
      `Click to ${capitalize(link.name || '')} - ${pathToTitle(link.link || '')}`,
      {
        custom_page: window.location.origin,
        custom_level: pageLevel,
      }
    );
  };

  const handleClickButton = () =>
    amplitudeTrackEvent(`Click to ${capitalize(link.name || '')} - Navigation`, {
      custom_page: window.location.origin,
      custom_level: pageLevel,
    });

  if (link.link != null) {
    return (
      <WebsiteLink to={link.link} handleClick={handleClickLink}>
        <div css={styles}>{children}</div>
      </WebsiteLink>
    );
  }

  return (
    <button
      type="button"
      onClick={() => {
        handleClickButton();
        link.onClick();
      }}
      css={styles}
      disabled={!enabled}
    >
      {children}
    </button>
  );
};

export type HeaderMobileSubMenuProps = {
  isMenuOpen: boolean;
  isVisible: boolean;
  isChildMenuVisible: boolean;
  level: number;
  title?: string;
  description?: string;
  links?: SubMenuLink[];
  openClubSearch?: () => void;
  onFinish?: () => void;
};

const HeaderMobileSubMenu = (props: HeaderMobileSubMenuProps) => {
  const { headerContent } = useContext(GlobalCmsContent);
  const { styling } = useContext(SiteContext);
  const componentStyling = createComponentStyling(styling, props.level);

  if (!headerContent) {
    return null;
  }

  return (
    <HeaderMobileSubMenuWrapper
      isMenuOpen={props.isMenuOpen}
      isVisible={props.isVisible}
      isChildMenuVisible={props.isChildMenuVisible}
      openClubSearch={props.openClubSearch}
      level={props.level}
      onClickLink={props.onFinish}
    >
      {(props.title || props.description) && (
        <div css={componentStyling.borderBottom}>
          {props.title && <div css={componentStyling.title}>{props.title}</div>}
          <MarkdownToHtmlBlock
            markdownText={props.description}
            colorOverride={styling.colors.capeCod}
            cssOverride={componentStyling.text}
          />
        </div>
      )}
      {(props.links ?? []).map((link) => (
        <SubMenuLinkComponent
          key={link.name}
          link={link}
          styles={[
            componentStyling.link,
            componentStyling.borderBottom,
            flex.rowCentered,
            props.level > 1 ? componentStyling.largerPaddingLeft : null,
          ]}
          enabled={props.isVisible}
          onClickLink={props.onFinish}
        >
          <div>{link.name}</div>
          <RedesignRightChevronThin size="0.7rem" fill={styling.colors.capeCod} />
        </SubMenuLinkComponent>
      ))}
      {props.level === 1 && <HeaderMobileSecondaryNav onClick={props.onFinish} />}
    </HeaderMobileSubMenuWrapper>
  );
};

export default HeaderMobileSubMenu;
