import React from 'react';
import { Icon } from './icon-types';
import { colors } from 'src/styling/constants';

const Cross: Icon = ({ fill = colors.darkGreen }) => (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.68164 6.375L12.6895 10.3828C12.9121 10.6055 12.9121 11.0137 12.6895 11.2363L11.7617 12.1641C11.5391 12.3867 11.1309 12.3867 10.9082 12.1641L6.9375 8.15625L2.92969 12.1641C2.70703 12.3867 2.29883 12.3867 2.07617 12.1641L1.14844 11.2363C0.925781 11.0137 0.925781 10.6055 1.14844 10.3828L5.15625 6.375L1.14844 2.4043C0.925781 2.18164 0.925781 1.77344 1.14844 1.55078L2.07617 0.623047C2.29883 0.400391 2.70703 0.400391 2.92969 0.623047L6.9375 4.63086L10.9082 0.623047C11.1309 0.400391 11.5391 0.400391 11.7617 0.623047L12.6895 1.55078C12.9121 1.77344 12.9121 2.18164 12.6895 2.4043L8.68164 6.375Z"
      fillOpacity="0.7"
      fill={fill}
    />
  </svg>
);

export default Cross;
