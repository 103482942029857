import React, { PropsWithChildren } from 'react';
import { SnippetsProps } from './snippets';
import GlobalContentProvider from 'src/content/global-content-provider';
import GlobalPageLayoutContent from 'src/components/layout/global-page-layout-content';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import 'src/styling/global.css';

type GlobalPageLayoutProps = PropsWithChildren<{
  shouldShowFooter: boolean;
  snippets?: SnippetsProps;
}>;

const GlobalPageLayout = (props: GlobalPageLayoutProps) => {
  return (
    <GlobalContentProvider>
      <GlobalPageLayoutContent {...props} />
    </GlobalContentProvider>
  );
};

export default GlobalPageLayout;
