import { EUWA } from '@puzzel/euwa-wrapper';
import { getPuzzelConfig } from 'src/helpers/config-helper';
import { getClubByHyphenatedClubName } from 'src/content/club-look-ups-by-id';

let euwa: any;
let variablesCapturedBeforeInitialisation: ChatVariables;

export const init = () => {
  euwa = new EUWA(getPuzzelConfig());
  if (variablesCapturedBeforeInitialisation) {
    updateChatVariables(variablesCapturedBeforeInitialisation);
  }
  return;
};

interface ChatVariables {
  page?: string;
}

interface PuzzelChatVariables {
  'Page'?: string;
  'Club name'?: string;
}

export const updateChatVariables = (variables: ChatVariables) => {
  if (!euwa) {
    variablesCapturedBeforeInitialisation = variables;
    return;
  }
  euwa.getApplication(EUWA.APPS.CHAT).then((chat: any) => {
    let chatVariables: PuzzelChatVariables = {};
    if (variables.page) {
      chatVariables['Page'] = variables.page;
      const regex = '/clubs/([^/]*)/';
      const hyphenatedClubName = variables.page.match(regex);
      if (hyphenatedClubName) {
        chatVariables['Club name'] = getClubByHyphenatedClubName(hyphenatedClubName[1])?.clubName;
      }
    }
    chat.api.updateVariables(chatVariables);
  });
};

export default { init };
