import React from 'react';
import { IconProps } from './icon-types';

export const FlagGermany = ({ size }: IconProps) => (
  <svg
    viewBox="0 0 126 126"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: size, height: size, borderRadius: size }}
  >
    <g>
      <path d="M125.854 -14.4082H0.00390625V140.263H125.854V-14.4082Z" fill="#FFCA05" />
      <path d="M125.857 -14.4082H0.00390625V88.7095H125.857V-14.4082Z" fill="#EC1C24" />
      <path d="M125.857 -14.4043H0.00390625V37.1527H125.857V-14.4043Z" fill="#231F20" />
    </g>
  </svg>
);
