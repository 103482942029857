export const group = <TItem, TKey>(
  items: readonly TItem[],
  getGroupKey: (item: TItem) => TKey
): Map<TKey, [TItem, ...TItem[]]> =>
  items.reduce((itemsByGroup, item) => {
    const groupKey = getGroupKey(item);
    const currentGroup = itemsByGroup.get(groupKey);
    if (currentGroup) {
      currentGroup.push(item);
    } else {
      itemsByGroup.set(groupKey, [item]);
    }
    return itemsByGroup;
  }, new Map());
