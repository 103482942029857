import { CountriesToSplitIntoRegions, Country } from './country';
import { City } from './city';
import { Region } from './region';
import { BuildType } from './build/build-type';
import { ProdBuildState } from './build/prod-build-state';
import { ClubIntroImageKey } from './club-intro-image';
import { Language } from './language';

// If you are changing this file then you may also want to change clubs-helper.ts in the Sales Team App

type ClubCountriesThatMustContainRegionInformation = CountriesToSplitIntoRegions;

type ClubLocationInformation = {
  city?: City;
} & (
  | { country: ClubCountriesThatMustContainRegionInformation; region: Region }
  | {
      country: Exclude<Country, ClubCountriesThatMustContainRegionInformation>;
      region: Region | null;
    }
);

export type ClubInformation = {
  siteId: string;
  hyphenatedClubName: string;
  buildType: BuildType;
  clubName: string;
  prodBuildState: ProdBuildState;
  introImageKey?: ClubIntroImageKey;
  languagesOverride?: Language[];
} & ClubLocationInformation;

export const CLUBS_INFORMATION: ClubInformation[] = [
  {
    siteId: '1',
    hyphenatedClubName: 'heston',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Heston',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_GREATER_LONDON,
    city: City.LONDON,
  },
  {
    siteId: '2',
    hyphenatedClubName: 'raynes-park',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Raynes Park',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_GREATER_LONDON,
    city: City.LONDON,
  },
  {
    siteId: '3',
    hyphenatedClubName: 'finchley',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Finchley',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_GREATER_LONDON,
    city: City.LONDON,
  },
  {
    siteId: '4',
    hyphenatedClubName: 'bushey',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Bushey',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_GREATER_LONDON,
    city: City.LONDON,
  },
  {
    siteId: '5',
    hyphenatedClubName: 'chigwell',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Chigwell',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_GREATER_LONDON,
    city: City.LONDON,
  },
  {
    siteId: '7',
    hyphenatedClubName: 'eastbourne',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Eastbourne',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_SOUTH_EAST,
  },
  {
    siteId: '8',
    hyphenatedClubName: 'enfield',
    buildType: BuildType.DAVID_LLOYD_UK,
    country: Country.UNITED_KINGDOM,
    clubName: 'Enfield',
    prodBuildState: ProdBuildState.ALL,
    region: Region.UNITED_KINGDOM_GREATER_LONDON,
    city: City.LONDON,
  },
  {
    siteId: '10',
    hyphenatedClubName: 'glasgow-renfrew',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Glasgow Renfrew',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_SCOTLAND,
    city: City.GLASGOW,
  },
  {
    siteId: '14',
    hyphenatedClubName: 'birmingham',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Birmingham',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_MIDLANDS,
  },
  {
    siteId: '15',
    hyphenatedClubName: 'bristol-long-ashton',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Bristol Long Ashton',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_SOUTH_WEST,
    city: City.BRISTOL,
  },
  {
    siteId: '17',
    hyphenatedClubName: 'ringwood',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Ringwood',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_SOUTH,
  },
  {
    siteId: '18',
    hyphenatedClubName: 'reading',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Reading',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_SOUTH_WEST,
  },
  {
    siteId: '19',
    hyphenatedClubName: 'cardiff',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Cardiff',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_WALES,
  },
  {
    siteId: '20',
    hyphenatedClubName: 'newcastle',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Newcastle',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_NORTH_EAST,
  },
  {
    siteId: '21',
    hyphenatedClubName: 'sidcup',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Sidcup',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_GREATER_LONDON,
  },
  {
    siteId: '22',
    hyphenatedClubName: 'stevenage',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Stevenage',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_EAST,
  },
  {
    siteId: '23',
    hyphenatedClubName: 'cheadle',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Cheadle',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_NORTH_WEST,
    city: City.MANCHESTER,
  },
  {
    siteId: '24',
    hyphenatedClubName: 'leeds',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Leeds',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_NORTH_EAST,
  },
  {
    siteId: '25',
    hyphenatedClubName: 'milton-keynes',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Milton Keynes',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_MIDLANDS,
  },
  {
    siteId: '28',
    hyphenatedClubName: 'maidstone',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Maidstone',
    prodBuildState: ProdBuildState.NONE,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_SOUTH_EAST,
  },
  {
    siteId: '29',
    hyphenatedClubName: 'brighton',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Brighton',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_SOUTH,
  },
  {
    siteId: '30',
    hyphenatedClubName: 'leicester-meridian',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Leicester Meridian',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_MIDLANDS,
    city: City.LEICESTER,
  },
  {
    siteId: '31',
    hyphenatedClubName: 'derby',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Derby',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_MIDLANDS,
  },
  {
    siteId: '32',
    hyphenatedClubName: 'hull',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Hull',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_NORTH_EAST,
  },
  {
    siteId: '33',
    hyphenatedClubName: 'basildon',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Basildon',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_SOUTH_EAST,
  },
  {
    siteId: '34',
    hyphenatedClubName: 'teesside',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Teesside',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_NORTH_EAST,
  },
  {
    siteId: '35',
    hyphenatedClubName: 'kensington',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Kensington',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_GREATER_LONDON,
    city: City.LONDON,
  },
  {
    siteId: '36',
    hyphenatedClubName: 'nottingham',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Nottingham',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_MIDLANDS,
    city: City.NOTTINGHAM,
  },
  {
    siteId: '37',
    hyphenatedClubName: 'liverpool-knowsley',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Liverpool Knowsley',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_NORTH_WEST,
    city: City.LIVERPOOL,
  },
  {
    siteId: '38',
    hyphenatedClubName: 'warrington',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Warrington',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_NORTH_WEST,
  },
  {
    siteId: '39',
    hyphenatedClubName: 'bolton',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Bolton',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_NORTH_WEST,
  },
  {
    siteId: '40',
    hyphenatedClubName: 'belfast',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Belfast',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_NOTHERN_IRELAND,
  },
  {
    siteId: '41',
    hyphenatedClubName: 'liverpool-speke',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Liverpool Speke',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_NORTH_WEST,
    city: City.LIVERPOOL,
  },
  {
    siteId: '42',
    hyphenatedClubName: 'edinburgh-corstorphine',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Edinburgh Corstorphine',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_SCOTLAND,
    city: City.EDINBURGH,
  },
  {
    siteId: '43',
    hyphenatedClubName: 'southampton',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Southampton',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_SOUTH,
    city: City.SOUTHAMPTON,
  },
  {
    siteId: '44',
    hyphenatedClubName: 'kidbrooke-village',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Kidbrooke Village',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_GREATER_LONDON,
    city: City.LONDON,
  },
  {
    siteId: '45',
    hyphenatedClubName: 'weybridge-brooklands',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Weybridge Brooklands',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_GREATER_LONDON,
    city: City.LONDON,
  },
  {
    siteId: '46',
    hyphenatedClubName: 'cheshire-oaks',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Cheshire Oaks',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_NORTH_WEST,
  },
  {
    siteId: '47',
    hyphenatedClubName: 'sudbury-hill',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Sudbury Hill',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_GREATER_LONDON,
    city: City.LONDON,
  },
  {
    siteId: '48',
    hyphenatedClubName: 'manchester-trafford-city',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Manchester Trafford City',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_NORTH_WEST,
    city: City.MANCHESTER,
  },
  {
    siteId: '49',
    hyphenatedClubName: 'nottingham-west-bridgford',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Nottingham West Bridgford',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_MIDLANDS,
    city: City.NOTTINGHAM,
  },
  {
    siteId: '50',
    hyphenatedClubName: 'solihull-cranmore',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Solihull Cranmore',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_MIDLANDS,
  },
  {
    siteId: '51',
    hyphenatedClubName: 'dublin-riverview',
    buildType: BuildType.DAVID_LLOYD_IRELAND,
    clubName: 'Dublin Riverview',
    prodBuildState: ProdBuildState.ALL,
    country: Country.IRELAND,
    region: null,
    introImageKey: 'dublinRiverviewIntroClubImage',
  },
  {
    siteId: '52',
    hyphenatedClubName: 'maidenhead',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Maidenhead',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_SOUTH_WEST,
  },
  {
    siteId: '53',
    hyphenatedClubName: 'port-solent',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Port Solent',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_SOUTH,
  },
  {
    siteId: '54',
    hyphenatedClubName: 'luton',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Luton',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_GREATER_LONDON,
  },
  {
    siteId: '55',
    hyphenatedClubName: 'cheam',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Cheam',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_GREATER_LONDON,
    city: City.LONDON,
  },
  {
    siteId: '56',
    hyphenatedClubName: 'epsom',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Epsom',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_GREATER_LONDON,
    city: City.LONDON,
  },
  {
    siteId: '57',
    hyphenatedClubName: 'bromsgrove',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Bromsgrove',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_MIDLANDS,
  },
  {
    siteId: '58',
    hyphenatedClubName: 'fulham',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Fulham',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_GREATER_LONDON,
    city: City.LONDON,
  },
  {
    siteId: '59',
    hyphenatedClubName: 'kingston',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Kingston',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_GREATER_LONDON,
    city: City.LONDON,
  },
  {
    siteId: '60',
    hyphenatedClubName: 'woking',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Woking',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_SOUTH,
  },
  {
    siteId: '61',
    hyphenatedClubName: 'lincoln',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Lincoln',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_EAST,
  },
  {
    siteId: '63',
    hyphenatedClubName: 'oxford',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Oxford',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_SOUTH_WEST,
  },
  {
    siteId: '64',
    hyphenatedClubName: 'worthing',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Worthing',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_SOUTH,
  },
  {
    siteId: '66',
    hyphenatedClubName: 'kings-hill',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Kings Hill',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_SOUTH_EAST,
  },
  {
    siteId: '67',
    hyphenatedClubName: 'exeter',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Exeter',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_SOUTH_WEST,
  },
  {
    siteId: '68',
    hyphenatedClubName: 'southend',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Southend',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_SOUTH_EAST,
  },
  {
    siteId: '69',
    hyphenatedClubName: 'aberdeen',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Aberdeen',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_SCOTLAND,
  },
  {
    siteId: '70',
    hyphenatedClubName: 'windsor',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Windsor',
    prodBuildState: ProdBuildState.NONE,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_GREATER_LONDON,
  },
  {
    siteId: '72',
    hyphenatedClubName: 'swindon',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Swindon',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_SOUTH_WEST,
  },
  {
    siteId: '73',
    hyphenatedClubName: 'edinburgh-newhaven-harbour',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Edinburgh Newhaven Harbour',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_SCOTLAND,
    city: City.EDINBURGH,
  },
  {
    siteId: '74',
    hyphenatedClubName: 'dundee',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Dundee',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_SCOTLAND,
  },
  {
    siteId: '75',
    hyphenatedClubName: 'glasgow-west-end',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Glasgow West End',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_SCOTLAND,
    city: City.GLASGOW,
  },
  {
    siteId: '76',
    hyphenatedClubName: 'chorley',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Chorley',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_NORTH_WEST,
  },
  {
    siteId: '77',
    hyphenatedClubName: 'dudley',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Dudley',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_MIDLANDS,
  },
  {
    siteId: '78',
    hyphenatedClubName: 'ipswich',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Ipswich',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_EAST,
  },
  {
    siteId: '79',
    hyphenatedClubName: 'bristol-westbury',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Bristol Westbury',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_SOUTH_WEST,
    city: City.BRISTOL,
  },
  {
    siteId: '80',
    hyphenatedClubName: 'dartford',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Dartford',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_GREATER_LONDON,
  },
  {
    siteId: '81',
    hyphenatedClubName: 'york',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'York',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_NORTH_EAST,
  },
  {
    siteId: '82',
    hyphenatedClubName: 'cambridge',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Cambridge',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_EAST,
  },
  {
    siteId: '83',
    hyphenatedClubName: 'hatfield',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Hatfield',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_GREATER_LONDON,
  },
  {
    siteId: '84',
    hyphenatedClubName: 'gidea-park',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Gidea Park',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_GREATER_LONDON,
    city: City.LONDON,
  },
  {
    siteId: '85',
    hyphenatedClubName: 'leicester-narborough',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Leicester Narborough',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_MIDLANDS,
    city: City.LEICESTER,
  },
  {
    siteId: '87',
    hyphenatedClubName: 'beaconsfield',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Beaconsfield',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_GREATER_LONDON,
    city: City.LONDON,
  },
  {
    siteId: '88',
    hyphenatedClubName: 'beckenham',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Beckenham',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_GREATER_LONDON,
    city: City.LONDON,
  },
  {
    siteId: '89',
    hyphenatedClubName: 'hampton',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Hampton',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_GREATER_LONDON,
    city: City.LONDON,
  },
  {
    siteId: '90',
    hyphenatedClubName: 'chelsea',
    buildType: BuildType.HARBOUR,
    clubName: 'Chelsea',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_GREATER_LONDON,
    introImageKey: 'chelseaIntroClubImage',
    city: City.LONDON,
  },
  {
    siteId: '91',
    hyphenatedClubName: 'notting-hill',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Notting Hill',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_GREATER_LONDON,
    introImageKey: 'nottingHillIntroClubImage',
    city: City.LONDON,
  },
  {
    siteId: '92',
    hyphenatedClubName: 'rotterdam-blijdorp',
    buildType: BuildType.DAVID_LLOYD_NETHERLANDS,
    clubName: 'Rotterdam Blijdorp',
    prodBuildState: ProdBuildState.ALL,
    country: Country.NETHERLANDS,
    region: null,
    city: City.ROTTERDAM,
    introImageKey: 'rotterdamBlijdorpIntroClubImage',
  },
  {
    siteId: '93',
    hyphenatedClubName: 'amsterdam',
    buildType: BuildType.DAVID_LLOYD_NETHERLANDS,
    clubName: 'Amsterdam',
    prodBuildState: ProdBuildState.ALL,
    country: Country.NETHERLANDS,
    region: null,
    introImageKey: 'amsterdamIntroClubImage',
  },
  {
    siteId: '94',
    hyphenatedClubName: 'capelle',
    buildType: BuildType.DAVID_LLOYD_NETHERLANDS,
    clubName: 'Capelle',
    prodBuildState: ProdBuildState.ALL,
    country: Country.NETHERLANDS,
    region: null,
    city: City.ROTTERDAM,
    introImageKey: 'capelleIntroClubImage',
  },
  {
    siteId: '96',
    hyphenatedClubName: 'rotterdam-centrum',
    buildType: BuildType.DAVID_LLOYD_NETHERLANDS,
    clubName: 'Rotterdam Centrum',
    prodBuildState: ProdBuildState.ALL,
    country: Country.NETHERLANDS,
    region: null,
    city: City.ROTTERDAM,
    introImageKey: 'rotterdamCentrumIntroClubImage',
  },
  {
    siteId: '97',
    hyphenatedClubName: 'utrecht',
    buildType: BuildType.DAVID_LLOYD_NETHERLANDS,
    clubName: 'Utrecht',
    prodBuildState: ProdBuildState.ALL,
    country: Country.NETHERLANDS,
    region: null,
    introImageKey: 'utrechtIntroClubImage',
  },
  {
    siteId: '98',
    hyphenatedClubName: 'eindhoven',
    buildType: BuildType.DAVID_LLOYD_NETHERLANDS,
    clubName: 'Eindhoven',
    prodBuildState: ProdBuildState.ALL,
    country: Country.NETHERLANDS,
    region: null,
    introImageKey: 'eindhovenIntroClubImage',
  },
  {
    siteId: '99',
    hyphenatedClubName: 'uccle',
    buildType: BuildType.DAVID_LLOYD_BELGIUM,
    clubName: 'Uccle',
    prodBuildState: ProdBuildState.ALL,
    country: Country.BELGIUM,
    region: null,
    city: City.BRUSSELS,
    introImageKey: 'uccleIntroClubImage',
  },
  {
    siteId: '100',
    hyphenatedClubName: 'turo',
    buildType: BuildType.DAVID_LLOYD_SPAIN,
    clubName: 'Turo',
    prodBuildState: ProdBuildState.ALL,
    country: Country.SPAIN,
    region: null,
    city: City.BARCELONA,
    introImageKey: 'turoIntroClubImage',
  },
  {
    siteId: '101',
    hyphenatedClubName: 'farnham',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Farnham',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_SOUTH,
  },
  {
    siteId: '103',
    hyphenatedClubName: 'worcester',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Worcester',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_SOUTH_WEST,
  },
  {
    siteId: '104',
    hyphenatedClubName: 'newbury',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Newbury',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_SOUTH_WEST,
  },
  {
    siteId: '105',
    hyphenatedClubName: 'colchester',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Colchester',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_SOUTH_EAST,
  },
  {
    siteId: '106',
    hyphenatedClubName: 'antwerp',
    buildType: BuildType.DAVID_LLOYD_BELGIUM,
    clubName: 'Antwerp',
    prodBuildState: ProdBuildState.ALL,
    country: Country.BELGIUM,
    region: null,
    introImageKey: 'antwerpIntroClubImage',
  },
  {
    siteId: '107',
    hyphenatedClubName: 'glasgow-rouken-glen',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Glasgow Rouken Glen',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_SCOTLAND,
    city: City.GLASGOW,
  },
  {
    siteId: '108',
    hyphenatedClubName: 'aravaca',
    buildType: BuildType.DAVID_LLOYD_SPAIN,
    clubName: 'Aravaca',
    prodBuildState: ProdBuildState.ALL,
    country: Country.SPAIN,
    region: null,
    city: City.MADRID,
    introImageKey: 'aravacaIntroClubImage',
    languagesOverride: [Language.ES_ES, Language.EN_ES],
  },
  {
    siteId: '111',
    hyphenatedClubName: 'coventry',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Coventry',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_MIDLANDS,
  },
  {
    siteId: '112',
    hyphenatedClubName: 'gloucester',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Gloucester',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_MIDLANDS,
  },
  {
    siteId: '113',
    hyphenatedClubName: 'hamilton',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Hamilton',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_SCOTLAND,
    city: City.GLASGOW,
  },
  {
    siteId: '114',
    hyphenatedClubName: 'lichfield',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Lichfield',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_MIDLANDS,
  },
  {
    siteId: '115',
    hyphenatedClubName: 'swansea',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Swansea',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_WALES,
  },
  {
    siteId: '116',
    hyphenatedClubName: 'manchester-north',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Manchester North',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_NORTH_WEST,
    city: City.MANCHESTER,
  },
  {
    siteId: '117',
    hyphenatedClubName: 'northwood',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Northwood',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_GREATER_LONDON,
    city: City.LONDON,
  },
  {
    siteId: '118',
    hyphenatedClubName: 'norwich',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Norwich',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_EAST,
  },
  {
    siteId: '119',
    hyphenatedClubName: 'poole',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Poole',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_SOUTH_WEST,
  },
  {
    siteId: '120',
    hyphenatedClubName: 'royal-berkshire',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Royal Berkshire',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_SOUTH_WEST,
  },
  {
    siteId: '121',
    hyphenatedClubName: 'southampton-west-end',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Southampton West End',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_SOUTH,
    city: City.SOUTHAMPTON,
  },
  {
    siteId: '122',
    hyphenatedClubName: 'sunderland',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Sunderland',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_NORTH_EAST,
  },
  {
    siteId: '123',
    hyphenatedClubName: 'peterborough',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Peterborough',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_EAST,
  },
  {
    siteId: '124',
    hyphenatedClubName: 'purley',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Purley',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_GREATER_LONDON,
    city: City.LONDON,
  },
  {
    siteId: '125',
    hyphenatedClubName: 'acton-park',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Acton Park',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_GREATER_LONDON,
    city: City.LONDON,
  },
  {
    siteId: '126',
    hyphenatedClubName: 'harrogate',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Harrogate',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_NORTH_EAST,
  },
  {
    siteId: '127',
    hyphenatedClubName: 'malaspina',
    buildType: BuildType.DAVID_LLOYD_ITALY,
    clubName: 'Malaspina',
    prodBuildState: ProdBuildState.ALL,
    country: Country.ITALY,
    region: null,
    introImageKey: 'malaspinaIntroClubImage',
  },
  {
    siteId: '128',
    hyphenatedClubName: 'veigy',
    buildType: BuildType.DAVID_LLOYD_FRANCE,
    clubName: 'Veigy',
    prodBuildState: ProdBuildState.ALL,
    country: Country.FRANCE,
    region: null,
    introImageKey: 'veigyIntroClubImage',
  },
  {
    siteId: '129',
    hyphenatedClubName: 'bad-homburg',
    buildType: BuildType.DAVID_LLOYD_GERMANY,
    clubName: 'Bad Homburg',
    prodBuildState: ProdBuildState.ALL,
    country: Country.GERMANY,
    region: null,
    introImageKey: 'badHomburgIntroClubImage',
  },
  {
    siteId: '130',
    hyphenatedClubName: 'bristol-emersons-green',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Bristol Emersons Green',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_SOUTH_WEST,
    city: City.BRISTOL,
  },
  {
    siteId: '131',
    hyphenatedClubName: 'birmingham-blaze-studio',
    buildType: BuildType.BLAZE_STUDIO,
    clubName: 'BLAZE Studio Birmingham',
    prodBuildState: ProdBuildState.NONE,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_MIDLANDS,
  },
  {
    siteId: '132',
    hyphenatedClubName: 'bicester',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Bicester',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_SOUTH_WEST,
  },
  {
    siteId: '133',
    hyphenatedClubName: 'hamburg-wandsbek',
    buildType: BuildType.MERIDIAN,
    clubName: 'Hamburg Wandsbek',
    prodBuildState: ProdBuildState.ALL,
    country: Country.GERMANY,
    region: null,
    city: City.HAMBURG,
    introImageKey: 'wandsbekIntroClubImage',
  },
  {
    siteId: '134',
    hyphenatedClubName: 'hamburg-am-michel',
    buildType: BuildType.MERIDIAN,
    clubName: 'Hamburg Am Michel',
    prodBuildState: ProdBuildState.ALL,
    country: Country.GERMANY,
    region: null,
    city: City.HAMBURG,
    introImageKey: 'amMichelIntroClubImage',
  },
  {
    siteId: '135',
    hyphenatedClubName: 'hamburg-eppendorf',
    buildType: BuildType.MERIDIAN,
    clubName: 'Hamburg Eppendorf',
    prodBuildState: ProdBuildState.ALL,
    country: Country.GERMANY,
    region: null,
    city: City.HAMBURG,
    introImageKey: 'eppendorfIntroClubImage',
  },
  {
    siteId: '136',
    hyphenatedClubName: 'hamburg-barmbek',
    buildType: BuildType.MERIDIAN,
    clubName: 'Hamburg Barmbek',
    prodBuildState: ProdBuildState.ALL,
    country: Country.GERMANY,
    region: null,
    city: City.HAMBURG,
    introImageKey: 'barmbekIntroClubImage',
  },
  {
    siteId: '137',
    hyphenatedClubName: 'berlin-spandau-arcaden',
    buildType: BuildType.MERIDIAN,
    clubName: 'Berlin Spandau Arcaden',
    prodBuildState: ProdBuildState.ALL,
    country: Country.GERMANY,
    region: null,
    introImageKey: 'berlinSpandauIntroClubImage',
  },
  {
    siteId: '138',
    hyphenatedClubName: 'frankfurt-skyline-plaza',
    buildType: BuildType.DAVID_LLOYD_GERMANY,
    clubName: 'Frankfurt Skyline Plaza',
    prodBuildState: ProdBuildState.ALL,
    country: Country.GERMANY,
    region: null,
    introImageKey: 'frankfurtIntroClubImage',
  },
  {
    siteId: '139',
    hyphenatedClubName: 'hamburg-alstertal',
    buildType: BuildType.MERIDIAN,
    clubName: 'Hamburg Alstertal',
    prodBuildState: ProdBuildState.ALL,
    country: Country.GERMANY,
    region: null,
    city: City.HAMBURG,
    introImageKey: 'alstertalIntroClubImage',
  },
  {
    siteId: '140',
    hyphenatedClubName: 'kiel-sophienhof',
    buildType: BuildType.MERIDIAN,
    clubName: 'Kiel Sophienhof',
    prodBuildState: ProdBuildState.NONE,
    country: Country.GERMANY,
    region: null,
    introImageKey: 'kielSophienhofIntroClubImage',
  },
  {
    siteId: '143',
    hyphenatedClubName: 'country-club-geneva',
    buildType: BuildType.DAVID_LLOYD_SWITZERLAND,
    clubName: 'Country Club Geneva',
    prodBuildState: ProdBuildState.ALL,
    country: Country.SWITZERLAND,
    region: null,
    introImageKey: 'countryClubGenevaIntroClubImage',
  },
  {
    siteId: '153',
    hyphenatedClubName: 'gava-mar',
    buildType: BuildType.DAVID_LLOYD_SPAIN,
    clubName: 'Gavà Mar',
    prodBuildState: ProdBuildState.ALL,
    country: Country.SPAIN,
    region: null,
    city: City.BARCELONA,
    introImageKey: 'gavaMarIntroClubImage',
  },
  {
    siteId: '154',
    hyphenatedClubName: 'zaragoza',
    buildType: BuildType.DAVID_LLOYD_SPAIN,
    clubName: 'Zaragoza',
    prodBuildState: ProdBuildState.ALL,
    country: Country.SPAIN,
    region: null,
    introImageKey: 'zaragozaIntroClubImage',
    languagesOverride: [Language.ES_ES, Language.EN_ES],
  },
  {
    siteId: '155',
    hyphenatedClubName: 'malaga',
    buildType: BuildType.DAVID_LLOYD_SPAIN,
    clubName: 'Málaga',
    prodBuildState: ProdBuildState.ALL,
    country: Country.SPAIN,
    region: null,
    introImageKey: 'malagaIntroClubImage',
    languagesOverride: [Language.ES_ES, Language.EN_ES],
  },
  {
    siteId: '156',
    hyphenatedClubName: 'edinburgh-shawfair',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Edinburgh Shawfair',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_SCOTLAND,
    city: City.EDINBURGH,
  },
  {
    siteId: '157',
    hyphenatedClubName: 'cricklewood-lane',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Cricklewood Lane',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_GREATER_LONDON,
    city: City.LONDON,
  },
  {
    siteId: '158',
    hyphenatedClubName: 'rugby',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Rugby',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_MIDLANDS,
  },
  {
    siteId: '159',
    hyphenatedClubName: 'modena',
    buildType: BuildType.DAVID_LLOYD_ITALY,
    clubName: 'Modena',
    prodBuildState: ProdBuildState.ALL,
    country: Country.ITALY,
    region: null,
    introImageKey: 'modenaIntroClubImage',
  },
  {
    siteId: '160',
    hyphenatedClubName: 'sterrebeek',
    buildType: BuildType.DAVID_LLOYD_BELGIUM,
    clubName: 'Sterrebeek',
    prodBuildState: ProdBuildState.ALL,
    country: Country.BELGIUM,
    region: null,
    city: City.BRUSSELS,
    introImageKey: 'sterrebeekIntroClubImage',
  },
  {
    siteId: '161',
    hyphenatedClubName: 'boadilla',
    buildType: BuildType.DAVID_LLOYD_SPAIN,
    clubName: 'Boadilla',
    prodBuildState: ProdBuildState.ALL,
    country: Country.SPAIN,
    region: null,
    city: City.MADRID,
    introImageKey: 'boadillaIntroClubImage',
    languagesOverride: [Language.ES_ES, Language.EN_ES],
  },
  {
    siteId: '162',
    hyphenatedClubName: 'la-finca',
    buildType: BuildType.DAVID_LLOYD_SPAIN,
    clubName: 'La Finca',
    prodBuildState: ProdBuildState.ALL,
    country: Country.SPAIN,
    region: null,
    city: City.MADRID,
    introImageKey: 'laFincaIntroClubImage',
    languagesOverride: [Language.ES_ES, Language.EN_ES],
  },
  {
    siteId: '163',
    hyphenatedClubName: 'serrano',
    buildType: BuildType.DAVID_LLOYD_SPAIN,
    clubName: 'Serrano',
    prodBuildState: ProdBuildState.ALL,
    country: Country.SPAIN,
    region: null,
    city: City.MADRID,
    introImageKey: 'serranoIntroClubImage',
    languagesOverride: [Language.ES_ES, Language.EN_ES],
  },
  {
    siteId: '164',
    hyphenatedClubName: 'bury-st-edmunds',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Bury St Edmunds',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_EAST,
  },
  {
    siteId: '165',
    hyphenatedClubName: 'wickwoods',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Wickwoods',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_SOUTH_EAST,
  },
  {
    siteId: '167',
    hyphenatedClubName: 'shrewsbury',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Shrewsbury',
    prodBuildState: ProdBuildState.ALL,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_MIDLANDS,
  },
  {
    siteId: '168',
    hyphenatedClubName: 'herne-bay',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Herne Bay',
    prodBuildState: ProdBuildState.NO_JOIN_ONLINE,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_SOUTH_EAST,
  },
  {
    siteId: '169',
    hyphenatedClubName: 'ashford',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Ashford',
    prodBuildState: ProdBuildState.NO_JOIN_ONLINE,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_SOUTH_EAST,
  },
  {
    siteId: '170',
    hyphenatedClubName: 'harlow',
    buildType: BuildType.DAVID_LLOYD_UK,
    clubName: 'Harlow',
    prodBuildState: ProdBuildState.NO_JOIN_ONLINE,
    country: Country.UNITED_KINGDOM,
    region: Region.UNITED_KINGDOM_EAST,
  },
];
