export enum JourneyType {
  NORMAL = 'NORMAL',
  CORPORATE = 'CORPORATE',
  TDR_CAPITAL = 'TDR_CAPITAL',
  FRIENDS_AND_FAMILY = 'FRIENDS_AND_FAMILY',
  JOIN_IN_CLUB = 'JOIN_IN_CLUB',
  TRIAL = 'TRIAL',
}

export const isCorporateJourneyType = (journeyType: JourneyType) => {
  return journeyType === JourneyType.CORPORATE || journeyType === JourneyType.TDR_CAPITAL;
};
