import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { GlobalSharedContent } from 'src/models/global/global-shared-content';
import {
  CmsGlobalFileNode,
  findCmsFileNodeForBrand,
  parseRelativeDirectory,
} from 'src/helpers/cms-file-helper';
import { SiteContext } from 'src/context/site-context';
import { FooterContent, NewFooterContent } from 'src/models/cms/footer-content';
import { useMemo } from 'react';

const globalCmsContentQuery = graphql`
  fragment _GlobalSharedContent on GlobalJson {
    sitecoreToGatsbySiteDesignRolloutPercentage
    oldToNewGatsbySiteDesignRolloutPercentage
    mobileApp {
      title
      description
      image {
        imagePath
        alternateText
        horizontalFocus
        leftHighlight
      }
    }
    nextStep {
      title
      description
      button {
        showButton
        buttonText
        buttonLink
        goToGlobal
        buttonStyle
      }
      secondaryButton {
        showButton
        buttonText
        buttonLink
        goToGlobal
        buttonStyle
      }
    }
    atGlobalUnit {
      showBlock
      title
      description
      button {
        showButton
        buttonText
        buttonLink
        goToGlobal
        buttonStyle
      }
      imagesWithLink {
        image {
          imagePath
          alternateText
          horizontalFocus
          leftHighlight
        }
        link
        title
        description
      }
    }
    navigationPagesToDisplay {
      mainPages {
        displayText
        link
      }
      secondaryPages {
        displayText
        link
      }
    }
    unitLocation {
      addressLine1
      addressLine2
      mapUrl
    }
    enquireNowButton {
      enquireNowButtonText
      enquireNowButtonLink
      goToGlobal
    }
    linkDropdownCta {
      enabled
      text
      dropdownOptions {
        displayText
        link
      }
    }
  }

  fragment _GlobalFooterContent on GlobalJson {
    navMenuSections {
      title
      links {
        text
        url
        openInNewTab
      }
    }
    footerImagesSections {
      title
      images {
        image {
          imagePath
          alternateText
          horizontalFocus
          leftHighlight
        }
        size
        link
        setToExpire
        expiryDate
      }
    }
    copyrightInformation
    vatInformation
    registeredCompanyInformation
    imagesDisclaimer
  }

  query GlobalCmsContentQuery {
    sharedContent: allFile(filter: { base: { eq: "global-shared.json" } }) {
      nodes {
        relativeDirectory
        childGlobalJson {
          ..._GlobalSharedContent
        }
      }
    }
    footerContent: allFile(filter: { base: { eq: "footer.json" } }) {
      nodes {
        relativeDirectory
        childGlobalJson {
          ..._GlobalFooterContent
        }
      }
    }
    newFooterContent: allFile(filter: { base: { eq: "footer-redesign.json" } }) {
      nodes {
        relativeDirectory
        childGlobalJson {
          ..._GlobalFooterRedesignFragment
        }
      }
    }
    headerContent: allFile(filter: { base: { eq: "header.json" } }) {
      nodes {
        relativeDirectory
        childGlobalJson {
          ..._GlobalHeaderContent
        }
      }
    }
  }
`;

interface WrappedGlobalCmsRecord<T> {
  sharedContent: T;
  footerContent: T;
}

export interface GlobalCmsRecord {
  sharedContent: GlobalSharedContent;
  headerContent: {
    headerMenuItems?: Queries.GlobalHeaderLevelOneMenuItem[];
    headerSecondaryUpperMenu?: Queries.GlobalHeaderSecondaryUpperMenuItem[];
    headerPromoRibbon?: Queries.GlobalHeaderPromoRibbon;
    headerButton?: Queries.RedesignButton;
    headerOtherText?: Queries.GlobalHeaderOtherText;
    searchSettings?: Queries.GlobalHeaderSearchSettings;
  };
  footerContent: FooterContent;
  newFooterContent: NewFooterContent;
}

export const GlobalCmsProvider = ({
  children,
}: {
  children: (cmsContent: GlobalCmsRecord) => React.ReactNode;
}) => {
  const { language, buildType } = React.useContext(SiteContext);

  const cms =
    useStaticQuery<WrappedGlobalCmsRecord<{ nodes: CmsGlobalFileNode<any>[] }>>(
      globalCmsContentQuery
    );

  const cmsRecord = useMemo(() => {
    Object.keys(cms).forEach(
      (key: keyof WrappedGlobalCmsRecord<{ nodes: CmsGlobalFileNode<any>[] }>) => {
        cms[key].nodes.forEach(parseRelativeDirectory);
      }
    );

    return Object.keys(cms).reduce(
      (record, key: keyof WrappedGlobalCmsRecord<{ nodes: CmsGlobalFileNode<any>[] }>) => {
        const cmsNode = findCmsFileNodeForBrand(cms[key].nodes, language, buildType);

        record[key] = cmsNode ? cmsNode.childGlobalJson : null;

        return record;
      },
      {} as GlobalCmsRecord
    );
  }, [buildType, cms, language]);

  return children(cmsRecord);
};
