import React from 'react';

type ClosePickerUnderlayProps = {
  closePicker: () => void;
};

const ClosePickerUnderlay: React.FC<ClosePickerUnderlayProps> = ({ closePicker }) => (
  <div css={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0 }} onClick={closePicker} />
);

export default ClosePickerUnderlay;
