import { CSSInterpolation } from '@emotion/serialize';
import { BuildType } from 'src/enums/build/build-type';
import { fonts } from 'src/styling/constants';

export type Fonts = {
  light: CSSInterpolation;
  regular: CSSInterpolation;
  bold: CSSInterpolation;
};

export const getFonts = (buildType: BuildType) => {
  switch (buildType) {
    case BuildType.BLAZE_STUDIO:
      return {
        light: fonts.light,
        regular: fonts.blaze,
        bold: fonts.blazeHeader,
      } as Fonts;
    case BuildType.HARBOUR:
      return {
        light: fonts.harbour,
        regular: fonts.harbour,
        bold: fonts.harbourBold,
      } as Fonts;
    case BuildType.MERIDIAN:
      return {
        light: fonts.meridianLight,
        regular: fonts.meridian,
        bold: fonts.meridianBold,
      } as Fonts;
    default:
      return {
        light: fonts.light,
        regular: fonts.regular,
        bold: fonts.bold,
      } as Fonts;
  }
};
