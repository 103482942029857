exports.components = {
  "component---src-page-templates-generic-content-page-tsx": () => import("./../../../src/page-templates/generic-content-page.tsx" /* webpackChunkName: "component---src-page-templates-generic-content-page-tsx" */),
  "component---src-page-templates-generic-enquire-now-page-tsx": () => import("./../../../src/page-templates/generic-enquire-now-page.tsx" /* webpackChunkName: "component---src-page-templates-generic-enquire-now-page-tsx" */),
  "component---src-page-templates-global-first-level-page-tsx": () => import("./../../../src/page-templates/global-first-level-page.tsx" /* webpackChunkName: "component---src-page-templates-global-first-level-page-tsx" */),
  "component---src-page-templates-global-generic-content-page-tsx": () => import("./../../../src/page-templates/global-generic-content-page.tsx" /* webpackChunkName: "component---src-page-templates-global-generic-content-page-tsx" */),
  "component---src-page-templates-global-generic-enquire-now-page-tsx": () => import("./../../../src/page-templates/global-generic-enquire-now-page.tsx" /* webpackChunkName: "component---src-page-templates-global-generic-enquire-now-page-tsx" */),
  "component---src-page-templates-global-generic-enquiry-success-page-tsx": () => import("./../../../src/page-templates/global-generic-enquiry-success-page.tsx" /* webpackChunkName: "component---src-page-templates-global-generic-enquiry-success-page-tsx" */),
  "component---src-page-templates-global-generic-policy-page-tsx": () => import("./../../../src/page-templates/global-generic-policy-page.tsx" /* webpackChunkName: "component---src-page-templates-global-generic-policy-page-tsx" */),
  "component---src-page-templates-global-press-centre-page-tsx": () => import("./../../../src/page-templates/global-press-centre-page.tsx" /* webpackChunkName: "component---src-page-templates-global-press-centre-page-tsx" */),
  "component---src-page-templates-global-second-level-page-tsx": () => import("./../../../src/page-templates/global-second-level-page.tsx" /* webpackChunkName: "component---src-page-templates-global-second-level-page-tsx" */),
  "component---src-page-templates-global-terms-and-conditions-page-tsx": () => import("./../../../src/page-templates/global-terms-and-conditions-page.tsx" /* webpackChunkName: "component---src-page-templates-global-terms-and-conditions-page-tsx" */),
  "component---src-page-templates-global-third-level-page-tsx": () => import("./../../../src/page-templates/global-third-level-page.tsx" /* webpackChunkName: "component---src-page-templates-global-third-level-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-login-tsx": () => import("./../../../src/pages/account/login.tsx" /* webpackChunkName: "component---src-pages-account-login-tsx" */),
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/admin.tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-book-your-club-tour-tsx": () => import("./../../../src/pages/book-your-club-tour.tsx" /* webpackChunkName: "component---src-pages-book-your-club-tour-tsx" */),
  "component---src-pages-club-trial-tsx": () => import("./../../../src/pages/club-trial.tsx" /* webpackChunkName: "component---src-pages-club-trial-tsx" */),
  "component---src-pages-clubs-blaze-tsx": () => import("./../../../src/pages/clubs/blaze.tsx" /* webpackChunkName: "component---src-pages-clubs-blaze-tsx" */),
  "component---src-pages-clubs-book-a-club-welcome-tsx": () => import("./../../../src/pages/clubs/book-a-club-welcome.tsx" /* webpackChunkName: "component---src-pages-clubs-book-a-club-welcome-tsx" */),
  "component---src-pages-clubs-book-a-tour-tsx": () => import("./../../../src/pages/clubs/book-a-tour.tsx" /* webpackChunkName: "component---src-pages-clubs-book-a-tour-tsx" */),
  "component---src-pages-clubs-club-facilities-creche-tsx": () => import("./../../../src/pages/clubs/club-facilities/creche.tsx" /* webpackChunkName: "component---src-pages-clubs-club-facilities-creche-tsx" */),
  "component---src-pages-clubs-club-facilities-exercise-classes-tsx": () => import("./../../../src/pages/clubs/club-facilities/exercise-classes.tsx" /* webpackChunkName: "component---src-pages-clubs-club-facilities-exercise-classes-tsx" */),
  "component---src-pages-clubs-club-facilities-gym-tsx": () => import("./../../../src/pages/clubs/club-facilities/gym.tsx" /* webpackChunkName: "component---src-pages-clubs-club-facilities-gym-tsx" */),
  "component---src-pages-clubs-club-facilities-kids-classes-tsx": () => import("./../../../src/pages/clubs/club-facilities/kids-classes.tsx" /* webpackChunkName: "component---src-pages-clubs-club-facilities-kids-classes-tsx" */),
  "component---src-pages-clubs-club-facilities-pools-tsx": () => import("./../../../src/pages/clubs/club-facilities/pools.tsx" /* webpackChunkName: "component---src-pages-clubs-club-facilities-pools-tsx" */),
  "component---src-pages-clubs-club-facilities-spa-tsx": () => import("./../../../src/pages/clubs/club-facilities/spa.tsx" /* webpackChunkName: "component---src-pages-clubs-club-facilities-spa-tsx" */),
  "component---src-pages-clubs-club-facilities-tennis-tsx": () => import("./../../../src/pages/clubs/club-facilities/tennis.tsx" /* webpackChunkName: "component---src-pages-clubs-club-facilities-tennis-tsx" */),
  "component---src-pages-clubs-club-facilities-tsx": () => import("./../../../src/pages/clubs/club-facilities.tsx" /* webpackChunkName: "component---src-pages-clubs-club-facilities-tsx" */),
  "component---src-pages-clubs-enquire-now-tsx": () => import("./../../../src/pages/clubs/enquire-now.tsx" /* webpackChunkName: "component---src-pages-clubs-enquire-now-tsx" */),
  "component---src-pages-clubs-family-and-kids-tsx": () => import("./../../../src/pages/clubs/family-and-kids.tsx" /* webpackChunkName: "component---src-pages-clubs-family-and-kids-tsx" */),
  "component---src-pages-clubs-family-tsx": () => import("./../../../src/pages/clubs/family.tsx" /* webpackChunkName: "component---src-pages-clubs-family-tsx" */),
  "component---src-pages-clubs-golf-tsx": () => import("./../../../src/pages/clubs/golf.tsx" /* webpackChunkName: "component---src-pages-clubs-golf-tsx" */),
  "component---src-pages-clubs-index-tsx": () => import("./../../../src/pages/clubs/index.tsx" /* webpackChunkName: "component---src-pages-clubs-index-tsx" */),
  "component---src-pages-clubs-join-online-corporate-tsx": () => import("./../../../src/pages/clubs/join-online/corporate.tsx" /* webpackChunkName: "component---src-pages-clubs-join-online-corporate-tsx" */),
  "component---src-pages-clubs-join-online-friends-and-family-tsx": () => import("./../../../src/pages/clubs/join-online/friends-and-family.tsx" /* webpackChunkName: "component---src-pages-clubs-join-online-friends-and-family-tsx" */),
  "component---src-pages-clubs-join-online-more-details-tsx": () => import("./../../../src/pages/clubs/join-online/more-details.tsx" /* webpackChunkName: "component---src-pages-clubs-join-online-more-details-tsx" */),
  "component---src-pages-clubs-join-online-tdr-capital-tsx": () => import("./../../../src/pages/clubs/join-online/TDRCapital.tsx" /* webpackChunkName: "component---src-pages-clubs-join-online-tdr-capital-tsx" */),
  "component---src-pages-clubs-join-online-tsx": () => import("./../../../src/pages/clubs/join-online.tsx" /* webpackChunkName: "component---src-pages-clubs-join-online-tsx" */),
  "component---src-pages-clubs-landing-tsx": () => import("./../../../src/pages/clubs/landing.tsx" /* webpackChunkName: "component---src-pages-clubs-landing-tsx" */),
  "component---src-pages-clubs-membership-trial-tsx": () => import("./../../../src/pages/clubs/membership-trial.tsx" /* webpackChunkName: "component---src-pages-clubs-membership-trial-tsx" */),
  "component---src-pages-clubs-relax-and-socialise-tsx": () => import("./../../../src/pages/clubs/relax-and-socialise.tsx" /* webpackChunkName: "component---src-pages-clubs-relax-and-socialise-tsx" */),
  "component---src-pages-clubs-timetable-tsx": () => import("./../../../src/pages/clubs/timetable.tsx" /* webpackChunkName: "component---src-pages-clubs-timetable-tsx" */),
  "component---src-pages-clubs-yoga-tsx": () => import("./../../../src/pages/clubs/yoga.tsx" /* webpackChunkName: "component---src-pages-clubs-yoga-tsx" */),
  "component---src-pages-contact-verification-tsx": () => import("./../../../src/pages/contact-verification.tsx" /* webpackChunkName: "component---src-pages-contact-verification-tsx" */),
  "component---src-pages-direct-debit-details-tsx": () => import("./../../../src/pages/direct-debit-details.tsx" /* webpackChunkName: "component---src-pages-direct-debit-details-tsx" */),
  "component---src-pages-friend-tsx": () => import("./../../../src/pages/friend.tsx" /* webpackChunkName: "component---src-pages-friend-tsx" */),
  "component---src-pages-global-enquire-now-tsx": () => import("./../../../src/pages/global-enquire-now.tsx" /* webpackChunkName: "component---src-pages-global-enquire-now-tsx" */),
  "component---src-pages-gyms-in-bristol-tsx": () => import("./../../../src/pages/gyms-in-bristol.tsx" /* webpackChunkName: "component---src-pages-gyms-in-bristol-tsx" */),
  "component---src-pages-gyms-in-edinburgh-tsx": () => import("./../../../src/pages/gyms-in-edinburgh.tsx" /* webpackChunkName: "component---src-pages-gyms-in-edinburgh-tsx" */),
  "component---src-pages-gyms-in-glasgow-tsx": () => import("./../../../src/pages/gyms-in-glasgow.tsx" /* webpackChunkName: "component---src-pages-gyms-in-glasgow-tsx" */),
  "component---src-pages-gyms-in-leicester-tsx": () => import("./../../../src/pages/gyms-in-leicester.tsx" /* webpackChunkName: "component---src-pages-gyms-in-leicester-tsx" */),
  "component---src-pages-gyms-in-liverpool-tsx": () => import("./../../../src/pages/gyms-in-liverpool.tsx" /* webpackChunkName: "component---src-pages-gyms-in-liverpool-tsx" */),
  "component---src-pages-gyms-in-manchester-tsx": () => import("./../../../src/pages/gyms-in-manchester.tsx" /* webpackChunkName: "component---src-pages-gyms-in-manchester-tsx" */),
  "component---src-pages-gyms-in-nottingham-tsx": () => import("./../../../src/pages/gyms-in-nottingham.tsx" /* webpackChunkName: "component---src-pages-gyms-in-nottingham-tsx" */),
  "component---src-pages-gyms-in-southampton-tsx": () => import("./../../../src/pages/gyms-in-southampton.tsx" /* webpackChunkName: "component---src-pages-gyms-in-southampton-tsx" */),
  "component---src-pages-help-centre-tsx": () => import("./../../../src/pages/help-centre.tsx" /* webpackChunkName: "component---src-pages-help-centre-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-join-corporate-tsx": () => import("./../../../src/pages/join/corporate.tsx" /* webpackChunkName: "component---src-pages-join-corporate-tsx" */),
  "component---src-pages-join-friends-and-family-tsx": () => import("./../../../src/pages/join/friends-and-family.tsx" /* webpackChunkName: "component---src-pages-join-friends-and-family-tsx" */),
  "component---src-pages-join-index-tsx": () => import("./../../../src/pages/join/index.tsx" /* webpackChunkName: "component---src-pages-join-index-tsx" */),
  "component---src-pages-join-tdr-capital-tsx": () => import("./../../../src/pages/join/TDRCapital.tsx" /* webpackChunkName: "component---src-pages-join-tdr-capital-tsx" */),
  "component---src-pages-live-stream-tsx": () => import("./../../../src/pages/live-stream.tsx" /* webpackChunkName: "component---src-pages-live-stream-tsx" */),
  "component---src-pages-member-offers-tsx": () => import("./../../../src/pages/member-offers.tsx" /* webpackChunkName: "component---src-pages-member-offers-tsx" */),
  "component---src-pages-membership-and-contact-verification-tsx": () => import("./../../../src/pages/membership-and-contact-verification.tsx" /* webpackChunkName: "component---src-pages-membership-and-contact-verification-tsx" */),
  "component---src-pages-membership-trial-tsx": () => import("./../../../src/pages/membership-trial.tsx" /* webpackChunkName: "component---src-pages-membership-trial-tsx" */),
  "component---src-pages-membership-verification-tsx": () => import("./../../../src/pages/membership-verification.tsx" /* webpackChunkName: "component---src-pages-membership-verification-tsx" */),
  "component---src-pages-memberships-tsx": () => import("./../../../src/pages/memberships.tsx" /* webpackChunkName: "component---src-pages-memberships-tsx" */),
  "component---src-pages-mobile-app-faqs-tsx": () => import("./../../../src/pages/mobile-app-faqs.tsx" /* webpackChunkName: "component---src-pages-mobile-app-faqs-tsx" */),
  "component---src-pages-mobile-links-extras-tsx": () => import("./../../../src/pages/mobile-links/extras.tsx" /* webpackChunkName: "component---src-pages-mobile-links-extras-tsx" */),
  "component---src-pages-mobile-links-live-stream-tsx": () => import("./../../../src/pages/mobile-links/live-stream.tsx" /* webpackChunkName: "component---src-pages-mobile-links-live-stream-tsx" */),
  "component---src-pages-mobile-links-on-demand-tsx": () => import("./../../../src/pages/mobile-links/on-demand.tsx" /* webpackChunkName: "component---src-pages-mobile-links-on-demand-tsx" */),
  "component---src-pages-mobile-links-pt-vouchers-tsx": () => import("./../../../src/pages/mobile-links/pt-vouchers.tsx" /* webpackChunkName: "component---src-pages-mobile-links-pt-vouchers-tsx" */),
  "component---src-pages-offer-expired-tsx": () => import("./../../../src/pages/offer-expired.tsx" /* webpackChunkName: "component---src-pages-offer-expired-tsx" */),
  "component---src-pages-payment-confirmation-tsx": () => import("./../../../src/pages/payment-confirmation.tsx" /* webpackChunkName: "component---src-pages-payment-confirmation-tsx" */),
  "component---src-pages-press-tsx": () => import("./../../../src/pages/press.tsx" /* webpackChunkName: "component---src-pages-press-tsx" */),
  "component---src-pages-property-tsx": () => import("./../../../src/pages/property.tsx" /* webpackChunkName: "component---src-pages-property-tsx" */),
  "component---src-pages-referral-index-tsx": () => import("./../../../src/pages/referral/index.tsx" /* webpackChunkName: "component---src-pages-referral-index-tsx" */),
  "component---src-pages-referral-update-your-app-tsx": () => import("./../../../src/pages/referral/update-your-app.tsx" /* webpackChunkName: "component---src-pages-referral-update-your-app-tsx" */),
  "component---src-pages-sitemap-tsx": () => import("./../../../src/pages/sitemap.tsx" /* webpackChunkName: "component---src-pages-sitemap-tsx" */)
}

