import { getTawkUrl } from './config-helper';
import { BuildType } from 'src/enums/build/build-type';

export type TawkMethod =
  | 'maximize'
  | 'minimize'
  | 'showWidget'
  | 'hideWidget'
  | 'getStatus'
  | 'onStatusChange'
  | 'onLoad';

export type TawkHandler = (...args: any[]) => void;

declare global {
  interface Window {
    Tawk_API: Partial<Record<TawkMethod, (...args: any[]) => any>>;
    Tawk_LoadStart?: Date;
  }
}

let queue: Array<{ key: TawkMethod; args?: any[] }> = [];
let startedInit = false;
let finishedInit = false;
let handlers: Partial<Record<TawkMethod, Array<TawkHandler>>> = {
  onStatusChange: [],
};

function init(buildType: BuildType, siteId: string) {
  if (startedInit || typeof window === `undefined`) {
    return;
  }

  startedInit = true;

  window.Tawk_API = window.Tawk_API || {};
  window.Tawk_LoadStart = new Date();

  const s1 = document.createElement('script');
  const s0 = document.getElementsByTagName('script')[0];
  s1.async = true;
  s1.src = getTawkUrl(buildType, siteId);
  s1.charset = 'UTF-8';
  s1.setAttribute('crossorigin', '*');
  s0.parentNode.insertBefore(s1, s0);

  Object.keys(handlers).forEach((event: TawkMethod) => {
    window.Tawk_API[event] = (...args) => {
      handlers[event] &&
        handlers[event].forEach((handler) => {
          handler && handler(...args);
        });
    };
  });

  window.Tawk_API.onLoad = () => {
    finishedInit = true;
    queue.forEach(runCall);
    queue = [];
  };
}

function runCall({ key, args }: { key: TawkMethod; args: any[] }) {
  try {
    window.Tawk_API[key](...args);
  } catch (e) {
    console.error(
      `Failed to run tawk.${key}(...${args}) on tawk ${Object.keys(window.Tawk_API)}`,
      e
    );
  }
}

const conditionalMethodForward =
  (key: TawkMethod) =>
  (...args: any[]) => {
    const call = { key, args };
    if (finishedInit) {
      runCall(call);
    } else {
      queue.push(call);
    }
  };

const failableMethodForward =
  (key: TawkMethod) =>
  (...args: any[]) => {
    if (typeof window === `undefined`) {
      return;
    }
    const method = window.Tawk_API && window.Tawk_API[key];
    return method && method(...args);
  };

const addHandler = (event: TawkMethod) => (handler: TawkHandler) => {
  handlers[event].push(handler);

  // return index of handler
  return handlers[event].length - 1;
};

const removeHandler = (event: TawkMethod) => (handlerId: number) => {
  if (handlerId != null) {
    handlers[event][handlerId] = null;
  }
};

export default {
  init,
  maximize: conditionalMethodForward('maximize'),
  minimize: conditionalMethodForward('minimize'),
  showWidget: conditionalMethodForward('showWidget'),
  hideWidget: conditionalMethodForward('hideWidget'),
  getStatus: failableMethodForward('getStatus'),
  addStatusChangeHandler: addHandler('onStatusChange'),
  removeStatusChangeHandler: removeHandler('onStatusChange'),
};
