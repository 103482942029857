import { IconProps } from 'src/components/icons/icon-types';
import React from 'react';

const RedesignClose = ({ size, fill }: IconProps) => (
  <div style={{ width: size || '100%', height: size || '100%' }}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_873_8682)">
        <path
          d="M23.7068 0.792786C23.5193 0.605315 23.2649 0.5 22.9998 0.5C22.7346 0.5 22.4803 0.605315 22.2928 0.792786L11.9998 11.0858L1.70679 0.792786C1.51926 0.605315 1.26495 0.5 0.999786 0.5C0.734622 0.5 0.480314 0.605315 0.292786 0.792786C0.105315 0.980314 0 1.23462 0 1.49979C0 1.76495 0.105315 2.01926 0.292786 2.20679L10.5858 12.4998L0.292786 22.7928C0.105315 22.9803 0 23.2346 0 23.4998C0 23.7649 0.105315 24.0193 0.292786 24.2068C0.480314 24.3943 0.734622 24.4996 0.999786 24.4996C1.26495 24.4996 1.51926 24.3943 1.70679 24.2068L11.9998 13.9138L22.2928 24.2068C22.4803 24.3943 22.7346 24.4996 22.9998 24.4996C23.2649 24.4996 23.5193 24.3943 23.7068 24.2068C23.8943 24.0193 23.9996 23.7649 23.9996 23.4998C23.9996 23.2346 23.8943 22.9803 23.7068 22.7928L13.4138 12.4998L23.7068 2.20679C23.8943 2.01926 23.9996 1.76495 23.9996 1.49979C23.9996 1.23462 23.8943 0.980314 23.7068 0.792786Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_873_8682">
          <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  </div>
);

export default RedesignClose;
