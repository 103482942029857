import React, { useContext, useMemo } from 'react';
import { GlobalCmsContent } from 'src/content/global-content';
import { CSSInterpolation } from '@emotion/serialize';
import { GlobalCmsRecord } from 'src/content/cms-provider/global-cms-provider';
import HeaderMobileSubMenu, {
  HeaderMobileSubMenuProps,
} from 'src/components/layout/redesign/header-mobile/header-sub-menu';
import { mqReducedMotion } from 'src/styling/media-queries';
import HeaderClubSearch from './header-club-search';
import { REDESIGN_HEADER_HEIGHT_CSS_VARIABLE } from 'src/components/layout/redesign/header-desktop';

const idForMenu = (level1Index?: number | null, level2Index?: number | null) => {
  if (level1Index == null) {
    return 'header';
  }
  if (level2Index == null) {
    return `header-${level1Index}`;
  }
  return `header-${level1Index}-${level2Index}`;
};

type SubMenuProps = Pick<HeaderMobileSubMenuProps, 'level' | 'title' | 'description' | 'links'> & {
  id: string;
};

const mapHeaderContentToSubMenus = (
  headerContent: GlobalCmsRecord['headerContent'],
  onSelectLevel1Index: (index: number) => void,
  onSelectLevel2Index: (index: number) => void
): SubMenuProps[] => {
  if (!headerContent) {
    return null;
  }

  const level1Menu: SubMenuProps = {
    id: idForMenu(),
    level: 1,
    links: (headerContent.headerMenuItems ?? []).map(({ name }, index) => ({
      name,
      onClick: () => onSelectLevel1Index(index),
    })),
  };

  const level2Menus: SubMenuProps[] = (headerContent.headerMenuItems ?? []).flatMap(
    (level1Menu, level1Index) => ({
      id: idForMenu(level1Index),
      level: 2,
      title: level1Menu.name,
      description: level1Menu.description,
      links: [
        {
          name: headerContent.headerOtherText?.overviewText ?? '',
          link: level1Menu.link,
        },
        ...(level1Menu.levelTwoMenuItems ?? []).map(
          ({ name, link, levelThreeMenuItems }, level2Index) =>
            levelThreeMenuItems?.length
              ? {
                  name,
                  onClick: () => onSelectLevel2Index(level2Index),
                }
              : {
                  name,
                  link,
                }
        ),
      ],
    })
  );

  const level3Menus: SubMenuProps[] = (headerContent.headerMenuItems ?? []).flatMap(
    (level1Menu, level1Index) =>
      (level1Menu.levelTwoMenuItems ?? []).flatMap((level2Menu, level2Index) => ({
        id: idForMenu(level1Index, level2Index),
        level: 3,
        title: level2Menu.name,
        links: [
          {
            name: headerContent.headerOtherText?.overviewText ?? '',
            link: level2Menu.link,
          },
          ...(level2Menu.levelThreeMenuItems ?? []),
        ],
      }))
  );

  return [level1Menu, ...level2Menus, ...level3Menus];
};

const createComponentStyling = (headerBarHeight: number) =>
  ({
    container: {
      position: 'fixed',
      width: '100%',
      top: `var(--${REDESIGN_HEADER_HEIGHT_CSS_VARIABLE})`,
      left: 0,
      right: 0,
      bottom: 0,
    },
    hidden: mqReducedMotion({
      transition: [
        'height 0.4s ease-in-out; visibility 0.4s',
        'opacity 0.4s ease-in-out; visibility 0.4s',
      ],
      visibility: 'hidden',
      opacity: [1, 0],
      height: [0, 'auto'],
    }),
    visible: mqReducedMotion({
      transition: ['height 0.4s ease-in-out', 'opacity 0.4s ease-in-out'],
      height: [`calc(100% - ${Math.floor(headerBarHeight) - 2}px)`, 'auto'],
      opacity: 1,
      visibility: 'visible',
    }),
  }) satisfies Record<string, CSSInterpolation>;

type HeaderMenuProps = {
  headerBarHeight: number;
  isVisible: boolean;
  isClubSearchOpen: boolean;
  level1SelectedIndex: number | null;
  level2SelectedIndex: number | null;
  onSelectLevel1Index: (index: number) => void;
  onSelectLevel2Index: (index: number) => void;
  openClubSearch: () => void;
  onFinish?: () => void;
};

const HeaderMobileMenu = (props: HeaderMenuProps) => {
  const { headerContent } = useContext(GlobalCmsContent);

  const subMenus = useMemo(
    () =>
      mapHeaderContentToSubMenus(
        headerContent,
        props.onSelectLevel1Index,
        props.onSelectLevel2Index
      ),
    [headerContent, props.onSelectLevel1Index, props.onSelectLevel2Index]
  );

  const currentVisibleId = idForMenu(props.level1SelectedIndex, props.level2SelectedIndex);

  const isChildMenuVisible = (menuId: string) => {
    return currentVisibleId !== menuId && currentVisibleId.startsWith(menuId);
  };

  const componentStyling = createComponentStyling(props.headerBarHeight);

  if (!headerContent) {
    return null;
  }

  return (
    <div
      css={[
        componentStyling.container,
        props.isVisible ? componentStyling.visible : componentStyling.hidden,
      ]}
    >
      {subMenus.map((subMenuProps) => (
        <HeaderMobileSubMenu
          key={subMenuProps.id}
          {...subMenuProps}
          isMenuOpen={props.isVisible}
          isVisible={props.isVisible && currentVisibleId === subMenuProps.id}
          isChildMenuVisible={props.isVisible && isChildMenuVisible(subMenuProps.id)}
          openClubSearch={props.openClubSearch}
          onFinish={props.onFinish}
        />
      ))}
      <HeaderClubSearch
        isMenuOpen={props.isVisible}
        isVisible={props.isClubSearchOpen}
        onFinish={props.onFinish}
      />
    </div>
  );
};
export default HeaderMobileMenu;
