import React from 'react';
import { getAllLanguagesForBuildType } from 'src/enums/build/build-type';
import ClosePickerUnderlay from './close-picker-underlay';
import LanguageItem from './language-item';
import LanguagePickerPopup from './language-picker-popup';
import { flex } from 'src/styling/constants';
import { spacer } from 'src/components/online-joining/style';
import { SiteContext } from 'src/context/site-context';
import mq from 'src/styling/media-queries';

const LanguagePicker: React.FC = () => {
  const [pickerOpen, setPickerOpen] = React.useState(false);
  const {
    styling,
    language: currentLanguage,
    pageLanguages,
    buildType,
  } = React.useContext(SiteContext);
  const allLanguagesForBuildType = getAllLanguagesForBuildType(buildType);
  const orderedLanguages = allLanguagesForBuildType
    .map((l) => pageLanguages?.find((p) => p.language === l))
    .filter((p) => !!p);

  if (orderedLanguages.length < 2) {
    return null;
  }

  return (
    <div
      css={mq([
        flex.columnCentered,
        {
          marginTop: ['1rem', ...spacer.marginTop],
          marginBottom: ['1rem', ...spacer.marginBottom],
        },
      ])}
    >
      {pickerOpen && <ClosePickerUnderlay closePicker={() => setPickerOpen(false)} />}
      <div
        className="language-toggle"
        css={{ color: styling.colors.highlightText, position: 'relative', cursor: 'pointer' }}
        onClick={() => setPickerOpen(!pickerOpen)}
      >
        <LanguageItem language={currentLanguage} withChevron isChevronExpanded={pickerOpen} />
        {pickerOpen && (
          <LanguagePickerPopup currentLanguage={currentLanguage} pageLanguages={orderedLanguages} />
        )}
      </div>
    </div>
  );
};

export default LanguagePicker;
