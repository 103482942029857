import React from 'react';
import { IconProps } from 'src/components/icons/icon-types';

const RedesignLeftChevronThin = ({ size, fill }: IconProps) => (
  <svg width={size || '100%'} viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.1562 21.6172C10.9219 21.8516 10.5938 21.8516 10.3594 21.6172L0.46875 11.7266C0.234375 11.4922 0.234375 11.1641 0.46875 10.9297L10.3594 1.03906C10.5938 0.804688 10.9219 0.804688 11.1562 1.03906L11.4844 1.36719C11.7188 1.60156 11.7188 1.92969 11.4844 2.16406L2.34375 11.3047L11.4844 20.4922C11.7188 20.7266 11.7188 21.0547 11.4844 21.2891L11.1562 21.6172Z"
      fill={fill}
    />
  </svg>
);

export default RedesignLeftChevronThin;
