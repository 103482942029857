import { useState, useEffect } from 'react';
import { breakpoints } from 'src/styling/media-queries';

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    isMobileOrSmaller: false,
    isTabletOrSmaller: false,
  });

  const handleResize = () => {
    const isMobileOrSmaller = !window.matchMedia(`(min-width: ${breakpoints.maxMobileScreen})`)
      .matches;
    const isTabletOrSmaller = !window.matchMedia(`(min-width: ${breakpoints.maxLargeTabletScreen})`)
      .matches;
    setWindowSize({
      isMobileOrSmaller,
      isTabletOrSmaller,
    });
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

export default useWindowSize;
