import React from 'react';
import VideoWithPlaceholderImage, {
  VideoWithPlaceholderImageProps,
  VideoType,
} from 'src/components/common/video-with-placeholder-image';

export type VimeoVideoDetails = {
  videoId: string;
  videoPlaceholder: Queries.ImageWithCropDetails | Queries.ImageDetails;
};

type VimeoVideoProps = Omit<VideoWithPlaceholderImageProps, 'video'> & {
  video: VimeoVideoDetails;
};

const VimeoVideo = ({ video, ...rest }: VimeoVideoProps) => (
  <VideoWithPlaceholderImage
    video={{
      type: VideoType.VIMEO,
      ...video,
    }}
    {...rest}
  />
);

export default VimeoVideo;
