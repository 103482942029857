import { CmsFileNode, findCmsFileNodeForClub } from '../helpers/cms-file-helper';
import { Language } from 'src/enums/language';
import { ClubSection } from '../../cms/page-widgets/enabled-pages-widget';
import { ClubInformation } from 'src/enums/club-information';
import { getClubDefaultLanguage } from '../helpers/language-helper';

export const enabledPagesQuery = `
  query EnabledPagesQuery {
    pages: allFile(filter: { base: { eq: "pages.json" } }) {
      nodes {
        relativeDirectory
        childClubJson {
          treatmentsPage {
            enabled
            clubSection
          }
          familyPage {
            enabled
            clubSection
          }
          liveClassesPage {
            enabled
            clubSection
          }
          onDemandPage {
            enabled
            clubSection
          }
          golfPage {
            enabled
            clubSection
          }
          yogaPage {
            enabled
            clubSection
          }
          blazePage {
            enabled
            clubSection
          }
          personalTrainingPage {
            enabled
            clubSection
          }
          familyAndKidsPage {
            enabled
            clubSection
          }
          relaxAndSocialisePage {
            enabled
            clubSection
          }
          timetablePage {
            enabled
            clubSection
          }
          clubFacilitiesPage {
            enabled
            clubSection
          }
          clubFacilities_spaPage {
            enabled
            clubSection
          }
          clubFacilities_poolsPage {
            enabled
            clubSection
          }
          clubFacilities_tennisPage {
            enabled
            clubSection
          }
          clubFacilities_padelPage {
            enabled
            clubSection
          }
          clubFacilities_gymPage {
            enabled
            clubSection
          }
          clubFacilities_exerciseClassesPage {
            enabled
            clubSection
          }
          clubFacilities_crechePage {
            enabled
            clubSection
          }
          clubFacilities_kidsClassesPage {
            enabled
            clubSection
          }
        }
      }
    }
  }
`;

export type EnabledPage = {
  enabled: boolean;
  clubSection: ClubSection;
};

export type EnabledPages = Record<string, EnabledPage>;

export type EnabledPagesPerClub = CmsFileNode<EnabledPages>;

export const getEnabledPagesForClub = (
  enabledPagesPerClub: EnabledPagesPerClub[],
  clubInformation: ClubInformation
): EnabledPages =>
  findEnabledPagesForClub(
    enabledPagesPerClub,
    clubInformation.siteId,
    getClubDefaultLanguage(clubInformation.siteId)
  );

export const isInClubFacilitiesSection = (page: EnabledPage): boolean =>
  page.clubSection === ClubSection.CLUB_FACILITIES;

export const defaultEnabledPage: EnabledPage = {
  enabled: true,
  clubSection: ClubSection.ROOT,
};

const findEnabledPagesForClub = (
  enabledPagesPerClub: EnabledPagesPerClub[],
  clubId: string,
  language: Language
): EnabledPages => {
  const enabledPages = findCmsFileNodeForClub<EnabledPages>(
    enabledPagesPerClub,
    clubId,
    language
  ).childClubJson;
  addLandingPageToEnabledPages(enabledPages);
  return enabledPages;
};

const addLandingPageToEnabledPages = (enabledPages: EnabledPages) => {
  enabledPages['landingPage'] = {
    enabled: true,
    clubSection: ClubSection.ROOT,
  };
};
