import React, { startTransition, useContext, useEffect, useRef, useState } from 'react';
import { CSSInterpolation } from '@emotion/serialize';
import { calculateDesktopGridWidth, colors, flex, zIndexes } from 'src/styling/constants';
import { keyframes } from '@emotion/react';
import { Styling } from 'src/context/styling';
import { SiteContext } from 'src/context/site-context';
import MarkdownToHtmlBlock from 'src/components/common/markdown-to-html-block';
import { WebsiteLink } from 'src/components/common/website-link';
import ArrowRightThin from 'src/components/icons/arrow-right-thin';
import { getBackgroundColorByTheme } from 'src/helpers/styling';
import { BackgroundTheme } from 'src/enums/background-theme';
import { REDESIGN_HEADER_HEIGHT_CSS_VARIABLE } from 'src/components/layout/redesign/header-desktop/index';
import HeaderDesktopLevelTwoMenu from 'src/components/layout/redesign/header-desktop/header-level-two-menu';
import { mqReducedMotion } from 'src/styling/media-queries';
import { amplitudeTrackEvent } from 'src/helpers/amplitude-event-track';
import capitalize from 'lodash/capitalize';
import { pathToTitle } from 'src/helpers/string-helper';

const delayOverflow = keyframes({
  '0%': {
    overflowY: 'hidden',
  },
  '99%': {
    overflowY: 'hidden',
  },
  '100%': {
    overflowY: 'initial',
  },
});

const createComponentStyling = (styling: Styling, enableOverflow?: boolean) =>
  ({
    container: [
      {
        position: 'fixed',
        width: '100%',
        backgroundColor: getBackgroundColorByTheme(BackgroundTheme.LIGHT),
        top: `calc(var(--${REDESIGN_HEADER_HEIGHT_CSS_VARIABLE}) - 1px)`,
        left: 0,
        zIndex: zIndexes.header,
        display: 'flex',
        justifyContent: 'center',
      },
    ],
    wrapper: {
      maxWidth: '90rem',
      padding: '0 10.75rem 1.8rem',
      height: 'fit-content',
      width: '100%',
      gap: '1.5rem',
    },
    overviewWrapper: {
      width: calculateDesktopGridWidth(10, 4),
      paddingRight: '3rem',
    },
    overviewLink: {
      gap: '0.75rem',
      alignItems: 'center',
    },
    title: {
      fontSize: '3rem',
      lineHeight: '3.625rem',
      marginBottom: '1rem',
    },
    text: [
      styling.fonts.regular,
      {
        borderBottom: '1px solid',
        borderBottomColor: 'transparent',
        fontSize: '1.1875rem',
        lineHeight: '145%',
      },
    ],
    hover: {
      '&:hover div': {
        color: styling.colors.camelot,
        borderBottomColor: styling.colors.camelot,
      },
      '&:hover path': {
        fill: styling.colors.camelot,
      },
    },
    levelTwoWrapper: {
      'width': calculateDesktopGridWidth(10, 3),
      'position': 'relative',
      'li:first-of-type > a > div': {
        borderTop: '1px solid',
        borderTopColor: colors.transparentGrayOlive,
      },
    },
    item: {
      minHeight: '4rem',
      borderBottom: '1px solid',
      borderBottomColor: colors.transparentGrayOlive,
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '0.5rem',
    },
    selected: {
      color: styling.colors.camelot,
      borderBottomColor: styling.colors.camelot,
    },
    menuHidden: mqReducedMotion({
      transition: ['height 0.3s ease-in-out, visibility 0.3s', 'none'],
      height: 0,
      visibility: 'hidden',
      overflowY: 'hidden',
    }),
    menuVisible: mqReducedMotion({
      transition: ['height 0.3s ease-in-out 0.15s', 'none'],
      visibility: 'visible',
      animation: [enableOverflow ? `${delayOverflow} 0.45s` : 'none', 'none'],
      overflowY: enableOverflow ? [null, 'initial'] : 'hidden',
    }),
  }) satisfies Record<string, CSSInterpolation>;

type HeaderDesktopSubMenuProps = Queries.GlobalHeaderLevelOneMenuItem & {
  overviewText: string;
  visible: boolean;
  children?: React.ReactNode;
  enableOverflow?: boolean;
  onFinish?: () => void;
};

const HeaderDesktopMenu = (props: HeaderDesktopSubMenuProps) => {
  const { styling, pageLevel } = useContext(SiteContext);
  const componentStyling = createComponentStyling(styling, props.enableOverflow);
  const ref = useRef<HTMLDivElement>(null);
  const level3Refs = useRef<Record<number, HTMLElement>>({});

  const [displayedSubMenuIndex, setDisplayedSubMenuIndex] = useState<number | null>(null);
  const [componentHeight, setHeight] = useState(0);
  const [displayedLevel3ComponentHeight, setDisplayedLevel3ComponentHeight] = useState(0);

  useEffect(() => {
    if (!props.visible) {
      setDisplayedSubMenuIndex(null);
    }
  }, [props.visible]);

  useEffect(() => {
    setDisplayedLevel3ComponentHeight(
      level3Refs.current[displayedSubMenuIndex]?.getBoundingClientRect()?.height || 0
    );
  }, [displayedSubMenuIndex]);

  useEffect(() => {
    if (!ref.current || !props.visible) {
      return;
    }

    const updateHeaderHeightVariable = () => {
      const containerHeight = ref.current.getBoundingClientRect().height;

      if (!containerHeight) {
        return;
      }
      setHeight(containerHeight);
    };

    const resizeObserver = new ResizeObserver(() => {
      startTransition(updateHeaderHeightVariable);
    });

    resizeObserver.observe(ref.current);
    updateHeaderHeightVariable();

    return () => resizeObserver.disconnect();
  }, [props.visible]);

  const handleClick = () => {
    props.onFinish?.();
    return amplitudeTrackEvent(
      `Click to ${capitalize(props.overviewText || '')} - ${pathToTitle(props.link || '')}`,
      {
        custom_page: window.location.origin,
        custom_level: pageLevel,
      }
    );
  };

  return (
    <div
      css={[
        componentStyling.container,
        props.visible
          ? [componentStyling.menuVisible, { height: componentHeight }]
          : componentStyling.menuHidden,
      ]}
      tabIndex={-1}
    >
      <div css={[componentStyling.wrapper, flex.row]} ref={ref}>
        <div
          css={[componentStyling.overviewWrapper, { minHeight: displayedLevel3ComponentHeight }]}
        >
          <div css={[componentStyling.title, styling.fonts.light]}>{props.name}</div>
          <MarkdownToHtmlBlock
            markdownText={props.description}
            colorOverride={styling.colors.capeCod}
            cssOverride={componentStyling.text}
          />
          <WebsiteLink to={props.link} handleClick={handleClick}>
            <div css={[componentStyling.overviewLink, flex.row, componentStyling.hover]}>
              <div css={[componentStyling.text]}>{props.overviewText}</div>
              <ArrowRightThin size={16} fill={styling.colors.capeCod} />
            </div>
          </WebsiteLink>
        </div>
        {!!props.levelTwoMenuItems?.length && (
          <ul css={componentStyling.levelTwoWrapper}>
            {props.levelTwoMenuItems.map((levelTwoMenu, index) => {
              const hasLevelThreeItems = !!levelTwoMenu.levelThreeMenuItems?.length;
              return (
                <li key={levelTwoMenu.name}>
                  <HeaderDesktopLevelTwoMenu
                    selected={displayedSubMenuIndex === index}
                    levelTwoMenu={levelTwoMenu}
                    onSelectMenu={() => setDisplayedSubMenuIndex(hasLevelThreeItems ? index : null)}
                    updateRef={(ref) => (level3Refs.current[index] = ref)}
                    onFinish={props.onFinish}
                  />
                </li>
              );
            })}
          </ul>
        )}
        {props.children}
      </div>
    </div>
  );
};

export default HeaderDesktopMenu;
