export async function get<T>(urlPath: string) {
  const response = await fetch(`${getMobileBackendHostname()}/${urlPath}`);
  return handleResponse<T>(response);
}

export async function put<T>(urlPath: string, body?: any) {
  const response = await fetch(`${getMobileBackendHostname()}/${urlPath}`, {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return handleResponse<T>(response);
}

export async function post<T>(urlPath: string, body: any) {
  const response = await fetch(`${getMobileBackendHostname()}/${urlPath}`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return handleResponse<T>(response);
}

export async function del<T>(urlPath: string) {
  const response = await fetch(`${getMobileBackendHostname()}/${urlPath}`, {
    method: 'DELETE',
  });
  return handleResponse<T>(response);
}

export interface ResponseError {
  statusCode: number;
  response?: any;
}

export const isInstanceOfResponseError = (object: any): object is ResponseError => {
  return 'statusCode' in object;
};

async function handleResponse<T>(response: Response): Promise<T> {
  if (response.status >= 200 && response.status < 300) {
    if (response.status === 204) {
      return null;
    }
    return response.json();
  }

  const responseJson = await response.json();
  const error: ResponseError = { statusCode: response.status, response: responseJson };
  throw error;
}

export function getMobileBackendHostname() {
  return process.env.GATSBY_MOBILE_BACKEND_HOSTNAME;
}

export const parseExpectedErrorCodesOnThrow = <T>(
  networkCall: () => Promise<T>,
  expectedErrorCodes: string[]
): Promise<T> =>
  networkCall().catch((e) => {
    const responseErrors = e?.response?.errors || [];
    const knownResponseErrorCodes = responseErrors
      .map((error: { code?: string }) => error.code)
      .filter((code?: string) => !!code)
      .filter((code: string) => expectedErrorCodes.includes(code));

    if (knownResponseErrorCodes.length > 0) {
      throw knownResponseErrorCodes[0];
    }

    throw e;
  });

export const containsError = (
  errorResponse: any,
  httpsStatusCode: number,
  errorCode: string
): boolean =>
  (errorResponse.statusCode === httpsStatusCode &&
    errorResponse.response?.errors?.some((error: ApiErrorModel) => error.code === errorCode)) ??
  false;

type ApiErrorModel = {
  error: string;
  code: string;
  extraProperties: Record<string, string>;
};
