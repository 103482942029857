export const staticTextLookup = {
  FAQs: "Preguntes freqüents",
  aCopyOfThisGuaranteeShouldBeRetained: "El client haurà de guardar una còpia de la garantia, s'enviarà una confirmació via email",
  about: "Sobre",
  aboutYou: "Sobre tu",
  accessToClubsAndSpaRetreats: "Platinum membership will also give you access to other David Lloyd clubs. See below for details of the clubs you'll be able to access.\nPlus, if the club has a Spa Retreat, this will also include access to the Spa Retreat facilities. Click here for a list of clubs with Spa Retreat.",
  accessToClubsNearYou: "El teu accès als clubs a prop de tu:",
  accessToOtherClubs: "Accés a altres clubs",
  accountHoldersName: "Nom del titular del compte",
  accountNumber: "Número de compte",
  activating: "Activant",
  addPartnerAmount: "Afegeix la teva parella %amount%",
  addSelectedMembership: "Afegeix la membresia escollida",
  addSelectedPromotion: "Afegir la promoció seleccionada",
  addToCalendar: "Afegir Al Calendari",
  addYourAssociates: "Afegeix la teva parella i fills",
  addYourChildren: "Afegeix els teus fills",
  addYourPartner: "Afegeix la teva parella",
  addYourPromotions: "Selecciona la teva promoció",
  additionalDetailsSecondErrorMessage: "Ho sentim, no podem gestionar les teves dades online en aquests moments, ho farem a la teva primera visita al club.",
  additionalMembers: "Soci addicional",
  additionalMemberships: "Membresies addicionals",
  address: "Direcció",
  addressLine1: "Direcció - línea 1",
  addressLine2: "Direcció - línea 2",
  addressLinePlaceholder: "Introdueix la teva direcció",
  adultMembers: "Socis adults",
  adults: "Adults",
  afterSchool: "Després de l'escola",
  allAgesWelcome: "Per a totes les edats",
  allFacilities: "Totes les instal·lacions",
  alsoAtClub: "També a %clubName%",
  amountSubjectToPendingPriceRise: "El total indicat està subjecte a pujades de preu, si us plau contacta amb el club per a més informació.",
  and: "i",
  annualOnJoiningTheClubPayment: "Quan t'inscrius al club, et demanem un pagament que cobreix la matrícula, la prorrata de la quota del mes vigent (si hi correspon) i el pagament del cost de 12 quotes mensuals naturals. Si vols millorar la teva experiència al club, pots afegir-ne productes addicionals com entrenament personal o classes particulars, que també pots pagar avui mateix.",
  annually: "Anual",
  anythingElseYoudLikeUsToKnow: "Alguna cosa més que ens vulguis comentar?",
  anytimeClubAccess: "Accés al club amb horari il·limitat",
  app: "App",
  appQrCodeInstructions: "Només has d'aliniar la càmera del teu telèfon al codi i, un cop el reconegui, seguir el link per a descarregar l'App %brandName% .",
  appUpdateRequired: "L'App s'ha d'actualitzar",
  areYouSureYouDoNotWantToBeContacted: "Estàs segur que no vols ser contactat per email o SMS?",
  associatesWereReset: "Ho sentim, però aquesta quota no comprèn tots els associats que has seleccionat - si us plau, escull una altra modalitat si vols incloure aquests socis al teu compte.",
  atClub: "A %clubName%",
  atHomeDigitalContent: "Contingut digital @home tant en viu com sota demanda, seminaris i consells per a entrenar",
  atUnit: "A %unitName%",
  availablePromotions: "promocions disponibles",
  availableSlot: "%numberOfSlots% plaça disponible",
  availableSlots: "%numberOfSlots% places disponibles",
  awaitingPayment: "Pendent de pagament",
  back: "Enrere",
  backToTop: "Torna al cim",
  beforeYouBecomeAMember: "Abans de fer-te soci, deixa que et mostrem com aprofitar al màxim aquest club",
  bestValue: "Millor valor",
  bookAClass: "Reserva una classe",
  bookAClubWelcome: "Reserva una benvinguda al club",
  bookAClubWelcomeError: "Ho sentim, sembla que hi ha un error. Si us plau contacta'ns i trobarem una hora",
  bookATour: "Reserva una visita",
  bookATourError: "Ho sentim, sembla que hi ha un error. Si us plau contacta'ns i trobarem una hora",
  bookATourHeader: "Reserva una visita",
  bookATourSubtext: "Escull el teu club més proper",
  bookOnTheMove: "Reserva sobre la marxa",
  bookYourClubWelcome: "Reserva la teva benvinguda al club",
  bookYourClubWelcomeAt: "Reserva la teva benvinguda a %clubName%",
  bookYourTour: "Reserva la teva visita",
  bookYourTourOf: "Reserva la teva visita a %clubName%",
  booked: "Reservat",
  bookingYourClubWelcome: "Reservant la teva benvinguda al club...",
  bookingYourTour: "Reservant la teva visita…",
  buyapowaWarningPopupCancelButtonText: "Escollir un altre club",
  buyapowaWarningPopupConfirmButtonText: "Apunta't a %clubName%",
  buyapowaWarningPopupHeader: "Tingues en compte",
  buyapowaWarningPopupText: "En aquests moments, el Club %clubName% no té una oferta disponible per a referir un amic, així que ni tu ni la persona que ens recomanes podreu escollir cap regal si continues la inscripció.\n ",
  byClickingFinishYouAcceptDDConditions: "En fer click a ‘%buttonText%’ confirmes que ets el titular del compte bancari i que no és necessari que ningú més autoritzi els càrrecs en aquest compte. Si és un compte que necessiti l'autorització d'un tercer, tant personal com d'empresa, si us plau contacta'ns directament.",
  cancel: "Cancel·lar",
  cancelMyMembership: "Cancel·lar la meva subscripció",
  cantFindASlot: "No trobes espai disponible?",
  cardCollectionTime: "Hora de la visita al club",
  careers: "Feina",
  changeClub: "Canviar de club",
  changePreferences: "Canviar preferències",
  changeSelectedPackage: "Canvia la membresia escollida",
  checkYourAccessToASpecificClub: "Comprova el teu accés a un club en concret:",
  child2AndUnder: "Tarifes per a nens:\nFins a 2 anys",
  child3To18: "Tarifes per a nens:\nDe 3 a 18 anys",
  childMembers: "Socis infantils",
  children: "Nens",
  childrenOverview: "Informació general dels nens",
  chooseAClub: "Tria un club",
  chooseAClubToRegisterForClubTrial: "Selecciona un club",
  chooseHowYouPay: "Escull la forma de pagament",
  chooseMembershipLength: "Triï la durada de la seva subscripció",
  chooseYourStartDate: "Triï la data d'inici",
  clickHereIfYouAreNot: "Faci clic aquí si no és %firstName%",
  close: "Tanca",
  closeDetails: "Tancar la informació",
  club: "Club",
  clubEmail: "Email del club",
  clubFacilities: "Instal·lacions",
  clubFinderSearchPlaceholder: "Introdueix una localització",
  clubOpeningHours: "Horaris d'obertura",
  clubOpeningTimes: "Horaris d'obertura",
  clubOverview: "Informació general del club",
  clubTourTime: "Hora de la visita al club",
  clubVideo: "Vídeo del club",
  clubWelcomeMeetingTime: "Hora de la benvinguda al club",
  comingSoon: "Properament",
  completeDirectDebitPopupText: "Estalvia temps en la teva primera visita domiciliant-te ara",
  completeForm: "Completi el formulari inferior.",
  completeItInClub: "Completa la domiciliació al Club",
  completeItNow: "Completa la domiciliació ara",
  completeSEPAInClub: "Completa la domiciliació SEPA al Club",
  completeSEPANow: "Completa la domiciliació SEPA ara",
  completeSEPAPopupText: "Estalvia temps en la teva primera visita domiciliant-te ara",
  confirm: "Confirmar",
  confirmNoPaymentTaken: "Si us plau, confirma que no s'ha processat cap pagament",
  confirmPassword: "Confirmar contrasenya",
  confirmPasswordPlaceholder: "Confirma la teva nova contrasenya",
  confirmPaymentTaken: "Si us plau, confirma que el pagament s'ha processat",
  contact: "Contacte",
  contactInformation: "Dades de contacte",
  contactMeToArrange: "Contacteu-me per concertar la visita",
  contactNotPromotedMonthlyPaymentErrorSubtitle: "Gestionar la teva quota ens porta una mica de temps. Si us plau, torna a intentar-ho, però no pateixis que no et farem el càrrec dos cops.",
  contactNotPromotedMonthlyPaymentErrorTitle: "Ho sentim, avui no som gaire ràpids!",
  contactPreferencesAfterPrivacyPolicy: "per a més detalls. Només has de marcar la casella per mantenir-te en contacte amb nosaltres.",
  contactPreferencesBeforePrivacyPolicy: "Ens encantaria seguir en contacte amb tu i mantenir-te al dia dels nostres productes, serveis i ofertes. Si hi estàs d'acord, marca la casella més a baix. Sempre pots canviar d'opinió en qualsevol moment. Si us plau, consulta la nostra",
  contactTheClubForOpeningTimes: "Si us plau contacta el club per a més informació sobre els horaris d'obertura",
  "continue": "Continuar",
  cookiePolicyDescription: "La nostra pàgina web fa servir Cookies per millorar la teva experiència. En entrar a la pàgina web, acceptes l'ús de Cookies per la nostra part",
  cookiesPolicy: "Política de Cookies",
  copyright: "Copyright",
  coreStrengthAndHIIT: "Core, força i condicionament físic, HIIT, Wellness i benestar, Ioga, Cycling",
  corporateFitness: "Unternehmensfitness",
  countryBelgium: "Bèlgica",
  countryFrance: "França",
  countryGermany: "Alemanya",
  countryIreland: "Irlanda",
  countryItaly: "Itàlia",
  countryNetherlands: "Països Baixos",
  countrySpain: "Espanya",
  countrySwitzerland: "Suïssa",
  countryUnitedKingdom: "Regne Unit",
  county: "Província",
  countyPlaceholder: "Introdueix la teva província",
  couple: "Parella",
  creche: "Ludoteca",
  creditorAddress: "Direcció",
  creditorId: "Identiﬁcador del creditor",
  creditorName: "Nom del creditor",
  dateAtTime: "%date% a les %time%",
  dateOfBirth: "Data de naixement",
  days: "dies",
  daytime: "Durant el dia",
  detailsAddedToMembershipAccount: "Aquestes dades s'han agregat al seu compte de soci.",
  diamondPlusRequired: "Necessites la quota Diamant Plus per poder accedir a alguns Spa Retreats",
  directDebitClubOpens: "El primer càrrec se't farà quan el club obri, considerant les promocions que hi hagi en aquell moment",
  directDebitClubReopens: "La seva domiciliació mensual començarà quan el club torni a obrir les seves portes i aplicarà qualsevol promoció de subscripció vigent",
  directDebitMandate: "Autorització de domiciliació",
  directDebitMembershipStarts: "El teu primer càrrec serà el dia que comenci el teu contracte, considerant les promocions que hi hagi en aquell moment",
  discoverTheCreche: "Descobreix la ludoteca",
  done: "Fet",
  downloadAppPrompt: "Per reservar i fer altres gestions, si us plau descarrega la App David Lloyd Clubs",
  downloadDescriptionMembershipVerification: "Descarrega la nostra aplicació abans de la teva visita perquè et puguem ajudar a registrar-te i que aprofitis al màxim la teva quota",
  downloadDescriptionTennis: "Descarrega l'aplicació per consultar els horaris i reservar classes i pistes sempre que vulguis",
  downloadDescriptionTrialConfirmation: "Descarrega abans de la teva visita perquè et puguem ajudar a registrar-te i que aprofitis al màxim la teva quota",
  downloadTheMobileAppHere: "Descarrega l'App mòbil aquí:",
  dr: "Dr.",
  duration: "Durada",
  edit: "Editar",
  email: "E-mail",
  emailAddress: "E-mail",
  emailAddressesMustBeUnique: "Introduïu una adreça de correu electrònic única. Ha de ser diferent de l'adreça electrònica proporcionada pel membre principal",
  endsIn: "Acaba a",
  enjoyTimeTogetherAt: "Gaudeix del temps junts a %clubPrefix% %clubName%",
  enquireAboutMembership: "Informa't sobre les quotes",
  enquireNow: "Informa-te'n ara",
  enquireText: "Diga'ns la teva consulta",
  enterALocation: "Introdueix una ubicació…",
  enterValidEmailAddress: "Si us plau, introdueix un correu electrònic vàlid",
  enterValidMobilePhoneNumber: "Si us plau, introdueix un número de telèfon vàlid",
  enterValidName: "Si us plau, introdueix un nom vàlid",
  enterValidPhoneNumber: "Si us plau, introdueix un número de telèfon vàlid",
  enterValidPostCode: "Si us plau, introdueix un codi postal vàlid",
  enterValidTaxId: "Introduïu un identificador fiscal vàlid",
  equipmentAt: "El nostre equipament a %clubName%",
  exerciseClasses: "Activitats dirigides",
  exerciseClassesAt: "Activitats dirigides a %clubName%",
  experienceAllTheBenefits: "Descobreix tots els beneficis de fer-se soci",
  expertPersonalTrainers: "Entrenadors personals experts",
  expertTuition: "Professors expert",
  exploreClub: "Descobreix %clubName%",
  exploreKidsActivities: "Descobreix les activitats infantils",
  failedToFetchExistingPayment: "Ja s'ha fet el pagament, però hem trobat un error en confirmar les dades",
  familyAmpersandKids: "Família i nens",
  familyAndKids: "Família i nens",
  familySwim: "Natació per la família",
  female: "Dona",
  findAClub: "Buscar un club",
  findOutMore: "Sol·licita informació",
  findYourClub: "Troba el teu club",
  firstFullMonth: "Primer mes complet",
  firstMonthFees: "Com no podem gestionar la teva domiciliació a temps per la teva primera quota mensual, el pagament es farà per avançar",
  firstName: "Nom",
  fitnessOverview: "Informació de fitness",
  fixedTermFee: "Dotze quotes mensuals que comencen %month%",
  fixedTermFeeTooltip: "El pagament cobreix el cost de les quotes de 12 mesos naturals",
  flexible: "Flexible",
  followUs: "Segueix-nos",
  followedByMonthly: "Seguit per un pagament mensual de",
  followedByMonthlyPaymentsTitle: "Seguit de pagaments mensuals",
  free: "Gratis",
  frequentlyAskedQuestions: "Preguntes Freqüents",
  friend: "Amic",
  friendsAndFamilyReferralAlreadyUsed: "Ho sentim, aquest enllaç ja s'ha utilitzat. Posi's en contacte amb la persona que li va recomanar perquè ho torni a fer.",
  friendsAndFamilyReferralExpired: "Ho sentim, el link ha expirat. Si us plau, contacta la persona que t'ha recomanat per a què ho torni a fer",
  friendsAndFamilyReferralNotFound: "Ho sentim, però no hem trobat aquest enllaç. Posi's en contacte amb la persona que li va recomanar perquè ho torni a fer.",
  from: "Des de",
  from3MonthsPlus: "Des del 3 mesos",
  from3To18Years: "De 3 a 18 anys",
  gender: "Gènere",
  germanAndFallbackOnly: "Test",
  getInTouch: "Segueix en contacte",
  getStarted: "Començar",
  getYourQuoteContactPreferencesAfterPrivacyPolicy: "política de privacitat. Només has de marcar la casella per continuar en contacte.",
  getYourQuoteContactPreferencesBeforePrivacyPolicy: "Ens encantaria seguir en contacte amb tu i mantenir-te informat dels nostres últims productes, serveis i ofertes. Si hi estàs d'acord, només has de marcar la casella. Sempre pots canviar d'idea en qualsevol moment. Si vols saber més, consulta la nostra política de privacitat",
  groupExerciseClasses: "Activitats dirigides",
  gym: "Gimnàs",
  havingProblemsSavingMonthlyPaymentDetails: "Ho sentim, avui no som tan ràpids com tu! Gestionar la teva inscripció ens porta un temps. Si us plau, torna-ho a intentar, però no pateixis perquè no et carregarem dos cops.",
  healthyBreakfast: "Esmorzar saludable",
  heatedSwimmingPools: "Piscines climatitzades",
  highlightsAtClub: "Destacats a %clubPrefix% %clubName%*",
  hours: "hores",
  howCanWeContactYou: "Com vols que et contactem?",
  iAgree: "D'acord",
  ifSomethingWereToChangeInYourLifestyle: "Si sorgeix un imprevist, %brandName% opera amb un període de rescissió de 14 dies. Parla amb el nostre equip per a saber-ne més.",
  ifSomethingWereToChangeRightToCancel: "Si hi ha canvis a la teva vida, tens dret a cancel·lar. Parla amb el nostre equip per a saber-ne més.",
  imageDisclaimer: "*Avís: les imatges són il·lustratives i poden ser d’un club diferent al seleccionat.",
  importantInformation: "Informació important",
  includedInPromotion: "Inclòs a una promoció",
  individual: "Individual",
  initialEnquiryKeepYouUpdated: "Després de la vostra consulta inicial, ens agradaria actualitzar-vos sobre els nostres últims productes, serveis i ofertes. Si està interessat, només cal fer clic a les caixes següents.",
  initialMonthTerm: "Terme inicial de %monthDuration% mesos naturals",
  isChildPresentText: "El meu fill està al Club amb mi avui",
  isPartnerPresentText: "La meva parella està amb mi en el club",
  join: "Apunta't",
  joinCorporate: "Apunta't amb corporate",
  joinDifferentClub: "Apuntar-te a un altre club",
  joinFriendsAndFamily: "Apunta't amb Friends and Family",
  joinOurReferralScheme: "Sembla que vols apuntar-te en el nostre programa per recomanar amics...",
  joinTodayAndSave: "Inscrigui's avui i est",
  joiningFee: "Cost d'inscripció",
  joiningFeeFirstMonth: "inclòs el càrrec d'inscripció i el primer mes",
  joiningFeeProRataFirstMonth: "Matrícula, prorrata del mes i mes següent complet",
  joiningFeeTooltip: "Pagament per fer-se soci del club",
  joiningFeeTwelveMonths: "Matrícula, el que resta del mes i 12 mesos naturals",
  joiningFees: "Càrrec d'inscripció",
  joiningFeesApply: "S'apliquen despeses d'inscripció",
  joiningFeesWaived: "OFERTA ESPECIAL: Estalvi de quota d'inscripció ara disponible",
  joiningTheClubAsAFamily: "Apuntar-se al club en família",
  justMe: "Només jo",
  keepAnEyeOutForFutureTrials: "Consulta la nostra web per futures ofertes de prova o deixa les teves dades a la pàgina web i el club et contactarà",
  keepInTouch: "Seguir en contacte",
  keepYouUpdated: "Ens encantaria informar-te dels últims productes, serveis i ofertes. Si hi estàs interessat, només has de marcar la casella",
  kickstartTitleWithName: "%firstName%, com et descriuries?",
  kickstartTitleWithoutName: "Com et descriuries?",
  kidsActivities: "Activitats per nens",
  kidsBuildYourOwnPizza: "Els nens fan la seva pizza",
  kidsClub: "Club per nens",
  lastName: "Cognom",
  latestNews: "Últimes notícies",
  learnMore: "Més informació",
  learnSomethingNew: "Aprèn coses noves",
  letThemEnjoyTheClub: "Deixa'ls gaudir de les activitats al club mentre",
  letUsKnowWhatYouAreInterestedIn: "Explica'ns els teus interessos i farem la teva experiència a mida",
  letUsKnowWhatYouAreLookingForwardTo: "Explica'ns quines són les àrees del club que més t'interessen",
  likeOurPage: "Fes like a la nostra pàgina",
  linkIsInvalid: "Ho sentim, aquest enllaç no és vàlid",
  loadingClubWelcomeTimes: "Carregant l'horari de benvingudes...",
  loadingTourTimes: "Carregant horaris per la visita…",
  logOut: "Desconnectar",
  login: "Iniciar sessió",
  male: "Home",
  master: "Mestre",
  meAndMyFamily: "La meva família i jo",
  meAndMyPartner: "La meva parella i jo",
  membersLogin: "Accés per socis",
  membershipDescription: "Que trobarà en el seu correu electrònic de benvinguda o en la seva targeta de soci",
  membershipDiscountUntil: "Fins %date%",
  membershipEnquiryDetails: "L'usuari seleccionat: %duration%%packageKey%%packageTypes%",
  membershipFees: "Quotes de subscripció",
  membershipFeesUpUntil: "Quotes fins %date%",
  membershipNumber: "Número de soci/sòcia",
  membershipPlaceholder: "Introdueix el teu número de soci/sòcia",
  membershipPriceChangedError: "Ja hem registrat un pagament amb un import diferent. Si vols canviar les dades de soci, si us plau, inicia una nova sessió",
  membershipSaving: "Estalvi per a socis",
  membershipStartClubConfirm: "El teu club confirmarà quan comença el teu contracte",
  membershipStartClubOpens: "El teu contracte començarà quan el club obri",
  membershipTermsAndConditions: "Termes i Condicions del contracte",
  membershipTrial: "Prova el club",
  memberships: "Quotes",
  min: "min",
  minutes: "minuts",
  miss: "Senyora",
  monthlyDirectDebitsStartOn: "El pagament mensual per domiciliació comença el",
  monthlyPaymentsDuringOfferPeriodTitle: "Pagaments mensuals durant l'oferta especial",
  monthlySavings: "Estalvi mensual",
  months: "Mesos",
  more: "Més",
  moreDetailsSkipDirectDebit: "Vull domiciliar-me amb el Club",
  moreDetailsSkipSEPA: "Vull domiciliar-me amb el Club",
  mostPopular: "Més popular",
  mostRecent: "El més recent",
  mr: "Sr.",
  mrs: "Sra.",
  ms: "Sra.",
  mx: "S.",
  myMembership: "La meva subscripció",
  nearlyThere: "Ja estem a punt d'acabar",
  next: "Següent",
  noAdditionalMembershipsAvailable: "Actualment, no hi ha opcions addicionals per a socis disponibles.",
  noAvailablePromotions: "No hi ha promocions disponibles.",
  noClubWelcomeSlotsError: "Ho sentim, en aquests moments no tenim hores online disponibles per la teva benvinguda al club. Si us plau, contacta amb nosaltres i trobarem una hora",
  noClubsInClubFinder: "No hi ha clubs en aquesta zona, tens la llista amb els clubs més propers més avall.",
  noJoiningFee: "Sense matrícula",
  noJoiningFeeOffer: "Oferta especial: Sense cost d'inscripció",
  noMembershipFeeForMonthOffer: "Oferta addicional: Inscripció gratis durant %month%",
  noMembershipFeeUntilMonthOffer: "Oferta addicional: Inscripció gratis fins a %month%",
  noPreferencePackageKey: "Sense preferència pel tipus de quota;",
  noPreferencePackageTypes: "Sense preferència pel tipus d'accès.",
  noTourSlotsError: "Ho sentim, no tenim horaris disponibles via online per la visita. Si us plau, posa't en contacte amb nosaltres i trobarem una data",
  normally: "Normalment",
  notAllMembershipsAvailable: "No estan disponibles totes les subscripcions",
  notSureWhichClubsNearest: "No estàs segur de quin és el teu club més proper",
  now: "Ara",
  onDate: "el %date%",
  onFacebook: "%clubName% a Facebook",
  onInstagram: "%clubName% a Instagram",
  onJoiningTheClubPayment: "En apuntar-te al club, et demanarem un pagament inicial que cobreix la matrícula i la prorrata del mes (és el pagament que faràs avui). Després es domiciliaran mensualment les quotes. Si vols millorar la teva experiència al club, pots afegir-ne més productes com entrenament personal o classes particulars, que també podràs pagar avui.",
  oneMonth: "Un mes",
  onlyAvailableForALimitedTime: "Només disponible durant un temps limitat",
  openingHours: "Horari d'obertura",
  optInToSpecialOffer: "Afegeix per a gaudir d'una oferta especial",
  optOutAtAnyTimeAfterPrivacyPolicy: "per a més informació!",
  optOutAtAnyTimeBeforePrivacyPolicy: "Sempre pots fer-te enrere. Consulta la nostra",
  options: "Opcions",
  organisation: "Organització",
  organisationOther: "Altre",
  other: "Un altre",
  ourEquipmentAt: "El nostre equipament a %clubName%",
  ourFriendlyTeamAreLookingForwardToMeetingYou: "El nostre equip està esperant la teva visita",
  outdoorPoolTimesWeatherDependent: "L'horari de la nostra piscina exterior depèn de les condicions climatològiques",
  over14EmailAddress: "Els socis majors de 14 anys poden utilitzar les instal·lacions del club. Introduïu la seva adreça de correu electrònic perquè es puguin inscriure.",
  over14PhoneNumber: "Els membres majors de 14 anys poden utilitzar l'aplicació mòbil %brandName%, introduïu el seu número de mòbil.",
  "package": "Paquet",
  packageSelected: "%packageName% seleccionat",
  padel: "Pàdel",
  paidOnline: "Pagat online",
  partner: "Associat",
  partnerEmail: "La teva parella rebrà un email per confirmar les seves dades i completar la seva alta. Si us plau, introdueix el número de telèfon i enviarem un link de registre a l'App %brandName%",
  partnerWasReset: "Lamentablement aquest paquet no té una opció per a parelles. Consideri actualitzar el seu paquet per a poder incloure a la seva parella.",
  partnersAndSponsors: "Col·laboradors i patrocinadors",
  password: "Contrasenya",
  passwordPlaceholder: "Confirma la teva nova contrasenya",
  payAnnually: "Pagament anual",
  payAtReception: "Pagar a la recepció",
  payMonthly: "Pagament mensual",
  payOnline: "Pagar online",
  payingAnnually: "El pagament anual sol ser més econòmic que el mensual",
  paymentAlreadyConfirmed: "El pagament s'ha confirmat, continua a la pàgina de pagament mensual",
  paymentDetails: "Dades de pagament",
  paymentLinkExpired: "L'enllaç de pagament ha caducat",
  paymentReceived: "Pagament rebut",
  paymentRefused: "Pagament refusat",
  penneArrabbiata: "Penne arrabbiata",
  perMonth: "al mes",
  personalInformation: "Informació personal",
  phoneNumber: "Número de telèfon",
  pickAnotherTime: "Escull una altra hora",
  pleaseCheckAndTryAgain: "Comprovi'l i torni a provar",
  pleaseCheckYourCardDetails: "Si us plau, comprova les dades de la teva tarjeta",
  pleaseCheckYourDetailsAndTryAgain: "Si us plau, comprova les teves dades i intenta-ho de nou. Si el problema continua, el nostre equip estarà encantat d'ajudar-te al club.",
  pleaseCheckYourEmail: "Si us plau, consulta el teu email",
  pleaseClickHereTo: "Si us plau, clica aquí per",
  pleaseContactOnlineJoining: "Si necessites ajuda, contacta amb %email%",
  pleaseDoubleCheckAndTryAgain: "Si us plau, comprova les teves dades i torna-ho a intentar",
  pleaseEnterANumber: "Introdueix un número",
  pleaseMakePaymentAtReception: "Si us plau, fes el pagament a recepció",
  pleasePickAnotherTime: "Si us plau, escull una altra hora.",
  pleaseRefreshOrTryAgainLater: "Actualitzi la pàgina o torni a intentar-lo més tard",
  pleaseSelectATime: "Si us plau, selecciona una hora",
  pleaseTryAgainLater: "Si us plau, prova-ho més tard",
  pleaseUseAnAlternativeBrowser: "Utilitza un navegador alternatiu com una versió actualitzada de Microsoft Edge, Google Chrome o Safari",
  pleaseVerifyCaptcha: "Si us plau, verifiqueu el captcha per continuar",
  policies: "Política",
  policiesAndDocuments: "Política i documents",
  poolOpeningHours: "Hores d'obertura de la piscina",
  poolOpeningTimes: "Horaris d'obertura de la piscina",
  poolTimes: "Hores de la piscina",
  pools: "Piscines",
  poolsAreClosed: "Totes les nostres piscines estan actualment tancades",
  postCode: "Codi postal",
  postCodePlaceholder: "Introdueix el teu codi postal",
  preferNotToSay: "prefereixo no dir-ho",
  press: "Premsa",
  pressCentre: "Centre de premsa",
  previous: "anterior",
  priceDuringPromotionalPeriod: "Preu durant la promoció",
  pricePerAdult: "%adultPrice% per adult",
  pricePerChild: "%childPrice% per nen",
  primaryMember: "Soci titular",
  privacyPolicy: "Política de Privacitat",
  promotionSelected: "Promoció seleccionada",
  promotionalItems: "Ítems promocionals",
  promotions: "Promocions",
  qrCodeExpired: "El codi QR ha caducat - cal pagar a recepció",
  quickRegistration: "Registre Ràpid",
  referral: "Referit",
  refundedIfYouJoinOnAStandardMembership: "S'aplica una tarifa si no t'apuntes en una modalitat estàndard",
  regionUnitedKingdomEast: "Est",
  regionUnitedKingdomGreaterLondon: "Greater London",
  regionUnitedKingdomMidlands: "Midlands",
  regionUnitedKingdomNorthEast: "Nord-est",
  regionUnitedKingdomNorthWest: "Nord-oest",
  regionUnitedKingdomNorthernIreland: "Irlanda del Nord",
  regionUnitedKingdomScotland: "Escòcia",
  regionUnitedKingdomSouth: "Sud",
  regionUnitedKingdomSouthEast: "Sud-est",
  regionUnitedKingdomSouthWest: "Sud-oest",
  regionUnitedKingdomWales: "Gal·les",
  registerForClubTrial: "Comença la prova de 14 dies avui",
  registerForClubTrialButton: "Comença la teva prova",
  registeredCompanyNumber: "Companyia registrada nº",
  registrationErrorMessage: "Comprovi que les seves dades siguin els correctes. Si té problemes registrant un compte, escrigui'ns a <a style=\"color: %emailColor%;\" href=\"mailto:%email%\">%email%</a> i algú es posarà en contacte amb vostè al més aviat possible.",
  registrationUnauthorised: "L'autorització ha fallat",
  relaxAmpersandSocialise: "Relax i social",
  relaxAndEnjoySpaRetreats: "Relaxa't i gaudeix dels Spa Retreat",
  relaxAndEnjoyTheSpaRetreatsAt: "Relaxa't i gaudeix del nostre spa a:",
  relaxAndSocialise: "Relax i social",
  remainderOfFirstMonth: "Resta del primer mes",
  remainderOfFirstMonthAnd12MonthsMembership: "Resta del primer mes i 12 mesos naturals de contracte",
  remainderOfFirstMonthAndFirstFullMonth: "Resta del primer mes i el primer mes complet",
  remainderOfThisMonth: "Pendent d'aquest mes",
  remainderOfThisMonthTooltip: "El pagament cobreix el cost de la quota pel que queda de mes",
  requestingAClubWelcome: "Sol·licitant una benvinguda al club",
  retry: "Reintenta-ho",
  saveAmount: "Estalvia %amount%",
  savingUpTo: "Un estalvi de fins a %price%*",
  scanQrCodeToDownload: "Escaneja el codi QR per a descarregar l'App",
  scanQrCodeToPay: "Escaneja el codi QR per anar a la pàgina de pagament",
  seeAllFacilities: "Mira totes les instal·lacions",
  seeDetails: "Consulta els detalls",
  seeMore: "Veure més…",
  selectADate: "Selecciona una data",
  selectAnOrganisation: "Selecciona una empresa o grup...",
  selectOptionsBelow: "Selecciona a les opcions més a baix per trobar activitats per a tota la família",
  selectPackage: "Selecciona %packageName%",
  selectPromotion: "Seleccionar promoció",
  selectTitle: "Seleccionar un tractament",
  selectedDuration: "Un contracte de %durationType% de duració",
  selectedPackageKey: "Una quota %packageKey% ",
  selectedPackageTypes: "Quota que inclou accès a %packageTypes%.",
  selectedTourTimeNoLongerAvailable: "Lamentablement, l'hora que has escollit per fer la visita ja no està disponible.",
  selectedTourTimeNoLongerAvailableHeader: "No està disponible aquest hora de visita",
  sendQuote: "Enviar pressupost",
  sepaGuaranteeLine1: "Firmant aquest formulari, vostè autoritza a (A) %companyName% a enviar instruccions a la seva entitat financera per a domiciliar al seu compte i (B) a l'entitat del debtor per a efectuar els càrrecs al seu compte seguint les instruccions de %companyName%.",
  sepaGuaranteeLine2: "Tens dret a que la teva entitat faci un reemborsament segons els termes i condicions del teu contracte amb el banc.  ",
  sepaGuaranteeLine3: "Pots reclamar un reemborsament durant les 8 setmanes següents a la data en què s'ha fet el càrrec al teu compte. ",
  sepaGuaranteeLine4: "Els teus drets s'expliquen al estat del compte que pots obtenir del teu banc.",
  sepaGuaranteeLine5: "Tipus de pagament: Pagament recorrent (es pot fer més d'un cop) ",
  sepaTitle: "Domiciliació SEPA",
  settingUpYourDirectDebit: "Gestionar la teva domiciliació",
  settingUpYourMonthlyPayment: "Configura el teu pagament mensual",
  shareOnFacebook: "Compartir en Facebook",
  shareOnTwitter: "Compartir en Twitter",
  signMeUp: "Dóna'm d'alta",
  signUpForAGradeCoaching: "Apunta't i tindràs el millor entrenament per totes les edats",
  skip: "Salta",
  skipPayment: "Ometre el pagament, vull pagar a recepció",
  slotsTakingAWhileToLoad: "Estem tardant una mica en trobar espais disponibles. Ho intentarem de nou, però mentre, pots contactar-nos directament amb les dades que trobaràs més a baix.",
  snrgyFamilyFitness: "Synrgy en família",
  socialMedia: "Xarxes socials",
  softPlay: "Jocs tous",
  somethingHasGoneWrong: "Hi ha hagut un error",
  sorryIeNotSupported: "Ho sentim, Internet Explorer no és compatible",
  sorrySomethingHasGoneWrongPleaseTryAgain: "Ho sentim, hi ha hagut un error. Torna a provar",
  sorrySomethingWentWrong: "Ho sentim, hi ha hagut un error",
  sorryThereWasAProblemWithYourPayment: "Ho sentim, hi ha hagut un problema amb el teu pagament",
  sorryWereHavingTechnicalProblems: "Ho sentim, tenim problemes tècnics",
  sortBy: "Ordenar per:",
  sortByMyLocation: "Ordena per la meva ubicació",
  sortCode: "Gestionar un codi",
  spa: "Spa",
  specialHours: "Horaris especials",
  specialOffer: "Oferta especial",
  specialsAndEvents: "Especials i Esdeveniments",
  sportsOverview: "Informació esportiva",
  standard: "Estàndard",
  startDate: "Data d'inici",
  startInNextWeek: "Comenci la seva subscripció qualsevol dia de la pròxima setmana",
  startOnFixedDate: "Pots començar com a soci el",
  stateOfTheArtGymFacilities: "Gimnàs d'última generació",
  studioOpeningHours: "Horaris de les sales",
  studioOverview: "Descobreix les nostres sales",
  submitAndStartMembership: "Enviar i començar a gaudir de la meva subscripció",
  swimmingAt: "Piscina a %clubName%",
  swimmingCoaching: "Classes de natació",
  swimmingPools: "Piscines",
  tailorYourClubWelcome: "Personalitza la teva benvinguda al club.",
  tailorYourTour: "Dissenya la teva visita",
  takeOurVideoTour: "Mira el nostre video per a fer-te una idea de l’experiència als %brandName%*",
  takeTheNextStep: "Fes el següent pas",
  takeTheNextStepDescription: "Vols saber com apuntar-te a %clubPrefix% %clubName% pot transformar-te a tu i canviar el temps que passes en família?",
  taxId: "Número de Identificación",
  telephone: "Telèfon",
  tennis: "Tennis",
  tennisClub: "Club de tennis",
  tennisCoaching: "Classes de tennis",
  termsAndConditions: "Condicions",
  textMessage: "Missatge",
  thankYouForSettingUpDirectDebit: "Gràcies per crear la seva domiciliació amb nosaltres",
  thankYouForSubscribing: "Gràcies per subscriure't!",
  thankYouWelcomeToBrand: "Gràcies, benvingut a %brandName%!",
  theClub: "El club",
  thereAreCurrentlyNoMembershipPackages: "Sentim que t'ho hagis perdut; però ja hem tancat el període d'inscripció anticipada. Si encara no t'has registrat, fes clic al botó de dalt i ens assegurarem d'enviar-te un recordatori quan s'obri la propera oportunitat d'apuntar-te.",
  threeMonths: "3 mesos",
  timetable: "Horaris",
  title: "Tractament",
  today: "Avui",
  tomorrow: "Demà",
  totalDue: "Import a pagar",
  totalDueToday: "Total a abonar",
  totalSavedJoiningToday: "Total d'estalvi si s'inscriu avui",
  townOrCity: "Municipi / Ciutat",
  townOrCityPlaceholder: "Introdueix el teu municipi o ciutat",
  treatments: "Spa de Dia Face & Body",
  treatmentsOverview: "Informació dels tractaments",
  unableToOfferTrial: "Ho sentim, no tenim ofertes de prova en aquests moments",
  unableToTakeClubWelcomeBookings: "Ho sentim, no podem fer reserves online en aquests moments - contacta amb nosaltres a les dades que trobaràs més avall per reservar la teva benvinguda al club",
  unableToTakeTourBookings: "Ho sentim, no podem fer reserves online pel moment - pots contactar amb nosaltres per concertar la visita amb els detalls que trobaràs més a baix",
  unexpectedError: "Error inesperat",
  unfortunatelyAnErrorHasOccurred: "Ho sentim, hi ha hagut un error",
  unfortunatelyThisTimeIsNoLongerAvailable: "Ho sentim, aquesta hora no està disponible",
  unknownMembershipError: "La teva alta pot haver-se registrat correctament. Si us plau, comprova que has rebut un e-mail de confirmació. Si no el reps en una hora, torna-ho a provar",
  unsubscribe: "Donar-se de baixa",
  updateAppPrompt: "Si us plau, actualitza l'App amb la última versió i torna-ho a intentar.",
  upgradeYourMembershipToAddChildren: "Actualitzi la subscripció per afegir un fill.",
  upgradeYourMembershipToAddPartner: "Actualitzi la seva subscripció si desitja afegir a un soci.",
  useMyLocation: "Busca per ubicació",
  userAlreadyExists: "Aquest número de soci ja està sent utilitzat",
  userInputOrganisation: "Quin és el nom de la teva empresa o grup?",
  userVerificationFailed: "La verificació ha fallat",
  usualJoiningFees: "Despeses d'inscripció habituals",
  vatNumber: "IVA No.",
  viewAllClubs: "Descobreix tots els clubs",
  viewInMaps: "Veure al maps",
  viewMap: "View a map of all David Lloyd Clubs",
  viewOurFoodMenu: "Consultar el nostre menú",
  viewTimetable: "Consultar els horaris",
  visitSpaRetreats: "Visita Spa Retreat",
  was: "Estava",
  weAlreadyHaveYourDetails: "%firstName%, ja tenim les seves dades en el sistema.",
  weDontRecogniseThatLocation: "Ho sentim, no podem reconèixer la ubicació",
  weJustNeedAFewMoreDetails: "Només necessitem alguns detalls més",
  weSendOurMostExcitingOffers: "T'enviarem les millors ofertes per email",
  weWereUnableToSaveDetails: "Ho sentim, no podem guardar les teves dades",
  weekend: "Cap de setmana",
  weeklyTimetable: "Horari setmanal",
  welcomeTo: "Benvingut a",
  whatToExpect: "Què hi puc esperar?",
  whatsApp: "WhatsApp",
  whereToFindUs: "On trobar-nos",
  whoCanCome: "Qui pot venir?",
  whosComing: "Qui vindrà?",
  workEmailAddress: "Direcció d'email de la feina",
  years: "Anys",
  youngChildren: "Nadons",
  yourCardDetails: "Les dades de la teva targeta",
  yourChild: "el teu fill (%range%)",
  yourChildren: "els teus fills (%range%)",
  yourClubWelcomeIsBooked: "%name%, hem reservat la teva benvinguda al club",
  yourDetails: "Les vostres dades",
  yourFirstFullMonth: "El teu primer mes complet (%month%)",
  yourFirstFullMonthTooltip: "El pagament cobreix el cost de les quotes fins que establim la domiciliació amb el teu banc",
  yourMembership: "La teva quota",
  yourMembershipTrial: "La teva prova al club",
  yourNannies: "Els cangurs",
  yourNanny: "El cangur",
  yourOlderChild: "El teu fill (nen) (%range%)",
  yourOlderChildren: "Els teus fills (nens) (%range%)",
  yourPartner: "La teva parella",
  yourSavings: "El seu estalvi",
  yourSavingsSubtitle: "Aquí pot veure el que pot estalviar-se si s'inscriu avui",
  yourStartDate: "La teva data d'inici",
  yourTourIsBooked: "%name%, el teu tour ja està reservat",
  yourYoungChild: "El teu fill (nadó) (%range%)",
  yourYoungChildren: "Els teus fills (nadons) (%range%)"
};
