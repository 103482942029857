import React, { useContext } from 'react';
import {
  ClubFacilityKeyWithVideo,
  getFacilitiesAtClub,
} from 'src/components/facilities/facilities';
import { EnabledPages } from 'src/content/enabled-pages-helper';
import { ClubFacilitiesPage } from 'src/models/cms/navigation-menu-content';
import { clubFacilitiesMobileNavHeight } from 'src/styling/constants';
import mq from 'src/styling/media-queries';
import ClubFacilitiesMobileNavItem from './club-facilities-mobile-nav-item';
import { getBuildDomainForBuildType, newWebsiteBuildDomains } from 'src/enums/build/build-domain';
import { SiteContext } from 'src/context/site-context';

type ClubFacilitiesMobileNavProps = {
  clubFacilities: ClubFacilityKeyWithVideo[];
  clubFacilitiesPages: ClubFacilitiesPage[];
  enabledPages: EnabledPages;
};

const ClubFacilitiesMobileNav: React.FC<ClubFacilitiesMobileNavProps> = ({
  clubFacilitiesPages,
  clubFacilities,
  enabledPages,
}) => {
  const { buildType, styling } = useContext(SiteContext);
  const facilitiesAtClub = getFacilitiesAtClub(clubFacilities, enabledPages, clubFacilitiesPages);

  const facilitiesToDisplay = facilitiesAtClub
    .map((facility) => clubFacilitiesPages.find((page) => page.link.includes(facility.urlPath)))
    .filter((f) => !!f);
  const isNewDesign = newWebsiteBuildDomains.includes(getBuildDomainForBuildType(buildType));
  return (
    facilitiesToDisplay.length > 0 && (
      <div
        css={mq({
          display: ['flex', 'none'],
          justifyContent: 'space-between',
          flexWrap: 'nowrap',
          position: 'relative',
          backgroundColor: isNewDesign ? styling.colors.headerBackground : 'white',
          height: clubFacilitiesMobileNavHeight,
          overflowX: 'auto',
          overflowY: 'hidden',
          scrollbarWidth: 'none',
          padding: '.2rem 0',
          boxShadow: '0 .625rem 1.25rem 0 #0000000d',
        })}
      >
        {facilitiesToDisplay.map((item: ClubFacilitiesPage) => {
          return (
            <ClubFacilitiesMobileNavItem
              displayText={item.displayText}
              link={item.link}
              mobileNavIcon={item.mobileNavIcon}
              facility={item.facility}
              key={item.displayText}
            />
          );
        })}
      </div>
    )
  );
};

export default ClubFacilitiesMobileNav;
