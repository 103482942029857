export enum ProdBuildState {
  ALL,
  TEST,
  NO_JOIN_ONLINE,
  ENQUIRY_TOUR_ONLY,
  NONE,
}

export function shouldBuildPageForProdBuildState(page: string, prodBuildState: ProdBuildState) {
  switch (prodBuildState) {
    case ProdBuildState.ALL:
    case ProdBuildState.TEST:
      return true;
    case ProdBuildState.NO_JOIN_ONLINE:
      return !page.includes('join-online');
    case ProdBuildState.ENQUIRY_TOUR_ONLY:
      return page.includes('enquire-now') || page.includes('book-a-tour');
    case ProdBuildState.NONE:
      return false;
  }
}

export function shouldBuildClubPageForProdBuildState(prodBuildState: ProdBuildState) {
  return shouldBuildPageForProdBuildState('/clubs', prodBuildState);
}

export function shouldIncludeInLists(prodBuildState: ProdBuildState) {
  return [ProdBuildState.ALL, ProdBuildState.NO_JOIN_ONLINE].indexOf(prodBuildState) > -1;
}

export function shouldIncludeInOnlineJoiningLists(prodBuildState: ProdBuildState) {
  return prodBuildState === ProdBuildState.ALL;
}

export function shouldIncludeInClubFinder(prodBuildState: ProdBuildState) {
  return (
    [ProdBuildState.ALL, ProdBuildState.NO_JOIN_ONLINE, ProdBuildState.ENQUIRY_TOUR_ONLY].indexOf(
      prodBuildState
    ) > -1
  );
}
