import { WindowLocation } from '@gatsbyjs/reach-router';
import { getClubInformation } from '../content/club-look-ups-by-id';
import { Language } from '../enums/language';
import { translatePath } from '../translators/links-translator';

export const trimPath = (path: string) => path?.replace(/^\/+/, '').replace(/\/+$/, '') ?? '';

export const isOnGlobalPage = (
  location: WindowLocation,
  path: string,
  language: Language
): boolean => {
  const translatedPath = translatePath(path, language);
  const trimmedPath = trimPath(translatedPath);
  return location.pathname.endsWith(`/${trimmedPath !== '' ? '/' : ''}${trimmedPath}/`);
};

export const isOnClubPage = (
  location: WindowLocation,
  siteId: string,
  path: string,
  language: Language
): boolean => {
  const clubInformation = getClubInformation(siteId);
  const translatedPath = translatePath(path, language);
  const trimmedPath = trimPath(translatedPath);
  return location.pathname.endsWith(
    `/clubs/${clubInformation.hyphenatedClubName}${trimmedPath !== '' ? '/' : ''}${trimmedPath}/`
  );
};

export const isOnClubsPage = (location: WindowLocation): boolean => {
  return location.pathname.endsWith(`/clubs/`);
};

export const isChildOfClubPage = (
  location: WindowLocation,
  siteId: string,
  path: string
): boolean => {
  const clubInformation = getClubInformation(siteId);
  const trimmedPath = trimPath(path);
  return location.pathname.includes(
    `/clubs/${clubInformation.hyphenatedClubName}${trimmedPath !== '' ? '/' : ''}${trimmedPath}`
  );
};
