import React, { useContext } from 'react';

import { getClubsLinkedToBuildType } from 'src/content/club-look-ups-by-id';
import { SiteContext } from 'src/context/site-context';
import imageFade from 'src/helpers/image-fades';
import { getAbsoluteClubPagePath, getClubPagePath } from 'src/helpers/navigation-helper';
import { flex } from 'src/styling/constants';
import FadedImage from '../common/faded-image';
import mq from 'src/styling/media-queries';
import { getClubIntroImage } from 'src/helpers/fixed-images';
import { Link } from 'gatsby';

type HeaderClubSelectProps = {
  onFinish?: () => void;
};

const HeaderClubSelect: React.FC<HeaderClubSelectProps> = ({ onFinish }) => {
  const { styling, language, buildType } = useContext(SiteContext);
  const clubs = getClubsLinkedToBuildType(buildType);

  return (
    <div
      css={[
        flex.row,
        {
          justifyContent: 'center',
          flexWrap: 'wrap',
        },
        mq({
          padding: ['0.5rem 0 1rem', '2.5rem 10rem 1rem'],
          maxHeight: ['calc(100vh - 11rem)', 'unset'],
          overflowY: ['auto', 'unset'],
        }),
      ]}
    >
      {clubs.map((club) => {
        const linkContent = (
          <FadedImage
            image={getClubIntroImage(club.introImageKey)}
            fixedImage
            shadow={true}
            fadeType={imageFade.fullFade}
          >
            <div className="f5 w-100 h-100 flex flex-column justify-end white pa2 lh-title">
              <div css={[styling.fonts.regular, { fontSize: '1.25rem' }]}>{club.clubName}</div>
            </div>
          </FadedImage>
        );
        return (
          <div
            key={club.siteId}
            css={[
              mq({
                width: ['10rem', '12rem'],
                margin: ['0 0.5rem 1rem', '0 1.5rem 1.5rem 0'],
              }),
            ]}
          >
            {club.buildType !== buildType ? (
              <a
                href={getAbsoluteClubPagePath('', club.siteId, language)}
                target="_blank"
                onClick={onFinish}
              >
                {linkContent}
              </a>
            ) : (
              <Link to={getClubPagePath('', club.siteId, language)} onClick={onFinish}>
                {linkContent}
              </Link>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default HeaderClubSelect;
