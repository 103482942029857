import { BuildType } from 'src/enums/build/build-type';
import { documentIsDefined } from 'src/helpers/document-helper';

export const resetCookie = (key: string): void => {
  if (documentIsDefined()) {
    document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }
};

export const resetAllCookies = (): void => {
  if (documentIsDefined()) {
    document.cookie.split(';').forEach((cookie) => {
      const parts = cookie.split('=');
      if (parts.length === 2) {
        const key = parts[0];
        resetCookie(key);
      }
    });
  }
};

export const cookieConsentIsNecessaryForBuildType = (buildType: BuildType): boolean => {
  return buildType === BuildType.MERIDIAN;
};
