import React, { ReactNode, useContext, useEffect, useRef } from 'react';
import { Styling } from 'src/context/styling';
import { CSSInterpolation } from '@emotion/serialize';
import { SiteContext } from 'src/context/site-context';
import { flex } from 'src/styling/constants';
import { mqReducedMotion } from 'src/styling/media-queries';
import HeaderMobileBottomNav from './header-bottom-nav';

const createComponentStyling = (styling: Styling) =>
  ({
    container: {
      position: 'absolute',
      overflow: 'hidden',
      width: '100%',
      height: '100%',
      paddingTop: '0.125rem',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      color: styling.colors.capeCod,
    },
    wrapper: {
      flexGrow: 1,
      padding: '0 1.25rem 5rem',
      overflowY: 'scroll',
    },
  }) satisfies Record<string, CSSInterpolation>;

const createAnimationStyles = (props: HeaderMobileSubMenuWrapperProps): CSSInterpolation => {
  const { level, isMenuOpen, isVisible, isChildMenuVisible } = props;

  const leftValue = () => {
    if (isVisible) {
      return 0;
    }

    if (isChildMenuVisible) {
      return '-100%';
    }

    return level === 1 ? 0 : '100%';
  };

  const baseTransition = 'left 0.4s ease-in-out, opacity 0.4s ease-out';

  return mqReducedMotion({
    transition: isVisible ? baseTransition : baseTransition + ', visibility 0.4s',
    transitionDelay: isMenuOpen ? undefined : '0.4s',
    visibility: isVisible ? 'visible' : 'hidden',
    opacity: [1, isVisible ? 1 : 0],
    left: [leftValue(), 0],
  });
};

export type HeaderMobileSubMenuWrapperProps = {
  children: ReactNode;
  isMenuOpen: boolean;
  isVisible: boolean;
  isChildMenuVisible: boolean;
  openClubSearch: () => void;
  level?: number;
  onClickLink?: () => void;
};

const HeaderMobileSubMenuWrapper = (props: HeaderMobileSubMenuWrapperProps) => {
  const { styling } = useContext(SiteContext);
  const componentStyling = createComponentStyling(styling);
  const scrollDivRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (props.isVisible) {
      return;
    }

    const timeout = setTimeout(() => {
      scrollDivRef.current?.scrollTo(0, 0);
    }, 400);

    return () => clearTimeout(timeout);
  }, [props.isVisible]);

  return (
    <div
      className="header-sub-menu"
      css={[componentStyling.container, flex.column, createAnimationStyles(props)]}
    >
      <div css={componentStyling.wrapper} ref={scrollDivRef}>
        {props.children}
      </div>
      {props.level === 1 && (
        <HeaderMobileBottomNav
          isMenuOpen={props.isMenuOpen}
          openClubSearch={props.openClubSearch}
          onClickLink={props.onClickLink}
        />
      )}
    </div>
  );
};

export default HeaderMobileSubMenuWrapper;
