export enum City {
  BARCELONA = 'BARCELONA',
  BRISTOL = 'BRISTOL',
  BRUSSELS = 'BRUSSELS',
  EDINBURGH = 'EDINBURGH',
  GLASGOW = 'GLASGOW',
  HAMBURG = 'HAMBURG',
  LEICESTER = 'LEICESTER',
  LIVERPOOL = 'LIVERPOOL',
  LONDON = 'LONDON',
  MADRID = 'MADRID',
  MANCHESTER = 'MANCHESTER',
  NOTTINGHAM = 'NOTTINGHAM',
  ROTTERDAM = 'ROTTERDAM',
  SOUTHAMPTON = 'SOUTHAMPTON',
}

export const getAllCities = () => Object.values(City);
