export const staticTextLookup = {
  FAQs: "FAQ",
  aCopyOfThisGuaranteeShouldBeRetained: "Een kopie van deze garantie moet door de klant worden bewaard en wordt per e-mailbevestiging verzonden",
  about: "Over ons",
  aboutYou: "Over uzelf",
  accessToClubsAndSpaRetreats: "Platinum membership will also give you access to other David Lloyd clubs. See below for details of the clubs you'll be able to access.\nPlus, if the club has a Spa Retreat, this will also include access to the Spa Retreat facilities. Click here for a list of clubs with Spa Retreat.",
  accessToClubsNearYou: "Toegang tot clubs in de buurt",
  accessToOtherClubs: "Toegang tot andere clubs",
  accountHoldersName: "Naam van de rekeninghouder",
  accountNumber: "Rekeningnummer",
  activating: "Activating",
  addPartnerAmount: "Voeg een partner toe %amount%",
  addSelectedMembership: "Geselecteerd lidmaatschap toevoegen",
  addSelectedPromotion: "Voeg geselecteerde promotie toe",
  addToCalendar: "Voeg Toe Aan Kalender",
  addYourAssociates: "Voeg uw partner en kinderen toe",
  addYourChildren: "Voeg uw kinderen toe",
  addYourPartner: "Voeg uw partner toe",
  addYourPromotions: "Selecteer uw promotie",
  additionalDetailsSecondErrorMessage: "Het spijt ons, we kunnen op dit moment uw gegevens niet online verwerken, we zullen dit doen bij uw eerste bezoek aan de club.",
  additionalMembers: "Bijkomende leden",
  additionalMemberships: "Bijkomende lidmaatschappen",
  address: "Adres",
  addressLine1: "Adreslijn 1",
  addressLine2: "Adreslijn 2",
  addressLinePlaceholder: "Voer je adres in",
  adultMembers: "Leden, volwassenen",
  adults: "Volwassenen",
  afterSchool: "Na school",
  allAgesWelcome: "Voor alle leeftijden",
  allFacilities: "Alle uitrustingen",
  alsoAtClub: "Ook in %clubName%",
  amountSubjectToPendingPriceRise: "Het vermelde bedrag is onderhevig aan eventuele prijsstijgingen in behandeling. Neem contact op met het clubteam voor meer informatie.",
  and: "en",
  annualOnJoiningTheClubPayment: "Wanneer u lid wordt van de club, wordt u gevraagd om een betaling te doen bestaande uit een inschrijfgeld, een pro rata betaling voor de rest van deze maand (indien van toepassing) en een betaling die de kosten van het lidmaatschap voor 12 kalendermaanden dekt. Als u uw lidmaatschapservaring wilt verbeteren, kunt u aanvullende producten toevoegen, zoals persoonlijke training of coaching, dit kan ook vandaag worden betaald.",
  annually: "Jaarlijks",
  anythingElseYoudLikeUsToKnow: "Wat wil je ons nog vertellen?",
  anytimeClubAccess: "Toegang tot de Club wanneer u maar wil",
  app: " App",
  appQrCodeInstructions: "Plaats gewoon de camera van uw telefoon op de code en zodra deze is herkend, tikt u op de link om de app %brandName% te downloaden.",
  appUpdateRequired: "App-update vereist",
  areYouSureYouDoNotWantToBeContacted: "Ben je zeker dat je niet wil gecontacteerd worden via mail of sms?",
  associatesWereReset: "Jammer genoeg ondersteunt dit pakket niet alle extra leden die u geselecteerd hebt - kiest u alstublieft een ander pakket wanneer u deze leden wilt toevoegen.",
  atClub: "In %clubName%",
  atHomeDigitalContent: "@home digitaal aanbod van lessen, informatieve seminars en tips voor uw training",
  atUnit: "In %unitName%",
  availablePromotions: "beschikbare promoties",
  availableSlot: "%numberOfSlots% moment beschikbaar",
  availableSlots: "%numberOfSlots% momenten beschikbaar",
  awaitingPayment: "Awaiting payment",
  back: "Terug",
  backToTop: "Terug naar boven",
  beforeYouBecomeAMember: "Voor je lid wordt, tonen we je hoe je het meeste uit deze club kan halen",
  bestValue: "Beste prijs-kwaliteit",
  bookAClass: "Boek een les",
  bookAClubWelcome: "Boek een club welkom",
  bookAClubWelcomeError: "Sorry, er is iets misgegaan. Contacteer ons om een moment te vinden.",
  bookATour: "Reserveer een bezoek",
  bookATourError: "Sorry, er is iets misgegaan. Contacteer ons om een moment te vinden.",
  bookATourHeader: "Boek je rondleiding",
  bookATourSubtext: "Kies hieronder een club om een rondleiding te boeken",
  bookOnTheMove: "Reserveer waar je ook bent",
  bookYourClubWelcome: "Boek uw clubontvangst",
  bookYourClubWelcomeAt: "Boek uw clubontvangst bij %clubName%",
  bookYourTour: "Reserveer je bezoek",
  bookYourTourOf: "Reserveer je bezoek aan %clubName%",
  booked: "Gereserveerd",
  bookingYourClubWelcome: "Reserveer jouw club welkom...",
  bookingYourTour: "Reservatie bezig…",
  buyapowaWarningPopupCancelButtonText: "Kies een andere club",
  buyapowaWarningPopupConfirmButtonText: "Word lid van %clubName%",
  buyapowaWarningPopupHeader: "Let op",
  buyapowaWarningPopupText: "Club %clubName% is momenteel niet ingeschreven voor ons verwijs-een-vriend-schema, dus noch jij, noch de persoon die je heeft doorverwezen, komen in aanmerking voor beloningen als je doorgaat met deze aanmelding.",
  byClickingFinishYouAcceptDDConditions: "Door op '%buttonText%' te klikken, erkent u dat u de rekeninghouder bent en dat een andere persoon geen toestemming hoeft te geven voor de afschrijvingen op deze rekening. Als het een persoonlijke of zakelijke rekening betreft en er is meer dan één persoon nodig om machtiging te verlenen voor afschrijvingen op deze rekening, neem dan rechtstreeks contact met ons op.",
  cancel: "Annuleer",
  cancelMyMembership: "Zeg mijn lidmaatschap op",
  cantFindASlot: "Vind je geen vrij moment?",
  cardCollectionTime: "Uur om kaart op te halen/club te bezoeken",
  careers: "Vacatures",
  changeClub: "Van club veranderen",
  changePreferences: "Voorkeuren aanpassen",
  changeSelectedPackage: "Wijzig het geselecteerde lidmaatschap",
  checkYourAccessToASpecificClub: "Check je toeganf tot een specifieke club",
  child2AndUnder: "Lidmaatschap voor kinderen:\nTot 2 jaar",
  child3To18: "Lidmaatschap voor kinderen:\n3-18 jaar",
  childMembers: "Leden, kinderen",
  children: "Kinderen",
  childrenOverview: "Overzicht voor kinderen",
  chooseAClub: "Kies een club",
  chooseAClubToRegisterForClubTrial: "Kies hieronder een club",
  chooseHowYouPay: "Kies hoe je betaalt",
  chooseMembershipLength: "Kies de duurtijd van uw lidmaatschap",
  chooseYourStartDate: "Kies uw startdatum",
  clickHereIfYouAreNot: "Klik hier als u niet %firstName% bent",
  close: "Sluiten",
  closeDetails: "Details sluiten",
  club: "Club",
  clubEmail: "Club e-mail",
  clubFacilities: "Uitrustingen van de club",
  clubFinderSearchPlaceholder: "Voer een locatie in",
  clubOpeningHours: "Openingstijden club",
  clubOpeningTimes: "Openingsuren club",
  clubOverview: "Overzicht club",
  clubTourTime: "Bezoekuur",
  clubVideo: "Video club",
  clubWelcomeMeetingTime: "Tijdstip welkomstbijeenkomst van de club",
  comingSoon: "Binnenkort verwacht",
  completeDirectDebitPopupText: "Bespaar tijd op uw eerste bezoek door uw domiciliëring nu in te stellen.",
  completeForm: "Gelieve het formulier onderaan te vervolledigen.",
  completeItInClub: "Vervolledig domiciliëring in Club",
  completeItNow: "Vervolledig domiciliëring nu",
  completeSEPAInClub: "Vervolledig domiciliëring in Club",
  completeSEPANow: "Vervolledig domiciliëring nu",
  completeSEPAPopupText: "Bespaar tijd op uw eerste bezoek door uw domiciliëring nu in te stellen.",
  confirm: "Bevestig",
  confirmNoPaymentTaken: "Bevestig dat er geen betaling is verwerkt",
  confirmPassword: "Bevestig wachtwoord",
  confirmPasswordPlaceholder: "Bevestig uw nieuw paswoord",
  confirmPaymentTaken: "Bevestig dat de betaling is verwerkt",
  contact: "Contact",
  contactInformation: "Contactinformatie",
  contactMeToArrange: "Contacteer me voor een afspraak",
  contactNotPromotedMonthlyPaymentErrorSubtitle: "Het opzetten van uw lidmaatschap duurt even achter de schermen. Probeer het opnieuw, maar maak u geen zorgen, er worden geen twee keer kosten in rekening gebracht.",
  contactNotPromotedMonthlyPaymentErrorTitle: "Sorry, we zijn niet zo snel als u vandaag!",
  contactPreferencesAfterPrivacyPolicy: "voor alle details. Vink de vakjes aan om in contact te blijven.",
  contactPreferencesBeforePrivacyPolicy: "We houden je graag op de hoogte van onze producten, diensten en aanbiedingen. Vink onderstaande vakjes aan als je akkoord bent. Je kan op elk moment van gedacht veranderen. Zie onze",
  contactTheClubForOpeningTimes: "Contacteer de club voor meer info over de openingstijden",
  "continue": "Verdergaan",
  cookiePolicyDescription: "Onze website gebruikt cookies om je ervaring te verbeteren. Door deze site te gebruiken, aanvaard je ons gebruik van cookies.",
  cookiesPolicy: "Cookiebeleid",
  copyright: "Copyright",
  coreStrengthAndHIIT: "Kracht & Conditie, HIIT, Wellzijn en welbeleven, Yoga, Cycling",
  corporateFitness: "Unternehmensfitness",
  countryBelgium: "Belgium",
  countryFrance: "France",
  countryGermany: "Germany",
  countryIreland: "Ireland",
  countryItaly: "Italy",
  countryNetherlands: "Netherlands",
  countrySpain: "Spain",
  countrySwitzerland: "Switzerland",
  countryUnitedKingdom: "United Kingdom",
  county: "Provincie",
  countyPlaceholder: "Voer je land in",
  couple: "Koppel",
  creche: "Kinderopvang",
  creditorAddress: "Geregistreerd adres",
  creditorId: "Incassant-id",
  creditorName: "Bedrijfsnaam",
  dateAtTime: "op %date% om %time%",
  dateOfBirth: "Geboortedatum",
  days: "dagen",
  daytime: "Overdag",
  detailsAddedToMembershipAccount: "Deze details worden nu toegevoegd aan uw lidmaatschapsaccount.",
  diamondPlusRequired: "Diamond Plus-lidmaatschap is vereist om toegang te krijgen tot bepaalde Spa Retreats",
  directDebitClubOpens: "Je eerste automatische betaling gaat in bij de opening van de club, rekening houdend met eventuele promoties die gelden op het moment dat je lid wordt",
  directDebitClubReopens: "Uw maandelijkse betaling zal starten wanneer de club heropent en zal rekening houden met in voege zijnde promoties",
  directDebitMandate: "Machtiging voor domiciliëring",
  directDebitMembershipStarts: "Je eerste automatische betaling gaat in wanneer je lidmaatschap start, rekening houdend met eventuele promoties die gelden op het moment dat je lid wordt",
  discoverTheCreche: "Ontdek de kinderopvang",
  done: "Gereed",
  downloadAppPrompt: "Download de David Lloyd Clubs App om boekingen te maken en uw lidmaatschap te beheren.",
  downloadDescriptionMembershipVerification: "Download vóór je bezoek onze app, zodat we je kunnen helpen in te schrijven en maximaal te profiteren van je abonnement.",
  downloadDescriptionTennis: "Download de app om overal waar je wil tijdschema’s te bekijken, een les of tennisbaan te boeken.",
  downloadDescriptionTrialConfirmation: "Download ze vóór je eerste bezoek, zodat we je kunnen helpen in te schrijven en maximaal te profiteren van je abonnement.",
  downloadTheMobileAppHere: "Download de app hier:",
  dr: "Dr.",
  duration: "Duur",
  edit: "Wijzigen",
  email: "E-mail",
  emailAddress: "E-mailadres",
  emailAddressesMustBeUnique: "Voer een uniek e-mailadres in. Het moet afwijken van het e-mailadres dat door het primaire lid is opgegeven",
  endsIn: "Eindigt binnen",
  enjoyTimeTogetherAt: "Geniet van momenten samen in %clubPrefix% %clubName%",
  enquireAboutMembership: "Info over het abonnement",
  enquireNow: "Info vragen",
  enquireText: "Vertel ons over uw aanvraag",
  enterALocation: "Vul locatie in",
  enterValidEmailAddress: "Gelieve een geldig e-mailadres in te geven",
  enterValidMobilePhoneNumber: "Voer een geldig gsm-nummer in",
  enterValidName: "Voer een geldige naam in, inclusief een hoofdletter",
  enterValidPhoneNumber: "Voer een geldig telefoonnummer in",
  enterValidPostCode: "Voer een geldige postcode in",
  enterValidTaxId: "Please enter a valid tax ID",
  equipmentAt: "Onze uitrusting in %clubName%",
  exerciseClasses: "Gymlessen",
  exerciseClassesAt: "Gymlessen in %clubName%",
  experienceAllTheBenefits: "Geniet van alles voordelen van een clubabonnement.",
  expertPersonalTrainers: "Ervaren personal trainers",
  expertTuition: "Les van experts",
  exploreClub: "Verken %clubName%",
  exploreKidsActivities: "Verken de activiteiten voor kids",
  failedToFetchExistingPayment: "A payment has already been made, but we encountered an error confirming details",
  familyAmpersandKids: "Gezinnen & kids",
  familyAndKids: "Gezinnen & kids",
  familySwim: "Zwemmen met het gezin",
  female: "Vrouwelijk",
  findAClub: "Vind een club",
  findOutMore: "Meer weten",
  findYourClub: "Vind je club",
  firstFullMonth: "Eerste volledige maand",
  firstMonthFees: "Aangezien we uw domiciliëring niet op tijd kunnen instellen voor uw eerste volledige maand, wordt deze betaling vooraf afgeschreven",
  firstName: "Voornaam",
  fitnessOverview: "Overzicht fitness",
  fixedTermFee: "Twaalf maanden lidmaatschap vanaf %month%",
  fixedTermFeeTooltip: "Betaling ter dekking van de kosten van het lidmaatschap voor 12 kalendermaanden",
  flexible: "Flexibel",
  followUs: "Volg ons",
  followedByMonthly: "Gevolgd door maandelijkse betaling van",
  followedByMonthlyPaymentsTitle: "Gevolgd door maandelijkse betalingen",
  free: "Gratis",
  frequentlyAskedQuestions: "Veel Gestelde Vragen",
  friend: "Vriend",
  friendsAndFamilyReferralAlreadyUsed: "Sorry, deze link is reeds in gebruik. Gelieve de persoon die u nomineerde te verzoeken u opnieuw te nomineren.",
  friendsAndFamilyReferralExpired: "Sorry, deze link is verlopen. Neem contact op met de persoon die je heeft doorverwezen om je opnieuw te nomineren.",
  friendsAndFamilyReferralNotFound: "Sorry, deze link werd niet gevonden. Gelieve de persoon die u nomineerde te verzoeken u opnieuw te nomineren.",
  from: "Van",
  from3MonthsPlus: "Vanaf 3 maanden",
  from3To18Years: "3-18 jaar",
  gender: "Geslacht",
  germanAndFallbackOnly: "Test",
  getInTouch: "Contacteer ons",
  getStarted: "Starten",
  getYourQuoteContactPreferencesAfterPrivacyPolicy: "voor alle details. Vink het vakje aan om in contact te blijven.",
  getYourQuoteContactPreferencesBeforePrivacyPolicy: "We blijven graag in contact met meer info over onze producten, diensten en aanbiedingen. Vink onderstaand vakje aan als je akkoord bent. Je kan op elk moment van gedacht veranderen. Zie onze",
  groupExerciseClasses: "Groepslessen",
  gym: "Sportschool",
  havingProblemsSavingMonthlyPaymentDetails: "Sorry, we zijn vandaag niet zo snel als u! Het opzetten van uw lidmaatschap duurt een paar ogenblikken achter de schermen. Probeer het opnieuw, maar maak u geen zorgen de kosten zullen geen twee keer in rekening worden gebracht.",
  healthyBreakfast: "Gezond ontbijt",
  heatedSwimmingPools: "Verwarmde zwembaden",
  highlightsAtClub: "Het beste van %clubPrefix% %clubName%*",
  hours: "uren",
  howCanWeContactYou: "Hoe kunnen we je contacteren?",
  iAgree: "Ik ga akkoord",
  ifSomethingWereToChangeInYourLifestyle: "Als er iets zou veranderen in uw levensstijl, hanteert %brandName% een bedenktijd van 14 dagen. Praat met een lid van het team voor meer informatie.",
  ifSomethingWereToChangeRightToCancel: "Als er iets zou veranderen in uw levensstijl, hebt u het recht om te annuleren. Praat met een medewerker voor meer informatie.",
  imageDisclaimer: "*Disclaimer: afbeeldingen dienen uitsluitend ter illustratie en zijn mogelijk niet van de door u geselecteerde club",
  importantInformation: "Belangrijke informatie",
  includedInPromotion: "Inbegrepen in promotie",
  individual: "Individueel",
  initialEnquiryKeepYouUpdated: "Na uw eerste aanvraag houden we u graag op de hoogte van onze nieuwste producten, diensten en aanbiedingen. Als u geïnteresseerd bent, klik dan op de vakjes hieronder.",
  initialMonthTerm: "Initieel %monthDuration% maandelijkse termijn",
  isChildPresentText: "Mijn kind is vandaag bij mij in de Club",
  isPartnerPresentText: "Mijn partner is bij me in de Club",
  join: "Inschrijven",
  joinCorporate: "Sluit zakelijk aan",
  joinDifferentClub: "Sluit aan bij een andere club.",
  joinFriendsAndFamily: "Word lid van vrienden en familie",
  joinOurReferralScheme: "Het lijkt erop dat je probeert deel te nemen aan ons verwijzingsprogramma...",
  joinTodayAndSave: "Word vandaag lid en bespaar",
  joiningFee: "Aansluitingstarief",
  joiningFeeFirstMonth: "inclusief lidmaatschapsbijdrage en eerste maand",
  joiningFeeProRataFirstMonth: "Inschrijfgeld, rest van eerste maand en eerste volledige maand",
  joiningFeeTooltip: "Betaling om bij de club te horen",
  joiningFeeTwelveMonths: "Fee lidmaatschap, saldo voor de rest van de maand en 12 maanden lidmaatschap",
  joiningFees: "Lidmaatschapsbijdrage",
  joiningFeesApply: "Aansluitingstarieven zijn van toepassing",
  joiningFeesWaived: "SPECIAAL AANBOD - Besparing op het aansluitingsgeld nu beschikbaar",
  joiningTheClubAsAFamily: "Naar de club met het gezin",
  justMe: "Alleen ik",
  keepAnEyeOutForFutureTrials: "Houd onze website in de gaten voor de volgende proefperiodes of contacteer ons via de website. Ons team zal zo snel mogelijk antwoorden.",
  keepInTouch: "Blijf in contact",
  keepYouUpdated: "We houden je graag op de hoogte van onze producten, diensten en aanbiedingen. Geïnteresseerd? Vink dan de onderstaande vakjes aan.",
  kickstartTitleWithName: "%firstName%, hoe zou u uzelf omschrijven?",
  kickstartTitleWithoutName: "Hoe zou u uzelf omschrijven?",
  kidsActivities: "Activiteiten voor kids",
  kidsBuildYourOwnPizza: "Maak je eigen pizza",
  kidsClub: "Kinderclub",
  lastName: "Naam",
  latestNews: "Latest News",
  learnMore: "Meer weten",
  learnSomethingNew: "Leer iets nieuws",
  letThemEnjoyTheClub: "Laat hen genieten van de club terwijl jij tijd voor jezelf neemt.",
  letUsKnowWhatYouAreInterestedIn: "Zeg ons wat je interesseert zodat we je bezoek kunnen personaliseren",
  letUsKnowWhatYouAreLookingForwardTo: "Laat ons weten welke delen van de club je het liefst zou willen gebruiken.",
  likeOurPage: "Like onze pagina",
  linkIsInvalid: "Sorry, deze link is niet geldig.",
  loadingClubWelcomeTimes: "club welkomsttijden laden...",
  loadingTourTimes: "Tijden worden geladen…",
  logOut: "Uitloggen",
  login: "Log in",
  male: "Mannelijk",
  master: "Mr.",
  meAndMyFamily: "Ik en mijn gezin",
  meAndMyPartner: "Ik en mijn partner",
  membersLogin: "Login lid",
  membershipDescription: "Opgenomen in uw welkomst-email of op uw lidmaatschapskaart",
  membershipDiscountUntil: "tot %date%",
  membershipEnquiryDetails: "De geselecteerde gebruiker: %duration%%packageKey%%packageTypes%",
  membershipFees: "Lidgelden",
  membershipFeesUpUntil: "Lidmaatschapskosten tot %date%",
  membershipNumber: "Lidmaatschapsnummer",
  membershipPlaceholder: "Voer je lidmaatschapsnummer in",
  membershipPriceChangedError: "The payment has been confirmed, if you wish to change membership details, please complete joining journey and manually update club centric account",
  membershipSaving: "Besparing lidmaatschap",
  membershipStartClubConfirm: "Je club zal bevestigen wanneer je lidmaatschap start",
  membershipStartClubOpens: "Je lidmaatschap start wanneer de club opengaat",
  membershipTermsAndConditions: "Algemene voorwaarden van lidmaatschap",
  membershipTrial: "Proefabonnement",
  memberships: "Lidmaatschappen",
  min: "min",
  minutes: "minuten",
  miss: "Juffrouw",
  monthlyDirectDebitsStartOn: "Maandelijkse domiciliëring begint op",
  monthlyPaymentsDuringOfferPeriodTitle: "Maandelijkse betalingen tijdens de periode van de speciale aanbieding",
  monthlySavings: "Maandelijkse besparingen",
  months: "maanden",
  more: "Meer",
  moreDetailsSkipDirectDebit: "Ik wil mijn domiciliëring activeren in Club",
  moreDetailsSkipSEPA: "Ik wil mijn domiciliëring activeren in Club",
  mostPopular: "Meest populaire",
  mostRecent: "Most Recent",
  mr: "Dhr.",
  mrs: "Mw.",
  ms: "Mevrouw",
  mx: "Mx.",
  myMembership: "Aansluitingstarieven zijn van toepassing",
  nearlyThere: "We zijn er bijna",
  next: "Volgende",
  noAdditionalMembershipsAvailable: "Er zijn momenteel geen aanvullende lidmaatschapsopties beschikbaar.",
  noAvailablePromotions: "Er zijn geen aanbiedingen beschikbaar.",
  noClubWelcomeSlotsError: "Helaas hebben we op dit moment geen club welkomsttijden online beschikbaar. Neem gerust contact met ons op zodat wij een tijdstip voor je vinden",
  noClubsInClubFinder: "Er zijn geen clubs in dit gebied, maar de dichtstbijzijnde clubs staan hieronder vermeld.",
  noJoiningFee: "Geen inschrijfkosten",
  noJoiningFeeOffer: "Speciaal aanbod: geen aansluitingsgeld",
  noMembershipFeeForMonthOffer: "Bonusaanbieding: geen lidmaatschapskosten voor %month%",
  noMembershipFeeUntilMonthOffer: "Bonusaanbod: geen lidgeld voor %month%",
  noPreferencePackageKey: "geen voorkeur voor type lidmaatschapspakket; ",
  noPreferencePackageTypes: "geen voorkeur voor type lidmaatschapstoegang.",
  noTourSlotsError: "Sorry, we hebben momenteel geen bezoektijden online beschikbaar. Contacteer ons om een vrij moment te vinden.",
  normally: "Normaal",
  notAllMembershipsAvailable: "Niet alle lidmaatschappen zijn beschikbaar",
  notSureWhichClubsNearest: "Welke club is dichtstbij",
  now: "Nu",
  onDate: "op %date%",
  onFacebook: "%clubName% op Facebook",
  onInstagram: "%clubName% op Instagram",
  onJoiningTheClubPayment: "Als u lid wordt van de club, wordt u gevraagd om een eerste betaling te doen, bestaande uit een inschrijfgeld en een pro rata betaling (dit is uw betaling vandaag). Dit wordt gevolgd door een maandelijks abonnement dat via domiciliëring wordt afgesloten. Als u uw lidmaatschapservaring wilt verbeteren, kunt u aanvullende producten toevoegen, zoals persoonlijke training of coaching, dit kan ook vandaag worden betaald.",
  oneMonth: "Een maand",
  onlyAvailableForALimitedTime: "Beperkt beschikbaar",
  openingHours: "Openingsuren",
  optInToSpecialOffer: "Voeg dit toe om u aan te melden voor de promotie",
  optOutAtAnyTimeAfterPrivacyPolicy: "voor alle details!",
  optOutAtAnyTimeBeforePrivacyPolicy: "U kunt zich op elk moment afmelden. Bekijk onze",
  options: "Opties",
  organisation: "Organisatie",
  organisationOther: "Ander",
  other: "Andere",
  ourEquipmentAt: "Onze uitrusting in %clubName%",
  ourFriendlyTeamAreLookingForwardToMeetingYou: "Ons team kijkt uit naar je komst",
  outdoorPoolTimesWeatherDependent: "De openingstijden van het buitenzwembad hangen af van het weer",
  over14EmailAddress: "Members over 14 can use in-club facilities. Enter their email address so they can be enrolled.",
  over14PhoneNumber: "Leden ouder dan 14 kunnen de mobiele app %brandName% gebruiken, voer hun mobiele nummer in.",
  "package": "Pakket",
  packageSelected: "%packageName% geselecteerd",
  padel: "Padel",
  paidOnline: "Online betaald",
  partner: "Partner",
  partnerEmail: "Uw partner ontvangt een e-mail met bevestiging van de lidmaatschapsgegevens en om het lidmaatschap te voltooien. Voer hun mobiele nummer in en we sturen een link om te registreren naar de %brandName% mobiele app.",
  partnerWasReset: "Jammer genoeg omvat dit pakket geen partner optie - overweeg een upgrade naar ander pakket om uw partner toe te voegen",
  partnersAndSponsors: "Partners en sponsors",
  password: "Wachtwoord",
  passwordPlaceholder: "Voer je wachtwoord in",
  payAnnually: "Betaal jaarlijks",
  payAtReception: "Betalen bij de receptie",
  payMonthly: "Betaal maandelijks",
  payOnline: "Betaal online",
  payingAnnually: "Jaarlijks betalen is in principe goedkoper dan maandelijks betalen",
  paymentAlreadyConfirmed: "The payment has been confirmed, skip to the monthly payment page",
  paymentDetails: "Betaalgegevens",
  paymentLinkExpired: "Payment link expired",
  paymentReceived: "Payment received",
  paymentRefused: "Betaling geweigerd",
  penneArrabbiata: "Penne arrabbiata",
  perMonth: "per maand",
  personalInformation: "Persoonlijke informatie",
  phoneNumber: "Mobiel telefoonnummer",
  pickAnotherTime: "Kies een ander uur",
  pleaseCheckAndTryAgain: "Probeert u het later opnieuw.",
  pleaseCheckYourCardDetails: "Controleer de gegevens van je kaart",
  pleaseCheckYourDetailsAndTryAgain: "Controleer uw gegevens en probeer het opnieuw. Als het probleem aanhoudt, bezoek ons dan in de club en ons team zal u graag helpen.",
  pleaseCheckYourEmail: "Please check your email",
  pleaseClickHereTo: "Klik hier om",
  pleaseContactOnlineJoining: "Neem contanct op met %email% voor assistentie",
  pleaseDoubleCheckAndTryAgain: "Controleer uw gegevens nogmaals en probeer het opnieuw",
  pleaseEnterANumber: "Geef een nummer in",
  pleaseMakePaymentAtReception: "U wordt verzocht bij de receptie te betalen",
  pleasePickAnotherTime: "Kies alsjeblieft een andere tijd.",
  pleaseRefreshOrTryAgainLater: "Refresh, of probeer het later opnieuw.",
  pleaseSelectATime: "Kies een uur",
  pleaseTryAgainLater: "Probeer later opnieuw",
  pleaseUseAnAlternativeBrowser: "Gebruik een andere browser, zoals een up-to-date versie van Microsoft Edge, Google Chrome of Safari",
  pleaseVerifyCaptcha: "Gelieve de captcha te verifiëren om verder te gaan",
  policies: "Beleid",
  policiesAndDocuments: "Beleid en documenten",
  poolOpeningHours: "Openingsuren zwembad",
  poolOpeningTimes: "Openingstijden zwembad",
  poolTimes: "Openingstijden zwembad",
  pools: "Zwembaden",
  poolsAreClosed: "Al onze zwembaden zijn momenteel gesloten",
  postCode: "Postcode",
  postCodePlaceholder: "Voer je postcode in",
  preferNotToSay: "Zeg ik liever niet",
  press: "Pers",
  pressCentre: "Press Centre",
  previous: "Vorige",
  priceDuringPromotionalPeriod: "Prijs tijdens de promotie periode",
  pricePerAdult: "%adultPrice% per volwassene",
  pricePerChild: "%childPrice% per kind",
  primaryMember: "Hoofdlid",
  privacyPolicy: "Privacybeleid",
  promotionSelected: "Promotie geselecteerd",
  promotionalItems: "Promotie-artikelen",
  promotions: "Aanbiedingen",
  qrCodeExpired: "QR code expired - please pay at reception",
  quickRegistration: "Snelle Registratie",
  referral: "Verwijzing",
  refundedIfYouJoinOnAStandardMembership: "te betalen kosten als je geen standaardabonnement neemt",
  regionUnitedKingdomEast: "East",
  regionUnitedKingdomGreaterLondon: "Greater London",
  regionUnitedKingdomMidlands: "Midlands",
  regionUnitedKingdomNorthEast: "North East",
  regionUnitedKingdomNorthWest: "North West",
  regionUnitedKingdomNorthernIreland: "Northern Ireland",
  regionUnitedKingdomScotland: "Scotland",
  regionUnitedKingdomSouth: "South",
  regionUnitedKingdomSouthEast: "South East",
  regionUnitedKingdomSouthWest: "South West",
  regionUnitedKingdomWales: "Wales",
  registerForClubTrial: "Start vandaag met uw proeflidmaatschap",
  registerForClubTrialButton: "Start uw proeflidmaatschap",
  registeredCompanyNumber: "Bedrijf geregistreerd met nummer",
  registrationErrorMessage: "Controleer uw details. Stuur ons een email op <a style=\"color: %emailColor%;\" href=\"mailto:%email%\">%email%</a> als u problemen hebt met de registratie van een account en iemand zal u zo snel mogelijk contacteren.",
  registrationUnauthorised: "Goedkeuring mislukt",
  relaxAmpersandSocialise: "Relax en ontmoet",
  relaxAndEnjoySpaRetreats: "Ontspan en geniet van Spa Retreats",
  relaxAndEnjoyTheSpaRetreatsAt: "Ontspan en geniet in onze spa in:",
  relaxAndSocialise: "Relax en ontmoet",
  remainderOfFirstMonth: "Rest van de eerste maand",
  remainderOfFirstMonthAnd12MonthsMembership: "Rest van het lidmaatschap van de eerste maand en 12 kalendermaanden",
  remainderOfFirstMonthAndFirstFullMonth: "Rest van de eerste maand en de eerste volledige maand",
  remainderOfThisMonth: "Rest van deze maand",
  remainderOfThisMonthTooltip: "Betaling ter dekking van de kosten van het lidmaatschap voor de rest van deze maand",
  requestingAClubWelcome: "Een club welkom afspraak aanvragen",
  retry: "Opnieuw proberen",
  saveAmount: "Bespaar %amount%",
  savingUpTo: "U bespaart tot %price%*",
  scanQrCodeToDownload: "Scan de QR-code om de app te downloaden",
  scanQrCodeToPay: "Scan the QR code to proceed to payment page",
  seeAllFacilities: "Bekijk alle uitrustingen",
  seeDetails: "Bekijk de details",
  seeMore: "Meer zien…",
  selectADate: "Kies een datum",
  selectAnOrganisation: "Selecteer een bedrijf of groep…",
  selectOptionsBelow: "Kies uit de onderstaande opties om activiteiten voor het hele gezin te vinden.",
  selectPackage: "Selecteer %packageName%",
  selectPromotion: "Selecteer promotie",
  selectTitle: "Selecteer een aanspreektitel...",
  selectedDuration: "een %durationType% pakketduur; ",
  selectedPackageKey: "een%packageKey% -lidmaatschap; ",
  selectedPackageTypes: "lidmaatschapstoegang die %packageTypes% omvat.",
  selectedTourTimeNoLongerAvailable: "Helaas is de door jou geselecteerde tourtijd niet langer beschikbaar.",
  selectedTourTimeNoLongerAvailableHeader: "Tourtijd niet beschikbaar",
  sendQuote: "Verzend offerte",
  sepaGuaranteeLine1: "Door ondertekening van dit formulier geett u toestemming aan [A] %companyName% om doorlopend incasso-opdrachten te sturen naar uw bank om een bedrag van uw rekening at te schrijven en [Bl uw bank om doorlopend een bed rag at te schrijven overeenkomstig de opdracht \nvan %companyName%.",
  sepaGuaranteeLine2: "Als u het niet eens bent met deze afschrijving dan kunt u deze laten terugboeken. Neem hiervoor binnen acht weken na afschrijving contact op met uw bank. Vraag uw bank naar de voorwaarden. ",
  sepaGuaranteeLine3: "Type betaling: doorlopend (mag meerdere malen gebruikt worden)",
  sepaGuaranteeLine4: "Uw rechten met betrekking tot dit mandaat worden uitgelegd in een verklaring die u van uw bank kunt verkrijgen.",
  sepaGuaranteeLine5: "Type betaling: terugkerend (kan meer dan eens worden gebruikt)",
  sepaTitle: "Machtiging doorlopende SEPA Incasso Algemeen",
  settingUpYourDirectDebit: "Uw domiciliëring instellen",
  settingUpYourMonthlyPayment: "Uw maandelijkse betaling instellen",
  shareOnFacebook: "Delen op Facebook",
  shareOnTwitter: "Delen op Twitter",
  signMeUp: "Ik wil me inschrijven",
  signUpForAGradeCoaching: "Schrijf je in voor professionele coaching voor alle leeftijden",
  skip: "Skip",
  skipPayment: "Betaling overslaan, ik wil graag betalen bij de receptie",
  slotsTakingAWhileToLoad: "Het duurt even om onze beschikbare momenten te vinden. We blijven proberen, maar intussen kan je ons ook rechtstreeks contacteren via onderstaande gegevens.",
  snrgyFamilyFitness: "Synrgy gezinsfitness",
  socialMedia: "Social Media",
  softPlay: "Vrije spelletjes",
  somethingHasGoneWrong: "Er is iets misgegaan",
  sorryIeNotSupported: "Het spijt ons, Internet Explorer wordt niet ondersteund",
  sorrySomethingHasGoneWrongPleaseTryAgain: "Sorry, er is iets misgegaan. Probeer het nog eens.",
  sorrySomethingWentWrong: "Sorry, er is iets misgegaan.",
  sorryThereWasAProblemWithYourPayment: "Sorry, er is een probleem met je betaling",
  sorryWereHavingTechnicalProblems: "Sorry, er zijn technische problemen",
  sortBy: "Sort by:",
  sortByMyLocation: "Sort by my location",
  sortCode: "Sorteercode",
  spa: "Spa",
  specialHours: "Speciale uren",
  specialOffer: "exclusieve aanbieding",
  specialsAndEvents: "Specials en evenementen",
  sportsOverview: "Overzicht van de sporten",
  standard: "Standaard",
  startDate: "Startdatum",
  startInNextWeek: "Start uw lidmaatschap wanneer u wilt volgende week",
  startOnFixedDate: "U kunt uw lidmaatschap starten op",
  stateOfTheArtGymFacilities: "Hypermoderne fitness faciliteiten",
  studioOpeningHours: "Openingsuren studio",
  studioOverview: "Overzicht studio",
  submitAndStartMembership: "Dien in en begin te genieten van uw lidmaatschap",
  swimmingAt: "Zwemmen in %clubName%",
  swimmingCoaching: "Zwemcoaching",
  swimmingPools: "Zwembaden",
  tailorYourClubWelcome: "Maak uw clubontvangst op maat.",
  tailorYourTour: "Personaliseer je bezoek",
  takeOurVideoTour: "Bekijk onze video om een idee te krijgen van de %brandName% ervaring*",
  takeTheNextStep: "Naar de volgende stap",
  takeTheNextStepDescription: "Ontdek hoe lid worden van %clubPrefix% %clubName% jou en de tijd die je met je gezin doorbrengt, kan veranderen.",
  taxId: "Tax ID",
  telephone: "Telefoon",
  tennis: "Tennis",
  tennisClub: "Tennisclub",
  tennisCoaching: "Tenniscoaching",
  termsAndConditions: "Algemene voorwaarden",
  textMessage: "Sms",
  thankYouForSettingUpDirectDebit: "Bedankt voor het instellen van uw domiciliëring",
  thankYouForSubscribing: "Dank je voor je inschrijving!",
  thankYouWelcomeToBrand: "Bedankt, en welkom bij %brandName%!",
  theClub: "De club",
  thereAreCurrentlyNoMembershipPackages: "Sorry, er is momenteel geen online lidmaatschap beschikbaar. Gelieve uw plaatselijke club te contacteren om meer te weten te komen over uw aansluiting.",
  threeMonths: "3 maanden",
  timetable: "Openingstijden",
  title: "Aanhef",
  today: "Vandaag",
  tomorrow: "morgen",
  totalDue: "Totaal te betalen",
  totalDueToday: "Totaal verschuldigd",
  totalSavedJoiningToday: "Totale besparing wanneer je vandaag lid wordt",
  townOrCity: "Stad/gemeente",
  townOrCityPlaceholder: "Voer je gemeente of stad in",
  treatments: "Face & Body Day Spa",
  treatmentsOverview: "Overzicht van behandelingen",
  unableToOfferTrial: "Sorry, we kunnen momenteel geen proefsessie aanbieden.",
  unableToTakeClubWelcomeBookings: "Helaas kunnen wij op dit moment geen online boekingen aannemen - neem contact met ons op via onderstaande gegevens om jouw clubwelkomst te boeken.",
  unableToTakeTourBookings: "Sorry, we kunnen momenteel geen online reservaties maken – je kan ons contacteren met onderstaande gegevens om je bezoek te reserveren.",
  unexpectedError: "Onverwachte fout",
  unfortunatelyAnErrorHasOccurred: "Sorry, er is iets misgegaan.",
  unfortunatelyThisTimeIsNoLongerAvailable: "Sorry, dit uur is niet meer vrij",
  unknownMembershipError: "Je abonnement is succesvol geregistreerd. Kijk uit naar een bevestigingsmail. Als je die niet binnen het uur krijgt, probeer het dan opnieuw.",
  unsubscribe: "Afmelden",
  updateAppPrompt: "Update uw app naar de nieuwste versie en probeer het opnieuw.",
  upgradeYourMembershipToAddChildren: "Upgrade uw lidmaatschap om een kind toe te voegen.",
  upgradeYourMembershipToAddPartner: "Upgrade uw lidmaatschap en voeg een partner toe",
  useMyLocation: "Gebruik mijn locatie",
  userAlreadyExists: "Dit lidmaatschapsnummer is reeds in gebruik",
  userInputOrganisation: "Wat is de naam van uw bedrijf of groep?",
  userVerificationFailed: "Verificatie mislukt",
  usualJoiningFees: "Normale aanslutingsgelden",
  vatNumber: "Btw-nummer",
  viewAllClubs: "Bekijk alle clubs",
  viewInMaps: "Bekijk in Maps",
  viewMap: "View a map of all David Lloyd Clubs",
  viewOurFoodMenu: "Bekijk ons menu",
  viewTimetable: "Bekijk de tijdschema’s",
  visitSpaRetreats: "Bezoek Spa Retreats",
  was: "Was",
  weAlreadyHaveYourDetails: "%firstName%, uw details staan reeds in ons systeem",
  weDontRecogniseThatLocation: "Sorry, deze locatie wordt niet herkend",
  weJustNeedAFewMoreDetails: "Nog enkele details",
  weSendOurMostExcitingOffers: "We sturen je onze interessantste aanbiedingen via mail",
  weWereUnableToSaveDetails: "Sorry, we konden uw gegevens niet opslaan",
  weekend: "Weekend",
  weeklyTimetable: "Wekelijkse openingstijden",
  welcomeTo: "Welkom bij",
  whatToExpect: "Wat mag je verwachten?",
  whatsApp: "WhatsApp",
  whereToFindUs: "Waar vind je ons",
  whoCanCome: "Wie mag komen?",
  whosComing: "Wie komt er?",
  workEmailAddress: "E-mailadres van het werk",
  years: "jaren",
  youngChildren: "Kinderen (van 0 tot 2 jaar oud)",
  yourCardDetails: "Jouw kaartgegevens",
  yourChild: "jouw kind (%range%)",
  yourChildren: "jouw kinderen (%range%)",
  yourClubWelcomeIsBooked: "%name%, jouw club welkom is geboekt",
  yourDetails: "Uw gegevens",
  yourFirstFullMonth: "Uw eerste volledige maand (%month%)",
  yourFirstFullMonthTooltip: "Betaling die de kosten van het lidmaatschap dekt totdat uw domiciliëring is ingesteld bij uw bank",
  yourMembership: "Uw lidmaatschap",
  yourMembershipTrial: "Je proefabonnement",
  yourNannies: "Uw nanny",
  yourNanny: "Uw nanny",
  yourOlderChild: "Uw kind (%range%)",
  yourOlderChildren: "Uw kinderen (%range%)",
  yourPartner: "Uw partner",
  yourSavings: "Uw besparingen",
  yourSavingsSubtitle: "Dit kunt u besparen door vandaag lid te worden",
  yourStartDate: "Uw startdatum",
  yourTourIsBooked: "%name%, je bezoek is gereserveerd",
  yourYoungChild: "Uw kind (%range%)",
  yourYoungChildren: "Uw kinderen (%range%)"
};
