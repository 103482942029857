import { BackgroundTheme, TextBackgroundTheme } from 'src/enums/background-theme';
import { colors } from 'src/styling/constants';
import imageFade from 'src/helpers/image-fades';
import { TextOverPosition } from 'src/enums/text-over-position';
import { Styling } from 'src/context/styling';
import { CSSInterpolation } from '@emotion/serialize';
import { BuildType } from 'src/enums/build/build-type';
import { ButtonStyle, RedesignButtonStyle } from 'src/enums/button-style';
import React from 'react';

export const rgbaValues = (hex: string, alpha = 1) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  // We convert the first 2 hex characters to the r value, the next 2 characters to g and the last 2 characters to b
  return result
    ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${alpha})`
    : null;
};

export const getBackgroundColorByTheme = (
  theme: BackgroundTheme | string,
  alpha?: number
): string => {
  switch (theme) {
    case BackgroundTheme.GREY:
      return rgbaValues(colors.hessianBackground, alpha);
    case BackgroundTheme.LIGHT:
    default:
      return colors.cream;
  }
};

export const getReverseBackgroundColorByTheme = (theme: BackgroundTheme | string): string => {
  switch (theme) {
    case BackgroundTheme.GREY:
      return colors.cream;
    case BackgroundTheme.LIGHT:
    default:
      return colors.hessianBackground;
  }
};

export const getTextBackground = (theme: TextBackgroundTheme): Array<string> => {
  switch (theme) {
    case TextBackgroundTheme.GREY:
      return [colors.hessianBackground, colors.hessianBackground];
    case TextBackgroundTheme.LIGHT:
      return ['white', 'white'];
    default:
      return ['white', colors.hessianBackground];
  }
};

export const getPosition = (position: TextOverPosition, isFullWidth: boolean) => {
  const margin = '3.75rem';
  const gap = '1.5rem';
  const column = 12;
  switch (position) {
    case TextOverPosition.LEFT:
    case TextOverPosition.CENTER_LEFT:
      return {
        position: ['unset', 'absolute'],
        left: [
          'unset',
          isFullWidth
            ? `calc((100% - 2 * ${margin} + ${gap})/${column} + ${margin})`
            : `calc((100% + ${gap})/${column})`,
        ],
      };
    case TextOverPosition.CENTER:
      return { width: '100%' };
    case TextOverPosition.BOTTOM_RIGHT:
      return {
        position: ['unset', 'absolute'],
        right: [
          'unset',
          isFullWidth
            ? `calc((100% - 2 * ${margin} + ${gap})/${column} + ${margin})`
            : `calc((100% + ${gap})/${column})`,
        ],
        justifyContent: 'end',
        paddingBottom: [0, '5rem!important'],
      };
    case TextOverPosition.BOTTOM_LEFT:
      return {
        position: ['unset', 'absolute'],
        left: [
          'unset',
          isFullWidth
            ? `calc((100% - 2 * ${margin} + ${gap})/${column} + ${margin})`
            : `calc((100% + ${gap})/${column})`,
        ],
        justifyContent: 'end',
        paddingBottom: [0, '5rem!important'],
      };
    case TextOverPosition.RIGHT:
    case TextOverPosition.CENTER_RIGHT:
    default:
      return {
        position: ['unset', 'absolute'],
        right: [
          'unset',
          isFullWidth
            ? `calc((100% - 2 * ${margin} + ${gap})/${column} + ${margin})`
            : `calc((100% + ${gap})/${column})`,
        ],
      };
  }
};

export const getFlexPositioning = (position: TextOverPosition) => {
  switch (position) {
    case TextOverPosition.LEFT:
    case TextOverPosition.CENTER_LEFT:
      return {
        alignItems: ['flex-end', 'center'],
        justifyContent: 'flex-start',
      };
    case 'center':
      return {
        alignItems: ['flex-end', 'center'],
        justifyContent: 'center',
      };
    case TextOverPosition.BOTTOM_RIGHT:
      return {
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
      };
    case TextOverPosition.BOTTOM_LEFT:
      return {
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
      };
    case TextOverPosition.RIGHT:
    case TextOverPosition.CENTER_RIGHT:
    default:
      return {
        alignItems: ['flex-end', 'center'],
        justifyContent: 'flex-end',
      };
  }
};

export const getImageFade = (position: TextOverPosition) => {
  switch (position?.toLowerCase()) {
    case TextOverPosition.LEFT:
    case TextOverPosition.CENTER_LEFT:
      return imageFade.centerLeft;
    case TextOverPosition.BOTTOM_RIGHT:
      return imageFade.bottomRight;
    case TextOverPosition.BOTTOM_LEFT:
      return imageFade.bottomLeft;
    case TextOverPosition.RIGHT:
    case TextOverPosition.CENTER_RIGHT:
      return imageFade.centerRight;
    case TextOverPosition.CENTER:
    default:
      return imageFade.bottomFade;
  }
};

export const markdownBulletStyle = (styling: Styling, color?: string, withLinkStyle = true) => {
  const bulletPoint = '"\u2022"';

  const markdownListStyle = (styling: Styling) =>
    [
      styling.fonts.regular,
      {
        paddingLeft: '1.5rem',
        margin: 'auto',
        textAlign: 'left',
        color: color || styling.colors.capeCod,
        lineHeight: 1.35,
      },
    ] as CSSInterpolation;

  const linkStyle = withLinkStyle
    ? {
        a: {
          color: styling.colors.camelot,
          borderBottom: `1px solid ${styling.colors.camelot}`,
          lineHeight: '160%',
        },
      }
    : {};

  return {
    ul: [
      markdownListStyle(styling),
      {
        listStyle: 'none',
        li: {
          '::before': {
            content: bulletPoint,
            display: 'inline-block',
            color: color || styling.colors.capeCod,
            width: '1.1em',
          },
        },
      },
    ],
    ol: [markdownListStyle(styling)],
    ...linkStyle,
  };
};

export const getSecondaryButtonStyle = (buildType: BuildType) => {
  switch (buildType) {
    case BuildType.MERIDIAN:
      return {
        whiteBg: ButtonStyle.SECONDARY,
        mobile: ButtonStyle.TRANSPARENT,
        desktop: ButtonStyle.SECONDARY,
      };
    case BuildType.HARBOUR:
      return {
        whiteBg: ButtonStyle.SECONDARY,
        mobile: ButtonStyle.TRANSPARENT,
        desktop: ButtonStyle.TRANSPARENT,
      };
    default:
      return {
        whiteBg: RedesignButtonStyle.TRANSPARENT,
        mobile: RedesignButtonStyle.WHITE_WITH_PLUM_FONT,
        desktop: RedesignButtonStyle.WHITE_WITH_PLUM_FONT,
      };
  }
};

export const getBuildButtonStyle = (
  buildType: BuildType,
  button: Partial<{
    showButton: boolean;
    buttonText: string | React.ReactElement;
    buttonLink: string;
    goToGlobal: boolean;
    buttonStyle: string;
    oldButtonStyle: string;
    newButtonStyle: string;
  }>
): ButtonStyle => {
  if (button.buttonStyle) return button.buttonStyle as ButtonStyle;

  switch (buildType) {
    case BuildType.MERIDIAN:
    case BuildType.HARBOUR:
      return button.oldButtonStyle as ButtonStyle;
    default:
      return button.newButtonStyle as ButtonStyle;
  }
};
