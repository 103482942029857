import React, { useContext } from 'react';
import { Language } from '../enums/language';
import { StaticTextLookupKey, StaticTextLookup } from './static-text-lookup';
import { staticTextLookup as deDeStaticTextLookup } from './static-text/de-de';
import { staticTextLookup as enDeStaticTextLookup } from './static-text/en-de';
import { staticTextLookup as enGbStaticTextLookup } from './static-text/en-gb';
import { staticTextLookup as enIeStaticTextLookup } from './static-text/en-ie';
import { staticTextLookup as esEsStaticTextLookup } from './static-text/es-es';
import { staticTextLookup as enEsStaticTextLookup } from './static-text/en-es';
import { staticTextLookup as caEsStaticTextLookup } from './static-text/ca-es';
import { staticTextLookup as itItStaticTextLookup } from './static-text/it-it';
import { staticTextLookup as enItStaticTextLookup } from './static-text/en-it';
import { staticTextLookup as enBeStaticTextLookup } from './static-text/en-be';
import { staticTextLookup as frBeStaticTextLookup } from './static-text/fr-be';
import { staticTextLookup as nlBeStaticTextLookup } from './static-text/nl-be';
import { staticTextLookup as nlNlStaticTextLookup } from './static-text/nl-nl';
import { staticTextLookup as enNlStaticTextLookup } from './static-text/en-nl';
import { staticTextLookup as frFrStaticTextLookup } from './static-text/fr-fr';
import { staticTextLookup as enFrStaticTextLookup } from './static-text/en-fr';
import { staticTextLookup as frChStaticTextLookup } from './static-text/fr-ch';
import { staticTextLookup as enChStaticTextLookup } from './static-text/en-ch';
import { SiteContext } from 'src/context/site-context';

export const staticTextLookupByLanguage = {
  [Language.EN_BE]: enBeStaticTextLookup as Partial<StaticTextLookup>,
  [Language.FR_BE]: frBeStaticTextLookup as Partial<StaticTextLookup>,
  [Language.NL_BE]: nlBeStaticTextLookup as Partial<StaticTextLookup>,

  [Language.DE_DE]: deDeStaticTextLookup as Partial<StaticTextLookup>,
  [Language.EN_DE]: enDeStaticTextLookup as Partial<StaticTextLookup>,

  [Language.EN_ES]: enEsStaticTextLookup as Partial<StaticTextLookup>,
  [Language.CA_ES]: caEsStaticTextLookup as Partial<StaticTextLookup>,
  [Language.ES_ES]: esEsStaticTextLookup as Partial<StaticTextLookup>,

  [Language.EN_GB]: enGbStaticTextLookup as StaticTextLookup,

  [Language.EN_IE]: enIeStaticTextLookup as Partial<StaticTextLookup>,

  [Language.EN_IT]: enItStaticTextLookup as Partial<StaticTextLookup>,
  [Language.IT_IT]: itItStaticTextLookup as Partial<StaticTextLookup>,

  [Language.EN_NL]: enNlStaticTextLookup as Partial<StaticTextLookup>,
  [Language.NL_NL]: nlNlStaticTextLookup as Partial<StaticTextLookup>,

  [Language.EN_FR]: enFrStaticTextLookup as Partial<StaticTextLookup>,
  [Language.FR_FR]: frFrStaticTextLookup as Partial<StaticTextLookup>,

  [Language.FR_CH]: frChStaticTextLookup as Partial<StaticTextLookup>,
  [Language.EN_CH]: enChStaticTextLookup as Partial<StaticTextLookup>,
};

export const fallbackLanguage: Language = Language.EN_GB;
const fallbackStaticTextLookup: StaticTextLookup = staticTextLookupByLanguage[fallbackLanguage];

export const translateStaticTextIntoLanguage = (
  key: StaticTextLookupKey,
  language: Language = fallbackLanguage
): string => {
  const textInCurrentLanguage = staticTextLookupByLanguage[language][key];

  if (textInCurrentLanguage) {
    return textInCurrentLanguage;
  }

  console.warn(`No translation found for ${key} in the language ${language}`);

  return fallbackStaticTextLookup[key];
};

export const PARAMETER_DELIMITER = '%';

export const translateStaticTextFromKeyStringWithFixedValues = (
  key: StaticTextLookupKey,
  params: { [key: string]: string },
  language: Language
): string => {
  let translatedText = translateStaticTextIntoLanguage(key, language);

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const textToReplace = PARAMETER_DELIMITER + key + PARAMETER_DELIMITER;
      translatedText = replaceAll(translatedText, textToReplace, params[key]);
    }
  }

  return translatedText;
};

function replaceAll(text: string, textToReplace: string, replacement: string): string {
  text = text.replace(textToReplace, replacement);
  if (text.includes(textToReplace)) {
    return replaceAll(text, textToReplace, replacement);
  } else {
    return text;
  }
}

type TranslatedStaticTextProps = {
  lookupKey: StaticTextLookupKey;
  params?: { [key: string]: string };
};

export const TranslatedStaticText: React.FC<TranslatedStaticTextProps> = ({
  lookupKey,
  params,
}) => {
  const { language } = useContext(SiteContext);
  return (
    <>
      {params
        ? translateStaticTextFromKeyStringWithFixedValues(lookupKey, params, language)
        : translateStaticTextIntoLanguage(lookupKey, language)}
    </>
  );
};
