import React from 'react';
import { IconProps } from './icon-types';

const HamburgerIcon = ({ size, fill }: IconProps) => (
  <div css={{ width: size || '100%', height: size || '100%' }}>
    <svg
      css={{ display: 'block', fill: fill }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
    </svg>
  </div>
);

export default HamburgerIcon;
