export enum Brand {
  DAVID_LLOYD_UK = 'david-lloyd',
  DAVID_LLOYD_BELGIUM = 'belgium',
  DAVID_LLOYD_GERMANY = 'germany',
  DAVID_LLOYD_SPAIN = 'barcelona',
  DAVID_LLOYD_FRANCE = 'france',
  DAVID_LLOYD_IRELAND = 'ireland',
  DAVID_LLOYD_ITALY = 'italy',
  DAVID_LLOYD_NETHERLANDS = 'netherlands',
  DAVID_LLOYD_SWITZERLAND = 'switzerland',
  HARBOUR = 'harbour',
  MERIDIAN = 'meridian',
}
