import fixedImages from '../../helpers/fixed-images';
import { BuildType } from '../../enums/build/build-type';

export type Logos = {
  headerLogo: Queries.ImageDetails;
  whiteBackgroundLogo: Queries.ImageDetails;
};

export const getLogos = (buildType: BuildType): Logos => {
  switch (buildType) {
    case BuildType.BLAZE_STUDIO:
      return {
        headerLogo: fixedImages.whiteDavidLloydLogo,
        whiteBackgroundLogo: fixedImages.davidLloydLogo,
      };
    case BuildType.HARBOUR:
      return {
        headerLogo: fixedImages.whiteHarbourLogo,
        whiteBackgroundLogo: fixedImages.harbourLogo,
      };
    case BuildType.MERIDIAN:
      return {
        headerLogo: fixedImages.darkBgMeridianLogo,
        whiteBackgroundLogo: fixedImages.darkBgMeridianLogo,
      };
    default:
      return {
        headerLogo: fixedImages.davidLloydLogo,
        whiteBackgroundLogo: fixedImages.davidLloydLogo,
      };
  }
};
