import React, { useContext } from 'react';
import { GlobalCmsContent } from 'src/content/global-content';
import { Styling } from 'src/context/styling';
import { CSSInterpolation } from '@emotion/serialize';
import { SiteContext } from 'src/context/site-context';
import { WebsiteLink } from 'src/components/common/website-link';
import LanguageSelection from 'src/components/layout/redesign/header-shared/language-selection';
import { amplitudeTrackEvent } from 'src/helpers/amplitude-event-track';
import capitalize from 'lodash/capitalize';

const createComponentStyling = (styling: Styling) =>
  ({
    wrapper: {
      marginTop: '1rem',
    },
    link: [
      styling.fonts.regular,
      {
        fontSize: '1rem',
        lineHeight: '125%',
        padding: '0.75rem',
        paddingRight: 0,
        width: '100%',
      },
    ],
  }) satisfies Record<string, CSSInterpolation>;

type HeaderMobileSecondaryNavProps = {
  onClick?: () => void;
};

const HeaderMobileSecondaryNav: React.FC<HeaderMobileSecondaryNavProps> = ({ onClick }) => {
  const { headerContent } = useContext(GlobalCmsContent);
  const { styling, pageLevel } = useContext(SiteContext);
  const componentStyling = createComponentStyling(styling);

  if (!headerContent?.headerSecondaryUpperMenu?.length) {
    return null;
  }

  return (
    <div css={componentStyling.wrapper}>
      {(headerContent?.headerSecondaryUpperMenu ?? []).map((link) => {
        const handleClick = () => {
          onClick?.();
          return amplitudeTrackEvent(`Click to ${capitalize(link.text || '')} - Navigation`, {
            custom_page: window.location.origin,
            custom_level: pageLevel,
          });
        };

        return (
          <WebsiteLink key={link.text} to={link.link} handleClick={handleClick}>
            <div css={componentStyling.link}>{link.text}</div>
          </WebsiteLink>
        );
      })}
      <LanguageSelection text={headerContent?.headerOtherText?.selectLanguageText} />
    </div>
  );
};

export default HeaderMobileSecondaryNav;
