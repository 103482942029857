export const staticTextLookup = {
  FAQs: "FAQs",
  aCopyOfThisGuaranteeShouldBeRetained: "Een kopie van deze garantie moet door de klant worden bewaard, deze wordt per e-mailbevestiging verzonden",
  about: "Over",
  aboutYou: "Over u",
  accessToClubsAndSpaRetreats: "Platinum membership will also give you access to other David Lloyd clubs. See below for details of the clubs you'll be able to access.\nPlus, if the club has a Spa Retreat, this will also include access to the Spa Retreat facilities. Click here for a list of clubs with Spa Retreat.",
  accessToClubsNearYou: "Uw toegang tot clubs in uw buurt:",
  accessToOtherClubs: "Toegang tot andere clubs",
  accountHoldersName: "Naam van de rekeninghouder",
  accountNumber: "Rekeningnummer",
  activating: "Activeren\n",
  addPartnerAmount: "Voeg een partner toe %amount%",
  addSelectedMembership: "Geselecteerd lidmaatschap toevoegen",
  addSelectedPromotion: "Geselecteerde promotie toevoegen",
  addToCalendar: "Voeg Toe Aan Kalender",
  addYourAssociates: "Voeg uw partner en kinderen toe",
  addYourChildren: "Voeg uw kinderen toe",
  addYourPartner: "Voeg uw partner toe",
  addYourPromotions: "Selecteer uw promotie",
  additionalDetailsSecondErrorMessage: "Sorry, we kunnen uw gegevens momenteel niet online zetten, we nemen ze op bij uw eerste bezoek aan de club.",
  additionalMembers: "Bijkomende leden",
  additionalMemberships: "Bijkomende lidmaatschappen",
  address: "Adres",
  addressLine1: "Adres regel 1",
  addressLine2: "Adres regel 2",
  addressLinePlaceholder: "Voer uwadres in",
  adultMembers: "Volwassen leden",
  adults: "Volwassenen",
  afterSchool: "Na school",
  allAgesWelcome: "Alle leeftijden zijn welkom",
  allFacilities: "Alle faciliteiten",
  alsoAtClub: "Ook op %clubName%",
  amountSubjectToPendingPriceRise: "Het vermelde bedrag is onderhevig aan eventuele prijsstijgingen in behandeling. Neem contact op met het clubteam voor meer informatie.",
  and: "en",
  annualOnJoiningTheClubPayment: "Wanneer u lid wordt van de club, wordt u gevraagd om een betaling te doen bestaande uit een inschrijfgeld, een pro rata betaling voor de rest van deze maand (indien van toepassing) en een betaling die de kosten van het lidmaatschap voor 12 kalendermaanden dekt. Als u uw lidmaatschapservaring wilt verbeteren, kunt u aanvullende producten toevoegen, zoals persoonlijke training of coaching, dit kan ook vandaag worden betaald.",
  annually: "Jaarlijks",
  anythingElseYoudLikeUsToKnow: "Is er nog iets dat u ons wilt meedelen?",
  anytimeClubAccess: "Altijd toegang tot de club",
  app: " App",
  appQrCodeInstructions: "Plaats gewoon de camera van uw telefoon op de code en zodra deze is herkend, tikt u op de link om de app %brandName% te downloaden.",
  appUpdateRequired: "App-update vereist",
  areYouSureYouDoNotWantToBeContacted: "Weet u zeker dat u niet wilt dat wij contact met u opnemen via e-mail of sms?",
  associatesWereReset: "Helaas ondersteunt dit pakket niet alle extra leden die u had geselecteerd - kies een ander pakket als u die leden wilt opnemen.",
  atClub: "Bij %clubName%",
  atHomeDigitalContent: "@home digitale inhoud, waaronder live en on-demand content, educatieve seminars en tips voor trainers",
  atUnit: "Bij %unitName%",
  availablePromotions: "beschikbare promoties",
  availableSlot: "%numberOfSlots% beschikbaar slot",
  availableSlots: "%numberOfSlots% beschikbare slots",
  awaitingPayment: "Awaiting payment",
  back: "Terug",
  backToTop: "Terug naar boven",
  beforeYouBecomeAMember: "Voordat u lid wordt, laten we u zien hoe u het meeste uit deze club kunt halen",
  bestValue: "Beste waarde",
  bookAClass: "Reserveer een les",
  bookAClubWelcome: "Reserveer een club-welkom",
  bookAClubWelcomeError: "Sorry, er is iets misgegaan. Neem contact met ons op, dan zoeken we een tijdstip voor u.",
  bookATour: "Reserveer een rondleiding",
  bookATourError: "Sorry, er is iets misgegaan. Neem contact met ons op, dan zoeken we een tijdstip voor u.",
  bookATourHeader: "Boek je rondleiding",
  bookATourSubtext: "Kies hieronder een club om een rondleiding te boeken",
  bookOnTheMove: "Reserveer onderweg",
  bookYourClubWelcome: "Reserveer uw club-welkom",
  bookYourClubWelcomeAt: "Reserveer uw club-welkom op %clubName%",
  bookYourTour: "Reserveer uw rondleiding",
  bookYourTourOf: "Reserveer uw rondleiding van %clubName%",
  booked: "Gereserveerd",
  bookingYourClubWelcome: "We reserveren uw club-welkom...",
  bookingYourTour: "We reserveren uw rondleiding...",
  buyapowaWarningPopupCancelButtonText: "Kies een andere club",
  buyapowaWarningPopupConfirmButtonText: "Word lid van %clubName%",
  buyapowaWarningPopupHeader: "Let op",
  buyapowaWarningPopupText: "Club %clubName% is momenteel niet ingeschreven voor ons verwijs-een-vriend-schema, dus noch jij, noch de persoon die je heeft doorverwezen, komen in aanmerking voor beloningen als je doorgaat met deze aanmelding.",
  byClickingFinishYouAcceptDDConditions: "Door op '%buttonText%' te klikken, erkent u dat u de bankrekeninghouder bent en dat er voor de rekening geen toestemming van een andere persoon nodig is om de betalingen op deze rekening te autoriseren. Als het een persoonlijke of zakelijke rekening is waarvoor meer dan één persoon verplicht is om betalingen op deze rekening te autoriseren, neem dan rechtstreeks contact met ons op.",
  cancel: "Annuleer",
  cancelMyMembership: "Annuleer mijn lidmaatschap",
  cantFindASlot: "Kunt u geen plek vinden?",
  cardCollectionTime: "Tijdstip ophaling kaart/club-rondleiding",
  careers: "Carrières",
  changeClub: "Verander club",
  changePreferences: "Verander voorkeuren",
  changeSelectedPackage: "Verander het geselecteerde lidmaatschap",
  checkYourAccessToASpecificClub: "Controleer uw toegang tot een specifieke club:",
  child2AndUnder: "Lidmaatschap voor kinderen:\nTot 2 jaar",
  child3To18: "Lidmaatschap voor kinderen:\n 3-18 jaar",
  childMembers: "Minderjarige leden",
  children: "Kinderen",
  childrenOverview: "Overzicht voor kinderen",
  chooseAClub: "Kies een club",
  chooseAClubToRegisterForClubTrial: "Kies hieronder een club",
  chooseHowYouPay: "Kies hoe je betaalt",
  chooseMembershipLength: "Kies de duurtijd van uw lidmaatschap",
  chooseYourStartDate: "Kies uw startdatum",
  clickHereIfYouAreNot: "Klik hier als u niet %firstName% bent",
  close: "Sluiten",
  closeDetails: "Details sluiten",
  club: "Club",
  clubEmail: "Club e-mail",
  clubFacilities: "Clubfaciliteiten",
  clubFinderSearchPlaceholder: "Voer een locatie in",
  clubOpeningHours: "Openingstijden Club",
  clubOpeningTimes: "Openingstijden Club",
  clubOverview: "Overzicht van de Club",
  clubTourTime: "Tijd rondleiding club",
  clubVideo: "Video van de Club",
  clubWelcomeMeetingTime: "Tijdstip welkomstbijeenkomst van de club",
  comingSoon: "Binnenkort verwacht",
  completeDirectDebitPopupText: "Bespaar tijd op uw eerste bezoek door uw domiciliëring nu in te stellen.",
  completeForm: "Gelieve het formulier onderaan te vervolledigen.",
  completeItInClub: "Vervolledig domiciliëring in Club",
  completeItNow: "Vervolledig domiciliëring nu",
  completeSEPAInClub: "Vervolledig domiciliëring in Club",
  completeSEPANow: "Vervolledig domiciliëring nu",
  completeSEPAPopupText: "Bespaar tijd op uw eerste bezoek door uw domiciliëring nu in te stellen.",
  confirm: "Bevestig",
  confirmNoPaymentTaken: "Bevestig dat er geen betaling is verwerkt",
  confirmPassword: "Bevestig wachtwoord",
  confirmPasswordPlaceholder: "Bevestig uw nieuw paswoord",
  confirmPaymentTaken: "Bevestig dat de betaling is verwerkt",
  contact: "Contact",
  contactInformation: "Contactinformatie",
  contactMeToArrange: "Neem contact met mij om …",
  contactNotPromotedMonthlyPaymentErrorSubtitle: "Het opzetten van uw lidmaatschap duurt even achter de schermen. Probeer het opnieuw, maar maak u geen zorgen, er worden geen twee keer kosten in rekening gebracht.",
  contactNotPromotedMonthlyPaymentErrorTitle: "Sorry, we zijn niet zo snel als u vandaag!",
  contactPreferencesAfterPrivacyPolicy: "voor meer informatie. Vink het hokje aan wanneer je op de hoogte wilt blijven.",
  contactPreferencesBeforePrivacyPolicy: "We blijven graag met je in contact om je te informeren over onze nieuwste producten, diensten en aanbiedingen. Ben je akkoord, vink dan het onderstaande vakje aan. Je kunt je op elk gewenst moment bedenken. Zie onze",
  contactTheClubForOpeningTimes: "Neem contact op met de Club wanneer je meer informatie wilt over openingstijden.",
  "continue": "Ga door",
  cookiePolicyDescription: "Om uw ervaring te kunnen verbeteren hanteren wij cookies op de website. Door gebruik te maken van onze website stemt u in met het gebruik van deze cookies.",
  cookiesPolicy: "Cookiebeleid",
  copyright: "Auteursrecht",
  coreStrengthAndHIIT: "Kracht & Conditie, HIIT, Wellzijn en welbeleven, Yoga, Cycling",
  corporateFitness: "Unternehmensfitness",
  countryBelgium: "Belgium",
  countryFrance: "France",
  countryGermany: "Germany",
  countryIreland: "Ireland",
  countryItaly: "Italy",
  countryNetherlands: "Netherlands",
  countrySpain: "Spain",
  countrySwitzerland: "Switzerland",
  countryUnitedKingdom: "United Kingdom",
  county: "Provincie",
  countyPlaceholder: "Voer je land in",
  couple: "Koppel",
  creche: "Kinderopvang",
  creditorAddress: "Geregistreerd adres",
  creditorId: "Incassant-id",
  creditorName: "Bedrijfsnaam",
  dateAtTime: "%date% bij %time%",
  dateOfBirth: "Geboortedatum",
  days: "dagen",
  daytime: "Overdag",
  detailsAddedToMembershipAccount: "Deze details worden nu toegevoegd aan uw lidmaatschapsaccount.",
  diamondPlusRequired: "Diamond Plus-lidmaatschap is vereist voor toegang tot bepaalde Spa Retreats",
  directDebitClubOpens: "Uw eerste automatische incasso begint wanneer de club wordt geopend, rekening houdend met eventuele promoties die plaatsvinden op het moment dat u lid wordt.",
  directDebitClubReopens: "Uw maandelijkse betaling zal starten wanneer de club heropent en zal rekening houden met in voege zijnde promoties",
  directDebitMandate: "Machtiging voor automatische incasso",
  directDebitMembershipStarts: "Uw eerste automatische incasso gaat in op het moment dat uw lidmaatschap ingaat, rekening houdend met eventuele promoties die gelden op het moment van lid worden",
  discoverTheCreche: "Ontdek de kinderopvang",
  done: "Gedaan",
  downloadAppPrompt: "Om reserveringen te maken en uw lidmaatschap te beheren, downloadt u de David Lloyd Clubs App.",
  downloadDescriptionMembershipVerification: "Download onze mobiele app voordat je de Club bezoekt, zodat we je kunnen helpen met de registratie en het beste uit je lidmaatschap kunnen halen.",
  downloadDescriptionTennis: "Download de app en je kunt altijd en overal de roosters bekijken, lessen te boeken en tennisbanen te reserveren vanaf waar je ook bent.",
  downloadDescriptionTrialConfirmation: "Download voordat je de club bezoekt, zodat wij je kunnen helpen met de registratie en het beste uit je lidmaatschap kunnen halen.",
  downloadTheMobileAppHere: "Download hier de mobiele app:",
  dr: "Dr.",
  duration: "Duur",
  edit: "Pas aan",
  email: "Email",
  emailAddress: "Emailadres",
  emailAddressesMustBeUnique: "Voer een uniek e-mailadres in. Het moet verschillen van het e-mailadres dat is opgegeven door het primaire lid.",
  endsIn: "Eindigt in:",
  enjoyTimeTogetherAt: "Geniet van de tijd samen bij %clubPrefix% %clubName%",
  enquireAboutMembership: "Informeer naar een lidmaatschap",
  enquireNow: "Vraag nu aan",
  enquireText: "Vertel ons over uw verzoek",
  enterALocation: "Voer een locatie in...",
  enterValidEmailAddress: "Gelieve een geldig e-mailadres in te geven",
  enterValidMobilePhoneNumber: "Voer een geldig gsm-nummer in",
  enterValidName: "Voer een geldige naam in, inclusief een hoofdletter",
  enterValidPhoneNumber: "Voer een geldig telefoonnummer in",
  enterValidPostCode: "Voer een geldige postcode in",
  enterValidTaxId: "Please enter a valid tax ID",
  equipmentAt: "Onze apparatuur bij %clubName%",
  exerciseClasses: "Groepslessen",
  exerciseClassesAt: "Groepslessen bij %clubName%",
  experienceAllTheBenefits: "Ervaar alle voordelen van een lidmaatschap",
  expertPersonalTrainers: "Ervaren personal trainers",
  expertTuition: "Professionele begeleiding",
  exploreClub: "Ontdek %clubName%",
  exploreKidsActivities: "Ontdek activiteiten voor kids",
  failedToFetchExistingPayment: "A payment has already been made, but we encountered an error confirming details",
  familyAmpersandKids: "Gezin & kids",
  familyAndKids: "Gezin en kids",
  familySwim: "Zwemmen met het hele gezin",
  female: "Vrouw",
  findAClub: "Zoek een club",
  findOutMore: "Ontdek meer",
  findYourClub: "Vind jouw club",
  firstFullMonth: "Eerste volledige maand",
  firstMonthFees: "Aangezien we uw automatische incasso niet op tijd kunnen instellen voor uw eerste volledige maand, moet deze betaling vooraf gebeuren",
  firstName: "Voornaam",
  fitnessOverview: "Overzicht fitness",
  fixedTermFee: "Lidmaatschap van twaalf maanden vanaf %month%",
  fixedTermFeeTooltip: "Betaling ter dekking van de kosten van het lidmaatschap gedurende 12 kalendermaanden",
  flexible: "Flexibel",
  followUs: "Volg ons",
  followedByMonthly: "Gevolgd door maandelijkse betaling van",
  followedByMonthlyPaymentsTitle: "Gevolgd door maandelijkse betalingen",
  free: "Gratis",
  frequentlyAskedQuestions: " Veelgestelde Vragen",
  friend: "Vriend",
  friendsAndFamilyReferralAlreadyUsed: "Sorry, deze link is reeds in gebruik. Gelieve de persoon die u nomineerde te verzoeken u opnieuw te nomineren.",
  friendsAndFamilyReferralExpired: "Sorry, deze link is verlopen. Neem contact op met de persoon die je heeft doorverwezen om je opnieuw te nomineren.",
  friendsAndFamilyReferralNotFound: "Sorry, deze link werd niet gevonden. Gelieve de persoon die u nomineerde te verzoeken u opnieuw te nomineren.",
  from: "Van",
  from3MonthsPlus: "Vanaf 3+ maanden",
  from3To18Years: "Vanaf 3-18 jaar",
  gender: "Geslacht",
  germanAndFallbackOnly: "Test",
  getInTouch: "Neem contact op",
  getStarted: "Starten",
  getYourQuoteContactPreferencesAfterPrivacyPolicy: "voor meer informatie. Vink het hokje aan wanneer je op de hoogte wilt blijven",
  getYourQuoteContactPreferencesBeforePrivacyPolicy: "We blijven graag met je in contact om je te informeren over onze nieuwste producten, diensten en aanbiedingen. Ben je akkoord, vink dan het onderstaande vakje aan. Je kunt je op elk gewenst moment bedenken. Zie onze",
  groupExerciseClasses: "Groepslessen",
  gym: "Gym",
  havingProblemsSavingMonthlyPaymentDetails: "Sorry, we zijn vandaag niet zo snel als u! Het opzetten van uw lidmaatschap duurt een paar ogenblikken achter de schermen. Probeer het opnieuw, maar maak u geen zorgen de kosten zullen geen twee keer in rekening worden gebracht.",
  healthyBreakfast: "Gezond ontbijtje",
  heatedSwimmingPools: "Verwarmde zwembaden",
  highlightsAtClub: "Highlights van %clubPrefix% %clubName%*",
  hours: "uren",
  howCanWeContactYou: "Hoe kunnen we contact met je opnemen?",
  iAgree: "Ik ga akkoord",
  ifSomethingWereToChangeInYourLifestyle: "Als er iets zou veranderen aan uw levensstijl, hanteert %brandName% een bedenktijd van 14 dagen. Praat met een lid van het team voor meer informatie.",
  ifSomethingWereToChangeRightToCancel: "Als er iets zou veranderen aan uw levensstijl, hebt u het recht om te annuleren. Praat met een medewerker voor meer informatie.",
  imageDisclaimer: "*Disclaimer: afbeeldingen dienen uitsluitend ter illustratie en zijn mogelijk niet van de door u geselecteerde club",
  importantInformation: "Belangrijke informatie",
  includedInPromotion: "Inbegrepen bij de promotie",
  individual: "Individueel",
  initialEnquiryKeepYouUpdated: "Na uw eerste aanvraag houden we u graag op de hoogte van onze nieuwste producten, diensten en aanbiedingen. Als u geïnteresseerd bent, klik dan op de vakjes hieronder.",
  initialMonthTerm: "Initieel %monthDuration% maandelijkse termijn",
  isChildPresentText: "Mijn kind is vandaag bij mij in de Club",
  isPartnerPresentText: "Mijn partner is bij me in de Club",
  join: "Inschrijven",
  joinCorporate: "Sluit zakelijk aan",
  joinDifferentClub: "Sluit aan bij een andere club.",
  joinFriendsAndFamily: "Word lid van vrienden en familie",
  joinOurReferralScheme: "Het lijkt erop dat je probeert deel te nemen aan ons verwijzingsprogramma...",
  joinTodayAndSave: "Word vandaag lid en bespaar",
  joiningFee: "Inschrijfgeld",
  joiningFeeFirstMonth: "inclusief lidmaatschapsbijdrage en eerste maand",
  joiningFeeProRataFirstMonth: "Inschrijfgeld, rest van eerste maand en eerste volledige maand",
  joiningFeeTooltip: "Betaling om bij de club te horen",
  joiningFeeTwelveMonths: "Fee lidmaatschap, saldo voor de rest van de maand en 12 maanden lidmaatschap",
  joiningFees: "Lidmaatschapsbijdrage",
  joiningFeesApply: "Inschrijfgelden zijn van toepassing",
  joiningFeesWaived: "SPECIAAL AANBOD - Besparing op het inschrijfgeld nu beschikbaar",
  joiningTheClubAsAFamily: "Word lid met het hele gezin",
  justMe: "Alleen ik",
  keepAnEyeOutForFutureTrials: "Houd onze website in de gaten voor toekomstige trials of dien een aanvraag in via de website en het team op de Club neemt zo snel mogelijk contact met je op.",
  keepInTouch: "Houd contact",
  keepYouUpdated: "We informeren je graag over onze nieuwste producten, diensten en aanbiedingen. Ben je geïnteresseerd, vink dan het onderstaande vakje aan.",
  kickstartTitleWithName: "%firstName%, hoe zou u uzelf omschrijven?",
  kickstartTitleWithoutName: "Hoe zou u uzelf omschrijven?",
  kidsActivities: "Activiteiten voor kids",
  kidsBuildYourOwnPizza: "Maak je eigen pizza voor kids",
  kidsClub: "Club voor kinderen",
  lastName: "Achternaam",
  latestNews: "Latest News",
  learnMore: "Kom nog meer te weten",
  learnSomethingNew: "Leer iets nieuws",
  letThemEnjoyTheClub: "Laat ze van de Club genieten terwijl jij tijd neemt voor jezelf.",
  letUsKnowWhatYouAreInterestedIn: "Laat ons weten waar je interesse ligt, zodat we je rondleiding er op af kunnen stemmen",
  letUsKnowWhatYouAreLookingForwardTo: "Laat ons weten welke delen van de club je het liefst zou willen gebruiken.",
  likeOurPage: "Like onze pagina",
  linkIsInvalid: "Sorry, deze link is niet geldig.",
  loadingClubWelcomeTimes: "club welkomsttijden laden...",
  loadingTourTimes: "Tijden voor rondleidingen een het laden",
  logOut: "Uitloggen",
  login: "Log in",
  male: "Man",
  master: "Mr.",
  meAndMyFamily: "Ik + mijn gezin",
  meAndMyPartner: "Ik + mijn partner",
  membersLogin: "Login lid",
  membershipDescription: "Opgenomen in uw welkomst-email of op uw lidmaatschapskaart",
  membershipDiscountUntil: "Tot %date%",
  membershipEnquiryDetails: "De gebruiker selecteerde: %duration%%packageKey%\n%packageTypes%",
  membershipFees: "Lidmaatschapsgelden",
  membershipFeesUpUntil: "Lidmaatschapskosten tot %date%",
  membershipNumber: "Lidmaatschapsnummer",
  membershipPlaceholder: "Voer je lidmaatschapsnummer in",
  membershipPriceChangedError: "The payment has been confirmed, if you wish to change membership details, please complete joining journey and manually update club centric account",
  membershipSaving: "Besparing lidmaatschap",
  membershipStartClubConfirm: "Uw club zal bevestigen wanneer uw lidmaatschap begint",
  membershipStartClubOpens: "Uw lidmaatschap gaat in bij de opening van de club",
  membershipTermsAndConditions: "Algemene voorwaarden voor lidmaatschap",
  membershipTrial: "Lidmaatschapstrial",
  memberships: "Lidmaatschappen",
  min: "min",
  minutes: "minuten",
  miss: "Juffrouw",
  monthlyDirectDebitsStartOn: "Maandelijkse automatische incassobetalingen te beginnen op",
  monthlyPaymentsDuringOfferPeriodTitle: "Maandelijkse betalingen tijdens speciale aanbiedingsperiode",
  monthlySavings: "Maandelijkse besparingen",
  months: "maanden",
  more: "Meer",
  moreDetailsSkipDirectDebit: "Ik wil mijn domiciliëring activeren in Club",
  moreDetailsSkipSEPA: "Ik wil mijn domiciliëring activeren in Club",
  mostPopular: "Meest geliefd",
  mostRecent: "Most Recent",
  mr: "Dhr.",
  mrs: "Mw.",
  ms: "Juffrouw",
  mx: "Mx.",
  myMembership: "Mijn lidmaatschap",
  nearlyThere: "Bijna klaar",
  next: "Volgende",
  noAdditionalMembershipsAvailable: "Er zijn momenteel geen aanvullende lidmaatschapsopties beschikbaar.",
  noAvailablePromotions: "Er zijn geen aanbiedingen beschikbaar.",
  noClubWelcomeSlotsError: "Helaas hebben we op dit moment geen club welkomsttijden online beschikbaar. Neem a.u.b. contact met ons op, en wij zullen een tijdstip voor u vinden",
  noClubsInClubFinder: "Er zijn geen clubs in dit gebied, maar de dichtstbijzijnde clubs staan hieronder.",
  noJoiningFee: "Geen inschrijfkosten",
  noJoiningFeeOffer: "Speciaal aanbod: geen inschrijfgeld",
  noMembershipFeeForMonthOffer: "Bonusaanbod: geen contributie voor %month%",
  noMembershipFeeUntilMonthOffer: "Bonusaanbod: geen contributie tot %month%",
  noPreferencePackageKey: "geen voorkeur voor type lidmaatschapspakket; ",
  noPreferencePackageTypes: "geen voorkeur voor type lidmaatschapstoegang.",
  noTourSlotsError: "Helaas hebben wij via de site momenteel geen geschikte tijden voor een rondleiding beschikbaar. Neem contact met ons op zodat we een passende tijd voor je kunnen vinden.",
  normally: "Normaal",
  notAllMembershipsAvailable: "Niet alle lidmaatschappen zijn beschikbaar",
  notSureWhichClubsNearest: "Onduidelijk welke club het dichtste bij is?",
  now: "nu",
  onDate: "op %date%",
  onFacebook: "%clubName% op Facebook",
  onInstagram: "%clubName% op Instagram",
  onJoiningTheClubPayment: "Als u lid wordt van de club, wordt u gevraagd om een eerste betaling te doen, bestaande uit een inschrijfgeld en een pro rata betaling (dit is uw betaling vandaag). Dit wordt gevolgd door een maandelijks abonnement dat via automatische incasso wordt afgesloten. Als u uw lidmaatschapservaring wilt verbeteren, kunt u aanvullende producten toevoegen, zoals persoonlijke training of coaching, dit kan ook vandaag worden betaald.",
  oneMonth: "Een maand",
  onlyAvailableForALimitedTime: "Beperkt beschikbaar",
  openingHours: "Openingstijden",
  optInToSpecialOffer: "Voeg dit toe om u aan te melden voor de promotie",
  optOutAtAnyTimeAfterPrivacyPolicy: " voor volledige details!",
  optOutAtAnyTimeBeforePrivacyPolicy: "U kunt zich op elk moment afmelden. Bekijk onze",
  options: "Opties",
  organisation: "Organisatie",
  organisationOther: "Ander",
  other: "Andere",
  ourEquipmentAt: "Onze apparatuur bij %clubName%",
  ourFriendlyTeamAreLookingForwardToMeetingYou: "Ons vriendelijke team wil je graag ontmoeten.",
  outdoorPoolTimesWeatherDependent: "De openingstijden van ons buitenbad zijn afhankelijk van het weer",
  over14EmailAddress: "Leden ouder dan 14 kunnen gebruikmaken van de faciliteiten in de club. Voer hun e-mailadres in zodat ze kunnen worden ingeschreven.",
  over14PhoneNumber: "Leden ouder dan 14 kunnen de %brandName% mobiele app gebruiken, voer hiervoor hun mobiele nummer in.",
  "package": "Pakket",
  packageSelected: "%packageName% geselecteerd",
  padel: "Padel",
  paidOnline: "Online betaald",
  partner: "Partner",
  partnerEmail: "Uw partner ontvangt een e-mail met bevestiging van hun lidmaatschapsgegevens en om het lidmaatschap te voltooien. Voer hun mobiele nummer in en we sturen een link om te registreren naar de %brandName% mobiele app.",
  partnerWasReset: "Jammer genoeg omvat dit pakket geen partner optie - bekijk een upgrade naar ander pakket om uw partner toe te voegen",
  partnersAndSponsors: "Partners en sponsors",
  password: "Wachtwoord",
  passwordPlaceholder: "Voer je wachtwoord in",
  payAnnually: "Betaal jaarlijks",
  payAtReception: "Betalen bij de receptie",
  payMonthly: "Betaal maandelijks",
  payOnline: "Betaal online",
  payingAnnually: "Jaarlijks betalen is in principe goedkoper dan maandelijks betalen",
  paymentAlreadyConfirmed: "The payment has been confirmed, skip to the monthly payment page",
  paymentDetails: "Betaalgegevens",
  paymentLinkExpired: "Payment link expired",
  paymentReceived: "Payment received",
  paymentRefused: "Betaling geweigerd",
  penneArrabbiata: "Pasta arrabbiata",
  perMonth: "per maand",
  personalInformation: "Persoonlijke informatie",
  phoneNumber: "Mobiel telefoonnummer",
  pickAnotherTime: "Kies een andere tijd",
  pleaseCheckAndTryAgain: "Probeert u het later opnieuw.",
  pleaseCheckYourCardDetails: "Check je kaartgegevens",
  pleaseCheckYourDetailsAndTryAgain: "Controleer je gegevens en probeer het opnieuw. Als het probleem zich blijft voordoen, bezoek ons dan in de club en ons team helpt je graag verder.",
  pleaseCheckYourEmail: "Controleer uw e-mail\n",
  pleaseClickHereTo: "Klik hier om",
  pleaseContactOnlineJoining: "Neem contanct op met %email% voor assistentie",
  pleaseDoubleCheckAndTryAgain: "Controleer uw gegevens nogmaals en probeer het opnieuw",
  pleaseEnterANumber: "Geef een nummer in",
  pleaseMakePaymentAtReception: "U wordt verzocht bij de receptie te betalen",
  pleasePickAnotherTime: "Kies alsjeblieft een andere tijd.",
  pleaseRefreshOrTryAgainLater: "Refresh, of probeer het later opnieuw.",
  pleaseSelectATime: "Selecteer een tijd",
  pleaseTryAgainLater: "Probeer het later nog een keer",
  pleaseUseAnAlternativeBrowser: "Gebruik een andere browser, zoals een up-to-date versie van Microsoft Edge, Google Chrome of Safari",
  pleaseVerifyCaptcha: "Verifieer alstublieft de captcha om door te gaan",
  policies: "Beleid",
  policiesAndDocuments: "Beleid en documenten",
  poolOpeningHours: "Openingstijden zwembad",
  poolOpeningTimes: "Openingstijden zwembad",
  poolTimes: "Zwemtijden",
  pools: "Zwembaden",
  poolsAreClosed: "Al onze zwembaden zijn momenteel gesloten",
  postCode: "Postcode",
  postCodePlaceholder: "Voer je postcode in",
  preferNotToSay: "niet van toepassing",
  press: "Pers",
  pressCentre: "Press Centre",
  previous: "Vorige",
  priceDuringPromotionalPeriod: "prijs tijdens de actieperiode",
  pricePerAdult: "%adultPrice% per volwassene",
  pricePerChild: "%childPrice% per kind",
  primaryMember: "Primair lid",
  privacyPolicy: "privacy voorwaarden",
  promotionSelected: "Promotie geselecteerd",
  promotionalItems: "Promotie-artikelen",
  promotions: "Aanbiedingen",
  qrCodeExpired: "QR code expired - please pay at reception",
  quickRegistration: "Snelle Registratie",
  referral: "Verwijzing",
  refundedIfYouJoinOnAStandardMembership: "Kosten wanneer je geen standaard lidmaatschap hebt",
  regionUnitedKingdomEast: "East",
  regionUnitedKingdomGreaterLondon: "Greater London",
  regionUnitedKingdomMidlands: "Midlands",
  regionUnitedKingdomNorthEast: "North East",
  regionUnitedKingdomNorthWest: "North West",
  regionUnitedKingdomNorthernIreland: "Northern Ireland",
  regionUnitedKingdomScotland: "Scotland",
  regionUnitedKingdomSouth: "South",
  regionUnitedKingdomSouthEast: "South East",
  regionUnitedKingdomSouthWest: "South West",
  regionUnitedKingdomWales: "Wales",
  registerForClubTrial: "Start vandaag met uw proeflidmaatschap",
  registerForClubTrialButton: "Start uw proeflidmaatschap",
  registeredCompanyNumber: "Bedrijfsregistratienummer",
  registrationErrorMessage: "Controleer uw details. Stuur ons een email op <a style=\"color: %emailColor%;\" href=\"mailto:%email%\">%email%</a> als u problemen hebt met de registratie van een account en iemand zal u zo snel mogelijk contacteren.",
  registrationUnauthorised: "Goedkeuring mislukt",
  relaxAmpersandSocialise: "Ontspanning & sociaal contact",
  relaxAndEnjoySpaRetreats: "Ontspan en geniet van Spa Retreats",
  relaxAndEnjoyTheSpaRetreatsAt: "Ontspan en geniet van de spa-retraites op:",
  relaxAndSocialise: "Relax en socialise",
  remainderOfFirstMonth: "Rest van de eerste maand",
  remainderOfFirstMonthAnd12MonthsMembership: "Rest van de eerste maand en 12 kalendermaanden van het lidmaatschap",
  remainderOfFirstMonthAndFirstFullMonth: "Rest van de eerste maand en de eerste volledige maand",
  remainderOfThisMonth: "Rest van deze maand",
  remainderOfThisMonthTooltip: "Betaling ter dekking van de kosten van het lidmaatschap voor de rest van deze maand",
  requestingAClubWelcome: "Een club welkom afspraak aanvragen",
  retry: "Probeer nogmaals",
  saveAmount: "Bespaar %amount%",
  savingUpTo: "Bespaar tot %price%*",
  scanQrCodeToDownload: "Scan de QR-code om de app te downloaden",
  scanQrCodeToPay: "Scan the QR code to proceed to payment page",
  seeAllFacilities: "Bekijk alle faciliteiten",
  seeDetails: "Zie details",
  seeMore: "Bekijk meer",
  selectADate: "Selecteer een datum",
  selectAnOrganisation: "Selecteer een bedrijf of groep…",
  selectOptionsBelow: "Kies uit de onderstaande opties voor activiteiten voor het hele gezin.",
  selectPackage: "Selecteer %packageName%",
  selectPromotion: "Selecteer promotie",
  selectTitle: "Selecteer een aanspreektitel...",
  selectedDuration: "een %durationType% pakketduur; ",
  selectedPackageKey: "een%packageKey% -lidmaatschap; ",
  selectedPackageTypes: "lidmaatschapstoegang die %packageTypes% omvat.",
  selectedTourTimeNoLongerAvailable: "Helaas is de door jou geselecteerde tourtijd niet langer beschikbaar.",
  selectedTourTimeNoLongerAvailableHeader: "Tourtijd niet beschikbaar",
  sendQuote: "Stuur een prijsopgaaf",
  sepaGuaranteeLine1: "Door ondertekening van dit formulier geett u toestemming aan [A] %companyName% om doorlopend incasso-opdrachten te sturen naar uw bank om een bedrag van uw rekening at te schrijven en [Bl uw bank om doorlopend een bed rag at te schrijven overeenkomstig de opdracht \nvan %companyName%.",
  sepaGuaranteeLine2: "Als u het niet eens bent met deze afschrijving dan kunt u deze laten terugboeken. Neem hiervoor binnen acht weken na afschrijving contact op met uw bank. Vraag uw bank naar de voorwaarden. ",
  sepaGuaranteeLine3: "Type betaling: doorlopend (mag meerdere malen gebruikt worden)",
  sepaGuaranteeLine4: "Uw rechten met betrekking tot dit mandaat worden uitgelegd in een verklaring die u van uw bank kunt verkrijgen.",
  sepaGuaranteeLine5: "Type betaling: terugkerend (kan meer dan eens worden gebruikt)",
  sepaTitle: "Machtiging doorlopende SEPA Incasso Algemeen",
  settingUpYourDirectDebit: "Uw automatische incasso instellen",
  settingUpYourMonthlyPayment: "Uw maandelijkse betaling instellen",
  shareOnFacebook: "Deel op Facebook",
  shareOnTwitter: "Deel op Twitter",
  signMeUp: "Ik wil me inschrijven",
  signUpForAGradeCoaching: "Schrijf je in voor coaching van het hoogste niveau voor alle leeftijden",
  skip: "Skip",
  skipPayment: "Betaling overslaan, ik wil graag betalen bij de receptie",
  slotsTakingAWhileToLoad: "Het duurt even voordat wij beschikbare tijden hebben gevonden. We blijven proberen. Echter, je kunt ook direct contact opnemen door de onderstaande gegevens te gebruiken.",
  snrgyFamilyFitness: "Synrgy fitness voor het hele gezin",
  socialMedia: "Social Media",
  softPlay: "Spelen voor de kleintjes",
  somethingHasGoneWrong: "Er is iets mis gegaan",
  sorryIeNotSupported: "Het spijt ons, Internet Explorer wordt niet ondersteund",
  sorrySomethingHasGoneWrongPleaseTryAgain: "Sorry, er is iets mis gegaan. Probeer nog een keer.",
  sorrySomethingWentWrong: "Sorry, er ging iets mis.",
  sorryThereWasAProblemWithYourPayment: "Sorry, er is een probleem met je betaling.",
  sorryWereHavingTechnicalProblems: "Sorry, we hebben een technische storing",
  sortBy: "Sort by:",
  sortByMyLocation: "Sort by my location",
  sortCode: "Sorteercode",
  spa: "Wellness",
  specialHours: "Speciale uren",
  specialOffer: "Speciale aanbieding",
  specialsAndEvents: "Specials en evenementen",
  sportsOverview: "Sport overzicht",
  standard: "Standaard",
  startDate: "Startdatum",
  startInNextWeek: "Start uw lidmaatschap wanneer u wilt volgende week",
  startOnFixedDate: "U kunt uw lidmaatschap starten op",
  stateOfTheArtGymFacilities: "Hypermoderne fitness faciliteiten",
  studioOpeningHours: "Openingstijden studio",
  studioOverview: "Overzicht studio",
  submitAndStartMembership: "Dien in en begin te genieten van uw lidmaatschap",
  swimmingAt: "Zwemmen bij %clubName%",
  swimmingCoaching: "Zwembegeleiding",
  swimmingPools: "Zwembaden",
  tailorYourClubWelcome: "Maak uw clubontvangst op maat.",
  tailorYourTour: "Stel je rondleiding samen",
  takeOurVideoTour: "Bekijk onze video om een idee te krijgen van de %brandName% ervaring*",
  takeTheNextStep: "Ga door naar de volgende stap",
  takeTheNextStepDescription: "Ontdek hoe een lidmaatschap bij %clubPrefix% %clubName% jou en de 'familytime' met je gezin kunnen veranderen.",
  taxId: "Tax ID",
  telephone: "Telefoon",
  tennis: "Tennis",
  tennisClub: "Tennisclub",
  tennisCoaching: "Tennisbegeleiding",
  termsAndConditions: "Algemene voorwaarden",
  textMessage: "SMS",
  thankYouForSettingUpDirectDebit: "Dank u wel voor het instellen van uw automatische incasso",
  thankYouForSubscribing: "Dank u voor uw inschrijving!",
  thankYouWelcomeToBrand: "Dank je. Welkom bij %brandName%!",
  theClub: "De Club",
  thereAreCurrentlyNoMembershipPackages: "Sorry, er is momenteel geen online lidmaatschap beschikbaar. Gelieve uw plaatselijke club te contacteren om meer te weten te komen over uw lidmaatschap.",
  threeMonths: "3 maanden",
  timetable: "Rooster",
  title: "Aanhef",
  today: "Vandaag",
  tomorrow: "morgen",
  totalDue: "Totaal te betalen",
  totalDueToday: "Totaal verschuldigd",
  totalSavedJoiningToday: "Totale besparing wanneer je vandaag lid wordt",
  townOrCity: "Stad/gemeente",
  townOrCityPlaceholder: "Voer je gemeente of stad in",
  treatments: "Face & Body Day Spa",
  treatmentsOverview: "Overzicht van behandelingen",
  unableToOfferTrial: "Sorry, we kunnen je momenteel geen trial aanbieden",
  unableToTakeClubWelcomeBookings: "Helaas kunnen wij op dit moment geen online boekingen aannemen - u kunt contact met ons opnemen via de onderstaande gegevens om uw clubwelkomst te boeken.",
  unableToTakeTourBookings: "Jammer, we kunnen momenteel geen online boeking voor je maken- je kunt contact met ons opnemen via de onderstaande gegevens om je rondleiding te boeken.",
  unexpectedError: "Onverwachte fout",
  unfortunatelyAnErrorHasOccurred: "Helaas, er doet zich een probleem voor",
  unfortunatelyThisTimeIsNoLongerAvailable: "Helaas, deze tijd is niet meer beschikbaar.",
  unknownMembershipError: "Je lidmaatschap is naar alle waarschijnlijkheid succesvol geregistreerd. Controleer daarom of je een bevestigingsmail hebt ontvangen. Heb je deze niet binnen een uur ontvangen, probeer het dan nogmaals.",
  unsubscribe: "Afmelden",
  updateAppPrompt: "Update uw app naar de nieuwste versie en probeer het opnieuw.",
  upgradeYourMembershipToAddChildren: "Upgrade uw lidmaatschap om een kind toe te voegen.",
  upgradeYourMembershipToAddPartner: "Upgrade uw lidmaatschap en voeg een partner toe",
  useMyLocation: "Gebruik mijn locatie",
  userAlreadyExists: "Dit lidmaatschapsnummer is reeds in gebruik",
  userInputOrganisation: "Wat is de naam van uw bedrijf of groep?",
  userVerificationFailed: "Verificatie mislukt",
  usualJoiningFees: "Normale inschrijfgelden",
  vatNumber: "BTW-nr.",
  viewAllClubs: "Bekijk alle clubs",
  viewInMaps: "Bekijk op kaart",
  viewMap: "View a map of all David Lloyd Clubs",
  viewOurFoodMenu: "Bekijk onze menukaart",
  viewTimetable: "Bekijk rooster",
  visitSpaRetreats: "Bezoek Spa Retreats",
  was: "was",
  weAlreadyHaveYourDetails: "%firstName%, uw details staan reeds in ons systeem",
  weDontRecogniseThatLocation: "Sorry, we herkennen die locatie niet",
  weJustNeedAFewMoreDetails: "We hebben nog een paar details van je nodig",
  weSendOurMostExcitingOffers: "We sturen jou onze meest geweldige aanbiedingen per mail",
  weWereUnableToSaveDetails: "Sorry, we konden uw gegevens niet opslaan",
  weekend: "Weekend",
  weeklyTimetable: "Wekelijks rooster",
  welcomeTo: "Welkom bij",
  whatToExpect: "Wat kun je verwachten?",
  whatsApp: "WhatsApp",
  whereToFindUs: "Waar kun je ons vinden?",
  whoCanCome: "Wie kunnen er komen?",
  whosComing: "Wie komt er?",
  workEmailAddress: "E-mailadres van het werk",
  years: "jaren",
  youngChildren: "Jonge kinderen",
  yourCardDetails: "Jouw kaartgegevens",
  yourChild: "jouw kind (%range%)",
  yourChildren: "jouw kinderen (%range%)",
  yourClubWelcomeIsBooked: "%name%, uw club welkom is geboekt",
  yourDetails: "Uw gegevens",
  yourFirstFullMonth: "Uw eerste volledige maand (%month%)",
  yourFirstFullMonthTooltip: "Betaling die de kosten van het lidmaatschap dekt totdat uw automatische incasso is ingesteld bij uw bank",
  yourMembership: "Uw lidmaatschap",
  yourMembershipTrial: "Jouw lidmaatschapstrial",
  yourNannies: "Uw nanny",
  yourNanny: "Uw nanny",
  yourOlderChild: "Uw oudere kind (%range%)",
  yourOlderChildren: "Uw oudere kinderen (%range%)",
  yourPartner: "Uw partner",
  yourSavings: "Uw besparingen",
  yourSavingsSubtitle: "Dit kunt u besparen door vandaag lid te worden",
  yourStartDate: "Uw startdatum",
  yourTourIsBooked: "%name%, jouw rondleiding is geboekt",
  yourYoungChild: "Uw jonge kind (%range%)",
  yourYoungChildren: "Uw jonge kinderen (%range%)"
};
