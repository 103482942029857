import React, { useContext } from 'react';
import { FooterLinkContent } from 'src/models/cms/footer-content';
import { Styling } from 'src/context/styling';
import mq from 'src/styling/media-queries';
import { SiteContext } from 'src/context/site-context';
import useWindowSize from 'src/custom-hooks/use-window-size';
import { useIsOpen } from 'src/custom-hooks/use-is-open';
import ExpandIcon from 'src/components/icons/expand';

const footerNavMenuSectionTitle = (styling: Styling) => {
  return mq.withSmallTablet({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: ['center', 'center', 'left'],
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: styling.colors.footerNavMenuTitle,
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    paddingLeft: ['2rem', '1rem', '2rem'],
    border: '0 solid',
    borderColor: styling.colors.headerBorder,
    borderTopWidth: ['1px', '0px'],
    borderBottomWidth: ['1px', '0px'],
  });
};

const footerNavMenuLink = (styling: Styling) => {
  return mq({
    'fontSize': '0.9rem',
    'letterSpacing': '1px',
    'paddingTop': '1rem',
    'position': 'relative',
    'display': 'block',
    '&:hover': {
      color: styling.colors.highlightText,
    },
    '&:empty': {
      display: 'none',
    },
    '&:last-child': {
      paddingBottom: '1rem',
    },
  });
};

type FooterLinksColumnProps = {
  title: string;
  links: FooterLinkContent[];
};

const FooterNavMenuSection: React.FC<FooterLinksColumnProps> = ({ title, links }) => {
  const { styling } = useContext(SiteContext);
  const { isMobileOrSmaller } = useWindowSize();
  const sectionState = useIsOpen(false);

  return (
    <li role="presentation">
      <h4 onClick={sectionState.toggle} css={footerNavMenuSectionTitle(styling)}>
        {title}
        {isMobileOrSmaller && (
          <span>
            <ExpandIcon
              size="1rem"
              fill={styling.colors.accordionIcon}
              isExpanded={sectionState.isOpen}
            />
          </span>
        )}
      </h4>
      <ul css={{ display: !isMobileOrSmaller || sectionState.isOpen ? 'block' : 'none' }}>
        {links.map((link, index) => (
          // using the index as the key because links are never reordered/inserted/deleted dynamically
          <li key={index} css={footerNavMenuLink(styling)}>
            <a
              href={link.url}
              target={link.openInNewTab ? '_blank' : undefined}
              rel={link.openInNewTab ? 'noopener noreferrer' : undefined}
            >
              <> {link.text} </>
            </a>
          </li>
        ))}
      </ul>
    </li>
  );
};

export default FooterNavMenuSection;
