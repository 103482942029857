import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { CSSInterpolation } from '@emotion/serialize';
import Helmet from 'react-helmet';

function getObjectPosition(imageDetails: Queries.ImageDetails) {
  switch (imageDetails.horizontalFocus) {
    case 'start':
      return '0% 50%';
    case 'left':
      return '25% 50%';
    case 'right':
      return '75% 50%';
    case 'end':
      return '100% 50%';
    default:
      return '50% 50%';
  }
}

type FixedImageProps = {
  imageDetails: Queries.ImageDetails;
  styles?: CSSInterpolation;
  loading?: 'eager' | 'lazy';
  onImageLoaded?: () => void;
  enablePreload?: boolean;
};

const FixedImage = forwardRef<HTMLImageElement, FixedImageProps>(
  ({ imageDetails, styles, loading, onImageLoaded = () => {}, enablePreload = false }, ref) => {
    const innerRef = useRef<HTMLImageElement>(null);
    useImperativeHandle(ref, () => innerRef.current, []);

    useEffect(() => {
      if (innerRef.current?.complete) {
        onImageLoaded?.();
      }
    }, []);

    if (!imageDetails || !imageDetails.imagePath) {
      return null;
    }

    return (
      <>
        {enablePreload && (
          <Helmet>
            <link rel="preload" fetchPriority="high" as="image" href={imageDetails.imagePath} />
          </Helmet>
        )}
        <img
          ref={innerRef}
          src={imageDetails.imagePath}
          alt={imageDetails.alternateText}
          css={[
            {
              width: '100%',
              height: '100%',
              objectFit: `cover`,
              objectPosition: getObjectPosition(imageDetails),
              verticalAlign: 'middle',
            },
            styles,
          ]}
          loading={loading}
          onLoad={onImageLoaded}
        />
      </>
    );
  }
);

FixedImage.displayName = 'FixedImage';

export default FixedImage;
