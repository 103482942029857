import React from 'react';
import { IconProps } from 'src/components/icons/icon-types';

const RedesignDownChevronThin = ({ size, fill, svgStyle }: IconProps) => (
  <svg
    width={size ?? 15}
    viewBox="0 0 15 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={svgStyle}
  >
    <path
      d="M14.8438 1.09375C15 1.25 15 1.46875 14.8438 1.625L8.25 8.21875C8.09375 8.375 7.875 8.375 7.71875 8.21875L1.125 1.625C0.96875 1.46875 0.96875 1.25 1.125 1.09375L1.34375 0.875C1.5 0.71875 1.71875 0.71875 1.875 0.875L8 6.96875L14.0938 0.875C14.25 0.71875 14.4688 0.71875 14.625 0.875L14.8438 1.09375Z"
      fill={fill}
    />
  </svg>
);

export default RedesignDownChevronThin;
