import React from 'react';
import { WebsiteLink } from 'src/components/common/website-link';
import { getImagePathForClubFacilityIcon } from 'src/helpers/fixed-images';
import { ClubFacilitiesPage } from 'src/models/cms/navigation-menu-content';
import { colors } from 'src/styling/constants';

const ClubFacilitiesMobileNavItem = ({ displayText, link, mobileNavIcon }: ClubFacilitiesPage) => (
  <WebsiteLink to={link} css={{ display: 'flex', flex: 'auto' }}>
    <div
      css={{
        fontSize: '1rem',
        alignItems: 'center',
        padding: '.5rem',
        borderLeft: `1px solid ${colors.borderGrey}`,
        display: 'flex',
        justifyContent: 'center',
        minWidth: '7rem',
        width: 'max-content',
      }}
    >
      <img
        src={getImagePathForClubFacilityIcon(mobileNavIcon)}
        css={{
          width: '1.875rem',
        }}
      />
      <p css={{ margin: '0 .5rem', textAlign: 'center' }}>{displayText}</p>
    </div>
  </WebsiteLink>
);

export default ClubFacilitiesMobileNavItem;
