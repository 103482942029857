import React from 'react';
import mq from 'src/styling/media-queries';
import { FooterNavMenuSectionContent } from 'src/models/cms/footer-content';
import FooterNavMenuSection from 'src/components/layout/footer/footer-nav-menu-section';

const footerNavMenuList = mq({
  display: ['grid', 'flex'],
  justifyContent: 'space-between',
  gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
  width: '100%',
  padding: ['0', '0.5rem 2rem'],
});

type FooterNavMenuProps = {
  sections: FooterNavMenuSectionContent[];
};

const FooterNavMenu: React.FC<FooterNavMenuProps> = ({ sections }) => {
  return (
    <div>
      <ul css={footerNavMenuList}>
        {sections?.map((section, index) => (
          // using the index as the key because sections are never reordered/inserted/deleted dynamically
          <FooterNavMenuSection key={index} title={section.title} links={section.links} />
        ))}
      </ul>
    </div>
  );
};

export default FooterNavMenu;
