export const blueBackground = 'rgba(50,62,72,0.4)';

const imageFade = {
  fadeDownwards: 'transparent, rgba(1,1,1,0.45)',
  fadeDownwardsToBlack: 'transparent, black',
  fullFade: 'rgba(1,1,1,0.2), rgba(1,1,1,0.2)',
  fullFadeDarker: 'rgba(1,1,1,0.4), rgba(1,1,1,0.4)',
  none: 'transparent, transparent',
  fadeTopAndBottom: 'rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 52%, rgb(0, 0, 0) 100%',
  blueFullFade: `${blueBackground}, ${blueBackground}`,
  bottomFade: '180deg, rgba(46, 40, 44, 0.00) 41.32%, rgba(46, 40, 44, 0.70) 88.62%',
  centerRight:
    '97deg, rgba(51, 63, 81, 0.00) 37.35%, rgba(51, 63, 81, 0.60) 68.81%, rgba(51, 63, 81, 0.60) 86.45%, rgba(51, 63, 81, 0.00) 101.7%',
  centerLeft:
    '77deg, rgba(51, 63, 81, 0.00) 0%, rgba(51, 63, 81, 0.60) 31.81%, rgba(51, 63, 81, 0.60) 49.45%, rgba(51, 63, 81, 0.00) 63.7%',
  bottomLeft:
    '206deg, rgba(51, 63, 81, 0.00) 49.03%, rgba(51, 63, 81, 0.60) 68.27%, rgba(51, 63, 81, 0.60) 100.62%, rgba(51, 63, 81, 0.00) 122.08%',
  bottomRight:
    '145deg, rgba(51, 63, 81, 0.00) 48.52%, rgba(51, 63, 81, 0.60) 67.62%, rgba(51, 63, 81, 0.60) 99.73%, rgba(51, 63, 81, 0.00) 121.04%',
};

export default imageFade;
