import React, { useContext } from 'react';
import { CSSInterpolation } from '@emotion/serialize';
import { WebsiteLink } from 'src/components/common/website-link';
import { EnabledPages } from 'src/content/enabled-pages-helper';
import { FacilityDetails, findFacilityFromUrlPath, isDisabledFacility } from './facilities';
import { ButtonStyle } from 'src/enums/button-style';
import { StaticTextLookupKey } from 'src/translators/static-text-lookup';
import { SiteContext } from 'src/context/site-context';
import { WebsiteLinkButtonContent } from 'src/components/common/website-link-button-content';
import kebabCase from 'lodash/kebabCase';

type ButtonBaseProps = {
  buttonText?: string | React.ReactElement;
  buttonKey?: StaticTextLookupKey;
  buttonLink: string;
  buttonCss?: CSSInterpolation;
  buttonEndIcon?: React.ReactElement;
  buttonType?: string;
  showButton?: boolean;
  gtmTrackingId?: string;
  openInNewTab?: boolean;
};

export type ButtonDetails = ButtonBaseProps & {
  buttonStyle?: ButtonStyle;
  showButton?: boolean;
};

export type CustomHeroButtonsProps = {
  primaryButton?: ButtonDetails;
  secondaryButton?: ButtonDetails;
  useCustomHeroButtons?: boolean;
};

type ClubLinkButtonContentProps = ButtonBaseProps & {
  features: any;
  enabledPages: EnabledPages;
  className?: string;
  buttonStyle?: string;
  isRedesignButton?: boolean;
  handleClick?: () => void;
};

const ClubLinkButtonContent = ({
  buttonText,
  buttonKey,
  buttonLink,
  buttonCss,
  buttonEndIcon,
  buttonType,
  gtmTrackingId,
  features,
  enabledPages,
  className = '',
  buttonStyle,
  isRedesignButton,
  openInNewTab,
  handleClick,
}: ClubLinkButtonContentProps) => {
  const { language } = useContext(SiteContext);
  const buttonShouldNotRender =
    (!buttonText && !buttonKey) ||
    !buttonLink ||
    (buttonLink === '/timetable/' && !features.timetable);

  if (buttonShouldNotRender) {
    return null;
  }

  const facility: FacilityDetails = findFacilityFromUrlPath(buttonLink, language);

  if (facility && isDisabledFacility(enabledPages, facility)) {
    return null;
  }

  const content = (
    <WebsiteLinkButtonContent
      buttonCss={buttonCss}
      buttonKey={buttonKey}
      buttonText={buttonText}
      buttonEndIcon={buttonEndIcon}
      buttonType={buttonType}
      gtmTrackingId={gtmTrackingId}
    />
  );

  const tabIndexProp = isRedesignButton ? { tabIndex: 0 } : {};

  if (
    buttonLink.indexOf('http') !== -1 ||
    buttonLink.indexOf('localhost') !== -1 ||
    buttonLink.indexOf('mailto:') !== -1 ||
    openInNewTab
  ) {
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={buttonLink}
        className={
          isRedesignButton ? `redesign-button ${kebabCase(buttonStyle)} ${className}` : className
        }
        onClick={() => handleClick && handleClick()}
        {...tabIndexProp}
      >
        {content}
      </a>
    );
  }

  return (
    <WebsiteLink
      to={buttonLink || '/'}
      className={
        isRedesignButton ? `redesign-button ${kebabCase(buttonStyle)} ${className}` : className
      }
      isTabIndex={isRedesignButton}
      handleClick={handleClick}
    >
      {content}
    </WebsiteLink>
  );
};

export default ClubLinkButtonContent;
