import { Location, WindowLocation } from '@gatsbyjs/reach-router';
import React, { useContext } from 'react';
import { isOnClubPage, isOnGlobalPage } from 'src/helpers/path-matching-helper';
import { colors } from 'src/styling/constants';
import mq from 'src/styling/media-queries';
import { WebsiteLink } from '../../common/website-link';
import { TranslatedStaticText } from 'src/translators/static-text-translator';
import { SiteContext } from 'src/context/site-context';
import { CmsContent } from 'src/content';
import { clubPageIsDisabledInCms } from 'src/content/optional-page-helper';
import { GlobalCmsContent } from 'src/content/global-content';
import { getClubDisplayValues } from 'src/enums/build/build-type';
import { Styling } from 'src/context/styling';
import {
  DesktopSubNavMainPageLinks,
  DesktopSubNavSecondaryPageLinks,
} from './desktop-subnav-menu-links';
import { getBuildDomainForBuildType, newWebsiteBuildDomains } from 'src/enums/build/build-domain';

const subNavWrapperStyling = (styling: Styling, isBlaze: boolean, isNewDesign: boolean) =>
  mq({
    minHeight: '3.5rem',
    flex: 1,
    color: isNewDesign ? styling.colors.capeCod : styling.colors.headerNavigationText,
    fontSize: isNewDesign ? '1.1875rem' : '1rem',
    display: ['none', 'flex'],
    borderBottom: isBlaze || isNewDesign ? `1px solid ${styling.colors.headerBorder}` : null,
    padding: [null, '0 2rem'],
    textAlign: 'center',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: isNewDesign ? styling.colors.cream : 'unset',
  });

const atUnitTextStyling = (styling: Styling, isNewDesign: boolean) => [
  styling.common.headerStyle,
  styling.fonts.regular,
  {
    padding: isNewDesign ? '0.875rem 2rem 0.875rem 1rem' : '1rem 2rem 1rem 1rem',
    color: colors.lightSilver,
    lineHeight: isNewDesign ? '145%' : 'unset',
  },
];

type DesktopNavLinkProps = {
  location: WindowLocation;
  path: string;
  displayText: React.ReactNode | string;
};

// TODO a lot of functionality is shared between this + WebsiteLink, and MobileNavLink
export const DesktopNavLink: React.FC<DesktopNavLinkProps> = ({ location, path, displayText }) => {
  const { siteId, styling, language, isGlobalPage, buildType } = useContext(SiteContext);
  const cmsContent = useContext(CmsContent);

  if (!isGlobalPage && clubPageIsDisabledInCms(path, cmsContent.pages)) {
    return null;
  }

  const shouldHighlight = isGlobalPage
    ? isOnGlobalPage(location, path, language)
    : isOnClubPage(location, siteId, path, language);

  const isNewDesign = newWebsiteBuildDomains.includes(getBuildDomainForBuildType(buildType));
  const oldActiveState = shouldHighlight ? styling.fonts.bold : styling.fonts.regular;
  const newActiveState = styling.fonts.regular;
  const linkStyles = isNewDesign
    ? {
        'margin': '0.875rem 1rem',
        'cursor': 'pointer',
        'borderBottom': '1px solid',
        'borderBottomColor': shouldHighlight ? styling.colors.capeCod : 'transparent',
        'lineHeight': '145%',
        '&:hover': {
          color: styling.colors.camelot,
          borderBottomColor: styling.colors.camelot,
        },
      }
    : {
        padding: '1rem',
        cursor: 'pointer',
      };

  return (
    <WebsiteLink to={path}>
      <div
        css={[
          styling.common.headerStyle,
          isNewDesign ? newActiveState : oldActiveState,
          linkStyles,
        ]}
        data-gtmid="nav-tab"
      >
        {displayText}
      </div>
    </WebsiteLink>
  );
};

type DesktopSubNavProps = {
  clubName: string;
};

const DesktopSubNav: React.FC<DesktopSubNavProps> = ({ clubName }) => {
  const clubCmsData = useContext(CmsContent);
  const globalCmsData = useContext(GlobalCmsContent);
  const { styling, language, isBlaze, isGlobalPage, buildType } = useContext(SiteContext);
  const isNewDesign = newWebsiteBuildDomains.includes(getBuildDomainForBuildType(buildType));

  const navigationPagesToDisplay = isGlobalPage
    ? globalCmsData.sharedContent?.navigationPagesToDisplay
    : clubCmsData.navigationMenuContent?.navigationPagesToDisplay;

  return (
    <Location>
      {({ location }) => (
        <div css={subNavWrapperStyling(styling, isBlaze, isNewDesign)}>
          <div css={atUnitTextStyling(styling, isNewDesign)}>
            {isGlobalPage ? (
              <TranslatedStaticText
                lookupKey="atUnit"
                params={{ unitName: getClubDisplayValues(buildType).displayName }}
              />
            ) : (
              <TranslatedStaticText lookupKey="atClub" params={{ clubName: clubName }} />
            )}
          </div>
          <DesktopSubNavMainPageLinks
            pagesToDisplay={navigationPagesToDisplay?.mainPages}
            language={language}
            location={location}
            isGlobalPage={isGlobalPage}
          />
          <DesktopSubNavSecondaryPageLinks
            pagesToDisplay={navigationPagesToDisplay?.secondaryPages}
            language={language}
            location={location}
          />
        </div>
      )}
    </Location>
  );
};

export default DesktopSubNav;
