import { BuildType } from 'src/enums/build/build-type';

export const getBuildTypesFlags = (buildType: BuildType) => {
  const isMeridian = buildType === BuildType.MERIDIAN;
  const isBlaze = buildType === BuildType.BLAZE_STUDIO;
  const isHarbour = buildType === BuildType.HARBOUR;
  const isDL =
    buildType !== BuildType.MERIDIAN &&
    buildType !== BuildType.BLAZE_STUDIO &&
    buildType !== BuildType.HARBOUR;

  return { isMeridian, isBlaze, isHarbour, isDL };
};
