import { CSSInterpolation } from '@emotion/serialize';
import { Colors } from './colors';
import { Fonts } from './fonts';
import { BuildType } from '../../enums/build/build-type';

export type CommonStyle = {
  link: CSSInterpolation;
  defaultList: CSSInterpolation;
  headerStyle: CSSInterpolation;
  pageTitleStyle: CSSInterpolation;
  focusOutline: CSSInterpolation;
};

export const getCommon = (buildType: BuildType, colors: Colors, fonts: Fonts): CommonStyle => {
  return {
    link: [
      fonts.regular,
      {
        color: colors.highlightText,
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    ],
    defaultList: {
      listStyle: 'disc',
      padding: '0 0.25rem',
      li: [
        fonts.regular,
        {
          'color': colors.highlightText,
          'marginLeft': '1.25rem',
          'display': 'list-item',
          '*': {
            color: colors.bodyText,
          },
        },
      ],
    },
    headerStyle: {
      textTransform: buildType === BuildType.HARBOUR ? 'uppercase' : null,
    },
    pageTitleStyle: {
      letterSpacing: buildType === BuildType.HARBOUR ? [null, '1rem'] : null,
    },
    focusOutline: {
      outline: `solid ${colors.focusOutlineColor} 5px`,
      outlineOffset: '3px',
    },
  };
};
