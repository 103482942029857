import * as React from 'react';
import Helmet from 'react-helmet';
import { getGtmId } from 'src/helpers/config-helper';
import { useContext, useEffect, useState } from 'react';
import { SiteContext } from 'src/context/site-context';
import { BuildType } from 'src/enums/build/build-type';
import { buyapowaLibrarySource } from 'src/helpers/buyapowa-helpers/buyapowa-constants';
import { userHasConsentedToAllOptionalCookieCategories } from 'src/cookies/consent-cookie';
import usePuzzelLiveChatFeatureFlag from 'src/custom-hooks/use-puzzel-live-chat-feature-flag';
import puzzel from 'src/helpers/puzzel';
import tawk from 'src/helpers/tawk';
import { Script } from 'gatsby';
import { getMobileBackendHostname } from 'src/helpers/request-helper';

const MobileBackend = () => {
  return (
    <Helmet>
      <link rel="dns-prefetch" href={getMobileBackendHostname()} />
    </Helmet>
  );
};

/**
 * The initialisation script for Amplitude is defined elsewhere.
 * @see onClientEntry
 */
const Amplitude = () => {
  return (
    <>
      <Helmet>
        <link rel="dns-prefetch" href="https://sr-client-cfg.eu.amplitude.com" />
        <link rel="preconnect" href="https://cdn.eu.amplitude.com" />
      </Helmet>
      {process.env.GATSBY_AMPLITUDE_API_KEY && (
        <Script
          id="amplitude-experimental-inline-script"
          type="text/javascript"
          strategy="post-hydrate"
        >
          {`(function(w,d,s,i){var f=d.getElementsByTagName(s)[0],` +
            `j=d.createElement(s);j.async=true;j.src=` +
            `'https://cdn.eu.amplitude.com/script/'+i+'.experiment.js';f.parentNode.insertBefore(j,f);` +
            `})(window,document,'script','${process.env.GATSBY_AMPLITUDE_API_KEY}');`}
        </Script>
      )}
    </>
  );
};

/**
 * New Relic is controlled by the gatsby-plugin-newrelic package.
 */
const NewRelic = () => {
  return (
    <Helmet>
      <link rel="dns-prefetch" href="https://bam.nr-data.net" />
      <link rel="dns-prefetch" href="https://js-agent.newrelic.com" />
    </Helmet>
  );
};

export const TagManager = () => {
  const { buildType } = useContext(SiteContext);
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://www.googletagmanager.com" />
        <link rel="dns-prefetch" href="https://cc.cdn.civiccomputing.com" />
        <link rel="dns-prefetch" href="https://apikeys.civiccomputing.com" />
        <link rel="dns-prefetch" href="https://secure.quantserve.com" />
      </Helmet>
      <Script id="google-tag-manager-inline-script" type="text/javascript" strategy="idle">
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':` +
          `new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],` +
          `j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=` +
          `'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);` +
          `})(window,document,'script','dataLayer','${getGtmId(buildType)}');`}
      </Script>
    </>
  );
};

const useChat = (chatEnabled: boolean): void => {
  const { buildType, siteId } = useContext(SiteContext);
  const usePuzzelForLiveChat = usePuzzelLiveChatFeatureFlag();
  const [puzzelFeatureFlagHasLoaded, setPuzzelFeatureFlagHasLoaded] = useState(false);

  useEffect(() => {
    const canUseChat = chatEnabled && userHasConsentedToAllOptionalCookieCategories(buildType);
    setPuzzelFeatureFlagHasLoaded(usePuzzelForLiveChat !== null);
    if (canUseChat && puzzelFeatureFlagHasLoaded) {
      if (usePuzzelForLiveChat) {
        puzzel.init();
      } else {
        tawk.init(buildType, siteId);
        tawk.showWidget();
      }
    }
  }, [buildType, chatEnabled, puzzelFeatureFlagHasLoaded, siteId, usePuzzelForLiveChat]);
};

export const optimizelyScripts: Record<BuildType, string> = {
  [BuildType.DAVID_LLOYD_UK]: '7599971281.js',
  [BuildType.DAVID_LLOYD_BELGIUM]: '20085914942.js',
  [BuildType.DAVID_LLOYD_GERMANY]: '20009436761.js',
  [BuildType.DAVID_LLOYD_SPAIN]: '20050508125.js',
  [BuildType.DAVID_LLOYD_FRANCE]: '20068387149.js',
  [BuildType.DAVID_LLOYD_IRELAND]: '20066835302.js',
  [BuildType.DAVID_LLOYD_ITALY]: '20081168412.js',
  [BuildType.DAVID_LLOYD_NETHERLANDS]: '7893132344.js',
  [BuildType.DAVID_LLOYD_SWITZERLAND]: '20503167439.js',
  [BuildType.BLAZE_STUDIO]: '7599971281.js',
  [BuildType.HARBOUR]: '8194219373.js',
  [BuildType.MERIDIAN]: '20497815112.js',
};

const Optimizely = ({ buildType }: { buildType: BuildType }) => {
  const scriptName = optimizelyScripts[buildType];
  return (
    <>
      <Helmet>
        <link rel="dns-prefetch" href="https://cdn.optimizely.com" />
        <link rel="dns-prefetch" href="https://cdn2.optimizely.com" />
        <link rel="dns-prefetch" href="https://cdn3.optimizely.com" />
      </Helmet>
      <Script
        type="text/javascript"
        src={`https://cdn.optimizely.com/js/${scriptName}`}
        strategy="idle"
      />
    </>
  );
};

const Buyapowa = () => (
  <>
    <Helmet>
      <link rel="preconnect" href={buyapowaLibrarySource} />
    </Helmet>
    <Script src={buyapowaLibrarySource} />
  </>
);

const facebookDomainVerificationCode: Partial<Record<BuildType, string>> = {
  [BuildType.DAVID_LLOYD_GERMANY]: 'ta44ar1lwu1zj0j8uujd8dk16o3uhf',
  [BuildType.DAVID_LLOYD_SWITZERLAND]: 'sl3dc6a21us7cetnp1fko04a6dy8o9',
  [BuildType.MERIDIAN]: 'g3y960yd1mbcraa1si3hkppjkv3kab',
};

const FacebookDomainVerification = ({ buildType }: { buildType: BuildType }) => {
  return (
    <Helmet>
      <meta
        name="facebook-domain-verification"
        content={facebookDomainVerificationCode[buildType]}
      />
    </Helmet>
  );
};

export type SnippetsProps = {
  buildType: BuildType;
  chatEnabled?: boolean;
  addBuyapowaLibrary?: boolean;
  disableAnalyticsCookies?: boolean;
};

export const Snippets: React.FC<SnippetsProps> = ({
  buildType,
  chatEnabled,
  addBuyapowaLibrary,
  disableAnalyticsCookies,
}: SnippetsProps) => {
  useChat(chatEnabled);
  return (
    <>
      <MobileBackend />
      <Amplitude />
      <NewRelic />
      {!disableAnalyticsCookies && <TagManager />}
      <Optimizely buildType={buildType} />
      {addBuyapowaLibrary && <Buyapowa />}
      {facebookDomainVerificationCode[buildType] && (
        <FacebookDomainVerification buildType={buildType} />
      )}
    </>
  );
};

export default Snippets;
