export const staticTextLookup = {
  FAQs: "Preguntas frecuentes",
  aCopyOfThisGuaranteeShouldBeRetained: "El cliente debe conservar una copia de la garantía. Se enviará una confirmación por correo electrónico ",
  about: "Acerca del club",
  aboutYou: "Sobre ti",
  accessToClubsAndSpaRetreats: "Platinum membership will also give you access to other David Lloyd clubs. See below for details of the clubs you'll be able to access.\nPlus, if the club has a Spa Retreat, this will also include access to the Spa Retreat facilities. Click here for a list of clubs with Spa Retreat.",
  accessToClubsNearYou: "Tu acceso a los clubes cercanos:",
  accessToOtherClubs: "Acceso a otros Clubs",
  accountHoldersName: "Nombre del titular de la cuenta",
  accountNumber: "Número de Cuenta",
  activating: "Activando",
  addPartnerAmount: "Agregar tu pareja %amount%",
  addSelectedMembership: "Agregar la membresía seleccionada",
  addSelectedPromotion: "Añadir promoción seleccionada",
  addToCalendar: "Añadir Al Calendario",
  addYourAssociates: "Añade a tu pareja e hijos",
  addYourChildren: "Agrega a tus hijos",
  addYourPartner: "Agrega a tu pareja",
  addYourPromotions: "Selecciona tu oferta",
  additionalDetailsSecondErrorMessage: "Lo sentimos, no podemos tomar tus datos en estos momentos, los podrás facilitar en tu primera visita al club.",
  additionalMembers: "Socios adicionales",
  additionalMemberships: "Membresías adicionales",
  address: "Dirección",
  addressLine1: "Dirección línea 1",
  addressLine2: "Dirección línea 2",
  addressLinePlaceholder: "Introduce tu dirección",
  adultMembers: "Socios adultos",
  adults: "Adultos",
  afterSchool: "Después del cole",
  allAgesWelcome: "Todos están bienvenidos",
  allFacilities: "Nuestras instalaciones",
  alsoAtClub: "También en %clubName%",
  amountSubjectToPendingPriceRise: "La cantidad indicada está sujeta a cualquier aumento de precio pendiente. Consulta con el equipo del club para obtener más información.",
  and: "y",
  annualOnJoiningTheClubPayment: "Al unirte al club, se te pedirá que realice un pago que incluye una cuota de inscripción, un pago prorrateado para el resto de este mes (si corresponde) y un pago que cubre el costo de la membresía durante 12 meses naturales. Si deseas mejorar tu experiencia de membresía, puedes agregar productos adicionales, como entrenamiento personal o entrenamientos, que también se pueden pagar hoy.",
  annually: "anualmente",
  anythingElseYoudLikeUsToKnow: "¿Hay algo más que deberíamos saber?",
  anytimeClubAccess: "Accceso ilimitado al club",
  app: "App",
  appQrCodeInstructions: "Simplemente alinea la cámara de tu teléfono sobre el código y, una vez que lo reconozca, pulsa el enlace para descargar la aplicación %brandName% .",
  appUpdateRequired: "Actualización de la aplicación requerida",
  areYouSureYouDoNotWantToBeContacted: "¿Estás seguro que no quieres que te contactemos por email o SMS?",
  associatesWereReset: "Lamentablemente este paquete no cubre a todos los socios adicionales que has seleccionado. Escoge otro paquete si deseas incluir a esos socios.",
  atClub: "En %clubName%",
  atHomeDigitalContent: "Contenido digital en casa, incluyendo clases en vivo y en diferido, seminarios educativos y consejos para mejorar tu salud y bienestar.",
  atUnit: "En %unitName%",
  availablePromotions: "promociones disponibles",
  availableSlot: "%numberOfSlots% cita disponible",
  availableSlots: "%numberOfSlots% citas disponibles",
  awaitingPayment: "En espera de pago",
  back: "atrás",
  backToTop: "Volver al inicio",
  beforeYouBecomeAMember: "Antes de hacerte socio, deja que te mostremos cómo aprovechar al máximo este club",
  bestValue: "Mejor Valor",
  bookAClass: "Reserva una clase",
  bookAClubWelcome: "Reserva una bienvenida al club",
  bookAClubWelcomeError: "Algo ha ido mal. Por favor, contacta con nosotros y te ayudarremos a reservar tu cita.",
  bookATour: "Programa tu visita",
  bookATourError: "Algo ha ido mal. Por favor, contacta con nosotros y te ayudarremos a reservar tu cita.",
  bookATourHeader: "Reserva una visita",
  bookATourSubtext: "Elige tu club más cercano",
  bookOnTheMove: "Ven a vernos ya",
  bookYourClubWelcome: "Reserva tu bienvenida al club",
  bookYourClubWelcomeAt: "Reserva tu bienvenida en %clubName%",
  bookYourTour: "Reserva tu visita",
  bookYourTourOf: "Reserva tu visita en %clubName%",
  booked: "Reservado",
  bookingYourClubWelcome: "Reservando tu bienvenida al club...",
  bookingYourTour: "Reserva un tour",
  buyapowaWarningPopupCancelButtonText: "Selecciona otro club",
  buyapowaWarningPopupConfirmButtonText: "Únete a %clubName%",
  buyapowaWarningPopupHeader: "Tenga en cuenta",
  buyapowaWarningPopupText: "El club %clubName% no está inscrito actualmente en nuestro programa «Recomienda a un amigo», por lo que ni tú ni la persona que te recomendó serían elegibles para recibir ningún beneficio si continúas con este registro.",
  byClickingFinishYouAcceptDDConditions: "Al hacer click en ‘%buttonText%’ confirmas que eres el titular de la cuenta bancaria y que no es necesario que nadie más autorice los cargos en esta cuenta. Si es una cuenta que necesite la autorización de un tercero, sea personal o de empresa, por favor contáctanos directamente.",
  cancel: "Cancelar",
  cancelMyMembership: "Cancelar mi membresía",
  cantFindASlot: "¿No encuentras hora?",
  cardCollectionTime: "Recogida de la tarjeta / Hora del tour",
  careers: "Trabaja con nosotros",
  changeClub: "Cambia de Club",
  changePreferences: "Cambia tus preferencias",
  changeSelectedPackage: "Cambiar la membresía seleccionada",
  checkYourAccessToASpecificClub: "Compruebe su acceso a un Club específico",
  child2AndUnder: "Membresía infantil:\nHasta 2 años",
  child3To18: "Membresía infantil:\n3-18 años",
  childMembers: "Socios infantiles",
  children: "Niños",
  childrenOverview: "Resumen de los niños",
  chooseAClub: "Elige un club",
  chooseAClubToRegisterForClubTrial: "Selecciona tu club debajo",
  chooseHowYouPay: "Selecciona tu método de pago",
  chooseMembershipLength: "Escoge la duración de tu suscripción",
  chooseYourStartDate: "Escoge la fecha de inicio",
  clickHereIfYouAreNot: "Haga clic aquí si no es %firstName%",
  close: "Cerrar",
  closeDetails: "Cerrar los detalles",
  club: "Club",
  clubEmail: "Correo electrónico del club",
  clubFacilities: "Nuestras instalaciones",
  clubFinderSearchPlaceholder: "Introduce una ubicación",
  clubOpeningHours: "Nuestro horario de apertura",
  clubOpeningTimes: "Nuestro horario de apertura",
  clubOverview: "Vista del Club",
  clubTourTime: "Hora del tour",
  clubVideo: "Vídeo del Club",
  clubWelcomeMeetingTime: "Hora de la reunión de bienvenida del club",
  comingSoon: "Pronto",
  completeDirectDebitPopupText: "Ahorra tiempo en tu primera visita haciendo tu domiciliación ahora",
  completeForm: "Por favor, completa el siguiente formulario.",
  completeItInClub: "Completar domiciliación en el Club",
  completeItNow: "Completar domiciliación ahora",
  completeSEPAInClub: "Completar domiciliación en el Club",
  completeSEPANow: "Completar domiciliación ahora",
  completeSEPAPopupText: "Ahorra tiempo en tu primera visita haciendo tu domiciliación ahora",
  confirm: "Confirmar",
  confirmNoPaymentTaken: "Por favor, confirma que no se ha procesado ningún pago.",
  confirmPassword: "Confirmar contraseña",
  confirmPasswordPlaceholder: "Confirma tu nueva contraseña",
  confirmPaymentTaken: "Confirma que se ha procesado el pago",
  contact: "Contacto",
  contactInformation: "Datos de contacto",
  contactMeToArrange: "Llámame para cerrar el tour",
  contactNotPromotedMonthlyPaymentErrorSubtitle: "La configuración de tu membresía lleva un retraso. Vuelve a intentarlo, pero no te preocupes, no se te cobrará dos veces.",
  contactNotPromotedMonthlyPaymentErrorTitle: "Lo siento, ¡hoy no somos tan rápidos como tú!",
  contactPreferencesAfterPrivacyPolicy: ". Marca las casillas para que estemos en contacto.",
  contactPreferencesBeforePrivacyPolicy: "Nos encantaría poder informarte de todas nuestras novedades, promociones y nuevos servicios. Si tú también quieres saberlo, marca las siguientes casillas. Siempre podrás cambiar de opinión. Para conocer más detalles, visita",
  contactTheClubForOpeningTimes: "Para más información sobre nuestras actividades y horarios, contacta con el Club.",
  "continue": "Continúa",
  cookiePolicyDescription: "Nuestra web usa las cookies para mejorar la experiencia de usuario. Navegando por la web, aceptas el uso de las cookies.",
  cookiesPolicy: "Política de Cookies",
  copyright: "Todos los derechos reservados",
  coreStrengthAndHIIT: "Acondicionamiento Físico y de Fuerza, HIIT, Wellnees y bienestar, Yoga, Cycling",
  corporateFitness: "Aptitud corporativa",
  countryBelgium: "Bélgica",
  countryFrance: "Francia",
  countryGermany: "Alemania",
  countryIreland: "Irlanda",
  countryItaly: "Italia",
  countryNetherlands: "Países Bajos",
  countrySpain: "España",
  countrySwitzerland: "Suiza",
  countryUnitedKingdom: "Reino Unido",
  county: "Provincia",
  countyPlaceholder: "Introduce tu provincia",
  couple: "Pareja",
  creche: "Guarderia",
  creditorAddress: "Dirección",
  creditorId: "Identiﬁcador del acreedor",
  creditorName: "Nombre del acreedor",
  dateAtTime: "%date% a las %time% horas",
  dateOfBirth: "Fecha de nacimiento",
  days: "días",
  daytime: "Durante el día",
  detailsAddedToMembershipAccount: "Estos datos se han agregado a tu cuenta de socio.",
  diamondPlusRequired: "Se requiere una cuota Diamond Plus para acceder a algunos Spa Retreats",
  directDebitClubOpens: "El primer cargo se te hará cuando el club abra teniendo en cuenta las promociones que se apliquen en ese momento",
  directDebitClubReopens: "Tu domiciliación mensual comenzará cuando el club vuelva a abrir sus puertas y aplicará cualquier promoción de suscripción vigente",
  directDebitMandate: "Orden de débito directo",
  directDebitMembershipStarts: "Tu primer cargo se hará cuando empiece tu contrato, teniendo en cuenta las promociones que se apliquen en ese momento",
  discoverTheCreche: "Descubre nuestro servicio de guardería",
  done: "Hecho",
  downloadAppPrompt: "Para hacer reservas y gestionar tu membresía, descarga la aplicación David Lloyd Clubs.",
  downloadDescriptionMembershipVerification: "Descarga nuestra APP antes de tu visita para que podamos guiarte por el registro y ayudarte a aprovechar al máximo todo lo que el club pueda ofrecerte.",
  downloadDescriptionTennis: "Descarga la APP para consultar nuestros horarios, reservar clases o pistas de raquetas estés donde estés.",
  downloadDescriptionTrialConfirmation: "Descarga nuestra APP antes de tu visita para que podamos guiarte por el registro y ayudarte a aprovechar al máximo nuestras instalaciones.",
  downloadTheMobileAppHere: "Descarga nuestra APP aquí:",
  dr: "Dr.",
  duration: "Duración",
  edit: "Cambia",
  email: "Correo Electronico",
  emailAddress: "Email",
  emailAddressesMustBeUnique: "Introduzca una dirección de correo electrónico única. Debe ser diferente de la dirección de correo electrónico proporcionada por el miembro principal\n",
  endsIn: "Termina el",
  enjoyTimeTogetherAt: "Disfruta del tiempo juntos en %clubPrefix% %clubName%",
  enquireAboutMembership: "Pregunta sobre nuestras cuotas",
  enquireNow: "Solicita información ya",
  enquireText: "Cuéntanos tu consulta",
  enterALocation: "Ingrese una ubicación...",
  enterValidEmailAddress: "Por favor, introduce una dirección de correo electrónico válida",
  enterValidMobilePhoneNumber: "Por favor, introduce un número de teléfono móvil válido",
  enterValidName: "Por favor, introduce un nombre válido.",
  enterValidPhoneNumber: "Introduce un número de teléfono válido",
  enterValidPostCode: "Introduce un código postal válido",
  enterValidTaxId: "Por favor ingrese una identificación fiscal válida",
  equipmentAt: "Máquinas fitness en %clubName%",
  exerciseClasses: "Conoce nuestras clases",
  exerciseClassesAt: "Clases colectivas en %clubName%",
  experienceAllTheBenefits: "Conoce todos los beneficios de nuestras cuotas",
  expertPersonalTrainers: "Entrenadores personales cualificados",
  expertTuition: "Consejos de nuestros expertos",
  exploreClub: "Descubre %clubName%",
  exploreKidsActivities: "Conoce nuestras actividades Kids",
  failedToFetchExistingPayment: "Ya se ha efectuado un pago, pero se ha producido un error al confirmar los datos",
  familyAmpersandKids: "Familia y niños",
  familyAndKids: "Familia y niños",
  familySwim: "Piscina familiar",
  female: "Femenino",
  findAClub: "Buscar un club",
  findOutMore: "Descubre más",
  findYourClub: "Encuentra tu Club",
  firstFullMonth: "Primer mes completo",
  firstMonthFees: "Como no podemos configurar tu domiciliación bancaria a tiempo para tu primer mes completo, este pago se realiza por adelantado",
  firstName: "Nombre",
  fitnessOverview: "Resumen del estado físico",
  fixedTermFee: "Membresía de doce meses a partir del %month%",
  fixedTermFeeTooltip: "Pago que cubre el costo de la membresía durante 12 meses calendario",
  flexible: "flexible",
  followUs: "Síguenos",
  followedByMonthly: "Seguido del pago mensual de",
  followedByMonthlyPaymentsTitle: "Seguido de pagos mensuales",
  free: "Gratis",
  frequentlyAskedQuestions: "Preguntas Más Frecuentes",
  friend: "Amigo",
  friendsAndFamilyReferralAlreadyUsed: "Lo sentimos, este enlace ya se ha utilizado. Contacta con la persona que te recomendó para que lo vuelva a hacer.",
  friendsAndFamilyReferralExpired: "Lo sentimos, el link ha expirado. Por favor, contacta a la persona que te ha recomendado para que lo vuelva a hacer.",
  friendsAndFamilyReferralNotFound: "Lo sentimos pero no hemos encontrado este enlace. Por favor, contacta con la persona que te recomendó para que lo vuelva a hacer.",
  from: "Del",
  from3MonthsPlus: "Desde los 3 meses",
  from3To18Years: "Entre 3 y 18 años",
  gender: "Género",
  germanAndFallbackOnly: "Test",
  getInTouch: "Ponte en contacto con nosotros",
  getStarted: "Empezar",
  getYourQuoteContactPreferencesAfterPrivacyPolicy: "para obtener todos los detalles. Marca las casillas para que estemos en contacto.",
  getYourQuoteContactPreferencesBeforePrivacyPolicy: "Nos encantaría poder informarte de todas nuestras novedades, promociones y nuevos servicios. Si tu también quieres saberlo, marca las siguientes casillas. Siemore podrás cambiar de opinión. Para conocer más detalles, visita",
  groupExerciseClasses: "Actividades dirigidas",
  gym: "Gimnasio",
  havingProblemsSavingMonthlyPaymentDetails: "Estamos teniendo problemas para guardar los detalles de tu pago mensual",
  healthyBreakfast: "Desayunos saludables",
  heatedSwimmingPools: "Piscinas climatizadas",
  highlightsAtClub: "Destacado en %clubPrefix% %clubName%*",
  hours: "Horas",
  howCanWeContactYou: "¿Cómo podemos contactarte?",
  iAgree: "Estoy de acuerdo",
  ifSomethingWereToChangeInYourLifestyle: "Si algo cambiara en tu estilo de vida, %brandName% tiene un período de rescisión de 14 días. Habla con un miembro del equipo para obtener más información.",
  ifSomethingWereToChangeRightToCancel: "Si algo cambiara en tu estilo de vida, tienes derecho a cancelarlo. Habla con un miembro del personal para obtener más información.",
  imageDisclaimer: "*Cláusula de responsabilidad: las imágenes son solo ilustrativas y pueden no pertenecer al club que has seleccionado.",
  importantInformation: "Información importante",
  includedInPromotion: "Incluido en la promoción",
  individual: "Individual",
  initialEnquiryKeepYouUpdated: "Tras su consulta inicial, nos encantaría informarle sobre nuestros productos, servicios y ofertas más recientes. Si está interesado, simplemente haga clic en las casillas de abajo.",
  initialMonthTerm: "Plazo inicial de %monthDuration% meses calendario",
  isChildPresentText: "Mi hijo está conmigo en el Club hoy",
  isPartnerPresentText: "¿Ha venido hoy tu pareja contigo al club?",
  join: "Apúntate",
  joinCorporate: "Únete a Corporate",
  joinDifferentClub: "Apuntarse a otro Club",
  joinFriendsAndFamily: "Únete a amigos y familia",
  joinOurReferralScheme: "Parece que estás intentando unirte a nuestro programa de referidos...",
  joinTodayAndSave: "Inscríbete hoy y ahórrate",
  joiningFee: "Coste de inscripción",
  joiningFeeFirstMonth: "incluido el coste de inscripción y el primer mes",
  joiningFeeProRataFirstMonth: "Cuota de inscripción, resto del primer mes y primer mes completo",
  joiningFeeTooltip: "Pago para pertenecer al club",
  joiningFeeTwelveMonths: "Cuota de inscripción, resto del mes y membresía de 12 meses calendario",
  joiningFees: "Costes de inscripción",
  joiningFeesApply: "Se aplican costes de inscripción",
  joiningFeesWaived: "OFERTA ESPECIAL: Ahorro de cuota de inscripción ahora disponible",
  joiningTheClubAsAFamily: "Ven con toda tu familia",
  justMe: "Solo yo",
  keepAnEyeOutForFutureTrials: "Visita nuestra web para conocer las próximas fechas de prueba, o déjanos tus datos en el formulario y alguien de nuestro equipo te pondrá al día con todas las novedades.",
  keepInTouch: "Queremos estar en contacto contigo",
  keepYouUpdated: "Nos encantaría poder informarte de todas nuestras novedades, promociones y nuevos servicios. Si tu también quieres saberlo, marca la siguiente casilla.",
  kickstartTitleWithName: "%firstName%, ¿cómo te describirías?",
  kickstartTitleWithoutName: "¿Cómo te describirías?",
  kidsActivities: "Actividades para niños",
  kidsBuildYourOwnPizza: "Los peques creando su propia pizza",
  kidsClub: "Kids Club",
  lastName: "Apellidos",
  latestNews: "Últimas noticias",
  learnMore: "No pares de aprender",
  learnSomethingNew: "Aprende algo nuevo",
  letThemEnjoyTheClub: "Déjales disfrutar del Club mientras tú disfrutas de tu tiempo.",
  letUsKnowWhatYouAreInterestedIn: "Dinos qué es lo que te interesa, para que podamos adaptar la visita a tus preferencias",
  letUsKnowWhatYouAreLookingForwardTo: "Explícanos cuáles son las áreas del club que más te interesan",
  likeOurPage: "Danos un like",
  linkIsInvalid: "Lo sentimos, este enlace no es válido",
  loadingClubWelcomeTimes: "Cargando el horario de las bienvenidas...",
  loadingTourTimes: "Cargando horarios de la visita…",
  logOut: "Cerrar sesión",
  login: "Iniciar sesión",
  male: "Masculino",
  master: "Maestro",
  meAndMyFamily: "Yo y mi familia",
  meAndMyPartner: "Yo y mi pareja",
  membersLogin: "Acceso Socios",
  membershipDescription: "Que encontrarás en tu correo electrónico de bienvenida o en tu tarjeta de socio",
  membershipDiscountUntil: "Hasta la fecha %date%",
  membershipEnquiryDetails: "El usuario seleccionó: %duration%%packageKey%%packageTypes%",
  membershipFees: "Cuotas de suscripción",
  membershipFeesUpUntil: "Cuotas de membresía hasta el %date%",
  membershipNumber: "Número de socio",
  membershipPlaceholder: "Introduce tu número de socio/a",
  membershipPriceChangedError: "Hemos registrado un pago de membresía con un monto diferente. Si deseas cambiar los detalles de la cuota, inicia una nueva sesión de inscripción",
  membershipSaving: "Ahorro para socios",
  membershipStartClubConfirm: "Tu club confirmará cuándo empieza tu membresía",
  membershipStartClubOpens: "Tu membresía empezará cuando el club abra",
  membershipTermsAndConditions: "Términos y condiciones de membresía",
  membershipTrial: "Prueba nuestras instalaciones",
  memberships: "Membresías",
  min: "Min",
  minutes: "Minutos",
  miss: "Srta.",
  monthlyDirectDebitsStartOn: "Pagos de adeudo directo mensual a partir del",
  monthlyPaymentsDuringOfferPeriodTitle: "Pagos mensuales durante el período de oferta especial",
  monthlySavings: "Ahorro mensual",
  months: "meses",
  more: "Más",
  moreDetailsSkipDirectDebit: "Deseo hacer mi domiciliación con el Club",
  moreDetailsSkipSEPA: "Deseo hacer mi domiciliación con el Club",
  mostPopular: "Más popular",
  mostRecent: "Más reciente",
  mr: "Sr.",
  mrs: "Sra.",
  ms: "Doña",
  mx: "D.",
  myMembership: "Mi suscripción",
  nearlyThere: "Cerca de ti",
  next: "Siguiente",
  noAdditionalMembershipsAvailable: "No hay opciones de membresía adicionales disponibles actualmente.",
  noAvailablePromotions: "No hay promociones disponibles en este momento.",
  noClubWelcomeSlotsError: "Lo sentimos, en estos momento no tenemos horas online dispobiles para tu bienvenida al club. Por favor, contacta con nosotros y encontraremos una hora",
  noClubsInClubFinder: "No hay clubes en esta área, sin embargo, los clubes más cercanos se enumeran a continuación.",
  noJoiningFee: "Sin cuota de inscripción",
  noJoiningFeeOffer: "Oferta especial: Sin coste de inscripción",
  noMembershipFeeForMonthOffer: "Oferta adicional: Inscripción gratis durante %month%",
  noMembershipFeeUntilMonthOffer: "Oferta adicional: Inscripción gratis hasta %month%",
  noPreferencePackageKey: "no hay preferencia por el tipo de paquete de membresía; ",
  noPreferencePackageTypes: "no hay preferencia por el tipo de acceso de membresía.",
  noTourSlotsError: "Desafortunadamente, no tenemos horarios de recorridos disponibles en línea en este momento. Ponte en contacto y te encontraremos un momento",
  normally: "Normalmente",
  notAllMembershipsAvailable: "No están disponibles todas las suscripciones",
  notSureWhichClubsNearest: "Los Clubs más cercanos",
  now: "Ahora",
  onDate: "en %date%",
  onFacebook: "%clubName% en Facebook",
  onInstagram: "%clubName% en Instagram",
  onJoiningTheClubPayment: "Al unirte al club, se te pedirá que realices un pago inicial que incluye una cuota de inscripción y un pago prorrateado (este es tu pago de hoy). A esto le sigue una suscripción mensual que se cobrará mediante domiciliación bancaria. Si deseas mejorar tu experiencia de membresía, puede agregar productos adicionales, como entrenamiento personal o entrenamientos, que también se pueden pagar hoy.",
  oneMonth: "Un mes",
  onlyAvailableForALimitedTime: "Solo disponible por tiempo limitado",
  openingHours: "Horario de apertura",
  optInToSpecialOffer: "Añádelo para suscribirte a una oferta especial",
  optOutAtAnyTimeAfterPrivacyPolicy: "para más detalles!",
  optOutAtAnyTimeBeforePrivacyPolicy: "Puedes darte de baja en cualquier momento. Mira nuestro",
  options: "Opciones",
  organisation: "Organización",
  organisationOther: "Otro",
  other: "Otro",
  ourEquipmentAt: "El equipo fitness en %clubName%",
  ourFriendlyTeamAreLookingForwardToMeetingYou: "Nuestro fabuloso equipo quiere conocerte",
  outdoorPoolTimesWeatherDependent: "Piscina exterior climatizada",
  over14EmailAddress: "Los socios mayores de 14 años pueden utilizar las instalaciones del club. Introduce su dirección de correo electrónico para inscribirlos.",
  over14PhoneNumber: "Los socios mayores de 14 años pueden utilizar la aplicación móvil %brandName%, introduzca su número de móvil.",
  "package": "Paquete",
  packageSelected: "%packageName% seleccionado",
  padel: "Pádel",
  paidOnline: "Pagado en línea",
  partner: "Socio secundario",
  partnerEmail: "A tu pareja se le enviará un correo electrónico confirmando los detalles de su membresía y para completar su configuración. Ingresa su número de teléfono móvil y le enviaremos un enlace para registrarse en la aplicación móvil de %brandName%.",
  partnerWasReset: "Lamentablemente este paquete no tiene una opción para parejas. Considere actualizar su paquete para poder incluir a su pareja.",
  partnersAndSponsors: "Partners & Sponsors",
  password: "Contraseña",
  passwordPlaceholder: "Confirma tu nueva contraseña",
  payAnnually: "Paga anualmente",
  payAtReception: "Paga en recepción",
  payMonthly: "Paga mensualmente / Paga mes a mes",
  payOnline: "Paga en línea",
  payingAnnually: "Pagar anualmente es más barato que pagar mensualmente",
  paymentAlreadyConfirmed: "El pago ha sido confirmado, puedes ir a la página de pago mensual",
  paymentDetails: "Datos de pago",
  paymentLinkExpired: "El enlace de pago ha caducado",
  paymentReceived: "Pago recibido",
  paymentRefused: "Pago rechazado",
  penneArrabbiata: "Penne arrabbiata",
  perMonth: "al mes",
  personalInformation: "Información personal",
  phoneNumber: "Teléfono",
  pickAnotherTime: "Elige otra hora",
  pleaseCheckAndTryAgain: "Compruébelo y vuelva a probar",
  pleaseCheckYourCardDetails: "Por favor, comprueba los detalles de tu tarjeta",
  pleaseCheckYourDetailsAndTryAgain: "Comprueba tus datos e inténtalo de nuevo. Si el problema persiste, visítanos en el club y nuestro equipo estará encantado de ayudarte.",
  pleaseCheckYourEmail: "Por favor, comprueba tu correo electrónico",
  pleaseClickHereTo: "Haz un click aquí",
  pleaseContactOnlineJoining: "Si necesitas ayuda, contacto con %email%",
  pleaseDoubleCheckAndTryAgain: "Comprueba tus datos e inténtalo de nuevo",
  pleaseEnterANumber: "Introduce un número",
  pleaseMakePaymentAtReception: "Realiza el pago en la recepción",
  pleasePickAnotherTime: "Por favor, elige otro momento.",
  pleaseRefreshOrTryAgainLater: "Actualice la página o vuelva a intentarlo más tarde",
  pleaseSelectATime: "Por favor, escoge la hora",
  pleaseTryAgainLater: "Inténtalo más tarde",
  pleaseUseAnAlternativeBrowser: "Utiliza un navegador alternativo como una versión actualizada de Microsoft Edge, Google Chrome o Safari",
  pleaseVerifyCaptcha: "Por favor, verifica el captcha para continuar",
  policies: "Políticas",
  policiesAndDocuments: "Políticas y documentos",
  poolOpeningHours: "Horarios de apertura de nuestras piscinas",
  poolOpeningTimes: "Horarios de apertura de nuestras piscinas",
  poolTimes: "Momentos en el agua",
  pools: "Piscinas",
  poolsAreClosed: "Todas nuestras piscinas están actualmente cerradas",
  postCode: "Código postal",
  postCodePlaceholder: "Introduce tu código postal",
  preferNotToSay: "Prefiero no decirlo",
  press: "Prensa",
  pressCentre: "Centro de prensa",
  previous: "anterior",
  priceDuringPromotionalPeriod: "precio durante el período de promoción",
  pricePerAdult: "%adultPrice% por adulto",
  pricePerChild: "%childPrice% por niño",
  primaryMember: "Socio primario",
  privacyPolicy: "Política de privacidad",
  promotionSelected: "Promoción seleccionada",
  promotionalItems: "Artículos promocionales",
  promotions: "Promociones",
  qrCodeExpired: "Código QR caducado: pague en recepción",
  quickRegistration: "Registro Rápido",
  referral: "Referido",
  refundedIfYouJoinOnAStandardMembership: "Pago de matrícula obligatorio si no te apuntas con una cuota estandar",
  regionUnitedKingdomEast: "Este",
  regionUnitedKingdomGreaterLondon: "Londres",
  regionUnitedKingdomMidlands: "La región central de Inglaterra",
  regionUnitedKingdomNorthEast: "Noreste",
  regionUnitedKingdomNorthWest: "Noroeste",
  regionUnitedKingdomNorthernIreland: "Irlanda del Norte",
  regionUnitedKingdomScotland: "Escocia",
  regionUnitedKingdomSouth: "Sur",
  regionUnitedKingdomSouthEast: "Sudeste",
  regionUnitedKingdomSouthWest: "Sudoeste",
  regionUnitedKingdomWales: "Gales",
  registerForClubTrial: "Comienza tus 14 días de prueba hoy",
  registerForClubTrialButton: "Comienza tu prueba",
  registeredCompanyNumber: "CIF",
  registrationErrorMessage: "Compruebe que sus datos sean los correctos. Si tiene problemas registrando una cuenta, escríbanos a <a style=\"color: %emailColor%;\" href=\"mailto:%email%\">%email%</a> y alguien se pondrá en contacto con usted lo antes posible.",
  registrationUnauthorised: "La autorización ha fallado",
  relaxAmpersandSocialise: "Relax y vida social",
  relaxAndEnjoySpaRetreats: "Relájate y disfruta del Spa Retreats",
  relaxAndEnjoyTheSpaRetreatsAt: "Relájate y disfruta del Spa",
  relaxAndSocialise: "Relax y vida social",
  remainderOfFirstMonth: "Resto del primer mes",
  remainderOfFirstMonthAnd12MonthsMembership: "Resto del primer mes y 12 meses calendario",
  remainderOfFirstMonthAndFirstFullMonth: "Resto del primer mes y primer mes completo",
  remainderOfThisMonth: "Lo que queda de este mes",
  remainderOfThisMonthTooltip: "Pago que cubre el costo de la membresía para el resto de este mes",
  requestingAClubWelcome: "Solicitando una bienvenida al club",
  retry: "Procesar de nuevo",
  saveAmount: "Ahorra %amount%",
  savingUpTo: "Un ahorro de hasta %price%*",
  scanQrCodeToDownload: "Escanea el código QR para descargar la aplicación",
  scanQrCodeToPay: "Escanea el código QR para ir a la página de pago",
  seeAllFacilities: "Descubre todas las instalaciones",
  seeDetails: "Conoce los detalles",
  seeMore: "Conoce más…",
  selectADate: "Elige una fecha",
  selectAnOrganisation: "Selecciona una empresa o grupo...",
  selectOptionsBelow: "Elige entre todas las opciones para encontrar actividades de familia",
  selectPackage: "Selecciona %packageName%",
  selectPromotion: "Selecciona promoción",
  selectTitle: "Seleccionar un tratamiento",
  selectedDuration: "una duración del paquete de %durationType%; ",
  selectedPackageKey: "una membresía de %packageKey%; ",
  selectedPackageTypes: "acceso de membresía que incluye %packageTypes%.",
  selectedTourTimeNoLongerAvailable: "Lamentablemente, la hora del recorrido que seleccionó ya no está disponible.",
  selectedTourTimeNoLongerAvailableHeader: "El tiempo del recorrido no está disponible",
  sendQuote: "Recibe tu oferta",
  sepaGuaranteeLine1: "– Mediante la ﬁrma de este formulario, usted autoriza a (A) %companyName% a enviar instrucciones a su entidad ﬁnanciera para adeudar en su cuenta y (B) a la entidad del deudor para efectuar los adeudos en su cuenta siguiendo las instrucciones de %companyName%.",
  sepaGuaranteeLine2: "– Usted tiene derecho a que su entidad le efectúe un reembolso de acuerdo con los términos y condiciones del contrato suscrito con su banco. \nLa solicitud de reembolso deberá efectuarse en un plazo de 8 semanas a partir de la fecha en la cual se realizó el adeudo en cuenta.",
  sepaGuaranteeLine3: "– Puede obtener información adicional sobre sus derechos en su entidad ﬁnanciera.",
  sepaGuaranteeLine4: "– Todos los campos deben cumplimentarse obligatoriamente.",
  sepaGuaranteeLine5: "Tipo de pago: Pago recurrente (puede der usado para enviar varios adeudos)",
  sepaTitle: "Adeudos Domiciliados Europeos SEPA",
  settingUpYourDirectDebit: "Configuración de tu domiciliación bancaria",
  settingUpYourMonthlyPayment: "Configura tu pago mensual",
  shareOnFacebook: "Comparte en Facebook",
  shareOnTwitter: "Comparte en Twitter",
  signMeUp: "Inscríbeme",
  signUpForAGradeCoaching: "Contrata el mejor entrenamiento personal",
  skip: "Omitir",
  skipPayment: "Omitir el pago, me gustaría pagar en recepción",
  slotsTakingAWhileToLoad: "Vamos a tardar un poco en buscar una hora disponible. Puedes ponerte en contacto directamente con nosotros siguiendo los detalles de abajo.",
  snrgyFamilyFitness: "Synrgy family fitness",
  socialMedia: "Redes sociales",
  softPlay: "Zona de juegos infantil interior",
  somethingHasGoneWrong: "Algo ha ido mal",
  sorryIeNotSupported: "Lo sentimos, Internet Explorer no es compatible",
  sorrySomethingHasGoneWrongPleaseTryAgain: "Algo ha ido mal. Inténtalo de nuevo.",
  sorrySomethingWentWrong: "Disculpa, algo ha ido mal.",
  sorryThereWasAProblemWithYourPayment: "Ha habido un problema con el pago.",
  sorryWereHavingTechnicalProblems: "Disculpa, estamos teniendo problemas técnicos.",
  sortBy: "Ordenar por:",
  sortByMyLocation: "Ordenar por mi ubicación",
  sortCode: "Código de clasificación",
  spa: "SPA",
  specialHours: "Horarios especiales",
  specialOffer: "Oferta especial",
  specialsAndEvents: "Especiales y eventos",
  sportsOverview: "Resumen deportivo",
  standard: "Estándar",
  startDate: "Fecha de inicio",
  startInNextWeek: "Empiece su suscripción cualquier día de la semana que viene",
  startOnFixedDate: "Puedes iniciar tu membresía en",
  stateOfTheArtGymFacilities: "Instalaciones fitness de última y novedosa tecnología",
  studioOpeningHours: "Horario de apertura del Studio",
  studioOverview: "Descripción del Studio",
  submitAndStartMembership: "Enviar y empezar a disfrutar de mi suscripción",
  swimmingAt: "Natación en %clubName%",
  swimmingCoaching: "Clases particulares de natación",
  swimmingPools: "Piscinas",
  tailorYourClubWelcome: "Personaliza tu bienvenida al club.",
  tailorYourTour: "Adapta tu tour",
  takeOurVideoTour: "Mira nuestro video para familiarizarte con la experiencia de %brandName%*",
  takeTheNextStep: "Da un paso más",
  takeTheNextStepDescription: "Descubre cómo unirte a %clubPrefix% %clubName% puede transformar tu tiempo junto a tu familia.",
  taxId: "Identificación Fiscal",
  telephone: "Teléfono",
  tennis: "Tenis",
  tennisClub: "Club de Tenis",
  tennisCoaching: "Clases particulares de tenis",
  termsAndConditions: "Términos y condiciones",
  textMessage: "Mensaje de texto",
  thankYouForSettingUpDirectDebit: "Gracias por crear su domiciliación con nosotros",
  thankYouForSubscribing: "¡Gracias por suscribirte!",
  thankYouWelcomeToBrand: "Gracias. ¡Bienvenido a %brandName%!",
  theClub: "El Club",
  thereAreCurrentlyNoMembershipPackages: "Lamentamos que no hayas tenido la oportunidad de apuntarte de forma anticipada. La opción estará disponible en otro momento. Si aún no te has registrado, has clic en el botón de arriba y nos aseguraremos de enviarte un recordatorio cuando abramos nuevamente una jornada para unirte.",
  threeMonths: "3 meses",
  timetable: "Horario actividades",
  title: "Tratamiento",
  today: "Hoy",
  tomorrow: "Mañana",
  totalDue: "Importe a pagar",
  totalDueToday: "Total adeudado",
  totalSavedJoiningToday: "Total de ahorro al inscribirse hoy",
  townOrCity: "Municipio/Ciudad",
  townOrCityPlaceholder: "Introduce tu municipio o ciudad",
  treatments: "Día de Spa Face & Body",
  treatmentsOverview: "Resumen de los tratamientos",
  unableToOfferTrial: "Disculpa, en este momento no podemos ofrecerte la posibilidad de probar el Club.",
  unableToTakeClubWelcomeBookings: "Lo sentimos, no podemos hacer reservas online en este momento - contacta con nosotros con los datos que encontrarás más abajo para reservar tu bienvenida al club",
  unableToTakeTourBookings: "Desgraciadamente, de momento no podemos realizar reservas online. Puedes contactar directamente con el Club para reservar tu visita.",
  unexpectedError: "Error inesperado",
  unfortunatelyAnErrorHasOccurred: "Ha ocurrido un error",
  unfortunatelyThisTimeIsNoLongerAvailable: "Lamentablemente la hora escogida ya no está disponible",
  unknownMembershipError: "Tu alta ha sido registrada con éxito. Por favor comprueba el email de confirmación. Si no lo recibes dentro de una hora, inténtalo de nuevo.",
  unsubscribe: "Cancelar suscripción",
  updateAppPrompt: "Actualiza la aplicación a la versión más reciente e inténtalo de nuevo.",
  upgradeYourMembershipToAddChildren: "Actualice su suscripción para añadir a un niño.",
  upgradeYourMembershipToAddPartner: "Actualice su suscripción para añadir a un socio",
  useMyLocation: "Usa mi localización",
  userAlreadyExists: "Este número de socio ya está siendo utilizado",
  userInputOrganisation: "¿Cuál es el nombre de tu empresa o grupo?",
  userVerificationFailed: "La verificación ha fallado",
  usualJoiningFees: "Costes de inscripción habituales",
  vatNumber: "NIF",
  viewAllClubs: "Ver todos los Clubs",
  viewInMaps: "Encuéntranos en el mapa",
  viewMap: "View a map of all David Lloyd Clubs",
  viewOurFoodMenu: "Consulta el menú de nuestro restaurante",
  viewTimetable: "Consulta los horarios de las actividades",
  visitSpaRetreats: "Visita el Spa Retreat",
  was: "Estaba",
  weAlreadyHaveYourDetails: "%firstName%, ya tenemos sus datos en el sistema.",
  weDontRecogniseThatLocation: "Lo sentimos, no reconocemos esa ubicación",
  weJustNeedAFewMoreDetails: "Solo necesitamos unos detalles más",
  weSendOurMostExcitingOffers: "Te haremos llegar nuestras mejores ofertas por email",
  weWereUnableToSaveDetails: "Lo sentimos, no hemos podido guardar tus datos",
  weekend: "Fin de semana",
  weeklyTimetable: "Horario semanal de las actividades",
  welcomeTo: "Bienvenido a",
  whatToExpect: "¿Qué puedes esperar?",
  whatsApp: "WhatsApp",
  whereToFindUs: "¿Dónde estamos?",
  whoCanCome: "¿Quién puede visitarnos?",
  whosComing: "¿Quién viene?",
  workEmailAddress: "Dirección de email del trabajo",
  years: "años",
  youngChildren: "Niños pequeños [se refiere a los niños del paquete de 0-2 años]",
  yourCardDetails: "Los datos de tu tarjeta",
  yourChild: "Su hija/ hijo (%range%)",
  yourChildren: "Su hijas/hijos (%range%)",
  yourClubWelcomeIsBooked: "%name%, hemos reservado tu bienvenida al club",
  yourDetails: "Tus datos",
  yourFirstFullMonth: "Tu primer mes completo (%month%)",
  yourFirstFullMonthTooltip: "Pago que cubre el costo de la membresía hasta que se haya establecido la domiciliación bancaria en tu banco",
  yourMembership: "Su cuota",
  yourMembershipTrial: "Tu periodo de prueba",
  yourNannies: "Tus canguros",
  yourNanny: "Tu canguro",
  yourOlderChild: "Tu hijo (niño) (%range%)",
  yourOlderChildren: "Tus hijos (niños) (%range%)",
  yourPartner: "Tu pareja",
  yourSavings: "Su ahorro",
  yourSavingsSubtitle: "Aquí puede ver lo que puede ahorrarse al inscribirse hoy",
  yourStartDate: "Tu fecha de inicio",
  yourTourIsBooked: "%name%, has reservado tu tour",
  yourYoungChild: "Tu hijo (bebé) (%range%)",
  yourYoungChildren: "Tus hijos (bebés) (%range%)"
};
