import React, { PropsWithChildren, useContext } from 'react';
import { SiteContext } from 'src/context/site-context';
import { flex } from 'src/styling/constants';
import SimpleNavBarWithSpacer from 'src/components/layout/simple-nav-bar-with-spacer';
import { navigateToExternalPage } from 'src/helpers/navigation-helper';
import { ExternalLink } from 'src/components/common/website-link';
import 'src/styling/global.css';
import Snippets from './snippets';
import FooterRedesign from 'src/components/layout/footer/redesign';
import Footer from 'src/components/layout/footer/footer';
import GlobalContentProvider from 'src/content/global-content-provider';
import { GlobalCmsContent } from 'src/content/global-content';

type SimplePageLayoutProps = PropsWithChildren<{
  shouldShowFooter: boolean;
}>;

export const SimplePageLayout: React.FC<SimplePageLayoutProps> = ({
  shouldShowFooter,
  children,
}) => {
  const { styling, buildType } = useContext(SiteContext);
  return (
    <GlobalContentProvider>
      <div
        css={[
          flex.columnCentered,
          {
            position: 'relative',
            maxHeight: shouldShowFooter ? undefined : '100vh',
          },
        ]}
      >
        <SimpleNavBarWithSpacer
          onCloseButtonClicked={() => navigateToExternalPage('/')}
          logoWrapper={<ExternalLink to={'/'} />}
        />
        <div css={{ backgroundColor: styling.colors.secondaryBackground, width: '100%' }}>
          {children}
        </div>
      </div>
      {shouldShowFooter && <SimplePageLayoutFooter />}
      <Snippets buildType={buildType} />
    </GlobalContentProvider>
  );
};

const SimplePageLayoutFooter = () => {
  const { newFooterContent } = useContext(GlobalCmsContent);
  return newFooterContent.useNewFooter ? <FooterRedesign /> : <Footer />;
};
