import { StaticTextLookupKey } from 'src/translators/static-text-lookup';

export enum Region {
  UNITED_KINGDOM_EAST = 'East',
  UNITED_KINGDOM_GREATER_LONDON = 'Greater London',
  UNITED_KINGDOM_MIDLANDS = 'Midlands',
  UNITED_KINGDOM_NORTH_EAST = 'North East',
  UNITED_KINGDOM_NORTH_WEST = 'North West',
  UNITED_KINGDOM_NOTHERN_IRELAND = 'Northern Ireland',
  UNITED_KINGDOM_SCOTLAND = 'Scotland',
  UNITED_KINGDOM_SOUTH = 'South',
  UNITED_KINGDOM_SOUTH_EAST = 'South East',
  UNITED_KINGDOM_SOUTH_WEST = 'South West',
  UNITED_KINGDOM_WALES = 'Wales',
}

export const getTranslationKeyForRegion = (region: Region): StaticTextLookupKey => {
  switch (region) {
    case Region.UNITED_KINGDOM_EAST:
      return 'regionUnitedKingdomEast';
    case Region.UNITED_KINGDOM_GREATER_LONDON:
      return 'regionUnitedKingdomGreaterLondon';
    case Region.UNITED_KINGDOM_MIDLANDS:
      return 'regionUnitedKingdomMidlands';
    case Region.UNITED_KINGDOM_NORTH_EAST:
      return 'regionUnitedKingdomNorthEast';
    case Region.UNITED_KINGDOM_NORTH_WEST:
      return 'regionUnitedKingdomNorthWest';
    case Region.UNITED_KINGDOM_NOTHERN_IRELAND:
      return 'regionUnitedKingdomNorthernIreland';
    case Region.UNITED_KINGDOM_SCOTLAND:
      return 'regionUnitedKingdomScotland';
    case Region.UNITED_KINGDOM_SOUTH:
      return 'regionUnitedKingdomSouth';
    case Region.UNITED_KINGDOM_SOUTH_EAST:
      return 'regionUnitedKingdomSouthEast';
    case Region.UNITED_KINGDOM_SOUTH_WEST:
      return 'regionUnitedKingdomSouthWest';
    case Region.UNITED_KINGDOM_WALES:
      return 'regionUnitedKingdomWales';
    default:
      throw new Error('Unsupported region');
  }
};
