import React from 'react';
import { IconProps } from './icon-types';

export const FlagCatalan = ({ size }: IconProps) => (
  <svg
    viewBox="0 0 126 126"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: size, height: size, borderRadius: size }}
  >
    <g>
      <rect y="14" width="133" height="20" fill="#EC1C24" />
      <rect y="73" width="133" height="19" fill="#FFCA05" />
      <rect y="34" width="133" height="19" fill="#FFCA05" />
      <rect y="92" width="133" height="20" fill="#EC1C24" />
      <rect y="-5" width="133" height="19" fill="#FFCA05" />
      <rect y="112" width="133" height="19" fill="#FFCA05" />
      <rect y="53" width="133" height="20" fill="#EC1C24" />
    </g>
  </svg>
);
