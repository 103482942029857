import React from 'react';
import { IconProps } from './icon-types';

export const FlagSpain = ({ size }: IconProps) => (
  <svg
    viewBox="0 0 127 126"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: size, height: size, borderRadius: size }}
  >
    <g>
      <path d="M126.664 -14.4102H0.814453V140.261H126.664V-14.4102Z" fill="#EC1C24" />
      <path d="M126.668 -14.4102H0.814453V88.7076H126.668V-14.4102Z" fill="#FFCA05" />
      <path d="M126.668 -14.4062H0.814453V37.1508H126.668V-14.4062Z" fill="#EC1C24" />
    </g>
  </svg>
);
