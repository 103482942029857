import { CSSInterpolation } from '@emotion/serialize';
import { BuildType } from 'src/enums/build/build-type';
import { Colors } from './colors';
import { Fonts } from './fonts';
import { fonts } from 'src/styling/constants';
import { getBuildDomainForBuildType, newWebsiteBuildDomains } from 'src/enums/build/build-domain';

export type Forms = {
  input: CSSInterpolation;
  standardLabelFont: CSSInterpolation;
};

export const getForms = (buildType: BuildType, colors: Colors, fonts: Fonts): Forms => {
  const isMeridian = buildType === BuildType.MERIDIAN;
  const isNewDesign = newWebsiteBuildDomains.includes(
    getBuildDomainForBuildType(
      buildType === BuildType.BLAZE_STUDIO ? BuildType.DAVID_LLOYD_UK : buildType
    )
  );

  const getTextBoxWithFontAndBackgroundColor = (
    backgroundColor: string,
    textColor: string
  ): CSSInterpolation => {
    return [
      fonts.light,
      {
        display: 'block',
        width: '100%',
        padding: '1rem',
        borderRadius: isMeridian ? '4px' : null,
        border: `1px solid ${colors.border}`,
        fontSize: '1rem',
        lineHeight: 1.25,
        boxSizing: 'border-box',
        backgroundColor: backgroundColor,
        color: textColor,
      },
    ];
  };

  const input: CSSInterpolation = getTextBoxWithFontAndBackgroundColor(
    isNewDesign ? 'white' : colors.secondaryBackground,
    colors.bodyText
  );
  const standardLabelFont: CSSInterpolation = [
    fonts.bold,
    {
      fontSize: '1rem',
      color: colors.bodyText,
    },
  ];
  return {
    input,
    standardLabelFont,
  };
};

export const getRedesignedForms = (colors: Colors) => {
  const input: CSSInterpolation = [
    fonts.regular,
    {
      'borderRadius': '0.375rem',
      'border': `1px solid ${colors.capeCod}`,
      'backgroundColor': 'white',
      'paddingRight': '0.75rem',
      'paddingLeft': '1.5rem',
      'fontSize': '1.1875rem',
      'height': '3.4375rem',
      'width': '100%',
      'boxSizing': 'border-box',
      '&:focus-visible': {
        outline: 'none',
      },
      '&::placeholder': {
        color: 'rgba(71, 74, 74, 0.70)',
      },
    },
  ];

  return {
    input,
  };
};
