import { get } from './request-helper';
import { useEffect, useState } from 'react';

export enum FEATURE_FLAG {
  SHOW_ADDRESS_AND_ASSOCIATES_DATA_CAPTURE_FOR_ONLINE_JOINING = 'show-address-and-associates-data-capture-for-online-joining',
  COLLECT_DIRECT_DEBIT_DETAILS_BEFORE_PAYMENT = 'collect-direct-debit-details-before-payment',
  PUZZEL_LIVE_CHAT_INSTEAD_OF_TAWK = 'gatsby-puzzel-live-chat-instead-of-tawk',
  ENABLE_CLUB_FACILITIES_MOBILE_NAV = 'gatsby-enable-club-facilities-mobile-nav',
  COLLECT_KICKSTART_ANSWERS = 'collect-kickstart-answers-in-online-joining',
  BAT_EXISTING_CONTACT_SEARCH_ENABLED = 'in-club-membership-signup-redirect',
  SKIP_GENERATING_JOINING_FEE_CHARGE_IN_CLUB = 'skip-generating-joining-fee-charge-in-club',
  ALLOW_PAY_BY_LINK_WHEN_JOINING_IN_CLUB = 'allow-pay-by-link-when-joining-in-club',
  SHOW_PRICE_INCREASE_WARNING_WHEN_JOINING = 'show-price-increase-warning-when-joining',
  SHOW_TELEPHONE_MARKETING_CONSENT = 'show-telephone-marketing-consent',
  SHOW_TELEPHONE_MARKETING_CONSENT_ONLINE_JOINING = 'show-telephone-marketing-consent-online-joining',
  SHOW_WHATS_APP_MARKETING_CONSENT_ONLINE_JOINING = 'show-whats-app-marketing-consent-online-joining',
  SHOW_WHATS_APP_MARKETING_CONSENT_SALES_APP = 'show-whats-app-marketing-consent-sales-app',
  SHOW_WHATS_APP_MARKETING_CONSENT_ENQUIRY = 'show-whats-app-marketing-consent-enquiry',
  SHOW_WHATS_APP_MARKETING_CONSENT_TOUR = 'show-whats-app-marketing-consent-tour',
  SHOW_WHATS_APP_MARKETING_CONSENT_TRIAL = 'show-whats-app-marketing-consent-trial',
  CAPTURE_POSTCODE_ON_LETS_GET_STARTED_PAGE = 'capture-postcode-on-online-joining-lets-get-started-page',
  ENABLE_IDEAL_PAYMENTS = 'enable-ideal-payments',
  ENABLE_APPLE_PAY_PAYMENTS = 'enable-apple-pay-payments',
  ENABLE_GOOGLE_PAY_PAYMENTS = 'enable-google-pay-payments',
  ENABLE_PAYPAL_PAYMENTS_IN_ONLINE_JOINING = 'enable-paypal-payments-in-online-joining',
  USE_STRICT_VALIDATION_ENQUIRY_TOUR = 'use-strict-validation-enquiry-tour',
  USE_STRICT_VALIDATION_SALES_APP = 'use-strict-validation-sales-app',
  USE_STRICT_VALIDATION_ONLINE_JOINING = 'use-strict-validation-online-joining',
  USE_STRICT_VALIDATION_TRIAL = 'use-strict-validation-trial',
  USE_EXTENDED_BOOK_A_TOUR_FORM = 'use-extended-book-a-tour-form',
  SHOW_IMMEDIATE_CONTACT_PREFERENCE_POPUP_IN_ONLINE_JOINING_AND_TOUR_BOOKING = 'show-immediate-contact-preferences-popup-in-online-joining-and-tour-booking',
  SHOW_UNITED_KINGDOM_CODE_IN_ONLINE_JOINING = 'show-united-kingdom-code-in-online-joining',
  SHOW_OTHER_CODE_IN_ONLINE_JOINING = 'show-other-code-in-online-joining',
  SHOW_TAX_ID_FIELD_SPANISH_CLUBS = 'show-tax-id-field-spanish-clubs',
}

export enum FEATURE_FLAG_NUMBER {
  SALES_TABLET_QR_CODE_EXPIRY_DURATION = 'sales-tablet-qr-code-expiry-duration',
}

type FeatureFlagResponse = {
  enabled: boolean;
};

type LaunchDarklyFeatureFlagResponse<T> = {
  featureFlagId: string;
  value: T;
};

export const getFeatureFlagValueAtClub = (
  siteId: string,
  FEATURE_FLAG: FEATURE_FLAG
): Promise<boolean> => {
  return get<FeatureFlagResponse>(`clubs/${siteId}/feature-flag/${FEATURE_FLAG}`).then(
    (response) => response.enabled
  );
};

export const getFeatureFlagValue = (FEATURE_FLAG: FEATURE_FLAG): Promise<boolean> => {
  return get<FeatureFlagResponse>(`feature-flag/${FEATURE_FLAG}`).then(
    (response) => response.enabled
  );
};

export const getLDFeatureFlagNumber = (FEATURE_FLAG: FEATURE_FLAG_NUMBER): Promise<number> => {
  return get<LaunchDarklyFeatureFlagResponse<number>>(
    `feature-flag/ld/number/${FEATURE_FLAG}`
  ).then((response) => response.value);
};

export function useFeatureFlagNumber(FEATURE_FLAG: FEATURE_FLAG_NUMBER, defaultValue: number) {
  const [featureFlagNumber, setFeatureFlagNumber] = useState(defaultValue);

  useEffect(() => {
    getLDFeatureFlagNumber(FEATURE_FLAG).then(setFeatureFlagNumber);
  }, [FEATURE_FLAG]);

  return featureFlagNumber;
}

export function useFeatureFlagIsEnabled(FEATURE_FLAG: FEATURE_FLAG): boolean {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    getFeatureFlagValue(FEATURE_FLAG).then(setEnabled);
  }, [FEATURE_FLAG]);

  return enabled;
}

export function useFeatureFlagIsEnabledAtSite(siteId: string, FEATURE_FLAG: FEATURE_FLAG): boolean {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    // In case of error, we consider the flag disabled
    getFeatureFlagValueAtClub(siteId, FEATURE_FLAG)
      .then(setEnabled)
      .catch(() => {
        setEnabled(false);
      });
  }, [FEATURE_FLAG, siteId]);

  return enabled;
}
