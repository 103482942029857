import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import {
  CmsFileNode,
  parseRelativeDirectory,
  findCmsFileNodeForClub,
} from 'src/helpers/cms-file-helper';
import { SiteContext } from 'src/context/site-context';
import { TrialCmsContent } from 'src/models/cms/trial-content';
import { SharedContent } from 'src/models/cms/shared-content';
import { ClubPageFeatures } from 'src/models/cms/club-page-features';
import { EnabledPages } from 'src/content/enabled-pages-helper';
import { NavigationMenuContent } from 'src/models/cms/navigation-menu-content';
import { BlazeStudioLandingContent } from 'src/models/cms/blaze-studio-landing-content';
import { BlazeStudioFaqsPageContent } from 'src/models/cms/blaze-studio-faqs-content';
import { FooterContent } from 'src/models/cms/footer-content';
import { useMemo } from 'react';

const cmsContentQuery = graphql`
  fragment _ClubDetails on ClubJson {
    clubId
    clubName
    hyphenatedClubName
  }

  fragment _Features on ClubJson {
    aDayInLife
    clubPageLiveChat
    clubOpeningHours
    clubOpeningHours
    contactUsPhone
    facebookFeed
    fullBookATour
    instagramFeed
    timetable
    poolOpeningHours
    landingClubDetails
  }

  fragment _MultiImageBlocks on ClubJson {
    multiImageBlocks {
      title
      description
      bottomDescription
      images {
        imagePath
        alternateText
        horizontalFocus
        leftHighlight
      }
      showVideo
      videoId
      button {
        showButton
        buttonText
        buttonLink
        goToGlobal
        buttonStyle
      }
    }
  }

  fragment _AtClub on ClubJson {
    atClub {
      showBlock
      title
      description
      imagesWithLink {
        image {
          imagePath
          alternateText
          horizontalFocus
          leftHighlight
        }
        link
        title
        description
      }
    }
  }

  fragment _CarouselWithButton on ClubJson {
    carouselWithButton {
      showBlock
      footerButton {
        showButton
        buttonText
        buttonLink
        goToGlobal
        buttonStyle
      }
      title
      description
      carouselImages {
        image {
          imagePath
          alternateText
          horizontalFocus
          leftHighlight
        }
        text
        subtext
        button {
          showButton
          buttonText
          buttonLink
          goToGlobal
          buttonStyle
        }
      }
    }
  }

  fragment _MultiImageLinkBlockContent on ClubJson {
    multiImageLinkBlock {
      showBlock
      title
      description
      imagesWithLink {
        image {
          imagePath
          alternateText
          horizontalFocus
          leftHighlight
        }
        link
        title
        description
      }
      button {
        showButton
        buttonText
        buttonStyle
      }
    }
  }

  fragment _SingleImageBlocks on ClubJson {
    singleImageBlocks {
      ..._SingleImageBlockDetails
    }
  }

  fragment _SharedContent on ClubJson {
    comingSoonClubFinder
    comingSoonHeroStyling
    clubEmail
    additionalClubEmailAddresses {
      emailAddress
      emailLabel
    }
    clubLocation {
      lat
      lng
      text1
      text2
      url
    }
    clubFacilities {
      facility
      videoId
    }
    clubVideos {
      mediaType
      text
      reference
    }
    menuLink
    enquireNowButton {
      enquireNowButtonText
      enquireNowButtonLink
      goToGlobal
    }
    linkDropdownCta {
      enabled
      text
      dropdownOptions {
        displayText
        link
      }
    }
    enquiryEmailAddresses
    events {
      name
      time
      description
      location
      action
      showUntil
    }
    landingVideo {
      videoId
      videoPlaceholder {
        imagePath
        alternateText
        horizontalFocus
        leftHighlight
      }
    }
    tourEmailAddresses
    defaultPromoPopup {
      title
      description
      button {
        showButton
        buttonText
        buttonLink
        goToGlobal
        buttonStyle
      }
      secondaryButton {
        showButton
        buttonText
        buttonLink
        goToGlobal
        buttonStyle
      }
    }
    alsoAt {
      showBlock
      title
      description
      theClub {
        imagePath
        alternateText
        horizontalFocus
        leftHighlight
      }
      familyAndKids {
        imagePath
        alternateText
        horizontalFocus
        leftHighlight
      }
      clubFacilities {
        imagePath
        alternateText
        horizontalFocus
        leftHighlight
      }
      relaxAndSocialise {
        imagePath
        alternateText
        horizontalFocus
        leftHighlight
      }
    }
    clubBanner {
      showBanner
      text
      button {
        showButton
        buttonText
        buttonLink
        goToGlobal
        buttonStyle
      }
      bannerBackgroundColor
      bannerTextColor
    }
    ..._AtClub
    sharedMobileApp {
      showBlock
      mobileApp {
        title
        description
        image {
          imagePath
          alternateText
          horizontalFocus
          leftHighlight
        }
      }
    }
  }

  fragment _NavigationMenu on ClubJson {
    fitnessMenu {
      displayText
      link
      image {
        imagePath
        alternateText
        horizontalFocus
        leftHighlight
      }
    }
    treatmentsMenu {
      displayText
      link
      image {
        imagePath
        alternateText
        horizontalFocus
        leftHighlight
      }
    }
    sportsMenu {
      displayText
      link
      image {
        imagePath
        alternateText
        horizontalFocus
        leftHighlight
      }
    }
    childrenMenu {
      displayText
      link
      image {
        imagePath
        alternateText
        horizontalFocus
        leftHighlight
      }
    }
    navigationPagesToDisplay {
      mainPages {
        displayText
        link
      }
      secondaryPages {
        displayText
        link
      }
      clubFacilitiesPages {
        mobileNavIcon
        displayText
        link
        facility
      }
    }
    navigation {
      spa {
        imagePath
        alternateText
        horizontalFocus
        leftHighlight
      }
      pools {
        imagePath
        alternateText
        horizontalFocus
        leftHighlight
      }
      tennis {
        imagePath
        alternateText
        horizontalFocus
        leftHighlight
      }
      padel {
        imagePath
        alternateText
        horizontalFocus
        leftHighlight
      }
      gym {
        imagePath
        alternateText
        horizontalFocus
        leftHighlight
      }
      exerciseClasses {
        imagePath
        alternateText
        horizontalFocus
        leftHighlight
      }
      creche {
        imagePath
        alternateText
        horizontalFocus
        leftHighlight
      }
      kidsClasses {
        imagePath
        alternateText
        horizontalFocus
        leftHighlight
      }
    }
  }

  fragment _DeepLinkPages on ClubJson {
    ptDeepLinks {
      title
      description
      hero {
        image {
          imagePath
          alternateText
          horizontalFocus
          leftHighlight
        }
      }
      davidLloydMobileApp {
        title
        description
        image {
          imagePath
          alternateText
          horizontalFocus
          leftHighlight
        }
      }
      harbourClubMobileApp {
        title
        description
        image {
          imagePath
          alternateText
          horizontalFocus
          leftHighlight
        }
      }
    }
    onDemandDeepLink {
      title
      description
      hero {
        image {
          imagePath
          alternateText
          horizontalFocus
          leftHighlight
        }
      }
      davidLloydMobileApp {
        title
        description
        image {
          imagePath
          alternateText
          horizontalFocus
          leftHighlight
        }
      }
      harbourClubMobileApp {
        title
        description
        image {
          imagePath
          alternateText
          horizontalFocus
          leftHighlight
        }
      }
    }
    liveStreamDeepLink {
      title
      description
      hero {
        image {
          imagePath
          alternateText
          horizontalFocus
          leftHighlight
        }
      }
      davidLloydMobileApp {
        title
        description
        image {
          imagePath
          alternateText
          horizontalFocus
          leftHighlight
        }
      }
      harbourClubMobileApp {
        title
        description
        image {
          imagePath
          alternateText
          horizontalFocus
          leftHighlight
        }
      }
    }
    extrasDeepLink {
      title
      description
      hero {
        image {
          imagePath
          alternateText
          horizontalFocus
          leftHighlight
        }
      }
      davidLloydMobileApp {
        title
        description
        image {
          imagePath
          alternateText
          horizontalFocus
          leftHighlight
        }
      }
      harbourClubMobileApp {
        title
        description
        image {
          imagePath
          alternateText
          horizontalFocus
          leftHighlight
        }
      }
    }
  }

  fragment _PromotionContent on ClubJson {
    timedPromotions {
      identifier
      siteIds
      promotionDetails {
        from
        to
        landing {
          applyPromotionContent
          inlinePromo {
            description
            subtext
            contentPosition
            image {
              imagePath
              alternateText
              horizontalFocus
              leftHighlight
            }
            button {
              showButton
              buttonText
              buttonLink
              goToGlobal
              oldButtonStyle
              newButtonStyle
            }
            landingFirstContentBlockTextColor
            landingFirstContentBlockTextSize
          }
          banner {
            button {
              showButton
              buttonText
              buttonLink
              goToGlobal
              oldButtonStyle
              newButtonStyle
            }
            secondaryButton {
              showButton
              buttonText
              buttonLink
              goToGlobal
              oldButtonStyle
              newButtonStyle
            }
            comingSoonText
          }
        }
        popup {
          applyPromotionContent
          title
          description
          button {
            showButton
            buttonText
            buttonLink
            goToGlobal
            oldButtonStyle
            newButtonStyle
          }
          secondaryButton {
            showButton
            buttonText
            buttonLink
            goToGlobal
            oldButtonStyle
            newButtonStyle
          }
        }
        enquire {
          applyPromotionContent
          secondaryButton {
            showButton
            buttonText
            buttonLink
            goToGlobal
            oldButtonStyle
            newButtonStyle
          }
          secondaryLabelText
          mobileBanner
          slideshow {
            slides {
              image {
                imagePath
                alternateText
                horizontalFocus
                leftHighlight
              }
            }
            upperText
            middleText
            bottomText
            button {
              showButton
              buttonText
              buttonLink
              goToGlobal
              oldButtonStyle
              newButtonStyle
            }
          }
          termsAndConditions
        }
        globalBanner {
          showBanner
          text
          button {
            showButton
            buttonText
            buttonLink
            goToGlobal
            oldButtonStyle
            newButtonStyle
          }
          bannerBackgroundColor
          bannerTextColor
        }
      }
    }
  }

  fragment _BlazeStudioLandingContent on ClubJson {
    hero {
      title
      description
      landingVideo {
        videoId
        videoPlaceholder {
          imagePath
          alternateText
          horizontalFocus
          leftHighlight
        }
      }
    }
    introduction {
      description
    }
    vimeoIframe {
      title
      vimeoId
    }
    clubSummaryList {
      bullets
    }
    blazeTrainers {
      description
      images {
        imagePath
        alternateText
        horizontalFocus
        leftHighlight
      }
    }
    blazePackages {
      description
      packageCards {
        numberOfSessions
        priceOfPackage
      }
    }
    blazeTrackYourProgress {
      backgroundImage {
        imagePath
        alternateText
        horizontalFocus
        leftHighlight
      }
      overlayImage {
        imagePath
        alternateText
        horizontalFocus
        leftHighlight
      }
      title
      description
    }
  }

  fragment _BlazeStudioFaqsContent on ClubJson {
    title
    faqsContent {
      title
      questions {
        question
        answer
      }
    }
  }

  fragment _SharedLandingContent on ClubJson {
    clubPhoneNumbers {
      description
      number
    }
    defaultLandingPromotion {
      inlinePromo {
        title
        description
        subtext
        contentPosition
        image {
          imagePath
          alternateText
          horizontalFocus
          leftHighlight
        }
        button {
          showButton
          buttonText
          buttonLink
          goToGlobal
          buttonStyle
        }
        landingFirstContentBlockTextColor
        landingFirstContentBlockTextSize
      }
      banner {
        button {
          showButton
          buttonText
          buttonLink
          goToGlobal
          buttonStyle
        }
        secondaryButton {
          showButton
          buttonText
          buttonLink
          goToGlobal
          buttonStyle
        }
        comingSoonText
      }
    }
  }

  fragment _BlazeScreenContent on ClubJson {
    screenContent {
      studioScreenId
      pageName
      text {
        deDe
        en
        itIt
      }
      title {
        deDe
        en
        itIt
      }
      buttonText {
        deDe
        en
        itIt
      }
    }
  }

  fragment _MemberOffersContent on ClubJson {
    bannerImage {
      imagePath
      alternateText
      horizontalFocus
      leftHighlight
    }
    bannerTitle
    bannerDescription
    memberOffers {
      backgroundImage {
        imagePath
        alternateText
        horizontalFocus
        leftHighlight
      }
      logo {
        imagePath
        alternateText
        horizontalFocus
        leftHighlight
      }
      brandName
      offerName
      title
      description
      offerPageTitle
      detailedDescription
      url
      validUntil
    }
  }

  fragment _QuadrantTextBlocks on ClubJson {
    quadrantTextBlocks {
      title
      description
    }
  }

  fragment _Pages on ClubJson {
    yogaPage {
      enabled
      clubSection
    }
    golfPage {
      enabled
      clubSection
    }
    familyAndKidsPage {
      enabled
      clubSection
    }
    relaxAndSocialisePage {
      enabled
      clubSection
    }
    timetablePage {
      enabled
      clubSection
    }
    clubFacilitiesPage {
      enabled
      clubSection
    }
    clubFacilities_spaPage {
      enabled
      clubSection
    }
    clubFacilities_poolsPage {
      enabled
      clubSection
    }
    clubFacilities_tennisPage {
      enabled
      clubSection
    }
    clubFacilities_padelPage {
      enabled
      clubSection
    }
    clubFacilities_gymPage {
      enabled
      clubSection
    }
    clubFacilities_exerciseClassesPage {
      enabled
      clubSection
    }
    clubFacilities_crechePage {
      enabled
      clubSection
    }
    clubFacilities_kidsClassesPage {
      enabled
      clubSection
    }
  }

  fragment _MembershipTrialContent on ClubJson {
    trialDetails {
      header {
        bannerText
        image {
          imagePath
          alternateText
          horizontalFocus
          leftHighlight
        }
        title {
          line1
          line2
          line3
          subtext
        }
      }
      whyJoin {
        title
        description
        facilities
      }
      forms {
        title
        description
        payButtonText
      }
      summary {
        duration
        durationTooltip
        clubTourTimeTooltip
        totalDueText
        footer
        highlightText
      }
      clubForm {
        title
        description
      }
      personalDetailsForm {
        title
      }
      trialDetailsForm {
        title
        line1
        line1Tooltip
        line2
      }
      termsAndConditionsForm {
        title
        termsAndConditions
        confirmationText
      }
    }
    paymentDetails {
      title
      cardDetailsText
    }
    confirmationPage {
      header {
        welcomeToTheClub
        subheader
      }
      membershipCard {
        imagePath
        alternateText
        horizontalFocus
        leftHighlight
      }
      whatToExpect {
        title
        bullets
      }
      social {
        title
        description
        twitterText
      }
      mobileApp {
        title
        description
        image {
          imagePath
          alternateText
          horizontalFocus
          leftHighlight
        }
      }
    }
  }

  fragment _Placeholder on ClubJson {
    clubId
  }

  fragment _ServiceTypesBlock on ClubJson {
    serviceTypes {
      showBlock
      title
      serviceTypesList {
        title
        description
        characteristics
        image {
          imagePath
          alternateText
          horizontalFocus
          leftHighlight
        }
      }
      toggleButton {
        showButton
        buttonText
        buttonStyle
      }
    }
  }

  query CmsContentQuery {
    clubDetails: allFile(filter: { base: { eq: "details.json" } }) {
      nodes {
        relativeDirectory
        childClubJson {
          ..._ClubDetails
        }
      }
    }
    features: allFile(filter: { base: { eq: "features.json" } }) {
      nodes {
        relativeDirectory
        childClubJson {
          ..._Features
        }
      }
    }
    sharedContent: allFile(filter: { base: { eq: "shared.json" } }) {
      nodes {
        relativeDirectory
        childClubJson {
          ..._SharedContent
        }
      }
    }
    navigationMenuContent: allFile(filter: { base: { eq: "navigation-menu.json" } }) {
      nodes {
        relativeDirectory
        childClubJson {
          ..._NavigationMenu
        }
      }
    }
    promotionContent: allFile(filter: { base: { eq: "promotion.json" } }) {
      nodes {
        relativeDirectory
        childClubJson {
          ..._PromotionContent
        }
      }
    }
    deepLinkPages: allFile(filter: { base: { eq: "deep-links.json" } }) {
      nodes {
        relativeDirectory
        childClubJson {
          ..._DeepLinkPages
        }
      }
    }
    blazeStudioLandingContent: allFile(filter: { base: { eq: "blaze-landing.json" } }) {
      nodes {
        relativeDirectory
        childClubJson {
          ..._BlazeStudioLandingContent
        }
      }
    }
    blazeStudioFaqsContent: allFile(filter: { base: { eq: "blaze-faqs.json" } }) {
      nodes {
        relativeDirectory
        childClubJson {
          ..._BlazeStudioFaqsContent
        }
      }
    }
    sharedLandingContent: allFile(filter: { base: { eq: "landing.json" } }) {
      nodes {
        relativeDirectory
        childClubJson {
          ..._SharedLandingContent
        }
      }
    }
    memberOffersContent: allFile(filter: { base: { eq: "member-offers.json" } }) {
      nodes {
        relativeDirectory
        childClubJson {
          ..._MemberOffersContent
        }
      }
    }
    pages: allFile(filter: { base: { eq: "pages.json" } }) {
      nodes {
        relativeDirectory
        childClubJson {
          ..._Pages
        }
      }
    }
    membershipTrialContent: allFile(filter: { base: { eq: "membership-trial.json" } }) {
      nodes {
        relativeDirectory
        childClubJson {
          ..._MembershipTrialContent
        }
      }
    }
  }
`;

interface WrappedCmsRecord<T> {
  clubDetails: T;
  features: T;
  sharedContent: T;
  navigationMenuContent: T;
  promotionContent: T;
  deepLinkPages: T;
  blazeStudioLandingContent: T;
  blazeStudioFaqsContent: T;
  sharedLandingContent: T;
  pages: T;
  membershipTrialContent: T;
  memberOffersContent: T;
  footerContent: T;
}

export interface CmsRecord {
  clubDetails: any;
  features: ClubPageFeatures;
  sharedContent: SharedContent;
  navigationMenuContent: NavigationMenuContent;
  promotionContent: any;
  deepLinkPages: any;
  blazeStudioLandingContent: BlazeStudioLandingContent;
  blazeStudioFaqsContent: BlazeStudioFaqsPageContent;
  sharedLandingContent: Queries._SharedLandingContentFragment;
  pages: EnabledPages;
  membershipTrialContent: TrialCmsContent;
  memberOffersContent: any;
  footerContent: FooterContent;
}

export type CmsContent = WrappedCmsRecord<CmsFileNode<any>>;

export const CmsProvider = ({
  clubId,
  children,
}: {
  clubId: string;
  children: (cmsContent: CmsRecord) => React.ReactNode;
}) => {
  const { language } = React.useContext(SiteContext);

  const cms = useStaticQuery<WrappedCmsRecord<{ nodes: CmsFileNode<any>[] }>>(cmsContentQuery);

  const cmsRecord = useMemo(() => {
    // Replace backslashes in paths with forwards slashes for cross-platform compat
    Object.keys(cms).forEach((key: keyof WrappedCmsRecord<{ nodes: CmsFileNode<any>[] }>) => {
      cms[key].nodes.forEach(parseRelativeDirectory);
    });

    return Object.keys(cms).reduce(
      (record, key: keyof WrappedCmsRecord<{ nodes: CmsFileNode<any>[] }>) => {
        const cmsNode = findCmsFileNodeForClub(cms[key].nodes, clubId, language);

        record[key] = cmsNode ? cmsNode.childClubJson : null;

        return record;
      },
      {} as CmsRecord
    );
  }, [clubId, cms, language]);

  return children(cmsRecord);
};
