import { Link } from 'gatsby';
import React, { forwardRef, LegacyRef, useContext } from 'react';
import { getClubPagePath, getGlobalPagePath } from 'src/helpers/navigation-helper';
import { SiteContext } from 'src/context/site-context';
import { isExternalLink, isJumpLink } from 'src/helpers/link-helper';
import { updateUrlPreservingQueryParams } from 'src/helpers/query-string-helper';
import { useGatsbyPaths } from 'src/custom-hooks/use-gatsby-paths';
import { CSSInterpolation } from '@emotion/serialize';

type WebsiteLinkProps = {
  children?: React.ReactNode;
  to: string;
  goToGlobal?: boolean;
  preserveQueryParams?: boolean;
  openInNewTab?: boolean;
  onFocus?: () => void;
  className?: string;
  css?: CSSInterpolation;
  isTabIndex?: boolean;
  handleClick?: () => void;
};

export const WebsiteLink = forwardRef<HTMLElement, WebsiteLinkProps>((props, ref) => {
  const isExternalOrJumpLink = props.to && (isExternalLink(props.to) || isJumpLink(props.to));

  return !isExternalOrJumpLink ? (
    <InternalLink {...props} ref={ref as LegacyRef<Link<unknown>> & LegacyRef<HTMLAnchorElement>} />
  ) : (
    <AnchorLink {...props} ref={ref as LegacyRef<Link<unknown>> & LegacyRef<HTMLAnchorElement>} />
  );
});

const InternalLink = forwardRef<HTMLAnchorElement, WebsiteLinkProps>((props, ref) => {
  const { siteId, language, buildType, isGlobalPage } = useContext(SiteContext);
  const { isGatsbyPath } = useGatsbyPaths();

  const path =
    isGlobalPage || props.goToGlobal
      ? getGlobalPagePath(props.to, language, buildType)
      : getClubPagePath(props.to, siteId, language);

  const useGatsbyLink = isGatsbyPath(path) && !props.openInNewTab;

  const pathWithQueryParams = props.preserveQueryParams
    ? updateUrlPreservingQueryParams(path)
    : path;

  const tabIndexProp = props.isTabIndex ? { tabIndex: 0 } : {};

  return useGatsbyLink ? (
    <Link
      className={props.className}
      to={pathWithQueryParams}
      onFocus={props.onFocus}
      css={props.css}
      ref={ref as LegacyRef<Link<unknown>> & LegacyRef<HTMLAnchorElement>}
      onClick={() => props?.handleClick && props?.handleClick()}
      {...tabIndexProp}
    >
      {props.children}
    </Link>
  ) : (
    <AnchorLink {...props} to={pathWithQueryParams} ref={ref} />
  );
});

export const ExternalLink = forwardRef<HTMLAnchorElement, WebsiteLinkProps>((props, ref) => (
  <AnchorLink {...props} ref={ref} />
));

const AnchorLink = forwardRef<HTMLAnchorElement, WebsiteLinkProps>((props, ref) => {
  const tabIndexProp = props.isTabIndex ? { tabIndex: 0 } : {};

  return (
    <a
      className={props.className}
      href={props.to}
      onFocus={props.onFocus}
      target={props.openInNewTab ? '_blank' : undefined}
      rel={props.openInNewTab ? 'noopener noreferrer' : undefined}
      css={props.css}
      ref={ref}
      onClick={(event) => {
        props?.handleClick && props?.handleClick();

        if (isJumpLink(props.to)) {
          const id = props.to.split('#')[1];
          document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
          event.preventDefault();
        }
      }}
      {...tabIndexProp}
    >
      {props.children}
    </a>
  );
});

WebsiteLink.displayName = 'WebsiteLink';
InternalLink.displayName = 'InternalLink';
ExternalLink.displayName = 'ExternalLink';
AnchorLink.displayName = 'AnchorLink';
