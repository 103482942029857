import React from 'react';
import mq from 'src/styling/media-queries';

const HeaderUnderlay: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props) => (
  <div
    {...props}
    css={mq({
      display: ['none', 'initial'],
      position: 'absolute',
      width: '100vw',
      height: '100vh',
      top: '100%',
      right: 0,
      backgroundColor: 'transparent',
      opacity: [0.4, null],
      zIndex: -1,
    })}
  />
);

export default HeaderUnderlay;
