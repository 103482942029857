import React, { useContext } from 'react';
import { CmsContent } from 'src/content';
import { SiteConfig } from 'src/context/site-context';
import { BuildType, getCountryCodeForBuildType } from 'src/enums/build/build-type';
import { ContactDetails } from '../common/contact-details';
import { getDisplayTextForLanguage, Language } from 'src/enums/language';
import {
  useGeoLocation,
  useOpeningHoursSpecification,
  getAddressParts,
  getClubPhoneNumber,
  getContactDetails,
  HEAD_OFFICE_PHONE_NUMBER,
} from './structured-data-helper';
import { HealthClub, Organization, Thing as SchemaObject, WithContext } from 'schema-dts';

export const STRUCTURED_DATA_CONTEXT = 'https://schema.org';
const SCRIPT_TYPE = 'application/ld+json';

const getStructuredData = (buildType: BuildType) => {
  switch (buildType) {
    case 'meridian':
      return {
        name: 'Meridian Spa & Fitness Deutschland GmbH',
        clubName: 'Meridian Spa & Fitness',
        url: 'https://www.meridianspa.de/',
        logo: 'https://www.meridianspa.de/fixed-images/meridian-logo-dark-bg.png',
        sameAs: [
          'https://de-de.facebook.com/meridianspa/',
          'https://www.instagram.com/meridianspa/?hl=de',
          'https://www.youtube.com/MeridianSpa',
        ],
      };
    default:
      return {
        name: 'David Lloyd Clubs',
        clubName: 'David Lloyd',
        sameAs: [
          'https://www.facebook.com/davidlloydclubsuk',
          'https://twitter.com/DavidLloydUK',
          'https://www.youtube.com/user/davidlloydleisureltd',
        ],
        url: 'https://www.davidlloyd.co.uk',
        logo: 'https://www.davidlloyd.co.uk/-/media/david-lloyd/images/logo/david-lloyd-logo-dark.png',
      };
  }
};

export const getOrganisationStructuredData = (buildType: BuildType): WithContext<Organization> => {
  return {
    '@context': STRUCTURED_DATA_CONTEXT,
    '@type': 'Organization',
    'name': getStructuredData(buildType).name,
    'url': getStructuredData(buildType).url,
    'logo': getStructuredData(buildType).logo,
    'sameAs': getStructuredData(buildType).sameAs,
    'contactPoint': {
      '@type': 'ContactPoint',
      'areaServed': 'GB',
      'availableLanguage': 'en',
      'contactType': 'customer support',
      'telephone': `${HEAD_OFFICE_PHONE_NUMBER}`,
    },
  };
};

export const useClubStructuredData = (
  buildType: BuildType,
  contactDetails: ContactDetails,
  clubId: string,
  language: Language
): WithContext<HealthClub> => {
  const clubPhoneNumber = getClubPhoneNumber(contactDetails.clubPhoneNumbers, 'Non-members:');
  const { streetAddress, postCode } = getAddressParts(contactDetails);
  const openingHoursSpecification = useOpeningHoursSpecification(clubId);
  const geoLocation = useGeoLocation(clubId);

  return {
    '@context': STRUCTURED_DATA_CONTEXT,
    '@type': 'HealthClub',
    'name': `${getStructuredData(buildType).clubName} ${contactDetails.clubName}`,
    'logo': getStructuredData(buildType).logo,
    'url': `${getStructuredData(buildType).url}/clubs/${contactDetails.hyphenatedClubName}`,
    'address': {
      '@type': 'PostalAddress',
      'streetAddress': `${streetAddress}`,
      'postalCode': `${postCode}`,
      'addressCountry': getCountryCodeForBuildType(buildType),
    },
    'geo': geoLocation,
    'openingHoursSpecification': openingHoursSpecification,
    'contactPoint': {
      '@type': 'ContactPoint',
      'areaServed': getCountryCodeForBuildType(buildType),
      'availableLanguage': getDisplayTextForLanguage(language),
      'contactType': 'customer support',
      'telephone': `${clubPhoneNumber}`,
      'email': `${contactDetails.clubEmail}`,
    },
  };
};

type StructuredDataProps = {
  siteConfig: SiteConfig;
};

export const StructuredData: React.FC<StructuredDataProps> = ({ siteConfig }) => {
  const { buildType, language } = siteConfig;
  const data = useContext(CmsContent);
  const contactDetails: ContactDetails = getContactDetails(data);
  const organisationStructuredData = getOrganisationStructuredData(buildType);
  const clubStructuredData = useClubStructuredData(
    buildType,
    contactDetails,
    data.clubDetails.clubId,
    language
  );

  return (
    contactDetails.clubName !== 'promo' &&
    contactDetails.clubName.trim() !== '' && (
      <>
        <StructuredDataObject structuredData={organisationStructuredData} />
        <StructuredDataObject structuredData={clubStructuredData} />
      </>
    )
  );
};

type StructuredDataObjectProps = {
  structuredData: WithContext<SchemaObject>;
};

export const StructuredDataObject = ({ structuredData }: StructuredDataObjectProps) => {
  if (!structuredData) {
    return null;
  }

  return (
    <script
      type={SCRIPT_TYPE}
      dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
    />
  );
};
