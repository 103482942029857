import React from 'react';
import { IconProps } from './icon-types';

export const FlagNetherlands = ({ size }: IconProps) => (
  <svg
    viewBox="0 0 126 126"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: size, height: size, borderRadius: size }}
  >
    <g>
      <rect x="-14" y="40" width="155" height="53" fill="white" />
      <rect x="-14" y="93" width="155" height="53" fill="#21409A" />
      <rect x="-14" y="-13" width="155" height="53" fill="#EC1C24" />
    </g>
  </svg>
);
