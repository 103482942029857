import React, { useContext } from 'react';
import { WebsiteLink } from '../common/website-link';
import { shadow, zIndexes, flex } from '../../styling/constants';
import FixedImage from '../common/fixed-image';
import CloseIcon from '../icons/close';
import LanguagePicker from '../common/language-picker';
import { SiteContext } from '../../context/site-context';

type SimpleNavBarProps = {
  onCloseButtonClicked: () => void;
  logoWrapper?: React.ReactElement;
};

const height = '5rem';

export const SimpleNavBarWithSpacer: React.FC<SimpleNavBarProps> = ({
  onCloseButtonClicked,
  logoWrapper = <WebsiteLink to="/" />,
}) => {
  const { styling } = useContext(SiteContext);
  return (
    <>
      <div
        id="wizard-nav"
        css={[
          shadow.below,
          flex.rowCentered,
          {
            justifyContent: 'space-between',
            padding: '0.5rem 2rem',
            backgroundColor: styling.colors.headerBackground,
            width: '100%',
            position: 'fixed',
            zIndex: zIndexes.pageNavigation,
            height: height,
          },
        ]}
      >
        <div css={{ padding: '0.5rem', flexShrink: 0, width: '8.7rem' }}>
          {React.cloneElement(
            logoWrapper,
            null,
            <FixedImage imageDetails={styling.logos.headerLogo} />
          )}
        </div>
        <div css={{ flex: 1 }} />
        <LanguagePicker />
        <div css={{ cursor: 'pointer' }} onClick={onCloseButtonClicked}>
          <CloseIcon size="2rem" fill={styling.colors.highlightText} />
        </div>
      </div>
      <div css={{ paddingTop: height }} />
    </>
  );
};

export default SimpleNavBarWithSpacer;
