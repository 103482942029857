import { Language } from 'src/enums/language';
import { BuildType } from 'src/enums/build/build-type';
import { PAGE_LEVEL } from 'src/enums/page-level';

export type PageLanguage = {
  language: Language;
  path: string;
};

export type AlternatePage = PageLanguage & {
  buildType: BuildType;
};

export enum PageLayoutType {
  blank = 'blank',
  simple = 'simple',
  global = 'global',
  club = 'club',
}

type PageBlankLayoutOptions = {
  type: PageLayoutType.blank;
};

type PageSimpleLayoutOptions = {
  type: PageLayoutType.simple;
  shouldShowFooter?: boolean; // default false
};

type PageGlobalLayoutOptions = {
  type: PageLayoutType.global;
  shouldShowFooter?: boolean; // default true
  pageLevel?: PAGE_LEVEL;
};

type PageClubLayoutOptions = {
  type: PageLayoutType.club;
};

export type PageLayoutOptions =
  | PageBlankLayoutOptions
  | PageSimpleLayoutOptions
  | PageGlobalLayoutOptions
  | PageClubLayoutOptions;

type PageGlobalOrClubContext =
  | {
      isGlobalPage: true;
      clubId?: string | undefined | null;
      clubDirectoryName?: string | undefined | null;
    }
  | {
      isGlobalPage: false;
      clubId: string;
      clubDirectoryName: string;
    };

export type PageContext = PageGlobalOrClubContext & {
  globalDirectoryName: string;
  language?: Language;
  pageLanguages?: PageLanguage[];
  alternatePages?: AlternatePage[];
  basePath?: string;
  buildType?: BuildType;
  untranslatedPath?: string;
  seo: {
    allowPageIndex: boolean;
  };
  pageLayout: PageLayoutOptions;
};
