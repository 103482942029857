import React, { useContext, useState } from 'react';
import ExpandIcon from 'src/components/icons/expand';
import { SiteContext } from 'src/context/site-context';
import { Styling } from 'src/context/styling';
import { zIndexes } from 'src/styling/constants';
import mq from 'src/styling/media-queries';
import LinkDropdownCtaUnderlay from './link-dropdown-cta-underlay';
import { CSSInterpolation } from '@emotion/serialize';
import LinkDropdown from 'src/components/common/link-dropdown-cta/link-dropdown';

type LinkDropdownCtaProps = {
  title?: string;
  links?: readonly Queries.LinkDropdownCtaDropdownOptions[];
  showingMobileNav: boolean;
};

const createStyling = (styling: Styling, showingMobileNav: boolean) =>
  ({
    wrapper: mq({
      height: '100%',
      alignContent: 'center',
      display: [showingMobileNav ? 'none' : 'inline', 'inline'],
    }),
    button: mq.withSmallTablet([
      styling.buttons.primary,
      styling.fonts.regular,
      {
        zIndex: zIndexes.pageNavigation,
        position: 'relative',
        paddingBottom: '0.5rem',
        paddingTop: '0.5rem',
        paddingRight: ['0.25rem', '0.75rem'],
        paddingLeft: ['0.5rem', '1rem'],
      },
    ]),
    expandIconWrapper: { marginLeft: '0.25rem', opacity: '50%' },
  }) satisfies Record<string, CSSInterpolation>;

const LinkDropdownCta = ({ title, links, showingMobileNav }: LinkDropdownCtaProps) => {
  const { styling } = useContext(SiteContext);
  const [showDropdown, setShowDropdown] = useState(false);

  const componentStyling = createStyling(styling, showingMobileNav);

  return (
    <div css={componentStyling.wrapper}>
      {showDropdown && <LinkDropdownCtaUnderlay onPress={() => setShowDropdown(false)} />}
      <button
        css={componentStyling.button}
        data-gtmid="header-link-dropdown-cta"
        type="button"
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <div className="flex items-center">
          <span>{title}</span>
          <div css={componentStyling.expandIconWrapper}>
            <ExpandIcon size={20} fill="white" isExpanded={showDropdown} />
          </div>
        </div>
      </button>
      {showDropdown && <LinkDropdown links={links ?? []} />}
    </div>
  );
};

export default LinkDropdownCta;
