import React from 'react';
import { zIndexes } from 'src/styling/constants';
import mq from 'src/styling/media-queries';

type LinkDropdownCtaUnderlayProps = {
  onPress: () => void;
};

const LinkDropdownCtaUnderlay: React.FC<LinkDropdownCtaUnderlayProps> = ({ onPress }) => (
  <>
    <div
      css={mq.withSmallTablet({
        position: 'absolute',
        width: '100vw',
        height: '100vh',
        top: '100%',
        right: 0,
        backgroundColor: ['#1d2028', 'transparent'],
        opacity: [0.4, null],
        zIndex: zIndexes.dropShadow,
      })}
    />
    <div
      css={mq.withSmallTablet({
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: zIndexes.dropShadow,
      })}
      onClick={onPress}
    ></div>
  </>
);

export default LinkDropdownCtaUnderlay;
