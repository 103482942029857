export const isNullOrBlank = (testString: string | null): boolean =>
  testString == null || testString.trim().length === 0;

export const trimLocationString = (text1: string, text2: string): string => {
  if (isNullOrBlank(text1) && isNullOrBlank(text2)) {
    return null;
  }

  const text1CommasRemoved = text1.trim().replace(/(^,)|(,$)/g, '');
  const text2CommasRemoved = text2.trim().replace(/(^,)|(,$)/g, '');
  return `${text1CommasRemoved}, ${text2CommasRemoved}`;
};
export const isFalsyOrWhitespace = (text: string): boolean => {
  return !text?.trim();
};

export const capitaliseFirstLetter = (text: string) =>
  text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

export const capitaliseFirstLetterOfEachWord = (text: string) =>
  text.split(/\s+/).map(capitaliseFirstLetter).join(' ');

export const splitCamelCaseIntoWordsAndCapitaliseFirstLetter = (text: string) =>
  text
    .split(/(?=[A-Z])/)
    .map(capitaliseFirstLetter)
    .join(' ');

export const pathToTitle = (path: string) => {
  const trimmedPath = path.replace(/[^a-zA-Z\/]/g, ' ');

  const parts = trimmedPath.split('/');

  return parts.map((part) => capitaliseFirstLetter(part)).join(' ');
};
