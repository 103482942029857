import React, {
  PropsWithChildren,
  startTransition,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { mq, mqReducedMotion } from 'src/styling/media-queries';
import { CSSInterpolation } from '@emotion/serialize';
import { keyframes } from '@emotion/react';

const delayOverflow = keyframes({
  '0%': {
    overflowY: 'hidden',
  },
  '99%': {
    overflowY: 'hidden',
  },
  '100%': {
    overflowY: 'initial',
  },
});

const createComponentStyling = () =>
  ({
    hidden: [
      mqReducedMotion({
        transition: ['all 0.3s ease-in-out', 'none'],
        height: 0,
        visibility: 'hidden',
        overflowY: 'hidden',
      }),
    ],
    visible: mqReducedMotion({
      transition: ['all 0.3s, visibility 0s', 'none'],
      visibility: 'visible',
      overflowY: [null, 'visible'],
      animation: [`${delayOverflow} 0.3s`, 'none'],
    }),
    desktopAlwaysOpen: mq({
      height: [null, 'auto'],
      transition: [null, 'none'],
      visibility: [null, 'visible'],
      overflowY: [null, 'visible'],
    }),
  }) satisfies Record<string, CSSInterpolation>;

type AnimatedExpandWrapperProps = PropsWithChildren<{
  isExpanded: boolean;
  desktopAlwaysOpen?: boolean;
  containerStyles?: CSSInterpolation;
}>;

const AnimatedExpandWrapper = ({
  isExpanded,
  desktopAlwaysOpen,
  containerStyles,
  children,
}: AnimatedExpandWrapperProps) => {
  const childContainerRef = useRef<HTMLDivElement>(null);
  const componentStyles = createComponentStyling();
  const [childHeight, setChildHeight] = useState(0);

  const updateHeaderHeight = useCallback(
    () =>
      startTransition(() => {
        const height = childContainerRef.current?.getBoundingClientRect()?.height;

        if (height) {
          setChildHeight(height);
        }
      }),
    []
  );

  useLayoutEffect(() => {
    updateHeaderHeight();

    window.addEventListener('resize', updateHeaderHeight);
    window.addEventListener('orientationchange', updateHeaderHeight);

    return () => {
      window.removeEventListener('resize', updateHeaderHeight);
      window.removeEventListener('orientationchange', updateHeaderHeight);
    };
  }, [updateHeaderHeight]);

  useEffect(() => {
    if (isExpanded) {
      updateHeaderHeight();
    }
  }, [isExpanded, updateHeaderHeight]);

  return (
    <div
      css={[
        isExpanded ? [componentStyles.visible, { height: childHeight }] : componentStyles.hidden,
        desktopAlwaysOpen ? componentStyles.desktopAlwaysOpen : null,
        containerStyles,
      ]}
    >
      <div ref={childContainerRef}>{children}</div>
    </div>
  );
};

export default AnimatedExpandWrapper;
