import { ButtonStyle, RedesignButtonStyle } from 'src/enums/button-style';
import { Styling } from 'src/context/styling';
import { newWebsiteBuildDomains } from 'src/enums/build/build-domain';
import { buildDomain } from '../../../build/environment';

export const getButtonCss = (buttonStyle: ButtonStyle, styling: Styling) => {
  switch (buttonStyle) {
    case ButtonStyle.PRIMARY:
      return styling.buttons.primary;
    case ButtonStyle.SECONDARY:
      return styling.buttons.secondary;
    case ButtonStyle.TRANSPARENT:
      return styling.buttons.transparent;
    case ButtonStyle.SECONDARY_TRANSPARENT:
      return styling.buttons.secondaryTransparent;
    case ButtonStyle.ALERT:
      return styling.buttons.alert;
  }
};

export const getNewButtonCss = (buttonStyle: RedesignButtonStyle, styling: Styling) => {
  switch (buttonStyle) {
    case RedesignButtonStyle.PLUM:
      return styling.redesignButtons.plum;
    case RedesignButtonStyle.TRANSPARENT:
      return styling.redesignButtons.transparent;
    case RedesignButtonStyle.DARK_GREEN:
      return styling.redesignButtons.darkGreen;
    case RedesignButtonStyle.WHITE_WITH_GREEN_FONT:
      return styling.redesignButtons.whiteWithGreenFont;
    case RedesignButtonStyle.WHITE_WITH_PLUM_FONT:
      return styling.redesignButtons.whiteWithPlumFont;
  }
};

export const getBuildButtonStyle = (
  buttonStyle: RedesignButtonStyle | ButtonStyle,
  styling: Styling
) =>
  newWebsiteBuildDomains.includes(buildDomain)
    ? getNewButtonCss(buttonStyle as RedesignButtonStyle, styling)
    : getButtonCss(buttonStyle as ButtonStyle, styling);
