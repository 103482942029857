import { getCookieWithKey } from 'src/cookies';
import { userHasConsentedToAnalyticsCookieCategory } from 'src/cookies/cookie-types/analytical-cookies';
import { userHasConsentedToPreferencesCookieCategory } from 'src/cookies/cookie-types/preference-cookies';
import { userHasConsentedToSocialMediaCookieCategory } from 'src/cookies/cookie-types/social-media-cookies';
import { userHasConsentedToMarketingCookieCategory } from 'src/cookies/cookie-types/marketing-cookies';
import { necessaryConsentCookieCategoryKey } from './cookie-types/necessary-cookies';
import { BuildType } from 'src/enums/build/build-type';
import { cookieConsentIsNecessaryForBuildType } from 'src/helpers/cookie-helper';

export const COOKIE_CONSENT_COOKIE_NAME = 'CookieControl';
export const COOKIE_CATEGORY_CONSENT_ACCEPTED_VALUE = 'accepted';

export const userHasConsentedToCookieCategoryWithKey = (
  cookieCategoryKey: string,
  buildType: BuildType
): boolean => {
  if (cookieCategoryKey === necessaryConsentCookieCategoryKey) {
    return true;
  }

  if (cookieConsentIsNecessaryForBuildType(buildType)) {
    return true;
  }

  const consentCookieValue = getCookieWithKey(
    COOKIE_CONSENT_COOKIE_NAME,
    cookieCategoryKey
  ).getValue();

  if (!consentCookieValue) {
    return false;
  }
  const parsedConsentCookieValue = JSON.parse(consentCookieValue);
  return (
    parsedConsentCookieValue.optionalCookies[cookieCategoryKey] ===
    COOKIE_CATEGORY_CONSENT_ACCEPTED_VALUE
  );
};

export const userHasConsentedToAllOptionalCookieCategories = (buildType: BuildType): boolean =>
  userHasConsentedToAnalyticsCookieCategory(buildType) &&
  userHasConsentedToPreferencesCookieCategory(buildType) &&
  userHasConsentedToMarketingCookieCategory(buildType) &&
  userHasConsentedToSocialMediaCookieCategory(buildType);
