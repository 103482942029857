import { CSSInterpolation } from '@emotion/serialize';
import { Colors } from './colors';
import { borderRadius } from 'src/styling/constants';
import { Fonts } from './fonts';
import { BuildType } from 'src/enums/build/build-type';

export type Buttons = {
  primary: CSSInterpolation;
  disabled: CSSInterpolation;
  highlight: CSSInterpolation;
  secondary: CSSInterpolation;
  secondaryNoHover: CSSInterpolation;
  secondaryDisabled: CSSInterpolation;
  transparent: CSSInterpolation;
  secondaryTransparent: CSSInterpolation;
  alert: CSSInterpolation;
  darkGreen?: CSSInterpolation;
  plum?: CSSInterpolation;
  whiteWithPlumFont?: CSSInterpolation;
  whiteWithGreenFont?: CSSInterpolation;
};

export const getButtons = (buildType: BuildType, colors: Colors, fonts: Fonts) => {
  const isMeridian = buildType === BuildType.MERIDIAN;
  const button: CSSInterpolation = {
    border: 'none',
    borderRadius: isMeridian ? borderRadius.meridianButton : borderRadius.button,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    transition: 'background-color 0.2s ease',
    cursor: 'pointer',
    padding: '1rem',
    maxWidth: '20rem',
    fontSize: '1rem',
    lineHeight: '1',
  };

  return {
    primary: [
      button,
      fonts.bold,
      {
        'color': 'white',
        'backgroundColor': colors.highlightText,
        'border': `3px solid transparent`,
        '&:hover': {
          backgroundColor: colors.darkHighlight,
        },
      },
    ],
    disabled: [
      button,
      fonts.bold,
      {
        backgroundColor: colors.fadedHighlight,
        border: `3px solid transparent`,
        color: 'white',
        cursor: 'not-allowed',
      },
    ],
    highlight: [
      button,
      fonts.bold,
      {
        'color': 'white',
        'backgroundColor': colors.goldHighlight,
        'border': `3px solid transparent`,
        '&:hover': {
          backgroundColor: colors.darkGoldHighlight,
        },
      },
    ],
    secondary: [
      button,
      fonts.bold,
      {
        'color': colors.highlightText,
        'backgroundColor': isMeridian ? colors.secondaryHighlight : 'white',
        'border': `3px solid ${isMeridian ? 'transparent' : colors.fadedHighlight}`,
        '&:hover': {
          color: 'white',
          backgroundColor: colors.highlightText,
          border: `3px solid ${colors.darkHighlight}`,
        },
      },
    ],
    secondaryNoHover: [
      button,
      fonts.bold,
      {
        color: colors.highlightText,
        backgroundColor: 'white',
        border: `3px solid ${colors.fadedHighlight}`,
      },
    ],
    secondaryDisabled: [
      button,
      fonts.bold,
      {
        border: `3px solid ${colors.fadedHighlight}`,
        color: colors.fadedHighlight,
        backgroundColor: 'white',
        cursor: 'auto',
      },
    ],
    transparent: [
      button,
      fonts.bold,
      {
        'color': 'white',
        'backgroundColor': 'transparent',
        'border': `3px solid ${colors.highlightText}`,
        '&:hover': {
          color: 'white',
          backgroundColor: colors.highlightText,
          border: `3px solid ${colors.darkHighlight}`,
        },
      },
    ],
    secondaryTransparent: [
      button,
      fonts.bold,
      {
        'color': colors.highlightText,
        'backgroundColor': 'transparent',
        'border': `3px solid ${colors.highlightText}`,
        '&:hover': {
          color: 'white',
          backgroundColor: colors.highlightText,
          border: `3px solid ${colors.darkHighlight}`,
        },
      },
    ],
    alert: [
      button,
      fonts.bold,
      {
        'color': colors.alert,
        'backgroundColor': 'white',
        'border': `3px solid transparent`,
        '&:hover': {
          color: 'white',
          backgroundColor: colors.darkHighlight,
        },
      },
    ],
  } as Buttons;
};

export const getRedesignButtons = (colors: Colors, fonts: Fonts) => {
  const button: CSSInterpolation = {
    borderRadius: borderRadius.redesignButton,
    textAlign: 'center',
    transition: 'background-color 0.2s ease',
    cursor: 'pointer',
    padding: '0.75rem 1.5rem',
    fontSize: '1.1875rem',
    lineHeight: '100%',
    height: '3rem',
    display: 'flex',
    alignItems: 'center',
    maxWidth: 'fit-content',
    justifyContent: 'center',
  };

  const transparentButtonStyle: CSSInterpolation = [
    button,
    fonts.regular,
    {
      'color': colors.camelot,
      'backgroundColor': 'transparent',
      'border': `1px solid ${colors.camelot}`,
      '&:hover': {
        backgroundColor: colors.camelot,
        color: 'white',
      },
      '&:focus': {
        border: `1px solid ${colors.camelot}`,
        color: colors.camelot,
        backgroundColor: 'transparent',
      },
      '& svg': {
        fill: `${colors.camelot} !important`,
        verticalAlign: 'middle',
        marginLeft: '0.75rem',
      },
      '&:hover svg': {
        fill: 'white !important',
      },
      '&:focus svg': {
        fill: `${colors.camelot} !important`,
      },
    },
  ];

  const plumButtonStyle: CSSInterpolation = [
    button,
    fonts.regular,
    {
      'color': 'white',
      'backgroundColor': colors.camelot,
      'border': `1px solid transparent`,
      '&:hover': {
        backgroundColor: colors.vibrant,
      },
      '&:focus': {
        backgroundColor: colors.camelot,
        border: `1px solid white`,
      },
      '& svg': {
        fill: 'white !important',
        verticalAlign: 'middle',
        marginLeft: '0.75rem',
      },
    },
  ];

  return {
    plum: plumButtonStyle,
    darkGreen: [
      button,
      fonts.regular,
      {
        'color': 'white',
        'backgroundColor': colors.darkGreen,
        'border': `1px solid transparent`,
        '&:hover': {
          backgroundColor: 'rgba(35, 73, 75, 0.80)',
        },
        '&:focus': {
          backgroundColor: colors.darkGreen,
          border: `1px solid white`,
        },
        '& svg': {
          fill: 'white !important',
          verticalAlign: 'middle',
          marginLeft: '0.75rem',
        },
      },
    ],
    transparent: transparentButtonStyle,
    whiteWithPlumFont: [
      ...transparentButtonStyle,
      {
        'backgroundColor': colors.cream,
        'border': 'none',
        '&:hover': {
          color: 'white',
          backgroundColor: colors.camelot,
        },
        '&:focus': {
          color: colors.camelot,
          backgroundColor: colors.cream,
          border: 'none',
          boxShadow: `unset`,
        },
        '&:focus svg': {
          fill: `${colors.camelot} !important`,
        },
      },
    ],
    whiteWithGreenFont: [
      ...transparentButtonStyle,
      {
        'backgroundColor': colors.cream,
        'color': colors.darkGreen,
        'border': 'none',
        '&:hover': {
          color: 'white',
          backgroundColor: colors.camelot,
        },
        '& svg': {
          fill: `${colors.darkGreen} !important`,
        },
        '&:focus': {
          color: colors.darkGreen,
          backgroundColor: colors.cream,
          border: 'none',
          boxShadow: `unset`,
        },
        '&:focus svg': {
          fill: `${colors.darkGreen} !important`,
        },
      },
    ],
    disabled: [
      button,
      fonts.bold,
      {
        backgroundColor: colors.fadedHighlight,
        border: `1px solid transparent`,
        color: 'white',
        cursor: 'not-allowed',
      },
    ],
    highlight: [
      button,
      fonts.bold,
      {
        'color': 'white',
        'backgroundColor': colors.goldHighlight,
        'border': `1px solid transparent`,
        '&:hover': {
          backgroundColor: colors.darkGoldHighlight,
        },
      },
    ],
    secondaryNoHover: [
      button,
      fonts.bold,
      {
        color: colors.highlightText,
        backgroundColor: 'white',
        border: `1px solid ${colors.fadedHighlight}`,
      },
    ],
    secondaryDisabled: [
      button,
      fonts.bold,
      {
        border: `1px solid ${colors.fadedHighlight}`,
        color: colors.fadedHighlight,
        backgroundColor: 'white',
        cursor: 'auto',
      },
    ],
    secondaryTransparent: [
      button,
      fonts.bold,
      {
        'color': colors.highlightText,
        'backgroundColor': 'transparent',
        'border': `1px solid ${colors.highlightText}`,
        '&:hover': {
          color: 'white',
          backgroundColor: colors.highlightText,
          border: `1px solid ${colors.darkHighlight}`,
        },
      },
    ],
    alert: [
      button,
      fonts.bold,
      {
        'color': colors.alert,
        'backgroundColor': 'white',
        'border': `1px solid transparent`,
        '&:hover': {
          color: 'white',
          backgroundColor: colors.darkHighlight,
        },
      },
    ],
    primary: plumButtonStyle,
    secondary: transparentButtonStyle,
  } as Partial<Buttons>;
};
