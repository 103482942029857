import React from 'react';
import { CSSInterpolation } from '@emotion/serialize';
import { WebsiteLink } from 'src/components/common/website-link';
import { WebsiteLinkButtonContent } from 'src/components/common/website-link-button-content';
import { StaticTextLookupKey } from 'src/translators/static-text-lookup';
import kebabCase from 'lodash/kebabCase';

type GlobalLinkButtonContentProps = {
  buttonText?: string | React.ReactElement;
  buttonKey?: StaticTextLookupKey;
  buttonLink: string;
  buttonCss?: CSSInterpolation;
  buttonEndIcon?: React.ReactElement;
  buttonType?: string;
  gtmTrackingId?: string;
  className?: string;
  buttonStyle?: string;
  isRedesignButton?: boolean;
  openInNewTab?: boolean;
  handleClick?: () => void;
};

const GlobalLinkButtonContent = ({
  buttonText,
  buttonKey,
  buttonLink,
  buttonCss,
  buttonEndIcon,
  buttonType,
  gtmTrackingId,
  className = '',
  buttonStyle,
  isRedesignButton,
  openInNewTab,
  handleClick,
}: GlobalLinkButtonContentProps) => {
  const tabIndexProp = isRedesignButton ? { tabIndex: 0 } : { tabIndex: 0 };

  const content = (
    <WebsiteLinkButtonContent
      buttonCss={buttonCss}
      buttonKey={buttonKey}
      buttonText={buttonText}
      buttonEndIcon={buttonEndIcon}
      buttonType={buttonType}
      gtmTrackingId={gtmTrackingId}
    />
  );

  if (buttonLink.indexOf('http') !== -1 || buttonLink.indexOf('localhost') !== -1 || openInNewTab) {
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={buttonLink}
        className={
          isRedesignButton ? `redesign-button ${kebabCase(buttonStyle)} ${className}` : className
        }
        onClick={() => handleClick && handleClick()}
        {...tabIndexProp}
      >
        {content}
      </a>
    );
  }

  return (
    <WebsiteLink
      to={buttonLink || '/'}
      goToGlobal={true}
      className={
        isRedesignButton ? `redesign-button ${kebabCase(buttonStyle)} ${className}` : className
      }
      isTabIndex={isRedesignButton}
      handleClick={handleClick}
    >
      {content}
    </WebsiteLink>
  );
};

export default GlobalLinkButtonContent;
