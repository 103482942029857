import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Styling } from 'src/context/styling';
import { CSSInterpolation } from '@emotion/serialize';
import SiteConsumer from '../../context/site-consumer';
import { getSrc } from 'src/helpers/cloudinary-helpers';
import remarkGfm from 'remark-gfm';

type MarkdownToHtmlBlockProps = {
  markdownText: string;
  openLinksInNewTab?: boolean;
  colorOverride?: string;
  cssOverride?: CSSInterpolation;
};

const bulletPoint = '"\u2022"';

const markdownListStyle = (styling: Styling) =>
  [
    styling.fonts.light,
    {
      paddingLeft: '1.5rem',
      margin: 'auto',
      textAlign: 'left',
      lineHeight: 2,
      color: styling.colors.bodyText,
    },
  ] as CSSInterpolation;

const markdownTextStyle = (
  styling: Styling,
  colorOverride?: string,
  paddingStyle?: CSSInterpolation
) =>
  [
    styling.fonts.light,
    {
      strong: styling.fonts.bold,
      ul: [
        markdownListStyle(styling),
        paddingStyle,
        {
          listStyle: 'none',
          li: {
            '::before': {
              content: bulletPoint,
              display: 'inline-block',
              color: styling.colors.highlightText,
              width: '1.1em',
              marginLeft: '-1.1em',
            },
          },
        },
      ],
      ol: [markdownListStyle(styling), paddingStyle],
      a: [styling.common.link, { color: colorOverride }],
      color: colorOverride || styling.colors.bodyText,
    },
  ] as CSSInterpolation;

export const MarkdownToHtmlBlock: React.FC<MarkdownToHtmlBlockProps> = ({
  markdownText,
  openLinksInNewTab,
  colorOverride,
  cssOverride,
}) => (
  <SiteConsumer>
    {({ styling }) => (
      <ReactMarkdown
        css={[markdownTextStyle(styling, colorOverride), cssOverride]}
        transformImageUri={(imageUri) => getSrc(imageUri)}
        linkTarget={openLinksInNewTab ? '_blank' : '_self'}
        remarkPlugins={[remarkGfm]}
      >
        {markdownText}
      </ReactMarkdown>
    )}
  </SiteConsumer>
);

export default MarkdownToHtmlBlock;
