import * as React from 'react';
import { ClubMetadata, CmsContent } from '.';
import { CmsProvider } from './cms-provider';
import MetadataProvider from './metadata-provider';

export const ClubContentProvider = ({
  clubId,
  children,
}: {
  clubId?: string;
  children: React.ReactNode;
}) => (
  <MetadataProvider clubId={clubId}>
    {(clubMetadata) => (
      <CmsProvider clubId={clubId}>
        {(cmsContent) => (
          <ClubMetadata.Provider value={clubMetadata}>
            <CmsContent.Provider value={cmsContent}>{children}</CmsContent.Provider>
          </ClubMetadata.Provider>
        )}
      </CmsProvider>
    )}
  </MetadataProvider>
);

export default ClubContentProvider;
